import React, { useState } from 'react'
import { Button, Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { Switch, Route, Link } from 'react-router-dom'
import { CompressorStartCount } from '..'
import { PeakSourceTime } from '..'

const deletionStatisticsState = (setPublish) => {
    setPublish({
            PDHWT1: 0,
            PDHWT2: 0,
            PHTT1: 0,
            PHTT2: 0,
            PACT1: 0,
            PACT2: 0,
            PFC: 0,
            SS1_PDHWT1: 0,
            SS1_PDHWT2: 0,
            SS1_PHTT1: 0,
            SS1_PHTT2: 0,
            SS1_PACT1: 0,
            SS1_PACT2: 0,
            SS1_PFC: 0,
            SS2_PDHWT1: 0,
            SS2_PDHWT2: 0,
            SS2_PHTT1: 0,
            SS2_PHTT2: 0,
            SS2_PACT1: 0,
            SS2_PACT2: 0,
            SS2_PFC: 0,
            SS3_PDHWT1: 0,
            SS3_PDHWT2: 0,
            SS3_PHTT1: 0,
            SS3_PHTT2: 0,
            SS3_PACT1: 0,
            SS3_PACT2: 0,
            SS3_PFC: 0,
            SS4_PDHWT1: 0,
            SS4_PDHWT2: 0,
            SS4_PHTT1: 0,
            SS4_PHTT2: 0,
            SS4_PACT1: 0,
            SS4_PACT2: 0,
            SS4_PFC: 0,
            SS5_PDHWT1: 0,
            SS5_PDHWT2: 0,
            SS5_PHTT1: 0,
            SS5_PHTT2: 0,
            SS5_PACT1: 0,
            SS5_PACT2: 0,
            SS5_PFC: 0,
            SS6_PDHWT1: 0,
            SS6_PDHWT2: 0,
            SS6_PHTT1: 0,
            SS6_PHTT2: 0,
            SS6_PACT1: 0,
            SS6_PACT2: 0,
            SS6_PFC: 0,
            SS7_PDHWT1: 0,
            SS7_PDHWT2: 0,
            SS7_PHTT1: 0,
            SS7_PHTT2: 0,
            SS7_PACT1: 0,
            SS7_PACT2: 0,
            SS7_PFC: 0,
            SS8_PDHWT1: 0,
            SS8_PDHWT2: 0,
            SS8_PHTT1: 0,
            SS8_PHTT2: 0,
            SS8_PACT1: 0,
            SS8_PACT2: 0,
            SS8_PFC: 0,
            SS9_PDHWT1: 0,
            SS9_PDHWT2: 0,
            SS9_PHTT1: 0,
            SS9_PHTT2: 0,
            SS9_PACT1: 0,
            SS9_PACT2: 0,
            SS9_PFC: 0,
            SS10_PDHWT1: 0,
            SS10_PDHWT2: 0,
            SS10_PHTT1: 0,
            SS10_PHTT2: 0,
            SS10_PACT1: 0,
            SS10_PACT2: 0,
            SS10_PFC: 0,
            SS11_PDHWT1: 0,
            SS11_PDHWT2: 0,
            SS11_PHTT1: 0,
            SS11_PHTT2: 0,
            SS11_PACT1: 0,
            SS11_PACT2: 0,
            SS11_PFC: 0,
            SS12_PDHWT1: 0,
            SS12_PDHWT2: 0,
            SS12_PHTT1: 0,
            SS12_PHTT2: 0,
            SS12_PACT1: 0,
            SS12_PACT2: 0,
            SS12_PFC: 0
    })
}

const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień"
]

// const current = new Date().getMonth()
// const currentYear = new Date().getFullYear()

const listItem = (items) => {
    const current = items.MIESIAC - 1
    const currentYear = items.ROK

    let tableRowItem = []
    tableRowItem.push(
        <TableRow key={`statystyki_0`}>
                <TableCell style={{textAlign: "left"}}>{`${months[current]}`}&nbsp;{`${currentYear}`}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PDHWT1`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PDHWT2`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PHTT1`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PHTT2`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PACT1`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PACT2`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items.SS0[`PFC`]}</TableCell>
            </TableRow>
    )
    for (let i = 1; i <= 12; i++) {
        tableRowItem.push(
            <TableRow key={`statystyki_${i}`}>
                <TableCell style={{textAlign: "left"}}>{current - i < 0 ? <React.Fragment>{`${months[12 + current - i]}`}&nbsp;{`${(currentYear - 1)}`}</React.Fragment> : <React.Fragment>{`${months[current - i]}`}&nbsp;{`${currentYear}`}</React.Fragment>}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PDHWT1`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PDHWT2`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PHTT1`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PHTT2`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PACT1`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PACT2`]}</TableCell>
                <TableCell style={{textAlign: "center"}}>{items[`SS${i}`][`SS${i}_PFC`]}</TableCell>
            </TableRow>
        )
    }

    return tableRowItem
}

const DeviceTime = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // const handleDeletionStatistics = () => {
    //     deletionStatisticsState(setPublish)
    //     handleClose()
    // }
    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{height: "100%"}}>
        <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={2} style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold" style={{textAlign: "center"}}>
                                                Podgrzewanie CWU
                                        </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold" style={{textAlign: "center"}}>
                                                Ogrzewanie
                                        </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} style={{ borderLeft: "1px solid #e0e0e0", borderRight: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold" style={{textAlign: "center"}}>
                                                Chłodzenie AC
                                        </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Miesiąc
                                                    </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell  style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Drogi prąd (T1)
                                                    </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell  style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Tani prąd (T2)
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Drogi prąd (T1)
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Tani prąd (T2)
                                                    </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Drogi prąd (T1)
                                                    </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Tani prąd (T2)
                                                    </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #e0e0e0"}}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Chłodzenie
                                                    </Box>
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listItem(settings)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*<Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleClickOpen()}>Kasuj statystyki</Button>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>

                            <Typography variant="h2">Czy napewno chcesz wykasować historię statystyk?</Typography>
                            <Typography variant="body2">Proces kasowania jest nieodwracalny.</Typography>
                            <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleDeletionStatistics()}>Tak, kasuję permanentnie historię statystyk z urządzenia</Button>
                        </DialogContent>
                    </Dialog>*/}
                </Grid>
            </Grid>
        </div>
    )
}

export default DeviceTime