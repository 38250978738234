import React from 'react';
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';

const ControlFunctionItemRead = ({ stamp, cfs }) => {
    return (
        <Row>
            <p>
                <span style={{marginRight: "15px", marginLeft: "10px"}}>
                    {cfs[stamp] === 1 ?
                        <FontAwesomeIcon style={{ color: "green" }} icon={faCircle} />
                        : <FontAwesomeIcon icon={faCircle} style={{ color: "gray" }} />}
                </span> 
                 {descriptionDictionary.cfs[stamp]}
            </p>
        </Row>

    )
}

export default ControlFunctionItemRead;