import { default as fetchRefreshToken } from './fetchRefreshToken'

const fetchPush = async (data) => {
    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/message/send`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      return res
    })
    return response
}

  export default fetchPush
