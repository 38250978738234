import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { registerDictionary } from '../../../../data/dataDictionary'
import { FiberManualRecord } from '@material-ui/icons'

const LogicCoolingAC = ({ settings }) => {
    const dict = registerDictionary().analysis.coolingAC
    const deviceName = sessionStorage.getItem('deviceName')

    return (
        <React.Fragment>
           <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Analiza logiki sterowania chłodzeniem Active Cooling</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBF_OOF}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_OOF === 1 ? 'green' : 'red', marginLeft: '45px' }} /> 
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2">{settings.SBF_OOF === 1 ? "Urządzenie załączone" : "Urządzenie wyłączone"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBF_ACX}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_ACX === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }} >{settings.SBF_ACX === 1 ? "Funkcja chłodzenia AC dostępna" : "Funkcja chłodzenia AC niedostępna"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBF_ACS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_ACS === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_ACS === 1 ? "Funkcja chłodzenia AC załączona"  : "Funkcja chłodzenia AC wyłączona"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SXF_TOE}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SXF_TOE === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }} >{settings.SXF_TOE === 1 ? "Funkcja kontr. temp. wewn. zał." : "Funkcja kontr. temp. wewn. wył."}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QHW}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QHW === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QHW === 0 ? "Brak realizacji priorytetu cwu" : "Realizacja priorytetu cwu"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SWEXT_TWP}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SWEXT_TWP  === 0 ? settings.SBB_QTIN === 0 ? 'green' : 'red' : 'green', marginLeft: '45px' }} />
                        </Grid>
                        <Grid  style={{ lineHeight: '27px' }} item xs={10}>
                            <Typography variant="body2">{settings.SWEXT_TWP  === 0 ? settings.SBB_QTIN === 0 ? "Temp. wewn. za wysoka" : "Temp. wewn. wystarczająca" : "Brak kontroli temp. wewn."}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QCOUT}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QCOUT === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QCOUT === 1 ? "Temp. zewn. wyższa od progu chłod." : "Temp. zewn. niższa od progu chłodz"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_RMD}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_RMD === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_RMD === 1 ? "Zezwolenie z krzywej chłodzenia" : "Brak zezwolenia z krzywej chłodzenia"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.REW_ERR}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_ERR === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_ERR === 0 ? "Brak błędu" : "Aktywny błąd"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QDIS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QDIS === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }} >{settings.SBB_QDIS === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QRMIN}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QRMIN === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }} >{settings.SBB_QRMIN === 0 ? "W normie" : "Przekroczona"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QCS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QCS === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QCS === 0 ? "Zezwolenie" : "Wymuszony postój"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {deviceName === 'GEO_1' ?
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SXB_4DR}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SXB_4DR === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXB_4DR === 0 ? "Praca prawidłowa" : "Praca nieprawidłowa"}</Typography> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            {deviceName === 'GEO_1' ?
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QSMAX}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QSMAX === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMAX === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.REW_RMD }</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_RMD=== 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_RMD=== 0 ? "W normie" : "Przekroczenie"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {deviceName === 'GEO_1' ? 
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.REW_SMD }</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.REW_SMD === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_SMD === 0 ? "W normie" : "Przekroczona"}</Typography> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            {deviceName === 'GEO_1' ?
                null
                : <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBB_QSMIN }</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QSMIN === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMIN === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {deviceName === 'GEO_1' ? 
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SBF_FCS }</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBF_FCS === 0 ? 'red' : 'green', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_FCS === 0 ? "Free-coolin wyłączone" : "Free-cooling załączone"}</Typography> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            {deviceName === 'GEO_1' ? 
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dict.SXB_ACBL}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SXB_ACBL === 0 ? 'red' : 'green', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXB_ACBL === 0 ? "Za niska" : "W normie"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
        </React.Fragment>
    )
}

export default LogicCoolingAC