import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Navbar from 'react-bootstrap/Navbar'

import cwuIcon from '../../../../img/Cyrkulacja2.svg'

import { Away, Heating, AirConditioning, Time, Main, EcoSettings } from './components'
import { NavbarBox } from '../../components'

//import { Main, Heating, AirConditioning, EcoSettings, Away, Time } from "./components"

import {PumpTimeControl} from '../'

const Home = ({ main, setPublish }) => {
    return (
        <Container className="user-control">
            <section>
                <Main main={main.main} setPublish={setPublish} />
            </section>
            <section>
                <Heating heating={main.heating} setPublish={setPublish} />
            </section>
            <section>
                <AirConditioning airConditioning={main.airConditioning} setPublish={setPublish} />
            </section>
            <section>
                <EcoSettings ecoSettings={main.ecoSettings} setPublish={setPublish} />
            </section>
            <section>
                <Time time={main.time} controllertime={main.controllertime} setPublish={setPublish} />
            </section>
            <section>
                <Away away={main.away} setPublish={setPublish} />
            </section>
            <section>
            <Row>
            <Navbar bg="primary" variant="dark" style={{ width: "100%" }}>
                <Navbar.Brand className="mr-auto"><img alt="cyrkulacja" className="mode-icon" src={cwuIcon} /></Navbar.Brand>
                <Navbar.Brand>CWU</Navbar.Brand>
            </Navbar>
        </Row>
                <PumpTimeControl noTitle={true} ptc={main.ptc} setPublish={setPublish} />
            </section>
        </Container>
    )
}

export default Home;