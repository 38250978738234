import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { registerDictionary } from '../../../../data/dataDictionary'
import bullet_green from '../../../../img/bullet_green.svg'
import bullet_red from '../../../../img/bullet_red.svg'
import { FiberManualRecord } from '@material-ui/icons'

const LogicHeating = ({ settings }) => {
    const dictionary = registerDictionary().analysis.heating
    const deviceName = sessionStorage.getItem('deviceName')
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Analiza logiki sterowania ogrzewaniem</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_OOF}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_OOF === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_OOF === 1 ? "Urządzenie załączone" : "Urządzenie wyłączone"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_HTX}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_HTX === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_HTX === 1 ? "Funkcja ogrzewania dostępna" : "Funkcja ogrzewania niedostępna"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_HTS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_HTS === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_HTS === 1 ? "Funkcja ogrzewania załączona" : "Funkcja ogrzewania wyłączona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SXF_TOE}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SXF_TOE === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXF_TOE === 1 ? "Funkcja kontr. temp. wewn. zał." : "Funkcja kontr. temp. wewn. wył."}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QHW}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QHW === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QHW === 0 ? "Brak realizacji priorytetu cwu" : "Realizacja priorytetu cwu"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SWEXT_TWP}</Typography>
                </Grid>
                {deviceName === 'GEO_1' ?
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QTIN === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QTIN === 0 ? "Temperature osiągnięta" : "Temperatura za niska"}</Typography> 
                            </Grid>
                        </Grid>
                    </Grid>
                    : <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={4}>
                                <FiberManualRecord style={{ color: settings.SWEXT_TWP === 0 ? settings.SBB_QTIN === 1 ? 'green' : 'red' : 'green', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SWEXT_TWP === 0 ? settings.SBB_QTIN === 1 ? "OB0: Za niska" : "OB0: Osiągnięta" : "OB0: Brak kontroli"}</Typography> 
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {deviceName === 'GEO_1' ? 
                    null
                    : <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={4}>
                                <FiberManualRecord style={{ color: settings.OB1_XZM_TRANSM === 0 || settings.OB1_TWEW < 200 ? settings.OB1_TKMF > settings.OB1_TWEW ? 'green' : 'red' : 'green', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.OB1_XZM_TRANSM === 0 || settings.OB1_TWEW < 200 ? settings.OB1_TKMF > settings.OB1_TWEW ? "OB1: Za niska" : "OB1: Osiągnięta" : "OB1: Brak kontroli"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QHOUT}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QHOUT === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QHOUT === 1 ? "Temp. zewn. niższa od progu ogrz." : "Temp. zewn. wyższa od progu ogrz."}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QSMD}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QSMD === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMD === 1 ? "Zezwolenie z krzywej grzewczej" : "Brak zezwolenia z krzywej grzewczej"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_ERR}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_ERR === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_ERR === 0 ? "Brak błędu" : "Aktywny błąd"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QDIS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QDIS === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QDIS === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QRMAX}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QRMAX  === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QRMAX  === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {deviceName === 'GEO_1' ? 
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QSMIN}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QSMIN  === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMIN  === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QSMAX}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QSMAX  === 0 ? 'green' :  'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMAX  === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QCS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QCS  === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QCS  === 0 ? "Zezwolenie" : "Wymuszony postój"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {deviceName === 'GEO_1' ? 
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SXB_4DR}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SXB_4DR === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXB_4DR === 0 ? "Praca prawidłowa" : "Praca nieprawidłowa"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            {deviceName === 'GEO_1' ? 
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_SMD}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.REW_SMD === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_SMD === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_RMD}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_RMD === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_RMD === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LogicHeating