import React, { useState, useEffect } from 'react'
import { CirculationFunctions } from './components'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { Slider } from '@mui/material'
import Back from '../../img/powrot.svg'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Cyrkulacja from '../../img/Cyrkulacja2.svg'
import { useRouteMatch } from "react-router-dom"

const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function valuetext(value) {
    return `${value}°C`;
}

const marks = () => {
    const marksTable = []

    for (let i = 0; i <= 24; i++) {
        marksTable.push({ value: i, label: `${i}` })
    }

    return marksTable
}

const minDistance = 1

const DayTab = ({ day, value, index, settings, stamp, setPublish, constwork, setTouchStatus, ...props }) => {
    const theme = useTheme()
    const [value1, setValue1] = useState([settings[`CYR${stamp}1P_PG`], settings[`CYR${stamp}1P_KG`], settings[`CYR${stamp}2P_PG`], settings[`CYR${stamp}2P_KG`], settings[`CYR${stamp}3P_PG`], settings[`CYR${stamp}3P_KG`] === 23 && settings[`CYR${stamp}3P_KM`] === 60 ? 24 : settings[`CYR${stamp}3P_KG`]])
    const [trackColors, setTrackColors] = useState({
        S1P: (settings[`CYR${stamp}1P_PG`] / 24) * 100,
        S1K: (settings[`CYR${stamp}1P_KG`] / 24) * 100,
        S2P: (settings[`CYR${stamp}2P_PG`] / 24) * 100,
        S2K: (settings[`CYR${stamp}2P_KG`] / 24) * 100,
        S3P: (settings[`CYR${stamp}3P_PG`] / 24) * 100,
        S3K: settings[`CYR${stamp}3P_KM`] === 60 && settings[`CYR${stamp}3P_KG`] === 23? 100 : (settings[`CYR${stamp}3P_KG`] / 24) * 100
    })

    const handleChange1 = (event, newValue, activeThumb) => {
        // to do chwyta nie ten point jak są dwa jeden nad drugim
        setTouchStatus(true)
        if (!Array.isArray(newValue) && constwork) {
            return
        }

        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1], value1[2], value1[3], value1[4], value1[5]])
            setTrackColors({ ...trackColors, S1P: (Math.min(newValue[0], value1[1] - minDistance) / 24) * 100 })
        }

        if (activeThumb === 1) {
            if (newValue[1] > value1[1]) {
                // console.log({case1_min: Math.min(newValue[0], value1[1] - minDistance)})
                setValue1([value1[0], Math.min(newValue[1], value1[2]), value1[2], value1[3], value1[4], value1[5]])
                setTrackColors({ ...trackColors, S1K: (Math.min(newValue[1], value1[2]) / 24) * 100 })
            } else {
                // console.log({case1_max: Math.max(newValue[1], value1[0] + minDistance)})
                setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance), value1[2], value1[3], value1[4], value1[5]])
                setTrackColors({ ...trackColors, S1K: (Math.max(newValue[1], value1[0] + minDistance) / 24) * 100 })
            }
        }

        if (activeThumb === 2) {
            if (newValue[2] > value1[2]) {
                // console.log({case2_min: Math.min(newValue[2], value1[3] - minDistance)})
                setValue1([value1[0], value1[1], Math.min(newValue[2], value1[3] - minDistance), value1[3], value1[4], value1[5]])
                setTrackColors({ ...trackColors, S2P: (Math.min(newValue[2], value1[3] - minDistance) / 24) * 100 })
            } else {
                // let wskaznik = newValue[2] - value1[1] <= 1? value1[1] : Math.max(newValue[2], value1[1])
                // console.log(Math.max(newValue[2], value1[1]))
                setValue1([value1[0], value1[1], Math.max(newValue[2], value1[1]), value1[3], value1[4], value1[5]])
                setTrackColors({ ...trackColors, S2P: (Math.max(newValue[2], value1[1]) / 24) * 100 })
            }
        }

        if (activeThumb === 3) {
            if (newValue[3] > value1[3]) {
                setValue1([value1[0], value1[1], value1[2], Math.min(newValue[3], value1[4]), value1[4], value1[5]])
                setTrackColors({ ...trackColors, S2K: (Math.min(newValue[3], value1[4]) / 24) * 100 })
            } else {
                setValue1([value1[0], value1[1], value1[2], Math.max(newValue[3], value1[2] + minDistance), value1[4], value1[5]])
                setTrackColors({ ...trackColors, S2K: (Math.max(newValue[3], value1[2] + minDistance) / 24) * 100 })
            }
        }

        if (activeThumb === 4) {
            if (newValue[4] > value1[4]) {
                setValue1([value1[0], value1[1], value1[2], value1[3], Math.min(newValue[4], value1[5] - minDistance), value1[5]])
                setTrackColors({ ...trackColors, S3P: (Math.min(newValue[4], value1[5] - minDistance) / 24) * 100 })
            } else {
                setValue1([value1[0], value1[1], value1[2], value1[3], Math.max(newValue[4], value1[3]), value1[5]])
                setTrackColors({ ...trackColors, S3P: (Math.max(newValue[4], value1[3]) / 24) * 100 })
            }
        }

        if (activeThumb === 5) {
            setValue1([value1[0], value1[1], value1[2], value1[3], value1[4], Math.max(newValue[5], value1[4] + minDistance)])
            setTrackColors({ ...trackColors, S3K: (Math.max(newValue[5], value1[4] + minDistance) / 24) * 100 })
        }
    }

    const handleTouchMouseEnd = (event, newValue) => {
        if(!constwork){
            if (newValue[5] === 24) {
                setPublish({
                    [`CYR${stamp}1P_PG`]: newValue[0],
                    [`CYR${stamp}1P_KG`]: newValue[1],
                    [`CYR${stamp}2P_PG`]: newValue[2],
                    [`CYR${stamp}2P_KG`]: newValue[3],
                    [`CYR${stamp}3P_PG`]: newValue[4],
                    [`CYR${stamp}3P_KG`]: 23,
                    [`CYR${stamp}3P_KM`]: 60,
                })
            } else {
                setPublish({
                    [`CYR${stamp}1P_PG`]: newValue[0],
                    [`CYR${stamp}1P_KG`]: newValue[1],
                    [`CYR${stamp}2P_PG`]: newValue[2],
                    [`CYR${stamp}2P_KG`]: newValue[3],
                    [`CYR${stamp}3P_PG`]: newValue[4],
                    [`CYR${stamp}3P_KG`]: newValue[5],
                    [`CYR${stamp}3P_KM`]: 0,
                })
            }

        }
        setTouchStatus(false)
    }

    useEffect(() => {
        let mounted = true
        setValue1([settings[`CYR${stamp}1P_PG`], settings[`CYR${stamp}1P_KG`], settings[`CYR${stamp}2P_PG`], settings[`CYR${stamp}2P_KG`], settings[`CYR${stamp}3P_PG`], settings[`CYR${stamp}3P_KG`] === 23 && settings[`CYR${stamp}3P_KM`] === 60 ? 24 : settings[`CYR${stamp}3P_KG`]])
        setTrackColors({
            S1P: (settings[`CYR${stamp}1P_PG`] / 24) * 100,
            S1K: (settings[`CYR${stamp}1P_KG`] / 24) * 100,
            S2P: (settings[`CYR${stamp}2P_PG`] / 24) * 100,
            S2K: (settings[`CYR${stamp}2P_KG`] / 24) * 100,
            S3P: (settings[`CYR${stamp}3P_PG`] / 24) * 100,
            S3K: settings[`CYR${stamp}3P_KM`] === 60 && settings[`CYR${stamp}3P_KG`] === 23? 100 : (settings[`CYR${stamp}3P_KG`] / 24) * 100
        })
        return () => {
            mounted = false
        }
    }, [settings, stamp])

    /* useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
      }, []); */

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...props}>
            {value === index && (<React.Fragment>
                <div style={{ padding: '100px 50px 0' }}>
                    <Slider
                        className='bottomLabel'
                        getAriaLabel={() => 'Minimum distance'}
                        value={constwork? [0,24] : value1}
                        onChange={handleChange1}
                        onChangeCommitted={handleTouchMouseEnd}
                        getAriaValueText={valuetext}
                        max={24}
                        min={0}
                        marks={marks()}
                        disableSwap
                        valueLabelDisplay="on"
                        sx={{
                            '& .MuiSlider-track': {
                                background: `none transparent`,
                            },
                            '& .MuiSlider-rail': {
                                opacity: 1,
                                background: constwork === true ? '#006837' :
                                `linear-gradient(90deg, 
                                    #000000 0%, 
                                    #000000 ${trackColors.S1P}%, 
                                    #006837 ${trackColors.S1P}%, 
                                    #006837 ${trackColors.S1K}%, 
                                    #000000 ${trackColors.S1K}%, 
                                    #000000 ${trackColors.S2P}%,
                                    #006837 ${trackColors.S2P}%,
                                    #006837 ${trackColors.S2K}%,
                                    #000000 ${trackColors.S2K}%,
                                    #000000 ${trackColors.S3P}%,
                                    #006837 ${trackColors.S3P}%,
                                    #006837 ${trackColors.S3K}%,
                                    #000000 ${trackColors.S3K}%)`,
                            },
                        }}
                    />
                </div>
            </React.Fragment>)}
        </div>
    )
}

const Circulation = ({ settings, setPublish, setTouchStatus, url }) => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const constwork = settings.function.SXF_FORCYR === 1? true : false

    return (
        <React.Fragment>
            <Grid container className='circulation'>
                <Grid item xs={2} style={{textAlign: 'center'}} className='modes'>
                    <img style={{margin: '17px auto', maxHeight: "56px"}} alt="Cyrkulacja" className="small-icon" src={Cyrkulacja} />
                </Grid>
                <Grid item xs={10}>

                    <Grid container>
                        <Grid item xs={12}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab label="PN" {...a11yProps(0)} />
                                <Tab label="WT" {...a11yProps(1)} />
                                <Tab label="ŚR" {...a11yProps(2)} />
                                <Tab label="CZ" {...a11yProps(3)} />
                                <Tab label="PT" {...a11yProps(4)} />
                                <Tab label="SB" {...a11yProps(5)} />
                                <Tab label="ND" {...a11yProps(6)} />
                            </Tabs>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '30px' }}>
                        <Grid item xs={10}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>Aktywność pompki cyrkulacyjnej w ciągu doby</Typography>
                            <div className='back-btn'><Link to={`${url}`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '-17px' }}>
                <Grid item xs={12}>
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={0} constwork={constwork} day={'poniedziałek'} settings={settings.P} stamp={'P'} setPublish={setPublish} />
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={1} constwork={constwork} day={'wtorek'} settings={settings.W} stamp={'W'} setPublish={setPublish} />
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={2} constwork={constwork} day={'środa'} settings={settings.S} stamp={'S'} setPublish={setPublish} />
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={3} constwork={constwork} day={'czwartek'} settings={settings.C} stamp={'C'} setPublish={setPublish} />
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={4} constwork={constwork} day={'piątek'} settings={settings.PT} stamp={'PT'} setPublish={setPublish} />
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={5} constwork={constwork} day={'sobota'} settings={settings.SO} stamp={'SO'} setPublish={setPublish} />
                    <DayTab setTouchStatus={setTouchStatus} value={value} index={6} constwork={constwork} day={'niedziela'} settings={settings.N} stamp={'N'} setPublish={setPublish} />
                </Grid>
            </Grid>
            <CirculationFunctions settings={settings.function} setPublish={setPublish} />
        </React.Fragment>
    )
}

export default Circulation