import React, { useEffect, useState } from 'react'
import { Typography, Grid, TextField } from '@material-ui/core'
import fetchCustomer from '../../../../data/fetchCustomer'
import fetchCustomerUpdate from '../../../../data/fetchCustomerUpdate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import formatString from "format-string-by-pattern"

const formatPhone = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const formatPostCode = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('99-999', onlyNumbers)
}

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const CustomerContact = ({ address }) => {


    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Kontakt {address && parseInt(address.id) === 1 && address.first_name === ""? <span style={{color: "red"}}> - klient nie zarejestrował urządzenia</span> : null}</Typography>
                </Grid>
            </Grid>
            
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>
                                <p>Inwestor: {address ? (`${address.first_name} ${address.last_name}`) : null}</p>
                            </Grid>
                        </Grid>
                    </React.Fragment>
               
            
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>
                                <p>Adres: {address && address.street !== ""? `ul. ${address.street} ${address.street_number}, ${address.post_code} ${address.city}` : null}</p>
                            </Grid>
                        </Grid>
                    </React.Fragment>
              <Grid container>
                    <Grid item xs={8}>
                        <p>Telefon: {address && address.id !== 1? address.phone : null}</p>
                    </Grid>
                </Grid>
               
                    <Grid container>
                        <Grid item xs={8}><p>E-mail: {address ? address.email : "nie podano"}</p></Grid>
                    </Grid>
        </React.Fragment>
    )
}

export default CustomerContact