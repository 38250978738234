const errorMessage = (status, type) => {
    if(type === "partner"){
        switch (status) {
            case 410:
                return "Brak danych."
            case 411:
                return "Partner nie istnieje w bazie."
            case 412:
                return "Błąd serwera."
            case 413:
                return "Błąd walidacji."
            case 415:
                return "Partner z tym numerem telefonu już istnieje."
            case 416:
                return "Partner z tym adresem e-mail już istnieje."
            case 417: 
                return "Partner z tym numerem NIP już istnieje."
            case 418: 
                return "Błąd bazy danych."
            case 419:
                return "Partner podwykonawcy nie istnieje."
            case 420:
                return "Błędny numer NIP."
            case 421:
                return "Błędny adres e-mail."
            case 422:
                return "Błędny numer telefonu."
        
            default:
                return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
        }
    } else {
        switch (status) {
            case 410:
                return "Brak danych."
            case 411:
                return "Partner nie istnieje w bazie. Dodaj partnera i spróbuj jeszcze raz."
            case 412:
                return "Błąd serwera."
            case 413:
                return "Użytkownik z takim adresem e-mail lub numerem telefonu już istnieje."
            case 414:
                return "Podany token nie istnieje."
            case 415:
                return "Podany token utracił ważność."
            case 416:
                return "Błędny token."
            case 417: 
                return "Błąd bazy danych. Skontaktuj się z administratorem."
            case 418: 
                return "Użytkownik nie istnieje."
            case 419:
                return "Błędny numer certyfikatu."
            case 420:
                return "Użytkownik nie jest instalatorem."
            case 421:
                return "Użytkownik nie posiada certyfikatu."
            case 422:
                return "Niepoprawny numer telefonu."
            case 423:
                return "Niepoprawny adres e-mail."
        
            default:
                return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
        }
    }
}

export default errorMessage