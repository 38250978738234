import fetchRefreshToken from './fetchRefreshToken'

const sendPartnerId = async (partner_id, id) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/devices`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${newToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          partner_id: partner_id,
        })
      })
        return res
    }).then(res => res.status)
    
      return response
  }
  
  export default sendPartnerId