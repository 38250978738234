export const registerValue = (receivedData) => {
    let data
    if (receivedData !== undefined) {
        const [tabData] = receivedData;
        data = tabData.Data;

        return {
            device: {
                Name: tabData.Name,
                Type: tabData.Type,
                Version: tabData.Version,
            },
            errors: {
                Errors: parseInt(tabData.Errors),
            },
            expirationData: {
                ExpirationTime: data.ExpirationTime,
                TimeStamp: data.ExpirationTime,
            },
            scada: {
                ZM_DIS_TEMP: data.ZM_DIS_TEMP,
                TSM9: data.TSM9,
                ZM_SUCT_TEMP: data.ZM_SUCT_TEMP,
                ZM_FAN1_SPE: data.ZM_FAN1_SPE,
                ZM_FAN2_SPE: data.ZM_FAN2_SPE,
                TSM15: data.TSM15,
                SIO_R03: parseInt(data.SIO_R03),
                T6fiz: data.T6fiz,
                T5fiz: data.T5fiz,
                TSM13: data.TSM13,
                TSM14: data.TSM14,
                SIO_R08: parseInt(data.SIO_R08),
                SIO_R02: parseInt(data.SIO_R02),
                SIO_R05: parseInt(data.SIO_R05),
                ZM_CUR_COMP_FREQ: data.ZM_CUR_COMP_FREQ,
                TSM10: data.TSM10,
                ZM_OUT_TEMP: data.ZM_OUT_TEMP,
                ZM_COIL_TEMP: data.ZM_COIL_TEMP,
                TSM17: data.TSM17,
                SBB_QHT: parseInt(data.SBB_QHT),
                SBF_OOF: parseInt(data.SBF_OOF),
                SXF_TOE: parseInt(data.SXF_TOE),
                SBB_QHL: parseInt(data.SBB_QHL),
                SBF_DHS: parseInt(data.SBF_DHS),
                SBF_DHX: parseInt(data.SBF_DHX),
                SBB_QHW: parseInt(data.SBB_QHW),
                PWM_1: data.PWM_1,
                COP: data.COP,
                SIO_R01: parseInt(data.SIO_R01),
                SIO_R09: parseInt(data.SIO_R09),
                SIO_R06: parseInt(data.SIO_R06),
                SIO_R07: parseInt(data.SIO_R07),
                SIO_R04: parseInt(data.SIO_R04),
                T3fiz: data.T3fiz,
                T4fiz: data.T4fiz,
                SBF_FCS: parseInt(data.SBF_FCS),
                TSM16: data.TSM16,
                ADC_1: parseInt(data.ADC_1),
                ADC_2: parseInt(data.ADC_2),
                ADC_3: parseInt(data.ADC_3),
                SXB_QSFC: parseInt(data.SXB_QSFC),
                SXF_ECO: parseInt(data.SXF_ECO),
                SXF_TIME: parseInt(data.SXF_TIME),
                SXF_AWA: parseInt(data.SXF_AWA),
                SBF_HTS: parseInt(data.SBF_HTS),
                SBF_ACS: parseInt(data.SBF_ACS),
                SBF_PHT: parseInt(data.SBF_PHT),
                SXF_FORCYR: parseInt(data.SXF_FORCYR),
                SXF_TIMCYR: parseInt(data.SXF_TIMCYR),
                SXF_FLPCYR: parseInt(data.SXF_FLPCYR),
                REW_ERR: parseInt(data.REW_ERR),
                REW_WRN: parseInt(data.REW_WRN),
                TURBO_CWU: parseInt(data.TURBO_CWU),
                SWEXT_BUF: parseInt(data.SWEXT_BUF),
                SIO_R10: data.SIO_R10,
                SIO_R11: data.SIO_R11,
            },
        }
    } else {
        return null
    }
}