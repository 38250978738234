export const dataRangeDictionary = {
    crot: {
        TOUT_KOR: "Temperatura zewnętrzna skorygowana - mechanizm CROT: ",
        TOUT_NUM: {
            min: 1,
            max: 24,
        },
    },
    block: {
        BLK_KD1: "Pierwsza część kodu odblokowującego",
        BLK_KD2: "Druga część kodu odblokowującego",
        BLK_LW: "Liczba dni do wyłączenia - bilans",
        BLK_LK: "Liczba poprawnych kodów",
    },
    temperature: {
        TSM9: "Temperatura zewnętrzna: ",
        TSM10: "Temperatura wewnętrzna:",
        TSM11: "Temperatura niższa na wymienniku źródła:",
        TSM12: "Temperatura wyższa na wymienniku źródła:",
        TSM13: "Temperatura wyższa na wymienniku odbioru:",
        TSM14: "Temperatura niższa na wymienniku odbioru:",
        TSM15: "Temperatura w zasobniku CWU:",
        TSM16: "Temperatura tłoczenia:",
        TSM17: "Temperatura z wejścia nie wykorzystanego:",
        T3fiz: "Temperatura wymiennika źrodła – góra:",
        T4fiz: "Temperatura wymiennika źrodła – dół:",
        T5fiz: "Temperatura wymiennika odbioru – góra:",
        T6fiz: "Temperatura wymiennika odbioru – dół:",
    },
    itc: {
        SPHT_HIST: {
            RW: 1,
            min: 0.4,
            max: 2.4,
            step: 0.4
        },
        SPHT_ZAD: {
            RW: 1,
            min: 5,
            max: 35,
        },
        SCHT: {
            RW: 0,
        },
        SX6: {
            RW: 0,
        },
        CUN_HCU: {
            RW: 1,
            min: 0,
            max: 9,
        },
        CUN_D_HCU: {
            RW: 1,
            min: 0,
            max: 10,
        },
        SX2_P2: {
            RW: 1,
            min: "SX2_P1",
            max: 25,
        },
        SX2_P1: {
            RW: 1,
            min: 10,
            max: "SX2_P2",
        },
        SX3_HG: {
            RW: 1,
            min: 2,
            max: 16,
        },
        SX3_HC: {
            RW: 1,
            min: 2,
            max: 16,
        },
        SPCU: {
            RW: 0,
        },
        SCCU: {
            RW: 0,
        },
        COC: {
            RW: 1,
            min: 0,
            max: 50,
        },
        SX1: {
            RW: 0,
        },
        TB1: {
            RW: 0,
        },
        TB2: {
            RW: 0,
        },
        SX4_PH: {
            RW: 1,
            min: 5,
            max: "SX4_PC",
            change: "max",
            changeMax: -1,
        },
        SX4_PC: {
            RW: 1,
            min: "SX4_PH",
            max: 30,
            change: "min",
            changeMin: 1,
        },
        SPDHW_HIST:{
            RW: 1,
            min: 1,
            max: 12,
        },
        SPDHW_ZAD: {
            RW: 1,
            min: 35,
            max: 60,
        },
    },
    errWarn: {
        REW_SUP: "Błąd softstartu / czujnika kalejności faz:",
        REW_TER: "Błąd z modułu termicznego sprężarki:",
        REW_LPC: "Błąd z presostatu niskiego ciśnienia dla chłodzenia:",
        REW_LPH: "Błąd z presostatu niskiego ciśnienia dla ogrzewania:",
        REW_HP: "Błąd z presostatu wysokiego ciśnienia:",
        REW_FLO: "Błąd z czujnika przepływu:",
        REW_DIS: "Przekroczenie temperatury tłoczenia:",
        REW_ERR: "Zbiorcza flaga stanu awaryjnego:",
        REW_SMI: "Przekroczenie temperatury minimalnej źródła ciepła (ogrzewanie):",
        REW_SMA: "Przekroczenie temperatury maksymalnej źródła chłodu (chłodzenie):",
        REW_RMI: "Przekroczenie temperatury minimalnej odbioru chłodu (chłodzenie):",
        REW_RMA: "Przekroczenie temperatury maksymalnej odbióru ciepła (ogrzewanie):",
        REW_SMD: "Przekroczenie temperatury krytycznej w wymienniku źródła:",
        REW_RMD: "Przekroczenie temperatury krytycznej w wymienniku odbioru:",
        REW_WRN: "Zbiorcza flaga stanu ostrzegawczego:",
        REW_FCS: "Awaria układu kontroli wejśc binarnych:",
    },
    eco: {
        ECOT1R: {
            min: 0,
            max: "ECOT1S",
        },
        ECOT1S: {
            min: "ECOT1R",
            max: 23
        },
        ECOT2R_Z: {
            min: 0,
            max: "ECOT2S_Z",
        },
        ECOT2S_Z: {
            min: "ECOT2R_Z",
            max: 23,
        },
        ECOT2R_L: {
            min: 0,
            max: "ECOT2S_L",
        },
        ECOT2S_L: {
            min: "ECOT2R_L",
            max: 23
        },
        ECOT3R: {
            min: 0,
            max: "ECOT3S",
        },
        ECOT3S: {
            min: "ECOT3R",
            max: 23,
        },
        ECOX1: {
            min: 0,
            max: 10,
        },
        ECOX2: {
            min: 0,
            max: 10,
        },
        ECODHW: {
            min: 0,
            max: 5,
        },
        ECOY1: {
            min: 0,
            max: 6,
        },
        ECOY2: {
            min: 0,
            max: 6,
        },
        ECOTIN: {
            min: 0,
            max: 3,
        },
    },
    cfs: {
        SBF_PHT: "Manualne załączenie funkcji Antylegionella",
        SXF_TOE: "Zezwolenie na kontrolę temperaturą wewnętrzną (HEAT, AC, FC)",
        SBF_HTX: "Możliwość załączenia ogrzewania budynku",
        SBF_HTS: "Załączenie ogrzewania budynku",
        SBF_DHX: "Możliwość załączenia podgrzewania CWU",
        SBF_DHS: "Załączennie podgrzewania CWU",
        SBF_FCX: "Możlwiość załączenia Free-Coolingu",
        SBF_FCS: "Załączenie Free-Coolingu",
        SBF_OOF: "Załączenie całego urządzenia",
        SBF_AHX: "Możliwość załączenia źródła szczytowego",
        SBF_AHS: "Możliwość załączenia źródła szczytowego tylko w tanim prądzie",
        SBF_ACX: "Możliwość załączenia Active-Coolingu",
        SBF_ACS: "Załączenie Active-Coolingu",
        SXF_TIM_WIN_SW: "Zezwolenie na automatyczną zmianę czasu (letni/zimowy)",
        SXF_TIM_WIN_FL: "1 = czas letni / 0 = czas zimowy",
        SXF_ACT: "Aktywna blokada czasowa",
        SXF_FLPCYR: "Wymuszenie czasowe pracy pompy cyrkulacyjnej",
        SXF_AZP: "Zezwolenie na sezonową zmianę godzin popołudniowych dla trybu ECO",
        SXF_TIMCYR: "Zezwolenie na sterowanie czasowe pompą cyrkulacyjną CWU",
        SXF_ENACYR: "Zezwolenie na załączenie pompy cyrkulacyjnej CWU",
        SXF_FORCYR: "Wymuszenie pracy pompy cyrkulacyjnej CWU",
        SXF_TIME: "Załączenie trybu TIME",
        SXF_ANL: "Zezwolenie na załączenie trybu Antylegionella",
        SXF_WEK: "Taryfa weekendowa rozliczania energii",
        SXF_AWA: "Załączenie trybu AWAY",
        SXF_ECO: "Załączenie trybu ECO",
        SXF_DHM: "0 = CWU przez zawór 2 drożny / 1 = CWU przez pompę obiegową",
        SXF_4EN: "Załączenie kontroli poprawności pracy zaworu 4 drożnego",
        ANL_ALL: {
            RW: 0,
        },
        ANL_1PH: {
            RW: 0,
        },
        ANL_2PH: {
            RW: 0,
        },
        ANL_3PH: {
            RW: 0,
        },
    },
    ptc: {
        CYR_FILL: "Czas cyklicznej pracy pompy cyrkulacyjnej",
        CYRSW_S3: "Załączenie sterowania czasowego CWU dla strefy 3",
        CYRSW_S2: "Załączenie sterowania czasowego CWU dla strefy 2",
        CYRSW_S1: "Załączenie sterowania czasowego CWU dla strefy 1",

    }
}