import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { dataRange } from '../../data/dataRange'
import { SwitchControl } from '../index'

const ExtenderMapping = (itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, filteredItems, blok, ext) => {
    return (
        filteredItems ? filteredItems.map(entry => {
            if (entry[1] !== undefined) {
                return (
                    <Grid container className={classes.containerVerticalCenter} key={entry[0]}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px', width: '179px', justifyContent: 'center' }}>
                            <Typography variant="body2">{entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.containerVerticalCenter} style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px', width: '179px', justifyContent: 'center' }}>
                            {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                SwitchControl(entry, itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, blok) 
                                : <Grid item xs={2} className={classes.textRight}>
                                {dataRange()[entry[0]].controller === 4 ? 
                                    <FiberManualRecord style={{ color: entry[1] === 0 ? dataRange()[entry[0]].controllerColor0 : dataRange()[entry[0]].controllerColor1 }} />
                                    : <Typography variant="body2">{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]}{dataRange()[entry[0]].symbol}</Typography>
                                }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px', width: '179px', justifyContent: 'center' }}>
                            {dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].controller && dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].RW === 1 ?
                                SwitchControl([`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`, itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`]], itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, blok) 
                                : <Grid item xs={2} className={classes.textRight}>
                                {dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].controller === 4 ? 
                                    <FiberManualRecord style={{ color: itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`] === 0 ? dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].controllerColor0 : dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].controllerColor1 }} />
                                    : <Typography variant="body2">{dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].parsed === 'float' ? itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].toFixed(1) : entry[1]}{dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_AOR_EN`].symbol}</Typography>
                                }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={2} className={classes.containerVerticalCenter}  style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px', width: '179px', justifyContent: 'center' }}>
                            {dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].controller && dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].RW === 1 ?
                                SwitchControl([`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`, itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`]], itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, blok, ext) 
                                : <Grid item xs={2} className={classes.textRight}>
                                {dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].controller === 4 ? 
                                    <FiberManualRecord style={{ color: itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`] === 0 ? dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].controllerColor0 : dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].controllerColor1 }} />
                                    : <Typography variant="body2">{dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].parsed === 'float' ? itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].toFixed(1) : entry[1]}{dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_MAN`].symbol}</Typography>
                                }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px', width: '179px', justifyContent: 'center' }}>
                            {dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].controller && dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].RW === 1 ?
                                SwitchControl([`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`, itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`]], itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, blok) 
                                : <Grid item xs={2} className={classes.textRight}>
                                {dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].controller === 4 ? 
                                    <FiberManualRecord style={{ color: itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`] === 0 ? dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].controllerColor0 : dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].controllerColor1 }} />
                                    : <Typography variant="body2">{dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].parsed === 'float' ? itemValue[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].toFixed(1) : entry[1]}{dataRange()[`${entry[0].slice(0, 3) === 'PWM' ? entry[0].slice(0, 4) : entry[0].slice(0, 3)}_OUT`].symbol}</Typography>
                                }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                )
            } else {
                return null
            }
        }) : null
    )
}

export default ExtenderMapping