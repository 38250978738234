import fetchRefreshToken from './fetchRefreshToken'

const fetchAddUser = async (payload) => {
    const response = await fetchRefreshToken().then(async newToken => {
    
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/user`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: payload.email,
            password: payload.password,
            phone: payload.phone,
            first_name: payload.first_name,
            last_name: payload.last_name,
            admin: payload.admin,
            role_id: parseInt(payload.role_id),
            status: parseInt(payload.status),
            user_type: parseInt(payload.user_type),
            partners: payload.partners
        })
      })
      
      return res
    })

    return response
}

export default fetchAddUser