import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import { ConfirmSendForm, ConfirmSendFormEON } from '../../components/'
import arrayMutators from 'final-form-arrays'



const Wizard = ({ children, onSubmit, initialValues, setValues2, sensorsConnected, childName }) => {
    const classes = mainStyleClasses()

    const [values, setValues] = useState(initialValues);
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);

    console.log({values})

    const next = values => {
        setPage(Math.min(page + 1, children.length - 1))
        setValues(values)
        let isAlmostLastPage = (Math.min(page + 1, children.length - 1) >= React.Children.count(children) - 2)
        setValues2({type: "isCommentsPage", value: isAlmostLastPage})
    }

    const previous = () => {
        setPage(Math.max(page - 1, 0))
        let isAlmostLastPage = (Math.max(page - 1, 0) >= React.Children.count(children) - 2)
        setValues2({type: "isCommentsPage", value: isAlmostLastPage})
    }

    const validate = values => {
        const activePage = React.Children.toArray(children)[
          page
        ]
        
        return activePage.props.validate ? activePage.props.validate(values) : {}
    }

    const handleSubmit = values => {
        const isLastPage = page === React.Children.count(children) - 1
        if (isLastPage) {
          return onSubmit(values)
        } else if (childName === "SensorConnectionsEON" & !sensorsConnected) {
          setOpen(true)
        } else {
          next(values)
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
      next(values);
      setOpen(false);
  };

    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    //const isAlmostLastPage = page === React.Children.count(children) - 2

    return (
      <Form
        initialValues={values}
        validate={validate}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators
        }}
      >
        {({ handleSubmit, submitting, pristine, values, form }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            {isLastPage && (
                values.inspection ? <ConfirmSendFormEON values={values} setValues={setValues2} /> :
                  <ConfirmSendForm values={values} />
            )}
            <div className="buttons">
              {page > 0 && (
                <Button className={`${classes.floatLeft} ${classes.buttonMargin}`} variant="contained" color="primary" onClick={previous}> « Cofnij </Button>
              )}
              {!isLastPage && <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" type="submit"> Dalej » </Button>}
              {/*values.userInstruct && !isLastPage? <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" type="submit" onClick={() => form.change('status', 'filled')} disabled={submitting}>Zatwierdź</Button> : null*/}
              {isLastPage && (
                <React.Fragment>
                  <Button className={`${classes.floatRight} ${classes.buttonMargin}`} style={{marginLeft: "15px"}} variant="contained" color="primary" type="submit" onClick={() => form.change('status', 'filled')} disabled={submitting}>Zatwierdź</Button>
                  <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" type="submit" onClick={() => form.change('status', 'new')} disabled={submitting}> Zapisz </Button>
                </React.Fragment>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Czy na pewno nie podłączono żadnych czujników temperatury?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary"
                    type="submit" onClick={handleNext}>Tak, przejdź dalej</Button>
                  <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary"
                    onClick={handleClose}>Nie, edytuj odpowiedzi</Button>
                </DialogActions>
              </Dialog>
            </div>
          </form>
        )}
      </Form>
    )
}


Wizard.propTypes = {
    onSubmit: PropTypes.func.isRequired
  }

Wizard.Page = ({ children }) => children


export default Wizard;