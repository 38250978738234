import fetchRefreshToken from './fetchRefreshToken'

const fetchDeviceOnlineDate = async (sn) => {
  
    const response = await fetchRefreshToken().then(async newToken => {
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/device_last_online_date/${sn}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${newToken}`
        }
      }).then(response => response)
      
      return res
    })
    
    return response
  
  }


  export default fetchDeviceOnlineDate