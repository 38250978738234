import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Typography, Grid } from '@material-ui/core'

const CommentsEON = ({ setValues }) => {

    useEffect(() => {
        setValues({type: "childName", value: "CommentsEON"})
    }, []);

    return (
        <React.Fragment>
            <Typography variant='h3'>Pozostałe uwagi</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Field
                        name="comments"
                        component={TextField}
                        label=""
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default CommentsEON;