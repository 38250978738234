import React, { useState } from 'react'
import { Grid, TableCell, TableRow, Button, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../../../theme'
import { default as paramsService } from '../../../../dictionary/serviceNoteDictionary'
import { AddServiceNote } from '../../../../components'
import { dateConvert } from '../../../../functions'

const ListItem = ({ item, index, role, changeServiceNotesList, user, accessScada }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <TableRow style={{background: `${item.hidden? '#e0e0e0' : '#fff'}`}}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{paramsService.serviceNoteStatus[item.note_status_id]}</TableCell>
                <TableCell>{paramsService.serviceNoteType[item.note_type_id]}</TableCell>
                <TableCell>Zgłoszono: {dateConvert(item.date).toLocaleString()} <br /><br /> Modyfikacja: {dateConvert(item.modification_date).toLocaleString()}</TableCell>
                <TableCell>{item.hidden? <p><strong>NOTATKA NIEWIDOCZNA</strong></p> : null}<br />{item.subject}</TableCell>
                <TableCell>{item.device.device_sn} <br />{item.device.key} </TableCell>
                <TableCell>{item.customer.post_code}, {item.customer.city}</TableCell>
                <TableCell>{item.method_of_notification !== 0? paramsService.serviceNoteMethod[item.method_of_notification]:" brak informacji"}</TableCell>
                {role === "partner" && accessScada && accessScada.partner_id !== 1? <TableCell><Button onClick={handleClickOpen} variant="contained" color="primary">Zgłoszenie</Button><br /><br /><Link to={{pathname: `/deviceservice/${item.device.key}/${item.device.device_sn ? item.device.device_sn : "brak-nr-seryjnego"}`, state: { deviceState: item }}}>
                <Button variant="contained" color="primary">Urządzenie</Button></Link></TableCell>
                :<TableCell><Button onClick={handleClickOpen} variant="contained" color="primary">Zgłoszenie</Button><br /><br /><Link to={`/szczegoly/${item.device.key}/${item.device.device_sn}`}><Button variant="contained" color="primary">Urządzenie</Button></Link></TableCell>
                }
                
            </TableRow>
            <Dialog fullWidth={true}
                maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight}>
                        <CloseIcon />
                    </IconButton>
                    <AddServiceNote accessScada={accessScada} partner_id={item.device.partner_id} user={user} deviceSerial={item.device.device_sn} keyM={item.device.key} perm={role} handleClose={handleClose} action={"PUT"} note={item} customerId={item.customer_id} deviceId={item.device_id} changeServiceNotesList={changeServiceNotesList} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ListItem