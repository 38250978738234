import React, { useState, useEffect } from 'react'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { Slider } from '@mui/material'
import { TimeHeatingTemp } from './components'
import Back from '../../../../img/powrot.svg'
import Air from '../../../../img/ekran-powietrze.svg'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'

const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function valuetext(value) {
    return `${value}°C`;
}

const marks = () => {
    const marksTable = []

    for (let i = 0; i <= 24; i++) {
        marksTable.push({ value: i, label: `${i}` })
    }

    return marksTable
}

const minDistance = 1

const DayTab = ({ day, value, index, settings, stamp, setPublish, setTouchStatus, ...props }) => {
    const theme = useTheme()
    //aktualizacja wartości na suwaku
    const [value1, setValue1] = useState([settings[`TW${stamp}2P_PG`], settings[`TW${stamp}2P_KG`] === 23 && settings[`TW${stamp}2P_KM`] === 60 ? 24 : settings[`TW${stamp}2P_KG`]])
    //aktualizacja koloru poszczególnych części
    const [trackColors, setTrackColors] = useState({ start: (settings[`TW${stamp}2P_PG`] / 24) * 100, stop: (settings[`TW${stamp}2P_KG`] / 24) * 100})

    const handleChange1 = (event, newValue, activeThumb) => {
        setTouchStatus(true)
        if (!Array.isArray(newValue)) {
            return
        }

        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance) === 0 ? 1 : Math.min(newValue[0], value1[1] - minDistance), value1[1]])
            setTrackColors({start: ((Math.min(newValue[0], value1[1] - minDistance) === 0 ? 1 : Math.min(newValue[0], value1[1] - minDistance)) / 24) * 100, stop: (value1[1] / 24) * 100})
        } else {
            setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance) === 24 ? 23 : Math.max(newValue[1], value1[0] + minDistance)])
            setTrackColors({start: (value1[0] / 24) * 100, stop: ((Math.max(newValue[1], value1[0] + minDistance) === 24 ? 23 : Math.max(newValue[1], value1[0] + minDistance)) / 24) * 100})
        }
    }

    const handleTouchMouseEnd = (event, newValue) => {
        setTouchStatus(false)
        if(newValue[1] === 24) {
            setPublish({ 
                [`TW${stamp}1P_KG`]: newValue[0] === 0 ? 1 : newValue[0],
                [`TW${stamp}2P_PG`]: newValue[0] === 0 ? 1 : newValue[0],
                [`TW${stamp}2P_KG`]: 23, 
                [`TW${stamp}2P_KM`]: 0, 
                [`TW${stamp}3P_PG`]: 23, 
                [`TW${stamp}3P_PM`]: 0,
            })
        } else {  
            setPublish({ 
                [`TW${stamp}1P_KG`]: newValue[0] === 0 ? 1 : newValue[0],
                [`TW${stamp}2P_PG`]: newValue[0] === 0 ? 1 : newValue[0],
                [`TW${stamp}2P_KG`]: newValue[1] === 24 ? 23 : newValue[1],
                [`TW${stamp}3P_PG`]: newValue[1] === 24 ? 23 : newValue[1],
                [`TW${stamp}2P_KM`]: 0, 
                [`TW${stamp}3P_PM`]: 0, 
            })
        }
      
    }


    useEffect(() => {
        let mounted = true
        setValue1([settings[`TW${stamp}2P_PG`], settings[`TW${stamp}2P_KG`] === 23 && settings[`TW${stamp}2P_KM`] === 60 ? 24 : settings[`TW${stamp}2P_KG`]])
        setTrackColors({ start: (settings[`TW${stamp}2P_PG`] / 24) * 100, stop: (settings[`TW${stamp}2P_KG`] / 24) * 100})
        return () => {
            mounted = false
        }
    }, [settings, stamp])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...props}>
            {value === index && (<React.Fragment>
                <div style={{ padding: '120px 50px 0' }}>
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={value1}
                        onChange={handleChange1}
                        onChangeCommitted={handleTouchMouseEnd}
                        getAriaValueText={valuetext}
                        max={24}
                        min={0}
                        marks={marks()}
                        valueLabelDisplay="on"
                        track="inverted"
                        sx={{
                            '& .MuiSlider-track': {
                                background: `${theme.tempcolors[`color${settings.temp[`TW${stamp}2T`] <= 18? 18 : settings.temp[`TW${stamp}2T`] >= 25? 25 : settings.temp[`TW${stamp}2T`]}`]}`,
                            },
                            '& .MuiSlider-rail': {
                                background: `linear-gradient(90deg, 
                                    ${theme.tempcolors[`color${settings.temp[`TW${stamp}1T`] <= 18? 18 : settings.temp[`TW${stamp}1T`] >= 25? 25 : settings.temp[`TW${stamp}1T`]}`]} 0%, 
                                    ${theme.tempcolors[`color${settings.temp[`TW${stamp}1T`] <= 18? 18 : settings.temp[`TW${stamp}1T`] >= 25? 25 : settings.temp[`TW${stamp}1T`]}`]} ${trackColors.start}%, 
                                    ${theme.tempcolors[`color${settings.temp[`TW${stamp}2T`] <= 18? 18 : settings.temp[`TW${stamp}2T`] >= 25? 25 : settings.temp[`TW${stamp}2T`]}`]} ${trackColors.start}%, 
                                    ${theme.tempcolors[`color${settings.temp[`TW${stamp}3T`] <= 18? 18 : settings.temp[`TW${stamp}3T`] >= 25? 25 : settings.temp[`TW${stamp}3T`]}`]} ${trackColors.stop}%)`,
                            },
                          }}
                    />
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <TimeHeatingTemp settings={settings.temp} stamp={stamp} setPublish={setPublish} />
                    </Grid>
                </Grid>
            </React.Fragment>)}
        </div>
    )
}

const TimeHeating = ({ settings, setPublish, setTouchStatus, url }) => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <React.Fragment>
            <Grid container className='modes'>
                <Grid item xs={2}>
                    <Link to={`${url}/timemode/timecwu`}><img alt="Temperatury zadane powietrza w ciągu doby" className='small-icon' style={{margin: '20px auto', maxHeight: "58px"}} src={Air} /></Link>
                </Grid>
                <Grid item xs={10}>

                    <Grid container>
                        <Grid item xs={12}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab label="PN" {...a11yProps(0)} />
                                <Tab label="WT" {...a11yProps(1)} />
                                <Tab label="ŚR" {...a11yProps(2)} />
                                <Tab label="CZ" {...a11yProps(3)} />
                                <Tab label="PT" {...a11yProps(4)} />
                                <Tab label="SB" {...a11yProps(5)} />
                                <Tab label="ND" {...a11yProps(6)} />
                            </Tabs>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '30px' }}>
                        <Grid item xs={10}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>Temperatury zadane powietrza w ciągu doby</Typography>
                            <div className='back-btn'><Link to={`${url}`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={0} day={'poniedziałek'} settings={settings.P} stamp={'P'} setPublish={setPublish} />
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={1} day={'wtorek'} settings={settings.W} stamp={'W'} setPublish={setPublish} />
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={2} day={'środa'} settings={settings.S} stamp={'S'} setPublish={setPublish} />
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={3} day={'czwartek'} settings={settings.C} stamp={'C'} setPublish={setPublish} />
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={4} day={'piątek'} settings={settings.PT} stamp={'PT'} setPublish={setPublish} />
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={5} day={'sobota'} settings={settings.SO} stamp={'SO'} setPublish={setPublish} />
                    <DayTab value={value} setTouchStatus={setTouchStatus} index={6} day={'niedziela'} settings={settings.N} stamp={'N'} setPublish={setPublish} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default TimeHeating