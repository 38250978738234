import React, { useRef, useState, useEffect, useReducer } from 'react'

// import './App.css';
import { Container, Grid } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router-dom";
import fetchCustomer from '../../data/fetchCustomer'
import fetchDevice from '../../data/fetchDevice'

import { Home, Wizzard, Errors, Statistics, Antylegionella, SetDateTime, Parameters, Login, Mode, Blockade, Analysis,
  DevicesPeriphery, Outdoor, Identyfication, EmergencyStop, Extenders } from './pages'
import { ControlHysteresisTest, CoopInstallation, ParameterOptimization, SelectionFunction, DateTimeSettings,
  DeletionErrors, DeletionStatistics, DistributionCwuCo, FactorySettings, GlycolInSystem, SensorInside, ControlType } from './pages/Wizzard/pages'
import { AsideMenu, DefaultValueSet } from './components'

import { ThemeProvider } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop';
import { mainStyleTheme } from './theme'

import { mqttConfig } from "../../config/mqttConfig";
import { mqttClient } from './config/mqttClient'
import { functionPublish } from './config/functionPublish'

import { registerValue } from './data/dataFilter'
import { dataRange } from './data/dataRange'
import { ContactlessOutlined } from '@material-ui/icons';

// const started = async() => {
//   await fetch("started.txt").then(response => response).then(() => console.log("started data"))
// }
// const params = new URLSearchParams(window.location.search)
// const mqttHost = params.get("mqtt")

// const config = window.configValue

const switchOption = (state, action) => {
      return { ...state, [action.type]: action.payload };
}

// setInterval(() => {
//   console.log("DZIAŁA") 
// }, 3000);

function EurosMonitor({ token }) {
  let { installation, pass } = useParams();
  // let location = useLocation();
  // const { sn, token, installation_id, telemetry } = location.state ? location.state : JSON.parse(localStorage.getItem('deviceInfo'))
  const [deviceDetails, setDeviceDetails] = useState({ device_sn: "", installation_id: "", last_telemetry_update: "" })
  const [parameters, setParameters] = useReducer(switchOption, null)
  const [connectionStatus, setConnectionStatus] = useState(false)
  const [messages, setMessages] = useState(null)
  const [publishMsg, setPublish] = useState(null)
  const [publishDevice, setPublishDevice] = useState(null)
  const [countWizzard, setCountWizzard] = useState(0)
  const [login, setLogin] = useState(null)
  const clientRef = useRef(null)
  const errorRef = useRef(null)
  const [mesg, setMesg] = useState(null);
  const [defaultValue, setDefault] = useState(false);
  const [errorOfExpiration, seterrorOfExpiration] = useState(null);
  const [tokenStatus, setTokenStatus] = useState([]);
//   const [startedtxt, setStarted] = useState(0);
  const [messagesHelp, setMessagesHelp] = useState(null);
  const [touchStatus, setTouchStatus] = useState(false);
  const [customer, setCustomer] = useState({ first_name: "", last_name: "", phone: "", street: "", street_number: "", post_code: "", city: "" })
  const [backdropOpen, setBackdropOpen] = useState(true);
  const [intervalTimeId, setIntervalTimeId] = useState(null)
  const [ connectionTimer, setConnectionTimer ] = useState(0)
  const [ intervalId, setIntervalId] = useState(null)
  const [ counterOn, setCounterOn ] = useState(null)

  const connectionTimerRef = useRef(connectionTimer);
  connectionTimerRef.current = connectionTimer; 

  sessionStorage.setItem('deviceName', messages ? messages.device.Name : null)
  sessionStorage.setItem('version_1', messages ? messages.iconInfo.VER_S1 : null) 
  sessionStorage.setItem('version_2', messages ? messages.iconInfo.VER_S2 > 99 ? messages.iconInfo.VER_S1.toString().slice(0, 2) : messages.iconInfo.VER_S2 : null)
  sessionStorage.setItem('isExt', messages ? messages.MAG_C_CH : null) 

  // console.log(messages) 
  // if (messages && messages.extenders) { 
  //   for (let i = 0; i < Object.keys(messages.extenders).length; i++) { 
  //     sessionStorage.setItem(`extDeviceName${i+1}`, messages.extenders[`extender_${i+1}`].extDevice.Name) 
  //   } 
  // } 

  // localStorage.setItem('deviceInfo', location.state ? JSON.stringify(location.state) : JSON.stringify({ sn: sn, token: token, installation_id: installation_id, telemetry: telemetry }))

  //console.log("FUNC: App.js" + client.toString())

  // const changeTokenStatus = (token) => {
  //   let newTokenStatus = tokenStatus.slice()
  //   let time = Date.now()

  //   newTokenStatus.push({
  //     token: token,
  //     dateTime: time,
  //   })

  //   setTokenStatus(newTokenStatus)
  // }

  // const saveMessageFunc = (message, tokenStat, touch) => {
  //   let saveToState
  //   let DateTime = new Date()
  //   let StampTime = new Date()
  //   DateTime = Date.now()

  //   StampTime = Date.parse(JSON.parse(message).TimeStamp)
  //   if (DateTime - StampTime > JSON.parse(message).ExpirationTime + 3000) {
  //     seterrorOfExpiration(1)
  //   } else {
  //     seterrorOfExpiration(null)
  //     saveToState = registerValue(JSON.parse(message).Devices)
  //     saveToState.expirationData = {
  //       ExpirationTime: JSON.parse(message).ExpirationTime,
  //       TimeStamp: JSON.parse(message).TimeStamp,
  //     }
  //     if (!touch) {
  //       let newTokenStatus = []
  //       if (tokenStat && tokenStat.length > 0) {
  //         for (let index = 0; index < tokenStat.length; index++) {
  //           if ((DateTime - tokenStat[index].dateTime < JSON.parse(message).ExpirationTime + 3000) && (`${tokenStat[index].token}` !== JSON.parse(message).Token)) {
  //             newTokenStatus.push(tokenStat[index])
  //           }
  //         }
  //         setTokenStatus(newTokenStatus)
  //       }
  //       setMessages(saveToState)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   let mounted = true
  //   if (messagesHelp)
  //     saveMessageFunc(messagesHelp, tokenStatus, touchStatus)

  //   return () => {
  //     mounted = false
  //   }
  // }, [messagesHelp])


  // useEffect(() => {
  //   let mounted = true;
  //   if (publishMsg && messages.device) {
  //     functionPublish(
  //       clientRef.current,
  //       publishMsg,
  //       installation,
  //       messages.device,
  //       changeTokenStatus
  //     );
  //     setPublish(null);
  //   }
  //   return function cleanup() {
  //     mounted = false;
  //   };
  // }, [publishMsg, installation]);

  // useEffect(() => {
  //   let saveToState;
  //   clientRef.current = mqttClient.getClient(mqttConfig());

  //   clientRef.current.subscribe(`${installation}/Dev`)
  //   clientRef.current.subscribe(`${installation}/wifi`)
  //   clientRef.current.subscribe(`${installation}/Diagnostics/Agent`)
  //   clientRef.current.on("connect", () => setConnectionStatus(true));
  //   clientRef.current.on(
  //     "message",
  //     function (topic, message) {
  //       console.log(topic)
  //       console.log(JSON.parse(message))
  //       if (topic === `${installation}/Dev` && JSON.parse(message).Devices) {
  //         setMessagesHelp(message)
  //       }
  //       // if (startedtxt === 0) {
  //       //   setStarted(1);
  //       //   setTimeout(() => {
  //       //     clientRef.current.publish(`${installation}/start`, JSON.stringify({version: '1.0'}), {retain: false})
  //       //     started();
  //       //   }, parseInt(installation));
  //       // }
  //     },
  //     [tokenStatus]
  //   );
  //   return () => {
  //     if (clientRef.current) {
  //       clientRef.current.unsubscribe(`${installation}/Diagnostics/Agent`);
  //       clientRef.current.unsubscribe(`${installation}/wifi`);
  //       clientRef.current.unsubscribe(`${installation}/Dev`);
  //       clientRef.current.end(clientRef.current);
  //     }
  //   };
  // }, []);
  // console.log(messages ? messages.expirationData.TimeStamp : null)

  const connectionCounter = () => {
    let countdown = intervalId
    
    setConnectionTimer(63)
    
    if (intervalId === null) {
      countdown = setInterval(() => {
        setConnectionTimer(current => current - 3)
        if (connectionTimerRef.current <= 0) {
          seterrorOfExpiration(1)
          clearInterval(countdown)
          setIntervalId(null)
          setCounterOn(false)
        }
      }, 3000)
    } 
    if (counterOn === false) {
      seterrorOfExpiration(null)
      setCounterOn(true)
    }
    setIntervalId(countdown)
  }

  useEffect(() => {
    let mounted = true;
    
    connectionCounter()
    
    return () => {
      let mounted = false;
    }
  }, [messages])

  useEffect(() => {
    if (deviceDetails && deviceDetails.last_telemetry_update) {
      if (Date.now() - Date.parse(deviceDetails.last_telemetry_update) > 63000) {
        seterrorOfExpiration(1)
      }
    }
 
  }, [deviceDetails])

//   const StampTimeRef = useRef(0);
//   StampTimeRef.current = Date.parse(messages ? messages.expirationData.TimeStamp : null); 

//   const DateTimeRef = useRef(0);
//   DateTimeRef.current = Date.now()

//   const connectionState = () => {
//     const connection = setInterval(() => {
//         // let DateTime = Date.now() 
//         // let StampTime = Date.parse(messages.expirationData.TimeStamp)
//         const ExpirationTime = messages.expirationData.ExpirationTime

//           // console.log(DateTimeRef.current - StampTimeRef.current)
//           if (DateTimeRef.current - StampTimeRef.current > ExpirationTime + 3000) { 
//             seterrorOfExpiration(1) 
//           } else { 
//             seterrorOfExpiration(null) 
//           }
//       }, 5000)
//   setIntervalTimeId(connection) 
// }


  
  
  // useEffect(() => {
  //   setInterval(() => {

  //     clientRef.current = mqttClient.getClient(mqttConfig());
  //     clientRef.current.subscribe(`${installation}/Dev`)
  //     clientRef.current.subscribe(`${installation}/Diagnostics/Agent`)
  //     clientRef.current.on('connect', () => setConnectionStatus(true))
  //     clientRef.current.on('message', function (topic, message, packet) {
        
  //       if (topic === `${installation}/Dev` && JSON.parse(message).Devices) {
  //         let DateTime = new Date() 
  //         let StampTime = new Date() 
  //         DateTime = Date.now() 
       
  //         StampTime = Date.parse(JSON.parse(message).TimeStamp) 
  //         if (DateTime - StampTime > JSON.parse(message).ExpirationTime + 3000) { 
  //           seterrorOfExpiration(1) 
  //         } else { 
  //           seterrorOfExpiration(null) 
  //         } 
  //         let saveToState = registerValue(JSON.parse(message).Devices)
  //         saveToState.expirationData = {
  //           ExpirationTime: JSON.parse(message).ExpirationTime,
  //           TimeStamp: JSON.parse(message).TimeStamp
  //         }
  
  //         setMessages(saveToState)
  //         console.log({dane: JSON.parse(message)})
  //       }
  
  //       if (topic === `${installation}/Diagnostics/Agent`) {
          
  //         console.log({alarmy: JSON.parse(message)})
  //       }
  //     })
      
  //     return () => {
  //       if (clientRef.current) {
  //         clientRef.current.unsubscribe(`${installation}/Diagnostics/Agent`);
  //         clientRef.current.unsubscribe(`${installation}/Dev`);
  //         clientRef.current.end(clientRef.current);
  //       }
  //     }

  //   }, 63000);
  // }, [])

  useEffect(() => {
    let mounted = true
    if (publishMsg && messages.device) {
      functionPublish(clientRef.current, publishMsg, installation, messages.device, publishDevice, setPublishDevice)
      setPublish(null)
    }
    return function cleanup() {
      mounted = false
    }
  }, [publishMsg, installation])

  useEffect(() => {
    
    clientRef.current = mqttClient.getClient(mqttConfig());
    clientRef.current.subscribe(`${installation}/Dev`)
    clientRef.current.subscribe(`${installation}/Diagnostics/Agent`)
    clientRef.current.on('connect', () => setConnectionStatus(true))
    clientRef.current.on('message', function (topic, message, packet) {
      
      if (topic === `${installation}/Dev` && JSON.parse(message).Devices) {
        let DateTime = new Date() 
        let StampTime = new Date() 
        DateTime = Date.now() 
     
        // StampTime = Date.parse(JSON.parse(message).TimeStamp) 
        // if (DateTime - StampTime > JSON.parse(message).ExpirationTime + 3000) { 
        //   seterrorOfExpiration(1) 
        // } else { 
        //   seterrorOfExpiration(null) 
        // } 
        let saveToState = registerValue(JSON.parse(message).Devices)
        saveToState.expirationData = {
          ExpirationTime: JSON.parse(message).ExpirationTime,
          TimeStamp: JSON.parse(message).TimeStamp
        }

        setMessages(saveToState)
        console.log({dane: JSON.parse(message)})
      }

      if (topic === `${installation}/Diagnostics/Agent`) {
        
        console.log({alarmy: JSON.parse(message)})
      }
    })
    
    return () => {
      if (clientRef.current) {
        clientRef.current.unsubscribe(`${installation}/Diagnostics/Agent`);
        clientRef.current.unsubscribe(`${installation}/Dev`);
        clientRef.current.end(clientRef.current);
      }
    }

  }, [messagesHelp])

  useEffect(() => {
    let mounted = true
    if (installation) {
        fetchDevice(token, installation)
            .then(response => {
                if (mounted)
                    setDeviceDetails(response)
            })
    }
    return function cleanup() {
        mounted = false
    }
  }, [token, installation])

  useEffect(() => {
    if (deviceDetails.installation_id) {
        fetchCustomer(token, deviceDetails.installation_id)
            .then(response => {
                setCustomer(response)
            })
    }
  }, [deviceDetails, deviceDetails.installation_id, token])

  let { url } = useRouteMatch()
  
  return (
    <React.Fragment>
        <Grid container className='infobox' justifyContent="center" style={{ maxWidth: "1024px", margin: "auto", marginBottom: "30px" }}>
            <Grid item xs={4}>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{deviceDetails.device_sn}</Typography>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.first_name} {customer.last_name}</Typography>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.phone}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{installation}</Typography>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>ul. {customer.street} {customer.street_number}</Typography>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.post_code}, {customer.city}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>Ostatnio online: </Typography>
                <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{deviceDetails && deviceDetails.last_telemetry_update ? deviceDetails.last_telemetry_update.slice(0, 19).replace("T", " ") : ""}</Typography>
                {errorOfExpiration === 1 || !messages ? 
                  <Typography>Offline</Typography>
                  : <Typography>Online</Typography>
                }
            </Grid>
        </Grid>
        <div className="euros-monitor">
            {messages && !defaultValue ? (
                <DefaultValueSet
                setDefault={setDefault}
                defaultValue={defaultValue}
                settings={messages ? messages.defaultdata : null}
                setPublish={setPublish}
                />
            ) : null}
            <Router>
                <ThemeProvider theme={mainStyleTheme}>
                {/*{(messages && messages.errors && messages.errors.Errors >= 3) ||
                errorOfExpiration === 1 ||
                !messages ?
                <Backdrop
                    style={{ color: '#fff', zIndex: 99 }}
                    open={true}
                    >
                    <Typography style={{ fontSize: '200px', opacity: '50%', color: 'black', fontWeight: 'bold' }}>NO DATA</Typography>
                </Backdrop>
                : null
                }*/}
                {/*(messages && messages.errors && messages.errors.Errors >= 3) ||
                  errorOfExpiration === 1 ||
                  !messages ?
                    backdropOpen === true ? 
                      <Backdrop
                          style={{ color: '#fff', zIndex: 99 }}
                          open={true}
                          onClick={() => setBackdropOpen(false)}
                        >
                        <Typography style={{ fontSize: '200px', opacity: '50%', color: 'black', fontWeight: 'bold' }}>NO DATA</Typography>
                      </Backdrop>
                      : null
                    : null
                */}
                <Container style={{ padding: '0px' }} maxWidth="lg">
                    <React.Fragment>
                        <Grid container>
                            <Grid item md={1} xs={2}>
                                {messages ? (
                                    <AsideMenu
                                        iconInfo={messages ? messages.iconInfo : null}
                                        deviceType={messages ? messages.device.Name : null}
                                        url={url}
                                        locState={deviceDetails}
                                        extenders={messages ? messages.extenders : null}
                                    />
                                ) : null}
                            </Grid>
                            <Grid item md={11} xs={10}>
                                <Switch>
                                <Route exact path={`${url}/`}>
                                    {messages ? (
                                        <Home
                                            data={messages.iconInfo}
                                            home={messages.home}
                                            setPublish={setPublish}
                                            url={url}
                                        />
                                    ) : null}
                                </Route>
                                {/*<Route exact path="/wizzard">{messages && messages.device.Name === "GEO_1"? (<Wizzard countWizzard={countWizzard} wizzard={messages.wizzard} setPublish={setPublish}>
                                    <FactorySettings settings={messages.wizzard.factorySettings} setPublish={setPublish} />
                                    <DateTimeSettings settings={messages.wizzard.dateTimeSettings} setPublish={setPublish} />
                                    <DeletionErrors settings={messages.wizzard.deletionErrors} setPublish={setPublish} />
                                    <DeletionStatistics settings={messages.wizzard.deletionStatistics} setPublish={setPublish} />
                                    <SensorInside settings={messages.wizzard.sensorInside} setPublish={setPublish} setParameters={setParameters} parameters={parameters} />
                                    <ControlType settings={messages.wizzard.controlType} setPublish={setPublish} setParameters={setParameters} parameters={parameters} />
                                    <ParameterOptimization settings={messages.wizzard.parameterOptimization} setPublish={setPublish} setParameters={setParameters} parameters={parameters} />
                                    <GlycolInSystem device={messages.device.Name} settings={messages.wizzard.glycolInSystem} setPublish={setPublish} />
                                    <ControlHysteresisTest settings={messages.wizzard.controlHysteresisTest} setPublish={setPublish} parameters={parameters} />
                                    <SelectionFunction device={messages.device.Name} settings={messages.wizzard.selectionFunction} setPublish={setPublish} />
                                    </Wizzard>)
                                    :messages && messages.device.Name === ("ATMO_1" || "ALLINONE_1")? (<Wizzard countWizzard={countWizzard} wizzard={messages.wizzard} setPublish={setPublish}>
                                    <FactorySettings settings={messages.wizzard.factorySettings} setPublish={setPublish} />
                                    <DateTimeSettings settings={messages.wizzard.dateTimeSettings} setPublish={setPublish} />
                                    <DeletionErrors settings={messages.wizzard.deletionErrors} setPublish={setPublish} />
                                    <DeletionStatistics settings={messages.wizzard.deletionStatistics} setPublish={setPublish} />
                                    <SensorInside settings={messages.wizzard.sensorInside} setPublish={setPublish} setParameters={setParameters} parameters={parameters} />
                                    <ControlType settings={messages.wizzard.controlType} setPublish={setPublish} setParameters={setParameters} parameters={parameters} />
                                    <ParameterOptimization settings={messages.wizzard.parameterOptimization} setPublish={setPublish} setParameters={setParameters} parameters={parameters} />
                                    <GlycolInSystem device={messages.device.Name} settings={messages.wizzard.glycolInSystem} setPublish={setPublish} />
                                    <ControlHysteresisTest settings={messages.wizzard.controlHysteresisTest} setPublish={setPublish} parameters={parameters} />
                                    <SelectionFunction device={messages.device.Name} settings={messages.wizzard.selectionFunction} setPublish={setPublish} />
                                    </Wizzard>): null}</Route>*/}
                                    <Route path={`${url}/errors`}>{messages? (<Errors settings={messages.wizzard.deletionErrors} setPublish={setPublish} deviceName={messages.device.Name} />):null}</Route>
                                    <Route path={`${url}/emergencystop`}>{messages? (<EmergencyStop settings={messages.emergencyStop} setPublish={setPublish} deviceName={messages.device.Name} />):null}</Route>
                                    <Route path={`${url}/statistics`}>{messages? (<Statistics settings={messages.wizzard.deletionStatistics} setPublish={setPublish} />):null}</Route>
                                    <Route exact path={`${url}/antylegionella`}>{messages? (<Antylegionella settings={messages.antylegionella} antyException={messages.antyException} setPublish={setPublish} />):null}</Route>
                                    <Route exact path={`${url}/datetime`}>{messages? (<SetDateTime settings={messages.wizzard.dateTimeSettings} setPublish={setPublish} />):null}</Route>
                                    <Route path={`${url}/parameters`}>{messages? (<Parameters settings={{heatingCurve: messages.heatingCurve, coolingCurve: messages.coolingCurve, factorySetting: messages.wizzard.factorySettings}} setPublish={setPublish} />):null}</Route>
                                    <Route path={`${url}/modes`}>{messages? (<Mode setPublish={setPublish} settings={{away: messages.away, eco: messages.eco, time: messages.time}} />):null}</Route>
                                    <Route path={`${url}/blockade`}>{messages? (<Blockade setPublish={setPublish} settings={messages.blockade} />):null}</Route>
                                    <Route path={`${url}/analysis`}>{messages? (<Analysis setPublish={setPublish} settings={messages.analysis} />):null}</Route>
                                    <Route path={`${url}/periphery`}>{messages? (<DevicesPeriphery setPublish={setPublish} settings={messages.periphery} />):null}</Route>
                                    <Route path={`${url}/outdoor`}>{messages? (<Outdoor setPublish={setPublish} settings={messages.outdoor} />):null}</Route>
                                    <Route path={`${url}/identyfication`}>{messages ? (<Identyfication setPublish={setPublish} settings={{controller: messages.identyfication.controller, configuration: messages.identyfication.configuration, characteristics: messages.identyfication.characteristics}} />) : null}</Route>
                                    <Route path={`${url}/extenders`}>{messages? (<Extenders setPublish={setPublish} settings={messages.extenders} setPublishDevice={setPublishDevice} />):null}</Route> 
                                </Switch>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </Container>
                </ThemeProvider>
            </Router>
        </div>
    </React.Fragment>
  );
}

export default EurosMonitor;
