import React from 'react'

const AwayMode = () => {
    return (
        <React.Fragment>
            Tryb AWAY
        </React.Fragment>
    )
}

export default AwayMode