import React, { useReducer, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { TitleRow } from '../../components'

import { SwitchEco } from './components'

const EcoModeSettings = ({ eco, setPublish }) => {

    const switchOption = (state, action) => {
        if( action.case === "UPDATE_FROM_BROKER"){
            return ( action.payload )
        } else {
        return { ...state, [action.type]: action.payload };
        }
    }

    const [secondSchedule, setEcoSchedule] = useReducer(switchOption, eco)

    useEffect(() => {
        setEcoSchedule({ payload: eco, case: "UPDATE_FROM_BROKER" })
}, [eco])

    const handleEcoSchedule = (e) => {
        
        const sendKey = e.target.name
        const sendData = e.target.value

        setEcoSchedule({ type: e.target.name, payload: parseInt(e.target.value) })
        setPublish({ [sendKey]: sendData })
    }


     const tableEcoMode = Object.entries(eco).map(entry => (
        <SwitchEco handleEcoSchedule={handleEcoSchedule} eco={secondSchedule} stamp={entry[0]} key={entry[0]} />
        ));

    return (
        <Container>
            <TitleRow title="Nastawy trybu ECO" />
            {tableEcoMode}
        </Container>
    );
}

export default EcoModeSettings;