import React, { useReducer, useEffect, useState } from 'react'
import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { registerDictionary } from '../../../../data/dataDictionary'
import { dataRange } from '../../../../data/dataRange'
import { CarouselInputMulti } from '../../../../components'
import { ItemMapping } from '../../../../components'

const AwayMode = ({ away, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(away)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0 })

    const dictionary = registerDictionary()

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

 
    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    useEffect(() => {
        let mounted = true
        setItemValue(away)
        return () => {
            mounted = false
        }
    }, [away])
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">Tryb AWAY</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.away.controllCryteria.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.controllCryteria, handleSwitchValue, handleClickOpen, dictionary.away.controllCryteria.data, classes, filteredItems(itemValue.controllCryteria), 'controllCryteria')}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.away.dawayControll.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.dawayControll, handleSwitchValue, handleClickOpen, dictionary.away.dawayControll.data, classes, filteredItems(itemValue.dawayControll), 'dawayControll')}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default AwayMode