import React, { useReducer,useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { descriptionDictionary } from '../../dataFilter/descriptionDictionary';
import { dataRangeDictionary } from '../../dataFilter/dataRangeDictionary';
import { TitleRow } from '../../components';

import {TemperatureControllDynamicMin, TemperatureControllDynamicMax, TemperatureControllDynamic} from './components';

const InternalTemperatureControl = ({ itc, setPublish }) => {
    
    
    const switchOption = (state, action) => {
        if( action.case === "UPDATE_FROM_BROKER"){
            return ( action.payload )
        } else {
        return { ...state, [action.type]: parseFloat(action.payload) };
        }
    }

    const [rangeValue, setTemperatureControl] = useReducer(switchOption, itc)

    useEffect(() => {
        setTemperatureControl({ payload: itc, case: "UPDATE_FROM_BROKER" })
}, [itc])
    

const handleTemperatureControl = (e) => {
    const sendKey = e.target.name;
    const sendData = e.target.value;

    setPublish({ [sendKey]: sendData })
    
}

const handleSetTemperatureControl = (e) => {
    setTemperatureControl({ type: e.target.name, payload: e.target.value });
}

    const itcTable = Object.entries(itc).map(entry => {

        if (dataRangeDictionary.itc[entry[0]].RW === 0) {
            return (
                <Row key={entry[0]}>
                    <Col className="text-left"><p>{descriptionDictionary.itc[entry[0]]}</p></Col>
                    <Col className="text-right" xs={2} md={1}>{rangeValue[entry[0]]}</Col>
                </Row>
            )
        } else {
            let min;
            let max;
            if (typeof dataRangeDictionary.itc[entry[0]].min === 'number') {
                min = dataRangeDictionary.itc[entry[0]].min
            } else {
                min = rangeValue[dataRangeDictionary.itc[entry[0]].min]
            }

            if (typeof dataRangeDictionary.itc[entry[0]].max === 'number') {
                max = dataRangeDictionary.itc[entry[0]].max
            } else {
                max = rangeValue[dataRangeDictionary.itc[entry[0]].max]
            }

            if (dataRangeDictionary.itc[entry[0]].change) {
                switch (dataRangeDictionary.itc[entry[0]].change) {
                    case "min":
                        return (
                            <TemperatureControllDynamicMin handleSetTemperatureControl={handleSetTemperatureControl} handleTemperatureControl={handleTemperatureControl} min={min} max={max} itc={rangeValue} stamp={entry[0]} key={entry[0]} />
                        );
                    case "max":
                        return (
                            <TemperatureControllDynamicMax handleSetTemperatureControl={handleSetTemperatureControl} handleTemperatureControl={handleTemperatureControl} min={min} max={max} itc={rangeValue} stamp={entry[0]} key={entry[0]} />
                        );
                    case "all":
                        return null;
                    default:
                        return null;
                }
            } else {
                return (
                    <TemperatureControllDynamic handleSetTemperatureControl={handleSetTemperatureControl} handleTemperatureControl={handleTemperatureControl} min={min} max={max} itc={rangeValue} stamp={entry[0]} key={entry[0]} />
                )
            }
        }
    }
    );
    return (
        <>
            <Container>
                <TitleRow title="Sterowanie temperaturą wewnętrzną" />
                {itcTable}
            </Container>
        </>
    );
}

export default InternalTemperatureControl;