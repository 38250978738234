export const registerValue = (receivedData) => {
    let data

    if (receivedData !== undefined) {
        const [tabData] = receivedData;
        data = tabData.Data;
        
        let registries = {
            device: {
                Name: tabData.Name,
                Type: tabData.Type,
                Version: tabData.Version,
            },
            errors: {
                Errors: parseInt(tabData.Errors),
            },
            expirationData: {
                ExpirationTime: data.ExpirationTime,
                TimeStamp: data.ExpirationTime,
            },
            main: {
                modes: {
                    AUTO: "wyłączenie pozostałych trybów",
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SXF_ECO: parseInt(data.SXF_ECO),
                    SXF_TIME: parseInt(data.SXF_TIME),
                    SXF_AWA: parseInt(data.SXF_AWA),
                },
                stateDevice: {
                    SBF_HTS: parseInt(data.SBF_HTS),
                    SBF_ACS: parseInt(data.SBF_ACS),
                    SBF_FCS: parseInt(data.SBF_FCS),
                    SBF_PHT: parseInt(data.SBF_PHT),
                    SWEXT_T_CWU: parseInt(data.SWEXT_T_CWU),
                    SXF_ANL: parseInt(data.SXF_ANL),
                    SXF_FLPCYR: parseInt(data.SXF_FLPCYR),
                    REW_ERR: parseInt(data.REW_ERR),
                    REW_WRN: parseInt(data.REW_WRN),
                    SBF_DHS: parseInt(data.SBF_DHS),
                    SBF_DHX: parseInt(data.SBF_DHX),
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SBF_HTX: parseInt(data.SBF_HTX),
                    SBF_ACX: parseInt(data.SBF_ACX),
                    SBF_FCX: parseInt(data.SBF_FCX),
                    SXB_FER: parseInt(data.SXB_FER),
                    SX4_PH: parseInt(data.SX4_PH),
                    SX4_PC: parseInt(data.SX4_PC),
                    MB_COMM2_XZM_TRANSM: parseInt(data.MB_COMM2_XZM_TRANSM),
                    REW_FCS: parseInt(data.REW_FCS),
                    REW_FLO: parseInt(data.REW_FLO), 
                    REW_TER: parseInt(data.REW_TER),
                    REW_SMD: parseInt(data.REW_SMD),
                    SXF_TOE: parseInt(data.SXF_TOE),

                },
                circuit: {
                    temp: {
                        TSM9: parseFloat(data.TSM9),
                        TSM10: parseFloat(data.TSM10),
                        SPHT_ZAD: parseFloat(data.SPHT_ZAD),
                        TSM15: parseFloat(data.TSM15),
                        SPDHW_ZAD: parseFloat(data.SPDHW_ZAD),
                        SBF_HTS: parseInt(data.SBF_HTS),
                        SBF_ACS: parseInt(data.SBF_ACS),
                        SBF_DHS: parseInt(data.SBF_DHS),
                        SBF_DHX: parseInt(data.SBF_DHX),
                        SCHT: parseInt(data.SCHT),
                        SCDHW: parseInt(data.SCDHW),
                        AWAY_CLOCK: parseInt(data.AWAY_CLOCK),
                        SBB_QHW: parseInt(data.SBB_QHW),
                        SBB_QHT: parseInt(data.SBB_QHT),
                        SBF_OOF: parseInt(data.SBF_OOF),
                        SXF_TOE: parseInt(data.SXF_TOE),
                        SBB_QHL: parseInt(data.SBB_QHL),
                        SBF_HTX: parseInt(data.SBF_HTX),
                        SBF_ACX: parseInt(data.SBF_ACX),
                        SWEXT_BUF: parseInt(data.SWEXT_BUF),
                        SXB_F4DP: parseInt(data.SXB_F4DP),
                        SXB_4DR: parseInt(data.SXB_4DR),
                        map: {}
                    }
                },
                scada: {
                    Name: tabData.Name,
                    Type: tabData.Type,
                    MB_COMM2_XZM_TRANSM: parseInt(data.MB_COMM2_XZM_TRANSM),
                    ZM_DIS_TEMP: parseFloat(data.ZM_DIS_TEMP),
                    TSM9: parseFloat(data.TSM9),
                    ZM_SUCT_TEMP: parseFloat(data.ZM_SUCT_TEMP),
                    ZM_FAN1_SPE: parseInt(data.ZM_FAN1_SPE),
                    ZM_FAN2_SPE: parseInt(data.ZM_FAN2_SPE),
                    TSM15: parseFloat(data.TSM15),
                    SIO_R03: parseInt(data.SIO_R03),
                    T6fiz: parseFloat(data.T6fiz),
                    T5fiz: parseFloat(data.T5fiz),
                    SIO_R08: parseInt(data.SIO_R08),
                    SIO_R02: parseInt(data.SIO_R02),
                    SIO_R05: parseInt(data.SIO_R05),
                    ZM_CUR_COMP_FREQ: parseInt(data.ZM_CUR_COMP_FREQ),
                    TSM10: parseFloat(data.TSM10),
                    ZM_OUT_TEMP: parseFloat(data.ZM_OUT_TEMP),
                    ZM_COIL_TEMP: parseFloat(data.ZM_COIL_TEMP),
                    TSM17: parseFloat(data.TSM17),
                    SBB_QHT: parseInt(data.SBB_QHT),
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SXF_TOE: parseInt(data.SXF_TOE),
                    SBB_QHL: parseInt(data.SBB_QHL),
                    SBF_DHS: parseInt(data.SBF_DHS),
                    SBF_DHX: parseInt(data.SBF_DHX),
                    SBB_QHW: parseInt(data.SBB_QHW),
                    PWM_1: parseFloat(data.PWM_1),
                    COP: data.COP,
                    SIO_R01: parseInt(data.SIO_R01),
                    SIO_R09: parseInt(data.SIO_R09),
                    SIO_R10: parseInt(data.SIO_R10),
                    SIO_R11: parseInt(data.SIO_R11),
                    SIO_R06: parseInt(data.SIO_R06),
                    SIO_R07: parseInt(data.SIO_R07),
                    SIO_R04: parseInt(data.SIO_R04),
                    T3fiz: parseFloat(data.T3fiz),
                    T4fiz: parseFloat(data.T4fiz),
                    SBF_FCS: parseInt(data.SBF_FCS),
                    TSM16: parseFloat(data.TSM16),
                    ADC_1: parseFloat(data.ADC_1),
                    ADC_2: parseFloat(data.ADC_2),
                    ADC_3: parseFloat(data.ADC_3),
                    SXB_QSFC: parseInt(data.SXB_QSFC),
                    SWEXT_BUF: parseInt(data.SWEXT_BUF),
                    SXB_PV: parseInt(data.SXB_PV),
                    TSM13: parseFloat(data.TSM13),
                    TSM14: parseFloat(data.TSM14),
                    SSB_QHL: parseInt(data.SSB_QHL),
                },
                statistics: {
                    // allOn: {
                    //     CORSTA_SPRMAX: parseFloat(data.CORSTA_SPRMAX),
                    //     CORSTA_SPRMIN: parseFloat(data.CORSTA_SPRMIN),
                    //     CORSTA_SPSMAX: parseFloat(data.CORSTA_SPSMAX),
                    //     CORSTA_SPSMIN: parseFloat(data.CORSTA_SPSMIN),
                    //     TIME_FST: parseInt(data.TIME_FST),
                    //     TIME_WEEK: parseInt(data.TIME_WEEK),
                    //     CORSTA: parseInt(data.CORSTA),
                    //     SWEXT_ZBCRO: parseInt(data.SWEXT_ZBCRO),
                    // },
                    // SS0: {
                    //     PDHWT1: parseInt(data.PDHWT1),
                    //     SS1_PDHWT1: parseInt(data.SS1_PDHWT1),
                    //     SS2_PDHWT1: parseInt(data.SS2_PDHWT1),
                    //     SS3_PDHWT1: parseInt(data.SS3_PDHWT1),
                    //     SS4_PDHWT1: parseInt(data.SS4_PDHWT1),
                    //     SS5_PDHWT1: parseInt(data.SS5_PDHWT1),
                    //     SS6_PDHWT1: parseInt(data.SS6_PDHWT1),
                    //     SS7_PDHWT1: parseInt(data.SS7_PDHWT1),
                    //     SS8_PDHWT1: parseInt(data.SS8_PDHWT1),
                    //     SS9_PDHWT1: parseInt(data.SS9_PDHWT1),
                    //     SS10_PDHWT1: parseInt(data.SS10_PDHWT1),
                    //     SS11_PDHWT1: parseInt(data.SS11_PDHWT1),
                    //     SS12_PDHWT1: parseInt(data.SS12_PDHWT1),
                    // },
                    // SS1: {
                    //     PDHWT2: parseInt(data.PDHWT2),
                    //     SS1_PDHWT2: parseInt(data.SS1_PDHWT2),
                    //     SS2_PDHWT2: parseInt(data.SS2_PDHWT2),
                    //     SS3_PDHWT2: parseInt(data.SS3_PDHWT2),
                    //     SS4_PDHWT2: parseInt(data.SS4_PDHWT2),
                    //     SS5_PDHWT2: parseInt(data.SS5_PDHWT2),
                    //     SS6_PDHWT2: parseInt(data.SS6_PDHWT2),
                    //     SS7_PDHWT2: parseInt(data.SS7_PDHWT2),
                    //     SS8_PDHWT2: parseInt(data.SS8_PDHWT2),
                    //     SS9_PDHWT2: parseInt(data.SS9_PDHWT2),
                    //     SS10_PDHWT2: parseInt(data.SS10_PDHWT2),
                    //     SS11_PDHWT2: parseInt(data.SS11_PDHWT2),
                    //     SS12_PDHWT2: parseInt(data.SS12_PDHWT2),
                    // },
                    // SS2: {
                    //     PHTT1: parseInt(data.PHTT1),
                    //     SS1_PHTT1: parseInt(data.SS1_PHTT1),
                    //     SS2_PHTT1: parseInt(data.SS2_PHTT1),
                    //     SS3_PHTT1: parseInt(data.SS3_PHTT1),
                    //     SS4_PHTT1: parseInt(data.SS4_PHTT1),
                    //     SS5_PHTT1: parseInt(data.SS5_PHTT1),
                    //     SS6_PHTT1: parseInt(data.SS6_PHTT1),
                    //     SS7_PHTT1: parseInt(data.SS7_PHTT1),
                    //     SS8_PHTT1: parseInt(data.SS8_PHTT1),
                    //     SS9_PHTT1: parseInt(data.SS9_PHTT1),
                    //     SS10_PHTT1: parseInt(data.SS10_PHTT1),
                    //     SS11_PHTT1: parseInt(data.SS11_PHTT1),
                    //     SS12_PHTT1: parseInt(data.SS12_PHTT1),
                    // },
                    // SS3: {
                    //     PHTT2: parseInt(data.PHTT2),
                    //     SS1_PHTT2: parseInt(data.SS1_PHTT2),
                    //     SS2_PHTT2: parseInt(data.SS2_PHTT2),
                    //     SS3_PHTT2: parseInt(data.SS3_PHTT2),
                    //     SS4_PHTT2: parseInt(data.SS4_PHTT2),
                    //     SS5_PHTT2: parseInt(data.SS5_PHTT2),
                    //     SS6_PHTT2: parseInt(data.SS6_PHTT2),
                    //     SS7_PHTT2: parseInt(data.SS7_PHTT2),
                    //     SS8_PHTT2: parseInt(data.SS8_PHTT2),
                    //     SS9_PHTT2: parseInt(data.SS9_PHTT2),
                    //     SS10_PHTT2: parseInt(data.SS10_PHTT2),
                    //     SS11_PHTT2: parseInt(data.SS11_PHTT2),
                    //     SS12_PHTT2: parseInt(data.SS12_PHTT2),
                    // },
                    // SS4: {
                    //     PACT1: parseInt(data.PACT1),
                    //     SS1_PACT1: parseInt(data.SS1_PACT1),
                    //     SS2_PACT1: parseInt(data.SS2_PACT1),
                    //     SS3_PACT1: parseInt(data.SS3_PACT1),
                    //     SS4_PACT1: parseInt(data.SS4_PACT1),
                    //     SS5_PACT1: parseInt(data.SS5_PACT1),
                    //     SS6_PACT1: parseInt(data.SS6_PACT1),
                    //     SS7_PACT1: parseInt(data.SS7_PACT1),
                    //     SS8_PACT1: parseInt(data.SS8_PACT1),
                    //     SS9_PACT1: parseInt(data.SS9_PACT1),
                    //     SS10_PACT1: parseInt(data.SS10_PACT1),
                    //     SS11_PACT1: parseInt(data.SS11_PACT1),
                    //     SS12_PACT1: parseInt(data.SS12_PACT1),
                    // },
                    // SS5: {
                    //     PACT2: parseInt(data.PACT2),
                    //     SS1_PACT2: parseInt(data.SS1_PACT2),
                    //     SS2_PACT2: parseInt(data.SS2_PACT2),
                    //     SS3_PACT2: parseInt(data.SS3_PACT2),
                    //     SS4_PACT2: parseInt(data.SS4_PACT2),
                    //     SS5_PACT2: parseInt(data.SS5_PACT2),
                    //     SS6_PACT2: parseInt(data.SS6_PACT2),
                    //     SS7_PACT2: parseInt(data.SS7_PACT2),
                    //     SS8_PACT2: parseInt(data.SS8_PACT2),
                    //     SS9_PACT2: parseInt(data.SS9_PACT2),
                    //     SS10_PACT2: parseInt(data.SS10_PACT2),
                    //     SS11_PACT2: parseInt(data.SS11_PACT2),
                    //     SS12_PACT2: parseInt(data.SS12_PACT2),
                    // },
                    // SS6: {
                    //     PFC: parseInt(data.PFC),
                    //     SS1_PFC: parseInt(data.SS1_PFC),
                    //     SS2_PFC: parseInt(data.SS2_PFC),
                    //     SS3_PFC: parseInt(data.SS3_PFC),
                    //     SS4_PFC: parseInt(data.SS4_PFC),
                    //     SS5_PFC: parseInt(data.SS5_PFC),
                    //     SS6_PFC: parseInt(data.SS6_PFC),
                    //     SS7_PFC: parseInt(data.SS7_PFC),
                    //     SS8_PFC: parseInt(data.SS8_PFC),
                    //     SS9_PFC: parseInt(data.SS9_PFC),
                    //     SS10_PFC: parseInt(data.SS10_PFC),
                    //     SS11_PFC: parseInt(data.SS11_PFC),
                    //     SS12_PFC: parseInt(data.SS12_PFC),
                    // },
                    SS0: {
                        PDHWT1: parseInt(data.PDHWT1),
                        PDHWT2: parseInt(data.PDHWT2),
                        PHTT1: parseInt(data.PHTT1),
                        PHTT2: parseInt(data.PHTT2),
                        PACT1: parseInt(data.PACT1),
                        PACT2: parseInt(data.PACT2),
                        PFC: parseInt(data.PFC),
                    },
                    SS1: {
                        SS1_PDHWT1: parseInt(data.SS1_PDHWT1),
                        SS1_PDHWT2: parseInt(data.SS1_PDHWT2),
                        SS1_PHTT1: parseInt(data.SS1_PHTT1),
                        SS1_PHTT2: parseInt(data.SS1_PHTT2),
                        SS1_PACT1: parseInt(data.SS1_PACT1),
                        SS1_PACT2: parseInt(data.SS1_PACT2),
                        SS1_PFC: parseInt(data.SS1_PFC),
                    },
                    SS2: {
                        SS2_PDHWT1: parseInt(data.SS2_PDHWT1),
                        SS2_PDHWT2: parseInt(data.SS2_PDHWT2),
                        SS2_PHTT1: parseInt(data.SS2_PHTT1),
                        SS2_PHTT2: parseInt(data.SS2_PHTT2),
                        SS2_PACT1: parseInt(data.SS2_PACT1),
                        SS2_PACT2: parseInt(data.SS2_PACT2),
                        SS2_PFC: parseInt(data.SS2_PFC),
                    },
                    SS3: {
                        SS3_PDHWT1: parseInt(data.SS3_PDHWT1),
                        SS3_PDHWT2: parseInt(data.SS3_PDHWT2),
                        SS3_PHTT1: parseInt(data.SS3_PHTT1),
                        SS3_PHTT2: parseInt(data.SS3_PHTT2),
                        SS3_PACT1: parseInt(data.SS3_PACT1),
                        SS3_PACT2: parseInt(data.SS3_PACT2),
                        SS3_PFC: parseInt(data.SS3_PFC),
                    },
                    SS4: {
                        SS4_PDHWT1: parseInt(data.SS4_PDHWT1),
                        SS4_PDHWT2: parseInt(data.SS4_PDHWT2),
                        SS4_PHTT1: parseInt(data.SS4_PHTT1),
                        SS4_PHTT2: parseInt(data.SS4_PHTT2),
                        SS4_PACT1: parseInt(data.SS4_PACT1),
                        SS4_PACT2: parseInt(data.SS4_PACT2),
                        SS4_PFC: parseInt(data.SS4_PFC),
                    },
                    SS5: {
                        SS5_PDHWT1: parseInt(data.SS5_PDHWT1),
                        SS5_PDHWT2: parseInt(data.SS5_PDHWT2),
                        SS5_PHTT1: parseInt(data.SS5_PHTT1),
                        SS5_PHTT2: parseInt(data.SS5_PHTT2),
                        SS5_PACT1: parseInt(data.SS5_PACT1),
                        SS5_PACT2: parseInt(data.SS5_PACT2),
                        SS5_PFC: parseInt(data.SS5_PFC),
                    },
                    SS6: {
                        SS6_PDHWT1: parseInt(data.SS6_PDHWT1),
                        SS6_PDHWT2: parseInt(data.SS6_PDHWT2),
                        SS6_PHTT1: parseInt(data.SS6_PHTT1),
                        SS6_PHTT2: parseInt(data.SS6_PHTT2),
                        SS6_PACT1: parseInt(data.SS6_PACT1),
                        SS6_PACT2: parseInt(data.SS6_PACT2),
                        SS6_PFC: parseInt(data.SS6_PFC),
                    },
                    SS7: {
                        SS7_PDHWT1: parseInt(data.SS7_PDHWT1),
                        SS7_PDHWT2: parseInt(data.SS7_PDHWT2),
                        SS7_PHTT1: parseInt(data.SS7_PHTT1),
                        SS7_PHTT2: parseInt(data.SS7_PHTT2),
                        SS7_PACT1: parseInt(data.SS7_PACT1),
                        SS7_PACT2: parseInt(data.SS7_PACT2),
                        SS7_PFC: parseInt(data.SS7_PFC),
                    },
                    SS8: {
                        SS8_PDHWT1: parseInt(data.SS8_PDHWT1),
                        SS8_PDHWT2: parseInt(data.SS8_PDHWT2),
                        SS8_PHTT1: parseInt(data.SS8_PHTT1),
                        SS8_PHTT2: parseInt(data.SS8_PHTT2),
                        SS8_PACT1: parseInt(data.SS8_PACT1),
                        SS8_PACT2: parseInt(data.SS8_PACT2),
                        SS8_PFC: parseInt(data.SS8_PFC),
                    },
                    SS9: {
                        SS9_PDHWT1: parseInt(data.SS9_PDHWT1),
                        SS9_PDHWT2: parseInt(data.SS9_PDHWT2),
                        SS9_PHTT1: parseInt(data.SS9_PHTT1),
                        SS9_PHTT2: parseInt(data.SS9_PHTT2),
                        SS9_PACT1: parseInt(data.SS9_PACT1),
                        SS9_PACT2: parseInt(data.SS9_PACT2),
                        SS9_PFC: parseInt(data.SS9_PFC),
                    },
                    SS10: {
                        SS10_PDHWT1: parseInt(data.SS10_PDHWT1),
                        SS10_PDHWT2: parseInt(data.SS10_PDHWT2),
                        SS10_PHTT1: parseInt(data.SS10_PHTT1),
                        SS10_PHTT2: parseInt(data.SS10_PHTT2),
                        SS10_PACT1: parseInt(data.SS10_PACT1),
                        SS10_PACT2: parseInt(data.SS10_PACT2),
                        SS10_PFC: parseInt(data.SS10_PFC),
                    },
                    SS11: {
                        SS11_PDHWT1: parseInt(data.SS11_PDHWT1),
                        SS11_PDHWT2: parseInt(data.SS11_PDHWT2),
                        SS11_PHTT1: parseInt(data.SS11_PHTT1),
                        SS11_PHTT2: parseInt(data.SS11_PHTT2),
                        SS11_PACT1: parseInt(data.SS11_PACT1),
                        SS11_PACT2: parseInt(data.SS11_PACT2),
                        SS11_PFC: parseInt(data.SS11_PFC),
                    },
                    SS12: {
                        SS12_PDHWT1: parseInt(data.SS12_PDHWT1),
                        SS12_PDHWT2: parseInt(data.SS12_PDHWT2),
                        SS12_PHTT1: parseInt(data.SS12_PHTT1),
                        SS12_PHTT2: parseInt(data.SS12_PHTT2),
                        SS12_PACT1: parseInt(data.SS12_PACT1),
                        SS12_PACT2: parseInt(data.SS12_PACT2),
                        SS12_PFC: parseInt(data.SS12_PFC),
                    },
                    compressorStartCount: {
                        CORSTA_SPRMAX: parseInt(data.CORSTA_SPRMAX),
                        CORSTA_SPRMIN: parseInt(data.CORSTA_SPRMIN),
                        CORSTA_SPSMAX: parseInt(data.CORSTA_SPSMAX),
                    },
                    peakSourceTime: {
                        TIME_FST: parseInt(data.TIME_FST),
                        TIME_WEEK: parseInt(data.TIME_WEEK),
                        CORSTA: parseInt(data.CORSTA),
                        CORSTA_SPSMIN: parseInt(data.CORSTA_SPSMIN)
                    },
                    MIESIAC: parseInt(data.MIESIAC),
                    ROK: parseInt(data.ROK),
                }
            },
            timemode: {
                cwu: {
                    P: {
                        CWP1P_PG: parseInt(data.CWP1P_PG),
                        CWP1P_PM: parseInt(data.CWP1P_PM),
                        CWP1P_KG: parseInt(data.CWP1P_KG),
                        CWP1P_KM: parseInt(data.CWP1P_KM),
                        CWP2P_PG: parseInt(data.CWP2P_PG),
                        CWP2P_PM: parseInt(data.CWP2P_PM),
                        CWP2P_KG: parseInt(data.CWP2P_KG),
                        CWP2P_KM: parseInt(data.CWP2P_KM),
                        CWP3P_PG: parseInt(data.CWP3P_PG),
                        CWP3P_PM: parseInt(data.CWP3P_PM),
                        CWP3P_KG: parseInt(data.CWP3P_KG),
                        CWP3P_KM: parseInt(data.CWP3P_KM),
                        temp: {
                            CWP1T: parseInt(data.CWP1T),
                            CWP2T: parseInt(data.CWP2T),
                            CWP3T: parseInt(data.CWP3T),
                        },
                    },
                    W: {
                        CWW1P_PG: parseInt(data.CWW1P_PG),
                        CWW1P_PM: parseInt(data.CWW1P_PM),
                        CWW1P_KG: parseInt(data.CWW1P_KG),
                        CWW1P_KM: parseInt(data.CWW1P_KM),
                        CWW2P_PG: parseInt(data.CWW2P_PG),
                        CWW2P_PM: parseInt(data.CWW2P_PM),
                        CWW2P_KG: parseInt(data.CWW2P_KG),
                        CWW2P_KM: parseInt(data.CWW2P_KM),
                        CWW3P_PG: parseInt(data.CWW3P_PG),
                        CWW3P_PM: parseInt(data.CWW3P_PM),
                        CWW3P_KG: parseInt(data.CWW3P_KG),
                        CWW3P_KM: parseInt(data.CWW3P_KM),
                        temp: {
                            CWW1T: parseInt(data.CWW1T),
                            CWW2T: parseInt(data.CWW2T),
                            CWW3T: parseInt(data.CWW3T),
                        },
                    },
                    S: {
                        CWS1P_PG: parseInt(data.CWS1P_PG),
                        CWS1P_PM: parseInt(data.CWS1P_PM),
                        CWS1P_KG: parseInt(data.CWS1P_KG),
                        CWS1P_KM: parseInt(data.CWS1P_KM),
                        CWS2P_PG: parseInt(data.CWS2P_PG),
                        CWS2P_PM: parseInt(data.CWS2P_PM),
                        CWS2P_KG: parseInt(data.CWS2P_KG),
                        CWS2P_KM: parseInt(data.CWS2P_KM),
                        CWS3P_PG: parseInt(data.CWS3P_PG),
                        CWS3P_PM: parseInt(data.CWS3P_PM),
                        CWS3P_KG: parseInt(data.CWS3P_KG),
                        CWS3P_KM: parseInt(data.CWS3P_KM),
                        temp: {
                            CWS1T: parseInt(data.CWS1T),
                            CWS2T: parseInt(data.CWS2T),
                            CWS3T: parseInt(data.CWS3T),
                        },
                    },
                    C: {
                        CWC1P_PG: parseInt(data.CWC1P_PG),
                        CWC1P_PM: parseInt(data.CWC1P_PM),
                        CWC1P_KG: parseInt(data.CWC1P_KG),
                        CWC1P_KM: parseInt(data.CWC1P_KM),
                        CWC2P_PG: parseInt(data.CWC2P_PG),
                        CWC2P_PM: parseInt(data.CWC2P_PM),
                        CWC2P_KG: parseInt(data.CWC2P_KG),
                        CWC2P_KM: parseInt(data.CWC2P_KM),
                        CWC3P_PG: parseInt(data.CWC3P_PG),
                        CWC3P_PM: parseInt(data.CWC3P_PM),
                        CWC3P_KG: parseInt(data.CWC3P_KG),
                        CWC3P_KM: parseInt(data.CWC3P_KM),
                        temp: {
                            CWC1T: parseInt(data.CWC1T),
                            CWC2T: parseInt(data.CWC2T),
                            CWC3T: parseInt(data.CWC3T),
                        },
                    },
                    PT: {
                        CWPT1P_PG: parseInt(data.CWPT1P_PG),
                        CWPT1P_PM: parseInt(data.CWPT1P_PM),
                        CWPT1P_KG: parseInt(data.CWPT1P_KG),
                        CWPT1P_KM: parseInt(data.CWPT1P_KM),
                        CWPT2P_PG: parseInt(data.CWPT2P_PG),
                        CWPT2P_PM: parseInt(data.CWPT2P_PM),
                        CWPT2P_KG: parseInt(data.CWPT2P_KG),
                        CWPT2P_KM: parseInt(data.CWPT2P_KM),
                        CWPT3P_PG: parseInt(data.CWPT3P_PG),
                        CWPT3P_PM: parseInt(data.CWPT3P_PM),
                        CWPT3P_KG: parseInt(data.CWPT3P_KG),
                        CWPT3P_KM: parseInt(data.CWPT3P_KM),
                        temp: {
                            CWPT1T: parseInt(data.CWPT1T),
                            CWPT2T: parseInt(data.CWPT2T),
                            CWPT3T: parseInt(data.CWPT3T),
                        },
                    },
                    SO: {
                        CWSO1P_PG: parseInt(data.CWSO1P_PG),
                        CWSO1P_PM: parseInt(data.CWSO1P_PM),
                        CWSO1P_KG: parseInt(data.CWSO1P_KG),
                        CWSO1P_KM: parseInt(data.CWSO1P_KM),
                        CWSO2P_PG: parseInt(data.CWSO2P_PG),
                        CWSO2P_PM: parseInt(data.CWSO2P_PM),
                        CWSO2P_KG: parseInt(data.CWSO2P_KG),
                        CWSO2P_KM: parseInt(data.CWSO2P_KM),
                        CWSO3P_PG: parseInt(data.CWSO3P_PG),
                        CWSO3P_PM: parseInt(data.CWSO3P_PM),
                        CWSO3P_KG: parseInt(data.CWSO3P_KG),
                        CWSO3P_KM: parseInt(data.CWSO3P_KM),
                        temp: {
                            CWSO1T: parseInt(data.CWSO1T),
                            CWSO2T: parseInt(data.CWSO2T),
                            CWSO3T: parseInt(data.CWSO3T),
                        },
                    },
                    N: {
                        CWN1P_PG: parseInt(data.CWN1P_PG),
                        CWN1P_PM: parseInt(data.CWN1P_PM),
                        CWN1P_KG: parseInt(data.CWN1P_KG),
                        CWN1P_KM: parseInt(data.CWN1P_KM),
                        CWN2P_PG: parseInt(data.CWN2P_PG),
                        CWN2P_PM: parseInt(data.CWN2P_PM),
                        CWN2P_KG: parseInt(data.CWN2P_KG),
                        CWN2P_KM: parseInt(data.CWN2P_KM),
                        CWN3P_PG: parseInt(data.CWN3P_PG),
                        CWN3P_PM: parseInt(data.CWN3P_PM),
                        CWN3P_KG: parseInt(data.CWN3P_KG),
                        CWN3P_KM: parseInt(data.CWN3P_KM),
                        temp: {
                            CWN1T: parseInt(data.CWN1T),
                            CWN2T: parseInt(data.CWN2T),
                            CWN3T: parseInt(data.CWN3T),
                        },
                    },
                },
                air: {
                    P: {
                        TWP1P_PG: parseInt(data.TWP1P_PG),
                        TWP1P_PM: parseInt(data.TWP1P_PM),
                        TWP1P_KG: parseInt(data.TWP1P_KG),
                        TWP1P_KM: parseInt(data.TWP1P_KM),
                        TWP2P_PG: parseInt(data.TWP2P_PG),
                        TWP2P_PM: parseInt(data.TWP2P_PM),
                        TWP2P_KG: parseInt(data.TWP2P_KG),
                        TWP2P_KM: parseInt(data.TWP2P_KM),
                        TWP3P_PG: parseInt(data.TWP3P_PG),
                        TWP3P_PM: parseInt(data.TWP3P_PM),
                        TWP3P_KG: parseInt(data.TWP3P_KG),
                        TWP3P_KM: parseInt(data.TWP3P_KM),
                        temp: {
                            TWP1T: parseInt(data.TWP1T),
                            TWP2T: parseInt(data.TWP2T),
                            TWP3T: parseInt(data.TWP3T),
                        },
                    },
                    W: {
                        TWW1P_PG: parseInt(data.TWW1P_PG),
                        TWW1P_PM: parseInt(data.TWW1P_PM),
                        TWW1P_KG: parseInt(data.TWW1P_KG),
                        TWW1P_KM: parseInt(data.TWW1P_KM),
                        TWW2P_PG: parseInt(data.TWW2P_PG),
                        TWW2P_PM: parseInt(data.TWW2P_PM),
                        TWW2P_KG: parseInt(data.TWW2P_KG),
                        TWW2P_KM: parseInt(data.TWW2P_KM),
                        TWW3P_PG: parseInt(data.TWW3P_PG),
                        TWW3P_PM: parseInt(data.TWW3P_PM),
                        TWW3P_KG: parseInt(data.TWW3P_KG),
                        TWW3P_KM: parseInt(data.TWW3P_KM),
                        temp: {
                            TWW1T: parseInt(data.TWW1T),
                            TWW2T: parseInt(data.TWW2T),
                            TWW3T: parseInt(data.TWW3T),
                        },
                    },
                    S: {
                        TWS1P_PG: parseInt(data.TWS1P_PG),
                        TWS1P_PM: parseInt(data.TWS1P_PM),
                        TWS1P_KG: parseInt(data.TWS1P_KG),
                        TWS1P_KM: parseInt(data.TWS1P_KM),
                        TWS2P_PG: parseInt(data.TWS2P_PG),
                        TWS2P_PM: parseInt(data.TWS2P_PM),
                        TWS2P_KG: parseInt(data.TWS2P_KG),
                        TWS2P_KM: parseInt(data.TWS2P_KM),
                        TWS3P_PG: parseInt(data.TWS3P_PG),
                        TWS3P_PM: parseInt(data.TWS3P_PM),
                        TWS3P_KG: parseInt(data.TWS3P_KG),
                        TWS3P_KM: parseInt(data.TWS3P_KM),
                        temp: {
                            TWS1T: parseInt(data.TWS1T),
                            TWS2T: parseInt(data.TWS2T),
                            TWS3T: parseInt(data.TWS3T),
                        },
                    },
                    C: {
                        TWC1P_PG: parseInt(data.TWC1P_PG),
                        TWC1P_PM: parseInt(data.TWC1P_PM),
                        TWC1P_KG: parseInt(data.TWC1P_KG),
                        TWC1P_KM: parseInt(data.TWC1P_KM),
                        TWC2P_PG: parseInt(data.TWC2P_PG),
                        TWC2P_PM: parseInt(data.TWC2P_PM),
                        TWC2P_KG: parseInt(data.TWC2P_KG),
                        TWC2P_KM: parseInt(data.TWC2P_KM),
                        TWC3P_PG: parseInt(data.TWC3P_PG),
                        TWC3P_PM: parseInt(data.TWC3P_PM),
                        TWC3P_KG: parseInt(data.TWC3P_KG),
                        TWC3P_KM: parseInt(data.TWC3P_KM),
                        temp: {
                            TWC1T: parseInt(data.TWC1T),
                            TWC2T: parseInt(data.TWC2T),
                            TWC3T: parseInt(data.TWC3T),
                        },
                    },
                    PT: {
                        TWPT1P_PG: parseInt(data.TWPT1P_PG),
                        TWPT1P_PM: parseInt(data.TWPT1P_PM),
                        TWPT1P_KG: parseInt(data.TWPT1P_KG),
                        TWPT1P_KM: parseInt(data.TWPT1P_KM),
                        TWPT2P_PG: parseInt(data.TWPT2P_PG),
                        TWPT2P_PM: parseInt(data.TWPT2P_PM),
                        TWPT2P_KG: parseInt(data.TWPT2P_KG),
                        TWPT2P_KM: parseInt(data.TWPT2P_KM),
                        TWPT3P_PG: parseInt(data.TWPT3P_PG),
                        TWPT3P_PM: parseInt(data.TWPT3P_PM),
                        TWPT3P_KG: parseInt(data.TWPT3P_KG),
                        TWPT3P_KM: parseInt(data.TWPT3P_KM),
                        temp: {
                            TWPT1T: parseInt(data.TWPT1T),
                            TWPT2T: parseInt(data.TWPT2T),
                            TWPT3T: parseInt(data.TWPT3T),
                        },
                    },
                    SO: {
                        TWSO1P_PG: parseInt(data.TWSO1P_PG),
                        TWSO1P_PM: parseInt(data.TWSO1P_PM),
                        TWSO1P_KG: parseInt(data.TWSO1P_KG),
                        TWSO1P_KM: parseInt(data.TWSO1P_KM),
                        TWSO2P_PG: parseInt(data.TWSO2P_PG),
                        TWSO2P_PM: parseInt(data.TWSO2P_PM),
                        TWSO2P_KG: parseInt(data.TWSO2P_KG),
                        TWSO2P_KM: parseInt(data.TWSO2P_KM),
                        TWSO3P_PG: parseInt(data.TWSO3P_PG),
                        TWSO3P_PM: parseInt(data.TWSO3P_PM),
                        TWSO3P_KG: parseInt(data.TWSO3P_KG),
                        TWSO3P_KM: parseInt(data.TWSO3P_KM),
                        temp: {
                            TWSO1T: parseInt(data.TWSO1T),
                            TWSO2T: parseInt(data.TWSO2T),
                            TWSO3T: parseInt(data.TWSO3T),
                        },
                    },
                    N: {
                        TWN1P_PG: parseInt(data.TWN1P_PG),
                        TWN1P_PM: parseInt(data.TWN1P_PM),
                        TWN1P_KG: parseInt(data.TWN1P_KG),
                        TWN1P_KM: parseInt(data.TWN1P_KM),
                        TWN2P_PG: parseInt(data.TWN2P_PG),
                        TWN2P_PM: parseInt(data.TWN2P_PM),
                        TWN2P_KG: parseInt(data.TWN2P_KG),
                        TWN2P_KM: parseInt(data.TWN2P_KM),
                        TWN3P_PG: parseInt(data.TWN3P_PG),
                        TWN3P_PM: parseInt(data.TWN3P_PM),
                        TWN3P_KG: parseInt(data.TWN3P_KG),
                        TWN3P_KM: parseInt(data.TWN3P_KM),
                        temp: {
                            TWN1T: parseInt(data.TWN1T),
                            TWN2T: parseInt(data.TWN2T),
                            TWN3T: parseInt(data.TWN3T),
                        },
                    },
                },
            },
            ecomode: {
                ECOT1R: parseInt(data.ECOT1R),
                ECOT1S: parseInt(data.ECOT1S),
                ECOT2R_Z: parseInt(data.ECOT2R_Z),
                ECOT2S_Z: parseInt(data.ECOT2S_Z),
                ECOT2R_L: parseInt(data.ECOT2R_L),
                ECOT2S_L: parseInt(data.ECOT2S_L),
                ECOT3R: parseInt(data.ECOT3R),
                ECOT3S: parseInt(data.ECOT3S),
                temp: {
                    ECOX1: parseInt(data.ECOX1),
                    ECOX2: parseInt(data.ECOX2),
                    ECODHW: parseInt(data.ECODHW),
                    ECOY1: parseInt(data.ECOY1),
                    ECOY2: parseInt(data.ECOY2),
                    ECOTIN: parseInt(data.ECOTIN),
                },
                SXF_AZP: parseInt(data.SXF_AZP),
                SXF_WEK: parseInt(data.SXF_WEK),
            },
            awaymode: {},
            circulation: {
                function: {
                    SXF_FORCYR: parseInt(data.SXF_FORCYR),
                    SXF_TIMCYR: parseInt(data.SXF_TIMCYR),
                    SXF_FLPCYR: parseInt(data.SXF_FLPCYR),
                    CYR_FILL: parseInt(data.CYR_FILL),
                    CYR_BS: parseInt(data.CYR_BS),
                },
                CYRSW_S1: parseInt(data.CYRSW_S1),
                CYRSW_S2: parseInt(data.CYRSW_S2),
                CYRSW_S3: parseInt(data.CYRSW_S3),
                CYRSW_PN: parseInt(data.CYRSW_PN),
                CYRSW_WT: parseInt(data.CYRSW_WT),
                CYRSW_SR: parseInt(data.CYRSW_SR),
                CYRSW_CZ: parseInt(data.CYRSW_CZ),
                CYRSW_PT: parseInt(data.CYRSW_PT),
                CYRSW_SO: parseInt(data.CYRSW_SO),
                CYRSW_ND: parseInt(data.CYRSW_ND),
                P: {
                    CYRP1P_PG: parseInt(data.CYRP1P_PG),
                    CYRP1P_KG: parseInt(data.CYRP1P_KG),
                    CYRP2P_PG: parseInt(data.CYRP2P_PG),
                    CYRP2P_KG: parseInt(data.CYRP2P_KG),
                    CYRP3P_PG: parseInt(data.CYRP3P_PG),
                    CYRP3P_KG: parseInt(data.CYRP3P_KG),
                    CYRP3P_KM: parseInt(data.CYRP3P_KM),
                },
                W: {
                    CYRW1P_PG: parseInt(data.CYRW1P_PG),
                    CYRW1P_KG: parseInt(data.CYRW1P_KG),
                    CYRW2P_PG: parseInt(data.CYRW2P_PG),
                    CYRW2P_KG: parseInt(data.CYRW2P_KG),
                    CYRW3P_PG: parseInt(data.CYRW3P_PG),
                    CYRW3P_KG: parseInt(data.CYRW3P_KG),
                    CYRW3P_KM: parseInt(data.CYRW3P_KM),
                },
                S: {
                    CYRS1P_PG: parseInt(data.CYRS1P_PG),
                    CYRS1P_KG: parseInt(data.CYRS1P_KG),
                    CYRS2P_PG: parseInt(data.CYRS2P_PG),
                    CYRS2P_KG: parseInt(data.CYRS2P_KG),
                    CYRS3P_PG: parseInt(data.CYRS3P_PG),
                    CYRS3P_KG: parseInt(data.CYRS3P_KG),
                    CYRS3P_KM: parseInt(data.CYRS3P_KM),
                },
                C: {
                    CYRC1P_PG: parseInt(data.CYRC1P_PG),
                    CYRC1P_KG: parseInt(data.CYRC1P_KG),
                    CYRC2P_PG: parseInt(data.CYRC2P_PG),
                    CYRC2P_KG: parseInt(data.CYRC2P_KG),
                    CYRC3P_PG: parseInt(data.CYRC3P_PG),
                    CYRC3P_KG: parseInt(data.CYRC3P_KG),
                    CYRC3P_KM: parseInt(data.CYRC3P_KM),
                },
                PT: {
                    CYRPT1P_PG: parseInt(data.CYRPT1P_PG),
                    CYRPT1P_KG: parseInt(data.CYRPT1P_KG),
                    CYRPT2P_PG: parseInt(data.CYRPT2P_PG),
                    CYRPT2P_KG: parseInt(data.CYRPT2P_KG),
                    CYRPT3P_PG: parseInt(data.CYRPT3P_PG),
                    CYRPT3P_KG: parseInt(data.CYRPT3P_KG),
                    CYRPT3P_KM: parseInt(data.CYRPT3P_KM),
                },
                SO: {
                    CYRSO1P_PG: parseInt(data.CYRSO1P_PG),
                    CYRSO1P_KG: parseInt(data.CYRSO1P_KG),
                    CYRSO2P_PG: parseInt(data.CYRSO2P_PG),
                    CYRSO2P_KG: parseInt(data.CYRSO2P_KG),
                    CYRSO3P_PG: parseInt(data.CYRSO3P_PG),
                    CYRSO3P_KG: parseInt(data.CYRSO3P_KG),
                    CYRSO3P_KM: parseInt(data.CYRSO3P_KM),
                },
                N: {
                    CYRN1P_PG: parseInt(data.CYRN1P_PG),
                    CYRN1P_KG: parseInt(data.CYRN1P_KG),
                    CYRN2P_PG: parseInt(data.CYRN2P_PG),
                    CYRN2P_KG: parseInt(data.CYRN2P_KG),
                    CYRN3P_PG: parseInt(data.CYRN3P_PG),
                    CYRN3P_KG: parseInt(data.CYRN3P_KG),
                    CYRN3P_KM: parseInt(data.CYRN3P_KM),
                },
            },
            warnings: {
                REW_ERR: parseInt(data.REW_ERR),
                REW_SUP: parseInt(data.REW_SUP),
                REW_TER: parseInt(data.REW_TER),
                REW_LPC: parseInt(data.REW_LPC),
                REW_LPH: parseInt(data.REW_LPH),
                REW_HP: parseInt(data.REW_HP),
                REW_FLO: parseInt(data.REW_FLO),
                REW_DIS: parseInt(data.REW_DIS),
                REW_SMD: parseInt(data.REW_SMD),
                REW_RMD: parseInt(data.REW_RMD),
                // REW_SMI: parseInt(data.REW_SMI),
                // REW_SMA: parseInt(data.REW_SMA),
                // REW_RMI: parseInt(data.REW_RMI),
                // REW_RMA: parseInt(data.REW_RMA),
                REW_FCS: parseInt(data.REW_FCS),
                MB_COMM2_XZM_TRANSM: parseInt(data.MB_COMM2_XZM_TRANSM),
                SXB_DHWO: parseInt(data.SXB_DHWO),
                SXB_CLO: parseInt(data.SXB_CLO),
                SXB_PWRO: parseInt(data.SXB_PWRO),
                SXB_FER: parseInt(data.SXB_FER),
                SXB_HTO: parseInt(data.SXB_HTO),
            },
            settings: {
                SBF_HTX: parseInt(data.SBF_HTX),
                SBF_DHX: parseInt(data.SBF_DHX),
                SBF_FCX: parseInt(data.SBF_FCX),
                SBF_FCS: parseInt(data.SBF_FCS),
                SBF_AHX: parseInt(data.SBF_AHX),
                SBF_AHS: parseInt(data.SBF_AHS),
                SBF_ACX: parseInt(data.SBF_ACX),
                SBF_ACS: parseInt(data.SBF_ACS),
                SXF_TIM_WIN_SW: parseInt(data.SXF_TIM_WIN_SW),
                SXF_TIM_WIN_FL: parseInt(data.SXF_TIM_WIN_FL),
                SXF_ENACYR: parseInt(data.SXF_ENACYR),
                SXF_TOE: parseInt(data.SXF_TOE),
                SXF_ANL: parseInt(data.SXF_ANL),
                SXF_DHM: parseInt(data.SXF_DHM),
                TOUT_KOR: parseInt(data.TOUT_KOR),
                TOUT_NUM: parseInt(data.TOUT_NUM),
                SX4_PH: parseInt(data.SX4_PH),
                SX4_PC: parseInt(data.SX4_PC),
                COC: parseInt(data.COC),
                SX3_HG: parseInt(data.SX3_HG),
                SX3_HC: parseInt(data.SX3_HC),
                SBF_PHT: parseInt(data.SBF_PHT),
                OB1_XZM_TRANSM: parseInt(data.OB1_XZM_TRANSM),
                VER_S1: parseInt(data.VER_S1),
                VER_S2: parseInt(data.VER_S2),
                heatingCurve: {
                    CUN_HCU: parseInt(data.CUN_HCU),
                    CUN_D_HCU: parseInt(data.CUN_D_HCU),
                },
                heatingCurve2: {
                    OB1_KRZYWA: parseInt(data.OB1_KRZYWA),
                },
                coolingCurve: {
                    SX2_P2: parseInt(data.SX2_P2),
                    SX2_P1: parseInt(data.SX2_P1),
                },
                datatime: {
                    ST_GODZINY: parseInt(data.ST_GODZINY),
                    ST_MINUTY: parseInt(data.ST_MINUTY),
                    ST_SEKUNDY: parseInt(data.ST_SEKUNDY),
                    SXF_TIM_WIN_SW: parseInt(data.SXF_TIM_WIN_SW),
                    SXF_TIM_WIN_FL: parseInt(data.SXF_TIM_WIN_FL),
                    ST_DZIEN: parseInt(data.ST_DZIEN),
                    ST_MIESIAC: parseInt(data.ST_MIESIAC),
                    ST_ROK: parseInt(data.ST_ROK),
                    ST_DZEN_TYG: parseInt(data.ST_DZEN_TYG),
                    GODZINY: parseInt(data.GODZINY),
                    MINUTY: parseInt(data.MINUTY),
                    SEKUNDY: parseInt(data.SEKUNDY),
                    DZIEN: parseInt(data.DZIEN),
                    MIESIAC: parseInt(data.MIESIAC),
                    ROK: parseInt(data.ROK),
                    DZIEN_TYG: parseInt(data.DZIEN_TYG),
                },
                antylegionella: {
                   SBF_PHT: parseInt(data.SBF_PHT),
                   SBF_DHS: parseInt(data.SBF_DHS),
                   ANL_DAY: parseInt(data.ANL_DAY),
                   ANL_SETP_ZAD: parseInt(data.ANL_SETP_ZAD),
                   ANL_CLOCK: parseInt(data.ANL_CLOCK),
                   ANL_ALL: parseInt(data.ANL_ALL),
                   TSM15: parseFloat(data.TSM15),
                   ANL_1PH: parseInt(data.ANL_1PH),
                   ANL_2PH: parseInt(data.ANL_2PH),
                   ANL_3PH: parseInt(data.ANL_3PH),
                   SXF_ANL: parseInt(data.SXF_ANL),
                },
            },
            defaultdata: {
                CYRP1P_PG: parseInt(data.CYRP1P_PG),
                CYRP1P_KG: parseInt(data.CYRP1P_KG),
                CYRP2P_PG: parseInt(data.CYRP2P_PG),
                CYRP2P_KG: parseInt(data.CYRP2P_KG),
                CYRP3P_PG: parseInt(data.CYRP3P_PG),
                CYRP3P_KG: parseInt(data.CYRP3P_KG),
                CYRP3P_KM: parseInt(data.CYRP3P_KM),

                CYRW1P_PG: parseInt(data.CYRW1P_PG),
                CYRW1P_KG: parseInt(data.CYRW1P_KG),
                CYRW2P_PG: parseInt(data.CYRW2P_PG),
                CYRW2P_KG: parseInt(data.CYRW2P_KG),
                CYRW3P_PG: parseInt(data.CYRW3P_PG),
                CYRW3P_KG: parseInt(data.CYRW3P_KG),
                CYRW3P_KM: parseInt(data.CYRW3P_KM),

                CYRS1P_PG: parseInt(data.CYRS1P_PG),
                CYRS1P_KG: parseInt(data.CYRS1P_KG),
                CYRS2P_PG: parseInt(data.CYRS2P_PG),
                CYRS2P_KG: parseInt(data.CYRS2P_KG),
                CYRS3P_PG: parseInt(data.CYRS3P_PG),
                CYRS3P_KG: parseInt(data.CYRS3P_KG),
                CYRS3P_KM: parseInt(data.CYRS3P_KM),

                CYRC1P_PG: parseInt(data.CYRC1P_PG),
                CYRC1P_KG: parseInt(data.CYRC1P_KG),
                CYRC2P_PG: parseInt(data.CYRC2P_PG),
                CYRC2P_KG: parseInt(data.CYRC2P_KG),
                CYRC3P_PG: parseInt(data.CYRC3P_PG),
                CYRC3P_KG: parseInt(data.CYRC3P_KG),
                CYRC3P_KM: parseInt(data.CYRC3P_KM),

                CYRPT1P_PG: parseInt(data.CYRPT1P_PG),
                CYRPT1P_KG: parseInt(data.CYRPT1P_KG),
                CYRPT2P_PG: parseInt(data.CYRPT2P_PG),
                CYRPT2P_KG: parseInt(data.CYRPT2P_KG),
                CYRPT3P_PG: parseInt(data.CYRPT3P_PG),
                CYRPT3P_KG: parseInt(data.CYRPT3P_KG),
                CYRPT3P_KM: parseInt(data.CYRPT3P_KM),

                CYRSO1P_PG: parseInt(data.CYRSO1P_PG),
                CYRSO1P_KG: parseInt(data.CYRSO1P_KG),
                CYRSO2P_PG: parseInt(data.CYRSO2P_PG),
                CYRSO2P_KG: parseInt(data.CYRSO2P_KG),
                CYRSO3P_PG: parseInt(data.CYRSO3P_PG),
                CYRSO3P_KG: parseInt(data.CYRSO3P_KG),
                CYRSO3P_KM: parseInt(data.CYRSO3P_KM),

                CYRN1P_PG: parseInt(data.CYRN1P_PG),
                CYRN1P_KG: parseInt(data.CYRN1P_KG),
                CYRN2P_PG: parseInt(data.CYRN2P_PG),
                CYRN2P_KG: parseInt(data.CYRN2P_KG),
                CYRN3P_PG: parseInt(data.CYRN3P_PG),
                CYRN3P_KG: parseInt(data.CYRN3P_KG),
                CYRN3P_KM: parseInt(data.CYRN3P_KM),

                CWP1P_PG: parseInt(data.CWP1P_PG),
                CWP1P_KG: parseInt(data.CWP1P_KG),
                CWP2P_PG: parseInt(data.CWP2P_PG),
                CWP2P_KG: parseInt(data.CWP2P_KG),
                CWP2P_KM: parseInt(data.CWP2P_KM),
                CWP3P_PG: parseInt(data.CWP3P_PG),
                CWP3P_PM: parseInt(data.CWP3P_PM),
                CWP3P_KG: parseInt(data.CWP3P_KG),
                CWW1P_PG: parseInt(data.CWW1P_PG),
                CWW1P_KG: parseInt(data.CWW1P_KG),
                CWW2P_PG: parseInt(data.CWW2P_PG),
                CWW2P_KG: parseInt(data.CWW2P_KG),
                CWW2P_KM: parseInt(data.CWW2P_KM),
                CWW3P_PG: parseInt(data.CWW3P_PG),
                CWW3P_PM: parseInt(data.CWW3P_PM),
                CWW3P_KG: parseInt(data.CWW3P_KG),
                CWS1P_PG: parseInt(data.CWS1P_PG),
                CWS1P_KG: parseInt(data.CWS1P_KG),
                CWS2P_PG: parseInt(data.CWS2P_PG),
                CWS2P_KG: parseInt(data.CWS2P_KG),
                CWS2P_KM: parseInt(data.CWS2P_KM),
                CWS3P_PG: parseInt(data.CWS3P_PG),
                CWS3P_PM: parseInt(data.CWS3P_PM),
                CWS3P_KG: parseInt(data.CWS3P_KG),
                CWC1P_PG: parseInt(data.CWC1P_PG),
                CWC1P_KG: parseInt(data.CWC1P_KG),
                CWC2P_PG: parseInt(data.CWC2P_PG),
                CWC2P_KG: parseInt(data.CWC2P_KG),
                CWC2P_KM: parseInt(data.CWC2P_KM),
                CWC3P_PG: parseInt(data.CWC3P_PG),
                CWC3P_PM: parseInt(data.CWC3P_PM),
                CWC3P_KG: parseInt(data.CWC3P_KG),
                CWPT1P_PG: parseInt(data.CWPT1P_PG),
                CWPT1P_KG: parseInt(data.CWPT1P_KG),
                CWPT2P_PG: parseInt(data.CWPT2P_PG),
                CWPT2P_KG: parseInt(data.CWPT2P_KG),
                CWPT2P_KM: parseInt(data.CWPT2P_KM),
                CWPT3P_PG: parseInt(data.CWPT3P_PG),
                CWPT3P_PM: parseInt(data.CWPT3P_PM),
                CWPT3P_KG: parseInt(data.CWPT3P_KG),
                CWSO1P_PG: parseInt(data.CWSO1P_PG),
                CWSO1P_KG: parseInt(data.CWSO1P_KG),
                CWSO2P_PG: parseInt(data.CWSO2P_PG),
                CWSO2P_KG: parseInt(data.CWSO2P_KG),
                CWSO2P_KM: parseInt(data.CWSO2P_KM),
                CWSO3P_PG: parseInt(data.CWSO3P_PG),
                CWSO3P_PM: parseInt(data.CWSO3P_PM),
                CWSO3P_KG: parseInt(data.CWSO3P_KG),
                CWN1P_PG: parseInt(data.CWN1P_PG),
                CWN1P_KG: parseInt(data.CWN1P_KG),
                CWN2P_PG: parseInt(data.CWN2P_PG),
                CWN2P_KG: parseInt(data.CWN2P_KG),
                CWN2P_KM: parseInt(data.CWN2P_KM),
                CWN3P_PG: parseInt(data.CWN3P_PG),
                CWN3P_PM: parseInt(data.CWN3P_PM),
                CWN3P_KG: parseInt(data.CWN3P_KG),

                CWP1T: parseInt(data.CWP1T),
                CWP2T: parseInt(data.CWP2T),
                CWP3T: parseInt(data.CWP3T),

                CWW1T: parseInt(data.CWW1T),
                CWW2T: parseInt(data.CWW2T),
                CWW3T: parseInt(data.CWW3T),

                CWS1T: parseInt(data.CWS1T),
                CWS2T: parseInt(data.CWS2T),
                CWS3T: parseInt(data.CWS3T),

                CWC1T: parseInt(data.CWC1T),
                CWC2T: parseInt(data.CWC2T),
                CWC3T: parseInt(data.CWC3T),

                CWPT1T: parseInt(data.CWPT1T),
                CWPT2T: parseInt(data.CWPT2T),
                CWPT3T: parseInt(data.CWPT3T),

                CWSO1T: parseInt(data.CWSO1T),
                CWSO2T: parseInt(data.CWSO2T),
                CWSO3T: parseInt(data.CWSO3T),

                CWN1T: parseInt(data.CWN1T),
                CWN2T: parseInt(data.CWN2T),
                CWN3T: parseInt(data.CWN3T),

                TWP1P_PG: parseInt(data.TWP1P_PG),
                TWP1P_KG: parseInt(data.TWP1P_KG),
                TWP2P_PG: parseInt(data.TWP2P_PG),
                TWP2P_KG: parseInt(data.TWP2P_KG),
                TWP2P_KM: parseInt(data.TWP2P_KM),
                TWP3P_PG: parseInt(data.TWP3P_PG),
                TWP3P_PM: parseInt(data.TWP3P_PM),
                TWP3P_KG: parseInt(data.TWP3P_KG),
                TWW1P_PG: parseInt(data.TWW1P_PG),
                TWW1P_KG: parseInt(data.TWW1P_KG),
                TWW2P_PG: parseInt(data.TWW2P_PG),
                TWW2P_KG: parseInt(data.TWW2P_KG),
                TWW2P_KM: parseInt(data.TWW2P_KM),
                TWW3P_PG: parseInt(data.TWW3P_PG),
                TWW3P_PM: parseInt(data.TWW3P_PM),
                TWW3P_KG: parseInt(data.TWW3P_KG),
                TWS1P_PG: parseInt(data.TWS1P_PG),
                TWS1P_KG: parseInt(data.TWS1P_KG),
                TWS2P_PG: parseInt(data.TWS2P_PG),
                TWS2P_KG: parseInt(data.TWS2P_KG),
                TWS2P_KM: parseInt(data.TWS2P_KM),
                TWS3P_PG: parseInt(data.TWS3P_PG),
                TWS3P_PM: parseInt(data.TWS3P_PM),
                TWS3P_KG: parseInt(data.TWS3P_KG),
                TWC1P_PG: parseInt(data.TWC1P_PG),
                TWC1P_KG: parseInt(data.TWC1P_KG),
                TWC2P_PG: parseInt(data.TWC2P_PG),
                TWC2P_KG: parseInt(data.TWC2P_KG),
                TWC2P_KM: parseInt(data.TWC2P_KM),
                TWC3P_PG: parseInt(data.TWC3P_PG),
                TWC3P_PM: parseInt(data.TWC3P_PM),
                TWC3P_KG: parseInt(data.TWC3P_KG),
                TWPT1P_PG: parseInt(data.TWPT1P_PG),
                TWPT1P_KG: parseInt(data.TWPT1P_KG),
                TWPT2P_PG: parseInt(data.TWPT2P_PG),
                TWPT2P_KG: parseInt(data.TWPT2P_KG),
                TWPT2P_KM: parseInt(data.TWPT2P_KM),
                TWPT3P_PG: parseInt(data.TWPT3P_PG),
                TWPT3P_PM: parseInt(data.TWPT3P_PM),
                TWPT3P_KG: parseInt(data.TWPT3P_KG),
                TWSO1P_PG: parseInt(data.TWSO1P_PG),
                TWSO1P_KG: parseInt(data.TWSO1P_KG),
                TWSO2P_PG: parseInt(data.TWSO2P_PG),
                TWSO2P_KG: parseInt(data.TWSO2P_KG),
                TWSO2P_KM: parseInt(data.TWSO2P_KM),
                TWSO3P_PG: parseInt(data.TWSO3P_PG),
                TWSO3P_PM: parseInt(data.TWSO3P_PM),
                TWSO3P_KG: parseInt(data.TWSO3P_KG),
                TWN1P_PG: parseInt(data.TWN1P_PG),
                TWN1P_KG: parseInt(data.TWN1P_KG),
                TWN2P_PG: parseInt(data.TWN2P_PG),
                TWN2P_KG: parseInt(data.TWN2P_KG),
                TWN2P_KM: parseInt(data.TWN2P_KM),
                TWN3P_PG: parseInt(data.TWN3P_PG),
                TWN3P_PM: parseInt(data.TWN3P_PM),
                TWN3P_KG: parseInt(data.TWN3P_KG),

                TWP1T: parseInt(data.TWP1T),
                TWP2T: parseInt(data.TWP2T),
                TWP3T: parseInt(data.TWP3T),

                TWW1T: parseInt(data.TWW1T),
                TWW2T: parseInt(data.TWW2T),
                TWW3T: parseInt(data.TWW3T),

                TWS1T: parseInt(data.TWS1T),
                TWS2T: parseInt(data.TWS2T),
                TWS3T: parseInt(data.TWS3T),

                TWC1T: parseInt(data.TWC1T),
                TWC2T: parseInt(data.TWC2T),
                TWC3T: parseInt(data.TWC3T),

                TWPT1T: parseInt(data.TWPT1T),
                TWPT2T: parseInt(data.TWPT2T),
                TWPT3T: parseInt(data.TWPT3T),

                TWSO1T: parseInt(data.TWSO1T),
                TWSO2T: parseInt(data.TWSO2T),
                TWSO3T: parseInt(data.TWSO3T),

                TWN1T: parseInt(data.TWN1T),
                TWN2T: parseInt(data.TWN2T),
                TWN3T: parseInt(data.TWN3T),

                CYR_FILL: parseInt(data.CYR_FILL),
                CYR_BS: parseInt(data.CYR_BS),
            },
            MAG_C_CH: parseInt(data.MAG_C_CH),
        }

        const getAdditional = (data) => {
            const allRegistryKeys = Object.keys(data);
            let addArr = []

            return allRegistryKeys 
                .map((add) => add.match(/^OB\d+/)?.[0])
                .reduce((all, current) => {
                    if (current && !all.includes(current)) {
                        addArr.push(current)
                    }
                    return addArr
                })
        }

        for (let i = 0; i < getAdditional(data).length; i++) {
            registries.main = {...registries.main,
                circuit: {
                    temp: {
                        ...registries.main.circuit.temp,
                        [`OB${i+1}_TCO`]: parseInt(data[`OB${i+1}_TCO`]),
                        [`OB${i+1}_TWEW`]: parseFloat(data[`OB${i+1}_TWEW`]),
                        [`OB${i+1}_PCO`]: parseInt(data[`OB${i+1}_PCO`]),
                        [`OB${i+1}_ZAD_TCO`]: parseInt(data[`OB${i+1}_ZAD_TCO`]),
                        [`OB${i+1}_TKMF`]: parseInt(data[`OB${i+1}_TKMF`]),
                        [`OB${i+1}_KRZYWA`]: parseInt(data[`OB${i+1}_KRZYWA`]),
                        [`OB${i+1}_TKMF_BAZ`]: parseInt(data[`OB${i+1}_TKMF_BAZ`]),
                        [`OB${i+1}_XZM_TRANSM`]: parseInt(data[`OB${i+1}_XZM_TRANSM`]),
                        map: {
                            ...registries.main.circuit.temp.map,
                            [`OB${i+1}_XZM_TRANSM`]: parseInt(data[`OB${i+1}_XZM_TRANSM`]),
                        }
                    }
                },
                scada: {
                    ...registries.main.scada,
                    [`OB${i+1}_TCO`]: parseInt(data[`OB${i+1}_TCO`]),
                    [`OB${i+1}_TWEW`]: parseFloat(data[`OB${i+1}_TWEW`]),
                    [`OB${i+1}_PCO`]: parseInt(data[`OB${i+1}_PCO`]),
                    [`OB${i+1}_ZAD_TCO`]: parseInt(data[`OB${i+1}_ZAD_TCO`]),
                    [`OB${i+1}_XZM_TRANSM`]: parseInt(data[`OB${i+1}_XZM_TRANSM`]),
                },
            }
        }

            let extDataArray = []
            let extenders = {}
    
            for (let i = 0; i < receivedData.length; i++) {
                if (receivedData[i].Type === 'FriskoEXT') {
                    const extTabData = receivedData[i];
                    extDataArray[i] = extTabData.Data;
                    extenders[`extender_${receivedData[i].Name.slice(-1)}`] = {
                            extDevice: {
                                Name: extTabData.Name,
                                Type: extTabData.Type,
                                Version: extTabData.Version,
                            },
                            extScada: {
                                TSM9: parseFloat(data.TSM9),
                                TSM10: parseFloat(data.TSM10),
                                TSM16: parseFloat(data.TSM16),
                                OB1_TWEW: parseFloat(data.OB1_TWEW),
                                OB2_TWEW: parseFloat(data.OB2_TWEW),
                                OB3_TWEW: parseFloat(data.OB3_TWEW),
                                OB1_XZM_TRANSM: parseInt(data.OB1_XZM_TRANSM),
                                OB2_XZM_TRANSM: parseInt(data.OB2_XZM_TRANSM),
                                OB3_XZM_TRANSM: parseInt(data.OB3_XZM_TRANSM),
                                OB1_TCO: parseFloat(data.OB1_TCO),
                                SIO_R07: parseInt(data.SIO_R07),
                                SIO_R05: parseInt(data.SIO_R05),
                                SIO_R03: parseInt(data.SIO_R03),  
                                SIO2_R11_P_REGEN: parseInt(extDataArray[i].SIO2_R11_P_REGEN),
                                SIO_R08: parseInt(data.SIO_R08),
                                SIO_R06: parseInt(data.SIO_R06),
                                SIO_R02: parseInt(data.SIO_R02),
                                SIO_R09: parseInt(data.SIO_R09),
                                SIO2_R07_DZ: parseInt(extDataArray[i].SIO2_R07_DZ),
                                T_Z2_1: parseFloat(extDataArray[i].T_Z2_1),
                                T_Z2_2: parseFloat(extDataArray[i].T_Z2_2),
                                T_Z2_3: parseFloat(extDataArray[i].T_Z2_3),
                                PV_READY_AC: parseInt(extDataArray[i].PV_READY_AC),
                                SIO2_R02_G2: parseInt(extDataArray[i].SIO2_R02_G2),
                                SIO2_R01_G1: parseInt(extDataArray[i].SIO2_R01_G1),
                                TSM17: parseFloat(data.TSM17),
                                TSM15: parseFloat(data.TSM15),
                                TSM13: parseFloat(data.TSM13),
                                TSM14: parseFloat(data.TSM14),
                                T_OB_CHL: parseFloat(extDataArray[i].T_OB_CHL),
                                T_ODW_OUT: parseFloat(extDataArray[i].T_ODW_OUT),
                                T_ODW_IN: parseFloat(extDataArray[i].T_ODW_IN),
                                T3fiz: parseFloat(data.T3fiz),
                                T4fiz: parseFloat(data.T4fiz),
                                ADC_1: parseFloat(data.ADC_1),
                                ADC_2: parseFloat(data.ADC_2),
                                ADC_3: parseFloat(data.ADC_3),
                                SXF_TOE: parseInt(data.SXF_TOE),
                                SBF_OOF: parseInt(data.SBF_OOF),
                                SBB_QHT: parseInt(data.SBB_QHT),
                                SBB_QHW: parseInt(data.SBB_QHW),
                                SBF_DHS: parseInt(data.SBF_DHS),
                                SBF_DHX: parseInt(data.SBF_DHX),
                                SIO2_R08_PCHL: parseInt(extDataArray[i].SIO2_R08_PCHL),
                                VER_S1: parseInt(data.VER_S1),
                                VER_S2: parseInt(data.VER_S2),
                                MAG_C_CH: parseInt(data.MAG_C_CH),
                                SPDHW_ZAD: parseFloat(data.SPDHW_ZAD),
                                TB1: parseFloat(data.TB1),
                                TB2: parseFloat(data.TB2),
                                SPCU: parseFloat(data.SPCU),
                                CUN_D_HCU: parseFloat(data.CUN_D_HCU),
                                SCCU: parseFloat(data.SCCU),
                            },
                            LICZNIK_PV_PWR_NADPROD: parseInt(extDataArray[i].LICZNIK_PV_PWR_NADPROD),
                            PW_G: parseInt(extDataArray[i].PW_G),
                            PZ_G: parseInt(extDataArray[i].PZ_G),
                            PW_P: parseInt(extDataArray[i].PW_P),
                            PZ_P: parseInt(extDataArray[i].PZ_P),
                            PZ_P_G: parseInt(extDataArray[i].PZ_P_G),
                            SP_CO_G: parseFloat(extDataArray[i].SP_CO_G),
                            SP_CWU_G: parseFloat(extDataArray[i].SP_CWU_G),
                            SP_T_REGEN: parseFloat(extDataArray[i].SP_T_REGEN),
                            SP_CWU_REGENER: parseFloat(extDataArray[i].SP_CWU_REGENER),
                            SP_CH_Z2: parseFloat(extDataArray[i].SP_CH_Z2),
                            T01_AOR_EN: parseInt(extDataArray[i].T01_AOR_EN),
                            T01_MAN: parseFloat(extDataArray[i].T01_MAN),
                            T02_AOR_EN: parseInt(extDataArray[i].T02_AOR_EN),
                            T02_MAN: parseFloat(extDataArray[i].T02_MAN),
                            T03_AOR_EN: parseInt(extDataArray[i].T03_AOR_EN),
                            T03_MAN: parseFloat(extDataArray[i].T03_MAN),
                            T04_AOR_EN: parseInt(extDataArray[i].T04_AOR_EN),
                            T04_MAN: parseFloat(extDataArray[i].T04_MAN),
                            T05_AOR_EN: parseInt(extDataArray[i].T05_AOR_EN),
                            T05_MAN: parseFloat(extDataArray[i].T05_MAN),
                            T06_AOR_EN: parseInt(extDataArray[i].T06_AOR_EN),
                            T06_MAN: parseFloat(extDataArray[i].T06_MAN),
                            T07_AOR_EN: parseInt(extDataArray[i].T07_AOR_EN),
                            T07_MAN: parseFloat(extDataArray[i].T07_MAN),
                            T08_AOR_EN: parseInt(extDataArray[i].T08_AOR_EN),
                            T08_MAN: parseFloat(extDataArray[i].T08_MAN),
                            T09_AOR_EN: parseInt(extDataArray[i].T09_AOR_EN),
                            T09_MAN: parseFloat(extDataArray[i].T09_MAN),
                            R01_AOR_EN: parseInt(extDataArray[i].R01_AOR_EN),
                            R01_AOR: parseInt(extDataArray[i].R01_AOR),
                            R02_AOR_EN: parseInt(extDataArray[i].R02_AOR_EN),
                            R02_AOR: parseInt(extDataArray[i].R02_AOR),
                            R03_AOR_EN: parseInt(extDataArray[i].R03_AOR_EN),
                            R03_AOR: parseInt(extDataArray[i].R03_AOR),
                            R04_AOR_EN: parseInt(extDataArray[i].R04_AOR_EN),
                            R04_AOR: parseInt(extDataArray[i].R04_AOR),
                            R05_AOR_EN: parseInt(extDataArray[i].R05_AOR_EN),
                            R05_AOR: parseInt(extDataArray[i].R05_AOR),
                            R06_AOR_EN: parseInt(extDataArray[i].R06_AOR_EN),
                            R06_AOR: parseInt(extDataArray[i].R06_AOR),
                            R07_AOR_EN: parseInt(extDataArray[i].R07_AOR_EN),
                            R07_AOR: parseInt(extDataArray[i].R07_AOR),
                            R08_AOR_EN: parseInt(extDataArray[i].R08_AOR_EN),
                            R08_AOR: parseInt(extDataArray[i].R08_AOR),
                            R09_AOR_EN: parseInt(extDataArray[i].R09_AOR_EN),
                            R09_AOR: parseInt(extDataArray[i].R09_AOR),
                            R10_AOR_EN: parseInt(extDataArray[i].R010_AOR_EN),
                            R10_AOR: parseInt(extDataArray[i].R010_AOR),
                            R11_AOR_EN: parseInt(extDataArray[i].R011_AOR_EN),
                            R11_AOR: parseInt(extDataArray[i].R011_AOR),
                            T_CWU_2: parseFloat(extDataArray[i].T_CWU_2),
                            T_BUF_2: parseFloat(extDataArray[i].T_BUF_2),
                            T_Z2_2: parseFloat(extDataArray[i].T_Z2_2),
                            T_Z2_3: parseFloat(extDataArray[i].T_Z2_3),
                            T_PVT: parseFloat(extDataArray[i].T_PVT),
                            T_ODW_IN: parseFloat(extDataArray[i].T_ODW_IN),
                            SIO2_R09_PV_READY: parseInt(extDataArray[i].SIO2_R09_PV_READY),
                            SIO2_R08_DOL_ZR_BUF_Z2: parseInt(extDataArray[i].SIO2_R08_DOL_ZR_BUF_Z2),
                            SIO2_R07_DOL_ZR_ODW: parseInt(extDataArray[i].SIO2_R07_DOL_ZR_ODW),
                            SIO2_R03_REGEN_OTW: parseInt(extDataArray[i].SIO2_R03_REGEN_OTW),
                            SIO2_R04_REGEN_ZAMK: parseInt(extDataArray[i].SIO2_R04_REGEN_ZAMK),
                            SIO2_R05_MIESZ_CHL_OTW: parseInt(extDataArray[i].SIO2_R05_MIESZ_CHL_OTW),
                            SIO2_R06_MIESZ_CHL_ZAM: parseInt(extDataArray[i].SIO2_R06_MIESZ_CHL_ZAM),
                            SIO2_R10_PVT: parseInt(extDataArray[i].SIO2_R10_PVT),
                            SIO2_B01: parseInt(extDataArray[i].SIO2_B01),
                            SIO2_B02: parseInt(extDataArray[i].SIO2_B02),
                            SIO2_B03: parseInt(extDataArray[i].SIO2_B03),
                            SIO2_B04: parseInt(extDataArray[i].SIO2_B04),
                            SIO2_B05: parseInt(extDataArray[i].SIO2_B05),
                            PV_READY_THR1: parseInt(extDataArray[i].ST_PV_PV_READY_THR1),
                            PV_READY_THR2: parseInt(extDataArray[i].ST_PV_ST_PV_PV_READY_THR2),
                            PV_READY_THR3: parseInt(extDataArray[i].ST_PV_PV_READY_THR3),
                            LICZNIK_PV_KOM: parseInt(extDataArray[i].LICZNIK_PV_KOM),
                            LICZNIK_PC_KOM: parseInt(extDataArray[i].LICZNIK_PC_KOM),
                            LICZNIK_PV_PWR_ODB: parseInt(extDataArray[i].LICZNIK_PV_PWR_ODB),
                            PWM1_AOR_EN: parseInt(extDataArray[i].PWM1_AOR_EN),
                            B01_AOR: parseInt(extDataArray[i].B01_AOR),
                            B02_AOR: parseInt(extDataArray[i].B02_AOR),
                            B03_AOR: parseInt(extDataArray[i].B03_AOR),
                            B04_AOR: parseInt(extDataArray[i].B04_AOR),
                            B05_AOR: parseInt(extDataArray[i].B05_AOR),
                            B01_AOR_EN: parseInt(extDataArray[i].B01_AOR_EN),
                            B02_AOR_EN: parseInt(extDataArray[i].B02_AOR_EN),
                            B03_AOR_EN: parseInt(extDataArray[i].B03_AOR_EN),
                            B04_AOR_EN: parseInt(extDataArray[i].B04_AOR_EN),
                            B05_AOR_EN: parseInt(extDataArray[i].B05_AOR_EN),
                            LICZNIK_PV_PWR_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PV_PWR_FLOAT1),
                            LICZNIK_PV_PWR_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PV_PWR_FLOAT2),
                            LICZNIK_PV_ENE_IMP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PV_ENE_IMP_FLOAT1),
                            LICZNIK_PV_ENE_IMP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PV_ENE_IMP_FLOAT2),
                            LICZNIK_PV_ENE_EXP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PV_ENE_EXP_FLOAT1),
                            LICZNIK_PV_ENE_EXP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PV_ENE_EXP_FLOAT2),
                            LICZNIK_PC_PWR_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PC_PWR_FLOAT1),
                            LICZNIK_PC_PWR_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PC_PWR_FLOAT2),
                            LICZNIK_PC_ENE_IMP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PC_ENE_IMP_FLOAT1),
                            LICZNIK_PC_ENE_IMP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PC_ENE_IMP_FLOAT2),
                            LICZNIK_PC_ENE_EXP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PC_ENE_EXP_FLOAT1),
                            LICZNIK_PC_ENE_EXP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PC_ENE_EXP_FLOAT2),
                            PWM1_MAN: parseFloat(extDataArray[i].PWM1_MAN),
                            PWM2_MAN: parseFloat(extDataArray[i].PWM2_MAN),
                            LICZNIK_PV_PWR_NADPROD_AR: parseFloat(extDataArray[i].LICZNIK_PV_PWR_NADPROD_AR),
                            LICZNIK_PV_PWR_ODB_AR: parseFloat(extDataArray[i].LICZNIK_PV_PWR_ODB_AR),
                            VER_DZIEN_2: parseInt(extDataArray[i].VER_DZIEN_2),
                            VER_MIESIAC_2: parseInt(extDataArray[i].VER_MIESIAC_2),
                            VER_ROK_2: parseInt(extDataArray[i].VER_ROK_2), 
                        }
                    }
                    registries = {
                        ...registries,
                        extenders
                    }
            }  
            return registries    
        } else {
            return null
        }
        
    }