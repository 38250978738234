import React, { useReducer, useEffect, useState } from 'react'
import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FiberManualRecord, LocationSearchingOutlined } from '@material-ui/icons'
import { mainStyleClasses } from '../../theme'

import { registerDictionary } from '../../data/dataDictionary'
import { dataRange } from '../../data/dataRange'

import { CarouselInputMulti, ItemMapping } from '../../components'

const Antylegionella = ({ settings, antyException, setPublish }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary().antylegionella
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0 })
    const filteredItems = Object.entries(itemValue ? itemValue : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))

    const handleClickOpen = (element, name, title, min, max, step, num, val) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val})
    }

    const handleClose = () => {
        setItemValue({...itemValue, [open.name]: open.val})
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (name, val) => {
        setOpen({
            ...open,
            val: val
        })
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])
    
    const handleSwitchValue = (e, item) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, [e.name]: 0 })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, [e.name]: 1})
            setPublish({ [e.name]: 1 })
        }
    }

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    // const switchControl = (entry, item) => {
    //     switch (dataRange()[entry[0]].controller) {
    //         case 1:
    //             return (
    //                     <Grid item xs={2} className={classes.textRight}>
    //                         <Switch
    //                             checked={item[entry[0]] === 0 ? false : true}
    //                             onChange={(e) => handleSwitchValue(e.target, item)}
    //                             color="primary"
    //                             name={entry[0]}
    //                             inputProps={{ 'aria-label': 'primary checkbox' }}
    //                         />
    //                     </Grid>
    //             )
    //         case 2:
    //             let minValue = typeof (dataRange()[entry[0]].min) === "string" ? parseFloat(item[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
    //             let maxValue = typeof (dataRange()[entry[0]].max) === "string" ? parseFloat(item[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)
    //             return (
    //                 <Grid item xs={2} className={classes.textRight} onClick={() => handleClickOpen(entry[1], entry[0], dictionary[entry[0]], minValue, maxValue, dataRange()[entry[0]].step, entry[0], entry[1])}>
    //                     <Typography variant="body2" className={"carousel-input-click"}>{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]} {dataRange()[entry[0]].symbol}</Typography>
    //                 </Grid>
    //             )

    //         default:
    //             break;
    //     }
    // }

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid container style={{ padding: '0 24px' }}>
                <Grid item xs={12}>
                    <Typography variant="h2">Antylegionella</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, filteredItems)}
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}   style={{ paddingRight: '24px' }}>
                        <Grid item xs={10}>
                            <Typography variant="body2">Liczba dób pozostał do uruchomenia trybu antylegionella</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.textRight}>{antyException.ANL_CLOCK < 0 ? 0 : antyException.ANL_CLOCK}</Typography>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        {settings ? filteredItems.map(entry => {
                            if (entry[1] !== undefined) {
                                return (
                                    <Grid container className={classes.containerVerticalCenter} key={entry[0]}>
                                        <Grid item xs={9}><Typography variant="body2">{dictionary[entry[0]]}</Typography></Grid>

                                        {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                            switchControl(entry, settings) : <Grid item xs={2} className={classes.textRight}>
                                            {dataRange()[entry[0]].controller === 4 ? entry[1] === 1 ? 
                                                <FiberManualRecord style={{ color: "green" }} /> : <FiberManualRecord style={{ color: "gray" }} />: 
                                                <Typography variant="body2">{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1] < 0 ? 0 : entry[1]}  {dataRange()[entry[0]].symbol}</Typography>}
                                                </Grid>}
                                    </Grid>
                                )
                            } else {
                                return null
                            }
                        }) : null
                        }
                    </Grid>*/}
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Antylegionella