import fetchRefreshToken from './fetchRefreshToken'

const fetchAddServiceNote = async (id, payload, action) => {
  const newToken = await fetchRefreshToken().then(response => response)
    let response
    if(action === "POST"){
    response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/service_notes`, {
      method: action,
      headers:{
        "Authorization": `Bearer ${newToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
  } else if(action === "PUT"){
      response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/service_notes/${id}`, {
      method: action,
      headers:{
        "Authorization": `Bearer ${newToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
  }

    return response
 }

  export default fetchAddServiceNote