import React, { useState, useEffect } from 'react'
import { Grid, Typography,Dialog, DialogContent, IconButton } from '@material-ui/core'
import { CarouselInputMulti } from '../../../../components'
import { mainStyleClasses } from '../../../../theme'
import { dataRange } from '../../../../data/dataRange'
import ALEGIONELLA_on from '../../../../img/ALEGIONELLA-on.svg'
import ALEGIONELLA_off from '../../../../img/ALEGIONELLA-off.svg'
import faza1_al from '../../../../img/faza1_AL.svg'
import faza2_al from '../../../../img/faza2_AL.svg'
import faza3_al from '../../../../img/faza3_AL.svg'
import X from '../../../../img/x.svg'
import Back from '../../../../img/powrot.svg'
import { Link } from 'react-router-dom'

const Antylegionella = ({ settings, setPublish, url }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings);
    const [open, setOpen] = useState({ status: false, name: "", init: 0, min: 0, max: 0, num: "", title: "", val: 0 })

    const handleClose = () => {
        setState({ ...state, [open.name]: open.val })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({ 
            ...open, 
            val: val 
        })
        // setPublish({ [num]: val })
    }

    const handleClickOpen = (name, init, min, max, num, title, val) => {
        setOpen({ status: true, name: name, init: init, min: min, max: max, num: num, title: title, val: val })
    }

    const handleModeSwitch = (modeName) => {
        if (settings.SBF_PHT === 1) {
            setPublish({ [modeName]: 0 })
            setState({...state, [modeName]: 0})
        } else { 
            setPublish({ [modeName]: 1 })
            setState({...state, [modeName]: 1})
        }
    }
    
    useEffect(() => {
        let mounted = true

        setState(settings)

        return () => {
            mounted = false
        }
    }, [settings])

    /* useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
      }, []); */

    return (
        <React.Fragment>
        <Grid container>
            <Grid item xs={12} style={{marginLeft: '600px' }}>
                {/*<Typography variant="h3" style={{ display: 'inline-block' }}>Ustawienia</Typography>*/}
                <div className='back-btn' style={{ marginTop: "20px" }}>
                    <Link to={`${url}/settings`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link>
                </div>
            </Grid>
        </Grid>
            {state.SXF_ANL === 1 ?
                <Grid container style={{ padding: '5px 30px 0px' }}>
                    {state.SBF_DHS === 1 ?
                        <Grid container>
                            <Grid item xs={4} className='text-centering'>
                                <Typography variant='h5' style={{ margin: 0 }}>Dezynfekcja</Typography>
                            </Grid>
                            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleModeSwitch('SBF_PHT')}>
                                {state.SBF_PHT === 1 || state.ANL_ALL === 1 ? 
                                    <img alt="antylegionella, wyłącz" style={{ margin: '10px auto' }} className='mode-icon' src={ALEGIONELLA_on}/>
                                    : <img alt="antylegionella, włącz" style={{ margin: '10px auto' }} className='mode-icon' src={ALEGIONELLA_off}/>
                                }  
                            </Grid>
                            <Grid item xs={6} className='text-centering'>
                                <Typography variant='h5' style={{ margin: 0 }}>{state.SBF_PHT === 1 || state.ANL_ALL === 1 ? 'wyłącz' : 'włącz'}</Typography>
                            </Grid>
                        </Grid>
                        : <Grid container>
                            <Grid item xs={12} className='text-centering'>
                                <Typography variant='h5' style={{ margin: '46px auto' }}>Brak możliwości uruchomienia funkcji</Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid container>
                        <Grid item xs={4} className='text-centering'>
                            <Typography variant='h5' style={{ margin: 0 }}>Cyklicznie co</Typography>
                        </Grid>
                        <Grid item xs={2} onClick={() => handleClickOpen("ANL_DAY", state.ANL_DAY, dataRange().ANL_DAY.min, dataRange().ANL_DAY.max, "ANL_DAY", "Cykliczne uruchamianie antylegionelli", state.ANL_DAY)}>
                            <Typography variant='h5' style={{ margin: '10px auto', borderRadius: '10px' }} className="modeEdit-box">{state.ANL_DAY}</Typography>
                        </Grid>
                        <Grid item xs={6} className='text-centering'>
                            <Typography variant='h5' style={{ margin: 0 }}>dni, następna{state.ANL_DAY === 0 || state.ANL_CLOCK < 0 ? ': nigdy' : ` za ${state.ANL_CLOCK} dni`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} className='text-centering'>
                            <Typography variant='h5' style={{ margin: 0 }}>Temp. max CWU</Typography>
                        </Grid>
                        <Grid item xs={2} onClick={() => handleClickOpen("ANL_SETP_ZAD", state.ANL_SETP_ZAD, dataRange().ANL_SETP_ZAD.min, dataRange().ANL_SETP_ZAD.max, "ANL_SETP_ZAD", "Cykliczne uruchamianie antylegionelli", state.ANL_SETP_ZAD)}>
                            <Typography variant='h5' style={{ margin: '10px auto', borderRadius: '10px' }} className="modeEdit-box">{state.ANL_SETP_ZAD}&deg;C</Typography>
                        </Grid>
                        <Grid item xs={6} className='text-centering'>
                            <Typography variant='h5' style={{ margin: 0 }}>obecna temp. CWU {state.TSM15}&deg;C</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs={1}>
                            <svg version="1.1" id="linijka" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 60 170" style={{ marginTop: '20px', maxHeight: '150px' }}>
                                <g id="pierwszy_wlaczony">
                                    <path className={state.ANL_1PH === 1 ? "st0" : null} d="M16.3,43.6H8c-2.5,0-4.6-2.2-4.6-4.9v-28c0-5.6,7.4-6.8,9-1.5l8.2,28C21.6,40.4,19.4,43.6,16.3,43.6z"/>
                                </g>
                                <g id="drugi_wlaczony">
                                    <path className={state.ANL_2PH === 1 ? "st0" : null} d="M34.5,105.4H8c-2.5,0-4.6-2.2-4.6-4.9v-43c0-2.7,2.1-4.9,4.6-4.9h13.8c2,0,3.8,1.4,4.4,3.4l12.7,43
                                        C39.8,102.2,37.6,105.4,34.5,105.4z"/>
                                </g>
                                <g id="trzeci_wlaczony">
                                    <path className={state.ANL_3PH === 1 ? "st0" : null} d="M51.7,163.8H8c-2.5,0-4.6-2.2-4.6-4.9v-39.7c0-2.7,2.1-4.9,4.6-4.9h32c2,0,3.8,1.4,4.4,3.4l11.7,39.7
                                    C57,160.6,54.8,163.8,51.7,163.8z"/>
                                </g>
                                <g id="pierwszy">
                                    <path className="st0" d="M8.1,5.8c1.8,0,3.6,1.1,4.3,3.4l8.2,28c0.9,3.2-1.3,6.4-4.4,6.4H8c-2.5,0-4.6-2.2-4.6-4.9v-28
                                        C3.5,7.6,5.8,5.8,8.1,5.8 M8.1,4.8L8.1,4.8c-2.7,0-5.6,2.1-5.6,5.9v28C2.5,42,5,44.6,8,44.6h8.2c1.7,0,3.3-0.8,4.4-2.2
                                        c1.1-1.5,1.5-3.5,0.9-5.4L13.4,9C12.6,6.4,10.6,4.8,8.1,4.8L8.1,4.8z"/>
                                </g>
                                <g id="drgi">
                                    <path className="st0" d="M21.8,52.6c2,0,3.8,1.4,4.4,3.4l12.7,43c0.9,3.2-1.3,6.4-4.4,6.4H8c-2.5,0-4.6-2.2-4.6-4.9v-43
                                        c0-2.7,2.1-4.9,4.6-4.9H21.8 M21.8,51.6H8c-3.1,0-5.6,2.6-5.6,5.9v43c0,3.3,2.5,5.9,5.6,5.9h26.4c1.7,0,3.3-0.8,4.4-2.2
                                        c1.1-1.5,1.5-3.5,0.9-5.4l-12.7-43C26.4,53.2,24.3,51.6,21.8,51.6L21.8,51.6z"/>
                                </g>
                                <g id="trzeci">
                                    <path className="st0" d="M40,114.4c2,0,3.8,1.4,4.4,3.4l11.7,39.7c0.9,3.2-1.3,6.4-4.4,6.4H8c-2.5,0-4.6-2.2-4.6-4.9v-39.7
                                        c0-2.7,2.1-4.9,4.6-4.9H40 M40,113.4H8c-3.1,0-5.6,2.6-5.6,5.9v39.7c0,3.3,2.5,5.9,5.6,5.9h43.7c1.7,0,3.3-0.8,4.4-2.2
                                        c1.1-1.5,1.5-3.5,0.9-5.4l-11.7-39.7C44.7,115,42.5,113.4,40,113.4L40,113.4z"/>
                                </g>
                            </svg>                
                        </Grid>
                        <Grid item xs={11} className='text-centering'>
                            <Grid container style={{ marginLeft: '35px', marginTop: '10px' }}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Typography variant='h5' style={{ margin: '5px', float: 'left' }}>faza 1 - grzanie pompą ciepła</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <img alt="antylegionella, włącz" style={{ marginTop: '13px' }} src={faza1_al}/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Typography variant='h5' style={{ margin: '5px', float: 'left' }}>faza 2 - grzanie grzałką</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <img alt="antylegionella, włącz" style={{ marginTop: '13px' }} src={faza2_al}/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={11}>
                                    <Typography variant='h5' style={{ margin: '5px', float: 'left' }}>faza 3 - chłodzenie CWU cyrkulacją</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <img alt="antylegionella, włącz" style={{ marginTop: '13px' }} src={faza3_al}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            : 
                <Grid container alignItems="flex-start" style={{ minHeight: '511px' }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className='text-centering'>
                            <Typography variant='h5' style={{ marginTop: '245px', textAlign: 'center' }}>Funkcja Antylegionella jest wyłączona.<br/> Włącz zezwolenie na załączenie trybu Antylegionella.</Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                </Grid>
            } 
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Antylegionella