import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const dayWeek = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela']

const slideList = (min, max, name) => {
    const tableList = []
    
        for (let i = min; i <= max; i++) {
            if ((name === "ECOY1" || name ==="ECOX1" || name === "ECOY2" || name === "ECOX2") && i !== 0)
            tableList.push(<SwiperSlide key={name+i}>+ {i}</SwiperSlide>)
            else if ((name === "ECOTIN" || name === "ECODHW")  && i !== 0)
            tableList.push(<SwiperSlide key={name+i}>- {i}</SwiperSlide>)
            else if(name === "ST_DZEN_TYG")
            tableList.push(<SwiperSlide key={name+i}>{dayWeek[i]}</SwiperSlide>)
            else
            tableList.push(<SwiperSlide key={name+i}>{i}</SwiperSlide>)
        }

    return tableList
}

const CarouselInputMulti = ({ init, min, max, name, setVal, num }) => {

    const [slide, setSlide] = useState(init)
    
    const onChangeSlideTime = (index) => {
        setSlide(index - 1)
    }
    const onChangeStop = (index, value) => {
        // console.log(value[index].textContent)
        // console.log(value[index].textContent.replace(/\D+/gi, ""))
        if(name === "ST_DZEN_TYG") {
            setVal(num, dayWeek.findIndex(day => day === value[index].textContent))
        } else {
            setVal(num, parseInt(value[index].textContent.replace(/\D+/gi, "")))
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                    <Swiper
                    // Jeśli dobrze widzę to ta funkcja jest zbędana - Szymon
                    // onTouchEnd={(swiper) => onChangeSlideTime(swiper.activeIndex)}
                    initialSlide={slide - min} watchSlidesVisibility={true} slidesPerView={3}
                    centeredSlides={true} direction="vertical" loop={false} className="mySwiper"
                    // loopAdditionalSlides={18}
                    onSlideChange={(swiper) => onChangeStop(swiper.activeIndex, swiper.slides)}>
                        {slideList(min, max, name)}
                    </Swiper>
            </Grid>
        </Grid>
    )
}

export default CarouselInputMulti