import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../LocalApp.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid, Typography } from '@material-ui/core'

import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useLocation,
  Redirect
} from "react-router-dom"
import { NavbarBox } from './components'
import {
  TemperatureRead, ErrorsWarnings, Home, Crot, Blockade, ControllerTime, EcoModeSettings, PumpTimeControl,
  InternalTemperatureControl, ControlFunctionState
} from './pages'
import { initData } from './dataFilter/dataFilterState'

import { mqttConfig } from '../../config/mqttConfig'
import { mqttClient } from './config/mqttClient'
import { functionPublish } from './config/functionPublish'
import fetchCustomer from '../../data/fetchCustomer'
 
const OnlineManagement = ({ token }) => {
  const [connectionStatus, setConnectionStatus] = useState(false)
  const [messages, setMessages] = useState(null)
  const [publishMsg, setPublish] = useState(null)
  const clientRef = React.useRef(null)
  let { installation, pass } = useParams();
  let location = useLocation();
  const [info, setInfo] = useState({ sn: "", customerToken: "", installation_id: "", telemetry: "" })
  // const { sn, customerToken, installation_id, telemetry } = location.state
  const [customer, setCustomer] = useState({ first_name: "", last_name: "", phone: "", street: "", street_number: "", post_code: "", city: "" })

  useEffect(() => {
    if(location && location.state && location.state.telemetry) {
        setInfo({ sn: location.state.sn, customerToken: location.state.token,
            installation_id: location.state.installation_id, telemetry: location.state.telemetry })
    }
  }, [])
  
  useEffect(() => {
    let mounted = true
    if (publishMsg && messages.device) {
      console.log({installation})
      functionPublish(clientRef.current, publishMsg, installation, messages.device)
      setPublish(null)
    }
    return function cleanup() {
      mounted = false
    }
  }, [publishMsg, installation])

  useEffect(() => {
    
    clientRef.current = mqttClient.getClient(mqttConfig(`${process.env.REACT_APP_BR_USER}`, `${process.env.REACT_APP_BR_PASS}`));
    // clientRef.current = mqttClient.getClient(mqttConfig(`${installation}`, `${atob(pass)}`));

    clientRef.current.subscribe(`${installation}/Dev`)
    clientRef.current.on('connect', () => setConnectionStatus(true))
    clientRef.current.on('message', function (topic, message) {
      
      if (JSON.parse(message).Devices) {
        let saveToState = initData(JSON.parse(message).Devices);
        saveToState.expirationData = {
          ExpirationTime: JSON.parse(message).ExpirationTime,
          TimeStamp: JSON.parse(message).TimeStamp
        }

        setMessages(saveToState)
       // console.log(JSON.parse(message))
      }
    })
    return () => {
      if (clientRef.current) {
        clientRef.current.unsubscribe(`${installation}/Dev`);
        clientRef.current.end(clientRef.current);
      }
    }

  }, []);

  useEffect(() => {
    if (info.installation_id) {
        fetchCustomer(info.customerToken, info.installation_id)
            .then(response => {
                setCustomer(response)
            })
    }
  }, [info.installation_id, token])


  let { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Grid container className='infobox' justifyContent="center" style={{ maxWidth: "1024px", margin: "auto", marginBottom: "30px" }}>
          <Grid item xs={4}>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{info.sn}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.first_name} {customer.last_name}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.phone}</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{installation}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>ul. {customer.street} {customer.street_number}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.post_code}, {customer.city}</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>Ostatnio online: </Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{info.telemetry.slice(0, 19).replace("T", " ")}</Typography>
          </Grid>
      </Grid>
      <NavbarBox moreParams={{inst: installation, pass}} errWarn={messages? messages.errWarn: null} ext={messages? messages.expirationData: null} />
      {!token ? <Redirect to="/" /> : null}
      <Switch>
        <Route path={`${url}/temperature`}>{messages ? (<TemperatureRead temperature={messages.temperature} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/errorwarnings`}>{messages ? (<ErrorsWarnings errWarn={messages.errWarn} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/crot`}>{messages ? (<Crot crot={messages.crot} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/blockade`}>{messages ? (<Blockade block={messages.block} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/controllertime`}>{messages ? (<ControllerTime setPublish={setPublish} controllertime={messages.controllertime} />) : <CircularProgress />}</Route>
        <Route path={`${url}/ecomodesettings`}>{messages ? (<EcoModeSettings eco={messages.eco} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/pumptimecontrol`}>{messages ? (<PumpTimeControl ptc={messages.ptc} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/internaltemperaturecontrol`}>{messages ? (<InternalTemperatureControl itc={messages.itc} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/controlfunctionstate`}>{messages ? (<ControlFunctionState cfs={messages.cfs} setPublish={setPublish} />) : <CircularProgress />}</Route>
        <Route path={`${url}/`}>{messages ? (<Home main={{
          controllertime: messages.controllertime,
          time: messages.time, ptc: messages.ptc, main: messages.main, heating: messages.heating,
          airConditioning: messages.airConditioning, ecoSettings: messages.ecoSettings, away: messages.away
        }} setPublish={setPublish} />) : <CircularProgress />}</Route>
      </Switch>

    </React.Fragment>
  )
}

export default OnlineManagement;
