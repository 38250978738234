import React, { useState, useEffect } from 'react'
import { Typography, Grid, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTheme, VictoryScatter, VictoryLabel } from 'victory'
import { CarouselInputMulti } from '../../../../components'
import { mainStyleClasses } from '../../../../theme'
import X from '../../../../img/x.svg'
import { dataRange } from '../../../../data/dataRange'

const CoolingCurve = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings)
    const [open, setOpen] = useState({ status: false, name: "", init: 0, min: 0, max: 0, num: "", title: "" })

    const handleClickOpen = (name, init, min, max, num, title) => {
        setOpen({ status: true, name: name, init: init, min: min, max: max, num: num, title: title })
    }

    const handleClose = () => {
        setState({ ...state, [open.num]: open.init })
        setPublish({ [open.num]: open.init })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({ 
            ...open, 
            init: val 
        })
    }

    useEffect(() => {
        let mounted = true
        
        setState(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">Parametry krzywej chłodzenia</Typography>
                </Grid>
                <Grid item xs={5} style={{ zIndex: 1000 }}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{ lineHeight: "60px" }}>Punkt P2 krzywej chłodzenia</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" className="edit-box" onClick={() => handleClickOpen('SX2_P2', state.SX2_P2, state.SX2_P1, dataRange().SX2_P2.max, 'SX2_P2', 'Ustaw punkt P2')}>{state.SX2_P2}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5} style={{ zIndex: 1000 }}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{ lineHeight: "60px" }}>Punkt P1 krzywej chłodzenia</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" className="edit-box" onClick={() => handleClickOpen('SX2_P1', state.SX2_P1, dataRange().SX2_P1.min, state.SX2_P2, 'SX2_P1', 'Ustaw punkt P1')}>{state.SX2_P1}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} style={{ marginTop: "-60px" }}>
                    <VictoryChart>
                        <VictoryAxis
                            width={400}
                            height={400}
                            domain={[10, 40]}
                            // theme={VictoryTheme.material}
                            offsetY={50}
                            orientation="bottom"
                            standalone={false}
                            tickValues={[10, 15, 20, 25, 30, 35, 40]}
                            tickLabelComponent={<VictoryLabel dy={0} style={{ fontSize: 12, fill: '#ffffff' }} />}
                            label="Temperatura zewnętrzna &deg;C"
                            style={{
                                axisLabel: { padding: 35, fill: '#ffffff', fontSize: 14 },
                                axis: { stroke: "#ffffff" },
                                ticks: { stroke: "grey", size: 3 },
                                data: { fill: '#ffffff', stroke: "#ffffff" }
                            }}
                        />
                        <VictoryAxis dependentAxis
                            width={400}
                            height={400}
                            domain={[0, 30]}
                            // theme={VictoryTheme.material}
                            offsetX={50}
                            orientation="left"
                            standalone={false}
                            tickValues={[0, 5, 10, 15, 20, 25, 30]}
                            tickLabelComponent={<VictoryLabel dx={0} style={{ fontSize: 12, fill: '#ffffff' }} />}
                            label="Temperatura obiegu &deg;C"
                            style={{
                                axisLabel: { padding: 35, fill: '#ffffff', fontSize: 14 },
                                axis: { stroke: "#ffffff" },
                                ticks: { stroke: "grey", size: 3 },
                                data: { fill: '#ffffff', stroke: "#ffffff" }
                            }}
                        />

                        <VictoryLine
                            scale={{ x: "linear", y: "linear" }}
                            labels={["", `P2(${state.SX2_P2})`, `P1(${state.SX2_P1})`]}
                            data={[
                                { x: 10, y: state.SX2_P2 },
                                { x: 20, y: state.SX2_P2 },
                                { x: 35, y: state.SX2_P1 },
                                { x: 40, y: state.SX2_P1 }
                            ]}
                            style={{ data: { stroke: "tomato", strokeWidth: 3 }, labels: { fontSize: 12, fill: "#ffffff" } }}

                        />

                        <VictoryScatter
                            data={[
                                { x: 20, y: state.SX2_P2 },
                                { x: 35, y: state.SX2_P1 }
                            ]}
                            style={{ data: { fill: "#ffffff"}}}

                        />

                    </VictoryChart>
                </Grid>
            </Grid>
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default CoolingCurve