import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const NumberInput = ({ descript, value, stamp, setTemperatureState, stateStamp, client, setState, handleSendTemperatureState, min, max }) => {
  const [state, setValidateState] = useState(null)

  useEffect(() => {
    if (value >= min && value <= max) setValidateState(null)
  }, [value, min, max])

  const onlyNumbers = (anyString, value, max, min) => {

    if (anyString.replace(/[^\d]/g, '') === "")
      return value
    else {
      if (anyString.length <= 1) {
        setValidateState(true)
        return anyString.replace(/[^\d]/g, '')
      } else {
        setValidateState(false)
        if (parseInt(anyString) > max && anyString.length > 1)
          return max
        else if (parseInt(anyString) < min && anyString.length > 1)
          return min
        else
          return anyString.replace(/[^\d]/g, '')
      }
    }
  }

  return (
    <React.Fragment>

      <Col xs={8}>
        <Button className="to-left" 
        onClick={e => { if (value > min) { setTemperatureState({ type: stateStamp, payload: value - 1 }); 
        handleSendTemperatureState({ id: stamp, value: value - 1 }, setState) } }}> </Button>
        <Form.Control
          type="text"
          value={value}
          id={stamp}
          onChange={(e) => {
            setTemperatureState({ type: stateStamp, payload: onlyNumbers(e.target.value, value, max, min) });
            if (parseInt(e.target.value) >= min && parseInt(e.target.value) <= max) {
              handleSendTemperatureState({ id: stamp, value: parseInt(e.target.value) }, setState)
            } else if (parseInt(e.target.value) > max && e.target.value.length > 1) {
              handleSendTemperatureState({ id: stamp, value: max }, setState)
            } else if (parseInt(e.target.value) < min && e.target.value.length > 1) {
              handleSendTemperatureState({ id: stamp, value: min }, setState)
            }
          }
          }
          maxLength={2}
          //minLength={2}
        />
        {state ? <p className="error">Zakres {min} - {max} </p> : null}
        <Button className="to-right" 
        onClick={e => { if (value < max) { setTemperatureState({ type: stateStamp, payload: value + 1 }); 
        handleSendTemperatureState({ id: stamp, value: value + 1 }, setState) } }}> </Button>
      </Col>

    </React.Fragment>
  )
}

export default NumberInput