import React, { useState, useEffect } from 'react'
import { Typography, Grid, Dialog, DialogContent, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTheme, VictoryScatter, VictoryLabel } from 'victory'
import { curveCalc } from './curveCalc'
import { CarouselInputMulti } from '../../../../components'
import { mainStyleClasses } from '../../../../theme'

import { dataRange } from '../../../../data/dataRange'
import { registerDictionary } from '../../../../data/dataDictionary'
import { ItemMapping } from '../../../../components'

const drawChartLine = (CUN_HCU, upCurve) => {
    const lineTable = []

    for (let i = 0; i < 10; i++) {
        lineTable.push(
            <VictoryLine
                key={`CUN_HCU_${i}`}
                interpolation="basis"
                data={curveCalc(i, 0)}
                style={{
                    data: { stroke: i === CUN_HCU ? "#42bd6b" : "#e7e7e7", strokeWidth: i === CUN_HCU ? 0.5 : 0.5 }
                }}
            />
        )
    }

    lineTable.push(
        <VictoryLine
            key={`CUN_HCU_active`}
            interpolation="basis"
            data={curveCalc(CUN_HCU, upCurve)}
            style={{
                data: { stroke: "#42bd6b", strokeWidth: 2 }
            }}
        />
    )

    // console.log(curveCalc(CUN_HCU, upCurve))

    return lineTable
}

const HeatingCurve = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: "" })

    const dictionary = registerDictionary().heatingCurveParameters
    
    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={5} style={{ zIndex: '99' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.parameters.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.parameters, undefined, handleClickOpen, dictionary.parameters.data, classes, filteredItems(itemValue.parameters), 'parameters')}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.temp.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.temp, undefined, handleClickOpen, dictionary.temp.data, classes, filteredItems(itemValue.temp), 'temp')}
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.reg.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.reg, undefined, handleClickOpen, dictionary.reg.data, classes, filteredItems(itemValue.reg), 'reg')}
                            <Grid container className={classes.containerVerticalCenter}   style={{ paddingRight: '24px' }}>
                                <Grid item xs={10}>
                                    <Typography variant="body2">Różnica temp. wewn. akt. i zad. (TSM10 - SX6)</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.textRight}>{(itemValue.reg.TSM10 - itemValue.reg.SX6).toFixed(1)}{dataRange().SX6.symbol}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Grid container>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6}>
                                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item xs={7}>
                    <VictoryChart theme={VictoryTheme.material} style={{ parent: { left: "250px", height: '500px', position: 'absolute' } }}>
                        <VictoryAxis crossAxis
                            width={400}
                            height={400}
                            domain={[-30, 20]}
                            theme={VictoryTheme.material}
                            offsetY={50}
                            standalone={false}
                            tickValues={[-30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20]}
                            tickLabelComponent={<VictoryLabel dy={0} style={{ fontSize: 10 }} />}
                            label="TSM9 [4072] - temperatura zewnętrzna"
                            style={{
                                axisLabel: { padding: 35, fontSize: 10 }
                            }}
                        />
                        <VictoryAxis dependentAxis crossAxis
                            width={400}
                            height={400}
                            domain={[20, 60]}
                            theme={VictoryTheme.material}
                            offsetX={50}
                            standalone={false}
                            tickValues={[20, 25, 30, 35, 40, 45, 50, 55, 60]}
                            tickLabelComponent={<VictoryLabel dx={0} style={{ fontSize: 10 }} />}
                            label="SCCU [4091] - wartość wyliczona"
                            style={{
                                axisLabel: { padding: 35, fontSize: 10 }
                            }}
                        />

                        {drawChartLine(itemValue.parameters.CUN_HCU, itemValue.parameters.CUN_D_HCU)}

                        <VictoryLine
                            key={`TSM13`}
                            interpolation="basis"
                            data={[
                                { x: -30, y: itemValue.temp.TSM13 },
                                { x: 20, y: itemValue.temp.TSM13 }
                            ]}
                            style={{
                                data: { stroke: "#f4f49b", strokeWidth: 1 }
                            }}
                        />
                        <VictoryScatter
                            style={{
                                data: { fill: "#f4f49b" },
                                labels: { fontSize: 8 }
                            }}
                            labels={["", `TSM13(${itemValue.temp.TSM13.toFixed(1)})`]}
                            data={[
                                { x: -30, y: itemValue.temp.TSM13, symbol: "circle", size: 2 },
                                { x: 20, y: itemValue.temp.TSM13, symbol: "circle", size: 2 }
                            ]}

                        />

                        <VictoryLine
                            key={`SPCU`}
                            interpolation="basis"
                            data={[
                                { x: -30, y: itemValue.parameters.SPCU },
                                { x: 20, y: itemValue.parameters.SPCU }
                            ]}
                            style={{
                                data: { stroke: "#ff0000", strokeWidth: 1 }
                            }}
                        />
                        <VictoryScatter
                            style={{
                                data: { fill: "#ff0000" },
                                labels: { fontSize: 8 }
                            }}
                            labels={["", `SPCU(${itemValue.parameters.SPCU.toFixed(1)})`]}
                            data={[
                                { x: -30, y: itemValue.parameters.SPCU, symbol: "circle", size: 2 },
                                { x: 20, y: itemValue.parameters.SPCU, symbol: "circle", size: 2 }
                            ]}

                        />

                        <VictoryLine
                            key={`TB1`}
                            interpolation="basis"
                            data={[
                                { x: -30, y: itemValue.parameters.TB1 },
                                { x: 20, y: itemValue.parameters.TB1 }
                            ]}
                            style={{
                                data: { stroke: "#000080", strokeWidth: 1 }
                            }}
                        />
                        <VictoryScatter
                            style={{
                                data: { fill: "#000080" },
                                labels: { fontSize: 8 }
                            }}
                            labels={["", `TB1(${itemValue.parameters.TB1.toFixed(1)})`]}
                            data={[
                                { x: -30, y: itemValue.parameters.TB1, symbol: "circle", size: 2 },
                                { x: 20, y: itemValue.parameters.TB1, symbol: "circle", size: 2 }
                            ]}

                        />
                        <VictoryLine
                            key={`TSM9`}
                            interpolation="basis"
                            data={[
                                { x: itemValue.parameters.TSM9, y: 20 },
                                { x: itemValue.parameters.TSM9, y: itemValue.temp.TSM13 }
                            ]}
                            style={{
                                data: { stroke: "#ff6223", strokeWidth: 1 }
                            }}
                        />
                        <VictoryScatter
                            style={{
                                data: { fill: "#ff6223" },
                                labels: { fontSize: 8 }
                            }}
                            labels={["", `TSM9(${itemValue.parameters.TSM9.toFixed(1)})`]}
                            data={[
                                { x: itemValue.parameters.TSM9, y: 20, symbol: "circle", size: 2 },
                                { x: itemValue.parameters.TSM9, y: itemValue.temp.TSM13, symbol: "circle", size: 2 }
                            ]}

                        />
                        <VictoryLine
                            key={`SX1`}
                            interpolation="basis"
                            data={[
                                { x: itemValue.parameters.TSM9, y: itemValue.parameters.SPCU },
                                { x: itemValue.parameters.TSM9, y: itemValue.parameters.TB1 }
                            ]}
                            style={{
                                data: { stroke: "#a2007b", strokeWidth: 1 }
                            }}
                        />
                        <VictoryScatter
                            style={{
                                data: { fill: "#a2007b" },
                                labels: { fontSize: 8 }
                            }}
                            labels={[`SX1(${itemValue.parameters.SX1.toFixed(1)})`]}
                            data={[
                                { x: itemValue.parameters.TSM9, y: itemValue.parameters.SPCU, symbol: "circle", size: 2 },
                                { x: itemValue.parameters.TSM9, y: itemValue.parameters.TB1, symbol: "circle", size: 2 }
                            ]}

                        />

                    </VictoryChart>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default HeatingCurve