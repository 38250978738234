import React, {useState, useEffect} from 'react'
import { mainStyleClasses } from '../../theme'
import { HeatingCurve, CoolingCurve, FactorySettings } from './components'
import { AppBar } from '@material-ui/core'
import { TabPanel, Tab, Tabs } from '../../components'

const Parameters = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const { heatingCurve, coolingCurve, factorySetting } = settings
    const [value, setValue] = useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    // useEffect(() => {
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function (event){
    //     window.history.pushState(null, document.title,  window.location.href);
    // });
    // }, []);

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
        <AppBar position="static" style={{boxShadow: "none"}}>
            <Tabs value={value} onChange={handleChange} aria-label="nawigacja">
            <Tab label="Krzywa grzewcza" />
            <Tab label="Krzywa chłodzenia" />
            <Tab label="Ustawienia fabryczne" />
            </Tabs> 
        </AppBar>
        <TabPanel value={value} index={0}>
            <HeatingCurve settings={heatingCurve} setPublish={setPublish} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <CoolingCurve settings={coolingCurve} setPublish={setPublish} />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <FactorySettings settings={factorySetting} setPublish={setPublish} />
        </TabPanel>
            {/*<Typography variant="body2">Parametry:</Typography>
            <Typography variant="body1">(Wybierz, żeby zobaczyć szczegóły)</Typography>
            <Button variant="contained" color="primary" component={Link} to="/parameters/heatingcurve">Krzywa grzewcza</Button>
            <Button variant="contained" color="primary" style={{margin: "20px"}} component={Link} to="/parameters/coolingcurve">Krzywa chłodzenia</Button>
            <Button variant="contained" color="primary" component={Link} to="/parameters/factorysettings">Ustawienia fabryczne</Button>
            <Switch>
                <Route path="/parameters/heatingcurve"><HeatingCurve settings={heatingCurve} setPublish={setPublish} /></Route>
                <Route path="/parameters/coolingcurve"><CoolingCurve settings={coolingCurve} setPublish={setPublish} /></Route>
                <Route path="/parameters/factorysettings"><FactorySettings settings={factorySetting} setPublish={setPublish} /></Route>
            </Switch>*/}
        </div>
    )
}


export default Parameters