import React, { useState, useReducer, useEffect } from 'react'
import { NumberInput, OptionList } from '../../../../components'
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary'
import heatingIcon from '../../../../../../img/Grzanie2.svg'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: parseInt(action.payload) }
    }
}


const Heating = ({ heating, setPublish }) => {

    const [controller, setHeatingState] = useReducer(switchOption, heating)
    const [state, setState] = useState(null)

    useEffect(() => {
        setHeatingState({ payload: heating, case: "UPDATE_FROM_BROKER" })
    }, [heating])

    let response
    const handleChangeHeating = e => {
        let sendKey;
        let sendData;
        if (e.id) {
            sendKey = e.id;
            sendData = e.value;
            setHeatingState({ type: e.id, payload: e.value })
        } else {
            sendKey = e.target.name;
            sendData = e.target.value;
            setHeatingState({ type: e.target.name, payload: e.target.value })
        }
        setPublish({[sendKey]: sendData})
    }
    
    return (
        <React.Fragment>
            <Row>
                <Navbar bg="primary" variant="dark" style={{ width: "100%" }}>
                    <Navbar.Brand className="mr-auto"><img alt="grzanie" className="mode-icon" src={heatingIcon} /></Navbar.Brand>
                    <Navbar.Brand>OGRZEWANIE</Navbar.Brand>
                </Navbar>
            </Row>
            <Row>
                <Col lg={6} xs={12}>
                    <Row className="bottom-margin">
                        <Col xs={8} className="text-left">
                            <p>{descriptionDictionary.heating.CUN_HCU}</p>
                        </Col>
                        <Col xs={4}>
                            <Form.Control as="select" custom value={controller.CUN_HCU} name="CUN_HCU" onChange={e => handleChangeHeating(e)}>
                                <OptionList nameKey={`CUN_HCU`} start={0} stop={9} />
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="bottom-margin">
                        <Col xs={8} className="text-left">
                            <p>{descriptionDictionary.heating.CUN_D_HCU}</p>
                        </Col>
                        <Col xs={4}>
                            <Form.Control as="select" custom value={controller.CUN_D_HCU} name="CUN_D_HCU" onChange={e => handleChangeHeating(e)}>
                                <OptionList nameKey={`CUN_D_HCU`} start={0} stop={10} />
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-left"><p>{descriptionDictionary.heating.SX4_PH}</p></Col>
                        <Col xs={6}>
                            <Row>
                                <Col xs={4}> &nbsp;</Col>
                                <NumberInput min={5} max={controller.SX4_PC - 1} setState={setState} descript={descriptionDictionary.heating.SX4_PH} value={controller["SX4_PH"]}
                                    stamp={"SX4_PH"} stateStamp={"SX4_PH"} setTemperatureState={setHeatingState} handleSendTemperatureState={handleChangeHeating} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/*state ? (<ToastInfo response={state} close={() => setState(null)} />) : null*/}
        </React.Fragment>
    )
}

export default Heating