export const dataDescriptions = () => {

	const deviceName = sessionStorage.getItem('deviceName')
	let descriptions
	
	switch(deviceName) {
		case 'GEO_1':
			descriptions = {
				A1: "Czas załączenia stopnia 2 źródła szczytowego dla c.o.",
				A2: "Temp. graniczna pracy stopnia 2 źródła szczytowego dla c.o.",
				ANL_1PH: "1 = tryb antylegionella w fazie pierwszej",
				ANL_2PH: "1 = tryb antylegionella w fazie drugiej",
				ANL_3PH: "1 = tryb antylegionella w fazie trzeciej",
				ANL_ALL: "1 = aktywny tryb antylegionella",
				ANL_CLOCK: "Liczba dób pozostałych do uruchomienia trybu antylegionella:",
				ANL_DAY: "Liczba dób dla cykl. uruchomienia trybu antylegionella:",
				ANL_SETP: "Wart. zad. i histereza dla temp. cwu przy wygrzewaniu antylegionella",
				ANL_SETP_HIST: "Histereza kontroli wartości tempeatury cwu w trybie wygrzewania antylegionella",
				ANL_SETP_ZAD: "Wartość zadana do kontroli temp.cwu w trybie wygrzewania antylegionella",
				ANT_FROZ: "NA",
				ASK_STEP: "Rejestr szczeliny czasowej",
				ASK_STEP2: "Licznik koordynacji zapisu",
				AWA_CLOCK: "Liczba dni do samoczynnego wył.  D-AWAY",
				AWAY_CLOCK: "Liczba dni urlopu",
				AWAY_COR: "Korekcja temp.zad.wewn. (D-AWAY)",
				B01: "NA",
				B02: "NA",
				B03: "NA",
				B04: "NA",
				B05: "NA",
				BLK_ENA: "1 = zezwolenie na blokadę czasową - PARAMETR NIEJAWNY!!!",
				BLK_KD1: "Pierwsza część kodu odblokowującego",
				BLK_KD2: "Druga część kodu odblokowującego",
				BLK_KLAW: "NA",
				BLK_LK: "Liczba poprawnie wprowadzonych kodów",
				BLK_LW: "Liczba dni do wyłączenia - bilans",
				BUZER: "NA",
				COC: "Wsp. sterujący mechanizmu REG+",
				COM_ERR: "NA",
				COM_RDY: "NA",
				COMMU: "NA",
				COR_SPRMAX: "Liczba załączeń sprężarki na CWU",
				COR_SPRMIN: "Liczba załączeń sprężarki na CO",
				COR_SPSMAX: "Liczba załączeń sprężarki na AC",
				COR_SPSMIN: "Czas pracy źródła szczytowego -ST2 - tygodnie",
				CORSTA: "Czas pracy źródła szczytowego - ST2 - minuty",
				CPOM: "NA",
				CTPOM1: "NA",
				CTPOM2: "NA",
				CTPOM3: "NA",
				CTPOM4: "NA",
				CTPOM5: "NA",
				CTPOM6: "NA",
				CUN: "Ust. krzywej grzewczej",
				CUN_D_HCU: "Podbicie krzywej grzewczej",
				CUN_HCU: "Numer krzywej grzewczej",
				CWC1P: "Czasy początku i końca strefy nr 1 cwu, czwartek",
				CWC1T: "Korekcja temp. strefy nr 1 cwu, czwartek",
				CWC2P: "Czasy początku i końca strefy nr 2 cwu, czwartek",
				CWC2T: "Korekcja temp. strefy nr 2 cwu, czwartek",
				CWC3P: "Czasy początku i końca strefy nr 3 cwu, czwartek",
				CWC3T: "Korekcja temp. strefy nr 3 cwu, czwartek",
				CWN1P: "Czasy początku i końca strefy nr 1 cwu, niedziela",
				CWN1T: "Korekcja temp. strefy nr 1 cwu, niedziela",
				CWN2P: "Czasy początku i końca strefy nr 2 cwu, niedziela",
				CWN2T: "Korekcja temp. strefy nr 2 cwu, niedziela",
				CWN3P: "Czasy początku i końca strefy nr 3 cwu, niedziela",
				CWN3T: "Korekcja temp. strefy nr 3 cwu, niedziela",
				CWP1P: "Czasy początku i końca strefy nr 1 cwu, poniedziałek",
				CWP1T: "Korekcja temp. strefy nr 1 cwu, poniedziałek",
				CWP2P: "Czasy początku i końca strefy nr 2 cwu, poniedziałek",
				CWP2T: "Korekcja temp. strefy nr 2 cwu, poniedziałek",
				CWP3P: "Czasy początku i końca strefy nr 3 cwu, poniedziałek",
				CWP3T: "Korekcja temp. strefy nr 3 cwu, poniedziałek",
				CWPT1P: "Czasy początku i końca strefy nr 1 cwu, piątek",
				CWPT1T: "Korekcja temp. strefy nr 1 cwu, piątek",
				CWPT2P: "Czasy początku i końca strefy nr 2 cwu, piątek",
				CWPT2T: "Korekcja temp. strefy nr 2 cwu, piątek",
				CWPT3P: "Czasy początku i końca strefy nr 3 cwu, piątek",
				CWPT3T: "Korekcja temp. strefy nr 3 cwu, piątek",
				CWS1P: "Czasy początku i końca strefy nr 1 cwu, środa",
				CWS1T: "Korekcja temp. strefy nr 1 cwu, środa",
				CWS2P: "Czasy początku i końca strefy nr 2 cwu, środa",
				CWS2T: "Korekcja temp. strefy nr 2 cwu, środa",
				CWS3P: "Czasy początku i końca strefy nr 3 cwu, środa",
				CWS3T: "Korekcja temp. strefy nr 3 cwu, środa",
				CWSO1P: "Czasy początku i końca strefy nr 1 cwu, sobota",
				CWSO1T: "Korekcja temp. strefy nr 1 cwu, sobota",
				CWSO2P: "Czasy początku i końca strefy nr 2 cwu, sobota",
				CWSO2T: "Korekcja temp. strefy nr 2 cwu, sobota",
				CWSO3P: "Czasy początku i końca strefy nr 3 cwu, sobota",
				CWSO3T: "Korekcja temp. strefy nr 3 cwu, sobota",
				CWTSW: "Maska dla CWU",
				CWTSW_ARCH: "NA",
				CWTZAK: "NA",
				CWW1P: "Czasy początku i końca strefy nr 1 cwu, wtorek",
				CWW1T: "Korekcja temp. strefy nr 1 cwu, wtorek",
				CWW2P: "Czasy początku i końca strefy nr 2 cwu, wtorek",
				CWW2T: "Korekcja temp. strefy nr 2 cwu, wtorek",
				CWW3P: "Czasy początku i końca strefy nr 3 cwu, wtorek",
				CWW3T: "Korekcja temp. strefy nr 3 cwu, wtorek",
				CYR_BS: "Podstawa czasu sterowania sekwencyjnego pompy cyrkulacyjnej CWU",
				CYR_FILL: "Wypełnienie czasowe pracy pompy cyrkulacyjnej cwu",
				CYR_TGL: "NA",
				CYRBLK: "NA",
				cyrBreak: "Przerwa w pracy pompy cyrkulacyjnej",
				CYRC1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, czwartek",
				CYRC2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, czwartek",
				CYRC3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, czwartek",
				CYRN1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, niedziela",
				CYRN2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, niedziela",
				CYRN3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, niedziela",
				CYRP1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, poniedziałek",
				CYRP2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, poniedziałek",
				CYRP3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, poniedziałek",
				CYRPT1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, piątek",
				CYRPT2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, piątek",
				CYRPT3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, piątek",
				CYRS1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, środa",
				CYRS2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, środa",
				CYRS3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, środa",
				CYRSO1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, sobota",
				CYRSO2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, sobota",
				CYRSO3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, sobota",
				CYRST: "NA",
				CYRSW: "Rejestr maski dla sterowania czasowego cyrkulacją cwu",
				CYRSW_S1: "Załączenie sterowania czasowego CWU dla strefy 1",
				CYRSW_S2: "Załączenie sterowania czasowego CWU dla strefy 2",
				CYRSW_S3: "Załączenie sterowania czasowego CWU dla strefy 3",
				CYRW1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, wtorek",
				CYRW2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, wtorek",
				CYRW3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, wtorek",
				DD1_COMPACT: "Rejestr pomocniczy - data wystapenia błędu",
				DD2_COMPACT: "Rejestr pomocniczy - godzina wystapenia błędu",
				DEFA_ST1: "1 = przywracanie ustawień domyślnych - krok 1",
				DEFA_ST2: "1 = przywracanie ustawień domyślnych - krok 2",
				DETE: "dt na wymienniku odbioru",
				DZIEN: "Dzień czasu rzeczywistego",
				DZIEN_TYG: "Dzien tygodnia daty systemowej",
				ECODHW: "Redukcja temp.zadanej cwu w czasie drogiej energii",
				ECOT1R: "Godz.początu obowiązywania II taryfy rano",
				ECOT1S: "Godz.końca obowiązywania II  taryfy rano",
				ECOT2R_L: "Godz.początku obowiązywania II taryfy po południu w lecie",
				ECOT2R_Z: "Godz.początku obowiązywania II  taryfy po południu w zimie",
				ECOT2S_L: "Godz.końca obowiązywania II taryfy po południu w lecie",
				ECOT2S_Z: "Godz.końca obowiązywania II  taryfy po południu w zimie",
				ECOT3R: "Godz.początu obowiązywania II  taryfy wieczorem",
				ECOT3S: "Godz.końca obowiązywania II  taryfy wieczorem",
				ECOTIN: "Redukcja temp.zadanej wewn. w czasie drogiej energii",
				ECOX1: "Podwyższenie temp.zad.cwu  rano i wieczorem w II taryfie",
				ECOX2: "Podwyższenie temp.zad.cwu po południu w II taryfie",
				ECOY1: "Podwyższenie temp.zad.wewn.  rano i wieczorem w II taryfie",
				ECOY2: "Podwyższenie  temp.zad.wewn. po południu w II taryfie",
				ENB_ENTER: "NA",
				ENB_ESC: "NA",
				ENB_STD: "NA",
				ENB_STG: "NA",
				ENB_STL: "NA",
				ENB_STP: "NA",
				ENTER_ON: "NA",
				ERR1_COIL_PROT: "Błąd przekroczenia zakresu temperatury zewnętrznej ",
				ERR1_FREQ_LT: "Przekroczony zakres częstotliwości względem temperatury otoczenia",
				ERR1_IPM: "Błąd limitu prądu, mocy, temperatury lub startowej różnicy ciśnień",
				ERR1_MCUR: "Błąd limitu prądu w linii zasilającej",
				ERR1_OGTS: "Zbyt wysoka temperatura tłoczenia ",
				ERR1_OIL: "Błąd - zakłócony powrót oleju do sprężarki",
				ERR1_PCUR: "Błąd limitu prądu w linii zasilania sprężarki",
				ERR1_PDF: "Błąd zbyt wysokiego ciśnienia - ograniczenie częstotliwości",
				ERR1_VOLT: "Przekroczony zakres napięcia zasilającego",
				ERR2_CPTRA_F: "Błąd przetwornika ciśnienia",
				ERR2_EPTRA_F: "Błąd przetwornika ciśnienia",
				ERR2_HP_F: "Błąd - zbyt wysokie ciśnienie",
				ERR2_LP_F: "Błąd - zbyt niskie ciśnienie",
				ESC_ON: "NA",
				F1_ON: "NA",
				F2_ON: "NA",
				F3_ON: "NA",
				F4_ON: "NA",
				FC_TRIG: "NA",
				FRC_4DR: "NA",
				FRC_FC: "NA",
				FRC_GANL: "NA",
				FRC_PCHL: "Wymuszenie pracy pompy chłodzenia",
				FRC_PCYR: "Wymuszenie pracy pompy cyrkulacyjnej CWU",
				FRC_PGRZ: "Wymuszenie pracy pompy grzania",
				FRC_PO: "Wymuszenie pracy pompy odbioru",
				FRC_PZ: "NA",
				FRC_ZBIW: "NA",
				FRC_ZCWU: "Wymuszenie pracy zaworu cwu",
				FSET_AC: "Nastawa wydajności dla chłodzenia",
				FSET_CO: "Nastawa wydajności dla ogrzewania",
				FSET_CWU: "Nastawa wydajności  dla cwu",
				FWM: "Wymuszenie manualne funkcji",
				FWM_CL: "NA",
				FWM_COUNT: "Licznik pomocniczy FWM",
				FWM_HT: "NA",
				FWM_HW: "NA",
				GODZINY: "Godziny czasu rzeczywistego",
				HAK_CWU: "NA",
				HPOM: "Limit licznika dynamiki dla ogrzewania",
				IDM: "NA",
				IDM_GRO: "Identyfikator produktu",
				IDM_MAN: "Identyfikator producenta",
				IDP: "Informacje o urządzeniu",
				IDP_EXP: "Typ elementu rozprężnego",
				IDP_MOD: "Funkcja",
				IDP_PWR: "Moc nominalna urządzenia [kW]",
				IDP_SUB: "Linia produktu",
				IHW: "intelligent Hot Water - wsk. min. wydajności",
				IHW_END: "NA",
				IHW_FL: "NA",
				IHW_HIGH: "NA",
				IHW_LOW: "NA",
				KLAW: "NA",
				LED_G: "NA",
				LED_R: "NA",
				LIGHT: "NA",
				MAX_PERF: "Ograniczenie wydajności",
				MB_COMM: "NA",
				MB_COMM2: "NA",
				MB_COMM2_XZM_TRANSM: "Błąd transmisji",
				MB_READY: "NA",
				MB_READY2: "NA",
				MIESIAC: "Miesiąc daty systemowej",
				MIESZACZ_KOM: "NA",
				MINUTY: "Minuty czasu rzeczywistego",
				NR_OBR: "NA",
				NUMER_STER: "Numer sterownika w sieci MODBUS",
				OB1_KRZYWA: "Numer krzywej grzewczej obiegu nr 1",
				OB1_PCO: "Stan pracy pompy obiegu 1",
				OB1_TCO: "Temp. w obiegu nr 1",
				OB1_TKMF: "Temp.wewn. zadan z korektą obiegu nr 1",
				OB1_TKMF_BAZ: "Temp. zadana bazowa",
				OB1_TKMFBAZOWA: "Temp. wewn. zadana bazowa obiegu nr 1",
				OB1_TWEW: "Temp. wewnętrzna",
				OB1_TWEWN: "Temp.wewnętrzna obiegu nr 1",
				OB1_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
				OB1_ZADTCO: "Temp.wyliczona z krzywej obiegu nr 1",
				OB2_KRZYWA: "Krzywa grzewcza",
				OB2_PCO: "Stan pracy obiegu",
				OB2_TCO: "Odczyt temp. obiegu (temp. bufora)",
				OB2_TKMF: "Temp. zad. po korektach",
				OB2_TKMF_BAZ: "Temp. zadana bazowa",
				OB2_TWEW: "Temp. wewnętrzna",
				OB2_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
				OB3_KRZYWA: "Krzywa grzewcza",
				OB3_PCO: "Stan pracy obiegu",
				OB3_TCO: "Odczyt temp. obiegu (temp. bufora)",
				OB3_TKMF: "Temp. zad. po korektach",
				OB3_TKMF_BAZ: "Temp. zadana bazowa",
				OB3_TWEW: "Temp. wewnętrzna",
				OB3_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
				OBR_ELEM: "NA",
				ODBLOKOWANIE: "NA",
				OOF_CL: "Liczba zdarzeń aw. i ostrz. podczas chłodzenia",
				OOF_DHW: "Liczba zdarzeń aw. i ostrz. podczas przygotowania cwu",
				OOF_HT: "Liczba zdarzeń aw. i ostrz. podczas ogrzewania",
				OOF_NUM: "Maks. liczba zdarzeń aw. i ostrz. w ciągu doby",
				OOF_PWR: "Liczba załączeń zasilania w bieżącej dobie",
				OVER_RUN: "NA",
				PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący",
				PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący",
				PANVER: "Wersja panelu operatorskiego",
				PANWER: "Wersja panelu operatorskiego",
				PDHWT1: "Praca cwu w T1 - miesiąc bieżący",
				PDHWT2: "Praca cwu w T2 - miesiąc bieżący",
				PDP: "Data produkcji",
				PDP_WEE: "Tydzień daty produkcji",
				PDP_YEA: "Rok daty produkcji",
				PFC: "Praca na chłodzenie FC - miesiąc bieżący",
				PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący",
				PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący",
				POMSIO_B01: "NA",
				POMSIO_B02: "NA",
				POMSIO_B03: "NA",
				POMSIO_B04: "NA",
				POMSIO_B05: "NA",
				POMZB1: "NA",
				POMZB2: "NA",
				PRF: "NA",
				PSN: "Numer seryjny produktu",
				PWM1: "Wydajność pompy obiegowej odbioru",
				PWM1_OUT: "NA",
				QRMAX2: "NA",
				R_JEZYK: "NA",
				R01: "NA",
				R02: "NA",
				R03: "NA",
				R04: "NA",
				R05: "NA",
				R06: "NA",
				R07: "NA",
				R08: "NA",
				R09: "NA",
				R10: "NA",
				R11: "NA",
				RELOAD: "NA",
				RESTART: "NA",
				REW: "Rejestr błędów",
				REW_DIS: "1 = przekroczenie temperatury tłoczenia",
				REW_ERR: "1 = zbiorcza flaga stanu awaryjnego",
				REW_FCS: "1 = awaria układu kontrolnego wejść binarnych",
				REW_FLO: "1 = błąd z czujnika przepływu",
				REW_HP: "1 = błąd z presostatu wysokiego ciśnienia",
				REW_LPC: "1 = błąd z presostatu niskiego ciśnienia dla chłodzenia",
				REW_LPH: "1 = błąd z presostatu niskiego ciśnienia dla ogrzewania",
				REW_RMA: "1 = przekroczona temp. max. odbioru ciepła (ogrzewanie)",
				REW_RMD: "1 = przekroczenie temperatury krytycznej w wymienniku odbioru",
				REW_RMI: "1 = przekroczona temp. min. odbioru chłodu (chłodzenie)",
				REW_SMA: "1 = przekroczona temp. max. źródła chłodu (chłodzenie)",
				REW_SMD: "1 = przekroczenie temperatury krytycznej w wymienniku źródła",
				REW_SMI: "1 = przekroczona temp. min. źródła ciepła (ogrzewanie)",
				REW_SUP: "1 = błąd z softstartera / czujnika faz",
				REW_TER: "1 = błąd z modułu termicznego sprężarki",
				REW_WRN: "1 = zbiorcza flaga stanu ostrzegawczego",
				RGRCH: "Rejestr konfiguracji obiegów odbiorczych",
				ROK: "Rok daty systemowej",
				RS_BAJT_IN: "NA",
				RS_KOM: "NA",
				RS_PRG: "NA",
				RS_TIBBO_1: "NA",
				RS_TIBBO_2: "NA",
				RS_TIBBO_H: "NA",
				RS2_KOM: "NA",
				S1: "NA",
				S2: "NA",
				SBB_MAIN: "Rozkaz uruchimienia sprężarki - aktywacja modułu wyk.",
				SBB_QCOUT: "Temp. zew. wyższa od progu chłodzenia",
				SBB_QCS: "Wymagany postój sprężarki",
				SBB_QDHW: "Woda w zasobniku cwu za zimna",
				SBB_QDIS: "Temperatura tłoczenia przekroczona",
				SBB_QHOUT: "Temp. zew. niższa od progu ogrzewania",
				SBB_QHW: "Warunki temp. podgrzewania cwu spełnione i podgrz. cwu załączone",
				SBB_QRMAX: "Temp. odbioru ciepła za wysoka",
				SBB_QRMIN: "Temperatura odbioru chłodu za niska",
				SBB_QSMAX: "Temperatura źródła chłodu za wysoka",
				SBB_QSMD: "Temp. na wyjściu wym. odbioru niższa niż SP k. grzewczej",
				SBB_QSMIN: "Temperatura źródła ciepła za niska",
				SBB_QTIN: "Temp. wewn. niższa od zadanej",
				SBB_RMD: "Kontrola krzywej chłodzenia",
				SBF: "Rejestr flag kontrolnych - załączanie funkcji",
				SBF_ACS: "1 = załączenie active-coolingu (AC)",
				SBF_ACS_ARCH: "NA",
				SBF_ACX: "1 = możliwość załączenia active-coolingu (AC)",
				SBF_ACX_ARCH: "NA",
				SBF_AHS: "1 = dopuszczenie zał. źr. szczytowego tylko w tanim prądzie",
				SBF_AHX: "1 = możliwość załączenia źródła szczytowego",
				SBF_DHS: "1 = załączenie podgrzewania cwu",
				SBF_DHX: "1 = możliwość załączenia podgrzewania cwu",
				SBF_FCS: "1 = załączenie free-coolingu (FC)",
				SBF_FCS_ARCH: "NA",
				SBF_FCX: "1 = możliwość załączenia free-coolingu (FC)",
				SBF_FCX_ARCH: "NA",
				SBF_FDR: "nie wykorzystane",
				SBF_FDS: "nie wykorzystane",
				SBF_HTS: "1 = załączenie ogrzewania budynku",
				SBF_HTS_ARCH: "NA",
				SBF_HTX: "1 = możliwość załączenia ogrzewania budynku",
				SBF_HTX_ARCH: "NA",
				SBF_OOF: "1 = załączenie całego urządzenia",
				SBF_PCL: "nie wykorzystane",
				SBF_PHT: "1 = manualne załączenie funkcji antylegionella / 0=wyłączenie",
				SCCU: "Setpoint z krzywej chłodzenia",
				SCDHW: "Korekcja czasowa tempeatury zadanej CWU",
				SCHT: "Korekcja czasowa temp. zad. wewn.",
				SEKUNDY: "Sekundy czasu rzeczywistego",
				SIO: "Stan wejść / wyjść",
				SIO_B01: "0 = bak sygnału z presostatu niskiego ciśnienia dla chłodzenia",
				SIO_B02: "0 = bak sygnału z presostatu niskiego ciśnienia dla ogrzewania",
				SIO_B03: "0 = bak sygnału z presostatu wysokiego ciśnienia",
				SIO_B04: "0 = brak sygnału kontrolnego z softstartera/czujnika zasilania",
				SIO_B05: "0 = brak sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu",
				SIO_R01: "1 = załączony przekaźnik R01",
				SIO_R02: "2 = załączony przekaźnik R02",
				SIO_R03: "1 = zał. przek. źródła szczytowego",
				SIO_R04: "4 = załączony przekaźnik R04",
				SIO_R05: "5 = załączony przekaźnik R05",
				SIO_R06: "1 = załączony przekaźnik sprężarki",
				SIO_R07: "1 = załączony przekaźnik pompy obiegowej źródła",
				SIO_R08: "1 = załączony przekaźnik pompy obiegowej odbioru",
				SIO_R09: "1 = załączony przekaźnik zaworu cwu/pompy obiegowej cwu",
				SIO_R10: "1 = załączony przekaźnik zaworu 4-dr/zaworów odwracających obiegi",
				SIO_R11: "1 = załączony przekaźnik pompy cyrkulacyjnej cwu",
				SPCU: "Setpoint z krzywej grzewczej",
				SPDHW: "Temperatura zadana i hist. bazowa CWU",
				SPDHW_HIST: "Histereza regulacji",
				SPDHW_ZAD: "Temperatura zadana bazowa",
				SPHT: "Temperatura zadana i hist. bazowa wewn.",
				SPHT_HIST: "Histereza regulacji",
				SPHT_ZAD: "Temperatura zadana bazowa wewnętrzna",
				SPRDIS: "Temperatura tłoczenia i hist maksymalna",
				SPRDIS_HIST: "Histereza maks. temperatury tłoczenia",
				SPRDIS_ZAD: "Temperatura tłoczenia maksymalna",
				SPRMAX: "Temp. maks. i hist. odbioru ciepła",
				SPRMAX_HIST: "Hist. temp. max. odbioru ciepła",
				SPRMAX_ZAD: "Temp. maksymalna odbioru ciepła",
				SPRMIN: "Temp. min. i hist. odbioru chłodu",
				SPRMIN_HIST: "Hist. temp. min. odbioru chłodu",
				SPRMIN_ZAD: "Temp. minimalna odbioru chłodu",
				SPSMAX: "Temp. maksymalna i hist. źródła chłodu",
				SPSMAX_HIST: "Hist temp. maks. żródła chłodu",
				SPSMAX_ZAD: "Temp. maksymalna żródła chłodu",
				SPSMIN: "Temp. minimalna i hist.  Wtrysku",
				SPSMIN_HIST: "Hist. temp. min. źródła ciepła",
				SPSMIN_ZAD: "Temp. minimalna źródła ciepła",
				SS1_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 1",
				SS1_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 1",
				SS1_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 1",
				SS1_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 1",
				SS1_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 1",
				SS1_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 1",
				SS1_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 1",
				SS10_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 10",
				SS10_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 10",
				SS10_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 10",
				SS10_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 10",
				SS10_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 10",
				SS10_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 10",
				SS10_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 10",
				SS11_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 11",
				SS11_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 11",
				SS11_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 11",
				SS11_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 11",
				SS11_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 11",
				SS11_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 11",
				SS11_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 11",
				SS12_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 12",
				SS12_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 12",
				SS12_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 12",
				SS12_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 12",
				SS12_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 12",
				SS12_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 12",
				SS12_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 12",
				SS2_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 2",
				SS2_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 2",
				SS2_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 2",
				SS2_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 2",
				SS2_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 2",
				SS2_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 2",
				SS2_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 2",
				SS3_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 3",
				SS3_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 3",
				SS3_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 3",
				SS3_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 3",
				SS3_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 3",
				SS3_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 3",
				SS3_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 3",
				SS4_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 4",
				SS4_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 4",
				SS4_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 4",
				SS4_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 4",
				SS4_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 4",
				SS4_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 4",
				SS4_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 4",
				SS5_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 5",
				SS5_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 5",
				SS5_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 5",
				SS5_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 5",
				SS5_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 5",
				SS5_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 5",
				SS5_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 5",
				SS6_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 6",
				SS6_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 6",
				SS6_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 6",
				SS6_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 6",
				SS6_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 6",
				SS6_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 6",
				SS6_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 6",
				SS7_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 7",
				SS7_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 7",
				SS7_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 7",
				SS7_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 7",
				SS7_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 7",
				SS7_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 7",
				SS7_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 7",
				SS8_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 8",
				SS8_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 8",
				SS8_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 8",
				SS8_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 8",
				SS8_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 8",
				SS8_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 8",
				SS8_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 8",
				SS9_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 9",
				SS9_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 9",
				SS9_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 9",
				SS9_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 9",
				SS9_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 9",
				SS9_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 9",
				SS9_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 9",
				SSB: "Rejestr flag statusowych nr 1",
				SSB_MAIN: "1 = rozkaz uruchomienia sprężarki kierowany do modułu wykonawczego",
				SSB_QCOUT: "1  = temp.zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)",
				SSB_QCS: "1 = wymuszony postój sprężarki",
				SSB_QDHW: "1 = woda w zasobniku cwu za zimna/0 = woda w zasobniku cwu podgrzana",
				SSB_QDIS: "1 = temperatura tłoczenia przekroczona",
				SSB_QHL: "1 = warunki temperaturowe chłodzenia spełnione i chłodzenie załączone",
				SSB_QHOUT: "1  = temp.zewnętrzna niższa od progu ogrzewania (zezwolenie na ogrzewanie)",
				SSB_QHT: "1 = warunki temperaturowe ogrzewania spełnione i ogrzewanie załączone",
				SSB_QHW: "1 = warunki temperaturowe podgrzewania cwu spełnione i podgrz.cwu załączone",
				SSB_QRMAX: "1 = temperatura odbioru ciepła za wysoka",
				SSB_QRMD: "1 = temp.na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej chłodzenia",
				SSB_QRMIN: "1 = temperatura odbioru chłodu za niska",
				SSB_QSMAX: "1 = temperatura źródła chłodu za wysoka",
				SSB_QSMD: "1 = temp.na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej grzewczej",
				SSB_QSMIN: "1 = temperatura źródła ciepła za niska",
				SSB_QTIN: "1 = temp.wewn.niższa od zadanej/0 = temp.wewn.wyższa od zadanej",
				ST_DZIEN: "Nastwa daty - dzień",
				ST_DZIEN_TYG: "NA",
				ST_GODZINY: "Nastwa czasu - godzny",
				ST_MIESIAC: "Nastwa daty - miesiąc",
				ST_MINUTY: "Nastwa czasu - minuty",
				ST_ROK: "Nastwa daty - rok",
				ST_SEKUNDY: "Nastwa czasu - sekundy",
				START: "NA",
				STATUS: "NA",
				STD_ON: "NA",
				STG_ON: "NA",
				STL_ON: "NA",
				STP_ON: "NA",
				SW1: "NA",
				SW2: "NA",
				SW3_LED1: "NA",
				SW4_LED2: "NA",
				SW5: "NA",
				SW6: "NA",
				SW7: "NA",
				SW8: "NA",
				SWEXT: "NA",
				SWEXT_AHS2: "Blokada pracy drugiego stopnia źródła szczytowego w drogim prądzie",
				SWEXT_AHX2: "Możliwość załączenia drugiego stopnia źródła szczytowego",
				SWEXT_BUF: "Układ pracy z buforem gdy SWEXT.BUF=1.",
				SWEXT_COMOFF: "Czasowe zablokowanie transmisji Modbus Master na porcie Frisko",
				SWEXT_D05: "NA",
				SWEXT_DCP: "Tryb pracy pomp odbiorczych",
				SWEXT_FIXREG: "Zezwolenie na regulację wydajności",
				SWEXT_TWP: "Praca z czujnikiem lub bez czujnika temperatury wewnętrznej",
				SWEXT_ZBC: "Możliwość załączenia źródła biw. na cwu: 0 – brak możliwości załączenia",
				SWEXT_ZBCRO: "Wybór konfiguracji pracy źródła  szczytowego",
				SWEXT_ZBST1: "Załączenie logiczne stopnia 1",
				SWEXT_ZBST2: "Załączenie logiczne stopnia 2",
				SX1: "Wyl. wart. korekty temp. odbioru",
				SX2: "Ust. krzywej chłodzenia",
				SX2_P1: "Punkt P1 krzywej chłodzenia",
				SX2_P2: "Punkt P2 krzywej chłodzenia",
				SX3: "Hist. Krzywych grzewczej i chłodzenia",
				SX3_HC: "Hist. setp. dla krzywej chłodzenia",
				SX3_HG: "Histereza setp. dla krzywej grzewczej",
				SX4: "Progi załączenia grzaniai chłodzenia",
				SX4_PC: "Próg zał. chłodzenia wzgl. TOUT_KOR",
				SX4_PH: "Próg zał. ogrzewania wzgl. TOUT_KOR",
				SX5: "Temperatura zadana CWU po korektach",
				SX6: "Temperatura zadana wewn. po korektach",
				SXB: "Rejestr flag statusowych nr 2",
				SXB_4DR: "1 = niepoprawna praca zaworu 4-drogowego lub praca skrajnie poza kopertą pracy",
				SXB_ACBL: "Kontrola temperatury minimalnej źródła chłodu przy AC",
				SXB_CLO: "1 = wystąpiła blokada awaryjna funkcji chłodzenia aktywnego",
				SXB_DHWO: "1 = wystąpiła blokada awaryjna funkcji podgrzewania cwu",
				SXB_ECACT: "obowiązująca taryfa energii elektrycznej (1=tani prąd)",
				SXB_F4D: "1 = wym.czas. zał. pomp ob.. źródła i odbioru po zmianie stanu zaw. 4-DR chł./grzanie",
				SXB_F4DP: "1 = wym.czas. zał. pomp ob.. źródła i odbioru po zmianie stanu zaw. 4-DR grzanie/chł.",
				SXB_FER: "1 = wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji",
				SXB_HTO: "1 = wystąpiła blokada awaryjna funkcji ogrzewania",
				SXB_PV: "Wymuszenie z int. PV",
				SXB_PWRO: "1 = wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem",
				SXB_QFC: "1 = warunek temperaturowy załączenia free-coolongu (FC) spełniony",
				SXB_QSFC: "1 = warunki temp.załączenia FC spełnione i FC załączony",
				SXB_WCL: "1 = praca sprężarki w funkcji active-coolingu (AC)",
				SXB_WDHW: "1 = praca sprężarki w funkcji przygotowania cwu",
				SXB_WHT: "1 = praca sprężarki w funkcji ogrzewania",
				SXF: "Rejestr flag kontrolnych - konfiguracja",
				SXF_4EN: "1 = załączenie kontroli poprawności pracy zawory 4-drogowego",
				SXF_ACT: "1 = aktywna blokada czasowa",
				SXF_ANL: "1 = zezwolenie na załączenie trybu antylegionella",
				SXF_AWA: "1 = załączenie trybu AWAY",
				SXF_AZP: "1 = zezwolenie na sezonową zmianę godzin popołudniowych dla ECO",
				SXF_DHM: "0 = odgrzewanie cwu prze zawór 3-dr / 1= podgrz.cwu przez dodatkową pompę",
				SXF_ECO: "1 = załączenie trybu ECO",
				SXF_ECO_ARCH: "1 = stan trybu ECO w momencie załączenia trybu AWAY",
				SXF_ENACYR: "1 = zezwolenie na załączenie pompy cyrkulacyjnej cwu",
				SXF_FLPCYR: "1 = wymuszenie czasowe pracy p. cyrkulacyjnej cwu",
				SXF_FORCYR: "1 = wymuszenie pracy pompy cyrkulacyjnej cwu",
				SXF_TERMO: "NA",
				SXF_TIMCYR: "1 = zezwolenie na sterowanie czasowe pompą cyrkulacyjną cwu",
				SXF_TIME: "1 = załączenie trybu czasowego",
				SXF_TOE: "1 = zezwolenie na kontrolę temw.wewn.(HEAT,AC,FC)",
				SXF_WEK: "1 = realizacja trybu ECO dla taryfy weekendowej",
				T1: "NA",
				T2: "NA",
				T3: "NA",
				T3fiz: "Temperatura powietrza wejściowego",
				T3FIZ: "Temperatura  powietrza wejściowego",
				T4: "NA",
				T4fiz: "Temperatura powietrza wyjściowego",
				T4FIZ: "Temperatura powietrza wyjściowego",
				T5: "NA",
				T5FIZ: "Temperatura wymiennika odbioru - góra",
				T6: "NA",
				T6FIZ: "Temperatura wymiennika odbioru - dół",
				T7: "NA",
				T8: "NA",
				T9: "NA",
				T9_REMOTE: "NA",
				TA1: "NA",
				TA2: "NA",
				TB1: "Setp. finalny dla krzywej grzewczej",
				TB10: "NA",
				TB11: "NA",
				TB12: "NA",
				TB13: "NA",
				TB14: "NA",
				TB15: "NA",
				TB16: "NA",
				TB2: "Setp. finalny dla krzywej chłodzenia",
				TB3: "Czas opóźnienia analizy pracy zaworu 4-DR",
				TB4: "Wskaźnik dynamiki dla ogrzewania",
				TB5: "Czas załączenia stopnia 1 źródła szczytowego dla c.o.",
				TB5W: "Czas załączenia źródła szczytowego dla c.w.u.",
				TB6: "Temperatura graniczna pracy stopnia 1 źródła szczytowego dla c.o.",
				TB7: "NA",
				TB8: "NA",
				TB9: "NA",
				TCH: "Czas postoju sprężarki - wymuszony",
				TCR: "Czas postoju sprężarki - pozostało",
				TERM1: "NA",
				TERM2: "NA",
				TEST_R01: "NA",
				TEST_R02: "NA",
				TEST_R03: "NA",
				TEST_R05: "NA",
				TEST_R08: "NA",
				TEST_R09: "NA",
				TEST_R10: "NA",
				TEST_R11: "NA",
				TESTPR: "Wywoływanie testu wyjść przekaźnikowych",
				TESTPWM: "Test PWM pompy odbiorczej",
				TESTPWM_FLOP: "NA",
				TIM_CYR: "Timer modułu cyrkulacji",
				TIM_FST: "Czas pracy źródła szczytowego -ST1 - minuty",
				TIM_WEEK: "Czas pracy źródła szczytowego -ST1 - tygodnie",
				TIM_WIN_FL: "1 = czas zimowy / 0 = czas letni",
				TIM_WIN_SW: "1 = zezwolenie na automatyczną zmianę czasu (letni/zimowy)",
				TIMCYR: "Timer modułu cyrkulacji",
				TIMCYR_SYNC: "NA",
				TIMER: "NA",
				TNKOR: "Ograniczenie minimalnej wydajności",
				TOUT_KOR: "Temp. zewnętrzna skorygowana - CROT",
				TOUT_NUM: "Siła mechanizmu CROT",
				TOUT_POM: "Zduplikowany TSM9",
				TPOM: "NA",
				TRR: "Czas przedbiegu pompy obiegowej odbioru",
				TRS: "Czas przedbiegu pompy obiegowej źródła",
				TSM10: "Temperatura wewnętrzna",
				TSM11: "Temp. niższa na wym. źródła",
				TSM12: "Temp. wtrysku czynnika chłodniczego",
				TSM13: "Temp. wyższa na wym. odbioru",
				TSM14: "Temp. niższa na wym. odbioru",
				TSM15: "Temperatura w zasobniku CWU",
				TSM16: "Temperatura tłoczenia",
				TSM17: "Temperatura bufora",
				TSM9: "Temperatura zewnętrzna",
				TSR: "Czas wybiegu pompy obiegowej odbioru",
				TSS: "Czas wybiegu pompy obiegowej źródła",
				TURBO_CWU: "Funkcja Turbo CWU",
				TWC1P: "Czasy początku i końca strefy nr 1 Twew, czwartek",
				TWC1T: "Korekcja temp. strefy nr 1 Twew, czwartek",
				TWC2P: "Czasy początku i końca strefy nr 2 Twew, czwartek",
				TWC2T: "Korekcja temp. strefy nr 2 Twew, czwartek",
				TWC3P: "Czasy początku i końca strefy nr 3 Twew, czwartek",
				TWC3T: "Korekcja temp. strefy nr 3 Twew, czwartek",
				TWN1P: "Czasy początku i końca strefy nr 1 Twew, niedziela",
				TWN1T: "Korekcja temp. strefy nr 1 Twew, niedziela",
				TWN2P: "Czasy początku i końca strefy nr 2 Twew, niedziela",
				TWN2T: "Korekcja temp. strefy nr 2 Twew, niedziela",
				TWN3P: "Czasy początku i końca strefy nr 3 Twew, niedziela",
				TWN3T: "Korekcja temp. strefy nr 3 Twew, niedziela",
				TWP1P: "Czasy początku i końca strefy nr 1 Twew, poniedziałek",
				TWP1T: "Korekcja temp. strefy nr 1 Twew, poniedziałek",
				TWP2P: "Czasy początku i końca strefy nr 2 Twew, poniedziałek",
				TWP2T: "Korekcja temp. strefy nr 2 Twew, poniedziałek",
				TWP3P: "Czasy początku i końca strefy nr 3 Twew, poniedziałek",
				TWP3T: "Korekcja temp. strefy nr 3 Twew, poniedziałek",
				TWPT1P: "Czasy początku i końca strefy nr 1 Twew, piątek",
				TWPT1T: "Korekcja temp. strefy nr 1 Twew, piątek",
				TWPT2P: "Czasy początku i końca strefy nr 2 Twew, piątek",
				TWPT2T: "Korekcja temp. strefy nr 2 Twew, piątek",
				TWPT3P: "Czasy początku i końca strefy nr 3 Twew, piątek",
				TWPT3T: "Korekcja temp. strefy nr 3 Twew, piątek",
				TWS1P: "Czasy początku i końca strefy nr 1 Twew, środa",
				TWS1T: "Korekcja temp. strefy nr 1 Twew, środa",
				TWS2P: "Czasy początku i końca strefy nr 2 Twew, środa",
				TWS2T: "Korekcja temp. strefy nr 2 Twew, środa",
				TWS3P: "Czasy początku i końca strefy nr 3 Twew, środa",
				TWS3T: "Korekcja temp. strefy nr 3 Twew, środa",
				TWSO1P: "Czasy początku i końca strefy nr 1 Twew, sobota",
				TWSO1T: "Korekcja temp. strefy nr 1 Twew, sobota",
				TWSO2P: "Czasy początku i końca strefy nr 2 Twew, sobota",
				TWSO2T: "Korekcja temp. strefy nr 2 Twew, sobota",
				TWSO3P: "Czasy początku i końca strefy nr 3 Twew, sobota",
				TWSO3T: "Korekcja temp. strefy nr 3 Twew, sobota",
				TWTSW: "Maska dla Twew",
				TWTSW_ARCH: "NA",
				TWTZAK: "NA",
				TWW1P: "Czasy początku i końca strefy nr 1 Twew, wtorek",
				TWW1T: "Korekcja temp. strefy nr 1 Twew, wtorek",
				TWW2P: "Czasy początku i końca strefy nr 2 Twew, wtorek",
				TWW2T: "Korekcja temp. strefy nr 2 Twew, wtorek",
				TWW3P: "Czasy początku i końca strefy nr 3 Twew, wtorek",
				TWW3T: "Korekcja temp. strefy nr 3 Twew, wtorek",
				UWNA_1: "Błąd - rejestr REW pozycja 1",
				UWNA_10: "Błąd - rejestr REW pozycja 10",
				UWNA_2: "Błąd - rejestr REW pozycja 2",
				UWNA_3: "Błąd - rejestr REW pozycja 3",
				UWNA_4: "Błąd - rejestr REW pozycja 4",
				UWNA_5: "Błąd - rejestr REW pozycja 5",
				UWNA_6: "Błąd - rejestr REW pozycja 6",
				UWNA_7: "Błąd - rejestr REW pozycja 7",
				UWNA_8: "Błąd - rejestr REW pozycja 8",
				UWNA_9: "Błąd - rejestr REW pozycja 9",
				UWNB_1: "Błąd - stany we/wy pozycja 1",
				UWNB_10: "Błąd - stany we/wy pozycja 10",
				UWNB_2: "Błąd - stany we/wy pozycja 2",
				UWNB_3: "Błąd - stany we/wy pozycja 3",
				UWNB_4: "Błąd - stany we/wy pozycja 4",
				UWNB_5: "Błąd - stany we/wy pozycja 5",
				UWNB_6: "Błąd - stany we/wy pozycja 6",
				UWNB_7: "Błąd - stany we/wy pozycja 7",
				UWNB_8: "Błąd - stany we/wy pozycja 8",
				UWNB_9: "Błąd - stany we/wy pozycja 9",
				UWNC_1: "Błąd - rejestr SSB pozycja 1",
				UWNC_10: "Błąd - rejestr SSB pozycja 10",
				UWNC_2: "Błąd - rejestr SSB pozycja 2",
				UWNC_3: "Błąd - rejestr SSB pozycja 3",
				UWNC_4: "Błąd - rejestr SSB pozycja 4",
				UWNC_5: "Błąd - rejestr SSB pozycja 5",
				UWNC_6: "Błąd - rejestr SSB pozycja 6",
				UWNC_7: "Błąd - rejestr SSB pozycja 7",
				UWNC_8: "Błąd - rejestr SSB pozycja 8",
				UWNC_9: "Błąd - rejestr SSB pozycja 9",
				UWND_1: "Błąd - rejestr SXB pozycja 1",
				UWND_10: "Błąd - rejestr SXB pozycja 10",
				UWND_2: "Błąd - rejestr SXB pozycja 2",
				UWND_3: "Błąd - rejestr SXB pozycja 3",
				UWND_4: "Błąd - rejestr SXB pozycja 4",
				UWND_5: "Błąd - rejestr SXB pozycja 5",
				UWND_6: "Błąd - rejestr SXB pozycja 6",
				UWND_7: "Błąd - rejestr SXB pozycja 7",
				UWND_8: "Błąd - rejestr SXB pozycja 8",
				UWND_9: "Błąd - rejestr SXB pozycja 9",
				UWNE_1: "Błąd - temp. zewnętrzna pozycja 1",
				UWNE_10: "Błąd - temp. zewnętrzna pozycja 10",
				UWNE_2: "Błąd - temp. zewnętrzna pozycja 2",
				UWNE_3: "Błąd - temp. zewnętrzna pozycja 3",
				UWNE_4: "Błąd - temp. zewnętrzna pozycja 4",
				UWNE_5: "Błąd - temp. zewnętrzna pozycja 5",
				UWNE_6: "Błąd - temp. zewnętrzna pozycja 6",
				UWNE_7: "Błąd - temp. zewnętrzna pozycja 7",
				UWNE_8: "Błąd - temp. zewnętrzna pozycja 8",
				UWNE_9: "Błąd - temp. zewnętrzna pozycja 9",
				UWNF_1: "Błąd - temp. wewnętrzna pozycja 1",
				UWNF_10: "Błąd - temp. wewnętrzna pozycja 10",
				UWNF_2: "Błąd - temp. wewnętrzna pozycja 2",
				UWNF_3: "Błąd - temp. wewnętrzna pozycja 3",
				UWNF_4: "Błąd - temp. wewnętrzna pozycja 4",
				UWNF_5: "Błąd - temp. wewnętrzna pozycja 5",
				UWNF_6: "Błąd - temp. wewnętrzna pozycja 6",
				UWNF_7: "Błąd - temp. wewnętrzna pozycja 7",
				UWNF_8: "Błąd - temp. wewnętrzna pozycja 8",
				UWNF_9: "Błąd - temp. wewnętrzna pozycja 9",
				UWNG_1: "Błąd - temp. T3fiz pozycja 1",
				UWNG_10: "Błąd - temp. T3fiz pozycja 10",
				UWNG_2: "Błąd - temp. T3fiz pozycja 2",
				UWNG_3: "Błąd - temp. T3fiz pozycja 3",
				UWNG_4: "Błąd - temp. T3fiz pozycja 4",
				UWNG_5: "Błąd - temp. T3fiz pozycja 5",
				UWNG_6: "Błąd - temp. T3fiz pozycja 6",
				UWNG_7: "Błąd - temp. T3fiz pozycja 7",
				UWNG_8: "Błąd - temp. T3fiz pozycja 8",
				UWNG_9: "Błąd - temp. T3fiz pozycja 9",
				UWNH_1: "Błąd - temp. T4fiz pozycja 1",
				UWNH_10: "Błąd - temp. T4fiz pozycja 10",
				UWNH_2: "Błąd - temp. T4fiz pozycja 2",
				UWNH_3: "Błąd - temp. T4fiz pozycja 3",
				UWNH_4: "Błąd - temp. T4fiz pozycja 4",
				UWNH_5: "Błąd - temp. T4fiz pozycja 5",
				UWNH_6: "Błąd - temp. T4fiz pozycja 6",
				UWNH_7: "Błąd - temp. T4fiz pozycja 7",
				UWNH_8: "Błąd - temp. T4fiz pozycja 8",
				UWNH_9: "Błąd - temp. T4fiz pozycja 9",
				UWNI_1: "Błąd - temp. T5fiz pozycja 1",
				UWNI_10: "Błąd - temp. T5fiz pozycja 10",
				UWNI_2: "Błąd - temp. T5fiz pozycja 2",
				UWNI_3: "Błąd - temp. T5fiz pozycja 3",
				UWNI_4: "Błąd - temp. T5fiz pozycja 4",
				UWNI_5: "Błąd - temp. T5fiz pozycja 5",
				UWNI_6: "Błąd - temp. T5fiz pozycja 6",
				UWNI_7: "Błąd - temp. T5fiz pozycja 7",
				UWNI_8: "Błąd - temp. T5fiz pozycja 8",
				UWNI_9: "Błąd - temp. T5fiz pozycja 9",
				UWNJ_1: "Błąd - temp. T6fiz pozycja 1",
				UWNJ_10: "Błąd - temp. T6fiz pozycja 10",
				UWNJ_2: "Błąd - temp. T6fiz pozycja 2",
				UWNJ_3: "Błąd - temp. T6fiz pozycja 3",
				UWNJ_4: "Błąd - temp. T6fiz pozycja 4",
				UWNJ_5: "Błąd - temp. T6fiz pozycja 5",
				UWNJ_6: "Błąd - temp. T6fiz pozycja 6",
				UWNJ_7: "Błąd - temp. T6fiz pozycja 7",
				UWNJ_8: "Błąd - temp. T6fiz pozycja 8",
				UWNJ_9: "Błąd - temp. T6fiz pozycja 9",
				UWNK_1: "Błąd - temp. zasobnika cwu pozycja 1",
				UWNK_10: "Błąd - temp. zasobnika cwu pozycja 10",
				UWNK_2: "Błąd - temp. zasobnika cwu pozycja 2",
				UWNK_3: "Błąd - temp. zasobnika cwu pozycja 3",
				UWNK_4: "Błąd - temp. zasobnika cwu pozycja 4",
				UWNK_5: "Błąd - temp. zasobnika cwu pozycja 5",
				UWNK_6: "Błąd - temp. zasobnika cwu pozycja 6",
				UWNK_7: "Błąd - temp. zasobnika cwu pozycja 7",
				UWNK_8: "Błąd - temp. zasobnika cwu pozycja 8",
				UWNK_9: "Błąd - temp. zasobnika cwu pozycja 9",
				UWNL_1: "Błąd - temp. tłoczenia  pozycja 1",
				UWNL_10: "Błąd - temp. tłoczenia  pozycja 10",
				UWNL_2: "Błąd - temp. tłoczenia  pozycja 2",
				UWNL_3: "Błąd - temp. tłoczenia  pozycja 3",
				UWNL_4: "Błąd - temp. tłoczenia  pozycja 4",
				UWNL_5: "Błąd - temp. tłoczenia  pozycja 5",
				UWNL_6: "Błąd - temp. tłoczenia  pozycja 6",
				UWNL_7: "Błąd - temp. tłoczenia  pozycja 7",
				UWNL_8: "Błąd - temp. tłoczenia  pozycja 8",
				UWNL_9: "Błąd - temp. tłoczenia  pozycja 9",
				UWNM_1: "Czas błedu pozycja 1",
				UWNM_10: "Czas błedu pozycja 10",
				UWNM_2: "Czas błedu pozycja 2",
				UWNM_3: "Czas błedu pozycja 3",
				UWNM_4: "Czas błedu pozycja 4",
				UWNM_5: "Czas błedu pozycja 5",
				UWNM_6: "Czas błedu pozycja 6",
				UWNM_7: "Czas błedu pozycja 7",
				UWNM_8: "Czas błedu pozycja 8",
				UWNM_9: "Czas błedu pozycja 9",
				UWNN_1: "Data błędu pozycja 1",
				UWNN_10: "Data błędu pozycja 10",
				UWNN_2: "Data błędu pozycja 2",
				UWNN_3: "Data błędu pozycja 3",
				UWNN_4: "Data błędu pozycja 4",
				UWNN_5: "Data błędu pozycja 5",
				UWNN_6: "Data błędu pozycja 6",
				UWNN_7: "Data błędu pozycja 7",
				UWNN_8: "Data błędu pozycja 8",
				UWNN_9: "Data błędu pozycja 9",
				VER_DZIEN: "Wersja oprogramowania liczba nr 1",
				VER_MIESIAC: "Wersja oprogramowania liczba nr 2",
				VER_ROK: "Identyfikator konfiguracji łączy",
				VER_S1: "Wersja oprogramowania sterownika",
				VR_ANO: "Możliwość podłączenia grzałki cwu",
				VR_FCI: "FC wbudowane w urządzenie",
				VR_FCO: "Możliwość podłączenia zewnętrznego FC",
				VR_FLI: "Wbudowany czujnik przepływu w ob. odbioru",
				VR_FLO: "Możliwość podłączenia zewn. czujnika przepływu",
				VR_PO: "Wbudowana pompa obiegowa odbioru",
				VR_PZ: "Wbudowana pompa obiegowa źródła",
				VR_REV: "Możliwość podłączenia źródła szczytowego",
				VR_ZCH: "Możliwość podłączenia pompy PGRZ",
				VR_ZCW: "Wbudowany zawór cwu",
				VR_ZKO: "Możliwość podłączenia pompy PCHL",
				WRN2: "NA",
				XSCHT: "Timer defrostu [minuty]",
				XXA: "NA",
				XXAX: "NA",
				XXXW: "NA",
				XZM_TRANSM: "Status transmisji master",
				Z: "NA",
				ZCWU_POS: "NA",
				ZM_AC_IN_CUR: "Prąd wejściowy",
				ZM_AC_IN_VOL: "Napięcie wejściowe",
				ZM_BLANK: "NA",
				ZM_COIL_TEMP: "Temperatura wymiennika",
				ZM_CUR_COMP_FREQ: "Aktualna częstotliwość sprężarki",
				ZM_CUR_MAX_FREQ: "Aktualna częstotliwość maksymalna",
				ZM_CUR_OP_MOD: "Aktualny tryb pracy",
				ZM_DEFR_SIGN: "Sygnał rozszraniania",
				ZM_DIS_TEMP: "Temperatura tłoczenia",
				ZM_DPS: "NA",
				ZM_EEV_OPEN: "Otwarcie zaworu rozprężnego",
				ZM_ERR1: "Rejestr błędu jedn. zewn. - numer 1",
				ZM_ERR2: "Rejestr błędu jedn. zewn. - numer 2",
				ZM_ERR3: "Rejestr błędu jedn. zewn. - numer 3",
				ZM_FAN_MAX_SPE: "Maksymalna prędkość wentylatora",
				ZM_FAN1_SPE: "Prędkość wentylatora nr 1",
				ZM_FAN2_SPE: "Prędkość wentylatora nr 2",
				ZM_OUT_TEMP: "Temperatura otoczenia",
				ZM_PRES_COND: "Ciśn. tłoczenia",
				ZM_PRES_EVAP: "Ciśn. ssania",
				ZM_SS: "NA",
				ZM_SUCT_TEMP: "Temperatura ssania",
				ZMA_MW_MOD: "NA",
				ZMB_SET_COMP_SPEED: "Nastawa prędkości sprężarki",
				ZMB_SET_EEV_OPEN: "Nastawa otwarcia zaworu",
				ZMB_SET_INW_TEMP: "Nastawa temperatury Tui",
				ZMT_POS: "NA",
				ZTEMP: "NA",
				ZZZ: "NA",
				ZZZPJK: "NA",
				ZZZSPRETA: "NA",
				ZZZZZ: "NA",
				ZZZZZZZZZZA1: "NA",
				ZZZZZZZZZZA2: "NA"
			}
			
			break
		case 'ATMO_1':
		// eslint-disable-next-line no-fallthrough
		case 'ALLINONE_1':
			descriptions = {
				A1: "Czas załączenia stopnia 2 źródła szczytowego dla c.o.",
				A2: "Temp. graniczna pracy stopnia 2 źródła szczytowego dla c.o.",
				ANL_1PH: "1 = tryb antylegionella w fazie pierwszej",
				ANL_2PH: "1 = tryb antylegionella w fazie drugiej",
				ANL_3PH: "1 = tryb antylegionella w fazie trzeciej",
				ANL_ALL: "1 = aktywny tryb antylegionella",
				ANL_CLOCK: "Liczba dób pozostałych do uruchomienia trybu antylegionella:",
				ANL_DAY: "Liczba dób dla cykl. uruchomienia trybu antylegionella:",
				ANL_SETP: "Wart. zad. i histereza dla temp. cwu przy wygrzewaniu antylegionella",
				ANL_SETP_HIST: "Histereza kontroli wartości tempeatury cwu w trybie wygrzewania antylegionella",
				ANL_SETP_ZAD: "Wartość zadana do kontroli temp.cwu w trybie wygrzewania antylegionella",
				ANT_FROZ: "Zmienna pomocnicza - iHW",
				ASK_STEP: "Rejestr szczeliny czasowej",
				ASK_STEP2: "Licznik koordynacji zapisu",
				AWAY_CLOCK: "Liczba dni do samoczynnego wył.  D-AWAY",
				AWAY_COR: "Korekcja temp.zad.wewn. (D-AWAY)",
				B01: "NA",
				B02: "NA",
				B03: "NA",
				B04: "NA",
				B05: "NA",
				BLK_ENA: "1 = zezwolenie na blokadę czasową - PARAMETR NIEJAWNY!!!",
				BLK_KD1: "Pierwsza część kodu odblokowującego",
				BLK_KD2: "Druga część kodu odblokowującego",
				BLK_KLAW: "Predefiniowany",
				BLK_LK: "Liczba poprawnie wprowadzonych kodów",
				BLK_LW: "Liczba dni do wyłączenia - bilans",
				BUZER: "NA",
				COC: "Wsp. sterujący mechanizmu REG+",
				COM_ERR: "NA",
				COM_RDY: "NA",
				COMMU: "NA",
				COR_SPRMAX: "Liczba załączeń sprężarki na CWU",
				COR_SPRMIN: "Liczba załączeń sprężarki na CO",
				COR_SPSMAX: "Liczba załączeń sprężarki na AC",
				COR_SPSMIN: "Czas pracy źródła szczytowego -ST2 - tygodnie",
				CORSTA: "Czas pracy źródła szczytowego - ST2 - minuty",
				CORSTA_SPRMAX: "-CWU",
				CORSTA_SPRMIN: "-CO",
				CORSTA_SPSMAX: "-AC",
				CPOM: "NA",
				CTPOM1: "Zmienna pomocnicza - programy czasowe",
				CTPOM2: "Zmienna pomocnicza - programy czasowe",
				CTPOM3: "Zmienna pomocnicza - programy czasowe",
				CTPOM4: "Zmienna pomocnicza - programy czasowe",
				CTPOM5: "Zmienna pomocnicza - programy czasowe",
				CTPOM6: "Zmienna pomocnicza - programy czasowe",
				CUN: "Ust. krzywej grzewczej",
				CUN_D_HCU: "Podbicie krzywej grzewczej",
				CUN_HCU: "Numer krzywej grzewczej",
				CWC1P: "Czasy początku i końca strefy nr 1 cwu, czwartek",
				CWC1T: "Korekcja temp. strefy nr 1 cwu, czwartek",
				CWC2P: "Czasy początku i końca strefy nr 2 cwu, czwartek",
				CWC2T: "Korekcja temp. strefy nr 2 cwu, czwartek",
				CWC3P: "Czasy początku i końca strefy nr 3 cwu, czwartek",
				CWC3T: "Korekcja temp. strefy nr 3 cwu, czwartek",
				CWN1P: "Czasy początku i końca strefy nr 1 cwu, niedziela",
				CWN1T: "Korekcja temp. strefy nr 1 cwu, niedziela",
				CWN2P: "Czasy początku i końca strefy nr 2 cwu, niedziela",
				CWN2T: "Korekcja temp. strefy nr 2 cwu, niedziela",
				CWN3P: "Czasy początku i końca strefy nr 3 cwu, niedziela",
				CWN3T: "Korekcja temp. strefy nr 3 cwu, niedziela",
				CWP1P: "Czasy początku i końca strefy nr 1 cwu, poniedziałek",
				CWP1T: "Korekcja temp. strefy nr 1 cwu, poniedziałek",
				CWP2P: "Czasy początku i końca strefy nr 2 cwu, poniedziałek",
				CWP2T: "Korekcja temp. strefy nr 2 cwu, poniedziałek",
				CWP3P: "Czasy początku i końca strefy nr 3 cwu, poniedziałek",
				CWP3T: "Korekcja temp. strefy nr 3 cwu, poniedziałek",
				CWPT1P: "Czasy początku i końca strefy nr 1 cwu, piątek",
				CWPT1T: "Korekcja temp. strefy nr 1 cwu, piątek",
				CWPT2P: "Czasy początku i końca strefy nr 2 cwu, piątek",
				CWPT2T: "Korekcja temp. strefy nr 2 cwu, piątek",
				CWPT3P: "Czasy początku i końca strefy nr 3 cwu, piątek",
				CWPT3T: "Korekcja temp. strefy nr 3 cwu, piątek",
				CWS1P: "Czasy początku i końca strefy nr 1 cwu, środa",
				CWS1T: "Korekcja temp. strefy nr 1 cwu, środa",
				CWS2P: "Czasy początku i końca strefy nr 2 cwu, środa",
				CWS2T: "Korekcja temp. strefy nr 2 cwu, środa",
				CWS3P: "Czasy początku i końca strefy nr 3 cwu, środa",
				CWS3T: "Korekcja temp. strefy nr 3 cwu, środa",
				CWSO1P: "Czasy początku i końca strefy nr 1 cwu, sobota",
				CWSO1T: "Korekcja temp. strefy nr 1 cwu, sobota",
				CWSO2P: "Czasy początku i końca strefy nr 2 cwu, sobota",
				CWSO2T: "Korekcja temp. strefy nr 2 cwu, sobota",
				CWSO3P: "Czasy początku i końca strefy nr 3 cwu, sobota",
				CWSO3T: "Korekcja temp. strefy nr 3 cwu, sobota",
				CWTSW: "Rejestr maski dla sterowania czasowego temperatury cwu",
				CWTSW_ARCH: "Zmienna pomocnicza - programy czasowe",
				CWTZAK: "NA",
				CWW1P: "Czasy początku i końca strefy nr 1 cwu, wtorek",
				CWW1T: "Korekcja temp. strefy nr 1 cwu, wtorek",
				CWW2P: "Czasy początku i końca strefy nr 2 cwu, wtorek",
				CWW2T: "Korekcja temp. strefy nr 2 cwu, wtorek",
				CWW3P: "Czasy początku i końca strefy nr 3 cwu, wtorek",
				CWW3T: "Korekcja temp. strefy nr 3 cwu, wtorek",
				CYR_BS: "Podstawa czasu sterowania sekwencyjnego pompy cyrkulacyjnej CWU",
				CYR_FILL: "Wypełnienie czasowe pracy pompy cyrkulacyjnej cwu",
				CYR_TGL: "NA",
				CYRBLK: "Sekwencyjne wyłączanie cyrkulacji",
				cyrBreak: "Przerwa w pracy pompy cyrkulacyjnej",
				CYRC1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, czwartek",
				CYRC2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, czwartek",
				CYRC3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, czwartek",
				CYRN1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, niedziela",
				CYRN2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, niedziela",
				CYRN3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, niedziela",
				CYRP1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, poniedziałek",
				CYRP2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, poniedziałek",
				CYRP3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, poniedziałek",
				CYRPT1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, piątek",
				CYRPT2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, piątek",
				CYRPT3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, piątek",
				CYRS1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, środa",
				CYRS2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, środa",
				CYRS3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, środa",
				CYRSO1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, sobota",
				CYRSO2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, sobota",
				CYRSO3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, sobota",
				CYRST: "NA",
				CYRSW: "Rejestr maski dla sterowania czasowego cyrkulacją cwu",
				CYRSW_S1: "Załączenie sterowania czasowego CWU dla strefy 1",
				CYRSW_S2: "Załączenie sterowania czasowego CWU dla strefy 2",
				CYRSW_S3: "Załączenie sterowania czasowego CWU dla strefy 3",
				CYRW1P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 1, wtorek",
				CYRW2P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 2, wtorek",
				CYRW3P: "Czas załączenia i wyłaczenia pompy cyrkulacji, strefa 3, wtorek",
				DD1_COMPACT: "Rejestr pomocniczy - data wystapenia błędu",
				DD2_COMPACT: "Rejestr pomocniczy - godzina wystapenia błędu",
				DEFA_ST1: "1 = przywracanie ustawień domyślnych - krok 1",
				DEFA_ST2: "1 = przywracanie ustawień domyślnych - krok 2",
				DEFR_IN: "Sygnał potwierdzenia defrostu",
				DEFR2_IN: "Sygnał przyjęcia sygnału defrostu",
				DETE: "dt na wymienniku odbioru",
				DZIEN: "Dzień czasu rzeczywistego",
				DZIEN_TYG: "Dzien tygodnia daty systemowej",
				ECODHW: "Redukcja temp.zadanej cwu w czasie drogiej energii",
				ECOT1R: "Godz.początu obowiązywania II taryfy rano",
				ECOT1S: "Godz.końca obowiązywania II  taryfy rano",
				ECOT2R_L: "Godz.początku obowiązywania II taryfy po południu w lecie",
				ECOT2R_Z: "Godz.początku obowiązywania II  taryfy po południu w zimie",
				ECOT2S_L: "Godz.końca obowiązywania II taryfy po południu w lecie",
				ECOT2S_Z: "Godz.końca obowiązywania II  taryfy po południu w zimie",
				ECOT3R: "Godz.początu obowiązywania II  taryfy wieczorem",
				ECOT3S: "Godz.końca obowiązywania II  taryfy wieczorem",
				ECOTIN: "Redukcja temp.zadanej wewn. w czasie drogiej energii",
				ECOX1: "Podwyższenie temp.zad.cwu  rano i wieczorem w II taryfie",
				ECOX2: "Podwyższenie temp.zad.cwu po południu w II taryfie",
				ECOY1: "Podwyższenie temp.zad.wewn.  rano i wieczorem w II taryfie",
				ECOY2: "Podwyższenie  temp.zad.wewn. po południu w II taryfie",
				ENB_ENTER: "NA",
				ENB_ESC: "NA",
				ENB_STD: "NA",
				ENB_STG: "NA",
				ENB_STL: "NA",
				ENB_STP: "NA",
				ENTER_ON: "NA",
				ERR1_COIL_PROT: "Błąd przekroczenia zakresu temperatury zewnętrznej ",
				ERR1_FREQ_LT: "Przekroczony zakres częstotliwości względem temperatury otoczenia",
				ERR1_IPM: "Błąd limitu prądu, mocy, temperatury lub startowej różnicy ciśnień",
				ERR1_MCUR: "Błąd limitu prądu w linii zasilającej",
				ERR1_OGTS: "Zbyt wysoka temperatura tłoczenia ",
				ERR1_OIL: "Błąd - zakłócony powrót oleju do sprężarki",
				ERR1_PCUR: "Błąd limitu prądu w linii zasilania sprężarki",
				ERR1_PDF: "Błąd zbyt wysokiego ciśnienia - ograniczenie częstotliwości",
				ERR1_VOLT: "Przekroczony zakres napięcia zasilającego",
				ERR2_CPTRA_F: "Błąd przetwornika ciśnienia",
				ERR2_EPTRA_F: "Błąd przetwornika ciśnienia",
				ERR2_HP_F: "Błąd - zbyt wysokie ciśnienie",
				ERR2_LP_F: "Błąd - zbyt niskie ciśnienie",
				ESC_ON: "NA",
				F1_ON: "NA",
				F2_ON: "NA",
				F3_ON: "NA",
				F4_ON: "NA",
				FC_TRIG: "NA",
				FRC_PCHL: "Wymuszenie pracy pompy chłodzenia",
				FRC_PCYR: "Wymuszenie pracy pompy cyrkulacyjnej CWU",
				FRC_PGRZ: "Wymuszenie pracy pompy grzania",
				FRC_PO: "Wymuszenie pracy pompy odbioru",
				FRC_ZCWU: "Wymuszenie pracy zaworu cwu",
				FSET_AC: "Nastawa wydajności dla chłodzenia",
				FSET_CO: "Nastawa wydajności dla ogrzewania",
				FSET_CWU: "Nastawa wydajności  dla cwu",
				FWM: "Wymuszenie manualne funkcji",
				FWM_CL: "Zmienna pomocnicza - manualne wymuszanie funkcji",
				FWM_COUNT: "Licznik pomocniczy FWM",
				FWM_HT: "Zmienna pomocnicza - manualne wymuszanie funkcji",
				FWM_HW: "Zmienna pomocnicza - manualne wymuszanie funkcji",
				GODZINY: "Godziny czasu rzeczywistego",
				HAK_CWU: "Zmienna pomocnicza - CWU",
				HPOM: "Limit licznika dynamiki dla ogrzewania",
				IDM: "Identyfikator produktu i producenta",
				IDM_GRO: "Identyfikator produktu",
				IDM_MAN: "Identyfikator producenta",
				IDP: "Informacje o urządzeniu",
				IDP_EXP: "Typ elementu rozprężnego",
				IDP_MOD: "Funkcja",
				IDP_PWR: "Moc nominalna urządzenia [kW]",
				IDP_SUB: "Linia produktu",
				IHW: "intelligent Hot Water - wsk. min. wydajności",
				IHW_END: "Zmienna pomocnicza - iHW",
				IHW_FL: "Zmienna pomocnicza - iHW",
				IHW_HIGH: "Próg iHW",
				IHW_LOW: "Próg iHW",
				KLAW: "Predefiniowany",
				LED_G: "Predefiniowany",
				LED_R: "Predefiniowany",
				LIGHT: "Predefiniowany",
				MAX_PERF: "Ograniczenie wydajności",
				MAX_RET_TEMP: "Temp. maks. powrotu jedn. zewn.",
				MAX_RET_TEMP_HIST: "Hist. temp. maks. powr. jedn. zewn.",
				MB_COMM: "Zmienna pomocnicza - komunikacja",
				MB_COMM2: "Zmienna pomocnicza - komunikacja",
				MB_COMM2_XZM_TRANSM: "Błąd transmisji",
				MB_READY: "Zmienna pomocnicza - komunikacja",
				MB_READY2: "Zmienna pomocnicza - komunikacja",
				MIESIAC: "Miesiąc daty systemowej",
				MIESZACZ_KOM: "Zmienna pomocnicza - komunikacja",
				MINUTY: "Minuty czasu rzeczywistego",
				NR_OBR: "Predefiniowany",
				NUMER_STER: "Numer sterownika w sieci MODBUS",
				OB1_KRZYWA: "Numer krzywej grzewczej obiegu nr 1",
				OB1_PCO: "Stan pracy pompy obiegu 1",
				OB1_TCO: "Temp. w obiegu nr 1",
				OB1_TKMF: "Temp.wewn. zadan z korektą obiegu nr 1",
				OB1_TKMF_BAZ: "Temp. zadana bazowa",
				OB1_TKMFBAZOWA: "Temp. wewn. zadana bazowa obiegu nr 1",
				OB1_TWEW: "Temp. wewnętrzna",
				OB1_TWEWN: "Temp.wewnętrzna obiegu nr 1",
				OB1_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
				OB1_ZADTCO: "Temp.wyliczona z krzywej obiegu nr 1",
				OB2_KRZYWA: "Krzywa grzewcza",
				OB2_PCO: "Stan pracy obiegu",
				OB2_TCO: "Odczyt temp. obiegu (temp. bufora)",
				OB2_TKMF: "Temp. zad. po korektach",
				OB2_TKMF_BAZ: "Temp. zadana bazowa",
				OB2_TWEW: "Temp. wewnętrzna",
				OB2_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
				OB3_KRZYWA: "Krzywa grzewcza",
				OB3_PCO: "Stan pracy obiegu",
				OB3_TCO: "Odczyt temp. obiegu (temp. bufora)",
				OB3_TKMF: "Temp. zad. po korektach",
				OB3_TKMF_BAZ: "Temp. zadana bazowa",
				OB3_TWEW: "Temp. wewnętrzna",
				OB3_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
				OBR_ELEM: "Predefiniowany",
				ODBLOKOWANIE: "NA",
				OOF_CL: "Liczba zdarzeń aw. i ostrz. podczas chłodzenia",
				OOF_DHW: "Liczba zdarzeń aw. i ostrz. podczas przygotowania cwu",
				OOF_HT: "Liczba zdarzeń aw. i ostrz. podczas ogrzewania",
				OOF_NUM: "Maks. liczba zdarzeń aw. i ostrz. w ciągu doby",
				OOF_PWR: "Liczba załączeń zasilania w bieżącej dobie",
				OVER_RUN: "Predefiniowany",
				PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący",
				PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący",
				PANVER: "Wersja panelu operatorskiego",
				PANWER: "Wersja panelu operatorskiego",
				PDHWT1: "Praca cwu w T1 - miesiąc bieżący",
				PDHWT2: "Praca cwu w T2 - miesiąc bieżący",
				PDP: "Data produkcji",
				PDP_WEE: "Tydzień daty produkcji",
				PDP_YEA: "Rok daty produkcji",
				PFC: "Praca na chłodzenie FC - miesiąc bieżący",
				PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący",
				PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący",
				POMSIO_B01: "NA",
				POMSIO_B02: "NA",
				POMSIO_B03: "NA",
				POMSIO_B04: "NA",
				POMSIO_B05: "NA",
				POMZB1: "Zmienna pomocnicza - sterowanie zaworem 4-drogowym",
				POMZB2: "Zmienna pomocnicza - sterowanie zaworem 4-drogowym",
				PRF: "Częstotliwość sprężarki wieksza od 0",
				PSN: "Numer seryjny produktu",
				PWM1: "Wydajność pompy obiegowej odbioru",
				PWM1_OUT: "NA",
				QHL_PRIM: "Zmienna pomocnicza - przełączanie trybow",
				QHT_PRIM: "Zmienna pomocnicza - przełączanie trybow",
				QRMAX2: "NA",
				R_JEZYK: "Predefiniowany",
				R01: "NA",
				R02: "NA",
				R03: "NA",
				R04: "NA",
				R05: "NA",
				R06: "NA",
				R07: "NA",
				R08: "NA",
				R09: "NA",
				R10: "NA",
				R11: "NA",
				RELOAD: "Predefiniowany",
				RESTART: "NA",
				REW: "Rejestr błędów",
				REW_DIS: "1 = przekroczenie temperatury tłoczenia",
				REW_ERR: "1 = zbiorcza flaga stanu awaryjnego",
				REW_FCS: "1 = awaria układu kontrolnego wejść binarnych",
				REW_FLO: "1 = błąd z czujnika przepływu",
				REW_HP: "1 = błąd z presostatu wysokiego ciśnienia",
				REW_LPC: "1 = błąd z presostatu niskiego ciśnienia dla chłodzenia",
				REW_LPH: "1 = błąd z presostatu niskiego ciśnienia dla ogrzewania",
				REW_RMA: "1 = przekroczona temp. max. odbioru ciepła (ogrzewanie)",
				REW_RMD: "1 = przekroczenie temperatury krytycznej w wymienniku odbioru",
				REW_RMI: "1 = przekroczona temp. min. odbioru chłodu (chłodzenie)",
				REW_SMA: "1 = przekroczona temp. max. źródła chłodu (chłodzenie)",
				REW_SMD: "1 = przekroczenie temperatury krytycznej w wymienniku źródła",
				REW_SMI: "1 = przekroczona temp. min. źródła ciepła (ogrzewanie)",
				REW_SUP: "1 = błąd z softstartera / czujnika faz",
				REW_TER: "1 = błąd z modułu termicznego sprężarki",
				REW_WRN: "1 = zbiorcza flaga stanu ostrzegawczego",
				RGRCH: "Rejestr konfiguracji obiegów odbiorczych",
				ROK: "Rok daty systemowej",
				RS_BAJT_IN: "Predefiniowany",
				RS_KOM: "Predefiniowany",
				RS_PRG: "Predefiniowany",
				RS_TIBBO_1: "Predefiniowany",
				RS_TIBBO_2: "Predefiniowany",
				RS_TIBBO_H: "Predefiniowany",
				RS2_KOM: "NA",
				S1: "nie wykorzystane",
				S2: "nie wykorzystane",
				SBB_MAIN: "Rozkaz uruchimienia sprężarki - aktywacja modułu wyk.",
				SBB_QCOUT: "Temp. zew. wyższa od progu chłodzenia",
				SBB_QCS: "Wymagany postój sprężarki",
				SBB_QDHW: "Woda w zasobniku cwu za zimna",
				SBB_QDIS: "Temperatura tłoczenia przekroczona",
				SBB_QHOUT: "Temp. zew. niższa od progu ogrzewania",
				SBB_QHW: "Warunki temp. podgrzewania cwu spełnione i podgrz. cwu załączone",
				SBB_QRMAX: "Temp. odbioru ciepła za wysoka",
				SBB_QRMIN: "Temperatura odbioru chłodu za niska",
				SBB_QSMAX: "Temperatura powrotu jednostki zewnętrznej za wysoka",
				SBB_QSMD: "Temp. na wyjściu wym. odbioru niższa niż SP k. grzewczej",
				SBB_QSMIN: "Temperatura wtrysku czynnika za niska",
				SBB_QTIN: "Temp. wewn. niższa od zadanej",
				SBB_RMD: "Kontrola krzywej chłodzenia",
				SBF: "Rejestr flag kontrolnych - załączanie funkcji",
				SBF_ACS: "1 = załączenie active-coolingu (AC)",
				SBF_ACS_ARCH: "Zmienna pomocnicza - odtwarzanie trybów",
				SBF_ACX: "1 = możliwość załączenia active-coolingu (AC)",
				SBF_ACX_ARCH: "Zmienna pomocnicza - odtwarzanie trybów",
				SBF_AHS: "1 = dopuszczenie zał. źr. szczytowego tylko w tanim prądzie",
				SBF_AHX: "1 = możliwość załączenia źródła szczytowego",
				SBF_DHS: "1 = załączenie podgrzewania cwu",
				SBF_DHX: "1 = możliwość załączenia podgrzewania cwu",
				SBF_FCS: "1 = załączenie free-coolingu (FC)",
				SBF_FCS_ARCH: "Zmienna pomocnicza - odtwarzanie trybów",
				SBF_FCX: "1 = możliwość załączenia free-coolingu (FC)",
				SBF_FCX_ARCH: "Zmienna pomocnicza - odtwarzanie trybów",
				SBF_FDR: "nie wykorzystane",
				SBF_FDS: "nie wykorzystane",
				SBF_HTS: "1 = załączenie ogrzewania budynku",
				SBF_HTS_ARCH: "Zmienna pomocnicza - odtwarzanie trybów",
				SBF_HTX: "1 = możliwość załączenia ogrzewania budynku",
				SBF_HTX_ARCH: "Zmienna pomocnicza - odtwarzanie trybów",
				SBF_OOF: "1 = załączenie całego urządzenia",
				SBF_PCL: "nie wykorzystane",
				SBF_PHT: "1 = manualne załączenie funkcji antylegionella / 0=wyłączenie",
				SCCU: "Setpoint z krzywej chłodzenia",
				SCDHW: "Korekcja czasowa tempeatury zadanej CWU",
				SCHT: "Korekcja czasowa temp. zad. wewn.",
				SEKUNDY: "Sekundy czasu rzeczywistego",
				SIO: "Stan wejść / wyjść",
				SIO_B01: "0 = bak sygnału z presostatu niskiego ciśnienia dla chłodzenia",
				SIO_B02: "0 = bak sygnału z presostatu niskiego ciśnienia dla ogrzewania",
				SIO_B03: "0 = bak sygnału z presostatu wysokiego ciśnienia",
				SIO_B04: "0 = brak sygnału kontrolnego z softstartera/czujnika zasilania",
				SIO_B05: "0 = brak sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu",
				SIO_R01: "1 = załączony przekaźnik R01",
				SIO_R02: "2 = załączony przekaźnik R02",
				SIO_R03: "1 = zał. przek. źródła szczytowego",
				SIO_R04: "4 = załączony przekaźnik R04",
				SIO_R05: "5 = załączony przekaźnik R05",
				SIO_R06: "1 = załączony przekaźnik sprężarki",
				SIO_R07: "1 = załączony przekaźnik pompy obiegowej źródła",
				SIO_R08: "1 = załączony przekaźnik pompy obiegowej odbioru",
				SIO_R09: "1 = załączony przekaźnik zaworu cwu/pompy obiegowej cwu",
				SIO_R10: "1 = załączony przekaźnik zaworu 4-dr/zaworów odwracających obiegi",
				SIO_R11: "1 = załączony przekaźnik pompy cyrkulacyjnej cwu",
				SPCU: "Setpoint z krzywej grzewczej",
				SPDHW: "Temperatura zadana i hist. bazowa CWU",
				SPDHW_HIST: "Histereza regulacji",
				SPDHW_ZAD: "Temperatura zadana bazowa",
				SPHT: "Temperatura zadana i hist. bazowa wewn.",
				SPHT_HIST: "Histereza regulacji",
				SPHT_ZAD: "Temperatura zadana bazowa wewnętrzna",
				SPRDIS: "Temperatura tłoczenia i hist maksymalna",
				SPRDIS_HIST: "Histereza maks. temperatury tłoczenia",
				SPRDIS_ZAD: "Temperatura tłoczenia maksymalna",
				SPRMAX: "Temp. maks. i hist. odbioru ciepła",
				SPRMAX_HIST: "Hist. temp. max. odbioru ciepła",
				SPRMAX_ZAD: "Temp. maksymalna odbioru ciepła",
				SPRMIN: "Temp. min. i hist. odbioru chłodu",
				SPRMIN_HIST: "Hist. temp. min. odbioru chłodu",
				SPRMIN_ZAD: "Temp. minimalna odbioru chłodu",
				SPSMAX: "Temp. maksymalna i hist. źródła chłodu",
				SPSMAX_HIST: "Hist temp. maks. żródła chłodu",
				SPSMAX_ZAD: "Temp. maksymalna żródła chłodu",
				SPSMIN: "Temp. minimalna i hist.  Wtrysku",
				SPSMIN_HIST: "Hist. temp. min. wtrysku czynnika",
				SPSMIN_ZAD: "Temp. minimalna wtrysku",
				SS1_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 1",
				SS1_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 1",
				SS1_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 1",
				SS1_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 1",
				SS1_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 1",
				SS1_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 1",
				SS1_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 1",
				SS10_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 10",
				SS10_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 10",
				SS10_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 10",
				SS10_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 10",
				SS10_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 10",
				SS10_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 10",
				SS10_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 10",
				SS11_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 11",
				SS11_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 11",
				SS11_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 11",
				SS11_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 11",
				SS11_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 11",
				SS11_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 11",
				SS11_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 11",
				SS12_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 12",
				SS12_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 12",
				SS12_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 12",
				SS12_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 12",
				SS12_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 12",
				SS12_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 12",
				SS12_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 12",
				SS2_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 2",
				SS2_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 2",
				SS2_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 2",
				SS2_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 2",
				SS2_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 2",
				SS2_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 2",
				SS2_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 2",
				SS3_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 3",
				SS3_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 3",
				SS3_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 3",
				SS3_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 3",
				SS3_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 3",
				SS3_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 3",
				SS3_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 3",
				SS4_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 4",
				SS4_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 4",
				SS4_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 4",
				SS4_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 4",
				SS4_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 4",
				SS4_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 4",
				SS4_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 4",
				SS5_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 5",
				SS5_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 5",
				SS5_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 5",
				SS5_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 5",
				SS5_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 5",
				SS5_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 5",
				SS5_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 5",
				SS6_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 6",
				SS6_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 6",
				SS6_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 6",
				SS6_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 6",
				SS6_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 6",
				SS6_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 6",
				SS6_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 6",
				SS7_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 7",
				SS7_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 7",
				SS7_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 7",
				SS7_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 7",
				SS7_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 7",
				SS7_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 7",
				SS7_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 7",
				SS8_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 8",
				SS8_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 8",
				SS8_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 8",
				SS8_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 8",
				SS8_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 8",
				SS8_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 8",
				SS8_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 8",
				SS9_PACT1: "Praca na chłodzenie AC w T1 - miesiąc bieżący - 9",
				SS9_PACT2: "Praca na chłodzenie AC w T2 - miesiąc bieżący - 9",
				SS9_PDHWT1: "Praca cwu w T1 - miesiąc bieżący - 9",
				SS9_PDHWT2: "Praca cwu w T2 - miesiąc bieżący - 9",
				SS9_PFC: "Praca na chłodzenie FC - miesiąc bieżący - 9",
				SS9_PHTT1: "Praca na ogrzewanie w T1 - miesiąc bieżący - 9",
				SS9_PHTT2: "Praca na ogrzewanie w T2 - miesiąc bieżący - 9",
				SSB: "Rejestr flag statusowych nr 1",
				SSB_MAIN: "1 = rozkaz uruchomienia sprężarki kierowany do modułu wykonawczego",
				SSB_QCOUT: "1  = temp.zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)",
				SSB_QCS: "1 = wymuszony postój sprężarki",
				SSB_QDHW: "1 = woda w zasobniku cwu za zimna/0 = woda w zasobniku cwu podgrzana",
				SSB_QDIS: "1 = temperatura tłoczenia przekroczona",
				SSB_QHL: "1 = warunki temperaturowe chłodzenia spełnione i chłodzenie załączone",
				SSB_QHOUT: "1  = temp.zewnętrzna niższa od progu ogrzewania (zezwolenie na ogrzewanie)",
				SSB_QHT: "1 = warunki temperaturowe ogrzewania spełnione i ogrzewanie załączone",
				SSB_QHW: "1 = warunki temperaturowe podgrzewania cwu spełnione i podgrz.cwu załączone",
				SSB_QRMAX: "1 = temperatura odbioru ciepła za wysoka",
				SSB_QRMD: "1 = temp.na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej chłodzenia",
				SSB_QRMIN: "1 = temperatura odbioru chłodu za niska",
				SSB_QSMAX: "1 = temperatura źródła chłodu za wysoka",
				SSB_QSMD: "1 = temp.na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej grzewczej",
				SSB_QSMIN: "1 = temperatura źródła ciepła za niska",
				SSB_QTIN: "1 = temp.wewn.niższa od zadanej/0 = temp.wewn.wyższa od zadanej",
				ST_DZIEN: "Nastwa daty - dzień",
				ST_DZIEN_TYG: "Nastawa - dzień tygodnia",
				ST_GODZINY: "Nastwa czasu - godzny",
				ST_MIESIAC: "Nastwa daty - miesiąc",
				ST_MINUTY: "Nastwa czasu - minuty",
				ST_ROK: "Nastwa daty - rok",
				ST_SEKUNDY: "Nastwa czasu - sekundy",
				START: "Zmienna pomocnicza - praca spężarki",
				STATUS: "Predefiniowany",
				STD_ON: "NA",
				STG_ON: "NA",
				STL_ON: "NA",
				STP_ON: "NA",
				SW1: "NA",
				SW2: "NA",
				SW3_LED1: "NA",
				SW4_LED2: "NA",
				SW5: "NA",
				SW6: "NA",
				SW7: "NA",
				SW8: "NA",
				SWEXT: "NA",
				SWEXT_AHS2: "Blokada pracy drugiego stopnia źródła szczytowego w drogim prądzie",
				SWEXT_AHX2: "Możliwość załączenia drugiego stopnia źródła szczytowego",
				SWEXT_BUF: "Układ pracy z buforem gdy SWEXT.BUF=1.",
				SWEXT_COMOFF: "Czasowe zablokowanie transmisji Modbus Master na porcie Frisko",
				SWEXT_D05: "Sygnał z czujnika przepływu",
				SWEXT_DCP: "Tryb pracy pomp odbiorczych",
				SWEXT_FIXREG: "Zezwolenie na regulację wydajności",
				SWEXT_TWP: "Praca z czujnikiem lub bez czujnika temperatury wewnętrznej",
				SWEXT_ZBC: "Możliwość załączenia źródła biw. na cwu: 0 – brak możliwości załączenia",
				SWEXT_ZBCRO: "Wybór konfiguracji pracy źródła  szczytowego",
				SWEXT_ZBST1: "Załączenie logiczne stopnia 1",
				SWEXT_ZBST2: "Załączenie logiczne stopnia 2",
				SX1: "Wyl. wart. korekty temp. odbioru",
				SX2: "Ust. krzywej chłodzenia",
				SX2_P1: "Punkt P1 krzywej chłodzenia",
				SX2_P2: "Punkt P2 krzywej chłodzenia",
				SX3: "Hist. Krzywych grzewczej i chłodzenia",
				SX3_HC: "Hist. setp. dla krzywej chłodzenia",
				SX3_HG: "Histereza setp. dla krzywej grzewczej",
				SX4: "Progi załączenia grzaniai chłodzenia",
				SX4_PC: "Próg zał. chłodzenia wzgl. TOUT_KOR",
				SX4_PH: "Próg zał. ogrzewania wzgl. TOUT_KOR",
				SX5: "Temperatura zadana CWU po korektach",
				SX6: "Temperatura zadana wewn. po korektach",
				SXB: "Rejestr flag statusowych nr 2",
				SXB_4DR: "1 = niepoprawna praca zaworu 4-drogowego lub praca skrajnie poza kopertą pracy",
				SXB_CLO: "1 = wystąpiła blokada awaryjna funkcji chłodzenia aktywnego",
				SXB_DHWO: "1 = wystąpiła blokada awaryjna funkcji podgrzewania cwu",
				SXB_ECACT: "obowiązująca taryfa energii elektrycznej (1=tani prąd)",
				SXB_F4D: "1 = wym.czas. zał. pomp ob.. źródła i odbioru po zmianie stanu zaw. 4-DR chł./grzanie",
				SXB_F4DP: "1 = wym.czas. zał. pomp ob.. źródła i odbioru po zmianie stanu zaw. 4-DR grzanie/chł.",
				SXB_FER: "1 = wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji",
				SXB_HTO: "1 = wystąpiła blokada awaryjna funkcji ogrzewania",
				SXB_PV: "Sygnał wymuszający z instalacji PV",
				SXB_PWRO: "1 = wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem",
				SXB_QFC: "1 = warunek temperaturowy załączenia free-coolongu (FC) spełniony",
				SXB_QSFC: "1 = warunki temp.załączenia FC spełnione i FC załączony",
				SXB_WCL: "1 = praca sprężarki w funkcji active-coolingu (AC)",
				SXB_WDHW: "1 = praca sprężarki w funkcji przygotowania cwu",
				SXB_WHT: "1 = praca sprężarki w funkcji ogrzewania",
				SXF: "Rejestr flag kontrolnych - konfiguracja",
				SXF_4EN: "1 = załączenie kontroli poprawności pracy zawory 4-drogowego",
				SXF_ACT: "1 = aktywna blokada czasowa",
				SXF_ANL: "1 = zezwolenie na załączenie trybu antylegionella",
				SXF_AWA: "1 = załączenie trybu AWAY",
				SXF_AZP: "1 = zezwolenie na sezonową zmianę godzin popołudniowych dla ECO",
				SXF_DHM: "0 = odgrzewanie cwu prze zawór 3-dr / 1= podgrz.cwu przez dodatkową pompę",
				SXF_ECO: "1 = załączenie trybu ECO",
				SXF_ECO_ARCH: "1 = stan trybu ECO w momencie załączenia trybu AWAY",
				SXF_ENACYR: "1 = zezwolenie na załączenie pompy cyrkulacyjnej cwu",
				SXF_FLPCYR: "1 = wymuszenie czasowe pracy p. cyrkulacyjnej cwu",
				SXF_FORCYR: "1 = wymuszenie pracy pompy cyrkulacyjnej cwu",
				SXF_TERMO: "nie wykorzystane",
				SXF_TIMCYR: "1 = zezwolenie na sterowanie czasowe pompą cyrkulacyjną cwu",
				SXF_TIME: "1 = załączenie trybu czasowego",
				SXF_TOE: "1 = zezwolenie na kontrolę temw.wewn.(HEAT,AC,FC)",
				SXF_WEK: "1 = realizacja trybu ECO dla taryfy weekendowej",
				T1: "NA",
				T2: "NA",
				T3: "NA",
				T3fiz: "Temperatura powietrza wejściowego",
				T3FIZ: "Temperatura  powietrza wejściowego",
				T4: "NA",
				T4fiz: "Temperatura powietrza wyjściowego",
				T4FIZ: "Temperatura powietrza wyjściowego",
				T5: "NA",
				T5FIZ: "Temperatura wymiennika odbioru - góra",
				T6: "NA",
				T6FIZ: "Temperatura wymiennika odbioru - dół",
				T7: "NA",
				T8: "NA",
				T9: "NA",
				T9_REMOTE: "Temp zewnetrzna - kaskada",
				TA1: "NA",
				TA2: "NA",
				TB1: "Setp. finalny dla krzywej grzewczej",
				TB10: "NA",
				TB11: "NA",
				TB12: "NA",
				TB13: "NA",
				TB14: "NA",
				TB15: "NA",
				TB16: "NA",
				TB2: "Setp. finalny dla krzywej chłodzenia",
				TB3: "Czas opóźnienia analizy pracy zaworu 4-DR",
				TB4: "Wskaźnik dynamiki dla ogrzewania",
				TB5: "Czas załączenia stopnia 1 źródła szczytowego dla c.o.",
				TB5W: "Czas załączenia źródła szczytowego dla c.w.u.",
				TB6: "Temperatura graniczna pracy stopnia 1 źródła szczytowego dla c.o.",
				TB7: "NA",
				TB8: "NA",
				TB9: "NA",
				TCH: "Czas postoju sprężarki - wymuszony",
				TCR: "Czas postoju sprężarki - pozostało",
				TERM1: "NA",
				TERM2: "NA",
				TEST_R01: "Test wyjścia R01",
				TEST_R02: "Test wyjścia R02",
				TEST_R03: "Test wyjścia R03",
				TEST_R05: "Test wyjścia R05",
				TEST_R08: "Test wyjścia R08",
				TEST_R09: "Test wyjścia R09",
				TEST_R10: "Test wyjścia R10",
				TEST_R11: "Test wyjścia R11",
				TESTPR: "Wywoływanie testu wyjść przekaźnikowych",
				TESTPWM: "Test PWM pompy odbiorczej",
				TESTPWM_FLOP: "Zmienna pomocnicza - test PWM",
				TIM_CYR: "Timer modułu cyrkulacji",
				TIM_FST: "Czas pracy źródła szczytowego -ST1 - minuty",
				TIM_WEEK: "Czas pracy źródła szczytowego -ST1 - tygodnie",
				TIM_WIN_FL: "1 = czas zimowy / 0 = czas letni",
				TIM_WIN_SW: "1 = zezwolenie na automatyczną zmianę czasu (letni/zimowy)",
				TIMCYR: "Timer modułu cyrkulacji",
				TIMCYR_SYNC: "Zmienna pomocnicza - cyrkulacja",
				TIMER: "NA",
				TNKOR: "Ograniczenie minimalnej wydajności",
				TOUT_KOR: "Temp. zewnętrzna skorygowana - CROT",
				TOUT_NUM: "Siła mechanizmu CROT",
				TOUT_POM: "Zduplikowany TSM9",
				TPOM: "Moduł wartośći SPRMIN",
				TRR: "Czas przedbiegu pompy obiegowej odbioru",
				TRS: "Czas przedbiegu pompy obiegowej źródła",
				TSM10: "Temperatura wewnętrzna",
				TSM11: "Temp. niższa na wym. źródła",
				TSM12: "Temp. wtrysku czynnika chłodniczego",
				TSM13: "Temp. wyższa na wym. odbioru",
				TSM14: "Temp. niższa na wym. odbioru",
				TSM15: "Temperatura w zasobniku CWU",
				TSM16: "Temperatura tłoczenia",
				TSM17: "Temperatura bufora",
				TSM9: "Temperatura zewnętrzna",
				TSR: "Czas wybiegu pompy obiegowej odbioru",
				TSS: "Czas wybiegu pompy obiegowej źródła",
				TURBO_CWU: "Funkcja Turbo CWU",
				TWC1P: "Czasy początku i końca strefy nr 1 Twew, czwartek",
				TWC1T: "Korekcja temp. strefy nr 1 Twew, czwartek",
				TWC2P: "Czasy początku i końca strefy nr 2 Twew, czwartek",
				TWC2T: "Korekcja temp. strefy nr 2 Twew, czwartek",
				TWC3P: "Czasy początku i końca strefy nr 3 Twew, czwartek",
				TWC3T: "Korekcja temp. strefy nr 3 Twew, czwartek",
				TWN1P: "Czasy początku i końca strefy nr 1 Twew, niedziela",
				TWN1T: "Korekcja temp. strefy nr 1 Twew, niedziela",
				TWN2P: "Czasy początku i końca strefy nr 2 Twew, niedziela",
				TWN2T: "Korekcja temp. strefy nr 2 Twew, niedziela",
				TWN3P: "Czasy początku i końca strefy nr 3 Twew, niedziela",
				TWN3T: "Korekcja temp. strefy nr 3 Twew, niedziela",
				TWP1P: "Czasy początku i końca strefy nr 1 Twew, poniedziałek",
				TWP1T: "Korekcja temp. strefy nr 1 Twew, poniedziałek",
				TWP2P: "Czasy początku i końca strefy nr 2 Twew, poniedziałek",
				TWP2T: "Korekcja temp. strefy nr 2 Twew, poniedziałek",
				TWP3P: "Czasy początku i końca strefy nr 3 Twew, poniedziałek",
				TWP3T: "Korekcja temp. strefy nr 3 Twew, poniedziałek",
				TWPT1P: "Czasy początku i końca strefy nr 1 Twew, piątek",
				TWPT1T: "Korekcja temp. strefy nr 1 Twew, piątek",
				TWPT2P: "Czasy początku i końca strefy nr 2 Twew, piątek",
				TWPT2T: "Korekcja temp. strefy nr 2 Twew, piątek",
				TWPT3P: "Czasy początku i końca strefy nr 3 Twew, piątek",
				TWPT3T: "Korekcja temp. strefy nr 3 Twew, piątek",
				TWS1P: "Czasy początku i końca strefy nr 1 Twew, środa",
				TWS1T: "Korekcja temp. strefy nr 1 Twew, środa",
				TWS2P: "Czasy początku i końca strefy nr 2 Twew, środa",
				TWS2T: "Korekcja temp. strefy nr 2 Twew, środa",
				TWS3P: "Czasy początku i końca strefy nr 3 Twew, środa",
				TWS3T: "Korekcja temp. strefy nr 3 Twew, środa",
				TWSO1P: "Czasy początku i końca strefy nr 1 Twew, sobota",
				TWSO1T: "Korekcja temp. strefy nr 1 Twew, sobota",
				TWSO2P: "Czasy początku i końca strefy nr 2 Twew, sobota",
				TWSO2T: "Korekcja temp. strefy nr 2 Twew, sobota",
				TWSO3P: "Czasy początku i końca strefy nr 3 Twew, sobota",
				TWSO3T: "Korekcja temp. strefy nr 3 Twew, sobota",
				TWTSW: "Rejestr maski dla sterowania temperaturą wewnętrzną",
				TWTSW_ARCH: "Zmienna pomocnicza - programy czasowe",
				TWTZAK: "NA",
				TWW1P: "Czasy początku i końca strefy nr 1 Twew, wtorek",
				TWW1T: "Korekcja temp. strefy nr 1 Twew, wtorek",
				TWW2P: "Czasy początku i końca strefy nr 2 Twew, wtorek",
				TWW2T: "Korekcja temp. strefy nr 2 Twew, wtorek",
				TWW3P: "Czasy początku i końca strefy nr 3 Twew, wtorek",
				TWW3T: "Korekcja temp. strefy nr 3 Twew, wtorek",
				UWNA_1: "Błąd - rejestr REW pozycja 1",
				UWNA_10: "Błąd - rejestr REW pozycja 10",
				UWNA_2: "Błąd - rejestr REW pozycja 2",
				UWNA_3: "Błąd - rejestr REW pozycja 3",
				UWNA_4: "Błąd - rejestr REW pozycja 4",
				UWNA_5: "Błąd - rejestr REW pozycja 5",
				UWNA_6: "Błąd - rejestr REW pozycja 6",
				UWNA_7: "Błąd - rejestr REW pozycja 7",
				UWNA_8: "Błąd - rejestr REW pozycja 8",
				UWNA_9: "Błąd - rejestr REW pozycja 9",
				UWNB_1: "Błąd - stany we/wy pozycja 1",
				UWNB_10: "Błąd - stany we/wy pozycja 10",
				UWNB_2: "Błąd - stany we/wy pozycja 2",
				UWNB_3: "Błąd - stany we/wy pozycja 3",
				UWNB_4: "Błąd - stany we/wy pozycja 4",
				UWNB_5: "Błąd - stany we/wy pozycja 5",
				UWNB_6: "Błąd - stany we/wy pozycja 6",
				UWNB_7: "Błąd - stany we/wy pozycja 7",
				UWNB_8: "Błąd - stany we/wy pozycja 8",
				UWNB_9: "Błąd - stany we/wy pozycja 9",
				UWNC_1: "Błąd - rejestr SSB pozycja 1",
				UWNC_10: "Błąd - rejestr SSB pozycja 10",
				UWNC_2: "Błąd - rejestr SSB pozycja 2",
				UWNC_3: "Błąd - rejestr SSB pozycja 3",
				UWNC_4: "Błąd - rejestr SSB pozycja 4",
				UWNC_5: "Błąd - rejestr SSB pozycja 5",
				UWNC_6: "Błąd - rejestr SSB pozycja 6",
				UWNC_7: "Błąd - rejestr SSB pozycja 7",
				UWNC_8: "Błąd - rejestr SSB pozycja 8",
				UWNC_9: "Błąd - rejestr SSB pozycja 9",
				UWND_1: "Błąd - rejestr SXB pozycja 1",
				UWND_10: "Błąd - rejestr SXB pozycja 10",
				UWND_2: "Błąd - rejestr SXB pozycja 2",
				UWND_3: "Błąd - rejestr SXB pozycja 3",
				UWND_4: "Błąd - rejestr SXB pozycja 4",
				UWND_5: "Błąd - rejestr SXB pozycja 5",
				UWND_6: "Błąd - rejestr SXB pozycja 6",
				UWND_7: "Błąd - rejestr SXB pozycja 7",
				UWND_8: "Błąd - rejestr SXB pozycja 8",
				UWND_9: "Błąd - rejestr SXB pozycja 9",
				UWNE_1: "Błąd - temp. zewnętrzna pozycja 1",
				UWNE_10: "Błąd - temp. zewnętrzna pozycja 10",
				UWNE_2: "Błąd - temp. zewnętrzna pozycja 2",
				UWNE_3: "Błąd - temp. zewnętrzna pozycja 3",
				UWNE_4: "Błąd - temp. zewnętrzna pozycja 4",
				UWNE_5: "Błąd - temp. zewnętrzna pozycja 5",
				UWNE_6: "Błąd - temp. zewnętrzna pozycja 6",
				UWNE_7: "Błąd - temp. zewnętrzna pozycja 7",
				UWNE_8: "Błąd - temp. zewnętrzna pozycja 8",
				UWNE_9: "Błąd - temp. zewnętrzna pozycja 9",
				UWNF_1: "Błąd - temp. wewnętrzna pozycja 1",
				UWNF_10: "Błąd - temp. wewnętrzna pozycja 10",
				UWNF_2: "Błąd - temp. wewnętrzna pozycja 2",
				UWNF_3: "Błąd - temp. wewnętrzna pozycja 3",
				UWNF_4: "Błąd - temp. wewnętrzna pozycja 4",
				UWNF_5: "Błąd - temp. wewnętrzna pozycja 5",
				UWNF_6: "Błąd - temp. wewnętrzna pozycja 6",
				UWNF_7: "Błąd - temp. wewnętrzna pozycja 7",
				UWNF_8: "Błąd - temp. wewnętrzna pozycja 8",
				UWNF_9: "Błąd - temp. wewnętrzna pozycja 9",
				UWNG_1: "Błąd - temp. T3fiz pozycja 1",
				UWNG_10: "Błąd - temp. T3fiz pozycja 10",
				UWNG_2: "Błąd - temp. T3fiz pozycja 2",
				UWNG_3: "Błąd - temp. T3fiz pozycja 3",
				UWNG_4: "Błąd - temp. T3fiz pozycja 4",
				UWNG_5: "Błąd - temp. T3fiz pozycja 5",
				UWNG_6: "Błąd - temp. T3fiz pozycja 6",
				UWNG_7: "Błąd - temp. T3fiz pozycja 7",
				UWNG_8: "Błąd - temp. T3fiz pozycja 8",
				UWNG_9: "Błąd - temp. T3fiz pozycja 9",
				UWNH_1: "Błąd - temp. T4fiz pozycja 1",
				UWNH_10: "Błąd - temp. T4fiz pozycja 10",
				UWNH_2: "Błąd - temp. T4fiz pozycja 2",
				UWNH_3: "Błąd - temp. T4fiz pozycja 3",
				UWNH_4: "Błąd - temp. T4fiz pozycja 4",
				UWNH_5: "Błąd - temp. T4fiz pozycja 5",
				UWNH_6: "Błąd - temp. T4fiz pozycja 6",
				UWNH_7: "Błąd - temp. T4fiz pozycja 7",
				UWNH_8: "Błąd - temp. T4fiz pozycja 8",
				UWNH_9: "Błąd - temp. T4fiz pozycja 9",
				UWNI_1: "Błąd - temp. T5fiz pozycja 1",
				UWNI_10: "Błąd - temp. T5fiz pozycja 10",
				UWNI_2: "Błąd - temp. T5fiz pozycja 2",
				UWNI_3: "Błąd - temp. T5fiz pozycja 3",
				UWNI_4: "Błąd - temp. T5fiz pozycja 4",
				UWNI_5: "Błąd - temp. T5fiz pozycja 5",
				UWNI_6: "Błąd - temp. T5fiz pozycja 6",
				UWNI_7: "Błąd - temp. T5fiz pozycja 7",
				UWNI_8: "Błąd - temp. T5fiz pozycja 8",
				UWNI_9: "Błąd - temp. T5fiz pozycja 9",
				UWNJ_1: "Błąd - temp. T6fiz pozycja 1",
				UWNJ_10: "Błąd - temp. T6fiz pozycja 10",
				UWNJ_2: "Błąd - temp. T6fiz pozycja 2",
				UWNJ_3: "Błąd - temp. T6fiz pozycja 3",
				UWNJ_4: "Błąd - temp. T6fiz pozycja 4",
				UWNJ_5: "Błąd - temp. T6fiz pozycja 5",
				UWNJ_6: "Błąd - temp. T6fiz pozycja 6",
				UWNJ_7: "Błąd - temp. T6fiz pozycja 7",
				UWNJ_8: "Błąd - temp. T6fiz pozycja 8",
				UWNJ_9: "Błąd - temp. T6fiz pozycja 9",
				UWNK_1: "Błąd - temp. zasobnika cwu pozycja 1",
				UWNK_10: "Błąd - temp. zasobnika cwu pozycja 10",
				UWNK_2: "Błąd - temp. zasobnika cwu pozycja 2",
				UWNK_3: "Błąd - temp. zasobnika cwu pozycja 3",
				UWNK_4: "Błąd - temp. zasobnika cwu pozycja 4",
				UWNK_5: "Błąd - temp. zasobnika cwu pozycja 5",
				UWNK_6: "Błąd - temp. zasobnika cwu pozycja 6",
				UWNK_7: "Błąd - temp. zasobnika cwu pozycja 7",
				UWNK_8: "Błąd - temp. zasobnika cwu pozycja 8",
				UWNK_9: "Błąd - temp. zasobnika cwu pozycja 9",
				UWNL_1: "Błąd - temp. tłoczenia  pozycja 1",
				UWNL_10: "Błąd - temp. tłoczenia  pozycja 10",
				UWNL_2: "Błąd - temp. tłoczenia  pozycja 2",
				UWNL_3: "Błąd - temp. tłoczenia  pozycja 3",
				UWNL_4: "Błąd - temp. tłoczenia  pozycja 4",
				UWNL_5: "Błąd - temp. tłoczenia  pozycja 5",
				UWNL_6: "Błąd - temp. tłoczenia  pozycja 6",
				UWNL_7: "Błąd - temp. tłoczenia  pozycja 7",
				UWNL_8: "Błąd - temp. tłoczenia  pozycja 8",
				UWNL_9: "Błąd - temp. tłoczenia  pozycja 9",
				UWNM_1: "Czas błedu pozycja 1",
				UWNM_10: "Czas błedu pozycja 10",
				UWNM_2: "Czas błedu pozycja 2",
				UWNM_3: "Czas błedu pozycja 3",
				UWNM_4: "Czas błedu pozycja 4",
				UWNM_5: "Czas błedu pozycja 5",
				UWNM_6: "Czas błedu pozycja 6",
				UWNM_7: "Czas błedu pozycja 7",
				UWNM_8: "Czas błedu pozycja 8",
				UWNM_9: "Czas błedu pozycja 9",
				UWNN_1: "Data błędu pozycja 1",
				UWNN_10: "Data błędu pozycja 10",
				UWNN_2: "Data błędu pozycja 2",
				UWNN_3: "Data błędu pozycja 3",
				UWNN_4: "Data błędu pozycja 4",
				UWNN_5: "Data błędu pozycja 5",
				UWNN_6: "Data błędu pozycja 6",
				UWNN_7: "Data błędu pozycja 7",
				UWNN_8: "Data błędu pozycja 8",
				UWNN_9: "Data błędu pozycja 9",
				VER_DZIEN: "Wersja oprogramowania liczba nr 1",
				VER_MIESIAC: "Wersja oprogramowania liczba nr 2",
				VER_ROK: "Identyfikator konfiguracji łączy",
				VER_S1: "Wersja oprogramowania sterownika",
				VR_ANO: "Możliwość podłączenia grzałki cwu",
				VR_FCI: "FC wbudowane w urządzenie",
				VR_FCO: "Możliwość podłączenia zewnętrznego FC",
				VR_FLI: "Wbudowany czujnik przepływu w ob. odbioru",
				VR_FLO: "Możliwość podłączenia zewn. czujnika przepływu",
				VR_PO: "Wbudowana pompa obiegowa odbioru",
				VR_PZ: "Wbudowana pompa obiegowa źródła",
				VR_REV: "Możliwość podłączenia źródła szczytowego",
				VR_ZCH: "Możliwość podłączenia pompy PGRZ",
				VR_ZCW: "Wbudowany zawór cwu",
				VR_ZKO: "Możliwość podłączenia pompy PCHL",
				WRN2: "Zdublowane z SSB.QRMAX",
				XSCHT: "Timer defrostu [minuty]",
				XXA: "Zmienna pomocnicza do automatycznej zmiany czasu",
				XXAX: "Zmienna pomocnicza do manualnego sterowania funkcją antylegionella",
				XXXW: "NA",
				XZM_TRANSM: "Status transmisji master",
				Z: "Zmienna pomocnicza - test przekaźników",
				ZCWU_POS: "Zmienna pomocnicza - CWU",
				ZM_AC_IN_CUR: "Prąd wejściowy",
				ZM_AC_IN_VOL: "Napięcie wejściowe",
				ZM_BLANK: "Wypełnia niepodpięte sygnały w komunikacji  z jednostką zewnętrzną",
				ZM_COIL_TEMP: "Temperatura wymiennika",
				ZM_CUR_COMP_FREQ: "Aktualna częstotliwość sprężarki",
				ZM_CUR_MAX_FREQ: "Aktualna częstotliwość maksymalna",
				ZM_CUR_OP_MOD: "Aktualny tryb pracy",
				ZM_DEFR_SIGN: "Sygnał rozszraniania",
				ZM_DIS_TEMP: "Temperatura tłoczenia",
				ZM_DPS: "Podlączone do rejestru Outdoor Dip-switch setting jednostki zewnętrznej",
				ZM_EEV_OPEN: "Otwarcie zaworu rozprężnego",
				ZM_ERR1: "Rejestr błędu jedn. zewn. - numer 1",
				ZM_ERR2: "Rejestr błędu jedn. zewn. - numer 2",
				ZM_ERR3: "Rejestr błędu jedn. zewn. - numer 3",
				ZM_FAN_MAX_SPE: "Maksymalna prędkość wentylatora",
				ZM_FAN1_SPE: "Prędkość wentylatora nr 1",
				ZM_FAN2_SPE: "Prędkość wentylatora nr 2",
				ZM_OUT_TEMP: "Temperatura otoczenia",
				ZM_PRES_COND: "Ciśn. tłoczenia",
				ZM_PRES_EVAP: "Ciśn. ssania",
				ZM_SS: "Podłączone do rejestru Timer Relay feedback jednostki zewnętrznej",
				ZM_SUCT_TEMP: "Temperatura ssania",
				ZMA_MW_MOD: "NA",
				ZMB_SET_COMP_SPEED: "Nastawa prędkości sprężarki",
				ZMB_SET_EEV_OPEN: "Nastawa otwarcia zaworu",
				ZMB_SET_INW_TEMP: "Nastawa temperatury Tui",
				ZMT_POS: "NA",
				ZTEMP: "Informacja o tanim prądzie",
				ZZZ: "Zmienna pomocnicza - statystyki",
				ZZZPJK: "NA",
				ZZZSPRETA: "Zmienna pomocnicza - praca spężarki",
				ZZZZZ: "NA",
				ZZZZZZZZZZA1: "NA",
				ZZZZZZZZZZA2: "Dubluje TSM15"
			}
			
			break
		default:
			break
	}
	
	return descriptions
}