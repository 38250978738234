const defaultData = (data, setPublish) => {
    // tablica do rozpoznawania dni w pętli
    const dayTable = ['P', 'W', 'S', 'C', 'PT', 'SO', 'N']

    // przez zerowanie minut końca drugiej strefy cofa mi się dziadostwo, muszę sprawdzić warunki na koniec drugiej strefy
    // może dodać warunek dla minut i jeśli 60 to nie zmieniać

    let defaultDataObj = {
        CWP1P_PG: 0,
        CWP1P_PM: 0,
        CWP1P_KM: 0,
        CWP2P_PM: 0,
        CWP2P_KM: 0,
        CWP3P_PM: 0,
        CWP3P_KG: 23,
        CWP3P_KM: 60,
        CWW1P_PG: 0,
        CWW1P_PM: 0,
        CWW1P_KM: 0,
        CWW2P_PM: 0,
        CWW2P_KM: 0,
        CWW3P_PM: 0,
        CWW3P_KG: 23,
        CWW3P_KM: 60,
        CWS1P_PG: 0,
        CWS1P_PM: 0,
        CWS1P_KM: 0,
        CWS2P_PM: 0,
        CWS2P_KM: 0,
        CWS3P_PM: 0,
        CWS3P_KG: 23,
        CWS3P_KM: 60,
        CWC1P_PG: 0,
        CWC1P_PM: 0,
        CWC1P_KM: 0,
        CWC2P_PM: 0,
        CWC2P_KM: 0,
        CWC3P_PM: 0,
        CWC3P_KG: 23,
        CWC3P_KM: 60,
        CWPT1P_PG: 0,
        CWPT1P_PM: 0,
        CWPT1P_KM: 0,
        CWPT2P_PM: 0,
        CWPT2P_KM: 0,
        CWPT3P_PM: 0,
        CWPT3P_KG: 23,
        CWPT3P_KM: 60,
        CWSO1P_PG: 0,
        CWSO1P_PM: 0,
        CWSO1P_KM: 0,
        CWSO2P_PM: 0,
        CWSO2P_KM: 0,
        CWSO3P_PM: 0,
        CWSO3P_KG: 23,
        CWSO3P_KM: 60,
        CWN1P_PG: 0,
        CWN1P_PM: 0,
        CWN1P_KM: 0,
        CWN2P_PM: 0,
        CWN2P_KM: 0,
        CWN3P_PM: 0,
        CWN3P_KG: 23,
        CWN3P_KM: 60,
        TWP1P_PG: 0,
        TWP1P_PM: 0,
        TWP1P_KM: 0,
        TWP2P_PM: 0,
        TWP2P_KM: 0,
        TWP3P_PM: 0,
        TWP3P_KG: 23,
        TWP3P_KM: 60,
        TWW1P_PG: 0,
        TWW1P_PM: 0,
        TWW1P_KM: 0,
        TWW2P_PM: 0,
        TWW2P_KM: 0,
        TWW3P_PM: 0,
        TWW3P_KG: 23,
        TWW3P_KM: 60,
        TWS1P_PG: 0,
        TWS1P_PM: 0,
        TWS1P_KM: 0,
        TWS2P_PM: 0,
        TWS2P_KM: 0,
        TWS3P_PM: 0,
        TWS3P_KG: 23,
        TWS3P_KM: 60,
        TWC1P_PG: 0,
        TWC1P_PM: 0,
        TWC1P_KM: 0,
        TWC2P_PM: 0,
        TWC2P_KM: 0,
        TWC3P_PM: 0,
        TWC3P_KG: 23,
        TWC3P_KM: 60,
        TWPT1P_PG: 0,
        TWPT1P_PM: 0,
        TWPT1P_KM: 0,
        TWPT2P_PM: 0,
        TWPT2P_KM: 0,
        TWPT3P_PM: 0,
        TWPT3P_KG: 23,
        TWPT3P_KM: 60,
        TWSO1P_PG: 0,
        TWSO1P_PM: 0,
        TWSO1P_KM: 0,
        TWSO2P_PM: 0,
        TWSO2P_KM: 0,
        TWSO3P_PM: 0,
        TWSO3P_KG: 23,
        TWSO3P_KM: 60,
        TWN1P_PG: 0,
        TWN1P_PM: 0,
        TWN1P_KM: 0,
        TWN2P_PM: 0,
        TWN2P_KM: 0,
        TWN3P_PM: 0,
        TWN3P_KG: 23,
        TWN3P_KM: 60,
        CYRP1P_PM: 0,
        CYRP1P_KM: 0,
        CYRP2P_PM: 0,
        CYRP2P_KM: 0,
        CYRP3P_PM: 0,
        // CYRP3P_KM: 0,
        CYRW1P_PM: 0,
        CYRW1P_KM: 0,
        CYRW2P_PM: 0,
        CYRW2P_KM: 0,
        CYRW3P_PM: 0,
        // CYRW3P_KM: 0,
        CYRS1P_PM: 0,
        CYRS1P_KM: 0,
        CYRS2P_PM: 0,
        CYRS2P_KM: 0,
        CYRS3P_PM: 0,
        // CYRS3P_KM: 0,
        CYRC1P_PM: 0,
        CYRC1P_KM: 0,
        CYRC2P_PM: 0,
        CYRC2P_KM: 0,
        CYRC3P_PM: 0,
        // CYRC3P_KM: 0,
        CYRPT1P_PM: 0,
        CYRPT1P_KM: 0,
        CYRPT2P_PM: 0,
        CYRPT2P_KM: 0,
        CYRPT3P_PM: 0,
        // CYRPT3P_KM: 0,
        CYRSO1P_PM: 0,
        CYRSO1P_KM: 0,
        CYRSO2P_PM: 0,
        CYRSO2P_KM: 0,
        CYRSO3P_PM: 0,
        // CYRSO3P_KM: 0,
        CYRN1P_PM: 0,
        CYRN1P_KM: 0,
        CYRN2P_PM: 0,
        CYRN2P_KM: 0,
        CYRN3P_PM: 0,
        // CYRN3P_KM: 0,
        ECOT1R: 0,
        ECOT3S: 24,
    }
    console.log(defaultDataObj) 
    // USTAWIENIA DEFAULTOWE DLA TRYBU TIME
    // pętelka na 7 dni
    for (let i = 0; i <= 6; i++) {
        // tu wybieram fragment stringa do właściwości
        let dayString = dayTable[i]

        // WODA
        // tutaj sprawdzam czy start drugiej strefy mieści się w zakresie 1-23, 
        // tak - ustawiam koniec pierwszej strefy na to co mam w starcie drugiej (takie sklejenie dla pewności)
        // nie - ustawiam start drugiej strefy i koniec pierwszej strefy na godziną 1:00
        if (data[`CW${dayString}2P_PG`] >= 1 && data[`CW${dayString}2P_PG`] < 23) {
            defaultDataObj[`CW${dayString}1P_KG`] = data[`CW${dayString}2P_PG`]
        } else {
            defaultDataObj[`CW${dayString}1P_KG`] = 1
            defaultDataObj[`CW${dayString}2P_PG`] = 1
        }

        // tutaj sprawdzam czy koniec drugiej strefy mieści się w zakresie 1-23 i czy jest po rozpoczęciu drugiej strefy
        // tak - ustawiam start trzeciej strefy na to co mam w końcu drugiej strefy (takie sklejenie dla pewności)
        // nie - ustawiam koniec drugiej strefy i rozpoczęcie trzeciej strefy na godzinę 23:00
        if (data[`CW${dayString}2P_KG`] >= 1 && data[`CW${dayString}2P_KG`] <= 23 && data[`CW${dayString}2P_KG`] > data[`CW${dayString}2P_PG`]) {
            defaultDataObj[`CW${dayString}3P_PG`] = data[`CW${dayString}2P_KG`]
        } else {
            defaultDataObj[`CW${dayString}2P_KG`] = 23
            defaultDataObj[`CW${dayString}3P_PG`] = 23
        }

        // if (data[`CW${dayString}2P_KM`] === 60){
        //     defaultDataObj[`CW${dayString}2P_KM`] = data[`CW${dayString}2P_KM`]
        //     defaultDataObj[`CW${dayString}3P_PM`] = data[`CW${dayString}2P_KM`]
        // } else {
        //     defaultDataObj[`CW${dayString}2P_KM`] = 0
        //     defaultDataObj[`CW${dayString}3P_PM`] = 0
        // }

        // sprawdzam ustalone zakresy temperatur, jak jest poza zakresem to ustawiam na minimalną (zakres dla wody 35-55)
        for (let j = 1; j <= 3; j++) {
            if (data[`CW${dayString}${j}T`] >= 35 && data[`CW${dayString}${j}T`] <= 55) {
                defaultDataObj[`CW${dayString}${j}T`] = data[`CW${dayString}${j}T`]
            } else {
                defaultDataObj[`CW${dayString}${j}T`] = 35
            }
        }

        // DOM
        // poniższe dwa warunki robią to samo co powyższe, ale dla temperatury w domu
        if (data[`TW${dayString}2P_PG`] >= 1 && data[`TW${dayString}2P_PG`] < 23) {
            defaultDataObj[`TW${dayString}1P_KG`] = data[`TW${dayString}2P_PG`]
        } else {
            defaultDataObj[`TW${dayString}1P_KG`] = 1
            defaultDataObj[`TW${dayString}2P_PG`] = 1
        }

        if (data[`TW${dayString}2P_KG`] >= 1 && data[`TW${dayString}2P_KG`] <= 23 && data[`TW${dayString}2P_KG`] > data[`TW${dayString}2P_PG`]) {
            defaultDataObj[`TW${dayString}3P_PG`] = data[`TW${dayString}2P_KG`]
        } else {
            defaultDataObj[`TW${dayString}2P_KG`] = 23
            defaultDataObj[`TW${dayString}3P_PG`] = 23
        }

        // if (data[`TW${dayString}2P_KM`] === 60){
        //     defaultDataObj[`TW${dayString}2P_KM`] = data[`TW${dayString}2P_KM`]
        //     defaultDataObj[`TW${dayString}3P_PM`] = data[`TW${dayString}2P_KM`]
        // } else {
        //     defaultDataObj[`TW${dayString}2P_KM`] = 0
        //     defaultDataObj[`TW${dayString}3P_PM`] = 0
        // }

        // sprawdzam ustalone zakresy temperatur, jak jest poza zakresem to ustawiam na minimalną (zakres dla powietrza 18-25)
        for (let j = 1; j <= 3; j++) {
            if (data[`TW${dayString}${j}T`] >= 10 && data[`TW${dayString}${j}T`] <= 30) {
                defaultDataObj[`TW${dayString}${j}T`] = data[`TW${dayString}${j}T`]
            } else {
                defaultDataObj[`TW${dayString}${j}T`] = 18
            }
        }

    }

//USTAWIENIA DEFAULTOWE DLA CYRKULACJI
    // pętelka na 7 dni
    for (let i = 0; i <= 6; i++) {
        // tu wybieram fragment stringa do właściwości
        let dayString = dayTable[i]
        //tutaj sprawdzamy nastawy trzeciej strefy
        if (data[`CYR${dayString}3P_KG`] === 23 && data[`CYR${dayString}3P_KM`] === 60) {
            defaultDataObj[`CYR${dayString}3P_KG`] = data[`CYR${dayString}3P_KG`]
            defaultDataObj[`CYR${dayString}3P_KM`] = data[`CYR${dayString}3P_KM`]
        } else defaultDataObj[`CYR${dayString}3P_KM`] = 0
        
        // to do - wartości absurdalen
        if (data[`CYR${dayString}1P_PG`] >= 0 && data[`CYR${dayString}1P_PG`] <= 21
            && data[`CYR${dayString}1P_KG`] >= 1 && data[`CYR${dayString}1P_KG`] <= 22
            && data[`CYR${dayString}2P_PG`] >= 1 && data[`CYR${dayString}2P_PG`] <= 22
            && data[`CYR${dayString}2P_KG`] >= 2 && data[`CYR${dayString}2P_KG`] <= 23
            && data[`CYR${dayString}3P_PG`] >= 2 && data[`CYR${dayString}3P_PG`] <= 23
            && data[`CYR${dayString}3P_KG`] >= 3 && data[`CYR${dayString}3P_KG`] <= 23) {

            if (data[`CYR${dayString}1P_PG`] < data[`CYR${dayString}1P_KG`] <= data[`CYR${dayString}2P_PG`] < data[`CYR${dayString}2P_KG`] <= data[`CYR${dayString}3P_PG`] < data[`CYR${dayString}3P_KG`]) {
                // console.log("jest git")
            } else {
                defaultDataObj[`CYR${dayString}1P_PG`] = 6
                defaultDataObj[`CYR${dayString}1P_KG`] = 8
                defaultDataObj[`CYR${dayString}2P_PG`] = 17
                defaultDataObj[`CYR${dayString}2P_KG`] = 19
                defaultDataObj[`CYR${dayString}3P_PG`] = 21
                defaultDataObj[`CYR${dayString}3P_KG`] = 23
            }

        } else {
            defaultDataObj[`CYR${dayString}1P_PG`] = 6
            defaultDataObj[`CYR${dayString}1P_KG`] = 8
            defaultDataObj[`CYR${dayString}2P_PG`] = 17
            defaultDataObj[`CYR${dayString}2P_KG`] = 19
            defaultDataObj[`CYR${dayString}3P_PG`] = 21
            defaultDataObj[`CYR${dayString}3P_KG`] = 23
            defaultDataObj[`CYR${dayString}3P_KM`] = 60
        }
    }

    if (data['CYR_FILL'] > 60) { 
        defaultDataObj['CYR_FILL'] = 60 
    } else if (data['CYR_FILL'] < 5) { 
        defaultDataObj['CYR_FILL'] = 5 
    } 
 
    if (data['CYR_BS'] - data['CYR_FILL'] > 60 && data['CYR_FILL'] > 60) { 
        defaultDataObj['CYR_BS'] = 120  
        defaultDataObj['CYR_FILL'] = 60 
    } else if (data['CYR_BS'] - data['CYR_FILL'] > 60 && data['CYR_FILL'] <= 60) { 
        defaultDataObj['CYR_BS'] = data['CYR_FILL'] + 60 
    } else if (data['CYR_BS'] - data['CYR_FILL'] < 5 && data['CYR_FILL'] > 60) { 
        defaultDataObj['CYR_BS'] = 65  
        defaultDataObj['CYR_FILL'] = 60 
    } else if (data['CYR_BS'] - data['CYR_FILL'] < 5 && data['CYR_FILL'] < 5) { 
        defaultDataObj['CYR_BS'] = 10  
        defaultDataObj['CYR_FILL'] = 5 
    } else if (data['CYR_BS'] - data['CYR_FILL'] < 5 && data['CYR_FILL'] <= 60) { 
        defaultDataObj['CYR_BS'] = data['CYR_FILL'] + 5 
    }  

    // publikacja danych za pomocą funkcji setPublish
    setPublish(defaultDataObj)
}

export default defaultData