import { createTheme } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'

const theme = createTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiContainer: {
            root: {
                marginTop: '0 !important',
            },
        },
        MuiFormControl: {
            root: {
                margin: '0 auto 0px',
                '& .PrivateSwitchBase-': {
                    padding: 0,
                },
                width: '100%'
            },
        },
        MuiFormLabel: {
            root: {
                marginBottom: '10px',
                '@media (max-width:600px)': {
                    fontSize: '12px',
                },
            }
        },
        MuiButton: {
            fontFamily: [
                'Geogrotesque-Regular',
                'Geogrotesque-SemiBold',
                'Geogrotesque-Medium',
                'sans-serif',
            ].join(','),
            root: {
                fontFamily: 'Geogrotesque-Regular',
            },
        },
        MuiInput: {
            underline: {
                '&:hover': {
                    '&:not(.Mui-disabled)': {
                        '&:before': {
                            borderBottom: "2px solid #005492",
                        },
                    },
                },
            },
            root: {
                '@media (max-width:600px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiInputLabel: {
            root: {
                '@media (max-width:600px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiSwitch: {
            thumb: {
                width: '29px',
                height: '29px',
            },
            track: {
                borderRadius: '13px',
                opacity: 1,
                backgroundColor: '#868383',
            },
            switchBase: {
                left: '0',
                top: '-7px',
                '& .Mui-checked': {
                    opacity: 1
                },
                color: '#b3b3b3',
            },
            colorPrimary: {
                opacity: 1,
                '& .Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#b3b3b3 !important',
                }
            },
            root: {
                width: '65px',
                height: '40px',
                padding: '4px 8px 11px 8px',
            },
        },
        MuiTabs: {
            indicator: {
                display: 'none',
            },
            root: {
                marginTop: '20px',
            },
        },
        MuiTab: {
            root: {
                border: '2px solid #ffffff !important',
                maxWidth: '14% !important',
                width: '12.5% !important',
                minWidth: 'auto !important',
                margin: '0 6px',
                fontSize: '42px',
                lineHeight: 1,
                borderRadius: '4px',
                textColorPrimary: {
                    color: '#fffffff',
                },
            }
        },
        MuiSlider: {
            markLabel:{
                color: '#ffffff !important',
                top: 0,
            }
        },
        MuiDialog: {
            paper: {
                background: "rgba(0, 0, 0, 0.85)",
                border: "2px solid rgba(255, 255, 255, 0.6)",
                color: "#ffffff",
                borderRadius: "12px",
            }
        },
        MuiIconButton: {
            root: {
                color: "rgba(255, 255, 255, 0.6)"
            },
        },
        MuiSvgIcon: {
            root: {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "36px",
                border: "2px solid rgba(255, 255, 255, 0.8)",
                borderRadius: "60px"
            },
        },
        MuiTable: {
            root: {
               marginTop: "35px"
            }
        },
        MuiTableContainer: {
            root: {
                overflowX: 'clip'
            }
        },
        MuiTableCell: {
            root: {
                fontSize: '22px',
                lineHeight: '20px',
                '&:first-child': {
                    textAlign: 'left',
                    borderLeft: 'none',
               },
               '&:last-child': {
                borderRight: 'none',
               } 
            },
            body: {
                color: 'white',
                textAlign: 'right',
                '&.top-row': {
                    borderTop: 'none',
                },
                '&.bottom-row': {
                    borderBottom: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                },
                '&.summary-cell': {
                    borderLeft: 'none',
                    borderRight: 'none',
                },
            },
        },
    },
    typography: {
        fontFamily: [
            'Geogrotesque-Regular',
            'Geogrotesque-SemiBold',
            'Geogrotesque-Medium',
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: 'Geogrotesque-Medium',
            fontSize: '236px',
            margin: '-40px auto 0',
            lineHeight: '170px',
            '@media (max-width:960px)': {
                fontSize: '30px',
            },
            '@media (max-width:600px)': {
                fontSize: '26px',
            },
        },
        h2: {
            fontFamily: 'Geogrotesque-Medium',
            fontSize: '46px',
            margin: '20px auto',
            lineHeight: '38px',
            '@media (max-width:960px)': {
                fontSize: '22px',
            },
            '@media (max-width:600px)': {
                fontSize: '18px',
                margin: '20px auto 10px',
            },
        },
        h3: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '38px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        h4: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '92px',
            color: '#b3b3b3',
            lineHeight: "20px",
            margin: '0 auto',
            '@media (max-width:960px)': {
                fontSize: '92px',
            },
            '@media (max-width:600px)': {
                fontSize: '82px',
            },
        },
        h5: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '24px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '24px',
            },
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
        body2: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '12px',
            margin: '0 auto',
            fontWeight: 'lighter'
            , '@media (max-width:600px)': {
                fontSize: '10px',
            },
        },
        body1: {
            fontFamily: 'Geogrotesque-Medium',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'left',
        },
    },
    palette: {
        primary: {
            main: '#005492',
        },
    },
    margin: {
        marginLarge: '50px auto',
        marginMedium: '30px auto',
        marginSmall: '15px auto',
    },
    tempcolors: {
        color25: '#ff1e04',
        color24: '#fd8c13',
        color23: '#f9c623',
        color22: '#bfd82a',
        color21: '#37b753',
        color20: '#009191',
        color19: '#00aaff',
        color18: '#003eff',
        color55: '#ff0000',
        color54: '#ff2806',
        color53: '#fe460b',
        color52: '#fc6511',
        color51: '#fb8116',
        color50: '#fa9e1c',
        color49: '#f9b921',
        color48: '#dec42a',
        color47: '#afc035',
        color46: '#84bd40',
        color45: '#59ba4b',
        color44: '#34bb5c',
        color43: '#2ac87c',
        color42: '#20d59c',
        color41: '#16e2bb',
        color40: '#0bf0dc',
        color39: '#02fcf9',
        color38: '#00d7ff',
        color37: '#00abff',
        color36: '#007eff',
        color35: '#0058ff',
    }
}, plPL)



export default theme