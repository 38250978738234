const userStatus = {
    1: "UNVERIFIED",
    2: "VERIFIED",
    3: "DISABLED",
    4: "DELETED"
}

const userType = {
    0: "Brak typu",
    1: "OPERATOR",
    2: "INSTALLATOR"
}

export default {
    userStatus,
    userType
}