import React, { useState, useEffect } from 'react'
import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../../../theme'
import { CarouselInputMulti } from '../../../../../../components'
import { dataRange } from '../../../../../../data/dataRange'

const rangeMinute = (start, stop) => {
    if (start.PG === stop.KG) {
        if ((start.PM / 10) < 5)
            return (start.PM / 10) + 1
        else
            return start.PM / 10
    } else {
        return 0
    }
}

const TimeZone = ({ timeZone, num, stamp, day, handleChangeTime, daySwitch, setPublish }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: null, num: "", val: 0 })
    const [state, setState] = useState(timeZone) 

    // let minValuePG = typeof(dataRange()[`CYR${stamp}${num}P_PG`].min) === 'string' ? parseFloat(timeZone[dataRange()[`CYR${stamp}${num}P_PG`].min.slice(0, 3) + dataRange()[`CYR${stamp}${num}P_PG`].min.slice(4, -4)].PG) : dataRange()[`CYR${stamp}${num}P_PG`].min
    // let maxValuePG = typeof(dataRange()[`CYR${stamp}${num}P_PG`].max) === 'string' ? parseFloat(timeZone[dataRange()[`CYR${stamp}${num}P_PG`].max.slice(0, 3) + dataRange()[`CYR${stamp}${num}P_PG`].max.slice(4, -4)].PG) : dataRange()[`CYR${stamp}${num}P_PG`].max
    // let minValuePG = typeof (dataRange()[`CYR${stamp}${num}P_PG`].min) === "string" ? parseFloat(item[`CYR${stamp}${num}P_PG`.min]) : parseFloat(dataRange()[`CYR${stamp}${num}P_PG`].min)
    // let maxValuePG = typeof (dataRange()[`CYR${stamp}${num}P_PG`].max) === "string" ? parseFloat(item[`CYR${stamp}${num}P_PG`.max]) : parseFloat(dataRange()[`CYR${stamp}${num}P_PG`].max)
    // let minValueKG = typeof (dataRange()[entry[0]].min) === "string" ? parseFloat(item[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
    // let maxValueKG = typeof (dataRange()[entry[0]].max) === "string" ? parseFloat(item[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)

    const handleClickOpen = (element, name, title, min, max, step, num, val) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step, num: num, val: val })
    }

    const handleClose = () => {
        setState({...state,
        [`CYR${num}`]: {
            ...state[`CYR${num}`],
            [open.name.slice(-2)]: open.val
        }})
        setOpen({ ...open, status: false })
        if (open.name === `CYR${stamp}3P_KG` && open.val === 24) {
            setPublish({ [open.name]: 23, [`CYR${stamp}3P_KM`]: 60 })
        } 
        else if (open.name === `CYR${stamp}3P_KG`) {
            setPublish({ [open.name]: open.val, [`CYR${stamp}3P_KM`]: 0 })
        } else setPublish({ [open.name]: open.val })
    }
    
    const setNewValue = (name, val) => {
        setOpen({
            ...open,
            val: val
        })
    }
    
    // console.log(timeZone[`CYRP1P_PG`.slice(0, 3) + `CYRP1P_PG`.slice(4, -4)])
    // console.log(typeof(dataRange()[`CYR${stamp}${num}P_PG`].min) === 'string' ? timeZone[dataRange()[`CYR${stamp}${num}P_PG`].min.slice(0, 3) + dataRange()[`CYR${stamp}${num}P_PG`].min.slice(4, -4)].PG : dataRange()[`CYR${stamp}${num}P_PG`].min)

    const handleSwitchValue = (e, item) => {
        if (item[e.name] === 1) {
            setState({ ...state, [e.name]: 0 })
            setPublish({ [e.name]: 0 })
        } else {
            setState({ ...state, [e.name]: 1})
            setPublish({ [e.name]: 1 })
        }
    }

    const switchControl = (entry, item) => {
        switch (dataRange()[`CYR${stamp}${num}P_${entry[0]}`].controller) {
            case 1:
                return (
                        <Grid item xs={2} className={classes.textRight}>
                            <Switch
                                checked={item[`CYR${stamp}${num}P_${entry[0]}`] === 0 ? false : true}
                                onChange={(e) => handleSwitchValue(e.target, item)}
                                color="primary"
                                name={`CYR${stamp}${num}P_${entry[0]}`}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                )
            case 2:
                let minValuePG = typeof(dataRange()[`CYR${stamp}${num}P_PG`].min) === 'string' ? parseFloat(timeZone['CYR' + dataRange()[`CYR${stamp}${num}P_PG`].min.slice(-5, -4)].KG) : dataRange()[`CYR${stamp}${num}P_PG`].min
                let maxValuePG = num === 3 && timeZone.CYR3.KG === 23 && timeZone.KM === 60 ? parseFloat(timeZone['CYR' + dataRange()[`CYR${stamp}${num}P_PG`].max.slice(-5, -4)].KG) : typeof(dataRange()[`CYR${stamp}${num}P_PG`].max) === 'string' ? parseFloat(timeZone['CYR' + dataRange()[`CYR${stamp}${num}P_PG`].max.slice(-5, -4)].KG) - 1 : dataRange()[`CYR${stamp}${num}P_PG`].max
                let minValueKG = typeof(dataRange()[`CYR${stamp}${num}P_KG`].min) === 'string' ? parseFloat(timeZone['CYR' + dataRange()[`CYR${stamp}${num}P_KG`].min.slice(-5, -4)].PG) + 1 : dataRange()[`CYR${stamp}${num}P_KG`].min
                let maxValueKG = typeof(dataRange()[`CYR${stamp}${num}P_KG`].max) === 'string' ? parseFloat(timeZone['CYR' + dataRange()[`CYR${stamp}${num}P_KG`].max.slice(-5, -4)].PG) : dataRange()[`CYR${stamp}${num}P_KG`].max

                if(entry[0].slice(-2) === 'PG') {
                    return (
                        <Grid item xs={2} className={classes.textRight} style={{ marginLeft: '25px' }} onClick={() => handleClickOpen(entry[1], `CYR${stamp}${num}P_${entry[0]}`, "Ustaw godzinę rozpoczęcia", minValuePG, maxValuePG, dataRange()[`CYR${stamp}${num}P_${entry[0]}`].step, [`CYR${stamp}${num}P_${entry[0]}`], entry[1])}>
                            <Typography variant="body2" className={"carousel-input-click"} style={{ width: '100px' }}>{dataRange()[`CYR${stamp}${num}P_${entry[0]}`].parsed === 'float' ? entry[1].toFixed(1) : entry[1]} {dataRange()[`CYR${stamp}${num}P_${entry[0]}`].symbol}</Typography>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid item xs={2} className={classes.textRight} style={{ marginLeft: '25px' }} onClick={() => handleClickOpen(entry[1], `CYR${stamp}${num}P_${entry[0]}`, "Ustaw godzinę zakończenia", minValueKG, maxValueKG, dataRange()[`CYR${stamp}${num}P_${entry[0]}`].step, [`CYR${stamp}${num}P_${entry[0]}`], entry[1])}>
                            <Typography variant="body2" className={"carousel-input-click"} style={{ width: '100px' }}>{entry[1] === 23 && state.KM === 60 ? 24 : entry[1]} {dataRange()[`CYR${stamp}${num}P_${entry[0]}`].symbol}</Typography>
                        </Grid>
                    )
                }
            default:
                break;
            
        }
    }

    useEffect(() => {
        let mounted = true
        setState(timeZone)
        return () => {
            mounted = false
        }
    }, [timeZone])

    return (
        <Grid container>
            <Grid item md={2} xs={2} className={"circulation"}>
                <p className={`${classes.textRight}`}>{num}</p><br/>
            </Grid>
            {state[`CYR${num}`] ? Object.entries(state[`CYR${num}`]).map(entry => {
                if (entry[1] !== undefined) {
                    return (
                        <Grid item xs={2} key={`CYR${stamp}${num}P_${entry[0]}`}>

                            {dataRange()[`CYR${stamp}${num}P_${entry[0]}`].controller && dataRange()[`CYR${stamp}${num}P_${entry[0]}`].RW === 1 ?
                                switchControl(entry, state[`CYR${num}`]) 
                                : <Grid item xs={2} className={classes.textRight}>
                                    {dataRange()[`CYR${stamp}${num}P_${entry[0]}`].controller === 4 ? entry[1] === 1 ? 
                                        <FiberManualRecord style={{ color: "green" }} /> 
                                            : <FiberManualRecord style={{ color: "red" }} />
                                                : <Typography variant="body2">{dataRange()[`CYR${stamp}${num}P_${entry[0]}`].parsed === 'float' ? 
                                                    entry[1].toFixed(1) 
                                                        : entry[1] < 0 ? 0 
                                                            : entry[1]}{dataRange()[`CYR${stamp}${num}P_${entry[0]}`].symbol}</Typography>}
                                </Grid>}
                        </Grid>
                        )
                    } else {
                        return null
                    }
                }) : null
            }
            {/*<Grid item md={2} xs={2}>
                <p className={`${classes.textRight}`}>{num}</p><br />
            </Grid>
            <Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen(timeZone[`CYR${num}`].PG, `CYR${stamp}${num}P_PG`, "Ustaw godzinę rozpoczęcia", "hm", 0, 24, `CYR${stamp}${num}P_PG`, timeZone[`CYR${num}`].PG)}>
                <div className="maskLikeInput">

                    <Typography variant="body2" className={"carousel-input-click2"}>{timeZone[`CYR${num}`].PG < 10 ? `0${timeZone[`CYR${num}`].PG}` : timeZone[`CYR${num}`].PG}</Typography>

                </div>
            </Grid>
            <Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen(timeZone[`CYR${num}`].KG, `CYR${stamp}${num}P_KG`, "Ustaw godzinę zakończenia", "hm", 0, 24, `CYR${stamp}${num}P_KG`, timeZone[`CYR${num}`].PG)}>
                <div className="maskLikeInput">
                    <Typography variant="body2" className={"carousel-input-click2"}>{timeZone[`CYR${num}`].KG < 10 ? `0${timeZone[`CYR${num}`].KG}` : timeZone[`CYR${num}`].KG}</Typography>
                </div>
            </Grid>*/}

            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <CarouselInputMulti style={{ float: "left" }} init={open.element === 23 && state.KM === 60 && open.name === `CYR${stamp}3P_KG` ? 24 : open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} step={open.step} num={open.num} />
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default TimeZone