const serviceNoteStatus = {
    1: "Nowe",
    2: "W realizacji",
    3: "Wstrzymane",
    4: "Zamknięte",
    5: "Zakończone",
    6: "Odrzucone",
    7: "Anulowane",
    8: "Reklamowane"
}

const serviceNoteType = {
    1: "Gwarancyjne niepłatne",
    2: "Gwarancyjne płatne",
    3: "Pogwarancyjne",
    4: "Przeglądy",
    5: "Inne"
}

const serviceNoteMethod = {
    1: "Telefonicznie",
    2: "Alerta",
    3: "E-mail",
    4: "SMS",
    5: "FCN",
    6: "Obserwacja",
    7: "Panel",
    8: "Slack",
    9: "Facebook",
    10: "Formularz WWW",
    11: "Aplikacja mobilna"
}

export default {
    serviceNoteStatus,
    serviceNoteType,
    serviceNoteMethod
}