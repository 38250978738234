import React from 'react'
import {
    Button
} from '@material-ui/core'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { dateConvert } from '../../../../functions'
import { default as paramsService } from '../../../../dictionary/serviceNoteDictionary'

const exportToCSV = (exportData, fileName) => {
    let exportTable = exportData.map(item => ({
        "ID": item.id,
        "Data zgłoszenia": item.date,
        "Data modyfikacji": item.modification_date,
        "Status": paramsService.serviceNoteStatus[item.note_status_id],
        "Kategoria": paramsService.serviceNoteType[item.note_type_id],
        "Tytuł": item.subject,
        "Numer seryjny": item.device.device_sn,
        "Klucz": item.device.key,
        "Sposób zgłoszenia": item.method_of_notification !== 0? paramsService.serviceNoteMethod[item.method_of_notification]:" brak informacji"
    }))

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"
    const ws = XLSX.utils.json_to_sheet(exportTable)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data, fileName + fileExtension)
}

const actualDate = new Date()

const ExportServisList = ({ dataToExport }) => {
    return (
        <React.Fragment>
            <Button onClick={() => exportToCSV(dataToExport, `Zgłoszenia-serwisowe-${dateConvert(actualDate).toISOString()}`)} variant="contained"
                color="primary" style={{ float: 'right' }}>Eksportuj zgłoszenia</Button>
        </React.Fragment>
    )
}

export default ExportServisList