const errorMessage = (status) => {
    switch (status) {
        case 410:
            return "Brak danych."
        case 411:
            return "Partner nie istnieje w bazie."
        case 412:
            return "Błąd serwera."
        case 413:
            return "Błąd walidacji."
        case 415:
            return "Partner z tym numerem telefonu już istnieje."
        case 416:
            return "Partner z tym adresem e-mail już istnieje."
        case 417: 
            return "Partner z tym numerem NIP już istnieje."
        case 418: 
            return "Błąd bazy danych."
        case 419:
            return "Partner podwykonawcy nie istnieje."
        case 420:
            return "Błędny numer NIP."
        case 421:
            return "Błędny adres e-mail."
        case 422:
            return "Błędny numer telefonu."
    
        default:
            return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
    }
}

export default errorMessage