import React from 'react'
import { Grid, Typography, Drawer, Button, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import BuildIcon from '@material-ui/icons/Build'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import hourglass from '../../../../img/hourglass.svg'
import warning from '../../../../img/warning.svg'

import { Link, useRouteMatch } from 'react-router-dom'

const NavbarBox = ({ name, icon, ext, errWarn, moreParams }) => {
    const { url } = useRouteMatch()
    const classes = mainStyleClasses()
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    let DateTime = new Date()
    let StampTime = new Date()
    let errorOfExpiration = null
    let warnInfo = 0
    DateTime = Date.now()

    if (ext) {
        StampTime = Date.parse(ext.TimeStamp);

        if ((DateTime - StampTime) > ext.ExpirationTime) {
            errorOfExpiration = 1;
        } else {
            errorOfExpiration = null;
        }
    }

    if (errWarn) {
        Object.entries(errWarn).map(warning => {
            if (warning[1] === 1)
                warnInfo = 1;
            return warnInfo;
        })
    }

    return (
        <React.Fragment><br />
            <Grid container>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={handleDrawerOpen}><BuildIcon style={{ fontSize: 40 }} /> serwis menu</Button>
                </Grid>
                <Grid item xs={6}>
                    {errorOfExpiration ? (<img src={hourglass} alt="Upłynął termin ważności danych. Dane mogą być nieaktualne."
                        title="Upłynął termin ważności danych. Dane mogą być nieaktualne."
                        className="float-right hourglass-icon"
                    />) : null}
                    {warnInfo === 1 ? (<img src={warning} alt="Wystąpił błąd. Więcej w zakładce Błędy i ostrzeżenia"
                        title="Wystąpił błąd. Więcej w zakładce Błędy i ostrzeżenia"
                        className="float-right warning-icon"
                    />) : null}
                </Grid>
            </Grid>
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
            >
                <div style={{ textAlign: "right" }}>
                    <Button onClick={handleDrawerClose}>
                        <HighlightOffIcon color="primary" style={{ fontSize: 40 }} />
                    </Button>
                </div>
                <Divider />
                <List>

                    <Link to={`${url}`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Podstawowe
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/controllertime`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Czas sterownika
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/temperature`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Odczyt temperatur
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/errorwarnings`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Błędy i ostrzeżenia
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/crot`}>
                        <ListItem button onClick={handleDrawerClose}>
                            CROT
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/internaltemperaturecontrol`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Sterowanie temp. wewnętrzna
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/controlfunctionstate`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Funkcje sterujące
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/blockade`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Blokada
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/ecomodesettings`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Nastawy trybu ECO
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`${url}/pumptimecontrol`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Sterowanie czasowe pompą
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={`/euros-monitor/${moreParams.inst}/${moreParams.pass}`}>
                        <ListItem button onClick={handleDrawerClose}>
                            Euros Monitor
                        </ListItem>
                    </Link>
                    <Divider />


                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default NavbarBox