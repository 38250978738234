import React from 'react'
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary'
import { dataRangeDictionary } from '../../../../dataFilter/dataRangeDictionary'
import { OptionList } from '../../../../components'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

const SwitchEco = ({eco, client, stamp, handleEcoSchedule}) => {

    let min;
    let max;
    if (typeof dataRangeDictionary.eco[stamp].min === 'number') {
        min = dataRangeDictionary.eco[stamp].min
    } else {
        min = eco[dataRangeDictionary.eco[stamp].min]
    }

    if (typeof dataRangeDictionary.eco[stamp].max === 'number') {
        max = dataRangeDictionary.eco[stamp].max
    } else {
        max = eco[dataRangeDictionary.eco[stamp].max]
    }


    return (
        <>
        <Form key={stamp}>
        <Form.Group as={Row}>
            <Col className="text-left" sm={10}>
                <Form.Label>{descriptionDictionary.eco[stamp]}</Form.Label>
            </Col>
            <Col className="text-right" sm={2}>
                <Form.Control name={stamp} as="select" value={eco[stamp]} custom
                    onChange={(e) => { handleEcoSchedule(e); }}>
                    <OptionList nameKey={stamp} start={min} stop={max} />
                </Form.Control>
            </Col>
        </Form.Group>
    </Form>
    
    </>
    )
}

export default SwitchEco;