// const deviceFriendlyName = {
//     111: "ATMO",
//     121: "GEO",
//     131: "ALLINONE",
//     25011: "Przemysłowe",
//     25021: "Przemysłowe",
//     250311: "Przemysłowe",
//     250411: "Przemysłowe",
//     250511: "Przemysłowe",
//     250611: "Przemysłowe",
//     250711: "Przemysłowe",
//     250811: "Przemysłowe",
//     250911: "Przemysłowe",
//     2501011: "Przemysłowe",
//     2501111: "Przemysłowe",
//     000: "CUSTOM",
//     122: "GEOPLUS",
//     211: "EMS",
//     10011: "PV",
// }

// const deviceFriendlyName2 = {
//     ATMO: {
//         friendlyName: "ATMO", 
//         numbers: [111]
//     },
//     GEO: {
//         friendlyName: "GEO", 
//         numbers: [121]
//     },
//     ALLINONE: {
//         friendlyName: "ALLINONE", 
//         numbers: [131]
//     },
//     PRZEMYSLOWE: { 
//         friendlyName: "Przemysłowe", 
//         numbers: [25011, 25021, 250311, 250411, 250511, 250611, 250711, 250811, 250911, 2501011, 2501111] 
//     },
//     CUSTOM: {
//         friendlyName: "CUSTOM",
//         numbers: [000]
//     },
//     GEOPLUS: {
//         friendlyName: "GEOPLUS",
//         numbers: [010202]
//     },
//     EMS: {
//         friendlyName: "EMS",
//         numbers: [020101]
//     },
//     PV: {
//         friendlyName: "PV",
//         numbers: [10011]
//     }
// }
export const deviceFriendlyName3 = (data) => {
    let typeArr = []

    data.map((item, index) => {
        if (!typeArr.includes(item)) {
            switch (item.dev_type.slice(0, 3)) {
                case "FA0":
                    if (typeArr.includes("PRZEMYSŁOWE"))
                        break
                    else
                        typeArr.push("PRZEMYSŁOWE")
                    break;

                default:
                    if (typeArr.includes(item.dev_type))
                        break
                    else
                        typeArr.push(item.dev_type)
                    break;
            }
        }
    })

    return typeArr
}


// export default {
//     deviceFriendlyName3
// }