import { fetchRefreshToken } from './'

const fetchCertInstallator = async (id) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installators/${id}/gen_cert`, {
        method: "GET",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/pdf'
        }
      })
      .then(response => response.blob())
      return res
    })

    return response
}

  export default fetchCertInstallator
