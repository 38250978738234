import React, { useEffect, useState } from 'react'
import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../theme'
import { registerDictionary } from '../../../data/dataDictionary'
import { dataRange } from '../../../data/dataRange'
import { CarouselInputMulti } from '../../../components'
import { ItemMapping, ExtenderMapping } from '../../../components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore' 
import ExpandLessIcon from '@material-ui/icons/ExpandLess' 

const Extender = ({ settings, setPublish, setPublishDevice, mainDriver }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0 })
    const [sumVal, setSumVal] = useState({1: 0, 2: 0 })
    const [clickedId, setClickedId] = useState({}) 

    const dictionary = registerDictionary()

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
        setSumVal({ 
            1: val.toString().split('.')[0], 
            2: parseInt((val * 10)).toString().slice(-1), 
        }) 
    }
    
    const handleClose = (blok) => {
        if (open.blok === 'tempMode' || open.blok === 'PWMOut') { 
            setItemValue({ ...itemValue, 
                [blok]: {
                    ...itemValue[blok],
                    [open.name]: parseFloat(`${sumVal['1']}.${sumVal['2']}`)
                },
            })
            setPublishDevice({ 
                Name: settings.extDevice.Name, 
                Type: settings.extDevice.Type, 
                Version: settings.extDevice.Version, 
            }) 
            setPublish({ [open.name]: parseFloat(`${sumVal['1']}.${sumVal['2']}`)})
        } else if (open.blok === 'mainDriver') { 
            setItemValue({ ...itemValue,  
                [blok]: { 
                    ...itemValue[blok], 
                    [open.name]: open.val 
                }, 
            }) 
            setPublishDevice(null) 
            setPublish({ [open.name]: open.val}) 
        } else {
            setItemValue({ ...itemValue, 
                [blok]: {
                    ...itemValue[blok],
                    [open.name]: open.val
                },
            })
            setPublishDevice({
                Name: settings.extDevice.Name,
                Type: settings.extDevice.Type,
                Version: settings.extDevice.Version,
            })
            setPublish({ [open.name]: open.val})
        }
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        if (open.blok === 'tempMode' || open.blok === 'PWMOut') { 
            setSumVal({...sumVal, [num]: val})
        } else {
            setOpen({...open, val: val })
        }
    }
 
    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublishDevice({
                Name: settings.extDevice.Name,
                Type: settings.extDevice.Type,
                Version: settings.extDevice.Version,
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublishDevice({
                Name: settings.extDevice.Name,
                Type: settings.extDevice.Type,
                Version: settings.extDevice.Version,
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const handleSwitchPV = (e, item, blok) => { 
        if (item[e.name] === 0) { 
            setItemValue({ ...itemValue,  
                            [blok]: { 
                                ...itemValue[blok], 
                                [e.name]: 32133 
                            } 
            }) 
            setPublishDevice({ 
                Name: settings.extDevice.Name, 
                Type: settings.extDevice.Type, 
                Version: settings.extDevice.Version, 
            }) 
            setPublish({ [e.name]: 32133 }) 
        } else { 
            setItemValue({ ...itemValue,  
                            [blok]: { 
                                ...itemValue[blok], 
                                [e.name]: 0 
                            } 
            }) 
            setPublishDevice({ 
                Name: settings.extDevice.Name, 
                Type: settings.extDevice.Type, 
                Version: settings.extDevice.Version, 
            }) 
            setPublish({ [e.name]: 0 }) 
        } 
    }

    const handleClickOpenList = (id) => { 
        if (!clickedId[id]) 
            setClickedId({...clickedId, [id]: id}) 
        else if (clickedId[id]) 
            setClickedId({...clickedId, [id]: null}) 
    } 

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">{`Extender ${settings.extDevice.Name.slice(-1)}`}</Typography>
                </Grid>
                <Grid item xs={12}> 
                    <Typography variant="body2">{`Wersja oprogramowania ${settings.VER_DZIEN_2}.${settings.VER_MIESIAC_2}`}</Typography> 
                </Grid> 
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.extender.settingsPV.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.settingsPV, handleSwitchValue, handleClickOpen, dictionary.extender.settingsPV.data, classes, filteredItems(itemValue.settingsPV), 'settingsPV')}
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}> 
                            <Grid item xs={8}> 
                                <Typography variant="body2">{dictionary.extender.settingsPV.data.LICZNIK_PV_PWR_NADPROD_AR}</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.textRight}> 
                                <Switch 
                                    checked={itemValue.LICZNIK_PV_PWR_NADPROD_AR === 0 ? true : itemValue.LICZNIK_PV_PWR_NADPROD_AR === 32133 ? false : false}
                                    onChange={(e) => handleSwitchPV(e.target, itemValue, '')} 
                                    color="primary" 
                                    name={'LICZNIK_PV_PWR_NADPROD_AR'} 
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                /> 
                            </Grid> 
                            <Grid item xs={2} className={classes.textRight} onClick={() => handleClickOpen(itemValue.LICZNIK_PV_PWR_NADPROD_AR, 'LICZNIK_PV_PWR_NADPROD_AR', dictionary.extender.settingsPV.data.LICZNIK_PV_PWR_NADPROD_AR, dataRange().LICZNIK_PV_PWR_NADPROD_AR.min, dataRange().LICZNIK_PV_PWR_NADPROD_AR.max, dataRange().LICZNIK_PV_PWR_NADPROD_AR.step, 'LICZNIK_PV_PWR_NADPROD_AR', itemValue.LICZNIK_PV_PWR_NADPROD_AR, '')}> 
                                <Typography variant="body2" className={"carousel-input-click"}>{itemValue.LICZNIK_PV_PWR_NADPROD_AR === 32133 ? 0 : itemValue.LICZNIK_PV_PWR_NADPROD_AR}</Typography> 
                            </Grid> 
                        </Grid> 
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.extender.tempSettings.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.tempSettings, handleSwitchValue, handleClickOpen, dictionary.extender.tempSettings.data, classes, filteredItems(itemValue.tempSettings), 'tempSettings')}
                    </Grid>
                </Grid>
                <Grid item xs={6}> 
                    <Grid item> 
                        <Typography variant="h2">{dictionary.extender.status.title}</Typography> 
                    </Grid> 
                    <Grid item> 
                        {ItemMapping(itemValue.status, handleSwitchValue, handleClickOpen, dictionary.extender.status.data, classes, filteredItems(itemValue.status), 'status')} 
                    </Grid> 
                </Grid> 
                <Grid item xs={6}> 
                    <Grid item> 
                        <Typography variant="h2">{dictionary.extender.forcePV.title}</Typography> 
                    </Grid> 
                    <Grid item> 
                        {ItemMapping(itemValue.forcePV, handleSwitchValue, handleClickOpen, dictionary.extender.forcePV.data, classes, filteredItems(itemValue.forcePV), 'forcePV')} 
                    </Grid> 
                </Grid> 
                <Grid item xs={6}> 
                    <Grid item> 
                        <Typography variant="h2">{dictionary.extender.tempDisplay.title}</Typography> 
                    </Grid> 
                    <Grid item> 
                        {ItemMapping(itemValue.tempDisplay, handleSwitchValue, handleClickOpen, dictionary.extender.tempDisplay.data, classes, filteredItems(itemValue.tempDisplay), 'tempDisplay')} 
                    </Grid> 
                </Grid> 
                <Grid item xs={6}> 
                    <Grid item> 
                        <Typography variant="h2">{dictionary.extender.mainDriver.title}</Typography> 
                    </Grid> 
                    <Grid item> 
                        {ItemMapping(itemValue.mainDriver, handleSwitchValue, handleClickOpen, dictionary.extender.mainDriver.data, classes, filteredItems(itemValue.mainDriver), 'mainDriver')} 
                    </Grid> 
                </Grid> 
                <Grid item xs={12}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.extender.tempMode.title}</Typography>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}> 
                        <Grid item xs={5}> 
                            <Typography variant="body2" style={{ color: itemValue.AOR_FB === 1 ? 'red' : 'black' }}>{dictionary.extender.tempMode.data.AOR_OFF_TRIGG}</Typography> 
                        </Grid> 
                        <Grid item xs={1} className={classes.textRight}> 
                            <Switch 
                                checked={itemValue.AOR_OFF_TRIGG === 0 ? false : true} 
                                onChange={(e) => handleSwitchValue(e.target, itemValue, '')} 
                                color={"primary"} 
                                name={'AOR_OFF_TRIGG'} 
                                inputProps={{ 'aria-label': 'primary checkbox' }} 
                            /> 
                        </Grid> 
                    </Grid> 
                    <Grid item>
                        <Grid container className={classes.containerVerticalCenter}> 
                            <Grid item xs={1}></Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Rejestr</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Odczyt Auto</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Przełącznik AOR</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter}  style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Nastawa Ręka</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Odczyt Wyjściowy</Typography> 
                            </Grid> 
                            <Grid item xs={1}>
                                {clickedId[1] ?
                                    <ExpandLessIcon alt="zwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(1)} /> 
                                    : <ExpandMoreIcon alt="rozwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(1)} /> 
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {clickedId[1] ? 
                        <Grid item>
                            {ExtenderMapping(itemValue.tempMode, handleSwitchValue, handleClickOpen, '', classes, filteredItems(itemValue.tempMode.map), 'tempMode', 'ext')}
                        </Grid>
                        : <Grid item>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.extender.digitalModeIn.title}</Typography> 
                    </Grid> 
                    <Grid item> 
                        <Grid container className={classes.containerVerticalCenter}> 
                            <Grid item xs={1}></Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Rejestr</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Odczyt Auto</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Przełącznik AOR</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter}  style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Nastawa Ręka</Typography> 
                            </Grid> 
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}> 
                                <Typography variant="body2">Odczyt Wyjściowy</Typography> 
                            </Grid> 
                            <Grid item xs={1}>
                                {clickedId[2] ?
                                    <ExpandLessIcon alt="zwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(2)} /> 
                                    : <ExpandMoreIcon alt="rozwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(2)} /> 
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {clickedId[2] ? 
                        <Grid item>
                            {ExtenderMapping(itemValue.digitalModeIn, handleSwitchValue, handleClickOpen, '', classes, filteredItems(itemValue.digitalModeIn.map), 'digitalModeIn')}
                        </Grid>
                        : <Grid item>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.extender.digitalModeOut.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container className={classes.containerVerticalCenter}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Rejestr</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Odczyt Auto</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Przełącznik AOR</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter}  style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Nastawa Ręka</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Odczyt Wyjściowy</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {clickedId[3] ?
                                    <ExpandLessIcon alt="zwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(3)} /> 
                                    : <ExpandMoreIcon alt="rozwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(3)} /> 
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {clickedId[3] ? 
                        <Grid item>
                            {ExtenderMapping(itemValue.digitalModeOut, handleSwitchValue, handleClickOpen, '', classes, filteredItems(itemValue.digitalModeOut.map), 'digitalModeOut')}
                        </Grid>
                        : <Grid item>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.extender.PWMOut.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container className={classes.containerVerticalCenter}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Rejestr</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Odczyt Auto</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Przełącznik AOR</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter}  style={{ borderTop: '2px solid', borderBottom: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Nastawa Ręka</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.containerVerticalCenter} style={{ border: '2px solid', height: '47px' }}>
                                <Typography variant="body2">Odczyt Wyjściowy</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {clickedId[4] ?
                                    <ExpandLessIcon alt="zwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(4)} /> 
                                    : <ExpandMoreIcon alt="rozwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(4)} /> 
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {clickedId[4] ? 
                        <Grid item>
                            {ExtenderMapping(itemValue.PWMOut, handleSwitchValue, handleClickOpen, '', classes, filteredItems(itemValue.PWMOut.map), 'PWMOut', 'ext')} 
                        </Grid>
                        : <Grid item>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                    {open.blok === 'tempMode' || open.blok === 'PWMOut' ?
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.textRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Grid container>
                                <Grid item xs={12}><Typography variant="h2">{open.title}</Typography></Grid>
                            </Grid>
                            <Grid container className="euros-monitor euros-monitor-carousel">
                                <Grid item xs={1}></Grid>
                                <Grid item xs={4}>
                                    <CarouselInputMulti init={open.element.toString().split('.')[0]} name={open.name} min={-50} max={150} setVal={setNewValue} num={1} step={1} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid item xs={1}><span style={{ fontSize: "86px", margin: "12px 22px 0", display: "block", textAlign: "center" }}>.</span></Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <CarouselInputMulti init={parseInt((open.element * 10)).toString().slice(-1)} name={open.name} min={0} max={9} setVal={setNewValue} num={2} />
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </DialogContent>
                        : <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h3">{open.title}</Typography>
                            <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step}/>
                        </DialogContent>
                    }
            </Dialog>
        </div>
    )
}

export default Extender
