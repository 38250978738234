import React, { useEffect } from 'react';
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'
import formatString from "format-string-by-pattern"

const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Wartość powinna wynosić nie wiecej niż ${max}`

  const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
  
    return formatString('-999', onlyNumbers)
  }


const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)


const PreparationFirstLaunchEON = ({ required, setValues, coagulationTemp }) => {

    useEffect(() => {
        setValues({type: "childName", value: "PreparationFirstLaunchEON"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Przygotowanie do pierwszego uruchomienia</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Demontaż dotychczasowego układu napełnia zładu</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="disassembly"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="disassembly"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="disassembly" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Pierwsze czyszczenie filtrów w obiegu grzewczym</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="cleanFilter"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="cleanFilter"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="cleanFilter" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Kontrola temperatury krzepnięcia w obiegu grzewczym</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="controlCoagulationTemp"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={() => {setValues({type: "coagulationTemp", value: true})}}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="controlCoagulationTemp"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                        onClick={() => {setValues({type: "coagulationTemp", value: false})}}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="controlCoagulationTemp" />
                </Grid>
                {coagulationTemp? (<Grid item xs={12} sm={6}>
                    <Field
                        name="coagulationTemp"
                        component={TextField}
                        type="text"
                        label="Temperatura krzepnięcia (&#x2103;)"
                        fullWidth
                        validate={composeValidators(required, maxValue(0))}
                        parse={e => formatOnlyNumbers(e)}
                    />
                </Grid>) : null}
                <Grid item xs={12}>
                    <Typography variant='h3'>Załączenie bezpiecznika jednostki zewnętrznej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="outdoorFuse"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="outdoorFuse"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="outdoorFuse" />
                </Grid>
            </Grid>
        </>
    )
}

export default PreparationFirstLaunchEON;