import React, {useState, useEffect} from 'react'
import { mainStyleClasses } from '../../theme'
import { AppBar } from '@material-ui/core'
import { LogicCWU, LogicHeating, LogicCoolingFC, LogicCoolingAC } from './components'
import { TabPanel, Tab, Tabs } from '../../components'

const Analysis = ({ settings }) => {
    const classes = mainStyleClasses()
    const [value, setValue] = useState(0);
    const deviceName = sessionStorage.getItem('deviceName')

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <AppBar position="static" style={{boxShadow: "none"}}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="CWU" />
                    <Tab label="Ogrzewanie" />
                    <Tab label="Chłodzenie AC" />
                    {deviceName === 'GEO_1' ? 
                        <Tab label="Chłodzenie FC" />
                    : null}  
                </Tabs> 
            </AppBar>
            <TabPanel value={value} index={0}>
                <LogicCWU settings={settings.cwu} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <LogicHeating settings={settings.heating} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <LogicCoolingAC settings={settings.coolingAC} />
            </TabPanel>
            {deviceName === 'GEO_1' ? 
                <TabPanel value={value} index={3}>
                    <LogicCoolingFC settings={settings.coolingFC} />
                </TabPanel>
            : null}
            {/*<Grid container>
                <Grid item xs={4}>
                    <Typography variant="body2">Analiza logiki sterowania:</Typography>
                    <Typography variant="body1">(Wybierz, żeby zobaczyć szczegóły)</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button variant="contained" color="primary" component={Link} to="/analysis/cwu">CWU</Button>
                    <Button variant="contained" color="primary" style={{ margin: "5px 20px" }} component={Link} to="/analysis/ogrzewanie">Ogrzewanie</Button>
                    <Button variant="contained" color="primary" style={{ margin: "5px 20px 5px 0" }} component={Link} to="/analysis/ac">Chłodzenie AC</Button>
                    <Button variant="contained" color="primary" component={Link} to="/analysis/fc">Chłodzenie FC</Button>
                </Grid>
            </Grid>

            <Switch>
                <Route path="/analysis/cwu"><LogicCWU settings={settings.cwu} /></Route>
                <Route path="/analysis/ogrzewanie"><LogicHeating settings={settings.heating} /></Route>
                <Route path="/analysis/fc"><LogicCoolingFC settings={settings.coolingFC} /></Route>
                <Route path="/analysis/ac"><LogicCoolingAC settings={settings.coolingAC} /></Route>
            </Switch>*/}
        </div>
    )
}

export default Analysis