import React from 'react'
import { Typography, Grid, TextField, TableRow, TableCell } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import DeleteIcon from '@material-ui/icons/Delete';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const HardwareList = ({ hardware, changeData, sendDeviceData, handleChange, i, fieldError, deleteHardwareVersion, editHardwareVers }) => {

    return(
            <TableRow>
                {changeData && changeData.change === i ? (
                    <React.Fragment>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <TextField  name="name" label="Nazwa" type="text" value={hardware ? hardware.name : ""}
                                    onChange={(e) => editHardwareVers(e, i)}
                                    error={fieldError && fieldError[`rowId${i}name`] && fieldError[`rowId${i}name`].status ? true : false} helperText={fieldError && fieldError[`rowId${i}name`] && fieldError[`rowId${i}name`].status ? fieldError[`rowId${i}name`].msg : ""}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="serial" label="Numer Seryjny" type="text" value={hardware ? hardware.serial : ""}
                                    onChange={(e) => editHardwareVers(e, i)}
                                    error={fieldError && fieldError[`rowId${i}serial`] && fieldError[`rowId${i}serial`].status ? true : false} helperText={fieldError && fieldError[`rowId${i}serial`] && fieldError[`rowId${i}serial`].status ? fieldError[`rowId${i}serial`].msg : ""}/>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => sendDeviceData("hardware_edit")} /><br />
                            </Grid>
                            <Grid item xs={6}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange(i)} />
                            </Grid>
                        </Grid>
                        {/*<Grid container direction="row">
                            <Grid item xs={8}>
                                <p style={{ color: "#d32f2f", lineHeight: "1" }}>Uwaga! Zmiana hasła agenta spowoduje,
                                że agent nie będzie mógł się połączyć dopóki hasło nie zostanie zmienione również w jego pliku konfiguracyjnym.</p>
                            </Grid>
                        </Grid>*/}
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <Grid container direction="row">
                                <TableCell>{hardware ? hardware.name : null}</TableCell> 
                                <TableCell>{hardware ? hardware.serial : null}</TableCell>
                                <Grid item xs={1}><FontAwesomeIcon onClick={() => handleChange(i)} style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" /></Grid>
                                <Grid item xs={1}><DeleteIcon onClick={() => deleteHardwareVersion(i)} style={{ cursor: "pointer" }}></DeleteIcon></Grid>
                                {/*<Grid item xs={4}><Typography variant="body1">Hasło agenta:</Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1">{deviceDetails ? deviceDetails.agent_pass : ""}</Typography></Grid>
                                <Grid item xs={2}><FontAwesomeIcon onClick={() => handleChange("agent_pass")} style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" /></Grid>*/}
                            </Grid>
                        </React.Fragment>
                    )}
            </TableRow>      
    )
}

export default HardwareList