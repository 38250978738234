import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { dataRange } from '../../data/dataRange'
import { SwitchControl } from '../index'

const ItemMapping = (itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, filteredItems, blok) => {
    return (
        filteredItems ? filteredItems.map((entry, index) => {
            if (entry[1] !== undefined) {
                return (
                    <Grid container className={classes.containerVerticalCenter} key={entry[0]}  style={{ paddingRight: '24px', backgroundColor: index % 2 === 1 && blok === 'errors_outdoor' ? '#F8F8F8' : null }}>
                        <Grid item xs={10}><Typography variant="body2">{dictionary[entry[0]]}</Typography></Grid>

                        {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                            SwitchControl(entry, itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, blok) 
                            : <Grid item xs={2} className={classes.textRight}>
                            {dataRange()[entry[0]].controller === 4 ?  
                                <FiberManualRecord style={{ color: entry[1] === 0 ? dataRange()[entry[0]].controllerColor0 : dataRange()[entry[0]].controllerColor1 }} /> 
                                : <Typography variant="body2">{entry[0] === 'SIO2_R07_DZ' ? entry[1] === 0 ? "Odwiert" : "Bufor chłodu" : dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]}{dataRange()[entry[0]].symbol}</Typography> 
                            }
                            </Grid>
                        }
                    </Grid>
                )
            } else {
                return null
            }
        }) : null
    )
}

export default ItemMapping
