import React, { useReducer, useEffect } from 'react'
import { ViewAcceptanceReport, CustomerContact, DeviceDetails, ServiceNotes } from './components'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import { Button, Grid, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import fetchAcceptanceReport from '../../data/fetchAcceptanceReport'

const moreInformationReducer = (state, action) => {
    switch (action.type) {
        case "load":
            return {
                ...state,
                [action.type]: action.payload
            }

        case "acceptanceReport":
            return {
                ...state,
                acceptanceReport: action.payload
            }

        case "loadAR":
            return {
                ...state,
                loadAR: action.payload
            }

        case "device":
            return {
                ...state,
                installation: action.payload
            }

        case "customer":
            return {
                ...state,
                customerId: action.payload
            }

        default:
            return { ...state }
    }
}

const DeviceService = ({ token, setBaseLogin, perm, user, accessScada }) => {
    const [moreInformation, setMoreInformation] = useReducer(moreInformationReducer, { load: false, acceptanceReport: null, loadAR: false, installation: null })
    const classes = mainStyleClasses()
    let location = useLocation()
    let { deviceSN, keyM } = useParams()

    const updateTelemetryColor = () => {

        if (moreInformation && moreInformation.installation !== null) {
            let dateNow = new Date()
            dateNow = Date.now()
            let dateUpdateTelemetry
            if (moreInformation.installation.last_telemetry_update) {
                dateUpdateTelemetry = new Date()
                dateUpdateTelemetry = Date.parse(moreInformation.installation.last_telemetry_update.slice(0, 19).replace("T", " "))
            } else {
                dateUpdateTelemetry = "nieznana"
            }

            if (((dateNow - dateUpdateTelemetry) / 1000 / 60) < 60) {
                return classes.backgroundGreen
            } else if (moreInformation.installation.last_telemetry_update === (null || undefined) || (parseInt(moreInformation.installation.last_telemetry_update.slice(0, 4)) < 2019)) {
                return classes.backgroundRed
            } else {
                return classes.backgroundYellow
            }
        }
    }
    const handleAcceptanceReport = () => {
        setMoreInformation({ type: "loadAR", payload: !moreInformation.loadAR })
    }
    useEffect(() => {
        let mounted = true
        if (location)
            setMoreInformation({ type: "device", payload: location.state.deviceState })
        return () => {
            mounted = false
        }
    }, [location])


    return (

        <React.Fragment>

            {!token ? <Redirect to="/" /> : null}
            <Grid container direction="row" >
                <Grid item xs={12} >
                    <Typography variant="h1">Instalacja: {deviceSN}</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" >
                <Grid item xs={6} >
                    {moreInformation && moreInformation.installation ?
                        <DeviceDetails colorTelemetryUpdate={updateTelemetryColor()}
                            perm={perm} details={moreInformation && moreInformation.installation ? moreInformation.installation : null} />
                        : null}
                        <Button style={{ marginTop: "30px" }} variant="contained" color="primary"
                        onClick={() => handleAcceptanceReport()}>Protokół pierwszego uruchomienia</Button>
                </Grid>
                <Grid item xs={6} >
                {/*updateTelemetryColor() && updateTelemetryColor().slice(21, -2) !== "Green" && updateTelemetryColor().slice(21, -2) === "Red" ?
                <p>Uwaga: instalacja nigdy nie była podłączona do internetu. Nie posiadamy żadnych danych z pracy urządzenia.</p>
                : (updateTelemetryColor() && updateTelemetryColor().slice(21, -2) !== "Green" && updateTelemetryColor().slice(21, -2) === "Yellow" ? <p>Uwaga: instalacja nie była widoczna online od dłuższego czasu. Dane dostępne w scadzie są ostatnimi
                    wysłanymi przez instalację. Mogą być nieaktualne. Sterowanie online prawdopodobnie jest niemożliwe. Proproś klienta
                    o podłączenie instalacji do internetu.</p> : null)*/}
                    {moreInformation && moreInformation.installation && perm && (perm.includes("admin") || (perm.includes("customer_r") && perm.includes("installation_r") && perm.includes("acceptance_report_r"))) ?
                        (<CustomerContact setMoreInformation={setMoreInformation}
                            address={moreInformation.installation && moreInformation.installation.installation ? moreInformation.installation.installation.customer : null}
                            token={token} installation_id={moreInformation.installation.installation_id} />)
                        : null}
                   
                </Grid>
            </Grid>
            <Grid container direction="row" >
                <Grid item xs={12}>
                <hr />
                    <ServiceNotes accessScada={accessScada} partner_id={moreInformation && moreInformation.installation ? moreInformation.installation.partner_id : null} user={user} perm={perm} token={token} keyM={keyM} deviceSerial={deviceSN} customerId={moreInformation && moreInformation.installation && moreInformation.installation.installation? moreInformation.installation.installation.customer.id : null}
                        deviceId={moreInformation.installation ? moreInformation.installation.id : null} />
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={moreInformation.loadAR} onClose={handleAcceptanceReport} aria-labelledby="form-dialog-title">
            <DialogContent>
                <IconButton aria-label="close" onClick={handleAcceptanceReport} className={classes.floatRight} style={{ zIndex: "99" }}>
                    <CloseIcon />
                </IconButton>
                {moreInformation.loadAR && moreInformation.acceptanceReport ?
                    <ViewAcceptanceReport deviceSerial={deviceSN} keyM={keyM} reportId={moreInformation.acceptanceReport.reportId} report={moreInformation.acceptanceReport.content} />
                    : <p>Protokół pierwszego uruchomienia nie został wypełniony.</p>}
            </DialogContent>
        </Dialog>
        </React.Fragment>
    )
}

export default DeviceService