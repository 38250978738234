import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { registerDictionary } from '../../../../data/dataDictionary'
import { FiberManualRecord } from '@material-ui/icons'

const LogicCoolingFC = ({ settings }) => {
    const dictionary = registerDictionary().analysis.coolingFC
    const deviceName = sessionStorage.getItem('deviceName')

    return (
        <React.Fragment>
           <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Analiza logiki sterowania chłodzeniem Free Cooling</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_OOF}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_OOF === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_OOF === 1 ? "Urządzenie załączone" : "Urządzenie wyłączone"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_FCX}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_FCX === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_FCX === 1 ? "Funkcja chłodzenia FC dostępna" : "Funkcja chłodzenia FC niedostępna"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_FCS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_FCS === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_FCS === 1 ? "Funkcja chłodzenia FC załączona" : "Funkcja chłodzenia FC wyłączona"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SXF_TOE}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SXF_TOE === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXF_TOE === 1 ? "Funkcja kontr. temp. wewn. zał." : "Funkcja kontr. temp. wewn. wył."}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QHW}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QHW === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QHW === 1 ? "Realizacja priorytetu cwu" : "Brak ralizacji priorytetu cwu"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QTIN}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QTIN === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QTIN === 1 ? "Temp. wewn. wystarczająca" : "Temp. wewn. za wysoka."}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QCOUT}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QCOUT === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QCOUT === 1 ? "Temp.zewn. wyższa od progu chłod." : "Temp. zewn. niższa od progu chłodz."}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SXB_QFC}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SXB_QFC === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXB_QFC === 1 ? "Zezwolenie z krzywej chłodzenia" : "Brak zezwolenia z krzywej chłodzenia"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_SMD}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_SMD === 1 ? 'red' : 'green', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_SMD === 1 ? "Przekroczenie" : "W normie"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_RMD}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_RMD === 1 ? 'red' : 'green', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_RMD === 1 ? "Przekroczenie" : "W normie"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LogicCoolingFC