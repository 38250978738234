import React, { useState } from 'react'
import {
    Redirect
} from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Button, Grid, Typography } from '@material-ui/core'
import fetchLogin from '../../data/fetchLogin'
import fetchAccess from '../../data/fetchAccess'
import { decodeToken } from "react-jwt"

const Login = ({ baseLogin, setBaseLogin, setAccessScada }) => {
    const [error, setError] = useState({ status: false, message: "" })

    const onSubmit = async values => {
        let timeStamp = new Date()
        timeStamp = Date.now()

        if (values && values.login && values.password) {
            fetchLogin(values.login, values.password)
                .then(response => {
                    let resPerm = decodeToken(response.access_token)
                    if (resPerm.role === "instalator") {
                        throw new Error("Panel nie jest dostępny dla instalatorów. Instalatorzy mogą zalogować się na https://cloud.eurosenergy.com:8803/")
                    } else {
                        sessionStorage.setItem('Expiration', timeStamp)
                        setBaseLogin({ type: "token", payload: response.access_token })
                        return response.access_token
                    }
                }).then(response => {
                    let resPerm = decodeToken(response)
                    setBaseLogin({ type: "perm", payload: resPerm.perms })
                    fetchAccess().then(res => { 
                        let access = JSON.stringify(res.data[0])
                        if(res.status === "ok"){
                            sessionStorage.setItem('ScadaAccess', JSON.stringify(res.data[0]))
                            setAccessScada(JSON.parse(access)) 
                        } else
                        setAccessScada(null) 
                    })
                }).catch(e => setError({ status: true, message: e.message }))
        }
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >{baseLogin && baseLogin.token ? (sessionStorage.getItem('Expiration') ? <Redirect to="/urzadzenia" /> : null) : null}
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h2" className="text-center">Logowanie</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="text" name="login" label="login" />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="password" name="password" label="hasło" />
                                </Grid>
                            </Grid>
                            {error.status ? <Grid container direction="row"><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="contained" color="primary" type="submit"
                                        disabled={submitting}>zaloguj</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </Grid>
        </React.Fragment>
    )
}

export default Login