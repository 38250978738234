import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { dataRange } from '../../../../data/dataRange'
import { registerDictionary } from '../../../../data/dataDictionary'
import { ItemMapping } from '../../../../components'

const deletionStatisticsState = (setPublish) => {
    setPublish({
        CORSTA_SPRMAX: 0,
        CORSTA_SPRMIN0: 0,
        CORSTA_SPSMAX: 0,
    })
}

const CompressorStartCount = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary().statistics.compressor
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState(false)
    const filteredItems = Object.entries(itemValue ? itemValue : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // const handleDeletionStatistics = () => {
    //     deletionStatisticsState(setPublish)
    //     setState({
    //         ...state,
    //         CORSTA_SPRMAX: 0,
    //         CORSTA_SPRMIN0: 0,
    //         CORSTA_SPSMAX: 0,
    //     })
    //     handleClose()
    // }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <Grid container className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}> 
            <Grid item xs={6}>
                <Grid item xs={12}>
                    {ItemMapping(settings, undefined, handleClickOpen, dictionary, classes, filteredItems)}
                </Grid>
                <Grid container className={classes.containerVerticalCenter}   style={{ paddingRight: '24px' }}>
                    <Grid item xs={10}>
                        <Typography variant="body2">-Razem:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2" className={classes.textRight}>{itemValue.CORSTA_SPRMAX + itemValue.CORSTA_SPRMIN + itemValue.CORSTA_SPSMAX}</Typography>
                    </Grid>
                </Grid>
                {/*<Grid container>   
                    <Grid item xs={9}>
                        <Typography variant="body2">-CWU:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{itemValue.CORSTA_SPRMAX}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">-CO:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{itemValue.CORSTA_SPRMIN}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">-AC:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{itemValue.CORSTA_SPSMAX}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">-Razem:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{itemValue.CORSTA_SPRMAX + itemValue.CORSTA_SPRMIN + itemValue.CORSTA_SPSMAX}</Typography>
                    </Grid>
                /Grid>*/}
            </Grid>
            {/*<Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleClickOpen()}>Kasuj historię</Button>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h2">Czy napewno chcesz wykasować historię załączeń sprężarki?</Typography>
                    <Typography variant="body2">Proces kasowania jest nieodwracalny.</Typography>
                    <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleDeletionStatistics()}>Tak, kasuję permanentnie historię załączeń sprężarki</Button>
                </DialogContent>
            </Dialog>*/}
        </Grid>
    )
}

export default CompressorStartCount