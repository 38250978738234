import React, { useEffect, useState, useReducer } from 'react';
import { functionPublish } from '../../config/functionPublish';
import { mainStyleClasses } from '../../theme';

import { 
  Button, Dialog, DialogContent, IconButton, Grid, Typography, List, ListItem, ListItemText, TextField, Checkbox
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { dataRange } from '../../pages/RegisterList/data/dataRange';
import { dataDescriptions } from '../../pages/RegisterList/data/dataDescriptions';

//---Tworzy litę rejestrów do wyświetlenia
const registerListReducer = (state, action) => {
    let data = !action.payload ? [] : action.payload;
    let registers = [];

    let index = 0

    data.map((device, ind) => (
        Object.keys(device.data).map((register, i) => {
            let reg = {}
            reg.id = index
            reg.registerName = register
            reg.value = dataRange()[register] ? `${device.data[register]}${dataRange()[register].symbol}` : device.data[register]
            reg.device = device.name
            reg.device_type = device.type
            reg.device_version = device.version

            index = index + 1

            return registers.push(reg)
        })
    ))

    return registers
}

const UploadFile = ({ client, installation, handleClose }) => {
    const [ uploadedFile, setUploadedFile ] = useState('');
    const [ openDevicesEditor, setOpenDevicesEditor ] = useState(false);
    const [ openDataEditor, setOpenDataEditor ] = useState(false);
    const [ openDataBlockIndex, setOpenDataBlockIndex ] = useState(0);
    const [ registers, setRegisters ] = useReducer(registerListReducer, []);
    const [ registersBackup, setRegistersBackup ] = useState([]);
    const [ pageSize, setPageSize ] = React.useState(50);
    const [ excludedDevices, setExcludedDevices ] = useState([]);
    const [ excludedRegisters, setExcludedRegisters ] = useState([]);
    const [ devicesCheckedStatus, setDevicesCheckedStatus ] = useState(null);
    const [ devicesCheckedStatusBackup, setDevicesCheckedStatusBackup ] = useState(null);
    const [ registersCheckedStatus, setRegistersCheckedStatus ] = useState(null);
    const [ registersCheckedStatusBackup, setRegistersCheckedStatusBackup ] = useState(null);
    const [ excludedRegistersBackup, setExcludedRegistersBackup ] = useState(null);
    const [ devicesNames, setDevicesNames ] = useState([]);
    const [ registersNames, setRegistersNames ] = useState([]);
    const classes = mainStyleClasses();

//---Definiuje kolumy tabeli
    const columns = [
        { field: 'registerName', headerName: 'Nazwa rejestru', width: 200, headerClassName: 'user-table-header' },
        {
            field: "description",
            headerName: "Opis",
            headerClassName: 'user-table-header',
            cellClassName: 'desc-styling',
            flex: 1,
            filterable: false,
            renderCell: params => <Typography variant='body1'>{!dataDescriptions() ? "" : dataDescriptions()[params.row.registerName] ? (dataDescriptions()[params.row.registerName] === "NA" ? "" : dataDescriptions()[params.row.registerName]) : ""}</Typography>
        },
        { field: 'value', headerName: 'Wartość z konfiguracji', width: 250, headerClassName: 'user-table-header', filterable: false },
        {
            field: "range",
            headerName: "Zakres",
            headerClassName: 'user-table-header',
            cellClassName: 'desc-styling',
            width: 130,
            filterable: false,
            renderCell: (params) => (
                dataRange()[params.row.registerName] && (dataRange()[params.row.registerName].min || dataRange()[params.row.registerName].max) ? (
                    <div>
                        <span className='data-grid-min'>{`Min: ${dataRange()[params.row.registerName].min ? dataRange()[params.row.registerName].min : (dataRange()[params.row.registerName].min === 0 ? "0" : "-")}`}</span>
                        <span>{`Max: ${dataRange()[params.row.registerName].max ? dataRange()[params.row.registerName].max : (dataRange()[params.row.registerName].max === 0 ? "0" : "-")}`}</span>
                    </div>
                ) : ""
            )
        },
        {/*
            field: "edit",
            headerName: "Edytuj wartość",
            sortable: false,
            headerClassName: 'user-table-header',
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return <TextField name={params.row.registerName} type="number" step={"any"}
                    onChange={(e) => onChangeInput(e, params.row.device, params.row.device_type, params.row.device_version)}
                    value={editRegs[params.row.registerName] ? editRegs[params.row.registerName] : ""} />;
            }
        */},
        {
            field: "select",
            headerName: "Zaznacz",
            headerClassName: 'user-table-header',
            cellClassName: 'desc-styling',
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Checkbox
                            checked={registersCheckedStatus ? Object.entries(registersCheckedStatus[openDataBlockIndex].Data)[params.row.id][1] : null}
                            color='primary'
                            onChange={() => handleRegistersSelect(params.row.registerName, params.row.id)}
                        />
                    </div>
                )
            }
            
        },
    ]

//---Załadowuje plik konfiguracyjny i ustawia dane do publikacji oraz ich kopie zapasowe
    const handleUpload = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        
        fileReader.onload = e => {
            let uploadedData = JSON.parse(e.target.result);
            let Dnames = [];
            let Rnames = [];

            
            for (let i = 0; i < JSON.parse(e.target.result).length; i++) {
                Dnames = [...Dnames, JSON.parse(e.target.result)[i].Name]
                Rnames[i] = Object.keys(JSON.parse(e.target.result)[i].Data)
            };
            setRegistersNames(Rnames)
            setUploadedFile(JSON.parse(e.target.result));
            setDevicesNames(Dnames);
            
            let checkedDevices = {};
            JSON.parse(e.target.result).forEach(item => checkedDevices[item.Name] = true);
            setDevicesCheckedStatus(checkedDevices);
            setDevicesCheckedStatusBackup(checkedDevices);

            let checkedRegisters = uploadedData.map(item => ({...item, Data: {...item.Data}}));
            for (let i = 0; i < uploadedData.length; i++) {
                Object.keys(checkedRegisters[i].Data).forEach(key => {
                    checkedRegisters[i].Data[key] = true
                });
            };
            setRegistersCheckedStatus(checkedRegisters);
            setRegistersCheckedStatusBackup(checkedRegisters);

            let exregs = [];
            for (let i = 0; i < uploadedData.length; i++) {
               exregs.push([uploadedData[i].Name, []]);
            };
            setExcludedRegisters(exregs)
            setExcludedRegistersBackup(exregs)
        };
    };

//---Funkcje obsługujące edycję listy sterowników
    const handleOpenDevicesEditor = () => {
        setOpenDevicesEditor(true);
    };

    const handleCloseDevicesEditor = () => {
        setOpenDevicesEditor(false);
    };
    
    const handleDevicesSelect = (name) => {
        let dataUpdate = excludedDevices;
        if (dataUpdate.find(item => item === name)) {
            dataUpdate = dataUpdate.filter(item => item !== name) 
        } else {
            dataUpdate.push(devicesNames.find(item => item === name));
        }
        setExcludedDevices(dataUpdate);

        let checkedUpdate = {...devicesCheckedStatus};
        if (checkedUpdate[name] === true) {
            checkedUpdate[name] = false;
        } else {
            checkedUpdate[name] = true;
        }
        setDevicesCheckedStatus(checkedUpdate);
    };

    const handleResetDevicesEdit = () => {
        setDevicesCheckedStatus(devicesCheckedStatusBackup);
        setExcludedDevices([]);
        setRegisters(registersBackup);
    };

//---Funkcje obsługujące edycję rejestrów danego sterownika
    const handleOpenDataEditor = (id) => {
        setOpenDataEditor(true);
        setOpenDataBlockIndex(id);
    };

    const handleCloseDataEditor = () => {
        setOpenDataEditor(false);
    };

    const handleRegistersSelect = (name) => {
        let registersUpdate = JSON.parse(JSON.stringify(excludedRegisters));
        if (registersUpdate[openDataBlockIndex][1].find(item => item === name)) {
            registersUpdate[openDataBlockIndex][1] = registersUpdate[openDataBlockIndex][1].filter(item => item !== name) 
        } else {
            registersUpdate[openDataBlockIndex][1].push(registersNames[openDataBlockIndex].find(item => item === name));
        }
        setExcludedRegisters(registersUpdate);

        let checkedUpdate = registersCheckedStatus.map(item => ({...item, Data: {...item.Data}}));
        if (checkedUpdate[openDataBlockIndex].Data[name] === true) {
            checkedUpdate[openDataBlockIndex].Data[name] = false;
        } else {
            checkedUpdate[openDataBlockIndex].Data[name] = true;
        }
        setRegistersCheckedStatus(checkedUpdate);
    };

    const handleDataReset = () => {
        setRegistersCheckedStatus(registersCheckedStatusBackup);
        setExcludedRegisters(excludedRegistersBackup);
        setRegisters(registersBackup);
    };

    const handlePublish = () => {
        let filteredDevices = [...uploadedFile]
        filteredDevices = filteredDevices.filter(item => !excludedDevices.includes(item.Name));
        const devicesStrings = [];
        let devices = []
        let registers = [];
        
        filteredDevices.map(device => (
            devicesStrings.push(device.Name, device.Type, device.Version),
            delete device.Name,
            delete device.Type,
            delete device.Version,
            delete device.DataFormat
          )
        );

        for (let i = 0; i < devicesStrings.length; i += 3) {
            devices.push(devicesStrings.slice(i, i + 3))
        };

        devices = devices.map(device => ({
            Name: device[0],
            Type: device[1],
            Version: device[2]
        }));


        for (let i = 0; i < uploadedFile.length; i++) {
            registers[i] = Object.entries(uploadedFile[i].Data).filter(item => !excludedRegisters[i][1].includes(item[0]));
            registers[i] = Object.fromEntries(registers[i]);
            registers[i] = [devicesNames[i], {...registers[i]}];
        };

        registers = registers.filter(item => !excludedDevices.includes(item[0]));
        registers = registers.map(item => item[1]);
      
        functionPublish(client, registers, installation, devices);
        handleClose();
    };

//---Aktualizuje istę rejestróe danego steownika po zmianie 
    useEffect(() => {
        if (!uploadedFile) {
            return
        } else {
            let extdata = [{ 
                name: uploadedFile[openDataBlockIndex].Name,
                data: uploadedFile[openDataBlockIndex].Data,
                type: uploadedFile[openDataBlockIndex].Type,
                version: uploadedFile[openDataBlockIndex].Version 
            }];

            setRegisters({ payload: extdata });
            setRegistersBackup({ payload: extdata });
        }
    }, [openDataEditor]);

   return(
    <div>
        <Typography variant="h6" className={classes.title}>
            Wybierz plik konfiguracyjny
        </Typography>
        <input type='file' accept='.json' onChange={handleUpload} style={{ width: 370, marginRight: 5 }} />
        <Button style={{ margin: 5 }}
            variant='contained' 
            color='primary' 
            onClick={handlePublish}
            disabled={!uploadedFile || uploadedFile.length === excludedDevices.length ? true : false}
        >
            Załaduj
        </Button>
        <Button style={{ margin: 5 }}
            variant='contained' 
            color='primary' 
            onClick={handleOpenDevicesEditor}
            disabled={!uploadedFile ? true : false}
        >
            Edytuj
        </Button>
        <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={openDevicesEditor} onClose={handleClose} aria-labelledby="file-upload">
            <DialogContent style={{ padding: "10px 25px 25px" }}>
                <Grid container direction="row">
                    <Grid item xs={10} md={10}>
                        <Typography variant="h6" className={classes.title}>
                            Sterowniki załadowanej konfiguracji
                        </Typography>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <IconButton aria-label="reset" onClick={handleResetDevicesEdit} className={classes.floatRight}>
                            <AutorenewIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <IconButton aria-label="close" onClick={handleCloseDevicesEditor} className={classes.floatRight}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <div>
                            <List>
                                {uploadedFile.length === 0 ?
                                    null :
                                    uploadedFile.map(item => {
                                    const id = uploadedFile.indexOf(item); 
                                        return (
                                            <ListItem key={item.Name}>
                                                <ListItemText primary={item.Name} />
                                                <Button 
                                                    variant='contained' 
                                                    color='primary' 
                                                    onClick={() => handleOpenDataEditor(id)}
                                                    >
                                                    Edytuj Rejestry
                                                </Button>
                                                <div>
                                                    <Checkbox 
                                                        checked={devicesCheckedStatus ? Object.entries(devicesCheckedStatus)[id][1] : null}
                                                        color='primary'
                                                        onChange={() => handleDevicesSelect(item.Name)}
                                                    />
                                                </div>
                                          </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained' 
                            color='primary' 
                            onClick={handlePublish}
                            disabled={!uploadedFile || uploadedFile.length === excludedDevices.length ? true : false}
                            >
                            Załaduj
                        </Button>
                    </Grid>
                    <Grid item xs={5}></Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        <Dialog scroll={"body"} fullWidth={true} maxWidth={"xl"} open={openDataEditor} onClose={handleClose} aria-labelledby="file-upload">
            <DialogContent style={{ padding: "10px 25px 25px" }}>
                <IconButton aria-label="close" onClick={handleCloseDataEditor} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                    <CloseIcon />
                </IconButton>
                <IconButton aria-label="reset" onClick={handleDataReset} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "80px" }}>
                    <AutorenewIcon />
                </IconButton>
                <Grid container direction="row" >
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" className={classes.title}>
                            {`Rejestry sterownika ${!uploadedFile ? '' : uploadedFile[openDataBlockIndex].Name}`}
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: 30 }}>
                                <DataGrid
                                    columns={columns}
                                    rows={registers}
                                    autoHeight
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[10, 30, 50, 100]}
                                    pagination
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </div>
   );
};

export default UploadFile;
