import React from 'react'
import { Grid, Typography, Switch } from '@material-ui/core'
import { dataRange } from '../../data/dataRange'

const SwitchControl = (entry, itemValue, handleSwitchValue, handleClickOpen, dictionary, classes, blok, ext) => {

    switch (dataRange()[entry[0]].controller) {
        case 1:
            return (
                <Grid item xs={2} className={classes.textRight}>
                    <Switch
                        checked={itemValue[entry[0]] === 0 ? false : true}
                        onChange={(e) => handleSwitchValue(e.target, itemValue, blok)}
                        color="primary"
                        name={entry[0]}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            )
        case 2:
            let minValue = typeof(dataRange()[entry[0]].min) === "string" ? parseFloat(itemValue[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
            let maxValue = typeof(dataRange()[entry[0]].max) === "string" ? parseFloat(itemValue[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)
            return (
                <Grid item xs={2} className={ext === 'ext' ? classes.textCenter : classes.textRight} onClick={() => handleClickOpen(entry[1], entry[0], dictionary[entry[0]], minValue, maxValue, dataRange()[entry[0]].step ? dataRange()[entry[0]].step : 1, entry[0], entry[1], blok)}> 
                    <Typography variant="body2" className={ext === 'ext' ? 'ext-carousel-input-click' : 'carousel-input-click'}>{[entry[1]] > 0 ? dataRange()[entry[0]].sign : null}{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]} {dataRange()[entry[0]].symbol}</Typography> 
                </Grid>
            )
        case 3:
            return (
                <Grid item xs={10} style={{ margin: "20px auto 0" }}>
                    select
                </Grid>
            )
        default:
            break;
    }
}

export default SwitchControl
