import React, { useState, useEffect } from 'react'
import { Grid, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { mainStyleClasses } from '../../../../../../theme'
import X from '../../../../../../img/x.svg'
import { dataRange } from '../../../../../../data/dataRange'

import { CarouselInputMulti } from '../../../../../../components'

const TempItem = ({ temp, stamp, num, setPublish }) => {
    const theme = useTheme()
    const classes = mainStyleClasses()
    const [state, setState] = useState(temp)
    const [open, setOpen] = useState({ status: false, init: 0, name: '', min: 0, max: 0, num: '', val: 0 })

    const handleClickOpen = (init, name, min, max, num, val) => {
        setOpen({ status: true, init: init, name: name, min: min, max: max, num: num, val: val })
    }

    const handleClose = () => {
        setState(open.val)
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({ ...open, val: val })
    }

    useEffect(() => {
        let mounted = true
        setState(temp)
        return () => {
            mounted = false
        }
    }, [temp])

    return (
        <React.Fragment>
            <Grid item xs={4} onClick={() => handleClickOpen(temp, `CW${stamp}${num}T`, dataRange()[`CW${stamp}${num}T`].min, dataRange()[`CW${stamp}${num}T`].max, `CW${stamp}${num}T`, state)}>
                <Typography variant='h4'>{state}<span className='deg'>&deg;C</span>
                    <br /><span className='line' style={{ background: `${theme.tempcolors[`color${temp <= 35 ? 35 : temp >= 55? 55 : temp}`]}` }}></span>
                    <br /><span className='interwal'>Interwał {num}</span></Typography>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h5">Ustaw temperaturę interwału {num}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

const TimeCWUTemp = ({ settings, stamp, setPublish }) => {

    return (
        <React.Fragment>
            <Grid container style={{ borderTop: '2px solid #b3b3b3', position: 'relative', marginTop: "50px" }}>
                {Object.entries(settings).map((entry, index) => <TempItem key={`${stamp}_${index}`} setPublish={setPublish} num={index + 1} stamp={stamp} temp={entry[1]} />)}
            </Grid>
        </React.Fragment>
    )
}

export default TimeCWUTemp