import React, { useState } from 'react'
import { Grid, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import plansza from '../../../../../../img/planszka_v3.png'
import googlePlay from '../../../../../../img/google-play.svg'
import appStore from '../../../../../../img/app-store.svg'
import X from '../../../../../../img/x.svg'
import { mainStyleClasses } from '../../../../../../theme'

const MobileEuros = () => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ status: false, name: "", title: "" })

    const handleClickOpen = (name, title) => {
        setOpen({ status: true, name: name, title: title })
    }


    const handleClose = () => {
        setOpen({ ...open, status: false })
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <img alt="Euros Energy Mobile" src={plansza} style={{ maxWidth: "100%", marginTop: "80px", borderRadius: "10px" }} />
                </Grid>
                <Grid container>
                    <Grid item xs={3} onClick={() => handleClickOpen("appstore", "App Store")} style={{ height: "50px", position: "absolute", top: "360px", left: "0px", width: "100%" }}>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3} onClick={() => handleClickOpen("googleplay", "Google Play")} style={{ height: "50px", position: "absolute", top: "415px", left: "0px", width: "100%" }}>

                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            {open.name === "appstore" ? <img alt="Euros Energy Mobile" src={appStore} style={{ maxWidth: "100%", margin: "30px auto 30px" }} /> : null}
                            {open.name === "googleplay" ? <img alt="Euros Energy Mobile" src={googlePlay} style={{ maxWidth: "100%", margin: "30px auto 30px" }} /> : null}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    )
}

export default MobileEuros