import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { mainStyleClasses } from '../../../../../theme'
import { ItemMapping } from '../../../../../components'
import { registerDictionary } from '../../../../../data/dataDictionary'
import { dataRange } from '../../../../../data/dataRange'

const HeatingCircuitsAdditional = ({ settings, num, handleSwitchValue, handleClickOpen }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const dictionary = registerDictionary().periphery.heatingCircuits

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <Typography variant="h2">Obieg dodatkowy[{`${num}`}] (z mieszaczem)</Typography>
                <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                    <Grid item xs={10}>
                        <Typography variant="body2">{itemValue.additional[`map${num}`][`OB${num}_XZM_TRANSM`] === 0 ? "Aktywny [MB=5]" : "Brak"}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.textRight}>
                        <FiberManualRecord style={{ color: itemValue.additional[`map${num}`][`OB${num}_XZM_TRANSM`] === 0 ? 'green' : 'gray' }} /> 
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item>
                        {ItemMapping(itemValue.additional[`map${num}`], handleSwitchValue, handleClickOpen, dictionary.additional, classes, filteredItems(itemValue.additional[`map${num}`]))}
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                        <Grid item xs={10}>
                            
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>
                            <FiberManualRecord style={{ color: itemValue.additional[`OB${num}_PCO`] === 100 ? 'green' : 'gray' }} /> 
                        </Grid>
                    </Grid>
                </Grid>   
            </Grid>
        </React.Fragment>
    )
}

export default HeatingCircuitsAdditional