import React, { useState, useEffect, useReducer } from 'react'
import { Grid, Tabs, Tab, Box, Typography, Switch } from '@material-ui/core'
import cwuIcon from '../../../../img/Temp-CWU2.svg'
import domIcon from '../../../../img/Temp-dom2.svg'
import cwuIcon2 from '../../../../img/Temp-CWU.svg'
import domIcon2 from '../../../../img/Temp-dom.svg'
import { mainStyleClasses } from '../../../../theme'
import { TimeZone } from './components'

const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const DayTab = ({ children, value, index, handleChangeSwitchDay, day, stamp, daySwitchT, daySwitchC, timeZone, handleChangeTime, setPublish, ...props }) => {
    const classes = mainStyleClasses()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...props}>
            {value === index && (<React.Fragment>
                <Grid container className={`gray-bg ${classes.textCenter}`}>
                    <Grid item md={2} xs={2} className={`${classes.textLeft}`}>
                        {props.daySwitchT === 0 ?
                            <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 1, stamp: day, blok: "T" })} className="mode-icon" src={domIcon} />
                            : <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 0, stamp: day, blok: "T" })} className="mode-icon" src={domIcon2} />}

                        <p className={`${classes.floatRight} d-none d-md-block`}>Nr</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Start</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Stop</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Temperatura</p>
                    </Grid>
                </Grid>
                <TimeZone key={`T1${day}`} setPublish={setPublish} daySwitch={daySwitchT} handleChangeTime={handleChangeTime} timeZone={timeZone.T} day={day} num={1} stamp={stamp} blok={'T'} />
                <TimeZone key={`T2${day}`} setPublish={setPublish} daySwitch={daySwitchT} handleChangeTime={handleChangeTime} timeZone={timeZone.T} day={day} num={2} stamp={stamp} blok={'T'} />
                <TimeZone key={`T3${day}`} setPublish={setPublish} daySwitch={daySwitchT} handleChangeTime={handleChangeTime} timeZone={timeZone.T} day={day} num={3} stamp={stamp} blok={'T'} />
                <Grid container className={`gray-bg ${classes.textCenter}`}>
                    <Grid item md={2} xs={2} className={`${classes.textLeft}`}>
                        {props.daySwitchC === 0 ?
                            <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 1, stamp: day, blok: "C" })} className="mode-icon" src={cwuIcon} />
                            : <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 0, stamp: day, blok: "C" })} className="mode-icon" src={cwuIcon2} />}

                        <p className={`${classes.floatRight} d-none d-md-block`}>Nr</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Start</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Stop</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Temperatura</p>
                    </Grid>
                </Grid>
                <TimeZone key={`C1${day}`} setPublish={setPublish} daySwitch={daySwitchC} handleChangeTime={handleChangeTime} timeZone={timeZone.C} day={day} num={1} stamp={stamp} blok={'C'} />
                <TimeZone key={`C2${day}`} setPublish={setPublish} daySwitch={daySwitchC} handleChangeTime={handleChangeTime} timeZone={timeZone.C} day={day} num={2} stamp={stamp} blok={'C'} />
                <TimeZone key={`C3${day}`} setPublish={setPublish} daySwitch={daySwitchC} handleChangeTime={handleChangeTime} timeZone={timeZone.C} day={day} num={3} stamp={stamp} blok={'C'} />
            </React.Fragment>)}
        </div>
    )
}

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else if (action.case === "DAY_SWITCH") {
        return {
            ...state,
            [action.blok]: {
                ...state[action.blok],
                [action.stamp]: {
                    ...state[action.blok][action.stamp],
                    [`${action.blok}WTSW_${action.stamp}`]: action.value
                }
            }
        }
    } else {
        return {
            ...state,
            [action.blok]: {
                ...state[action.blok],
                [action.stamp]: {
                    ...state[action.blok][action.stamp],
                    [`${action.blok}W${action.num}`]: {
                        ...state[action.blok][action.stamp][`${action.blok}W${action.num}`],
                        [action.type]: action.value
                    }
                }
            }
        }
    }
}

const TimeMode = ({ time, setPublish }) => {
    const classes = mainStyleClasses()
    const [controller, setTimeZoneState] = useReducer(switchOption, time)
    const [value, setValue] = useState(0);
    const [state, setState] = useState(null)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", type: "", min: null, max: null })
    const handleClickOpen = (element, name, title, type, min, max) => {

        setOpen({ status: true, element: element, name: name, title: title, type: type, min: min, max: max })
    }

    const handleClose = () => {
        setOpen({ status: false, element: 0, name: "", title: "", type: "", min: null, max: null })
    }

    useEffect(() => {
        setTimeZoneState({ payload: time, case: "UPDATE_FROM_BROKER" })
    }, [time])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeTime = (e) => {
        setTimeZoneState({ type: e.type, stamp: e.stamp, num: e.num, blok: e.blok, value: parseInt(e.value) })

        // if (e.type === "Temp") {
        //     handleSendTimeZoneState({ id: `${e.blok}W${e.more}${e.num}T`, value: parseInt(e.value) }, setState)
        // } else {
        //     handleSendTimeZoneState({ id: `${e.blok}W${e.more}${e.num}P_${e.type}`, value: parseInt(e.value) }, setState)
        // }
    }

    const handleChangeSwitchDay = (e) => {
        setTimeZoneState({ case: e.case, stamp: e.stamp, blok: e.blok, value: parseInt(e.value) })

        handleSendTimeZoneState({ id: `${e.blok}WTSW_${e.stamp}`, value: parseInt(e.value) }, setState)
    }

    const handleSendTimeZoneState = (e, setState) => {
        const sendKey = e.id;
        const sendData = e.value;

        setPublish({ [sendKey]: sendData })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">Tryb TIME</Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                    >
                        <Tab label="PN" {...a11yProps(0)} style={{ minWidth:"14.28%" }} />
                        <Tab label="WT" {...a11yProps(1)} style={{ minWidth:"14.28%" }} />
                        <Tab label="ŚR" {...a11yProps(2)} style={{ minWidth:"14.28%" }} />
                        <Tab label="CZ" {...a11yProps(3)} style={{ minWidth:"14.28%" }} />
                        <Tab label="PT" {...a11yProps(4)} style={{ minWidth:"14.28%" }} />
                        <Tab label="SB" {...a11yProps(5)} style={{ minWidth:"14.28%" }} />
                        <Tab label="ND" {...a11yProps(6)} style={{ minWidth:"14.28%" }} />
                    </Tabs>
                </Grid>
            </Grid>
            <DayTab value={value} index={0} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.PN.TWTSW_PN} daySwitchC={controller.C.PN.CWTSW_PN} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.PN, C: controller.C.PN }} day={'PN'} stamp={'P'} />
            <DayTab value={value} index={1} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.WT.TWTSW_WT} daySwitchC={controller.C.WT.CWTSW_WT} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.WT, C: controller.C.WT }} day={'WT'} stamp={'W'} />
            <DayTab value={value} index={2} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.SR.TWTSW_SR} daySwitchC={controller.C.SR.CWTSW_SR} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.SR, C: controller.C.SR }} day={'SR'} stamp={'S'} />
            <DayTab value={value} index={3} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.CZ.TWTSW_CZ} daySwitchC={controller.C.CZ.CWTSW_CZ} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.CZ, C: controller.C.CZ }} day={'CZ'} stamp={'C'} />
            <DayTab value={value} index={4} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.PT.TWTSW_PT} daySwitchC={controller.C.PT.CWTSW_PT} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.PT, C: controller.C.PT }} day={'PT'} stamp={'PT'} />
            <DayTab value={value} index={5} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.SO.TWTSW_SO} daySwitchC={controller.C.SO.CWTSW_SO} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.SO, C: controller.C.SO }} day={'SO'} stamp={'SO'} />
            <DayTab value={value} index={6} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.ND.TWTSW_ND} daySwitchC={controller.C.ND.CWTSW_ND} handleChangeTime={handleChangeTime} timeZone={{ T: controller.T.ND, C: controller.C.ND }} day={'ND'} stamp={'N'} />

        </React.Fragment>
    )
}

export default TimeMode