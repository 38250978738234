import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary';
import { dataRangeDictionary } from '../../../../dataFilter/dataRangeDictionary';

const TemperatureControllDynamicMax = ({ itc, stamp, min, max, handleTemperatureControl, handleSetTemperatureControl }) => {

    return (
        <>
            <Form key={stamp}>
                <Form.Group as={Row} controlId={stamp}>
                    <Col className="text-left" xs={12} md={5}>
                        <Form.Label>{descriptionDictionary.itc[stamp]}</Form.Label>
                    </Col>
                    <Col className="text-right" xs={10} md={6}>
                        <Form.Control type="range" name={stamp} custom value={itc[stamp]}
                            onChange={(e) => handleSetTemperatureControl(e)}
                            min={min} max={max + dataRangeDictionary.itc[stamp].changeMax} step={dataRangeDictionary.itc[stamp].step? dataRangeDictionary.itc[stamp].step:1}
                            onMouseUp={(e) => handleTemperatureControl(e)} 
                            onTouchEnd={(e) => handleTemperatureControl(e)}/>
                    </Col>
                    <Col xs={2} md={1} className="text-right">{itc[stamp]}</Col>
                </Form.Group>
            </Form>
        </>
    )
}

export default TemperatureControllDynamicMax;