import React, { useReducer, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'

import { OptionList, TitleRow } from '../../components'
import { SwitchControl, TimeZone } from './components'

const PumpTimeControl = ({ ptc, setPublish, noTitle }) => {

    const switchOption = (state, action) => {
        if (action.case === "UPDATE_FROM_BROKER") {
            return (action.payload)
        }
        switch (action.type) {
            case 'CYR_FILL':
                return { ...state, CYR_FILL: action.payload };
            case 'CYRSW_S3':
                let vCYRSW_S3;
                if (state.CYRSW_S3 === 0) {
                    vCYRSW_S3 = 1
                } else if (state.CYRSW_S3 === 1) {
                    vCYRSW_S3 = 0
                }
                return { ...state, CYRSW_S3: vCYRSW_S3 };
            case 'CYRSW_S2':
                let vCYRSW_S2;
                if (state.CYRSW_S2 === 0) {
                    vCYRSW_S2 = 1
                } else if (state.CYRSW_S2 === 1) {
                    vCYRSW_S2 = 0
                }
                return { ...state, CYRSW_S2: vCYRSW_S2 };
            case 'CYRSW_S1':
                let vCYRSW_S1;
                if (state.CYRSW_S1 === 0) {
                    vCYRSW_S1 = 1
                } else if (state.CYRSW_S1 === 1) {
                    vCYRSW_S1 = 0
                }
                return { ...state, CYRSW_S1: vCYRSW_S1 };

            case 'CYRSW_PN':
                let vCYRSW_PN;
                if (state.CYRSW_PN === 0) {
                    vCYRSW_PN = 1
                } else if (state.CYRSW_PN === 1) {
                    vCYRSW_PN = 0
                }
                return { ...state, CYRSW_PN: vCYRSW_PN };
            case 'SWITCH':
                return {
                    ...state,
                    [action.stamp]: {
                        ...state[action.stamp],
                        SWITCH: action.value
                    }
                };
            default:
                return { state };
        }
    }

    const [timeControl, setTimeControl] = useReducer(switchOption, ptc)

    useEffect(() => {
        setTimeControl({ payload: ptc, case: "UPDATE_FROM_BROKER" })
    }, [ptc])

    let response;
    const handleTimeControl = (e) => {
        const sendKey = e.target.name
        const sendData = e.target.value

        setPublish({ [sendKey]: sendData })
    }

    const handleSwitching = (e) => {
        const sendKey = e.target.id;
        let sendData;
        if (e.target.checked) {
            sendData = 1;
        } else {
            sendData = 0;
        }

        setPublish({ [sendKey]: sendData })
    }

    return (
        <Container>
            {noTitle ? null : <TitleRow title="Sterowanie czasowe pompą" />}
            <Row>
                <Col xs={12} md={6}>
                    <Form>
                        <Form.Group as={Row}>
                            <Col xs={8} className="text-left">
                                <p>Czas cyklicznej pracy pompy cyrkulacyjnej</p>
                            </Col>
                            <Col xs={4}>
                                <Form.Control name="CYR_FILL" as="select" value={timeControl.CYR_FILL} custom
                                    onChange={(e) => { setTimeControl({ type: "CYR_FILL", payload: parseInt(e.target.value) }); handleTimeControl(e) }}>
                                    <OptionList nameKey={"CYR_FILL"} start={2} stop={60} />
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} md={6} className="service-control">
                    <Form className="flex-right">
                        <Form.Check
                            type="switch"
                            id="CYRSW_S3"
                            label="Załączenie sterowania czasowego CWU dla strefy 3"
                            checked={timeControl.CYRSW_S3 === 1 ? true : false}
                            onChange={(e) => { setTimeControl({ type: "CYRSW_S3" }); handleSwitching(e) }}
                        />
                    </Form>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="CYRSW_S2"
                            label="Załączenie sterowania czasowego CWU dla strefy 2"
                            checked={timeControl.CYRSW_S2 === 1 ? true : false}
                            onChange={(e) => { setTimeControl({ type: "CYRSW_S2" }); handleSwitching(e) }}
                        />
                    </Form>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="CYRSW_S1"
                            label="Załączenie sterowania czasowego CWU dla strefy 1"
                            checked={timeControl.CYRSW_S1 === 1 ? true : false}
                            onChange={(e) => { setTimeControl({ type: "CYRSW_S1" }); handleSwitching(e) }}
                        />
                    </Form>
                </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="pn">
                <Row>
                    <Col sm={12} className="np">
                        <Nav variant="pills" className="flex" fill>
                            <Nav.Item>
                                <Nav.Link eventKey="pn">PN</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="wt">WT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sr">ŚR</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="cz">CZ</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pt">PT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="so">SB</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="nd">ND</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={12} className="np">
                        <Tab.Content>
                            <Tab.Pane transition={false} eventKey="pn">
                                <Row className="gray-bg text-center">
                                    <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.PN} stamp={"PN"} day={"poniedziałku"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>
                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.PN} stamp={"P"} day={"poniedziałku"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.PN} stamp={"P"} day={"poniedziałku"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.PN} stamp={"P"} day={"poniedziałku"} />
                            </Tab.Pane>
                            <Tab.Pane transition={false} eventKey="wt">
                                <Row className="gray-bg text-center">
                                <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.WT} stamp={"WT"} day={"wtorku"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>

                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.WT} stamp={"W"} day={"wtorku"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.WT} stamp={"W"} day={"wtorku"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.WT} stamp={"W"} day={"wtorku"} />
                            </Tab.Pane>
                            <Tab.Pane transition={false} eventKey="sr">
                                <Row className="gray-bg text-center">
                                <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.SR} stamp={"SR"} day={"środy"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>
                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.SR} stamp={"S"} day={"środy"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.SR} stamp={"S"} day={"środy"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.SR} stamp={"S"} day={"środy"} />
                            </Tab.Pane>
                            <Tab.Pane transition={false} eventKey="cz">
                                <Row className="gray-bg text-center">
                                <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.CZ} stamp={"CZ"} day={"czwartku"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>
                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.CZ} stamp={"C"} day={"czwartku"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.CZ} stamp={"C"} day={"czwartku"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.CZ} stamp={"C"} day={"czwartku"} />
                            </Tab.Pane>
                            <Tab.Pane transition={false} eventKey="pt">
                                <Row className="gray-bg text-center">
                                <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.PT} stamp={"PT"} day={"piątku"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>
                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.PT} stamp={"PT"} day={"piątku"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.PT} stamp={"PT"} day={"piątku"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.PT} stamp={"PT"} day={"piątku"} />
                            </Tab.Pane>
                            <Tab.Pane transition={false} eventKey="so">
                                <Row className="gray-bg text-center">
                                <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.SO} stamp={"SO"} day={"soboty"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>
                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.SO} stamp={"SO"} day={"soboty"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.SO} stamp={"SO"} day={"soboty"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.SO} stamp={"SO"} day={"soboty"} />
                            </Tab.Pane>
                            <Tab.Pane transition={false} eventKey="nd">
                                <Row className="gray-bg text-center">
                                <Col md={2} xs={2} className="text-left np">
                                        <SwitchControl setPumpTimeControl={setTimeControl} setPublish={setPublish} ptc={timeControl.ND} stamp={"ND"} day={"niedzieli"} />
                                        <p className="float-right d-none d-md-block">Nr</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Start</p>
                                    </Col>
                                    <Col  md={2} xs={4}>
                                        <p className="text-center">Stop</p>
                                    </Col>
                                </Row>
                                <TimeZone number={1} setPublish={setPublish} ptc={timeControl.ND} stamp={"N"} day={"niedzieli"} />
                                <TimeZone number={2} setPublish={setPublish} ptc={timeControl.ND} stamp={"N"} day={"niedzieli"} />
                                <TimeZone number={3} setPublish={setPublish} ptc={timeControl.ND} stamp={"N"} day={"niedzieli"} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            {/*state ? (<ToastInfo response={state} close={() => setState(null)} />) : null*/}
        </Container>
    );
}

export default PumpTimeControl;