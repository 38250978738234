import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Typography, Divider } from '@material-ui/core'
import praca_ciagla_off from '../../../../img/praca-ciagla-off.svg'
import praca_ciagla_on from '../../../../img/praca-ciagla-on.svg'
import praca_czasowa_off from '../../../../img/praca-czasowa-off.svg'
import praca_czasowa_on from '../../../../img/praca-czasowa-on.svg'
import praca_na_zadanie_off from '../../../../img/praca-na-zadanie-off.svg'
import praca_na_zadanie_on from '../../../../img/praca-na-zadanie-on.svg'
import wypelnienie from '../../../../img/wypelnienie.svg'
import wypelnienie_on from '../../../../img/wypelnienie-on.svg'


const CirculationFunctions = ({ settings, setPublish }) => {
    const [funcVal, setFuncVal] = useState(settings)
    const [cyrBreak, setCyrBreak] = useState(funcVal.CYR_BS - funcVal.CYR_FILL)

    const handleSwitchFunc = (name, val, more) => {
        setFuncVal({ ...funcVal, [name]: val, [more]: 0 })
        setPublish({ [name]: val, [more]: 0 })

        if (more === '') {
            setFuncVal({ ...funcVal, [name]: val })
            setPublish({ [name]: val })
        }
    }

    // const handleChangeCyrFill = () => {
    //     const cyrTable = [5, 10, 15, 20, 25, 30, 35, 40, 45, 55, 60]

    //     const oldVal = cyrTable.findIndex((el) => el === funcVal.CYR_FILL)

    //     if (oldVal === -1) {
    //         const closest = cyrTable.reduce((prev, curr) => {
    //             return (Math.abs(curr - funcVal.CYR_FILL) < Math.abs(prev - funcVal.CYR_FILL) ? curr : prev);
    //         })

    //         setFuncVal({ ...funcVal, CYR_FILL: closest })
    //         setPublish({ CYR_FILL: closest, CYR_BS: closest + cyrBreak  })
    //     } else if (oldVal + 1 >= cyrTable.length) {
    //         setFuncVal({ ...funcVal, CYR_FILL: cyrTable[0] })
    //         setPublish({ CYR_FILL: cyrTable[0], CYR_BS: cyrTable[0] + cyrBreak })
    //     } else {
    //         setFuncVal({ ...funcVal, CYR_FILL: cyrTable[oldVal + 1] })
    //         setPublish({ CYR_FILL: cyrTable[oldVal + 1], CYR_BS: cyrTable[oldVal + 1] + cyrBreak })
    //     }
    // }

    const handleChangeCyrFill = () => {
        setFuncVal({ ...funcVal, CYR_FILL: funcVal.CYR_FILL + 5, CYR_BS: funcVal.CYR_FILL + 5 + cyrBreak })
        setPublish({ CYR_FILL: funcVal.CYR_FILL + 5, CYR_BS: funcVal.CYR_FILL + 5 + cyrBreak })

        if (funcVal.CYR_FILL < 5 || funcVal.CYR_FILL >= 60 || funcVal.CYR_FILL % 5 !== 0) {
            setFuncVal({ ...funcVal, CYR_FILL: 5, CYR_BS: 5 + cyrBreak })
            setPublish({ CYR_FILL: 5, CYR_BS: 5 + cyrBreak })
        }
    }

    // const handleChangeCyrBreak = () => {
    //     const cyrTable = [5, 10, 15, 20, 25, 30, 35, 40, 45, 55, 60]

    //     const oldVal = cyrTable.findIndex((el) => el === cyrBreak)

    //     if (oldVal === -1) {
    //         const closest = cyrTable.reduce((prev, curr) => {
    //             return (Math.abs(curr - cyrBreak) < Math.abs(prev - cyrBreak) ? curr : prev);
    //         })

    //         setCyrBreak(closest)
    //         setPublish({ CYR_BS: closest + funcVal.CYR_FILL })
    //     } else if (oldVal + 1 >= cyrTable.length) {
    //         setCyrBreak(cyrTable[0])
    //         setPublish({ CYR_BS: cyrTable[0] + funcVal.CYR_FILL })
    //     } else {
    //         setCyrBreak(cyrTable[oldVal + 1])
    //         setPublish({ CYR_BS: cyrTable[oldVal + 1] + funcVal.CYR_FILL })
    //     }
    // }

    const handleChangeCyrBreak = () => {
        setCyrBreak(cyrBreak + 5)
        setPublish({ CYR_BS: cyrBreak + 5 + funcVal.CYR_FILL })

        if (cyrBreak < 5 || cyrBreak >= 60 || cyrBreak % 5 !== 0) {
            setCyrBreak(5)
            setPublish({ CYR_BS: 5 + funcVal.CYR_FILL })
        }
    }

    useEffect(() => {
        let mounted = true
        setFuncVal(settings)

        return () => {
            mounted = false
        }
    }, [settings])
    
    useEffect(() => {
        setCyrBreak(funcVal.CYR_BS - funcVal.CYR_FILL)
    }, [funcVal])
    
    return (
        <React.Fragment>
        <Grid container columns={15} style={{ marginTop: '-13px' }}>
            <Grid item xs={9}>
                <Typography variant='h5' style={{ marginTop: "55px" }}>Tryby pracy</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant='h5' style={{ marginTop: "55px" }}>Modulacja</Typography>
            </Grid>
        </Grid>
        <Grid container columns={15} className='circulation-container'>
            <Grid item xs={3} onClick={() => handleSwitchFunc('SXF_FORCYR', funcVal.SXF_FORCYR === 1 ? 0 : 1, 'SXF_TIMCYR')}>
                {funcVal.SXF_FORCYR === 1 ? <img alt="Cyrkulacja - praca ciągła" className='small-icon pointer' src={praca_ciagla_on} /> : <img alt="Cyrkulacja - praca ciągła" className='small-icon pointer' src={praca_ciagla_off} />}
                <br />
                <Typography variant='h5' style={{ marginTop: "0" }}>praca ciągła</Typography>
            </Grid>
            <Grid item xs={3} onClick={() => handleSwitchFunc('SXF_TIMCYR', funcVal.SXF_TIMCYR === 1 ? 0 : 1, 'SXF_FORCYR')}>
                {funcVal.SXF_TIMCYR === 1 ? <img alt="Cyrkulacja - praca czasowa" className='small-icon pointer' src={praca_czasowa_on} /> : <img alt="Cyrkulacja - praca czasowa" className='small-icon pointer' src={praca_czasowa_off} />}
                <br />
                <Typography variant='h5' style={{ marginTop: "0" }}>praca czasowa</Typography>
            </Grid>
            <Grid item xs={3} onClick={() => handleSwitchFunc('SXF_FLPCYR', funcVal.SXF_FLPCYR === 1 ? 0 : 1, '')}>
                {funcVal.SXF_FLPCYR === 1 ? <img alt="Cyrkulacja - praca na żądanie" className='small-icon pointer' src={praca_na_zadanie_on} /> : <img alt="Cyrkulacja - praca na żądanie" className='small-icon pointer' src={praca_na_zadanie_off} />}
                <br />
                <Typography variant='h5' style={{ marginTop: "0" }}>praca na żądanie</Typography>
            </Grid>
            <Grid item xs={3} onClick={handleChangeCyrFill} style={{ position: 'relative', borderLeft: '2px solid #b3b3b3' }}>
                {funcVal.SXF_TIMCYR === 1 || funcVal.SXF_FLPCYR === 1 ?
                    <Grid>
                        <img alt="Cyrkulacja - praca" className='small-icon pointer' src={wypelnienie_on} /> 
                        <span className='cyr pointer' style={{ color: 'black' }}>{funcVal.CYR_FILL}</span>
                        <br />
                        <Typography variant='h5' style={{ marginTop: "0" }}>praca</Typography>
                    </Grid>
                    :   <Grid>
                            <img alt="Cyrkulacja - praca" className='small-icon pointer' src={wypelnienie} /> 
                            <span className='cyr pointer'>{funcVal.CYR_FILL}</span>
                            <br />
                            <Typography variant='h5' style={{ marginTop: "0" }}>praca</Typography>
                        </Grid>
                }
            </Grid>
            <Grid item xs={3} onClick={handleChangeCyrBreak} style={{ position: 'relative' }}>
                {funcVal.SXF_TIMCYR === 1 ?
                    <Grid>
                        <img alt="Cyrkulacja - przerwa" className='small-icon pointer' src={wypelnienie_on} /> 
                        <span className='cyr pointer' style={{ color: 'black' }}>{cyrBreak}</span>
                        <br />
                        <Typography variant='h5' style={{ marginTop: "0" }}>przerwa</Typography>
                    </Grid>
                    :   <Grid>
                            <img alt="Cyrkulacja - przerwa" className='small-icon pointer' src={wypelnienie} /> 
                            <span className='cyr pointer'>{cyrBreak}</span>
                            <br />
                            <Typography variant='h5' style={{ marginTop: "0" }}>przerwa</Typography>
                        </Grid>
                }
            </Grid>
        </Grid>
        </React.Fragment>
    )
}

export default CirculationFunctions