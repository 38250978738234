import fetchRefreshToken from './fetchRefreshToken'

const fetchInstallerAddEdit = async (payload, action) => {
  // console.log(payload)
  const newToken = await fetchRefreshToken().then(response => response)
    let response
    if(action === "POST"){
    response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installators/add_many`, {
      method: action,
      headers:{
        "Authorization": `Bearer ${newToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response)
  } else if(action === "PUT"){
      response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installators/add_many`, {
      method: action,
      headers:{
        "Authorization": `Bearer ${newToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
  }

    return response
 }

  export default fetchInstallerAddEdit