import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { dataDictionary } from '../../data/dataDictionary'
import { dataRange } from '../../data/dataRange'
import { FiberManualRecord } from '@material-ui/icons'
import Back from '../../img/powrot.svg'
import { Link } from 'react-router-dom'

const WarnItem = ({ warn, typeDevice }) => {
    return (
        <React.Fragment>
            <Grid item xs={1} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                {warn[1] === 1 ? <FiberManualRecord style={{color: "#ff0000", fontSize: "20px"}} /> : null}
            </Grid>
            <Grid item xs={11} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Typography style={{ color: warn[1] !== 1 ? "#b3b3b3" : "#ffffff"}} variant="body1">
                    {dataDictionary(typeDevice).warnings.data[warn[0]]}
                </Typography>
            </Grid>
        </React.Fragment>
    )
}

const Warnings = ({ settings, typeDevice, url }) => {

    const [itemValue, setItemValue] = useState(settings)
    const filteredItems = Object.entries(itemValue ? itemValue : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
    
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline-block' }}>Błędy i ostrzeżenia</Typography>
                    <div className='back-btn' style={{ marginTop: "20px" }}><Link to={`${url}`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" style={{ minHeight: '511px' }}>
                <Grid container item xs={12} alignItems="center" style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                    {filteredItems.map(entry => entry[1] === 1 ?
                        <WarnItem key={entry[0]} warn={entry} typeDevice={typeDevice} /> : null)
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Warnings