import React, { useState } from 'react'
import { TitleRow } from '../../components'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const Blockade = ({ block, setPublish }) => {


    const [BLK_KD1, setBLK_KD1] = useState(parseInt(block.BLK_KD1))
    const [BLK_KD2, setBLK_KD2] = useState(parseInt(block.BLK_KD2))

    const handleOnChange = (e) => {
        if (e.target.name === "BLK_KD1") {
            const { value, maxLength } = e.target;
            const message = value.slice(0, maxLength);
            setBLK_KD1(message);
        } else if (e.target.name === "BLK_KD2") {
            const { value, maxLength } = e.target;
            const message = value.slice(0, maxLength);
            setBLK_KD2(message);
        }
    }
    
    const handleBlockade = (e) => {
        setPublish({ "BLK_KD1": BLK_KD1, "BLK_KD2": BLK_KD2 })
    }

    return (

        <Container>
            <TitleRow title="Blokada" />
            <Row>
                <Col className="text-left" xs={10} md={5}><p>Liczba dni do wyłączenia - bilans</p></Col>
                <Col className="text-right">{block.BLK_LW}</Col>
            </Row>
            <Row>
                <Col className="text-left" xs={10} md={5}><p>Liczba poprawnych kodów</p></Col>
                <Col className="text-right">{block.BLK_LK}</Col>
            </Row><br /><br />
            <Form>
                <Form.Group as={Row}>
                    <Col className="text-left" xs={12} md={10}>
                        <Form.Label>Pierwsza część kodu odblokowującego</Form.Label>
                    </Col>
                    <Col className="text-right">
                        <Form.Control className="b-input" maxLength="5" value={BLK_KD1} name="BLK_KD1" onChange={(e) => handleOnChange(e)} type="text" placeholder="" />
                    </Col>
                </Form.Group>
            </Form>
            <Form>
                <Form.Group as={Row}>
                    <Col className="text-left" xs={12} md={10}>
                        <Form.Label>Druga część kodu odblokowującego</Form.Label>
                    </Col>
                    <Col className="text-right">
                        <Form.Control className="b-input" maxLength="5" value={BLK_KD2} name="BLK_KD2" onChange={(e) => handleOnChange(e)} type="text" placeholder="" />
                    </Col>
                </Form.Group>
            </Form>
            <Row>
                <Col><Button onClick={handleBlockade}>Zapisz</Button></Col>
            </Row>
        </Container>

    );
}

export default Blockade