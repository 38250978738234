import React, { useState } from 'react'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { DayTab } from './components'
import Back from '../../../../img/powrot.svg'
import Water from '../../../../img/ekran-woda.svg'
import { Link } from 'react-router-dom'

const dayTable = [
    {
        day: "poniedziałek",
        stamp: "P",
        label: "PN"
    },
    {
        day: "wtorek",
        stamp: "W",
        label: "WT"
    },
    {
        day: "środa",
        stamp: "S",
        label: "ŚR"
    },
    {
        day: "czwartek",
        stamp: "C",
        label: "CZ"
    },
    {
        day: "piątek",
        stamp: "PT",
        label: "PT"
    },
    {
        day: "sobota",
        stamp: "SO",
        label: "SB"
    },
    {
        day: "niedziela",
        stamp: "N",
        label: "ND"
    },
]


const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}


const TimeCWU = ({ settings, setPublish, setTouchStatus, url }) => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <React.Fragment>
            <Grid container className='modes'>
                <Grid item xs={2}>
                    <Link to={`${url}/timemode/timeheating`}><img alt="Temperatury zadane wody użytkowej w ciągu doby" className='small-icon' style={{ margin: '20px auto', maxHeight: "58px" }} src={Water} /></Link>
                </Grid>
                <Grid item xs={10}>

                    <Grid container>
                        <Grid item xs={12}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                            >
                            {dayTable.map((item, index) => <Tab key={item.label} label={item.label} {...a11yProps(index)} />)}
                            </Tabs>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '30px' }}>
                        <Grid item xs={10}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>Temperatury zadane wody użytkowej w ciągu doby</Typography>
                            <div className='back-btn'><Link to={`${url}`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {dayTable.map((item, index) => <DayTab setTouchStatus={setTouchStatus} value={value} index={index} key={item.day} day={item.day} settings={settings[item.stamp]} stamp={item.stamp} setPublish={setPublish} />)}
                </Grid>
            </Grid>

        </React.Fragment>
    )
}


export default TimeCWU