import React, { useState, useReducer } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { mainStyleClasses } from '../../theme'

import { Wizard, FirstFilling, ElectricalConnections, SensorConnections, VentingCircuits, PreparationFirstLaunch, FirstLaunch,
    MainParametersRegulation, FinalActions, Comments, DeviceInspection, FirstFillingEON, ElectricalConnectionsEON,
    SensorConnectionsEON, VentingCircuitsEON, PreparationFirstLaunchEON, FirstLaunchEON, MainParametersRegulationEON,
    FinalActionsEON, InvestorTraining, CommentsEON } from './components'

import { default as fetchRefillAcceptanceReport } from '../../data/fetchRefillAcceptanceReport'

const switchOption = (state, action) => {
    return {
        ...state, 
        [action.type]: action.value
    }
}

const required = value => (value ? undefined : 'Pole obowiązkowe')

const RefillAcceptanceReport = ({ report, id, handleOnClickEdit, refreshInstallator, setNewValues }) => {
    const classes = mainStyleClasses()
    const [val, setValues] = useReducer(switchOption, {glycolType: null, powerLineProtection: null, isDamaged: null,
        heatExposure: null, coagulationTemp: null, dtValue: null, tempCWU: null, tempCO: null, startCoTemp: null,
        isCommentsPage: null, sensorsConnected: report ? (report.sensorsConnected.length > 0 ? true : false) : false, childName: ""})

    const [sensors, setSensors] = useState({ CWU: report ? (report.sensorsConnected.includes("CWU") ? true : false) : false,
        bufor: report ? (report.sensorsConnected.includes("bufor") ? true : false) : false,
        Tzewn: report ? (report.sensorsConnected.includes("temperatura zewnętrzna") ? true : false) : false,
        Twewn: report ? (report.sensorsConnected.includes("temperatura wewnętrzna") ? true : false) : false });

    const onSubmit = data => {

        let saveData = {
            ...report,
            ...data
        }

        const content = JSON.stringify(saveData)
        fetchRefillAcceptanceReport(content, id, data.status).then(res => {
            if(res === 200)
            refreshInstallator()
            console.log(saveData)
            setNewValues(saveData)
            handleOnClickEdit()
            // refreshInstallatorData()
            // handleClose()
        })
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="h1">Protokół uruchomienia</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body1">Rozpoczęcie konfiguracji urządzenia</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body2" className={classes.error}>* Wszystkie widoczne pola są obowiązkowe</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                {report ? (report.inspection ? (<Wizard
                    initialValues={{...report}}
                    onSubmit={onSubmit}
                    setValues2={setValues}
                    sensorsConnected={val.sensorsConnected}
                    childName={val.childName}
                >
                    <DeviceInspection required={required} setValues={setValues} isDamaged={val.isDamaged} />
                    <FirstFillingEON required={required} setValues={setValues} glycolType={val.glycolType} />
                    <ElectricalConnectionsEON required={required} setValues={setValues} powerLineProtection={val.powerLineProtection} />
                    <SensorConnectionsEON required={required} setValues={setValues} heatExposure={val.heatExposure} sensors={sensors}
                        setSensors={setSensors} />
                    <VentingCircuitsEON required={required} setValues={setValues} />
                    <PreparationFirstLaunchEON coagulationTemp={val.coagulationTemp} setValues={setValues} required={required} />
                    <FirstLaunchEON dtValue={val.dtValue} setValues={setValues} required={required} />
                    <MainParametersRegulationEON required={required} setValues={setValues} tempCWU={val.tempCWU}
                        tempCO={val.tempCO} startCoTemp={val.startCoTemp} />
                    <FinalActionsEON required={required} setValues={setValues} />
                    <InvestorTraining required={required} setValues={setValues} />
                    <CommentsEON setValues={setValues} />
                    <React.Fragment></React.Fragment>
                </Wizard> ) :
                (<Wizard
                    initialValues={{...report}}
                    onSubmit={onSubmit}
                    setValues2={setValues}
                    sensorsConnected={val.sensorsConnected}
                    childName={val.childName}
                >
                    <FirstFilling required={required} />
                    <ElectricalConnections required={required} />
                    <SensorConnections required={required} />
                    <VentingCircuits required={required} />
                    <PreparationFirstLaunch coagulationTemp={val.coagulationTemp} setValues={setValues} required={required} />
                    <FirstLaunch dtValue={val.dtValue} setValues={setValues} required={required} />
                    <MainParametersRegulation required={required} />
                    <FinalActions required={required} />
                    <Comments required={required} />
                    <React.Fragment></React.Fragment>
                </Wizard> )) : null}
                {/*typeof (reportStatus) === 'string' ? <p className={classes.error}>{reportStatus}</p> : null*/}



            </Grid>
        </React.Fragment>
    )
}


export default RefillAcceptanceReport