import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TitleRow = ({ title }) => {
    return(
        <Row>
                <Col>
                    <h1>{ title }</h1>
                </Col>
            </Row>
    )
}

export default TitleRow;