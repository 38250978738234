import React, { useReducer, useEffect } from 'react'
import cwuIcon from '../../../../../../img/Cyrkulacja.svg'
import cwuIcon2 from '../../../../../../img/Cyrkulacja2.svg'

const SwitchControl = ({ ptc, setPublish, stamp, setPumpTimeControl }) => {

    const switchOption = (state, action) => {
        if( action.case === "UPDATE_FROM_BROKER"){
            return ( action.payload )
        } else {
        if (state.SWITCH === 0) {
            return { ...state, SWITCH: 1 };
        } else if (state.SWITCH === 1) {
            return { ...state, SWITCH: 0 };
        }
    }

    }

    const [timeControl, setTimeControl] = useReducer(switchOption, ptc)
   
    useEffect(() => {
        setTimeControl({ payload: ptc, case: "UPDATE_FROM_BROKER" })
}, [ptc])


    const handleSwitching = (e) => {
        const sendKey = e.id;
        const sendData = e.value;
        
        setPublish({ [sendKey]: sendData })
        
    }
    return (
        <React.Fragment>
            {timeControl.SWITCH === 1 ?
                <img alt="cyrkulacja" onClick={() => {setTimeControl({ type: `CYRSW_${stamp}` }); setPumpTimeControl({ type: "SWITCH", stamp: stamp, value: 0 }); handleSwitching({id: `CYRSW_${stamp}`, value: 0})}} className="mode-icon" src={cwuIcon} />
                : <img alt="cyrkulacja" onClick={() => {setTimeControl({ type: `CYRSW_${stamp}` }); setPumpTimeControl({ type: "SWITCH", stamp: stamp, value: 1 }); handleSwitching({id: `CYRSW_${stamp}`, value: 1})}} className="mode-icon" src={cwuIcon2} />}

            </React.Fragment>
    );
}

export default SwitchControl;