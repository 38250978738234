import React, { useState, useReducer, useEffect } from 'react'
import { descriptionDictionary } from '../../../../../dataFilter/descriptionDictionary'
import { NumberInput } from '../../../../../components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import domIcon from '../../../../../../../img/Temp-dom.svg'
import cwuIcon from '../../../../../../../img/Temp-CWU.svg'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: parseInt(action.payload) }
    }
}


const Temperature = ({ temperatureData, setPublish, handleTemperatureChange }) => {
    const [controller, setTemperatureState] = useReducer(switchOption, temperatureData)
    const [state, setState] = useState(null)
    
    useEffect(() => {
        setTemperatureState({ payload: temperatureData, case: "UPDATE_FROM_BROKER" })
    }, [temperatureData])
    
    
    const handleSendTemperatureState = (e, setState) => {
        const sendKey = e.id;
        const sendData = e.value;
        
        if(sendKey === "SPHT_ZAD"){
            handleTemperatureChange({value: parseInt(e.value), change: "TSM10"})
        } else if(sendKey === "SPDHW_ZAD"){
            handleTemperatureChange({value: parseInt(e.value), change: "TSM15"})
        }
        
        setPublish({[sendKey]: sendData })
    }
    const parser = new DOMParser()
    
    return (
        <React.Fragment>
            <Row className="bottom-margin">
                <Col xs={6} className="text-left">
                    <p>{parser.parseFromString(descriptionDictionary.main.BUILDING_TEMP.descript, 'text/html').body.textContent}</p>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={4} className="np">
                        <img alt={parser.parseFromString(descriptionDictionary.main.BUILDING_TEMP.descript, 'text/html').body.textContent} className="mode-icon" src={domIcon} />
                        </Col>
                        <NumberInput setState={setState} descript={descriptionDictionary.main.BUILDING_TEMP.descript} value={controller["buildingTemp"]}
                            min={12} max={35} stamp={descriptionDictionary.main.BUILDING_TEMP.register} stateStamp={"buildingTemp"} setTemperatureState={setTemperatureState} handleSendTemperatureState={handleSendTemperatureState} />
                    </Row>
                </Col>
            </Row>
            <Row className="bottom-margin">
                <Col xs={6} className="text-left">
                    <p>{descriptionDictionary.main.WATHER_TEMP.descript}</p>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={4} className="np">
                        <img alt={descriptionDictionary.main.WATHER_TEMP.descript} className="mode-icon" src={cwuIcon} />
                        </Col>
                        <NumberInput setState={setState} descript={descriptionDictionary.main.WATHER_TEMP.descript} value={controller["watherTemp"]}
                        min={35} max={70} stamp={descriptionDictionary.main.WATHER_TEMP.register} stateStamp={"watherTemp"} setTemperatureState={setTemperatureState} handleSendTemperatureState={handleSendTemperatureState} />
                    </Row>
                </Col>
            </Row>
            {/*state ? (<ToastInfo response={state} close={() => setState(null)} />) : null*/}
        </React.Fragment>
    )
}

export default Temperature