import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { TimeOption } from './TimeOption'
import { OptionList } from '../../../../../../components'

const rangeMinute = (start, stop) => {
    if(start.PG === stop.KG){
        if((start.PM / 10) < 5)
        return (start.PM / 10) + 1
        else
        return start.PM / 10
    } else {
        return 0
    }
}

const TimeZone = ({ timeZone, num, stamp, day, blok, handleChangeTime, daySwitch }) => {
    let min, max
    if(blok === "T"){
        min = 15
        max = 35
    } else {
        min = 35
        max = 70
    }
    return (
        <Row style={{margin: "0 auto"}}>
            <Col md={2} xs={2}>
                <p className="text-right">{num}</p><br />
            </Col>
            <Col md={2} xs={3} className="np">
                <div className="maskLikeInput">
                    <Form.Control disabled={daySwitch === 0? true:false} as="select" custom name={`${blok}W${stamp}${num}P_PG`} value={timeZone[`${blok}W${num}`].PG} onChange={(e) => handleChangeTime({ type: 'PG', stamp: day, num, blok, value: e.target.value, more: stamp })}>
                        <TimeOption nameKey={`${blok}W${stamp}${num}P_PG`} start={0} stop={24} />
                    </Form.Control>
                                        :
                                        <Form.Control disabled={daySwitch === 0? true:false} as="select" custom name={`${blok}W${stamp}${num}P_PM`} value={timeZone[`${blok}W${num}`].PM} onChange={(e) => handleChangeTime({ type: 'PM', stamp: day, num, blok, value: e.target.value, more: stamp })}>
                        <TimeOption multiplier={true} nameKey={`${blok}W${stamp}${num}P_PM`} start={0} stop={6} />
                    </Form.Control>
                </div>
            </Col>
            <Col md={2} xs={3} className="np">
                <div className="maskLikeInput">
                    <Form.Control disabled={daySwitch === 0? true:false} as="select" custom name={`${blok}W${stamp}${num}_KG`} value={timeZone[`${blok}W${num}`].KG} onChange={(e) => handleChangeTime({ type: 'KG', stamp: day, num, blok, value: e.target.value, more: stamp })}>
                        <TimeOption nameKey={`${blok}W${stamp}${num}P_KG`} start={timeZone[`${blok}W${num}`].PG} stop={24} />
                    </Form.Control>
                                        :
                                        <Form.Control disabled={daySwitch === 0? true:false} as="select" custom name={`${blok}W${stamp}${num}_KM`} value={timeZone[`${blok}W${num}`].KM} onChange={(e) => handleChangeTime({ type: 'KM', stamp: day, num, blok, value: e.target.value, more: stamp })}>
                        <TimeOption multiplier={true} nameKey={`${blok}W${stamp}${num}P_KM`} start={rangeMinute({PG: timeZone[`${blok}W${num}`].PG, PM: timeZone[`${blok}W${num}`].PM}, {KG: timeZone[`${blok}W${num}`].KG})} stop={6} />
                    </Form.Control>
                </div>
            </Col>
            <Col md={2} xs={3} className="np">
                <div className="maskLikeInput">
                    <Form.Control disabled={daySwitch === 0? true:false} as="select" custom name={`${blok}W${stamp}${num}T`} value={timeZone[`${blok}W${num}`].Temp} onChange={(e) => handleChangeTime({ type: 'Temp', stamp: day, num, blok, value: e.target.value, more: stamp })} className="text-center temp-select">
                        <OptionList nameKey={`${blok}W${stamp}${num}T`} start={min} stop={max} />
                    </Form.Control>
                </div>
            </Col>
        </Row>
    )
}

export default TimeZone