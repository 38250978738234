import fetchRefreshToken from './fetchRefreshToken'

const fetchEditUser = async (payload, user_id) => {
    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/user/${user_id}`, {
        method: "PUT",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...payload })
      })
      
      return res
    })

    return response
}

export default fetchEditUser