import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField, Select, Checkbox } from 'final-form-material-ui'
import { Button, Grid, Typography, FormControl, MenuItem, FormControlLabel } from '@material-ui/core'
import { fetchPartnersList, sendPartnerId } from '../../../../data'

const PartnersList = ({id, setItemState, itemState, setPartnerListOpen}) => {

    const [ partnersList, setPartnersList ] = useState(null)
    const [ selectedPartner, setSelecetdPartner ] = useState({partnerCity: '', partnerEmial: '', partnerId: null, partnerMemo: '', partnerName: '', partnerNIP: '', partnerPhone: '', partnerPostCode: '', partnerStatus: null, partnerStreet: '', partnerStreetNumber: ''})  
  
    const refreshPartnersList = () => {
        fetchPartnersList().then(res => setPartnersList(res.data))
    }

    const handleSendPartnerId = () => {
        sendPartnerId(selectedPartner.partnerId, id).then(res => {
            if(res === 200) 
                setItemState({...itemState, 
                                    partner_id: selectedPartner.partnerId,
                                    partner: {...itemState.partner, 
                                        city: selectedPartner.partnerCity,
                                        email: selectedPartner.partnerEmail,
                                        id: selectedPartner.partnerId,
                                        memo: selectedPartner.partnerMemo,
                                        name: selectedPartner.partnerName, 
                                        nip: selectedPartner.partnerNIP, 
                                        phone: selectedPartner.partnerPhone, 
                                        post_code: selectedPartner.partnerPostCode, 
                                        status: selectedPartner.partnerStatus, 
                                        street: selectedPartner.partnerStreet, 
                                        street_number: selectedPartner.partnerStreetNumber, 
                                    }
                })
        })
        setPartnerListOpen(false)
    }

    const handleParnerSelect = (partnerCity, partnerEmial, partnerId, partnerMemo, partnerName, partnerNIP, partnerPhone, partnerPostCode, partnerStatus, partnerStreet, partnerStreetNumber) => {
        setSelecetdPartner({partnerCity: partnerCity, partnerEmial: partnerEmial, partnerId: partnerId, partnerMemo: partnerMemo, partnerName: partnerName, partnerNIP: partnerNIP, partnerPhone: partnerPhone, partnerPostCode: partnerPostCode, partnerStatus: partnerStatus, partnerStreet: partnerStreet, partnerStreetNumber: partnerStreetNumber})
    }

    useEffect(() => {
        let mounted = true

        refreshPartnersList()

        return () => {
            mounted = false
        }
    }, [])

    return (
        <React.Fragment>
            <Grid container  style={{ backgroundColor: '#00000090', overflow: 'auto', height: '85vh', paddingTop: 0  }}>
                {partnersList ? partnersList.map((entry, index) => {
                    if (entry !== undefined) {
                        return (
                            <Grid item xs={12}>
                                <Typography 
                                    key={index} 
                                    className='partnerListItem' 
                                    style={ selectedPartner.partnerId === entry.id ? { backgroundColor: '#efefef', color: 'black' } : null } 
                                    onClick={() => handleParnerSelect(entry.city, entry.email, entry.id, entry.memo, entry.name, entry.nip, entry.phone, entry.post_code, entry.status, entry.street, entry.street_number)}>
                                        {entry.name}
                                </Typography>
                            </Grid>
                        )
                    } else {
                        return null
                    }  
                    } ): null
                }   
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                {selectedPartner.partnerId === null ? 
                    <Button disabled variant="contained" color="primary">Zapisz</Button>
                    : <Button variant="contained" color="primary" onClick={() => handleSendPartnerId()}>Zapisz</Button>
                } 
            </Grid> 
        </React.Fragment>
    )
}

export default PartnersList