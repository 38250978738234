export const dataDictionary = (type) => {
    switch (type) {
        case 'GEO_1':
            return {
                warnings: {
                    title: "Błędy i ostrzeżenia",
                    data: {
                        REW_ERR: "Zbiorcza flaga stanu awaryjnego",
                        REW_SUP: "Błąd z softstartera / czujnika poprawności zasilania (CKF-B)",
                        REW_TER: "Błąd z modułu kontroli termicznej sprężarki",
                        REW_LPC: "Błąd z presostatu niskiego ciśnienia dla chłodzenia",
                        REW_LPH: "Błąd z presostatu niskiego ciśnienia dla grzania",
                        REW_HP: "Błąd z presostatu wysokiego ciśnienia",
                        REW_FLO: "Błąd z czujnika przepływu",
                        REW_DIS: "Błąd przekroczenia temperatury tłoczenia",
                        REW_SMD: "Przekroczenie temperatury krytycznej w wymienniku źródła",
                        REW_RMD: "Przekroczenie temperatury krytycznej w wymienniku odbioru",
                        REW_FCS: "Awaria układu kontrolnego", 
                        SXB_DHWO: "Blokada awaryjna funkcji - podgrzewanie cwu",
                        SXB_CLO: "Blokada awaryjna funkcji - chłodzenie aktywne",
                        SXB_PWRO: "Blokada awaryjna urządzenia - problemy z zasilaniem",
                        SXB_FER: "Blokada awaryjna urządzenia lub jednej funkcji",
                        SXB_HTO: "Blokada awaryjna funkcji podgrzewania CO.", 
                    }
                }
            }
        case 'ATMO_1': 
            return {
                warnings: {
                    title: "Błędy i ostrzeżenia",
                    data: {
                        REW_ERR: "Zbiorcza flaga stanu awaryjnego",
                        REW_LPH: "Błąd z presostatu niskiego ciśnienia",
                        REW_HP: "Błąd z presostatu wysokiego ciśnienia",
                        REW_FLO: "Błąd z czujnika przepływu",
                        REW_DIS: "Błąd przekroczenia temperatury tłoczenia",
                        REW_SMD: "Praca źródła szczytowego bez jednostki zewnętrznej",
                        REW_RMD: "Przekroczenie temperatury krytycznej w wymienniku odbioru",
                        MB_COMM2_XZM_TRANSM: "Błąd komunikacji z jednostką zewnętrzną",
                        SXB_DHWO: "Blokada awaryjna funkcji - podgrzewanie cwu",
                        SXB_CLO: "Blokada awaryjna funkcji - chłodzenie aktywne",
                        SXB_PWRO: "Blokada awaryjna urządzenia - problemy z zasilaniem",
                        SXB_FER: "Blokada awaryjna urządzenia lub jednej funkcji",
                        SXB_HTO: "Blokada awaryjna funkcji podgrzewania CO.", 
                    }
                }  
            } 
        case 'ALLINONE_1': 
            return {
                warnings: {
                    title: "Błędy i ostrzeżenia",
                    data: {
                        REW_ERR: "Zbiorcza flaga stanu awaryjnego",
                        REW_LPH: "Błąd z presostatu niskiego ciśnienia",
                        REW_HP: "Błąd z presostatu wysokiego ciśnienia",
                        REW_FLO: "Błąd z czujnika przepływu",
                        REW_DIS: "Błąd przekroczenia temperatury tłoczenia",
                        REW_SMD: "Praca źródła szczytowego bez jednostki zewnętrznej",
                        REW_RMD: "Przekroczenie temperatury krytycznej w wymienniku odbioru",
                        MB_COMM2_XZM_TRANSM: "Błąd komunikacji z jednostką zewnętrzną",
                        SXB_DHWO: "Blokada awaryjna funkcji - podgrzewanie cwu",
                        SXB_CLO: "Blokada awaryjna funkcji - chłodzenie aktywne",
                        SXB_PWRO: "Blokada awaryjna urządzenia - problemy z zasilaniem",
                        SXB_FER: "Blokada awaryjna urządzenia lub jednej funkcji",
                        SXB_HTO: "Blokada awaryjna funkcji podgrzewania CO.", 
                    }
                } 
            }
        default:
            break
    }  
}