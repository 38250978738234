import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { TitleRow } from '../../components'



const ControllerTime = ({ controllertime, setPublish, noTitle }) => {
    const date = new Date(parseInt(controllertime.ROK),
        parseInt(controllertime.MIESIAC) - 1, parseInt(controllertime.DZIEN), parseInt(controllertime.GODZINY), parseInt(controllertime.MINUTY), parseInt(controllertime.SEKUNDY)).toLocaleString();
    
        
    const handleSynchTime = () => {
        const actualDate = new Date();
        setPublish({
            "ST_ROK": actualDate.getFullYear(),
            "ST_MIESIAC": actualDate.getMonth() + 1,
            "ST_DZIEN": actualDate.getDate(),
            "ST_GODZINY": actualDate.getHours(),
            "ST_MINUTY": actualDate.getMinutes(),
            "ST_SEKUNDY": actualDate.getSeconds()
        })
    }


    return (
        <>
            <Container className="np">
                {noTitle? null : <TitleRow title="Aktualny czas sterownika" />}
                <Row>
                    <Col xs={12} md={6}>
                    {noTitle? <p className="center-for-middle">Czas sterownika {date.toLocaleString()}</p>
                    : <h3>{date.toLocaleString()}</h3> }
                    </Col>
                    <Col xs={12} md={6}>
                    <Button className="normal-btn" onClick={handleSynchTime}>Synchronizuj</Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ControllerTime;