import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const dayWeek = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela']
const IDP_MODTable = ["grzanie", "chłodzenie", "rewersyjna", "inna"]

const slideList = (min, max, name, step) => {
    const tableList = []
    if (step && step !== 1) {
        let helpmin = min / step
        let helpmax = max / step
        for (let i = helpmin; i <= helpmax; i++) {
            tableList.push(<SwiperSlide key={name + i}>{Number.isInteger(i * step) ? (i * step) : (i * step).toFixed(1)}</SwiperSlide>)
        }
    } else if (name === "DZIEN_TYG") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={name + i}>{dayWeek[i]}</SwiperSlide>)
        }
    } else if (name === "IDP_MOD") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={name + i}>{IDP_MODTable[i]}</SwiperSlide>)
        }
    }
    else {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={name + i}>{i}</SwiperSlide>)
        }
    }

    return tableList
}

const initSlideIndex = (step, initVal, name) => {
    if(name === "SPHT_HIST")
    return Math.round(parseFloat(initVal)/step)
    else if(!step)
    return initVal
    else if(step === 10) 
    return Math.round(initVal/step)
    else
    return initVal/step
}

const CarouselInputMulti = ({ init, min, max, name, setVal, num, step}) => {
    const [slide, setSlide] = useState(init)
    const onChangeSlideTime = (index) => {
        setSlide(index - 1)
    }
    const onChangeStop = (index, value) => {
        if (step !== 1) {
            if (name === "DZIEN_TYG") {
                setVal(num, dayWeek.findIndex(day => day === value[index].textContent))
            } else if (name === "SPHT_HIST"){
                setVal(num, parseFloat(value[index].textContent))
            } else if (name === "IDP_MOD") {
                setVal(num, IDP_MODTable.findIndex(val => val === value[index].textContent)) 
            } else {
                setVal(num, parseFloat(value[index].textContent.replace(/\D+/gi, "")))
            }
        } else if (parseInt(value[index].textContent) < 0) {
            setVal(num, parseInt(value[index].textContent), 1)
        } else if (parseInt(value[index].textContent) >= 0){
            setVal(num, parseInt(value[index].textContent.replace(/\D+/gi, "")), 0)
        }
    }
    
    return (
        <Grid container>
            <Grid item xs={12} className="euros-monitor euros-monitor-carousel">
                <Swiper
                    // onTouchEnd={(swiper) => onChangeSlideTime(swiper.activeIndex)}
                    initialSlide={initSlideIndex(step, init, name) - (min !== 0 && step !== 1 ? min/step : min)} watchSlidesVisibility={true} slidesPerView={3} 
                    centeredSlides={true} direction="vertical" loop={false} className="mySwiper"
                    // loopAdditionalSlides={18}
                    onSlideChange={(swiper) => onChangeStop(swiper.activeIndex, swiper.slides)}>
                    {slideList(min, max, name, step)}
                </Swiper>
            </Grid>
        </Grid>
    )
}

export default CarouselInputMulti