import React, { useReducer } from 'react'
import { Typography, Grid, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import czujnik0 from '../../../../img/czujnik0.svg'
import czujnik1 from '../../../../img/czujnik1.svg'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: action.payload };
    }
}

const InternalTemp = ({ settings, setPublish }) => {
    const [itemValue, setItemValue] = useReducer(switchOption, settings)

    const handleSwitchValue = name => (e, newValue) => {
        if (!newValue || parseInt(newValue) === 0) {
            setItemValue({ type: name, payload: 0 })
            setPublish({ [name]: 0 })
        } else {
            setItemValue({ type: name, payload: 1 })
            setPublish({ [name]: 1 })
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <RadioGroup name="SWEXT_TWP" value={parseInt(itemValue.SWEXT_TWP)} onChange={handleSwitchValue("SWEXT_TWP")}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h2">Wybór trybu pracy (z/bez czujnika temperatury wewnętrznej)</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <label style={{ display: "block", height: "100%" }}>
                                        <img alt="Euros Energy" src={czujnik1} style={{ maxWidth: "100%", height: "80px", margin: "0 auto" }} /><br />
                                        <FormControlLabel value={0} control={<Radio color="primary" />} label="z czujnikiem" />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label style={{ display: "block", height: "100%" }}>
                                        <img alt="Euros Energy" src={czujnik0} style={{ maxWidth: "100%", height: "80px", margin: "0 auto" }} /><br />
                                        <FormControlLabel value={1} control={<Radio color="primary" />} label="bez czujnika" />
                                    </label>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default InternalTemp