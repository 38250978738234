import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, Checkbox, TextField, Table, Paper, TableBody, TableContainer, TableRow, TableCell, TableHead, Box } from '@material-ui/core'
import { fetchInspection } from '../../../../data'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from "date-fns/locale/pl"

const addNewInspection = (deviceId, payload, refreshInspections, refreshDevice) => {
    fetchInspection("POST", deviceId, payload).then(response => {
        refreshInspections(response)
        refreshDevice()
    })
}

const deleteInspection = (deviceId, payload, refreshInspections, refreshDevice, inspection) => {
    fetchInspection("DELETE", deviceId, payload, inspection).then(response => {
        refreshInspections(response)
        refreshDevice()
    })
}

const editInspection = (deviceId, payload, inspections, setInspections, action, refreshInspections, refreshDevice) => {
    fetchInspection(action, deviceId, payload).then(response => {
        if (response.status === "ok") {
            let editInspectionslist = inspections.map(item => item.id === payload.id ? response.data : item)
            setInspections(editInspectionslist)
            refreshDevice()
            return refreshInspections()
        } else {
            throw new Error(response.status)
        }
    })
}

const inspectionList = (inspections, editInspectionForm, saveInspection, setHelpState, helpState, client_launch_date, setWarrantyInfo) => {
    let lastInspection = inspections && inspections.length !== 0 ? new Date(inspections[inspections.length - 1].date) : client_launch_date? new Date(client_launch_date) : new Date()
    let nextInspection = new Date(lastInspection.setMonth(lastInspection.getMonth() + 18))


    const backgroundInpectionCheck = (item, index) =>{
        let lastInspectionDate = inspections && inspections.length  > 1 && index > 0 ? new Date(inspections[index - 1].date) : client_launch_date ? new Date(client_launch_date) : null

        if(!lastInspectionDate){
            return "transparent"
        } else if((new Date(lastInspectionDate.setMonth(lastInspectionDate.getMonth() + 18)) < new Date(item.date)) && item.warranty === false) {
            setWarrantyInfo(true)
            return "#fd727254"
        } else {
            return "transparent"
        }
    }

    return (
        <React.Fragment>
            {client_launch_date? 
                null :
                <TableRow>
                    <TableCell colSpan={5}><Typography variant="body2">Nie dodano daty rozpoczęcia gwarancji. Urządzenie może być nadal w magazynie. Jeśli znasz datę uruchomienia u klienta należy wyedytować daty gwarancji powyżej.</Typography></TableCell>
                </TableRow>
                }
            {inspections ?
                inspections.map((item, index) =>{ 
                    
                    return (<TableRow key={index} style={{background: backgroundInpectionCheck(item, index)}}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                            <KeyboardDatePicker
                                variant="inline"
                                ampm={false}
                                placeholder="YYYY-MM-DD"
                                value={item.date}
                                onChange={(e) => editInspectionForm(e, "date", item.id, "edit")}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell>
                        <TextField name="comment" type="text"
                            value={item.comment ? item.comment : "Komentarz"}
                            onChange={(e) => editInspectionForm(e.target.value, "comment", item.id, "edit")} />
                    </TableCell>
                    <TableCell><Checkbox
                        checked={item.done ? true : false}
                        onChange={(e) => editInspectionForm(e.target.checked, "done", item.id, "edit")}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                    /></TableCell>
                    <TableCell><Checkbox
                        checked={item.warranty ? true : false}
                        onChange={(e) => editInspectionForm(e.target.checked, "warranty", item.id, "edit")}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color="primary"
                    /></TableCell>
                    <TableCell><Button onClick={() => saveInspection(item.id, "PUT")} variant="contained" color="primary">Zapisz</Button> <br /><br />
                    <Button onClick={() => saveInspection(item.id, "DELETE")} variant="contained" color="primary">Usuń</Button></TableCell>
                </TableRow>)})
                : null}
                <TableRow>
                <TableCell colSpan={5}><Typography variant="body2">Proponowany kolejny przegląd:</Typography></TableCell>
            </TableRow>
                {client_launch_date? <TableRow>
                <TableCell>*{inspections ? inspections.length + 1 : 1}</TableCell>
                <TableCell><MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        ampm={false}
                        placeholder="YYYY-MM-DD"
                        value={helpState.date? helpState.date : nextInspection}
                        onChange={(e) => {
                            editInspectionForm(e, "date", inspections ? inspections.length + 1 : 1, "new", e)

                        }}
                        format="yyyy-MM-dd"
                    />
                </MuiPickersUtilsProvider></TableCell>
                <TableCell><TextField name="comment" type="text"
                    value={helpState.comment? helpState.comment : "Komentarz"}
                    onChange={(e) => editInspectionForm(e.target.value, "comment", inspections ? inspections.length + 1 : 1, "new", nextInspection)} /></TableCell>
                <TableCell><Checkbox
                    checked={helpState.done}
                    onChange={(e) => editInspectionForm(e.target.checked, "done", inspections ? inspections.length + 1 : 1, "new", nextInspection)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                /></TableCell>
                <TableCell><Checkbox
                    checked={helpState.warranty}
                    onChange={(e) => editInspectionForm(e.target.checked, "warranty", inspections ? inspections.length + 1 : 1, "new", nextInspection)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                /></TableCell>
                <TableCell><Button onClick={() => saveInspection(inspections ? inspections.length + 1 : 1, "POST")} variant="contained" color="primary">Zapisz</Button></TableCell>
            </TableRow>:null}
        </React.Fragment>
    )
}

const Inspections = ({ deviceId, warranty_date, client_launch_date, setWarrantyInfo, refreshDevice }) => {
    const [inspections, setInspections] = useState(null)
    const [dateTo, setDateTo] = useState(new Date())
    const [helpState, setHelpState] = useState({ comment: "", done: false, warranty: false, date: new Date() })


    const editInspectionForm = (e, name, id, action, nextInspection) => {
        let editInspectionslist
        setWarrantyInfo(false)
        switch (action) {
            case "edit":
                editInspectionslist = inspections.map(item => item.id === id ? { ...item, [name]: e } : item)
                setInspections(editInspectionslist)
                switch (name) {
                    case "date":
                        let editInspectionsDate = inspections.map(item => item.id === id ? { id: item.id, date: e, done: item.done, warranty: item.warranty, comment: item.comment } : item)
                        setDateTo(e)
                        return setInspections(editInspectionsDate)
                    case "comment":
                        let editInspectionsComment = inspections.map(item => item.id === id ? { id: item.id, date: item.date, done: item.done, warranty: item.warranty, comment: e } : item)
                        return setInspections(editInspectionsComment)
                    case "done":
                        let editInspectionsDone = inspections.map(item => item.id === id ? { id: item.id, date: item.date, comment: item.comment, warranty: item.warranty, done: e } : item)
                        return setInspections(editInspectionsDone)
                    case "warranty":
                        let editInspectionsWarranty = inspections.map(item => item.id === id ? { id: item.id, date: item.date, comment: item.comment, done: item.done, warranty: e } : item)
                        return setInspections(editInspectionsWarranty)
                    default:
                        break;
                }
            case "new":
                switch (name) {
                    case "date":
                        return setHelpState({ ...helpState, date: nextInspection })
                    case "comment":
                        return setHelpState({ ...helpState, comment: e })
                    case "done":
                        return setHelpState({ ...helpState, done: e })
                    case "warranty":
                        return setHelpState({ ...helpState, warranty: e })
                    default:
                        break;
                }

            default:
                break;
        }


    }

    const saveInspection = (id, action) => {
        let editInspections
        switch (action) {
            case "PUT":
                editInspections = inspections.filter(item =>{ 
                    if (item.id === id)
                    return { ...item, date: dateTo }
                })
                return editInspection(deviceId, editInspections[0], inspections, setInspections, action, refreshInspections, refreshDevice)
            case "DELETE":
                editInspections = inspections.filter(item =>{ 
                    if (item.id === id)
                    return { ...item, date: dateTo }
                })
                return deleteInspection(deviceId, editInspections, refreshInspections, refreshDevice, id)
            case "POST":
                editInspections = { ...helpState }
                return addNewInspection(deviceId, editInspections, refreshInspections, refreshDevice)

            default:
                break;
        }
    }

    const refreshInspections = () => {
        fetchInspection("GET", deviceId).then(response => response.data ? setInspections(response.data) : setInspections([]))
    }

    useEffect(() => {
        let mounted = true
        if (deviceId)
            fetchInspection("GET", deviceId).then(response => response.data ? setInspections(response.data) : setInspections([]))
        return () => {
            mounted = false
        }
    }, [deviceId])


    return (
        <Grid container>
            <Grid item xs={12} className='no-padding'>
                <Typography variant='h3'>Przeglądy<br /><br /></Typography>
            </Grid>
            <Grid item xs={12} className='no-padding'>

                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            LP
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            Data
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            Komentarz
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            Wykonano
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            Gwarancja
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inspectionList(inspections, editInspectionForm, saveInspection, setHelpState, helpState, client_launch_date, setWarrantyInfo, refreshDevice)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default Inspections