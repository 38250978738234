import { default as fetchRefreshToken } from './fetchRefreshToken'

const fetchRefillAcceptanceReport = async (data, id, status) => {
    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_reports/${id}`, {
        method: "PUT",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({content: data, status: status})
    }).then(res => res.status)
        return res
    })
    return response
}

  export default fetchRefillAcceptanceReport

