const sendEmail = async (payload) => {

    const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customer/send_email_after_service_note`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    
    return res
  }
  
  
  export default sendEmail