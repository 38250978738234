import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const FinalActionsEON = ({ required, setValues }) => {

    useEffect(() => {
        setValues({type: "childName", value: "FinalActionsEON"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Czynności końcowe</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Test panelu operatorskiego</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="testOperatorPanel"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="testOperatorPanel"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="testOperatorPanel" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Komunikacja z Internetem<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Zalecane LAN, Wi-Fi w wyjątkowych opisanych sytuacjach</span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Kabel LAN + RJ45"
                                control={
                                    <Field
                                        name="internetConnection"
                                        component={Radio}
                                        type="radio"
                                        value="Kabel LAN + RJ45"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Wi-Fi (extender wraz z konfiguracją)"
                                control={
                                    <Field
                                        name="internetConnection"
                                        component={Radio}
                                        type="radio"
                                        value="Wi-Fi (extender wraz z konfiguracją)"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="internetConnection" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Rejestracja pompy ciepła w systemie fabrycznym
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="deviceRegistration"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="deviceRegistration"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="deviceRegistration" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Test mobilnej aplikacji sterującej
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="MASTest"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="MASTest"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="MASTest" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Instalacja mobilnej aplikacji sterującej na urządzeniu Inwestora
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="MASInstall"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="MASInstall"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="MASInstall" />
                </Grid>
            </Grid>
        </>
    )
}

export default FinalActionsEON;