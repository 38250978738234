import { makeStyles } from '@material-ui/core/styles'

const mainStyleClasses = makeStyles((theme) => ({
    hidden: {
        display: 'none',
    },
    textLeft: {
        textAlign: 'left'
    },
    textRight: {
        textAlign: 'right'
    },
    textCenter: {
        textAlign: 'center'
    },
    containerVerticalCenter: {
        height: '52px', 
        display: 'flex', 
        alignItems: 'center'
    },
    floatRight: {
        float: 'right',
    },
    floatLeft: {
        float: 'left',
    },
    buttonMargin: {
        margin: '20px auto !important',
    },
    error: {
        color: 'red',
        fontSize: '12px',
        '@media (max-width:600px)': {
            fontSize: '10px',
        },
    },
    logo: {
        margin: '15px',
        width: '180px',
        '@media (max-width:600px)': {
            margin: '10px',
            width: '120px',
        },
    },
    backgroundRed: {
        background: 'none #ffd4d4',
    },
    backgroundGreen: {
        background: 'none #c5ffc5',
    },
    backgroundYellow: {
        background: 'none #FFFFC0',
    },
    backgroundWhite: {
        background: 'none #ffffff',
    },
    boxRadius: {
        borderRadius: '15px',
        padding: '10px',
        margin: '0 1px 3px',
        textAlign: 'left',
        boxSizing: "border-box",
        // cursor: "pointer",
        width: "calc(100% - 3px)",
    },
    boxRadiusH:{
        '& $boxRadius':{
            height: "calc(100% - 5px)",
        },
    }
}));

export default mainStyleClasses