import React, { useEffect, useReducer } from 'react'
const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return {}
    }
}

const colorTemp = (qht, qhl) => {
    if (qht === 1 && qhl === 0)
        return "red"
    else if (qht === 0 && qhl === 1)
        return "blue"
    else if (qht === 0 && qhl === 0)
        return "green"
}

const Panel = ({ data }) => {
    const [active, setActive] = useReducer(switchOption, data)

    useEffect(() => {
        setActive({ payload: data, case: "UPDATE_FROM_BROKER" })
    }, [data])
    
    return (
        <div className="panel">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 556.17 401.36">

            <g id="Layer_2" data-name="Layer 2">,
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1"
                        d="M527,0H200.68C89.85,0,0,89.85,0,200.68S89.85,401.36,200.68,401.36H527a29.2,29.2,0,0,0,29.2-29.2V29.2A29.2,29.2,0,0,0,527,0" />
                    <path className="cls-2" d="M361.23,200.68A160.55,160.55,0,1,1,200.68,40.13,160.55,160.55,0,0,1,361.23,200.68" />
                    <path d="M343.77,200.69A143.35,143.35,0,1,1,200.42,57.34,143.35,143.35,0,0,1,343.77,200.69" />
                    <path className="cls-3" d="M388.6,117.63a63.07,63.07,0,1,0,63.07-63.07,63.08,63.08,0,0,0-63.07,63.07" />
                    <path className="cls-3" d="M388.6,283.73a63.07,63.07,0,1,0,63.07-63.07,63.08,63.08,0,0,0-63.07,63.07" />
                    <path className="cls-4"
                        d="M332.35,370.31a6.6,6.6,0,0,1-1.67.23c-1.41,0-2.26-.55-2.26-2.11a7.06,7.06,0,0,1,.13-1.24c.43-2.5,1.75-3.42,3.37-3.42a4.83,4.83,0,0,1,1.38.2l-.29.95a3.91,3.91,0,0,0-1-.13c-1,0-1.78.53-2.16,2.38a5.42,5.42,0,0,0-.12,1.12c0,.93.43,1.26,1.26,1.26a5.11,5.11,0,0,0,1.33-.2Z" />
                    <path className="cls-4"
                        d="M335.89,370.6c-1.45,0-2.31-.58-2.31-2.2a6.56,6.56,0,0,1,.12-1.22c.46-2.51,1.67-3.44,3.44-3.44,1.45,0,2.31.58,2.31,2.2a6.64,6.64,0,0,1-.12,1.22c-.46,2.51-1.67,3.44-3.44,3.44m2.29-4.59c0-1-.42-1.28-1.18-1.28-1,0-1.64.53-2,2.43a5.76,5.76,0,0,0-.13,1.14c0,1,.42,1.29,1.18,1.29,1,0,1.64-.54,2-2.43a5.77,5.77,0,0,0,.13-1.15" />
                    <path className="cls-4"
                        d="M348.29,370.4l.8-4.17a3.5,3.5,0,0,0,.06-.62c0-.53-.22-.78-.71-.78a3.09,3.09,0,0,0-2,1.3l-.82,4.26h-1.26l.8-4.17a3.5,3.5,0,0,0,.06-.62c0-.53-.21-.78-.72-.78a3.09,3.09,0,0,0-2,1.3l-.82,4.26h-1.26l1.25-6.47h1.05l-.08.91a3.46,3.46,0,0,1,2.38-1.05c.93,0,1.39.42,1.49,1.13a3.63,3.63,0,0,1,2.47-1.13,1.37,1.37,0,0,1,1.55,1.55,4.56,4.56,0,0,1-.09.78l-.83,4.29h-1.24Z" />
                    <path className="cls-4"
                        d="M352.89,370.94c-.35,1.81-1.2,2.41-2.6,2.41a4.7,4.7,0,0,1-1.08-.12l.18-1a4.67,4.67,0,0,0,.93.08c.65,0,1.09-.39,1.29-1.42l1.17-6h-1l.19-1h1l.21-1c.37-1.82,1.16-2.39,2.7-2.39a6.14,6.14,0,0,1,1.29.14l-.21,1a5.09,5.09,0,0,0-1.13-.12c-.71,0-1.16.37-1.37,1.39l-.21,1h1.93l-.18,1h-1.93Z" />
                    <path className="cls-4"
                        d="M358.1,370.6c-1.45,0-2.31-.58-2.31-2.2a6.56,6.56,0,0,1,.12-1.22c.46-2.51,1.67-3.44,3.44-3.44,1.45,0,2.31.58,2.31,2.2a6.64,6.64,0,0,1-.12,1.22c-.46,2.51-1.67,3.44-3.44,3.44m2.28-4.59c0-1-.42-1.28-1.18-1.28-1,0-1.64.53-2,2.43a5.76,5.76,0,0,0-.13,1.14c0,1,.42,1.29,1.18,1.29,1,0,1.64-.54,2-2.43a5.77,5.77,0,0,0,.13-1.15" />
                    <path className="cls-4"
                        d="M366.85,365a.77.77,0,0,0-.34-.08,2.68,2.68,0,0,0-1.87,1.18l-.82,4.26h-1.26l1.25-6.47h1.05l-.08.93a3.19,3.19,0,0,1,2-1.14.77.77,0,0,1,.33.07Z" />
                    <path className="cls-4"
                        d="M370.27,370.4a6.09,6.09,0,0,1-1.3.14c-.93,0-1.39-.3-1.39-1.13a3.48,3.48,0,0,1,.07-.58l.76-3.93h-1l.2-1h1l.39-2,1.3-.18-.42,2.2h1.49l-.2,1h-1.49l-.74,3.8a2,2,0,0,0,0,.38c0,.37.16.47.49.47a3.41,3.41,0,0,0,.85-.11Z" />
                    <path className="cls-4"
                        d="M378.92,370.4l.79-4.17a2.85,2.85,0,0,0,.08-.62c0-.53-.25-.78-.79-.78a3.21,3.21,0,0,0-2.14,1.3l-.82,4.26h-1.26l1.88-9.71h1.26l-.49,2.52a13,13,0,0,1-.37,1.53h0a3.77,3.77,0,0,1,2.41-1,1.39,1.39,0,0,1,1.6,1.55,4.56,4.56,0,0,1-.09.78l-.82,4.29h-1.25Z" />
                    <path className="cls-4"
                        d="M383.64,367.62a4.76,4.76,0,0,0-.09.79c0,.86.39,1.14,1.25,1.14a6.54,6.54,0,0,0,1.91-.28l0,1a6.9,6.9,0,0,1-2.14.31c-1.49,0-2.3-.48-2.3-2.1a7.87,7.87,0,0,1,.12-1.24c.43-2.5,1.71-3.47,3.42-3.47,1.26,0,2,.58,2,1.56,0,1.59-1.39,2.33-4.22,2.33m3-2.16c0-.46-.32-.74-1-.74-.92,0-1.55.57-1.88,2,1.92,0,2.85-.36,2.85-1.28" />
                    <path className="cls-4"
                        d="M392.48,370.4l.08-.9a3.45,3.45,0,0,1-2.26,1c-1.07,0-1.75-.68-1.75-2,0-2.93,1.58-4.72,3.52-4.72a2.62,2.62,0,0,1,1.55.47l.1-.33h1l-1.25,6.47h-1Zm.79-5.18a2,2,0,0,0-1.21-.42c-1.33,0-2.21,1.67-2.21,3.51,0,.79.32,1.18.87,1.18a3.06,3.06,0,0,0,2-1.29Z" />
                    <path className="cls-4"
                        d="M398.62,370.4a6.09,6.09,0,0,1-1.3.14c-.93,0-1.39-.3-1.39-1.13a3.48,3.48,0,0,1,.07-.58l.76-3.93h-1l.2-1H397l.39-2,1.3-.18-.42,2.2h1.49l-.2,1H398l-.74,3.8a2,2,0,0,0,0,.38c0,.37.16.47.49.47a3.41,3.41,0,0,0,.85-.11Z" />
                    <path className="cls-4"
                        d="M399.87,370.4l1.25-6.47h1.26l-1.25,6.47Zm2.15-7.86c-.49,0-.67-.11-.67-.43a1.5,1.5,0,0,1,0-.35c.11-.57.28-.74.92-.74s.67.11.67.43a1.55,1.55,0,0,1,0,.34c-.1.57-.29.75-.92.75" />
                    <path className="cls-4"
                        d="M407.31,370.4l.79-4.17a2.85,2.85,0,0,0,.08-.62c0-.53-.25-.78-.79-.78a3.21,3.21,0,0,0-2.14,1.3l-.82,4.26h-1.26l1.25-6.47h1.05l-.08.91a3.69,3.69,0,0,1,2.48-1.05,1.39,1.39,0,0,1,1.6,1.55,4.56,4.56,0,0,1-.09.78l-.82,4.29Z" />
                    <path className="cls-4"
                        d="M414.62,369.6h0a3.44,3.44,0,0,1-2.17,1c-1.07,0-1.75-.68-1.75-2,0-2.93,1.58-4.72,3.52-4.72a2.62,2.62,0,0,1,1.55.47l.11-.33h1.05l-1.31,6.76c-.37,1.94-1.45,2.68-3.41,2.68a7.45,7.45,0,0,1-1.95-.23l.21-1a7.56,7.56,0,0,0,1.81.21,1.81,1.81,0,0,0,2.06-1.7c.1-.46.16-.61.29-1.07m-1.76-.1a3.06,3.06,0,0,0,2-1.29l.58-3a2,2,0,0,0-1.21-.42c-1.33,0-2.21,1.67-2.21,3.51,0,.79.31,1.19.87,1.19" />
                    <path className="cls-4"
                        d="M427.75,370.76c-.46-.5-.88-1-1.34-1.5a4.26,4.26,0,0,1-3.05,1.34c-1.53,0-2.51-.83-2.51-2.13,0-1.59,1.2-2.46,2.43-3.26a4.3,4.3,0,0,1-.68-2.14,2.42,2.42,0,0,1,2.7-2.56c1.42,0,2,.76,2,1.62,0,1.56-1.29,2.52-2.47,3.3a18.83,18.83,0,0,0,1.67,2.21,6.31,6.31,0,0,0,.85-2h1.09a7.63,7.63,0,0,1-1.25,2.77c.47.55.92,1,1.42,1.58Zm-4.23-1.15a3.14,3.14,0,0,0,2.24-1.12,24.14,24.14,0,0,1-1.89-2.45c-.89.6-1.77,1.17-1.77,2.27a1.25,1.25,0,0,0,1.42,1.3m1.59-8.13c-.87,0-1.34.54-1.34,1.47a3.52,3.52,0,0,0,.52,1.64c1-.65,1.8-1.28,1.8-2.25,0-.5-.31-.86-1-.86" />
                    <path className="cls-4"
                        d="M436.61,370.31a6.6,6.6,0,0,1-1.67.23c-1.41,0-2.26-.55-2.26-2.11a7.06,7.06,0,0,1,.13-1.24c.43-2.5,1.75-3.42,3.37-3.42a4.83,4.83,0,0,1,1.38.2l-.29.95a3.91,3.91,0,0,0-1-.13c-1,0-1.77.53-2.16,2.38a5.42,5.42,0,0,0-.12,1.12c0,.93.43,1.26,1.26,1.26a5.11,5.11,0,0,0,1.33-.2Z" />
                    <path className="cls-4"
                        d="M440.15,370.6c-1.45,0-2.31-.58-2.31-2.2a6.56,6.56,0,0,1,.12-1.22c.46-2.51,1.67-3.44,3.44-3.44,1.45,0,2.31.58,2.31,2.2a6.64,6.64,0,0,1-.12,1.22c-.45,2.51-1.66,3.44-3.44,3.44m2.29-4.59c0-1-.42-1.28-1.18-1.28-1,0-1.64.53-2,2.43a5.76,5.76,0,0,0-.13,1.14c0,1,.42,1.29,1.18,1.29,1,0,1.64-.54,2-2.43a5.77,5.77,0,0,0,.13-1.15" />
                    <path className="cls-4"
                        d="M447.08,370.6c-1.45,0-2.31-.58-2.31-2.2a6.56,6.56,0,0,1,.12-1.22c.46-2.51,1.67-3.44,3.44-3.44,1.45,0,2.31.58,2.31,2.2a6.64,6.64,0,0,1-.12,1.22c-.46,2.51-1.67,3.44-3.44,3.44m2.29-4.59c0-1-.42-1.28-1.18-1.28-1,0-1.64.53-2,2.43a5.76,5.76,0,0,0-.13,1.14c0,1,.42,1.29,1.18,1.29,1,0,1.64-.54,2-2.43a5.77,5.77,0,0,0,.13-1.15" />
                    <polygon className="cls-4" points="452.81 370.4 451.55 370.4 453.43 360.7 454.69 360.7 452.81 370.4" />
                    <path className="cls-4"
                        d="M454.9,370.4l1.25-6.47h1.26l-1.25,6.47Zm2.16-7.86c-.49,0-.67-.11-.67-.43a1.5,1.5,0,0,1,0-.35c.11-.57.28-.74.92-.74.49,0,.67.11.67.43a1.55,1.55,0,0,1,0,.34c-.11.57-.29.75-.92.75" />
                    <path className="cls-4"
                        d="M462.34,370.4l.79-4.17a2.85,2.85,0,0,0,.08-.62c0-.53-.25-.78-.79-.78a3.21,3.21,0,0,0-2.14,1.3l-.82,4.26H458.2l1.25-6.47h1.05l-.08.91a3.69,3.69,0,0,1,2.48-1.05,1.39,1.39,0,0,1,1.6,1.55,4.56,4.56,0,0,1-.09.78l-.82,4.29Z" />
                    <path className="cls-4"
                        d="M469.66,369.6h0a3.44,3.44,0,0,1-2.17,1c-1.07,0-1.75-.68-1.75-2,0-2.93,1.58-4.72,3.52-4.72a2.62,2.62,0,0,1,1.55.47l.11-.33h1l-1.31,6.76c-.37,1.94-1.45,2.68-3.41,2.68a7.45,7.45,0,0,1-1.95-.23l.21-1a7.56,7.56,0,0,0,1.81.21,1.81,1.81,0,0,0,2.06-1.7,10.62,10.62,0,0,1,.29-1.07m-1.77-.1a3.06,3.06,0,0,0,2-1.29l.58-3a2,2,0,0,0-1.21-.42c-1.33,0-2.21,1.67-2.21,3.51,0,.79.32,1.19.87,1.19" />
                    <path className="cls-4"
                        d="M477.44,370.55a7.48,7.48,0,0,1-1.67-.21l.18-1a6.45,6.45,0,0,0,1.54.2c.85,0,1.39-.33,1.39-1,0-.39-.22-.58-1.08-1.08s-1.34-.93-1.34-1.76c0-1.33.93-2,2.59-2a6.2,6.2,0,0,1,1.33.17l-.2.95a6.25,6.25,0,0,0-1.22-.14c-.84,0-1.29.29-1.29.88,0,.41.25.6,1,1,1.12.66,1.45,1,1.45,1.74,0,1.49-1.07,2.15-2.65,2.15" />
                    <path className="cls-4"
                        d="M487.23,363.93l-3.94,7.27c-.92,1.71-1.46,2.15-2.79,2.15a3.41,3.41,0,0,1-.92-.12l.18-.94a5.35,5.35,0,0,0,.79.06c.57,0,1-.17,1.51-1.15l.43-.79h-.37l-.91-6.47h1.33l.42,3.22c.09.81.13,1.34.17,2.18h0c.37-.83.62-1.37,1-2.18l1.67-3.22h1.38Z" />
                    <path className="cls-4"
                        d="M488.52,370.55a7.48,7.48,0,0,1-1.67-.21l.18-1a6.45,6.45,0,0,0,1.54.2c.85,0,1.39-.33,1.39-1,0-.39-.22-.58-1.08-1.08s-1.34-.93-1.34-1.76c0-1.33.93-2,2.59-2a6.2,6.2,0,0,1,1.33.17l-.2.95a6.34,6.34,0,0,0-1.22-.14c-.84,0-1.29.29-1.29.88,0,.41.25.6,1,1,1.12.66,1.45,1,1.45,1.74,0,1.49-1.07,2.15-2.65,2.15" />
                    <path className="cls-4"
                        d="M495.1,370.4a6.09,6.09,0,0,1-1.3.14c-.93,0-1.39-.3-1.39-1.13a4.76,4.76,0,0,1,.06-.58l.76-3.93h-1l.2-1h.95l.4-2,1.3-.18-.42,2.2h1.48l-.2,1h-1.49l-.74,3.8a2,2,0,0,0,0,.38c0,.37.16.47.49.47a3.56,3.56,0,0,0,.86-.11Z" />
                    <path className="cls-4"
                        d="M497.71,367.62a4.76,4.76,0,0,0-.09.79c0,.86.39,1.14,1.25,1.14a6.54,6.54,0,0,0,1.91-.28l0,1a6.9,6.9,0,0,1-2.14.31c-1.49,0-2.3-.48-2.3-2.1a7.87,7.87,0,0,1,.12-1.24c.43-2.5,1.71-3.47,3.42-3.47,1.26,0,2,.58,2,1.56,0,1.59-1.39,2.33-4.22,2.33m3-2.16c0-.46-.32-.74-1-.74-.92,0-1.55.57-1.88,2,1.92,0,2.85-.36,2.85-1.28" />
                    <path className="cls-4"
                        d="M510.43,370.4l.8-4.17a3.5,3.5,0,0,0,.06-.62c0-.53-.22-.78-.71-.78a3.09,3.09,0,0,0-2,1.3l-.82,4.26h-1.26l.8-4.17a3.5,3.5,0,0,0,.06-.62c0-.53-.21-.78-.72-.78a3.09,3.09,0,0,0-2,1.3l-.82,4.26h-1.26l1.25-6.47h1l-.08.91a3.48,3.48,0,0,1,2.38-1.05c.93,0,1.39.42,1.49,1.13a3.63,3.63,0,0,1,2.47-1.13,1.37,1.37,0,0,1,1.55,1.55,4.56,4.56,0,0,1-.09.78l-.83,4.29h-1.24Z" />
                    <path className="cls-4"
                        d="M405.82,40.59c-.32,0-.44-.14-.44-.46v-9.4c0-.32.12-.45.44-.45H411c.32,0,.45.11.45.42v1.18c0,.32-.14.44-.45.44h-3.12v2h2.88c.32,0,.45.11.45.42v1.11c0,.32-.14.44-.45.44h-2.88v2.27h3.27c.32,0,.46.11.46.42v1.18c0,.32-.14.44-.46.44h-5.33Z" />
                    <path className="cls-4"
                        d="M415.48,37.68c0,.7.62,1,1.36,1s1.38-.29,1.38-1V30.74c0-.32.12-.45.44-.45h1.64c.32,0,.42.14.42.45v7.38c0,1.3-1,2.59-3.88,2.59S413,39.42,413,38.12V30.74c0-.32.12-.45.44-.45h1.64c.32,0,.42.14.42.45v6.94Z" />
                    <path className="cls-4"
                        d="M428.45,36.4l1.49,3.73c.11.27-.09.46-.42.46h-1.64c-.29,0-.35-.24-.44-.46l-1.33-3.35H425v3.35c0,.32-.11.46-.42.46h-1.64c-.32,0-.44-.14-.44-.46v-9.4c0-.32.12-.46.44-.46h3.27c2.85,0,3.85,1.29,3.85,2.59v1.32a2.34,2.34,0,0,1-1.58,2.22M425,32.33v2.41h1.21c.74,0,1.38-.29,1.38-1v-.44c0-.7-.64-1-1.38-1H425Z" />
                    <path className="cls-4"
                        d="M431.47,32.75c0-1.3,1-2.59,3.87-2.59s3.88,1.29,3.88,2.59v5.36c0,1.3-1,2.59-3.88,2.59s-3.87-1.29-3.87-2.59Zm5.25.44c0-.7-.64-1-1.38-1s-1.36.29-1.36,1v4.49c0,.7.62,1,1.36,1s1.38-.29,1.38-1Z" />
                    <path className="cls-4"
                        d="M445.62,33.08c0-.7-.48-.95-1.18-.95s-1.2.26-1.2.95v.15a.69.69,0,0,0,.48.65l2.55.86A2.45,2.45,0,0,1,448.16,37v1.08c0,1.3-.92,2.59-3.77,2.59s-3.8-1.29-3.8-2.59v-1c0-.32.12-.46.44-.46h1.58c.32,0,.42.14.42.46v.68c0,.7.59.95,1.33.95s1.35-.26,1.35-.95v-.3c0-.55-.73-.8-1.3-1l-2.18-.82a2.27,2.27,0,0,1-1.41-2v-.88c0-1.3.79-2.59,3.59-2.59s3.65,1.29,3.65,2.59v.56c0,.32-.12.45-.44.45H446c-.32,0-.42-.14-.42-.45v-.21Z" />
                    <path className="cls-4"
                        d="M456.47,38.37c0,1.24-.74,2.33-3.35,2.33s-3.35-1.09-3.35-2.33V32.49c0-1.24.74-2.33,3.35-2.33s3.35,1.09,3.35,2.33v.74c0,.21-.07.3-.27.3h-.88c-.21,0-.29-.09-.29-.3v-.5c0-.82-.53-1.32-1.89-1.32s-1.88.5-1.88,1.32v5.39c0,.82.51,1.32,1.88,1.32s1.89-.5,1.89-1.32v-.71c0-.2.07-.29.29-.29h.88c.2,0,.27.09.27.29Z" />
                    <path className="cls-4"
                        d="M457.88,32.49c0-1.24.85-2.33,3.44-2.33s3.46,1.09,3.46,2.33v5.88c0,1.24-.85,2.33-3.46,2.33s-3.44-1.09-3.44-2.33Zm5.41.25c0-.82-.61-1.32-2-1.32s-2,.5-2,1.32v5.39c0,.82.61,1.32,2,1.32s2-.5,2-1.32Z" />
                    <path className="cls-4"
                        d="M473.62,40.3c0,.2-.06.29-.27.29h-.84a.27.27,0,0,1-.27-.14l-3.56-6.24c-.33-.56-.51-1-.55-1.09V40.3c0,.2-.08.29-.27.29H467c-.2,0-.27-.09-.27-.29V30.57c0-.2.08-.29.27-.29h.85a.26.26,0,0,1,.24.12l3.65,6.38c.21.36.47,1,.47,1V30.57c0-.2.09-.29.29-.29h.83c.21,0,.27.09.27.29V40.3Z" />
                    <path className="cls-4"
                        d="M481.34,30.28c.2,0,.29.08.29.29v.68c0,.21-.09.29-.29.29h-2.42v8.74c0,.21-.08.3-.27.3h-.94c-.2,0-.27-.09-.27-.3V31.54H475c-.21,0-.29-.08-.29-.29v-.68c0-.21.08-.29.29-.29Z" />
                    <path className="cls-4"
                        d="M484.22,36.51v3.77c0,.21-.06.3-.27.3H483c-.2,0-.27-.09-.27-.3v-9.7c0-.21.08-.3.27-.3h3.08c2.61,0,3.44,1.09,3.44,2.33v1.58a2.11,2.11,0,0,1-1.77,2.12l1.82,4c.08.18-.06.3-.27.3h-.91c-.21,0-.24-.12-.33-.3l-1.71-3.77h-2.16Zm0-5v3.71h1.88c1.36,0,2-.49,2-1.32V32.85c0-.82-.61-1.32-2-1.32h-1.88Z" />
                    <path className="cls-4"
                        d="M491.22,32.49c0-1.24.85-2.33,3.44-2.33s3.46,1.09,3.46,2.33v5.88c0,1.24-.85,2.33-3.46,2.33s-3.44-1.09-3.44-2.33Zm5.42.25c0-.82-.61-1.32-2-1.32s-1.95.5-1.95,1.32v5.39c0,.82.61,1.32,1.95,1.32s2-.5,2-1.32Z" />
                    <path className="cls-4"
                        d="M501.56,39.33h3.88c.2,0,.29.06.29.27v.7c0,.21-.09.29-.29.29h-5.12c-.17,0-.24-.11-.24-.3v-9.7c0-.21.08-.3.29-.3h.92c.2,0,.27.09.27.3Z" />
                    {/*data.change === "TSM15" ? (<text className={"white"} x="165" y="257">{active.TSM15}</text>)
    : (<text className={active.SBB_QHW === 1 ? "red" : "green"} x="165" y="257">{active.TSM15}</text>)*/}


                    <path className="cls-5" d="M124.8,203.28H276.34l8.19,8.2a84.34,84.34,0,0,1-167.71-.21Z" />
                    <path className="cls-6" d="M149.71,226.73l-6.37-11-6.25,10.79a7.68,7.68,0,1,0,12.62.25Z" />

                    {/*data.change === "TSM10" ? (<text className={"white"} x="165" y="187">{active.TSM10}</text>)
    : (<text className={colorTemp(active.SBB_QHT, active.SBB_QHL)} x="165" y="187">{`${active.TSM10}`}</text>)*/}


                    <path className="cls-5" d="M276.33,198.09H124.8l-8-8a84.34,84.34,0,0,1,167.71-.2Z" />
                    <path className="cls-6"
                        d="M154.74,172.79a2.2,2.2,0,0,0-.07-.37.25.25,0,0,0,0-.07,1.23,1.23,0,0,0-.29-.49l-9.8-8.58a1.66,1.66,0,0,0-1.24-.48,1.76,1.76,0,0,0-1.24.59l-9.59,8.35a1.68,1.68,0,0,0-.63,1.28v11a1.69,1.69,0,0,0,1.69,1.69H153.1a1.69,1.69,0,0,0,1.69-1.69V173A1.32,1.32,0,0,0,154.74,172.79Z" />
                    <g className="circulation">
                        <path style={active.circulation && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M200.71,322.77a5,5,0,0,1-5-5,4.89,4.89,0,0,1,.94-2.89l3.6-6.24a.5.5,0,0,1,.43-.25h0a.5.5,0,0,1,.43.25l3.69,6.41a4.94,4.94,0,0,1-4.13,7.68Zm0-12.85-3.2,5.54a3.91,3.91,0,0,0-.77,2.35,4,4,0,1,0,7.92,0,3.86,3.86,0,0,0-.66-2.18l0,0Z" />
                        <path style={active.circulation && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M200.39,304.8l-4.54,2.88a.18.18,0,0,1-.26-.06l-.51-1a10.77,10.77,0,0,0-4.92,6.85.49.49,0,0,1-.49.38h-.11a.49.49,0,0,1-.37-.58,11.82,11.82,0,0,1,5.43-7.53l-.55-1a.19.19,0,0,1,.16-.28h6.06A.19.19,0,0,1,200.39,304.8Z" />
                        <path style={active.circulation && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M212,309.37v5.7a.26.26,0,0,1-.47.14l-2.71-4.28a.23.23,0,0,1,.1-.35l.89-.48a10.91,10.91,0,0,0-6.86-4.92.49.49,0,0,1-.37-.59.51.51,0,0,1,.59-.38,11.85,11.85,0,0,1,7.51,5.43l1-.5A.25.25,0,0,1,212,309.37Z" />
                        <path style={active.circulation && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M212.21,318.58a11.78,11.78,0,0,1-5.44,7.51l.49.92a.27.27,0,0,1-.23.4h-5.65a.27.27,0,0,1-.14-.49l4.23-2.7a.27.27,0,0,1,.37.11l.47.88a10.84,10.84,0,0,0,4.93-6.84.48.48,0,0,1,.59-.38A.5.5,0,0,1,212.21,318.58Z" />
                        <path style={active.circulation && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M198.84,327.29a.51.51,0,0,1-.49.38h-.11a11.85,11.85,0,0,1-7.51-5.43l-.92.48a.26.26,0,0,1-.38-.22v-5.7a.25.25,0,0,1,.47-.13l2.7,4.25a.29.29,0,0,1-.09.38l-.9.47a10.83,10.83,0,0,0,6.85,4.93A.49.49,0,0,1,198.84,327.29Z" />
                    </g>
                    <g className="airconditioning">
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M86.12,190.88a.71.71,0,0,1,.49.14l.1.09,9.74,8.53a.23.23,0,0,1,0,.11s0,0,0,.05a.41.41,0,0,1,0,.13.76.76,0,0,0,0,.21.1.1,0,0,1,0,0v10.95a.69.69,0,0,1-.68.69H76.35a.69.69,0,0,1-.69-.69v-11a.69.69,0,0,1,.29-.52l9.59-8.36.09-.08a.68.68,0,0,1,.49-.27m0-1a1.76,1.76,0,0,0-1.24.59l-9.59,8.35a1.68,1.68,0,0,0-.63,1.28v11a1.7,1.7,0,0,0,1.7,1.69H95.91a1.69,1.69,0,0,0,1.69-1.69v-11a.86.86,0,0,0,0-.23,2.2,2.2,0,0,0-.07-.37.25.25,0,0,0,0-.07,1.51,1.51,0,0,0-.29-.49l-9.8-8.58a1.66,1.66,0,0,0-1.25-.49" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M81.32,201.77a.22.22,0,0,1-.22-.17.23.23,0,0,1,.16-.29l1.81-.53-.49-1.84a.23.23,0,1,1,.44-.12l.55,2.06a.23.23,0,0,1-.16.28l-2,.6h-.06" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M81.32,201.77a.22.22,0,0,1-.22-.17.23.23,0,0,1,.16-.29l1.81-.53-.49-1.84a.23.23,0,1,1,.44-.12l.55,2.06a.23.23,0,0,1-.16.28l-2,.6h-.06" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M84.87,202.05l-.12,0-4.27-2.46a.23.23,0,0,1-.08-.32.22.22,0,0,1,.31-.08L85,201.62a.23.23,0,0,1,.08.32.2.2,0,0,1-.19.11" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M84.87,202.05l-.12,0-4.27-2.46a.23.23,0,0,1-.08-.32.22.22,0,0,1,.31-.08L85,201.62a.23.23,0,0,1,.08.32.2.2,0,0,1-.19.11" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M82.85,206.52h0a.24.24,0,0,1-.17-.28l.45-1.83-1.84-.49a.23.23,0,0,1,.12-.44l2.06.55a.23.23,0,0,1,.16.28l-.5,2a.24.24,0,0,1-.23.17" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M82.85,206.52h0a.24.24,0,0,1-.17-.28l.45-1.83-1.84-.49a.23.23,0,0,1,.12-.44l2.06.55a.23.23,0,0,1,.16.28l-.5,2a.24.24,0,0,1-.23.17" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M80.6,206.06a.23.23,0,0,1-.2-.11.24.24,0,0,1,.08-.32l4.26-2.46a.23.23,0,1,1,.24.4L80.72,206a.27.27,0,0,1-.12,0" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M80.6,206.06a.23.23,0,0,1-.2-.11.24.24,0,0,1,.08-.32l4.26-2.46a.23.23,0,1,1,.24.4L80.72,206a.27.27,0,0,1-.12,0" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M84.69,207.63a.2.2,0,0,1-.16-.07.24.24,0,0,1,0-.33L86,205.72a.22.22,0,0,1,.32,0l1.53,1.46a.23.23,0,0,1-.32.34l-1.37-1.3-1.35,1.35a.28.28,0,0,1-.16.06" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M84.69,207.63a.2.2,0,0,1-.16-.07.24.24,0,0,1,0-.33L86,205.72a.22.22,0,0,1,.32,0l1.53,1.46a.23.23,0,0,1-.32.34l-1.37-1.3-1.35,1.35a.28.28,0,0,1-.16.06" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M86.2,209.29a.23.23,0,0,1-.23-.23v-4.92a.23.23,0,0,1,.46,0v4.92a.23.23,0,0,1-.23.23" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M86.2,209.29a.23.23,0,0,1-.23-.23v-4.92a.23.23,0,0,1,.46,0v4.92a.23.23,0,0,1-.23.23" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M89.6,206.53a.23.23,0,0,1-.22-.17l-.55-2.06A.23.23,0,0,1,89,204l2-.59a.24.24,0,0,1,.29.16.23.23,0,0,1-.16.29l-1.81.53.5,1.84a.23.23,0,0,1-.16.28H89.6" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M89.6,206.53a.23.23,0,0,1-.22-.17l-.55-2.06A.23.23,0,0,1,89,204l2-.59a.24.24,0,0,1,.29.16.23.23,0,0,1-.16.29l-1.81.53.5,1.84a.23.23,0,0,1-.16.28H89.6" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M91.8,206.06a.27.27,0,0,1-.12,0l-4.27-2.46a.23.23,0,0,1-.08-.32.22.22,0,0,1,.31-.08l4.27,2.46A.23.23,0,0,1,92,206a.22.22,0,0,1-.19.11" />
                        <path style={active.airConditioning ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M91.8,206.06a.27.27,0,0,1-.12,0l-4.27-2.46a.23.23,0,0,1-.08-.32.22.22,0,0,1,.31-.08l4.27,2.46A.23.23,0,0,1,92,206a.22.22,0,0,1-.19.11" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M91.11,201.73h-.06L89,201.17a.23.23,0,0,1-.16-.28l.5-2a.23.23,0,0,1,.28-.17.24.24,0,0,1,.17.28l-.45,1.83,1.84.49a.22.22,0,0,1,.16.28.21.21,0,0,1-.22.18" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M91.11,201.73h-.06L89,201.17a.23.23,0,0,1-.16-.28l.5-2a.23.23,0,0,1,.28-.17.24.24,0,0,1,.17.28l-.45,1.83,1.84.49a.22.22,0,0,1,.16.28.21.21,0,0,1-.22.18" />
                        <path style={active.airConditioning ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M87.53,202.05a.22.22,0,0,1-.2-.12.24.24,0,0,1,.08-.32l4.26-2.46a.23.23,0,1,1,.24.4L87.65,202a.15.15,0,0,1-.12,0" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M87.53,202.05a.22.22,0,0,1-.2-.12.24.24,0,0,1,.08-.32l4.26-2.46a.23.23,0,1,1,.24.4L87.65,202a.15.15,0,0,1-.12,0" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M86.2,199.53a.25.25,0,0,1-.16-.06L84.51,198a.24.24,0,0,1,0-.33.25.25,0,0,1,.33,0L86.2,199l1.35-1.35a.23.23,0,1,1,.33.33l-1.51,1.51a.23.23,0,0,1-.17.07" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M86.2,199.53a.25.25,0,0,1-.16-.06L84.51,198a.24.24,0,0,1,0-.33.25.25,0,0,1,.33,0L86.2,199l1.35-1.35a.23.23,0,1,1,.33.33l-1.51,1.51a.23.23,0,0,1-.17.07" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M86.2,201.29a.23.23,0,0,1-.23-.23v-4.92a.23.23,0,0,1,.46,0v4.92a.23.23,0,0,1-.23.23" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M86.2,201.29a.23.23,0,0,1-.23-.23v-4.92a.23.23,0,0,1,.46,0v4.92a.23.23,0,0,1-.23.23" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M86.2,204.37A1.77,1.77,0,1,1,88,202.6a1.77,1.77,0,0,1-1.77,1.77m0-3.08a1.31,1.31,0,1,0,1.31,1.31,1.31,1.31,0,0,0-1.31-1.31" />
                        <path style={active.airConditioning && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-7"
                            d="M86.2,204.37A1.77,1.77,0,1,1,88,202.6a1.77,1.77,0,0,1-1.77,1.77m0-3.08a1.31,1.31,0,1,0,1.31,1.31,1.31,1.31,0,0,0-1.31-1.31" />
                    </g>
                    <g className="heating">
                        <path style={active.heating && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M315.22,190.88a.71.71,0,0,1,.49.14l.1.09,9.74,8.53a.23.23,0,0,1,.05.11s0,0,0,.05a.41.41,0,0,1,0,.13.64.64,0,0,0,0,.2.2.2,0,0,1,0,.05v10.95a.69.69,0,0,1-.68.69H305.45a.69.69,0,0,1-.69-.69v-11a.69.69,0,0,1,.29-.52l9.59-8.36.09-.08a.72.72,0,0,1,.49-.27m0-1a1.76,1.76,0,0,0-1.24.59l-9.59,8.36a1.68,1.68,0,0,0-.63,1.28v11a1.7,1.7,0,0,0,1.7,1.69H325a1.69,1.69,0,0,0,1.69-1.69v-11a.86.86,0,0,0-.05-.23,2.2,2.2,0,0,0-.07-.37.19.19,0,0,0,0-.07,1.51,1.51,0,0,0-.29-.49l-9.8-8.58a1.66,1.66,0,0,0-1.25-.48" />
                        <path style={active.heating && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M316.57,198.71a2.1,2.1,0,0,1,.33-3c-3.63.06-3.69,2.29-3.3,3.07.51,1,1,1.85.68,2.51a2.81,2.81,0,0,0-1.47-1,3.36,3.36,0,0,0-2.29.23,1.8,1.8,0,0,1,1.57.89c.4.71.13,1.46-.53,2.26a1.7,1.7,0,0,0,.43,2.38,2,2,0,0,1-1.69-1.85,1.56,1.56,0,0,0-.44,1c-.39,1.81,1.75,3.92,4.84,4.24a3.76,3.76,0,0,1-1.18-2.86,12.22,12.22,0,0,1,.53-2.22,1.34,1.34,0,0,0-.51-1.32c1,0,2,1.09,1.7,1.81.61-.13,1.89-1,.55-3a1.76,1.76,0,0,1,.13-2.42c.17,2,1.49,2.24,2.28,3.14.62.71,1.2,1.66.4,3.77a9.53,9.53,0,0,1-1.57,2.8,7.06,7.06,0,0,0,2.92-2.24,5.17,5.17,0,0,0,.88-4.66c-.67-2.31-3.35-2.27-4.26-3.54" />
                    </g>
                    <g className="heater">
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M141.5,305.18a3.76,3.76,0,0,1-.58,2.14,1.32,1.32,0,0,1-2.24.13,3.08,3.08,0,0,1-.63-1.7,6.8,6.8,0,0,1,1.48-4.89,2.56,2.56,0,0,1,2.21-1,2.62,2.62,0,0,1,1.37.47c.1.06.14.14.07.24s-.17.12-.28.05a2.36,2.36,0,0,0-1-.39,2.25,2.25,0,0,0-2,.76,5.5,5.5,0,0,0-1.07,1.82,6.13,6.13,0,0,0-.46,2.81,3,3,0,0,0,.54,1.6,1,1,0,0,0,.93.46,1.12,1.12,0,0,0,.82-.58,3.1,3.1,0,0,0,.46-1.5,6.83,6.83,0,0,0-1.11-4.07l0-.07a.16.16,0,0,1,.06-.25.15.15,0,0,1,.23.06c.2.36.42.71.6,1.09a6.74,6.74,0,0,1,.65,2.86" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-8"
                            d="M141.5,305.18a3.76,3.76,0,0,1-.58,2.14,1.32,1.32,0,0,1-2.24.13,3.08,3.08,0,0,1-.63-1.7,6.8,6.8,0,0,1,1.48-4.89,2.56,2.56,0,0,1,2.21-1,2.62,2.62,0,0,1,1.37.47c.1.06.14.14.07.24s-.17.12-.28.05a2.36,2.36,0,0,0-1-.39,2.25,2.25,0,0,0-2,.76,5.5,5.5,0,0,0-1.07,1.82,6.13,6.13,0,0,0-.46,2.81,3,3,0,0,0,.54,1.6,1,1,0,0,0,.93.46,1.12,1.12,0,0,0,.82-.58,3.1,3.1,0,0,0,.46-1.5,6.83,6.83,0,0,0-1.11-4.07l0-.07a.16.16,0,0,1,.06-.25.15.15,0,0,1,.23.06c.2.36.42.71.6,1.09a6.74,6.74,0,0,1,.65,2.86" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M145.24,305.17a3.69,3.69,0,0,1-.59,2.17,1.33,1.33,0,0,1-1.37.65,1.48,1.48,0,0,1-1-.78,4,4,0,0,1-.49-2.27,7.21,7.21,0,0,1,1.2-3.67,3.08,3.08,0,0,1,1.48-1.28,2.52,2.52,0,0,1,2.43.32.16.16,0,0,1,.05.24.17.17,0,0,1-.25.06,3.73,3.73,0,0,0-.64-.3,2.19,2.19,0,0,0-2.4.67,5.92,5.92,0,0,0-1.27,2.43,5.68,5.68,0,0,0-.25,2.17,3.1,3.1,0,0,0,.5,1.56,1.16,1.16,0,0,0,.58.47,1,1,0,0,0,1.18-.5,3.1,3.1,0,0,0,.48-1.53,6.75,6.75,0,0,0-1.1-4.07l0-.07a.17.17,0,0,1,0-.26.16.16,0,0,1,.25.05c.14.24.29.47.42.72a7.25,7.25,0,0,1,.82,3.22" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-8"
                            d="M145.24,305.17a3.69,3.69,0,0,1-.59,2.17,1.33,1.33,0,0,1-1.37.65,1.48,1.48,0,0,1-1-.78,4,4,0,0,1-.49-2.27,7.21,7.21,0,0,1,1.2-3.67,3.08,3.08,0,0,1,1.48-1.28,2.52,2.52,0,0,1,2.43.32.16.16,0,0,1,.05.24.17.17,0,0,1-.25.06,3.73,3.73,0,0,0-.64-.3,2.19,2.19,0,0,0-2.4.67,5.92,5.92,0,0,0-1.27,2.43,5.68,5.68,0,0,0-.25,2.17,3.1,3.1,0,0,0,.5,1.56,1.16,1.16,0,0,0,.58.47,1,1,0,0,0,1.18-.5,3.1,3.1,0,0,0,.48-1.53,6.75,6.75,0,0,0-1.1-4.07l0-.07a.17.17,0,0,1,0-.26.16.16,0,0,1,.25.05c.14.24.29.47.42.72a7.25,7.25,0,0,1,.82,3.22" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M149,305.21a4,4,0,0,1-.47,2,1.52,1.52,0,0,1-1,.81,1.31,1.31,0,0,1-1.36-.6,3.09,3.09,0,0,1-.58-1.62A6.87,6.87,0,0,1,147,301a2.64,2.64,0,0,1,1.78-1.1,2.59,2.59,0,0,1,1.89.45c.1.07.13.14.06.25a.17.17,0,0,1-.25.06,4.73,4.73,0,0,0-.6-.29,2.2,2.2,0,0,0-2.43.67,5.34,5.34,0,0,0-1.11,1.95,6.05,6.05,0,0,0-.4,2.63,2.83,2.83,0,0,0,.59,1.68,1,1,0,0,0,1.57,0,2.58,2.58,0,0,0,.55-1.38,6.63,6.63,0,0,0-1.09-4.39.25.25,0,0,1,0-.2.19.19,0,0,1,.14-.12.25.25,0,0,1,.18.1c.19.32.38.63.54,1a7.56,7.56,0,0,1,.65,3" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-8"
                            d="M149,305.21a4,4,0,0,1-.47,2,1.52,1.52,0,0,1-1,.81,1.31,1.31,0,0,1-1.36-.6,3.09,3.09,0,0,1-.58-1.62A6.87,6.87,0,0,1,147,301a2.64,2.64,0,0,1,1.78-1.1,2.59,2.59,0,0,1,1.89.45c.1.07.13.14.06.25a.17.17,0,0,1-.25.06,4.73,4.73,0,0,0-.6-.29,2.2,2.2,0,0,0-2.43.67,5.34,5.34,0,0,0-1.11,1.95,6.05,6.05,0,0,0-.4,2.63,2.83,2.83,0,0,0,.59,1.68,1,1,0,0,0,1.57,0,2.58,2.58,0,0,0,.55-1.38,6.63,6.63,0,0,0-1.09-4.39.25.25,0,0,1,0-.2.19.19,0,0,1,.14-.12.25.25,0,0,1,.18.1c.19.32.38.63.54,1a7.56,7.56,0,0,1,.65,3" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M137.93,299.83a2.57,2.57,0,0,1,1.44.46c.1.07.16.15.08.26s-.17.11-.28,0a2.21,2.21,0,0,0-2.54,0,.31.31,0,0,1-.2.06.23.23,0,0,1-.13-.14.24.24,0,0,1,.1-.18,2.85,2.85,0,0,1,1.53-.48" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-8"
                            d="M137.93,299.83a2.57,2.57,0,0,1,1.44.46c.1.07.16.15.08.26s-.17.11-.28,0a2.21,2.21,0,0,0-2.54,0,.31.31,0,0,1-.2.06.23.23,0,0,1-.13-.14.24.24,0,0,1,.1-.18,2.85,2.85,0,0,1,1.53-.48" />
                        <path style={active.heater && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M143.93,287.56l6.71,11.62h0a8.52,8.52,0,0,1-1.09,10.75,8.56,8.56,0,0,1-14.6-6.05,8.76,8.76,0,0,1,.41-2.62h0a8.67,8.67,0,0,1,1.19-2.35l6.59-11.38h0a.55.55,0,0,1,.17-.17.51.51,0,0,1,.62.2m5.92,12.08-6.31-10.94-6.2,10.71,0,.06a7.41,7.41,0,0,0-1.06,2.1h0a7.6,7.6,0,0,0,1.87,7.73,7.63,7.63,0,0,0,11.77-9.61s0,0,0-.05" />
                    </g>

                    <g className="antylegionella">
                        <path style={active.antylegionella && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M266.19,305.24a7.63,7.63,0,0,1-12.8,3.52,7.62,7.62,0,0,1-.81-9.84l0-.05,6.2-10.71,1.61,2.77a8.67,8.67,0,0,0,.79-.43l-2-3.48a.47.47,0,0,0-.62-.19.46.46,0,0,0-.17.17l-6.59,11.38a8.4,8.4,0,0,0-1.6,5,8.56,8.56,0,0,0,17,1.12A8.22,8.22,0,0,1,266.19,305.24Z" />
                        <path style={active.antylegionella && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M263.87,290.55a18.89,18.89,0,0,1-1.89,1.27c-.26.15-.52.3-.79.44a17.79,17.79,0,0,1-3.34,1.38,20.72,20.72,0,0,0,.26,3.32,17.63,17.63,0,0,0,.41,1.81,8.76,8.76,0,0,0,5.19,5.87.45.45,0,0,0,.3,0,8.45,8.45,0,0,0,2.42-1.46,6.75,6.75,0,0,0,.85-.84,9.86,9.86,0,0,0,2.09-4.05,16.7,16.7,0,0,0,.53-4v-.65A19,19,0,0,1,263.87,290.55Zm-.18,12.54a6.88,6.88,0,0,1-4-4.58,13.11,13.11,0,0,1-.32-1.41,16,16,0,0,1-.2-2.59,15.73,15.73,0,0,0,2.67-1.13,8.89,8.89,0,0,0,.79-.45c.37-.22.72-.47,1.07-.72Z" />
                    </g>

                    <g className="launch">
                        <path style={!active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M152.92,104.88v3.22a.69.69,0,0,1-.68.7H132.69a.69.69,0,0,1-.69-.7v-11a.65.65,0,0,1,.29-.52l9.59-8.36a.65.65,0,0,1,.09-.08.74.74,0,0,1,.49-.28A.65.65,0,0,1,143,88l.1.09,1.61,1.41a5.52,5.52,0,0,1,1-.49l-1.92-1.68a1.7,1.7,0,0,0-1.25-.49,1.76,1.76,0,0,0-1.24.59l-9.59,8.36a1.66,1.66,0,0,0-.63,1.28v11a1.69,1.69,0,0,0,1.7,1.68h19.55a1.69,1.69,0,0,0,1.69-1.68v-3.82A7.43,7.43,0,0,1,152.92,104.88Zm0-4.51v1.38a6.75,6.75,0,0,0,1-1V98.64A5,5,0,0,1,152.92,100.37Z" />
                        <path style={!active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-9"
                            d="M155.25,96.84a6.31,6.31,0,0,1-1.31,3.86,6.75,6.75,0,0,1-1,1A6.32,6.32,0,1,1,145.4,91.6a.5.5,0,0,1,.69.15.48.48,0,0,1-.13.68,5.32,5.32,0,1,0,7,7.94,5,5,0,0,0,1-1.73,5.09,5.09,0,0,0,.32-1.8A5.33,5.33,0,0,0,152,92.51a.5.5,0,1,1,.57-.81A6.32,6.32,0,0,1,155.25,96.84Z" />
                        <path style={!active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M155.25,96.84a6.31,6.31,0,0,1-1.31,3.86,6.75,6.75,0,0,1-1,1A6.32,6.32,0,1,1,145.4,91.6a.5.5,0,0,1,.69.15.48.48,0,0,1-.13.68,5.32,5.32,0,1,0,7,7.94,5,5,0,0,0,1-1.73,5.09,5.09,0,0,0,.32-1.8A5.33,5.33,0,0,0,152,92.51a.5.5,0,1,1,.57-.81A6.32,6.32,0,0,1,155.25,96.84Z" />
                        <path style={!active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4" d="M149.47,89.6v8.07a.5.5,0,1,1-1,0V89.6a.5.5,0,0,1,1,0Z" />
                    </g>

                    <g className="time">
                        <path style={active.mode.SXF_TIME && active.mode.SBF_OOF? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M269.57,104.88v3.22a.69.69,0,0,1-.68.7H249.34a.69.69,0,0,1-.69-.7v-11a.65.65,0,0,1,.29-.52l9.59-8.36a.65.65,0,0,1,.09-.08.78.78,0,0,1,.5-.28.65.65,0,0,1,.49.15.38.38,0,0,1,.09.09l1.61,1.41a5.28,5.28,0,0,1,1-.49l-1.92-1.68a1.69,1.69,0,0,0-1.25-.49,1.76,1.76,0,0,0-1.24.59l-9.59,8.36a1.66,1.66,0,0,0-.63,1.28v11a1.69,1.69,0,0,0,1.7,1.68h19.55a1.69,1.69,0,0,0,1.69-1.68v-3.82A7.15,7.15,0,0,1,269.57,104.88Z" />
                        <path style={active.mode.SXF_TIME && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M268.1,97.09a.49.49,0,0,1-.5.5h-2a.49.49,0,0,1-.49-.5V93.63a.49.49,0,0,1,.35-.47.32.32,0,0,1,.14,0,.5.5,0,0,1,.5.5v3h1.47A.5.5,0,0,1,268.1,97.09Z" />
                        <path style={active.mode.SXF_TIME && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M271.4,94.37a6.29,6.29,0,0,0-7.23-3.71,5.92,5.92,0,0,0-1,.3.38.38,0,0,1-.13.06A6.29,6.29,0,0,0,268,102.59a6.38,6.38,0,0,0,1.58-1,6.06,6.06,0,0,0,1-1.06A6.32,6.32,0,0,0,271.4,94.37Zm-.8,4.09a5.21,5.21,0,0,1-1,1.8,5.42,5.42,0,0,1-2,1.41,5.3,5.3,0,0,1-4.05-9.79l.34-.12a5,5,0,0,1,1.24-.25l.44,0a5.3,5.3,0,0,1,4.9,3.26A5.23,5.23,0,0,1,270.6,98.46Z" />
                    </g>

                    <g className="away">
                        <path style={active.mode.SXF_AWA && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M291.16,137.32l9.59-8.36a.65.65,0,0,0,.09-.08.74.74,0,0,1,.49-.28.65.65,0,0,1,.49.15l.1.09,5.17,4.53.72-.7-5.23-4.58a1.66,1.66,0,0,0-1.25-.49,1.73,1.73,0,0,0-1.24.6l-9.59,8.35a1.65,1.65,0,0,0-.63,1.28v11a1.7,1.7,0,0,0,1.7,1.7h10.8l.37-1H291.56a.69.69,0,0,1-.69-.7v-11A.65.65,0,0,1,291.16,137.32Z" />
                        <path style={active.mode.SXF_AWA && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-9" d="M305.29,150.54h0Z" />
                        <path style={active.mode.SXF_AWA && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M311.57,134.88a1.34,1.34,0,0,1-.28.83,1.31,1.31,0,0,1-.91.54h-.19a1.38,1.38,0,0,1-1.38-1.38h0a1.3,1.3,0,0,1,.42-1,1.35,1.35,0,0,1,1-.4A1.38,1.38,0,0,1,311.57,134.88Z" />
                        <path style={active.mode.SXF_AWA && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M314.56,142.1a.61.61,0,0,1-.6.61.85.85,0,0,1-.24-.05.91.91,0,0,1-.16-.11l-.75-.51-1-.71-.2-.15a.55.55,0,0,1-.32-.33l-.49-1-.66,2.91,1.67,2,.65.78s0,0,0,0a1,1,0,0,1,.2.51l.13.7.5,2.67a.43.43,0,0,1,0,.16.9.9,0,0,1-.9.88.88.88,0,0,1-.55-.2.74.74,0,0,1-.3-.39.94.94,0,0,0,0-.09v0a.3.3,0,0,1,0-.1l-.06-.3-.53-2.87-2-2.46-.51,2.3a.23.23,0,0,1,0,.1.45.45,0,0,1-.06.14.42.42,0,0,1,0,.1l-1.65,2.79-.31.52a.86.86,0,0,1-.67.47h-.31a.9.9,0,0,1-.72-.88.41.41,0,0,1,0-.11.8.8,0,0,1,.09-.31l0-.06,1.95-3.27.62-2.82a.69.69,0,0,1,0-.13l.55-2.42.26-1.2h0l-1.07.88-.62,2.73a.63.63,0,0,1,0,.09.59.59,0,0,1-.54.34.61.61,0,0,1-.6-.61.58.58,0,0,1,0-.19l.62-2.74a.58.58,0,0,1,.29-.47l2.55-2.12a1.76,1.76,0,0,1,.3-.24,1.29,1.29,0,0,1,.7-.19,1.37,1.37,0,0,1,1.25.8l.82,1.62.5,1,.52.37,1.45,1,.07,0h0l.06.07A.76.76,0,0,1,314.56,142.1Z" />
                    </g>

                    <g className="auto">
                        <path style={!active.mode.SXF_ECO && !active.mode.SXF_AWA && !active.mode.SXF_TIME && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4" d="M305.6,150.54h0Z" />
                        <path style={!active.mode.SXF_ECO && !active.mode.SXF_AWA && !active.mode.SXF_TIME && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M111.62,137.59a2.2,2.2,0,0,0-.07-.37.21.21,0,0,0,0-.06,1.33,1.33,0,0,0-.29-.49l-9.8-8.58a1.66,1.66,0,0,0-1.25-.49,1.73,1.73,0,0,0-1.24.6l-9.59,8.35a1.65,1.65,0,0,0-.63,1.28v11a1.7,1.7,0,0,0,1.7,1.7h6.11a2.31,2.31,0,0,1-.35-1H90.41a.69.69,0,0,1-.69-.7v-11a.65.65,0,0,1,.29-.52L99.6,129a.65.65,0,0,0,.09-.08.78.78,0,0,1,.5-.28.65.65,0,0,1,.49.15l.1.09,9.74,8.53a.15.15,0,0,1,0,.1.2.2,0,0,0,0,.05.44.44,0,0,1,0,.14.58.58,0,0,0,0,.19.22.22,0,0,1,0,.06v10.94a.69.69,0,0,1-.68.7h-5.08a2.31,2.31,0,0,1-.35,1H110a1.7,1.7,0,0,0,1.69-1.7v-11A1,1,0,0,0,111.62,137.59Z" />
                        <path style={!active.mode.SXF_ECO && !active.mode.SXF_AWA && !active.mode.SXF_TIME && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4" d="M100.52,136.14a1.38,1.38,0,1,0-1.38-1.38,1.38,1.38,0,0,0,1.38,1.38Z" />
                        <path style={!active.mode.SXF_ECO && !active.mode.SXF_AWA && !active.mode.SXF_TIME && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-10"
                            d="M104.16,143.54v0a.6.6,0,0,1-1.2,0v-4.25h-.41v10.53a.87.87,0,0,1-.32.69.9.9,0,0,1-1.18,0,.87.87,0,0,1-.32-.69v-6.06h-.42v5.76l-.29,1a.9.9,0,0,1-1.18,0,1,1,0,0,1-.3-.54.45.45,0,0,1,0-.16V139.32h-.39v4.25a.6.6,0,0,1-1.2,0v-5.35a.5.5,0,0,1,0-.17,1.37,1.37,0,0,1,1.35-1.1h4.61a1.36,1.36,0,0,1,1.22,1.12.78.78,0,0,1,0,.16v5.27S104.14,143.52,104.16,143.54Z" />
                    </g>

                    <g className="eco">
                        <path style={active.mode.SXF_ECO && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M211.17,90.51v5.75a.7.7,0,0,1-.68.69h-7l.27,1h6.73a1.69,1.69,0,0,0,1.69-1.68V89.16A4.36,4.36,0,0,1,211.17,90.51Zm-9.2-15a1.63,1.63,0,0,0-1.25-.49,1.76,1.76,0,0,0-1.24.59L189.89,84a1.67,1.67,0,0,0-.63,1.27v11a1.69,1.69,0,0,0,1.7,1.68h7.49l-.23-1h-7.28a.7.7,0,0,1-.69-.69v-11a.72.72,0,0,1,.29-.53l9.59-8.36.09-.08a.72.72,0,0,1,.5-.27.78.78,0,0,1,.49.13l.09.09,3.38,3a9.65,9.65,0,0,0,1.07-.38Z" />
                        <path style={active.mode.SXF_ECO && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-9"
                            d="M203.22,89.71a2.73,2.73,0,0,1-.25-.2,5.63,5.63,0,0,1-.57-.52l-.07-.06s0,0,0,0,.6.6.61.61Z" />
                        <path style={active.mode.SXF_ECO && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M199.24,90.67c1.88-4.85-6.29-4.12-6.29-4.12A16.76,16.76,0,0,1,194.6,90c.68,2.06,3.56,3.5,4.46,1.31a14.44,14.44,0,0,0-3.21-2.89A7,7,0,0,1,199.24,90.67Zm3.13-1.78s0,0,0,0l.07.06a5.63,5.63,0,0,0,.57.52h0S202.37,88.91,202.37,88.89Z" />
                        <path style={active.mode.SXF_ECO && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M201.48,97c.05.46.1.81.13,1h-1c0-.33-.06-.66-.09-1,0-.11,0-.2,0-.29a13.77,13.77,0,0,0-1.38-5.4c.1-.3.14-.41.18-.58a13.46,13.46,0,0,1,1.17,1.91,6.51,6.51,0,0,1,1.91-3.66l.07.06a5.63,5.63,0,0,0,.57.52c-1.74,1.73-1.69,5.23-1.51,7.16C201.46,96.76,201.47,96.85,201.48,97Z" />
                        <path style={active.mode.SXF_ECO && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4"
                            d="M212.1,84.71a10,10,0,0,1-.93,2.68,7.72,7.72,0,0,1-.9,1.37C207.7,91.84,205,91,203.64,90l-.11-.08-.31-.25L203,89.5a36.56,36.56,0,0,1,3.93-3.63,11,11,0,0,0,2.33-2.69,6.57,6.57,0,0,0,.46-.92,5.34,5.34,0,0,0,.33-2.34,8.24,8.24,0,0,1-.71,2,7.52,7.52,0,0,1-.53.87,12.76,12.76,0,0,1-2.38,2.53,24.77,24.77,0,0,0-4,3.56c-2.68-3.09-1.26-6.83,1.67-7.93.67-.25,1.3-.44,1.91-.66a8.24,8.24,0,0,0,4.66-4.16A11.46,11.46,0,0,1,212.1,84.71Z" />
                    </g>

                    <g className="warning">
                        <path style={active.error && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-11"
                            d="M111.2,265.82h-18a2.27,2.27,0,0,1-2-1.13,2.9,2.9,0,0,1,.09-2.86l9-16.22a2.19,2.19,0,0,1,1.85-1.27,2.48,2.48,0,0,1,2,1.44l9,16a2.93,2.93,0,0,1,.08,2.93A2.26,2.26,0,0,1,111.2,265.82Zm-9.11-20.66a1.43,1.43,0,0,0-1.15.86l-9,16.22a2.07,2.07,0,0,0-.1,2.05,1.48,1.48,0,0,0,1.34.73h18a1.47,1.47,0,0,0,1.33-.74,2.15,2.15,0,0,0-.08-2.12h0l-9-16A1.71,1.71,0,0,0,102.09,245.16Z" />
                        <path style={active.error && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4" d="M100.63,251.06l1,7.13h1l.92-7.31C103.63,248.49,100.61,248.62,100.63,251.06Z" />
                        <path style={active.error && active.mode.SBF_OOF ? { fill: "#fff" } : { fill: "#58595b" }} className="cls-4" d="M100.66,261.41a1.46,1.46,0,1,0,1.46-1.46A1.46,1.46,0,0,0,100.66,261.41Z" />
                    </g>
                </g>
            </g>
        </svg>
        {active.mode.SBF_OOF ? 
            <div className="dom">
                {active.SXF_TOE === 1 ?
                    <React.Fragment>
                        {data.change === "TSM10" ? (<span className={"white first-char"}>{active.TSM10.toString().length === 2? `${active.TSM10}`.slice(0, 1) : "0"}</span>)
                            : (<span className={`${colorTemp(active.SBB_QHT, active.SBB_QHL)} first-char`}>{active.TSM10.toString().length === 2? `${active.TSM10}`.slice(0, 1) : "0"}</span>)}

                        {data.change === "TSM10" ? (<span className={"white second-char"}>{active.TSM10.toString().length === 1? `${active.TSM10}`.slice(0, 1) : `${active.TSM10}`.slice(1, 2)}</span>)
                            : (<span className={`${colorTemp(active.SBB_QHT, active.SBB_QHL)} second-char`}>{active.TSM10.toString().length === 1? `${active.TSM10}`.slice(0, 1) : `${active.TSM10}`.slice(1, 2)}</span>)}
                    </React.Fragment>
                : <React.Fragment><span className={"white first-char"}>O</span><span className={"white second-char"}>F</span></React.Fragment>
                }
            </div>
        : null }
        
        {active.mode.SBF_OOF ? 
            <div className="woda">
                {active.SBF_DHS === 1 ?
                    <React.Fragment>
                        {data.change === "TSM15" ? (<span className={"white first-char"}>{active.TSM15.toString().length === 2? `${active.TSM15}`.slice(0, 1) : "0"}</span>)
                            : (<span className={active.SBB_QHW === 1 ? "red first-char" : "green first-char"}>{active.TSM15.toString().length === 2? `${active.TSM15}`.slice(0, 1) : "0"}</span>)}

                        {data.change === "TSM15" ? (<span className={"white second-char"}>{active.TSM15.toString().length === 1? `${active.TSM15}`.slice(0, 1) : `${active.TSM15}`.slice(1, 2)}</span>)
                            : (<span className={active.SBB_QHW === 1 ? "red second-char" : "green second-char"}>{active.TSM15.toString().length === 1? `${active.TSM15}`.slice(0, 1) : `${active.TSM15}`.slice(1, 2)}</span>)}
                    </React.Fragment>
                : <React.Fragment><span className={"white first-char"}>O</span><span className={"white second-char"}>F</span></React.Fragment>
                }
            </div>
        : null }

        </div>
    )
}

export default Panel