import React, {useState, useEffect} from 'react'
import { mainStyleClasses } from '../../theme'
import { AppBar} from '@material-ui/core'
import { EcoMode, TimeMode, AwayMode } from './components'
import { TabPanel, Tab, Tabs } from '../../components'

const Mode = ({ settings, setPublish}) => {
  const classes = mainStyleClasses()
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener('popstate', function (event){
  //       window.history.pushState(null, document.title,  window.location.href);
  //   });
  // }, []);
  
  return (
    <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
      <AppBar position="static" style={{boxShadow: "none"}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="ECO" />
          <Tab label="AWAY" />
          <Tab label="TIME" />
        </Tabs> 
      </AppBar>
      <TabPanel value={value} index={0}>
        <EcoMode eco={settings.eco} setPublish={setPublish} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AwayMode away={settings.away} setPublish={setPublish} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TimeMode time={settings.time} setPublish={setPublish} />
      </TabPanel>
      {/*<Grid container>
        <Grid item xs={4}>
          <Typography variant="body2">Tryby pracy urządzenia:</Typography>
          <Typography variant="body1">(Wybierz, żeby zobaczyć szczegóły)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Button variant="contained" color="primary" component={Link} to="/modes/ecomode">ECO</Button>
          <Button variant="contained" color="primary" style={{ margin: "5px 20px" }} component={Link} to="/modes/awaymode">AWAY</Button>
          <Button variant="contained" color="primary" component={Link} to="/modes/timemode">TIME</Button>
        </Grid>
      </Grid>

      <Switch>
        <Route path="/modes/ecomode"><EcoMode eco={settings.eco} setPublish={setPublish}/></Route>
        <Route path="/modes/awaymode"><AwayMode away={settings.away} setPublish={setPublish} /></Route>
        <Route path="/modes/timemode"><TimeMode time={settings.time} setPublish={setPublish} /></Route>
      </Switch>*/}
    </div>
  )
}


export default Mode