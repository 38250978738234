import React, { useReducer } from 'react'
import { Button, Typography, Grid, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import wspolpracaco0 from '../../../../img/wspolpracaco0.svg'
import wspolpracaco1 from '../../../../img/wspolpracaco1.svg'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: action.payload };
    }
}
const BufferCO = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useReducer(switchOption, settings)
    
    const handleSwitchValue = name => (e, newValue) => {
        if (!newValue || parseInt(newValue) === 0) {
            setItemValue({ type: name, payload: 0 })
            setPublish({ [name]: 0 })
        } else {
            setItemValue({ type: name, payload: 1 })
            setPublish({ [name]: 1 })
        }
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Wybór sposobu współpracy z instalacją co</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                <RadioGroup name="SWEXT_BUF" value={parseInt(itemValue.SWEXT_BUF)} onChange={handleSwitchValue("SWEXT_BUF")}>
                <Grid container>
                    <Grid item xs={6}>
                        <label style={{ display: "block", height: "100%"}}>
                            <img alt="Euros Energy" src={wspolpracaco0} style={{ maxWidth: "50%", height: "180px", margin: "0 auto" }} /><br />
                            <FormControlLabel value={0} control={<Radio color="primary" />} />
                        </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label style={{ display: "block", height: "100%"}}>
                            <img alt="Euros Energy" src={wspolpracaco1} style={{ maxWidth: "50%", height: "180px" }} /><br />
                            <FormControlLabel value={1} control={<Radio color="primary" />} />
                        </label>
                    </Grid>
                </Grid>
                </RadioGroup>
              </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default BufferCO