import React from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const ElectricalConnections = ({ required }) => {
    return (
        <React.Fragment>
            <Typography variant='h2'>III. Wykonanie podłączeń elektrycznych</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>3a. Sprawdzenie wymaganego przekroju żył przewodu zasilającego</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Przekrój odpowiedni"
                                control={
                                    <Field
                                        name="wireCrossSection"
                                        component={Radio}
                                        type="radio"
                                        value="odpowiedni"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Przekrój zbyt mały"
                                control={
                                    <Field
                                        name="wireCrossSection"
                                        component={Radio}
                                        type="radio"
                                        value="za mały"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="wireCrossSection" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>3b. Podłączenie zasilania</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="powerConnection"
                                        component={Radio}
                                        type="radio"
                                        value="tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="powerConnection"
                                        component={Radio}
                                        type="radio"
                                        value="nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="powerConnection" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>3c. Sprawdzenie zalecanego zabezpieczenia linii zasilającej pompę ciepła w rozdzielni głównej budynku</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Odpowiedni typ"
                                control={
                                    <Field
                                        name="powerLineProtection"
                                        component={Radio}
                                        type="radio"
                                        value="Odpowiedni typ"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Zbyt mały prąd nominalny"
                                control={
                                    <Field
                                        name="powerLineProtection"
                                        component={Radio}
                                        type="radio"
                                        value="Zbyt mały prąd nominalny"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nieodpowiednia charakterystyka"
                                control={
                                    <Field
                                        name="powerLineProtection"
                                        component={Radio}
                                        type="radio"
                                        value="Nieodpowiednia charakterystyka"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="powerLineProtection" />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ElectricalConnections;