import {PublishSetupModule} from './tokenBuild'

export const functionPublish = (client, Data, installation, device, publishDevice, setPublishDevice) => {
  let publishDataToken1 = Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
    if (publishDevice !== null) {
      client.publish(`${installation}/App`, PublishSetupModule(JSON.stringify({
        Data,
         "Token": publishDataToken1
       }), publishDevice.Name, publishDevice.Type, publishDevice.Version))
       console.log(`${installation}/App`, PublishSetupModule(JSON.stringify({
         Data,
          "Token": publishDataToken1
        }), publishDevice.Name, publishDevice.Type, publishDevice.Version))
    } else {
      client.publish(`${installation}/App`, PublishSetupModule(JSON.stringify({
      Data,
        "Token": publishDataToken1
      }), device.Name, device.Type, device.Version))
      console.log(`${installation}/App`, PublishSetupModule(JSON.stringify({
        Data,
        "Token": publishDataToken1
      }), device.Name, device.Type, device.Version))
    }
     setPublishDevice(null)
  }