import React, { useEffect, useState } from 'react'

import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { mainStyleClasses } from '../../../../theme'

import { registerDictionary } from '../../../../data/dataDictionary'
import { dataRange } from '../../../../data/dataRange'

import { CarouselInputMulti } from '../../../../components'
import { SwitchControl } from '../../../../components'

// const switchOption = (state, action) => {
//     if (action.case === "UPDATE_FROM_BROKER") {
//         return (action.payload)
//     } else {
//         return { ...state, [action.type]: action.payload };
//     }
// }

const DetailsBox = ({ item, name, setPublish, communicationColor, data }) => {

    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(item)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: '', val: 0, sign: null, blok: ''})
    const dictionary = name ? registerDictionary(data? data : null)[name] : registerDictionary()
    const filteredItems = Object.entries(itemValue ? itemValue.map : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        // let minValue = typeof (dataRange()[name].min) === 'string' ? eco[dataRange()[name].min] : dataRange()[name].min
        // let maxValue = typeof (dataRange()[name].max) === 'string' ? eco[dataRange()[name].max] : dataRange()[name].max
         setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step  ? step : 0, num: num, val: val, blok: blok})
    }

    const handleClose = (blok) => {
        if(open.name === 'SPSMIN_ZAD'){ 
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok], 
                                [open.name]: open.val 
                            }, 
                            SPSMIN_ZNAK: open.sign
            })
            setPublish({ [open.name]: open.val, SPSMIN_ZNAK: open.sign })
            setOpen({ ...open, status: false })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok], 
                                [open.name]: open.val 
                            }
            })
            setPublish({ [open.name]: open.val, })
            setOpen({ ...open, status: false })
        }
    }

    const setNewValue = (name, val, sign) => {
        setOpen({
            ...open,
            val: val,
            sign: sign
        })
    }

    // const marks = (entry, item) => {
    //     return [
    //         {
    //             value: typeof (dataRange()[entry[0]].min) === "string" ? item[dataRange()[entry[0]].min] : dataRange()[entry[0]].min,
    //             label: `${typeof (dataRange()[entry[0]].min) === "string" ? item[dataRange()[entry[0]].min] : dataRange()[entry[0]].min}${dataRange()[entry[0]].symbol}`
    //         },
    //         {
    //             value: typeof (dataRange()[entry[0]].max) === "string" ? item[dataRange()[entry[0]].max] : dataRange()[entry[0]].max,
    //             label: `${typeof (dataRange()[entry[0]].max) === "string" ? item[dataRange()[entry[0]].max] : dataRange()[entry[0]].max}${dataRange()[entry[0]].symbol}`
    //         },
    //     ]
    // }
    // const valuetext = (value) => {
    //     return `${value} °C`;
    // }
   
    // const handleSliderValue = name => (e, newValue) => {
    //     setItemValue({ type: name, payload: newValue })
    // }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    // const handlePublishValue = name => {
    //     setPublish({ [name]: itemValue[name] })
    // }

    // const switchControl = (entry, item) => {
    //     switch (dataRange()[entry[0]].controller) {
    //         case 1:
    //             return (
    //                 <Grid item xs={3}>
    //                     <Switch
    //                         checked={itemValue.map[entry[0]] === 0 ? false : true}
    //                         onChange={(e) => handleSwitchValue(e.target, item)}
    //                         color="primary"
    //                         name={entry[0]}
    //                         inputProps={{ 'aria-label': 'primary checkbox' }}
    //                     />
    //                 </Grid>
    //             )
    //         case 2:
    //             let minValue = typeof (dataRange()[entry[0]].min) === "string" ? parseFloat(item[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
    //             let maxValue = typeof (dataRange()[entry[0]].max) === "string" ? parseFloat(item[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)
    //             return (
    //                 <Grid item xs={2} className={classes.textRight} onClick={() => handleClickOpen(entry[1], entry[0], dictionary.data[entry[0]], minValue, maxValue, dataRange()[entry[0]].step ? dataRange()[entry[0]].step : 1, entry[0], entry[1])}>
    //                     <Typography variant="body2" className={"carousel-input-click"}>{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]} {dataRange()[entry[0]].symbol}</Typography>
    //                 </Grid>
    //             )
    //         case 3:
    //             return (
    //                 <Grid item xs={10} style={{ margin: "20px auto 0" }}>
    //                     select
    //                 </Grid>
    //             )
    //         default:
    //             break;
    //     }
    // }
    useEffect(() => {
        let mounted = true
        setItemValue(item)
        return () => {
            mounted = false
        }
    }, [item])
   
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2">{dictionary.title}</Typography>
            </Grid>
            {item ? filteredItems.map(entry => {
                if (communicationColor === 1 && entry[1] !== undefined) {
                    return (
                        <Grid container className={classes.containerVerticalCenter} key={entry[0]}>
                            <Grid item xs={9}><Typography variant="body2" style={{ color: entry[1] === 0 ? "grey" : "red" }}>{dictionary.data[entry[0]]}</Typography></Grid>

                            {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                SwitchControl(entry, itemValue.map, handleSwitchValue, handleClickOpen, dictionary, classes, 'map') : <Grid item xs={2} className={classes.textRight}>
                                    <Typography variant="body2" style={{ color: entry[1] === 0 ? "grey" : "red" }}>{entry[1]}  {dataRange()[entry[0]].symbol} {entry[1] === 1 ? 'WYSTĘPUJE' : 'NIE WYSTĘPUJE'}</Typography></Grid>}
                        </Grid>
                    )
                } else if (communicationColor === 2 && entry[1] !== undefined) {
                    return (
                        <Grid container className={classes.containerVerticalCenter} key={entry[0]}>
                            <Grid item xs={9}><Typography variant="body2" style={{ color: entry[1] === 0 ? "grey" : "green" }}>{dictionary.data[entry[0]]}</Typography></Grid>

                            {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                SwitchControl(entry, itemValue.map, handleSwitchValue, handleClickOpen, dictionary, classes, 'map') : <Grid item xs={2} className={classes.textRight}>
                                    <Typography variant="body2" style={{ color: entry[1] === 0 ? "grey" : "green" }}>{entry[1]}  {dataRange()[entry[0]].symbol} {entry[1] === 1 ? 'WYSTĘPUJE' : 'NIE WYSTĘPUJE'}</Typography></Grid>}
                        </Grid>
                    )
                } else if (entry[1] !== undefined) {
                    return (
                        <Grid container className={classes.containerVerticalCenter} key={entry[0]}>
                            <Grid item xs={9}><Typography variant="body2">{dictionary.data[entry[0]]}</Typography></Grid>

                            {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                SwitchControl(entry, itemValue.map, handleSwitchValue, handleClickOpen, dictionary, classes, 'map') : <Grid item xs={2} className={classes.textRight}>
                                    <Typography variant="body2">{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]}  {dataRange()[entry[0]].symbol}</Typography></Grid>}
                        </Grid>
                    )
                } else {
                    return null
                }
            }) : null
            }

            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} val={open.val} step={open.step} />
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default DetailsBox