import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Button, Grid, Typography, Box, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import formatString from 'format-string-by-pattern'

import { default as fetchRegonApi } from '../../../../data/fetchRegonApi'
import { default as fetchPartnerAddEdit } from '../../../../data/fetchPartnerAddEdit'

const required = value => (value ? undefined : "Pole obowiązkowe")

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const passConfirm = pass => value => (value && value === pass ? undefined : "Hasła nie są jednakowe")
const minLengthPass = min => value => value === undefined || value.length < min ? `Hasło powinno się składać z minimum ${min}` : null

const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")

const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const validNipNumber = (anyString) => {
    const nipString = anyString.replace(/[\s \-]/gi, '')
    console.log(nipString)
    const nipWieght = [6, 5, 7, 2, 3, 4, 5, 6, 7]

    let nipSum = 0

    if (nipString.length < 10) {
        return "Podany numer jest za krótki"
    } else if (nipString.length > 10) {
        return "Podany numer jest za długi"
    } else {

        for (let i = 0; i < nipWieght.length; i++) {
            nipSum += (parseInt(nipString.slice(i, i + 1)) * nipWieght[i])
        }

        if (nipSum % 11 === parseInt(nipString.slice(9, 10))) {
            return undefined
        } else {
            return "Nieprawidłowy numer nip"
        }
    }
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const dataPack = (val) => {
    //const reg = /^(.*[^0-9]+) (([1-9][0-9]*)[/])?([1-9][0-9]*[a-cA-C]?)$/g
    //const nameCompanyReg = /^[\"]$/g

    //const street = reg.exec(data.street_address)[1]

    const pack = {
        name: val.name,
        email: val.email,
        phone: val.phone,
        // nip: val.nip,
        street: val.street,
        street_number: val.street_number,
        city: val.city,
        post_code: val.post_code,
        parent_partner_id: val.parent_partner_id,
        memo: ""
    }

    return pack
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const EditPartner = ({ handleClose, refreshPartnersList, partnersList, partner, index }) => {
    const [error, setError] = useState({ status: false, message: "" })
    const [nipStatus, setNipStatus] = useState({ status: false, company: "" })
    const [sendStatus, setSendStatus] = useState(null)
    const [partnerSelect, setPartnerSelect] = useState(1)
// console.log({index})
    useEffect(() => {
        let mounted = true
        if (partner && partner.parent_partner_id)
            setPartnerSelect(partner.parent_partner_id)
        return () => {
            mounted = false
        }
    }, [partner])

    const handlePartnerSelect = (e) => {
        setPartnerSelect(parseInt(e.target.value))
    }

    const onSubmit = async values => {
        console.log({values})
        values = { ...values, parent_partner_id: parseInt(partnerSelect) }
        fetchPartnerAddEdit(dataPack(values), "PUT", partner.id).then(response => {
            // console.log(response.status)
            if (response.status === 200 || response.status === "ok") {
                handleClose()
                refreshPartnersList()
                // setSendStatus(null)
            } else {
                setError({ status: true, message: "Wystąpił błąd" })
            }
        })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Form
                        initialValues={null}
                        onSubmit={onSubmit}
                    >
                        {({ handleSubmit, form, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h2">Edytuj dane firmy</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item xs={12} md={12}>
                                        <FormControl>
                                            <InputLabel id="parent_partner_id">Wybierz partnera</InputLabel>
                                            <Select
                                                name="parent_partner_id"
                                                labelId="parent_partner_id"
                                                id="parent_partner_id"
                                                value={partnerSelect}
                                                onChange={(e) => handlePartnerSelect(e)}
                                            >
                                                {partnersList.map(partner => <MenuItem key={partner.id} value={partner.id}>{partner.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="email"
                                            component={TextField}
                                            type="email"
                                            label="Adres e-mail"
                                            defaultValue={partner && partner.email ? partner.email : ""}
                                            fullWidth
                                            validate={composeValidators(required, formatEmail)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="phone"
                                            component={TextField}
                                            type="phone"
                                            label="Numer telefonu"
                                            defaultValue={partner && partner.phone ? partner.phone : ""}
                                            fullWidth
                                            validate={composeValidators(required, minLength(11))}
                                            parse={e => formatOnlyNumbersTel(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="nip"
                                            component={TextField}
                                            type="nip"
                                            defaultValue={partner && partner.nip ? partner.nip : ""}
                                            label="NIP"
                                            fullWidth
                                            // validate={composeValidators(required, validNipNumber)}
                                            InputProps={{
                                                readOnly: true,
                                                disabled: true,
                                              }}
                                        />
                                       {/*} <Button onClick={() => checkNip(form.getFieldState('nip'))} style={{ float: "right" }}
                                            variant="contained" color="primary"
                        >Sprawdź <ArrowForwardIosIcon style={{ color: "#ffffff", height: "15px" }} /></Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {nipStatus && nipStatus.status ? (
                                            <Typography variant="body1">
                                                <Box fontWeight="fontWeightBold">{nipStatus.company.name}</Box><br />
                                                {nipStatus.company.street_address}<br />
                                                {nipStatus.company.postal_code} {nipStatus.company.city}
                                            </Typography>
                                        ) : null}*/}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field
                                            name="name"
                                            component={TextField}
                                            type="name"
                                            label="Nazwa firmy"
                                            defaultValue={partner && partner.name ? partner.name : ""}
                                            fullWidth
                                            validate={composeValidators(required)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Field
                                            name="street"
                                            component={TextField}
                                            type="street"
                                            label="Ulica"
                                            defaultValue={partner && partner.street ? partner.street : ""}
                                            fullWidth
                                            validate={composeValidators(required)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field
                                            name="street_number"
                                            component={TextField}
                                            type="street_number"
                                            label="Numer ulicy"
                                            defaultValue={partner && partner.street_number ? partner.street_number : ""}
                                            fullWidth
                                            validate={composeValidators(required)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="post_code"
                                            component={TextField}
                                            type="post_code"
                                            label="Kod pocztowy"
                                            defaultValue={partner && partner.post_code ? partner.post_code : ""}
                                            fullWidth
                                            validate={composeValidators(required)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="city"
                                            component={TextField}
                                            type="city"
                                            label="Miasto"
                                            defaultValue={partner && partner.city ? partner.city : ""}
                                            fullWidth
                                            validate={composeValidators(required)}
                                        />
                                    </Grid>
                                </Grid>

                                {error.status ? <Grid container direction="row"><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}><br />
                                        <Button
                                            variant="contained" color="primary" type="submit"
                                            disabled={submitting}>Zapisz zmiany</Button>
                                    </Grid>
                                    {sendStatus ? <Grid item xs={12}><Typography variant="body2" style={{ color: "grey", marginTop: "20px" }}>{sendStatus}</Typography></Grid> : null}
                                </Grid>
                            </form>
                        )}
                    </Form>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default EditPartner