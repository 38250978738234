import React, { useState, useEffect, useReducer } from 'react'
import { AddServiceNote } from '../../../../components'
import {
    Button, Grid,
    Typography,
    Dialog,
    DialogContent, IconButton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import fetchGetServiceNote from '../../../../data/fetchGetServiceNote'
import Note from './Note'

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const serviceNotesReducer = (state, action) => {
    switch (action.type) {
        case "notes":
            return {
                ...state,
                [action.type]: action.payload
            }

        default:
            return { ...state }
    }
}

const ServiceNotes = ({ token, deviceId, customerId, deviceSerial, perm, keyM, user, partner_id, accessScada }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState(false)

    const [serviceNotes, setServiceNotes] = useReducer(serviceNotesReducer, { notes: null })
    const [newNoteAdd, setNewNoteAdd] = useState({ status: false, message: "" })

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNewNoteAdd({ status: false, message: "" })
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        let mounted = true

        if (keyM && token) {
            fetchGetServiceNote(token, keyM)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error(response.status)
                    }
                }).then(response => {
                    if (mounted)
                        setServiceNotes({ type: "notes", payload: response.data })
                })
                .catch(error => {
                    // if (error.message === "403") {
                    //     sessionStorage.removeItem("APIToken")
                    // }
                })
        }
        return function cleanup() {
            mounted = false
        }
    }, [keyM, token])


    const changeServiceNotesList = (action, value) => {
        if(action === "PUT" && value){
            let serviceNoteList = [...serviceNotes.notes]
            let newServiceNoteList = serviceNoteList.map(item => {
                if(item.id === value.id){
                return value
                }else{
                return item}
            })
            setServiceNotes({ type: "notes", payload: newServiceNoteList })
            
        } else if(action === "POST" && value){
            let newServiceNoteList = []
            if(serviceNotes.notes)
            newServiceNoteList = [...serviceNotes.notes]
            newServiceNoteList.push(value)
            setServiceNotes({ type: "notes", payload: newServiceNoteList })
            handleClose()
        }
    }


    return (
        <React.Fragment>
            <Grid container direction="row" >
                <Grid item xs={6} >
                    <Typography variant="h2">Zgłoszenia serwisowe</Typography>
                </Grid>
                <Grid item xs={6} >
                   <Button variant="contained" color="primary" onClick={handleClickOpen} className={`${classes.floatRight} ${classes.buttonMargin}`}>
                        Dodaj zgłoszenie
                    </Button>
                </Grid>
            </Grid>
            {serviceNotes && serviceNotes.notes &&serviceNotes.notes.length !== 0 ? (<Grid container direction="row" >
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell xs={1}>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            LP
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell xs={2}>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            STATUS
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell xs={2}>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            TYP
                                    </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell xs={3}>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            TYTUŁ
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell xs={2}>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            DATA
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell xs={1}>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            ID
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell xs={1}>
                                    <p> </p>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {serviceNotes && serviceNotes.notes ? 
                                serviceNotes.notes.map((item, index) => <Note accessScada={accessScada} partner_id={partner_id} deviceSerial={deviceSerial} keyM={keyM} user={user} perm={perm} changeServiceNotesList={changeServiceNotesList} noteIndex={index + 1} 
                                key={item.id} note={item} customerId={customerId} deviceId={deviceId} />) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>): (
                <Typography component="div" style={{marginLeft: "15px"}}>
                    <Box fontWeight="fontWeightBold">
                        Brak zgłoszeń serwisowych
                    </Box>
                </Typography>
                )}
            <Dialog scroll={"paper"} fullWidth={true}
                maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight}>
                        <CloseIcon />
                    </IconButton>
                    <AddServiceNote accessScada={accessScada} setNewNoteAdd={setNewNoteAdd} partner_id={partner_id} deviceSerial={deviceSerial} keyM={keyM} user={user} perm={perm} action={"POST"} handleClose={handleClose} token={token} 
                    customerId={customerId} deviceId={deviceId} changeServiceNotesList={changeServiceNotesList} />
                </DialogContent>
            </Dialog>
            <Snackbar open={newNoteAdd.status} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="success">
                {newNoteAdd.message}
            </Alert>
        </Snackbar>
        </React.Fragment>
    )
}

export default ServiceNotes