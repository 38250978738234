import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, InputLabel, MenuItem, FormControl, Select, Snackbar, TextField, Table, Paper, TableBody, TableContainer, TableRow, TableCell, TableHead, Box, InputAdornment } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from "date-fns/locale/pl"
import { fetchDeviceUpdate } from '../../../../data'

const Insurance = ({ insurance_start_date, insurance_end_date, deviceId, refreshDevice }) => {
    const [dateInsuranceStart, setDateInsuranceStart] = useState(new Date())
    const [dateInsuranceEnd, setDateInsuranceEnd] = useState(new Date())
    const [addInsurance, setAddInsurance] = useState(false)

    const handleChangeInsuranceDate = () => {
        let payload = {
            id: deviceId,
            insurance_start_date: dateInsuranceStart.toISOString().slice(0, 19),
            insurance_end_date: dateInsuranceEnd.toISOString().slice(0, 19),
        }

        fetchDeviceUpdate(payload).then(response => refreshDevice(response))
    }

    const dateCheck = (e, typeDate) => {
        let helpInsuranceStart = new Date(dateInsuranceStart)
        let helpInsuranceEnd = new Date(dateInsuranceEnd)

        switch (typeDate) {
            case "dateInsuranceStart":

                if (e < helpInsuranceEnd)
                    return setDateInsuranceStart(new Date(helpInsuranceEnd.setDate(helpInsuranceEnd.getDate() - 1)))
                else
                    return setDateInsuranceStart(new Date(e))

            case "dateInsuranceEnd":

                if (e > helpInsuranceStart)
                    return setDateInsuranceEnd(new Date(helpInsuranceStart.setDate(helpInsuranceStart.getDate() + 1)))
                else
                    return setDateInsuranceEnd(new Date(e))

            default:
                break;
        }
    }

    const handleAddInsurance = () => {
        let helpDate = new Date()

        setAddInsurance(true)

        if (insurance_start_date) {
            let dateStartInsurance = parseInt(insurance_start_date.slice(0, 4)) < 2000 ? helpDate : insurance_start_date
            setDateInsuranceStart(new Date(dateStartInsurance))
        } else {
            setDateInsuranceStart(new Date())
        }

        if (insurance_end_date) {
            let dateEndInsurance = parseInt(insurance_end_date.slice(0, 4)) < 2000 ? new Date(insurance_start_date.setDate(insurance_start_date.getDate() + 1)) : insurance_end_date
            setDateInsuranceEnd(new Date(dateEndInsurance))
        } else {
            setDateInsuranceEnd(new Date(helpDate.setDate(helpDate.getDate() + 1)))
        }
    }

    useEffect(() => {
        let mounted = true
        let helpDate = new Date()
        let startDate = new Date(insurance_start_date)

        if (insurance_start_date) {
            let dateStartInsurance = parseInt(insurance_start_date.slice(0, 4)) < 2000 ? helpDate : insurance_start_date
            setDateInsuranceStart(new Date(dateStartInsurance))
        }
        if (insurance_end_date) {
            let dateEndInsurance = parseInt(insurance_end_date.slice(0, 4)) < 2000 ? new Date(startDate.setDate(startDate.getDate() + 1)) : insurance_end_date
            setDateInsuranceEnd(new Date(dateEndInsurance))
        }


        //   if(insurance_start_date && insurance_end_date){
        //     setDateInsuranceStart(new Date(insurance_start_date))
        //     setDateInsuranceEnd(new Date(insurance_end_date))
        //   }

        return () => {
            mounted = false
        }
    }, [insurance_start_date, insurance_end_date])

    return (
        <Grid container>
            <Grid item xs={2} className='no-padding'>
                <Typography variant='h3' style={{ color: insurance_start_date && insurance_end_date ? "green" : "red", marginTop: "15px" }}>Ubezpieczenie<br /><br /></Typography>
            </Grid>
            {(insurance_start_date && insurance_end_date) || addInsurance ?
                <React.Fragment><Grid item xs={4}>

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                        <KeyboardDatePicker
                            InputProps={{
                                startAdornment: <InputAdornment position="start">od</InputAdornment>,
                            }}
                            variant="inline"
                            ampm={false}
                            placeholder="YYYY-MM-DD"
                            value={dateInsuranceStart}
                            onChange={(e) => dateCheck(new Date(e), "dateInsuranceStart")}
                            format="yyyy-MM-dd"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                    <Grid item xs={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                            <KeyboardDatePicker
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">do</InputAdornment>,
                                }}
                                variant="inline"
                                ampm={false}
                                placeholder="YYYY-MM-DD"
                                value={dateInsuranceEnd}
                                onChange={(e) => dateCheck(new Date(e), "dateInsuranceEnd")}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className='no-padding'><Button onClick={handleChangeInsuranceDate} variant="contained" color="primary" style={{ float: "right" }}>Zapisz</Button></Grid>
                </React.Fragment> :
                <React.Fragment><Grid item xs={6}>
                    <Typography variant="h3" style={{ color: insurance_start_date && insurance_end_date ? "green" : "red", marginTop: "15px" }}>NIE WYKUPIONO</Typography>
                </Grid>
                    <Grid item xs={4} className='no-padding'><Button onClick={() => handleAddInsurance()} variant="contained" color="primary" style={{ float: "right" }}>Dodaj ubezpieczenie</Button></Grid>
                </React.Fragment>}

        </Grid>
    )
}

export default Insurance