import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const InvestorTraining = ({ required, setValues }) => {

    useEffect(() => {
        setValues({type: "childName", value: "InvestorTraining"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Szkolenie Inwestora</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Zasady działania urządzenia</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="operationPrinciples"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="operationPrinciples"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="operationPrinciples" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Włączania i wyłączania pompy ciepła</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="switchOnOff"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="switchOnOff"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="switchOnOff" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Sterowania i wykonywania prostych konfiguracji</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="simpleConfigurations"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="simpleConfigurations"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="simpleConfigurations" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Czyszczenia filtrów (skośnych/siatkowych, magnetycznych)</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="filterCleaning"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="filterCleaning"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="filterCleaning" />
                </Grid>
            </Grid>
        </>
    )
}

export default InvestorTraining;