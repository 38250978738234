import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const slideList = (min, max, type, name) => {
    const tableList = []
    if (type === "time") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={i}>{i < 10 ? `0${i}:00` : `${i}:00`}</SwiperSlide>)
        }
    } if (type === "degreesplus") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={i}>{i > 0 ? "+" : " "} {i}</SwiperSlide>)
        }
    } else if (type === "degreesminus") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={i}>{i > 0 ? "-" : " "} {i}</SwiperSlide>)
        }
    } else if (type === "hmmin") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={name+i}>{`${i}0`}</SwiperSlide>)
        }
    } else if (type === "hmhr") {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={name+i}>{i < 10 ? `0${i}` : `${i}`}</SwiperSlide>)
        }
    } else {
        for (let i = min; i <= max; i++) {
            tableList.push(<SwiperSlide key={name+i}>{i}</SwiperSlide>)
        }
    }

    return tableList
}

const CarouselInput = ({ init, min, max, title, setPublish, name, type, setVal }) => {
    const [slide, setSlide] = useState(init)
    const onChangeSlideTime = (index) => {
        setSlide(index - 1)
    }
   
    // const onChangeStart = (swiper) => {
    //     console.log("start")
    //     console.log(parseInt(swiper.textContent))
    // 
    const onChangeStop = (index, value, nameRegister) => {
        // console.log("stop: " + value[index].textContent.replace(/\D+/gi, ""))
        // console.log(value[index])
        // if (type === "time")
        //     setPublish({ [name]: parseInt(value[index].textContent) })
        // else if (type === "hm") {
        //     setPublish({ [nameRegister]: parseInt(value[index].textContent) })
        if(type === "degreesplus" || type === "degreesminus") {
            setVal( name, parseInt(value[index].textContent.replace(/\D+/gi, "")) )  
        }
        else
            setVal( name, parseInt(value[index].textContent) ) 
    }
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item xs={12} className="euros-monitor euros-monitor-carousel">
                {/*{type === "hm" ? 
                    <Grid container>
                        <Grid item xs={12}>
                            <Swiper
                                onTouchEnd={(swiper) => onChangeStop(swiper.activeIndex, swiper.slides, name)}
                                initialSlide={slide - min} watchSlidesVisibility={true} slidesPerView={3}
                                centeredSlides={true} direction="vertical" loop={false} className="mySwiper1"
                                onActiveIndexChange={(swiper) => onChangeSlideTime(swiper.activeIndex)}>
                                {slideList(min, max, `${type}hr`, name)}
                            </Swiper>
                        </Grid>

                        <Grid item xs={6}>
                            <Swiper
                                onTouchEnd={(swiper) => onChangeStop(swiper.activeIndex, swiper.slides, name.minute)}
                                initialSlide={slide.minute - min.minute} watchSlidesVisibility={true} slidesPerView={3}
                                centeredSlides={true} direction="vertical" loop={true} className="mySwiper2"
                                onActiveIndexChange={(swiper) => onChangeSlideTime(swiper.activeIndex)}>
                                {slideList(min.minute, max.minute, `${type}min`, name.minute)}
                            </Swiper>
                        </Grid>
                    </Grid>*/}
                    <Swiper
                        onTouchEnd={(swiper) => onChangeSlideTime(swiper.activeIndex)}
                        initialSlide={slide - min} watchSlidesVisibility={true} slidesPerView={3}
                        centeredSlides={true} direction="vertical" loop={false} className="mySwiper"
                        onActiveIndexChange={(swiper) => onChangeStop(swiper.activeIndex, swiper.slides)}>
                        {slideList(min, max, type, name)}
                    </Swiper>
            </Grid>
        </Grid>
    )
}

export default CarouselInput