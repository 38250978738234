import React, { useState, useEffect } from 'react'
import { Grid, TableCell, TableRow, Button, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh';
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../theme'
import { Scada } from './component'
import { PartnersList } from './component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { dateConvert } from '../../functions'

const ListItem = ({ item, index, perm, subLogin, role, handleRefreshDevice, accessScada }) => {
    const classes = mainStyleClasses()
    const [scadaOpen, setScadaOpen] = useState(false)
    const [partnerListOpen, setPartnerListOpen] = useState(false)
    const [itemState, setItemState] = useState(item)

    const handleOpen = (dialog) => {
        if (dialog === 'scada') {
            setScadaOpen(true)
        }
        else {
            setPartnerListOpen(true)
        }
    }

    const handleClose = () => {
        setScadaOpen(false)
        setPartnerListOpen(false)
    }

    const updateTelemetryColor = (el) => {
        let dateNow = new Date()
        dateNow = Date.now()
        let dateUpdateTelemetry = new Date()
        let dateHelp = dateConvert(dateNow, true)

        if (item.last_telemetry_update) {
            dateUpdateTelemetry = Date.parse(item.last_telemetry_update.slice(0, 19).replace("T", " "))
            // console.log({dateNow: new Date(dateNow)})
            // console.log({dateTelemetry: new Date(dateConvert(dateUpdateTelemetry))})

            if (((dateNow - dateConvert(dateUpdateTelemetry)) / 1000 / 60) < 60) {
                if (el === "list")
                    return classes.backgroundGreen
                else
                    return "Green"
            } else {
                if (el === "list")
                    return classes.backgroundYellow
                else
                    return "Yellow"
            }
        }
    }

    const handleEsc = (e) => {
        if (e.key === "Escape")
            handleClose()
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.key.slice(0, 6) === "010101" ? "ATMO"
                    : (item.key.slice(0, 6) === "010201" ? "GEO"
                        : item.key.slice(0, 6) === "010301" ? "ALLINONE"
                            : item.key.slice(0, 2) === "FA" ? "PRZEMYSŁOWE"
                                : item.key.slice(0, 2) === "64" ? "PV"
                                    : item.key.slice(0, 6) === "010202" ? "GEOPLUS"
                                        : item.key.slice(0, 6) === "020101" ? "EMS"
                                            : "nieznany typ urządzenia")}</TableCell>
                <TableCell>{item.device_sn === null ? "nie podano" : item.device_sn}</TableCell>
                <TableCell className={!item.last_telemetry_update || parseInt(item.last_telemetry_update.slice(0, 4)) < 2019 ?
                    `${classes.backgroundRed}` : `${updateTelemetryColor("list")}`}>
                    <Grid container direction="row">
                        <Grid item xs={9}>
                            {item.key}<br />{!item.last_telemetry_update || parseInt(item.last_telemetry_update.slice(0, 4)) < 2019 ?
                                "Ostatnio online: NIGDY" : `Ostatnio online: ${item.last_telemetry_update.slice(0, 19).replace("T", " ")}`}
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton onClick={(e) => handleRefreshDevice(e, item.device_sn)}>
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>{item.first_telemetry_update ? item.first_telemetry_update.slice(0, 19).replace("T", " ") : null}</TableCell>
                <TableCell>{item.installation.customer.first_name} {item.installation.customer.last_name} <br /> {item.installation.customer.phone}</TableCell>
                <TableCell>{item.installation.customer.post_code}, {item.installation.customer.city}</TableCell>
                {accessScada && role === "partner" && accessScada.partner_id !== 1 ? null
                    : <React.Fragment>
                        <TableCell>
                            {itemState.partner ? itemState.partner.name : null}
                        </TableCell>
                        <TableCell>
                            <FontAwesomeIcon onClick={() => handleOpen('partnerList')} style={{ cursor: "pointer", marginTop: '50%' }} icon={faEdit} title="Edytuj" />
                        </TableCell>
                    </React.Fragment>
                }
                <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleOpen('scada')}>scada</Button>
                </TableCell>
                {accessScada && role === "partner" && accessScada.partner_id !== 1 ?
                    (<TableCell>
                        <Link to={{
                            pathname: `/deviceservice/${item.key}/${item.device_sn ? item.device_sn : "brak-nr-seryjnego"}`,
                            state: { deviceState: item }
                        }}>
                            <Button variant="contained" color="primary">szczegóły</Button>
                        </Link>
                    </TableCell>)
                    : (<TableCell>
                        <Link to={{
                            pathname: `/szczegoly/${item.key}/${item.device_sn ? item.device_sn : "brak-nr-seryjnego"}`,
                            state: { deviceState: item }
                        }}>
                            <Button variant="contained" color="primary">szczegóły</Button>
                        </Link>
                    </TableCell>)
                }
            </TableRow>
            <Dialog className="quickinfo" onKeyDown={(e) => handleEsc(e)} scroll={"body"} fullWidth={true} maxWidth={"lg"} open={scadaOpen || partnerListOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    {scadaOpen === true ?
                        <Scada lastUpdate={!item.last_telemetry_update || parseInt(item.last_telemetry_update.slice(0, 4)) < 2019 ?
                            `${classes.backgroundRed}` : `${updateTelemetryColor("scada")}`}
                            street={`${item.installation.customer.street} ${item.installation.customer.street_number}`}
                            telemetry={item.last_telemetry_update ?
                                item.last_telemetry_update.slice(0, 19).replace("T", " ") : "brak danych"}
                            sn={item.device_sn}
                            installation={item.key}
                            address={`${item.installation.customer.post_code}, ${item.installation.customer.city}`}
                            client={`${item.installation.customer.first_name} ${item.installation.customer.last_name}`}
                            phone={`${item.installation.customer.phone}`}
                        />
                        : <PartnersList id={item.id} setItemState={setItemState} itemState={itemState} setPartnerListOpen={setPartnerListOpen} />
                    }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ListItem