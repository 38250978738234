import React, { useState, useReducer, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { descriptionDictionary } from '../../../../../dataFilter/descriptionDictionary'
import heatingIcon from '../../../../../../../img/Grzanie.svg'
import airConditioningIcon from '../../../../../../../img/Chlodzenie.svg'
import circulationIcon from '../../../../../../../img/Cyrkulacja.svg'
import antylegionellaIcon from '../../../../../../../img/Antylegionella.svg'


const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        if (state[action.type] === 0) {
            return { ...state, [action.type]: 1 };
        } else if (state[action.type] === 1) {
            return { ...state, [action.type]: 0 };
        }

    }
};

const handleSendFunctionState = (id, value, setPublish, setState) => {
    const sendKey = id;
    const sendData = value;
    setPublish({ [sendKey]: sendData })

}

const Functions = ({ functionData, setPublish }) => {
    const [controller, setFunctionState] = useReducer(switchOption, functionData);
    const [state, setState] = useState(null);
    useEffect(() => {
        setFunctionState({ payload: functionData, case: "UPDATE_FROM_BROKER" })
    }, [functionData])

    return (
        <React.Fragment>
            <Row className="bottom-margin">
                <Col xs={6} className="text-left">
                    <p>{descriptionDictionary.main.HEATING.descript}</p>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={4} className="np"><img alt={descriptionDictionary.main.HEATING.descript} className="mode-icon" src={heatingIcon} /></Col>
                        <Col xs={8}>
                            {controller["heating"] === 1 ? <div onClick={() => { setFunctionState({ type: "heating" }); handleSendFunctionState(descriptionDictionary.main.HEATING.register, 0, setPublish, setState) }} className="div-for-radio">TAK</div>
                                : <div onClick={() => { setFunctionState({ type: "heating" }); handleSendFunctionState(descriptionDictionary.main.HEATING.register, 1, setPublish, setState) }} className="div-for-radio">NIE</div>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="bottom-margin">
                <Col xs={6} className="text-left">
                    <p>{descriptionDictionary.main.AIR_CONDITIONING.descript}</p>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={4} className="np"><img alt={descriptionDictionary.main.AIR_CONDITIONING.descript} className="mode-icon" src={airConditioningIcon} /></Col>
                        <Col xs={8}>
                            {controller["airConditioning"] === 1 ?
                                <div onClick={() => { setFunctionState({ type: "airConditioning" }); handleSendFunctionState(descriptionDictionary.main.AIR_CONDITIONING.register, 0, setPublish, setState) }} className="div-for-radio">TAK</div>
                                : <div onClick={() => { setFunctionState({ type: "airConditioning" }); handleSendFunctionState(descriptionDictionary.main.AIR_CONDITIONING.register, 1, setPublish, setState) }} className="div-for-radio">NIE</div>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="bottom-margin">
                <Col xs={6} className="text-left">
                    <p>{descriptionDictionary.main.CIRCULATION.descript}</p>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={4} className="np"><img alt={descriptionDictionary.main.CIRCULATION.descript} className="mode-icon" src={circulationIcon} /></Col>
                        <Col xs={8}>
                            {controller["circulation"] === 1 ?
                                <div onClick={() => { setFunctionState({ type: "circulation" }); handleSendFunctionState(descriptionDictionary.main.CIRCULATION.register, 0, setPublish, setState) }} className="div-for-radio">START</div>
                                : <div onClick={() => { setFunctionState({ type: "circulation" }); handleSendFunctionState(descriptionDictionary.main.CIRCULATION.register, 1, setPublish, setState) }} className="div-for-radio">STOP</div>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="bottom-margin">
                <Col xs={6} className="text-left">
                    <p>{descriptionDictionary.main.ANTYLEGIONELLA.descript}</p>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={4} className="np"><img alt={descriptionDictionary.main.ANTYLEGIONELLA.descript} className="mode-icon" src={antylegionellaIcon} /></Col>
                        <Col xs={8}>
                            {controller["antylegionella"] === 1 ?
                                <div onClick={() => { setFunctionState({ type: "antylegionella" }); handleSendFunctionState(descriptionDictionary.main.ANTYLEGIONELLA.register, 0, setPublish, setState) }} className="div-for-radio">START</div>
                                : <div onClick={() => { setFunctionState({ type: "antylegionella" }); handleSendFunctionState(descriptionDictionary.main.ANTYLEGIONELLA.register, 1, setPublish, setState) }} className="div-for-radio">STOP</div>}
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/*state ? (<ToastInfo response={state} close={() => setState(null)} />) : null*/}
        </React.Fragment>
    )
}

export default Functions