import fetchRefreshToken from './fetchRefreshToken'

const fetchCheckNipPartner = async (nip) => {
  const newToken = await fetchRefreshToken().then(response => response)
    
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/partners/nip/${nip}`, {
      method: "GET",
      headers:{
        "Authorization": `Bearer ${newToken}`
      }
    })
    .then(response => response.json())

    return response
 }

  export default fetchCheckNipPartner