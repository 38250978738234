import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField, Select } from 'final-form-material-ui'
import { Button, Grid, Typography, Box, InputLabel, FormControl, MenuItem } from '@material-ui/core'
import formatString from 'format-string-by-pattern'
import EditIcon from '@material-ui/icons/Edit';

import { default as fetchRegonApi } from '../../../../data/fetchRegonApi'
import { default as fetchInstallerAddEdit } from '../../../../data/fetchInstallerAddEdit'
import { default as fetchPartnerAddEdit } from '../../../../data/fetchPartnerAddEdit'
import { errorMessage } from '../../validation'
import { fetchCheckNipPartner, fetchCertInstallator } from '../../../../data'
import { saveAs } from 'file-saver'

const required = value => (value ? undefined : "Pole obowiązkowe")

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const passConfirm = pass => value => (value && value === pass ? undefined : "Hasła nie są jednakowe")
const minLengthPass = min => value => value === undefined || value.length < min ? `Hasło powinno się składać z minimum ${min}` : null

const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")

const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const validNipNumber = (anyString) => {
    const nipString = anyString.replaceAll(/[\s-]/gi, '')
    //console.log(nipString)
    const nipWieght = [6, 5, 7, 2, 3, 4, 5, 6, 7]

    let nipSum = 0

    if (nipString.length < 10) {
        return "Podany numer jest za krótki"
    } else if (nipString.length > 10) {
        return "Podany numer jest za długi"
    } else {

        for (let i = 0; i < nipWieght.length; i++) {
            nipSum += (parseInt(nipString.slice(i, i + 1)) * nipWieght[i])
        }

        if (nipSum % 11 === parseInt(nipString.slice(9, 10))) {
            return undefined
        } else {
            return "Nieprawidłowy numer nip"
        }
    }
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const dataPack = (val, data, type) => {
    // ten regex nie uwzględnia numeru na końcu nazwy ulicy (np. Dywizjonu 303)
    const reg = /^(.*[^0-9]+) (([1-9][0-9]*[a-zA-Z]?)[/])?([a-zA-Z]?[1-9][0-9]*[a-zA-Z]?)$/g
    const nameCompanyReg = /^[\"]$/g
    
    const street = data ? (data.street ? data.street
            : (data.street_address ? reg.exec(data.street_address)[1] : "")) : ""
    const apartament_number = data ? (data.apartament_number ? "/" + data.apartament_number : "") : ""

    let pack
    if (type === "company") {
        pack = {
            name: data.name ? data.name.replaceAll("\"", "") : "nazwa firmy",
            email: val.email,
            phone: val.phone,
            nip: val.nip,
            street: street ? street : "ulica",
            street_number: data.street_number ? data.street_number + apartament_number
                : (data.street_address ? data.street_address.replace(street + " ", "") + apartament_number : "numer ulicy"),
            city: data.city ? data.city : "miasto",
            post_code: data.postal_code ? data.postal_code : (data.post_code ? data.post_code : "kod pocztowy"),
            parent_partner_id: val.parent_partner_id,
            memo: ""
        }
    } else {
        pack = {
            partner_id: val.partner_id,
            installators: [
                {
                    email: val.email,
                    phone: val.phone,
                    first_name: val.first_name,
                    last_name: val.last_name
                }
            ]
        }
    }

    return pack
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const NewInstallator = ({ handleClose, refreshInstallersList, partnersList }) => {
    const [error, setError] = useState({ status: false, message: "" })
    const [nipStatus, setNipStatus] = useState({ status: false, company: "" })
    const [sendStatus, setSendStatus] = useState(null)
    const [partnerSelect, setPartnerSelect] = useState(0)
    const [certSave, setCertSave] = useState(0)
    const [editValues, setEditValues] = useState({ parent_partner_id: "", first_name: "", last_name: "", email: "", phone: "",
        nip: "", name: "", street: "", street_number: "", post_code: "", city: "" })

    const handlePartnerSelect = (e) => {
        setPartnerSelect(parseInt(e.target.value))
    }

    const checkNIP = (e, nip) => {
        nip = nip.replaceAll(/[\s-]/gi, '')

        fetchCheckNipPartner(nip).then(response => {
            if (response.status === "ok") {
                setNipStatus({ status: true, company: response.data })
                setSendStatus("")
            } else {
                // jak nie ma w naszej bazie jeszcze zapisanej firmy o tym nipie
                fetchRegonApi(nip.replaceAll(/[\s-]/gi, '')).then(async res => {
                    setSendStatus("Sprawdzanie numeru NIP")
                    if (res.status === "ok") {
                        setSendStatus("Zapisywanie danych. Proszę czekać.")
                        setNipStatus({ status: true, company: res.data })
                        setError({ status: false, message:"" })
                        setSendStatus("new")
                    } else {
                        setError({ status: true, message: "Podany NIP nie istnieje" })
                    }
                })
            }
        })
    }

    const handleEdit = (e, values, values2) => {
        const reg = /^(.*[^0-9]+) (([1-9][0-9]*[a-zA-Z]?)[/])?([a-zA-Z]?[1-9][0-9]*[a-zA-Z]?)$/g

        setSendStatus("edit")
        
        let strNumber = values2.street_address.replace(reg.exec(values2.street_address)[1] + " ", "")

        setEditValues({ parent_partner_id: values.parent_partner_id, first_name: values.first_name, last_name: values.last_name,
            email: values.email, phone: values.phone, nip: values.nip, name: values2.name, street: values2.street,
            street_number: strNumber, post_code: values2.postal_code, city: values2.city })
    }

    const acceptChanges = (e, values) => {
        let data = nipStatus.company
        data.name = values.name
        data.street = values.street
        data.street_number = values.street_number
        data.street_address = `${values.street} ${values.street_number}`
        data.post_code = values.post_code
        data.postal_code = values.post_code
        data.city = values.city
        setNipStatus({ ...nipStatus, company: data })

        setSendStatus("new")
    }

    const rejectChanges = (e) => {
        setSendStatus("new")
    }

    const getCertificate = async(id, name) => {
        const blobCertificate = await fetchCertInstallator(id).then(res => res)
        const file = new Blob([blobCertificate], {type: 'application/pdf'})
        saveAs(file, `certyfikat_${name}.pdf`)
    }

    const onSubmit = async values => {
        values = { ...values, parent_partner_id: partnerSelect }
        values.nip = values.nip.replaceAll(/[\s-]/gi, '')

        if (sendStatus && sendStatus === "new") {
            fetchPartnerAddEdit(dataPack(values, nipStatus.company, "company"), "POST").then(response => {
                if (response.status === 200 || response.status === "ok") {
                    setSendStatus("Dodano nowego partnera.")
                    return response.json()
                } else {
                    setSendStatus("")
                    setError({ status: true, message: errorMessage(response.status, "partner") })
                    return "error"
                }
            }).then(response => {
                if (response.status === "ok") {
                    values = { ...values, partner_id: response.data.id }
                    
                    fetchInstallerAddEdit(dataPack(values, nipStatus.company), "POST").then(response => {
                        
                        if (response.status === 200 || response.status === "ok") {
                            refreshInstallersList()
                            setError({ status: false, message:"" })
                            setSendStatus("ok")
                            return response.json()
                        } else {
                            setError({ status: true, message: errorMessage(response.status) })
                        }
                    }).then(response => {
                        setCertSave({values, new: response})
                    })
                }
            })
        } else {
            fetchCheckNipPartner(values.nip).then(response => {
                if (response.status === "ok") {
                    setNipStatus({ status: true, company: response.data })
                    values = { ...values, partner_id: response.data.id }
                
                    fetchInstallerAddEdit(dataPack(values), "POST").then(response => {
                    
                        if (response.status === 200 || response.status === "ok") {
                            refreshInstallersList()
                            setSendStatus("ok")
                            setError({ status: false, message:"" })
                            return response.json()
                        } else {
                            setError({ status: true, message: errorMessage(response.status) })
                        }
                    }).then(response => {
                        setCertSave({values, new: response})
                    })
                } else {
                    // jak nie ma w naszej bazie jeszcze zapisanej firmy o tym nipie
                    fetchRegonApi(values.nip.replaceAll(/[\s-]/gi, '')).then(async res => {
                        setSendStatus("Sprawdzanie numeru NIP")
                        if (res.status === "ok") {
                            setSendStatus("Zapisywanie danych. Proszę czekać.")
                            setNipStatus({ status: true, company: res.data })
                            setError({ status: false, message:"" })
                            await sleep(1500)
                            fetchPartnerAddEdit(dataPack(values, res.data, "company"), "POST").then(response => {
                                if (response.status === 200 || response.status === "ok") {
                                    setSendStatus("Dodano nowego partnera.")
                                    return response.json()
                                } else {
                                    setSendStatus("")
                                    setError({ status: true, message: errorMessage(response.status, "partner") })
                                    return "error"
                                }
                            }).then(response => {
                                if (response.status === "ok") {
                                    values = { ...values, partner_id: response.data.id }
                                    
                                    fetchInstallerAddEdit(dataPack(values, res.data), "POST").then(response => {
                                        
                                        if (response.status === 200 || response.status === "ok") {
                                            refreshInstallersList()
                                            setError({ status: false, message:"" })
                                            setSendStatus("ok")
                                            return response.json()
                                        } else {
                                            setError({ status: true, message: errorMessage(response.status) })
                                        }
                                    }).then(response => {
                                        setCertSave({values, new: response})
                                    })
                                }
            
                            })
            
                        } else {
                            setError({ status: true, message: "Podany numer NIP nie istnieje" })
                        }
                    })
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Form
                        initialValues={editValues}
                        onSubmit={onSubmit}
                    >
                        {({ handleSubmit, form, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h2">Dodawanie nowego instalatora</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={12}>
                                        <FormControl>
                                            <Field
                                                name="parent_partner_id"
                                                labelId="parent_partner_id"
                                                label="Wybierz partnera"
                                                id="parent_partner_id"
                                                value={partnerSelect}
                                                onClick={(e) => handlePartnerSelect(e)}
                                                component={Select}
                                                validate={composeValidators(required)}
                                            >
                                            {/*<MenuItem value={0}>Wybierz partnera</MenuItem>*/}
                                                {partnersList.map(partner => <MenuItem key={partner.id} value={partner.id}>{partner.name}</MenuItem>)}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="first_name"
                                            component={TextField}
                                            type="first_name"
                                            label="Imię"
                                            fullWidth
                                            validate={composeValidators(required, textOnly)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="last_name"
                                            component={TextField}
                                            type="last_name"
                                            label="Nazwisko"
                                            fullWidth
                                            validate={composeValidators(required, textOnly)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="email"
                                            component={TextField}
                                            type="email"
                                            label="Adres e-mail"
                                            fullWidth
                                            validate={composeValidators(required, formatEmail)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="phone"
                                            component={TextField}
                                            type="phone"
                                            label="Numer telefonu"
                                            fullWidth
                                            validate={composeValidators(required, minLength(11))}
                                            parse={e => formatOnlyNumbersTel(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="nip"
                                            component={TextField}
                                            type="nip"
                                            label="NIP"
                                            fullWidth
                                            validate={composeValidators(required, validNipNumber)}
                                        />
                                    </Grid>
                                    {nipStatus && nipStatus.status ? (<Grid item xs={12} md={6}>
                                        <Typography variant="body1">
                                            <Box fontWeight="fontWeightBold">{nipStatus.company.name}</Box><br />
                                            {nipStatus.company.street_address ? nipStatus.company.street_address : (`${nipStatus.company.street} ${nipStatus.company.street_number}`)}<br />
                                            {nipStatus.company.postal_code ? nipStatus.company.postal_code : nipStatus.company.post_code} {nipStatus.company.city}<br />
                                            {sendStatus && sendStatus === "new" ?
                                                <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={(e) => handleEdit(e, values, nipStatus.company)}>
                                                    Edytuj dane firmy
                                                </Button> : null}
                                        </Typography>
                                    </Grid>) : null}
                                </Grid>
                                {sendStatus && sendStatus === "edit" ?
                                    <Grid container direction='row'>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                label="Nazwa firmy"
                                                name="name"
                                                type="text"
                                                component={TextField}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                label="Ulica"
                                                name="street"
                                                type="text"
                                                component={TextField}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                label="Numer domu"
                                                name="street_number"
                                                type="text"
                                                component={TextField}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                label="Kod pocztowy"
                                                name="post_code"
                                                type="text"
                                                component={TextField}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                label="Miejscowość"
                                                name="city"
                                                type="text"
                                                component={TextField}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button variant="contained" color="primary" onClick={e => acceptChanges(e, values)} disabled={submitting}>
                                                Zatwierdź zmiany
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={e => rejectChanges(e)} disabled={submitting}>
                                                Odrzuć zmiany
                                            </Button>
                                        </Grid>
                                    </Grid> : null
                                }

                                {error.status ? <Grid container direction="row"><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}><br />
                                        {sendStatus && sendStatus === "ok" ?
                                            <Typography variant="body2" style={{ color: "grey", marginTop: "20px" }}>
                                                Dodano instalatora
                                            </Typography> :
                                            <div>
                                                <Typography variant="body2" style={{ marginBottom: "20px" }}>
                                                    Upewnij się, że przy wprowadzaniu danych instalatora z firmy, której jeszcze
                                                    nie mamy w bazie, wszystkie dane firmy pobrane na podstawie NIPu są kompletne
                                                    i poprawne. W tym celu użyj przycisku Sprawdź NIP.
                                                </Typography>
                                                <Button variant="contained" color="primary" onClick={e => checkNIP(e, values["nip"])} disabled={submitting}>
                                                    Sprawdź NIP
                                                </Button>
                                                <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                                                    Dodaj
                                                </Button>
                                            </div>}
                                    </Grid>
                                    {sendStatus && sendStatus === "ok" ? <Grid item xs={12} md={6}>
                                        <Button variant="contained" color="primary" 
                                        onClick={() => getCertificate(certSave.new.data[0].user_id, `${certSave.values.first_name}_${certSave.values.last_name}`)}
                                        >Certyfikat</Button>
                                    </Grid> : null}
                                    {sendStatus && sendStatus !== "ok" && sendStatus !== "new" && sendStatus !== "edit" ?
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ color: "grey", marginTop: "20px" }}>{sendStatus}</Typography>
                                        </Grid> : null}
                                </Grid>
                            </form>
                        )}
                    </Form>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NewInstallator