import {PublishSetupModule} from './tokenBuild'

export const functionPublish = (client, Data, installation, device, changeTokenStatus) => {
  let publishDataToken1 = Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
  changeTokenStatus(publishDataToken1)

    client.publish(`${installation}/App`, PublishSetupModule(JSON.stringify({
     Data,
      "Token": publishDataToken1
    }), device.Name, device.Type, device.Version))
    console.log(`${installation}/App`, PublishSetupModule(JSON.stringify({
      Data,
       "Token": publishDataToken1
     }), device.Name, device.Type, device.Version))

  }