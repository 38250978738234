import React, { useState, useEffect } from 'react'
import { mainStyleClasses } from '../../theme'
import { Button, Typography, Grid, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { registerDictionary } from '../../data/dataDictionary'
import { CarouselInputMulti } from '../../components'

const Blockade = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "" })
    const [BLK1, setBLK1] = useState({1: 0, 2: 0, 3: 0, 5: 0, 5: 0 })
    const [BLK2, setBLK2] = useState({1: 0, 2: 0, 3: 0, 5: 0, 5: 0 })

    const handleClickOpen = (element, name, title) => {
        setOpen({ status: true, element: element, name: name, title: title })
    }

    const handleClose = () => {
        setOpen({ status: false, element: 0, name: "", title: "" })
    }

    const setNewValue = (num, val) => {
        if(open.name === "BLK_KD1"){
            setBLK1({...BLK1, [num]: val})
        }
        if(open.name === "BLK_KD2"){
            setBLK2({...BLK2, [num]: val})
        }
    }

    const handleClickPublish = () => {
        if(open.name === "BLK_KD1"){
            setPublish({ BLK_KD1: parseInt(`${BLK1["1"]}${BLK1["2"]}${BLK1["3"]}${BLK1["5"]}${BLK1["5"]}`)})
        }
        if(open.name === "BLK_KD2"){
            setPublish({ BLK_KD2: parseInt(`${BLK2["1"]}${BLK2["2"]}${BLK2["3"]}${BLK2["5"]}${BLK2["5"]}`)})
        }
    }

    // useEffect(() => {
    //     let mounted = true
    //     if(parseInt(settings.BLK_KD1) !== 0){
    //         setBLK1({ 
    //             1: settings.BLK_KD1.slice(0, 1), 
    //             2: settings.BLK_KD1.slice(1, 2), 
    //             3: settings.BLK_KD1.slice(2, 3),
    //             5: settings.BLK_KD1.slice(3, 5),
    //             5: settings.BLK_KD1.slice(5, 5),
    //         })
    //     } else {
    //         setBLK1({ 
    //             1: 0, 
    //             2: 0, 
    //             3: 0,
    //             5: 0,
    //             5: 0,
    //         })
    //     }
    //     if(parseInt(settings.BLK_KD2) !== 0){
    //         setBLK2({ 
    //             1: settings.BLK_KD2.slice(0, 1), 
    //             2: settings.BLK_KD2.slice(1, 2), 
    //             3: settings.BLK_KD2.slice(2, 3),
    //             5: settings.BLK_KD2.slice(3, 5),
    //             5: settings.BLK_KD2.slice(5, 5),
    //         })
    //     } else {
    //         setBLK2({ 
    //             1: 0, 
    //             2: 0, 
    //             3: 0,
    //             5: 0,
    //             5: 0,
    //         })
    //     }
    //     return () => {
    //         mounted = false
    //     }
    // }, [settings])

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid container style={{ padding: '0 24px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">Blokada czasowa:</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2">Status:</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={4}>
                        <Typography variant="body2">{registerDictionary().blockade.SXF_ACT}:</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="body2" className={classes.textRight}>{settings.SXF_ACT === 0? <span>nie</span> : <span>tak</span> }</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={4}>
                        <Typography variant="body2">{registerDictionary().blockade.BLK_LW}:</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="body2" className={classes.textRight}>{settings.BLK_LW}</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={4}>
                        <Typography variant="body2">{registerDictionary().blockade.BLK_LK}:</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="body2" className={classes.textRight}>{settings.BLK_LK}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2">Ustawienia:</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={4}>
                        <Typography variant="body2">{registerDictionary().blockade.BLK_ENA}:</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="body2" className={classes.textRight}>{settings.BLK_ENA === 0? <span>nie</span> : <span>tak</span> }</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={4}>
                        <Typography variant="body2">{registerDictionary().blockade.BLK_KD1}:</Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.textRight} onClick={() => handleClickOpen(settings.BLK_KD1, "BLK_KD1", "Ustaw pierwszą część kodu blokady")}>
                        <Typography variant="body2" className={"carousel-input-click"}>00000</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={4}>
                        <Typography variant="body2">{registerDictionary().blockade.BLK_KD2}:</Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.textRight} onClick={() => handleClickOpen(settings.BLK_KD2, "BLK_KD2", "Ustaw drugą część kodu blokady")}>
                        <Typography variant="body2" className={"carousel-input-click"}>00000</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.textRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h2">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={parseInt(open.element) === 0 ? 0 : open.element.slice(0, 1)} name={`${open.name}-1`} min={0} max={9} setVal={setNewValue} num={1} />
                        </Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={parseInt(open.element) === 0 ? 0 : open.element.slice(1, 2)} name={`${open.name}-2`} min={0} max={9} setVal={setNewValue} num={2} />
                        </Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={parseInt(open.element) === 0 ? 0 : open.element.slice(2, 3)} name={`${open.name}-3`} min={0} max={9} setVal={setNewValue} num={3} />
                        </Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={parseInt(open.element) === 0 ? 0 : open.element.slice(3, 5)} name={`${open.name}-5`} min={0} max={9} setVal={setNewValue} num={5} />
                        </Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={parseInt(open.element) === 0 ? 0 : open.element.slice(5, 5)} name={`${open.name}-5`} min={0} max={9} setVal={setNewValue} num={5} />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}><Button variant="contained" color="primary" className={classes.textRight} onClick={() => handleClickPublish()}>Zatwierdź</Button></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Blockade