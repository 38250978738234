import React from 'react'

const ScadaNCBR = ({ scada }) => {
    const version_1 = parseFloat(sessionStorage.getItem('version_1'))
    const version_2 = parseFloat(sessionStorage.getItem('version_2'))

    const getCWUStorageR = () => {
        if (!scada) return 100;
        else return (scada.TSM15 - scada.SPDHW_ZAD) / (75 - scada.SPDHW_ZAD);
      };

    const getCWUStorage = () => {
        let result = getCWUStorageR();

        if (result * 100 < 20) return 558.4;
        else if (result * 100 < 40) return 583.6;
        else if (result * 100 < 70) return 608.8;
        else return 642.6;
    };
    
    const getCOStorageR = () => {
        if (!scada) return 100;
        else return ((scada.TSM17 - scada.SPCU - scada.CUN_D_HCU) / (75 - scada.SPCU - scada.CUN_D_HCU));
    };

    const getCOStorage = () => {
        let result = getCOStorageR();
    
        if (result * 100 < 20) return 124.4;
        else if (result * 100 < 40) return 149.6;
        else if (result * 100 < 70) return 174.8;
        else return 208.6;
    };
    
    const getCoolStorageR = () => {
        if (!scada) return 100;
        return (scada.SCCU - ((scada.T_Z2_1 + scada.T_Z2_2 + scada.T_Z2_3) / 3)) / (scada.SCCU - 5);
      };
    
    const getCoolStorage = () => {
        let result = getCoolStorageR();
        
        if (result * 100 < 20) return 124.4;
        else if (result * 100 < 40) return 149.6;
        else if (result * 100 < 70) return 174.8;
        else return 208.6;
      };

    return (
        <React.Fragment>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 765 40">
                <g id='linijka' className='belka'>
                    <g id="temperatura_zewnetrzna" data-name="temperatura zewnetrzna">
                        <g id="symbol_pogody" data-name="symbol pogody">
                            <path className="cls-3" d="m5.28,14.77c-2.82,0-4.28,2.16-4.28,4.3,0,2.28,1.85,4.13,4.13,4.13h16.15c1.79,0,3.24-1.46,3.24-3.24s-1.46-3.25-3.24-3.25h-.52l.02-.52s.04-1.44-.87-2.38c-.56-.58-1.37-.87-2.42-.87-.98,0-1.73.37-2.36,1.17l-.21.27-.88-.27v-.37c0-2.28-1.81-4.06-4.12-4.06s-4.13,2.02-4.13,4.59v.5h-.5Z"/>
                            <g>
                                <g>
                                    <path className="cls-2" d="m24.64,12.12h-2.16c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.16c.28,0,.5.22.5.5s-.22.5-.5.5Z"/>
                                    <path className="cls-2" d="m10.77,7.31c-.13,0-.26-.05-.35-.15l-1.41-1.41c-.19-.2-.19-.51,0-.71.2-.19.51-.2.71,0l1.41,1.41c.19.2.19.51,0,.71-.1.1-.23.15-.35.15Z"/>
                                    <path className="cls-2" d="m15.58,5.32c-.28,0-.5-.22-.5-.5v-2.09c0-.28.22-.5.5-.5s.5.22.5.5v2.09c0,.28-.22.5-.5.5Z"/>
                                    <path className="cls-2" d="m20.39,7.31c-.13,0-.26-.05-.35-.15-.2-.2-.19-.51,0-.71l1.42-1.41c.2-.2.51-.2.71,0,.2.2.19.51,0,.71l-1.42,1.41c-.1.1-.23.15-.35.15Z"/>
                                </g>
                                <path className="cls-1" d="m19.59,13.42c-.05,0-.11,0-.16-.03-.26-.09-.4-.38-.31-.64.13-.38.2-.78.2-1.19,0-2.03-1.65-3.68-3.68-3.68-1.37,0-2.61.75-3.25,1.96-.13.24-.43.34-.68.21-.24-.13-.34-.43-.21-.68.81-1.54,2.4-2.5,4.14-2.5,2.58,0,4.68,2.1,4.68,4.68,0,.52-.09,1.03-.25,1.52-.07.21-.27.34-.47.34Z"/>
                            </g>
                        </g>
                    </g>
                    <g id="obieg_1" data-name="obieg 1">
                        <path className="cls-1" d="m187.22,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                        {scada.OB1_XZM_TRANSM === 0 ?
                            <path className="cls-5" d="m178.33,10.16c.19-.03.42,0,.42.42v7.87h1.79c.31,0,.45.1.45.42v1.17c0,.31-.13.44-.45.44h-6.12c-.31,0-.45-.12-.45-.44v-1.17c0-.31.13-.42.45-.42h1.86v-5.92l-1.86.27c-.28.04-.45-.06-.45-.38v-1.17c0-.31.17-.44.45-.48l3.92-.61Z"/>
                            : null
                        }
                    </g>
                    {scada.OB1_XZM_TRANSM === 0 ?
                        <g id="obieg_2" data-name="obieg 2">
                            <path className="cls-1" d="m351.8,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.25-.54-.36-.92-.36Z"/>
                            <path className="cls-5" d="m345.13,18.45c.31,0,.45.1.45.42v1.17c0,.31-.14.44-.45.44h-6.21c-.31,0-.45-.12-.45-.44v-2.7c0-.81.38-1.06.92-1.32l3.19-1.46c.27-.12.48-.3.48-.79v-.66c0-.69-.51-.98-1.21-.98s-1.2.29-1.2.98v.54c0,.31-.1.45-.42.45h-1.54c-.31,0-.44-.14-.44-.45v-.93c0-1.29.9-2.56,3.6-2.56s3.65,1.27,3.66,2.56v.96c0,1.58-.78,2.19-1.45,2.49l-2.54,1.15c-.46.21-.57.31-.57.75v.38h4.18Z"/>
                        </g>
                        : null
                    }
                    {scada.OB1_XZM_TRANSM === 0 && scada.OB2_XZM_TRANSM === 0 ?
                        <g id="obieg_3" data-name="obieg 3">
                            <path className="cls-1" d="m516.38,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                            <path className="cls-5" d="m506.29,14.31c.74,0,1.25-.29,1.25-.98v-.25c0-.69-.39-.98-1.12-.98s-1.14.29-1.14.98v.18c0,.31-.12.45-.44.45h-1.54c-.31,0-.42-.14-.42-.45v-.54c0-1.29.75-2.56,3.57-2.56s3.52,1.21,3.52,2.5v1.05c0,.73-.3,1.21-1.05,1.5.85.38,1.2.96,1.2,1.71v1.17c0,1.29-.84,2.5-3.63,2.5s-3.67-1.27-3.67-2.56v-.54c0-.31.1-.45.42-.45h1.54c.31,0,.44.14.44.45v.18c0,.69.51.97,1.24.97s1.23-.28,1.23-.97v-.45c0-.69-.51-.96-1.24-.96h-1.06c-.31,0-.45-.12-.45-.44v-1.09c0-.31.14-.42.45-.42h.91Z"/>
                        </g>
                        : null
                    }
                    {scada.OB1_XZM_TRANSM === 0 && scada.OB2_XZM_TRANSM === 0 && scada.OB3_XZM_TRANSM === 0 ?
                        <g id="obieg_4" data-name="obieg 4">
                            <path className="cls-1" d="m680.96,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                            <path className="cls-5" d="m669.73,15.89h2.32v-5.16c0-.31.12-.45.44-.45h1.62c.31,0,.42.14.42.45v9.3c0,.32-.1.45-.42.45h-1.62c-.31,0-.44-.13-.44-.45v-2.11h-4.42c-.31,0-.45-.12-.45-.44v-1.15c0-.1.03-.21.04-.27l1.53-5.32c.08-.29.23-.45.54-.45h1.62c.31,0,.39.18.31.45l-1.5,5.16Z"/>
                        </g>
                        : null
                    }
                </g>
                {scada.TSM9 < -45 ?
                    <text x="40" y="24.21" className="text-scada">E 1</text>
                    : scada.TSM9 > 145 ?
                        <text x="40" y="24.21" className="text-scada">E 2</text>
                        : <text x="40" y="24.21" className="text-scada">{scada.TSM9.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                }
                {scada.TSM10 < -45 ?
                    <text x="205" y="24.21" className="text-scada">E 1</text>
                    : scada.TSM10 > 145 ?
                        <text x="205" y="24.21" className="text-scada">E 2</text>
                        : <text x="205" y="24.21" className="text-scada">{scada.TSM10.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                }
                {scada.OB1_XZM_TRANSM === 0 ? 
                    scada.OB1_TWEW < -45 ?
                        <text x="368" y="24.21" className="text-scada">E 1</text>
                        : scada.OB1_TWEW > 145 ?
                            <text x="368" y="24.21" className="text-scada">E 2</text>
                            : <text x="368" y="24.21" className="text-scada">{scada.OB1_TWEW.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                    : null
                }
                {scada.OB1_XZM_TRANSM === 0 && scada.OB2_XZM_TRANSM === 0 ?  
                    scada.OB2_TWEW < -45 ?
                        <text x="533" y="24.21" className="text-scada">E 1</text>
                        : scada.OB2_TWEW > 145 ?
                            <text x="533" y="24.21" className="text-scada">E 2</text>
                            : <text x="533" y="24.21" className="text-scada">{scada.OB2_TWEW.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                    : null
                }
                {scada.OB1_XZM_TRANSM === 0 && scada.OB2_XZM_TRANSM === 0 && scada.OB3_XZM_TRANSM === 0 ?
                    scada.OB3_TWEW < -45 ?
                        <text x="698" y="24.21" className="text-scada">E 1</text>
                        : scada.OB3_TWEW > 145 ?
                            <text x="698" y="24.21" className="text-scada">E 2</text>
                            : <text x="698" y="24.21" className="text-scada">{scada.OB3_TWEW.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                    : null
                }
            </svg>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 762 498">
                <g className='ncbr'>
                    <g id="Layer_1" className="st0">
                        <rect x="3.1" y="2.2" className="st1" width="758.9" height="495.8"/>
                    </g>
                    <g id="czesc_stala_regeneracji">
                        <polyline className="st2" points="526.4,347.8 458.3,347.8 458.3,444.6 526.4,444.6 	"/>
                        <line className="st2" x1="458.3" y1="347.8" x2="458.3" y2="444.6"/>
                    </g>
                    <g id="zasobnik_CWU">
                        <path id="zbiornik_CWU_ramka" className="st3" d="M662.3,484.6H537.8c-4.9,0-9-4-9-9V314.1c0-4.9,4-9,9-9h124.5c4.9,0,9,4,9,9v161.5
                            C671.3,480.6,667.3,484.6,662.3,484.6z"/>
                        <path id="woda_-_kolor_zielny_lub_pomarancz" className={(scada.SBF_DHS === 1 && scada.SBF_DHX === 1 && scada.SBF_OOF === 1 && scada.SBB_QHW === 1) || scada.SIO2_R01_G1 === 1 ?
                            `st28`
                            : `st12`} d="M667.3,321.3v153.1c0,3.2-2.6,5.8-5.8,5.8H538.6
                            c-3.2,0-5.8-2.6-5.8-5.8V321.3c1.2-0.4,2.3-1,3.3-1.6c3.1,1.7,6.6,2.7,10.3,2.7c3.9,0,7.5-1,10.6-2.8c3.2,2,6.9,3.1,11,3.1
                            c3.9,0,7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9
                            c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9C665,320.4,666.1,320.9,667.3,321.3z"/>
                        <g id="opisy_00000089552245596248999920000012503336143325244293_">
                            {scada.TSM15 < -45 ?
                                <text x="575.85" y="391.81" className="st5 st6 st7">E 1</text>
                                : scada.TSM15 > 145 ?
                                    <text x="575.85" y="391.81" className="st5 st6 st7">E 2</text>
                                    : <text x={Math.round(scada.TSM15) > 9 || Math.round(scada.TSM15) < 0 ? "558.85" : "568.85"} y="391.81" className="st5 st6 st7">{scada.TSM15 < 0 || scada.TSM15 > 99 ? "-- " : Math.round(scada.TSM15)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            <text transform="matrix(1 0 0 1 569.7494 358.3141)" className="st5 st6 st8">CWU</text>
                            {!scada.ADC_2 || scada.ADC_2 === "NA" ? null : <text x="561.14" y="449.69" className="text-scada middle bar">{scada.ADC_2.toFixed(1)} bar</text>}
                        </g>
                        {scada.SIO2_R01_G1 === 1 ? 
                            <path id="grzalka_CWU" className="st10" d="M582.6,401c3.8,0,7.6,5.3,8.9,11.8c1.3,6.5-0.3,11.8-3.3,11.8c-3,0-4.6-5.3-3.2-11.8
                            c1.4-6.5,5.4-11.8,9.3-11.8s7.9,5.3,9.3,11.8c1.4,6.5-0.1,11.8-3,11.8c-2.9,0-4.5-5.3-3.1-11.8c1.4-6.5,5.4-11.8,9.3-11.8
                            c3.9,0,7.8,5.3,9.2,11.8c1.3,6.5-0.3,11.8-3.3,11.8c-3,0-4.6-5.3-3.4-11.8c1.2-6.5,5-11.8,8.8-11.8"/>
                            : null
                        }
                        <g id="pasek_naladowania_00000126314486747808341070000010064556191338655112_">
                            <path id="tlo_wartosci_naladowania_CWU" 
                            d="M642.8,471.8h-84.5c-3.8,0-6.9-3.1-6.9-6.9v0c0-3.8,3.1-6.9,6.9-6.9h84.5
                                c3.8,0,6.9,3.1,6.9,6.9v0C649.7,468.7,646.6,471.8,642.8,471.8z"/>
                            <line id="wartosc_naladowania_CWU" className={(scada.SBF_DHS === 1 && scada.SBF_DHX === 1 && scada.SBF_OOF === 1 && scada.SBB_QHW === 1) || scada.SIO2_R01_G1 === 1 ?
                                `st11`
                                : `st13`} x1="558.4" y1="464.9" x2={getCWUStorage()} y2="464.9"/>
                        </g>
                    </g>
                    <g id="bufor_CO">
                        <path id="zbiornik_CO_ramka" className="st3" d="M231.2,484.6H106.8c-4.9,0-9-4-9-9V314.1c0-4.9,4-9,9-9h124.5c4.9,0,9,4,9,9v161.5
                            C240.2,480.6,236.2,484.6,231.2,484.6z"/>
                        <path id="woda_zielona_-_nie_grzanie_-_pomarancz_grzanie" className={`${scada.SXF_TOE === 1 && scada.SBF_OOF === 1 ?
                            scada.SBB_QHT === 1 || scada.SIO2_R02_G2 === 1 ? `st28`
                                : `st12`
                            : `st12`}`} d="M236.3,321.3v153.1c0,3.2-2.6,5.8-5.8,5.8H107.5
                            c-3.2,0-5.8-2.6-5.8-5.8V321.3c1.2-0.4,2.3-1,3.3-1.6c3.1,1.7,6.6,2.7,10.3,2.7c3.9,0,7.5-1,10.6-2.8c3.2,2,6.9,3.1,11,3.1
                            c3.9,0,7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9
                            c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9C233.9,320.4,235,320.9,236.3,321.3z"/>
                        <g id="opisy_00000088126198291203434490000010079513035585895354_">
                            {scada.TSM17 < -45 ?
                                <text x="144.32" y="391.81" className="st5 st6 st7">E 1</text>
                                : scada.TSM17 > 145 ?
                                    <text x="144.32" y="391.81" className="st5 st6 st7">E 2</text>
                                    : <text x={Math.round(scada.TSM17) > 9 || Math.round(scada.TSM17) < 0 ? "127.32" : "137.32"} y="391.81" className="st5 st6 st7">{scada.TSM17 < 0 || scada.TSM17 > 99 ? "-- " : Math.round(scada.TSM17)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            {!scada.ADC_1 || scada.ADC_1 === "NA" ? null : <text x="129.62" y="452.27" className="text-scada middle bar">{scada.ADC_1.toFixed(1)} bar</text>}
                            <text transform="matrix(1 0 0 1 112.9313 358.3141)" className="st5 st6 st8">bufor CO</text>
                        </g>
                        {scada.SIO2_R02_G2 === 1 ?
                            <path id="grzalka_CO" className="st10" d="M151.1,401c3.8,0,7.6,5.3,8.9,11.8c1.3,6.5-0.3,11.8-3.3,11.8s-4.6-5.3-3.2-11.8
                            c1.4-6.5,5.4-11.8,9.3-11.8s7.9,5.3,9.3,11.8c1.4,6.5-0.1,11.8-3,11.8c-2.9,0-4.5-5.3-3.1-11.8c1.4-6.5,5.4-11.8,9.3-11.8
                            c3.9,0,7.8,5.3,9.2,11.8c1.3,6.5-0.3,11.8-3.3,11.8s-4.6-5.3-3.4-11.8c1.2-6.5,5-11.8,8.8-11.8"/>
                            : null
                        }
                        <g id="pasek_naladowania_00000052811801671291623650000002252326150743476376_">
                            <path id="tlo_paska" d="M208.8,471.8h-84.5c-3.8,0-6.9-3.1-6.9-6.9v0c0-3.8,3.1-6.9,6.9-6.9h84.5c3.8,0,6.9,3.1,6.9,6.9v0
                                C215.7,468.7,212.6,471.8,208.8,471.8z"/>
                            <line id="wartosc_naladowania" className={`${scada.SXF_TOE === 1 && scada.SBF_OOF === 1 ?
                                scada.SBB_QHT === 1 || scada.SIO2_R02_G2 === 1 ? `st11`
                                    : `st13`
                                : `st13`}`} x1="124.4" y1="464.9" x2={getCOStorage()} y2="464.9"/>
                        </g>
                    </g>
                    <g id="bufor_chlodu">
                        <path id="zbiornik_CH_ramka" className="st3" d="M231.2,226.7H106.8c-4.9,0-9-4-9-9V56.2c0-4.9,4-9,9-9h124.5c4.9,0,9,4,9,9v161.5
                            C240.2,222.7,236.2,226.7,231.2,226.7z"/>
                        <path id="glikol_niebiekski_lub_zielony" className={scada.PV_READY_AC === 1 ? "st14" : "st12"} d="M236.3,61.9v154.6c0,3.2-2.6,5.8-5.8,5.8H107.5c-3.2,0-5.8-2.6-5.8-5.8
                            V61.8c1.2-0.4,2.3-1,3.3-1.6c3.1,1.7,6.6,2.7,10.3,2.7c3.9,0,7.5-1,10.6-2.8c3.2,2,6.9,3.1,11,3.1c3.9,0,7.5-1.1,10.7-2.9
                            c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9
                            c3.1,1.8,6.8,2.9,10.7,2.9s7.5-1.1,10.7-2.9C233.9,60.9,235,61.4,236.3,61.9z"/>
                        <g id="opisy_00000034801339462766307140000013234351748221286069_">
                            {scada.TSM17 < -45 ?
                                <text x="145.18" y="391.81" className="st5 st6 st7">E 1</text>
                                : scada.TSM17 > 145 ?
                                    <text x="145.18" y="391.81" className="st5 st6 st7">E 2</text>
                                    : <text x={Math.round(scada.T_Z2_1) > 9 || Math.round(scada.T_Z2_1) < 0 ? "128.18" : "138.18"} y="135.15" className="st5 st6 st7">{scada.T_Z2_1 > 99 ? "-- " : Math.round(scada.T_Z2_1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            <text transform="matrix(1 0 0 1 112.4131 100.3894)" className="st5 st6 st8">bufor CH</text>
                            {!scada.ADC_3 || scada.ADC_3 === "NA" ? null : <text x="129.51" y="195.5" className="text-scada middle bar">{scada.ADC_3.toFixed(1)} bar</text>}
                        </g>
                        <g id="pasek_naladowania">
                            <path id="tlo_naladowania_chlodu" d="M208.8,214.8h-84.5c-3.8,0-6.9-3.1-6.9-6.9v0c0-3.8,3.1-6.9,6.9-6.9h84.5
                                c3.8,0,6.9,3.1,6.9,6.9v0C215.7,211.7,212.6,214.8,208.8,214.8z"/>
                            <line id="naladowanie_chlodu" className={scada.PV_READY_AC === 1 ? "st15" : "st13"} 
                                x1="124.4" y1="207.9" x2={getCoolStorage()} y2="207.9"/>
                        </g>
                    </g>
                    <g id="gorne_zrodlo">
                        <rect x="623.6" y="204.9" className="st16" width="17.9" height="4.5"/>
                        <path className="st17" d="M624.9,204.9h15.2c7.9,0,14.4-6.4,14.4-14.4V74.1c0-7.9-6.4-14.4-14.4-14.4h-15.2c-7.9,0-14.4,6.4-14.4,14.4
                            v116.4C610.6,198.5,617,204.9,624.9,204.9z"/>
                        <path className="st18" d="M650.2,74.1v116.7c0,5.4-4.3,9.7-9.7,9.7h-15.9c-5.4,0-9.7-4.3-9.7-9.7V74.1c0-5.4,4.3-9.7,9.7-9.7h15.9
                            C645.9,64.4,650.2,68.8,650.2,74.1z"/>
                        <g id="Layer_30">
                            <path className="st19" d="M632.6,209.5v19.8c0,3.6-2.9,6.5-6.5,6.5h-57.5"/>
                            <path className="st20" d="M571.8,32.7l54.8-0.2c3.3,0,6,2.7,6,6v21.3"/>
                            <path className="st21" d="M650.2,159.9v30.9c0,5.4-4.3,9.7-9.7,9.7h-15.9c-5.4,0-9.7-4.3-9.7-9.7v-30.9H650.2z"/>
                            <path className="st21" d="M634.6,106.8c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6
                                C635.3,105.2,634.6,105.9,634.6,106.8z"/>
                            <path className="st21" d="M616.3,102.7c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
                                C617,101.3,616.3,101.9,616.3,102.7z"/>
                            <path className="st21" d="M629.5,122.8c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
                                C629.9,121.9,629.5,122.3,629.5,122.8z"/>
                            <path className="st21" d="M631.3,146.3c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6
                                C631.6,145.7,631.3,146,631.3,146.3z"/>
                            <path className="st21" d="M643.2,133.9c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6
                                C644,132.3,643.2,133,643.2,133.9z"/>
                            <path className="st21" d="M644.1,119.5c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2S644.1,118.8,644.1,119.5z"/>
                            <path className="st21" d="M616.3,121.2c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7
                                C617.1,119.5,616.3,120.2,616.3,121.2z"/>
                            <path className="st21" d="M616.3,134.3c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
                                C617,133,616.3,133.6,616.3,134.3z"/>
                            <path className="st21" d="M629.4,106.7c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6S629.4,107.6,629.4,106.7z"/>
                            <path className="st21" d="M647.6,110.8c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
                                C647,112.1,647.6,111.5,647.6,110.8z"/>
                            <path className="st21" d="M634.5,90.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
                                C634.1,91.6,634.5,91.2,634.5,90.7z"/>
                            <path className="st21" d="M631,83c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6C630.8,83.6,631,83.3,631,83z"
                                />
                            <path className="st21" d="M620.7,79.5c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6S620.7,80.4,620.7,79.5z"/>
                            <path className="st21" d="M619.9,94c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2S619.9,94.6,619.9,94z"/>
                            <path className="st21" d="M647.6,92.3c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7
                                C646.9,94,647.6,93.2,647.6,92.3z"/>
                            <path className="st21" d="M647.6,79.1c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
                                C647,80.5,647.6,79.9,647.6,79.1z"/>
                            <path className="st22" d="M610.4,136.9c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5h44c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5
                                "/>
                            <path className="st22" d="M610.4,110.9c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5h44c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5
                                "/>
                            <path className="st22" d="M610.4,84.8c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5h44c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5"
                                />
                            <line className="st22" x1="742.3" y1="85" x2="654.5" y2="84.8"/>
                            <path className="st22" d="M747.2,176.2h-82.3l-54.2-0.2c-3.8,0-7.2-2.7-7.3-6.2c-0.2-3.7,3.1-6.8,7.1-6.8"/>
                            <polygon className="st23" points="735.9,85 735.9,77.6 742.3,81.3 748.8,85 742.3,88.8 735.9,92.5 		"/>
                            <polygon className="st23" points="748.8,175.7 748.8,183.1 742.3,179.4 735.9,175.7 742.3,172 748.8,168.2 		"/>
                        </g>
                    </g>
                    <g id="wymiennik_dolnego_zrodla">
                        <path className="st24" d="M466,209.5v19.8c0,3.6,2.9,6.5,6.5,6.5h74.7"/>
                        <path className="st24" d="M524.6,32.5h-49.3c-3.3,0-6,2.7-6,6v21.3"/>
                        <rect x="457.4" y="204.4" className="st16" width="17.9" height="4.5"/>
                        <path className="st17" d="M474,204.4h-15.2c-7.9,0-14.4-6.4-14.4-14.4V73.7c0-7.9,6.4-14.4,14.4-14.4H474c7.9,0,14.4,6.4,14.4,14.4
                            v116.4C488.3,198,481.9,204.4,474,204.4z"/>
                        <path className="st18" d="M448.7,73.7v116.7c0,5.4,4.3,9.7,9.7,9.7h15.9c5.4,0,9.7-4.3,9.7-9.7V73.7c0-5.4-4.3-9.7-9.7-9.7h-15.9
                            C453.1,63.9,448.7,68.3,448.7,73.7z"/>
                        <path className="st14" d="M448.7,142v48.4c0,5.4,4.3,9.7,9.7,9.7h15.9c5.4,0,9.7-4.3,9.7-9.7V142H448.7z"/>
                        <path className="st14" d="M464.3,106.3c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
                            C463.6,104.7,464.3,105.4,464.3,106.3z"/>
                        <path className="st14" d="M482.6,102.2c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                            C482,100.9,482.6,101.5,482.6,102.2z"/>
                        <path className="st14" d="M469.4,122.3c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
                            C469,121.4,469.4,121.8,469.4,122.3z"/>
                        <path className="st14" d="M467.6,145.8c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6
                            C467.3,145.2,467.6,145.5,467.6,145.8z"/>
                        <path className="st14" d="M455.7,133.5c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
                            C454.9,131.8,455.7,132.6,455.7,133.5z"/>
                        <path className="st14" d="M454.9,119c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2
                            C454.3,117.8,454.9,118.3,454.9,119z"/>
                        <path className="st14" d="M482.6,120.7c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7
                            C481.8,119,482.6,119.8,482.6,120.7z"/>
                        <path className="st14" d="M482.6,133.9c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                            C482,132.5,482.6,133.1,482.6,133.9z"/>
                        <path className="st14" d="M469.5,106.2c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
                            C470.2,107.8,469.5,107.1,469.5,106.2z"/>
                        <path className="st14" d="M451.3,110.3c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
                            C451.9,111.6,451.3,111,451.3,110.3z"/>
                        <path className="st14" d="M464.4,90.2c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9
                            C464.8,91.1,464.4,90.7,464.4,90.2z"/>
                        <path className="st14" d="M467.9,82.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6
                            C468.1,83.1,467.9,82.9,467.9,82.5z"/>
                        <path className="st14" d="M478.2,79c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
                            C478.9,80.7,478.2,79.9,478.2,79z"/>
                        <path className="st14" d="M479,93.5c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2C479.5,94.7,479,94.2,479,93.5z
                            "/>
                        <path className="st14" d="M451.3,91.8c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7
                            C452,93.5,451.3,92.7,451.3,91.8z"/>
                        <path className="st14" d="M451.3,78.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
                            C451.9,80,451.3,79.4,451.3,78.6z"/>
                        <path className="st24" d="M488.5,136.5c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5h-44c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5
                            "/>
                        <path className="st24" d="M488.5,110.4c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5h-44c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5
                            "/>
                        <path className="st24" d="M488.5,84.3c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5h-44c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5"
                            />
                        <line className="st24" x1="444.4" y1="84.3" x2="427.6" y2="84.3"/>
                    </g>
                    <g id="napis_CWU__x2F__CO_zawor_trojdorzny_na_gornym_zrodle">
                        {scada.SIO_R09 === 1 ? 
                            <g id="CWU_00000123407678835669772590000006380725593424702909_">
                                <polygon className="st4" points="676.2,27.1 676.2,19.6 682.6,23.4 689.1,27.1 682.6,30.8 676.2,34.6 		"/>
                                <text transform="matrix(1 0 0 1 691.7345 37.5593)" className="st4 st6 st8">CWU</text>
                            </g>
                            : <g id="CO">
                                <polygon className="st28" points="676.2,27.1 676.2,19.6 682.6,23.4 689.1,27.1 682.6,30.8 676.2,34.6 		"/>
                                <text transform="matrix(1 0 0 1 691.7345 37.5593)" className="st28 st6 st8">CO</text>
                            </g>
                        }
                    </g>
                    
                    {scada.T3fiz < -45 ?
                        <text x="388.08" y="132.47" className={`st26 st27 st9 ${scada.SIO_R07 === 1 ? "white" : "grey"}`}>E 1</text>
                        : scada.T3fiz > 145 ?
                            <text x="388.08" y="132.47" className={`st26 st27 st9 ${scada.SIO_R07 === 1 ? "white" : "grey"}`}>E 2</text>
                            : <text x="345.08" y="132.47" className={`st26 st27 st9 ${scada.SIO_R07 === 1 ? "white" : "grey"}`}>{scada.T3fiz.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    }
                    {scada.T4fiz < -45 ?
                        <text x="388.08" y="156.83" className={`st26 st27 st9 ${scada.SIO_R07 === 1 ? "white" : "grey"}`}>E 1</text>
                        : scada.T4fiz > 145 ?
                            <text x="388.08" y="156.83" className={`st26 st27 st9 ${scada.SIO_R07 === 1 ? "white" : "grey"}`}>E 2</text>
                            : <text x="345.08" y="156.83" className={`st26 st27 st9 ${scada.SIO_R07 === 1 ? "white" : "grey"}`}>{scada.T4fiz.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    }
                    {scada.SIO2_R07_DZ === 1 && scada.SIO2_R11_P_REGEN === 1 ? 
                        <g id="STAN_3__dolne_zrodlo__x2F__praca_na_bufor__x2B__regeneracja">
                            <path className="st24" d="M242.6,175.6h245.7c3.8,0,7.2-2.7,7.3-6.2c0.2-3.7-3.1-6.8-7.1-6.8"/>
                            <line className="st24" x1="383.2" y1="84.3" x2="242.6" y2="84.3"/>
                            <polygon className="st25" points="352.8,76.9 339.9,84.3 352.8,91.8"/> 
                            <polygon className="st25" points="339.9,183 352.8,175.6 339.9,168.1"/> 
                            <polygon class="st25" points="378.8,347.8 378.8,355.3 372.4,351.6 365.9,347.8 372.4,344.1 378.8,340.4 	"/>
                            <polygon class="st25" points="410.7,401 418.2,401 414.5,407.5 410.7,413.9 407,407.5 403.3,401 	"/>
                            <polygon class="st25" points="268.5,399.9 276,399.9 272.3,406.3 268.5,412.8 264.8,406.3 261.1,399.9 	"/>
                            <polygon class="st25" points="307,412.8 299.6,412.8 303.3,406.3 307,399.9 310.7,406.3 314.5,412.8 	"/>
                            <path class="st24" d="M448.3,444.6v-96.8H273.5c-2.8,0-5,2.2-5,5v112.5c0,10.6,8.6,19.2,19.2,19.2l0,0c10.6,0,19.2-8.6,19.2-19.2
                                v-77.6c0-2.8,2.2-5,5-5h93.9c2.8,0,5,2.2,5,5l0.1,51.9c0,2.8,2.2,5,5,5H448.3z"/>
                            <g id="opisy_00000107564176632560436990000005341897011424783533_">
                                {scada.T_ODW_IN < -45 ?
                                    <text x="290.62" y="336.25" className="st26 st27 st9">E 1</text>
                                    : scada.T_ODW_IN > 145 ?
                                        <text x="290.62" y="336.25" className="st26 st27 st9">E 2</text>
                                        : <text x="290.62" y="336.25" className="st26 st27 st9">{scada.T_ODW_IN.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                {scada.T_ODW_OUT < -45 ?
                                    <text x="346.31" y="410.10" className="st26 st27 st9">E 1</text>
                                    : scada.T_ODW_OUT > 145 ?
                                        <text x="346.31" y="410.10" className="st26 st27 st9">E 2</text>
                                        : <text x="322.31" y="410.10" className="st26 st27 st9">{scada.T_ODW_OUT.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                            </g>
                            <g>
                                <polygon className="st25" points="325.3,27.1 325.3,19.6 331.8,23.4 338.2,27.1 331.8,30.8 325.3,34.6 		"/>
                                <text transform="matrix(1 0 0 1 340.9044 37.5593)" className="st25 st6 st8">BUFOR</text>
                            </g>
                            <g>
                                <polygon className="st25" points="325.3,279.9 325.3,272.5 331.8,276.2 338.2,279.9 331.8,283.7 325.3,287.4 		"/>
                                <text transform="matrix(1 0 0 1 340.9044 290.391)" className="st25 st6 st8">REGENERACJA</text>
                            </g>
                        </g>
                        : scada.SIO2_R07_DZ === 1 && scada.SIO2_R11_P_REGEN === 0 ?
                            <g id="STAN_2__dolne_zrodlo__x2F__praca_na_bufor_bez_regeneracji">
                                <path className="st24" d="M242.6,175.6h245.7c3.8,0,7.2-2.7,7.3-6.2c0.2-3.7-3.1-6.8-7.1-6.8"/>
                                <line className="st24" x1="383.2" y1="84.3" x2="242.6" y2="84.3"/>
                                <polygon className="st25" points="352.8,76.9 339.9,84.3 352.8,91.8 	"/> 
                                <polygon className="st25" points="339.9,183 352.8,175.6 339.9,168.1 	"/>
                                <g>
                                    <polygon className="st25" points="325.3,27.1 325.3,19.6 331.8,23.4 338.2,27.1 331.8,30.8 325.3,34.6 		"/>
                                    <text transform="matrix(1 0 0 1 340.9044 37.5593)" className="st25 st6 st8">BUFOR</text>
                                </g>
                                {scada.T_ODW_IN < -45 ?
                                    <text x="250.54" y="287.39" className="st26 st27 st9">E 1</text>
                                    : scada.T_ODW_IN > 145 ?
                                        <text x="250.54" y="287.39" className="st26 st27 st9">E 2</text>
                                        : <text x="213.54" y="287.39" className="st26 st27 st9">{scada.T_ODW_IN.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                {scada.T_ODW_OUT < -45 ?
                                    <text x="341.17" y="287.39" className="st26 st27 st9">E 1</text>
                                    : scada.T_ODW_OUT > 145 ?
                                        <text x="341.17" y="287.39" className="st26 st27 st9">E 2</text>
                                        : <text x="341.17" y="287.39" className="st26 st27 st9">{scada.T_ODW_OUT.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                <path className="st24" d="M333.7,247.7v217.6c0,10.6-8.6,19.2-19.2,19.2h0c-10.6,0-19.2-8.6-19.2-19.2V247.7"/>
                                <polyline className="st24" points="372.4,347.8 448.3,347.8 448.3,444.6 372.4,444.6 	"/>
                                <polygon className="st25" points="378.8,347.8 378.8,355.3 372.4,351.5 365.9,347.8 372.4,344.1 378.8,340.3 	"/>
                                <polygon className="st25" points="365.9,444.6 365.9,437.1 372.4,440.8 378.8,444.6 372.4,448.3 365.9,452 	"/>
                                <polygon className="st25" points="295.2,298.7 302.7,298.7 299,305.1 295.2,311.6 291.5,305.1 287.8,298.7 	"/>
                                <polygon className="st25" points="333.7,311.6 326.3,311.6 330,305.1 333.7,298.7 337.4,305.1 341.2,311.6 	"/>
                            </g>
                            : <g id="STAN_1__dolne_zrodlo__x2F__praca_na_odwiert">
                                {scada.T_ODW_IN < -45 ?
                                    <text x="253.54" y="287.39" className="st26 st27 st9">E 1</text>
                                    : scada.T_ODW_IN > 145 ?
                                        <text x="253.54" y="287.39" className="st26 st27 st9">E 2</text>
                                        : <text x="213.54" y="287.39" className="st26 st27 st9">{scada.T_ODW_IN.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                {scada.T_ODW_OUT < -45 ?
                                    <text x="341.17" y="287.39" className="st26 st27 st9">E 1</text>
                                    : scada.T_ODW_OUT > 145 ?
                                        <text x="341.17" y="287.39" className="st26 st27 st9">E 2</text>
                                        : <text x="341.17" y="287.39" className="st26 st27 st9">{scada.T_ODW_OUT.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                <polyline className="st24" points="372.6,347.7 448.6,347.7 448.6,444.4 372.6,444.4 	"/>
                                <polygon className="st25" points="379.1,347.7 379.1,355.1 372.6,351.4 366.2,347.7 372.6,343.9 379.1,340.2 	"/>
                                <polygon className="st25" points="366.2,444.4 366.2,437 372.6,440.7 379.1,444.4 372.6,448.1 366.2,451.9 	"/>
                                <g>
                                    <line className="st24" x1="243.4" y1="84.7" x2="267.8" y2="84.7"/>
                                    <line className="st24" x1="243.4" y1="175.8" x2="267.8" y2="175.8"/>
                                    <polygon className="st25" points="263.1,168.4 276,175.9 263.1,183.4"/> 
                                    <polygon className="st25" points="276,91.7 263.1,84.3 276,76.8"/> 
                                </g>
                                <polygon className="st25" points="372.8,76.9 359.9,84.3 372.8,91.8"/> 
                                <polygon className="st25" points="359.9,183 372.8,175.6 359.9,168.1"/> 
                                <path className="st24" d="M383.6,84.3h-77.5c-5.8,0-10.5,4.7-10.5,10.5v370.5c0,10.6,8.6,19.2,19.2,19.2h0c10.6,0,19.2-8.6,19.2-19.2
                                    V186c0-5.8,4.7-10.5,10.5-10.5h144.1c3.8,0,7.2-2.7,7.3-6.2c0.2-3.7-3.1-6.8-7.1-6.8"/>
                                <polygon className="st25" points="295.7,298.5 303.1,298.5 299.4,305 295.7,311.4 291.9,305 288.2,298.5 	"/>
                                <polygon className="st25" points="334.1,311.4 326.7,311.4 330.4,305 334.1,298.5 337.9,305 341.6,311.4 	"/>
                                <polygon className="st25" points="319.1,26.9 319.1,19.5 325.6,23.2 332.1,26.9 325.6,30.7 319.1,34.4 	"/>
                                <text transform="matrix(1 0 0 1 334.7289 37.3983)" className="st25 st6 st8">ODWIERT</text>
                            </g>
                    }
                    <g id="opisy">
                        {/* <text transform="matrix(1 0 0 1 536.7491 77.2644)" className="st26 st27 st9">ON</text> */}
                        {scada.T_OB_CHL < -45 ?
                            <text x="43.69" y="132.47" className="st26 st27 st9">E 1</text>
                            : scada.T_OB_CHL > 145 ?
                                <text x="43.69" y="132.47" className="st26 st27 st9">E 2</text>
                                : <text x="17.69" y="132.47" className="st26 st27 st9">{scada.T_OB_CHL.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                        }
                        {scada.SSB_QHL === 1 ?
                            scada.TSM14 < -45 ?
                                <text x="682.42" y="119.68" className={`text-scada ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 1</text>
                                : scada.TSM14 > 145 ?
                                    <text x="682.42" y="119.68" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 2</text>
                                    : <text x="682.42" y="119.68" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>{scada.TSM14.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                : scada.TSM13 < -45 ?
                                    <text x="682.42" y="119.68" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 1</text>
                                    : scada.TSM13 > 145 ?
                                        <text x="682.42" y="119.68" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 2</text>
                                        : <text x="682.42" y="119.68" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>{scada.TSM13.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                        }
                        {scada.SSB_QHL === 1 ?
                            scada.TSM13 < -45 ?
                                <text x="682.42" y="144.05" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 1</text>
                                : scada.TSM13 > 145 ?
                                    <text x="682.42" y="144.05" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 2</text>
                                    : <text x="682.42" y="144.05" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>{scada.TSM13.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                : scada.TSM14 < -45 ?
                                    <text x="682.42" y="144.05" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 1</text>
                                    : scada.TSM14 > 145 ?
                                        <text x="682.42" y="144.05" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 2</text>
                                        : <text x="682.42" y="144.05" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>{scada.TSM14.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                        }
                        {scada.OB1_TCO < -45 ?
                            <text x="43.69" y="399.26" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 1</text>
                            : scada.OB1_TCO  > 145 ?
                                <text x="43.69" y="399.26" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>E 2</text>
                                : <text x="17.69" y="399.26" className={`st26 st27 st9 ${scada.SIO_R08 === 1 ? "white" : "grey"}`}>{scada.OB1_TCO.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>

                        }
                        {/*<text x="520" y="10" className={'st26 st27 st9'}>{scada.TSM16.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>*/} 
                        <text transform="matrix(1 0 0 1 589.6798 287.4177)" className="st26 st27 st9">p. cyrkulacyjna</text>
                        <text transform="matrix(1 0 0 1 22.5016 287.4177)" className="st26 st27 st9">pompa CO</text>
                        <text transform="matrix(1 0 0 1 22.5016 35.4323)" className="st26 st27 st9">pompa CH</text>
                    </g>
                    <g id="wyjscie_CO">
                        <line className="st22" x1="29" y1="347.8" x2="94.1" y2="347.8"/>
                        <line className="st22" x1="29" y1="444.6" x2="94.1" y2="444.6"/>
                        <polygon className="st23" points="35.4,347.8 35.4,340.4 29,344.1 22.5,347.8 29,351.6 35.4,355.3 	"/>
                        <polygon className="st23" points="22.5,444.8 22.5,452.3 29,448.5 35.4,444.8 29,441.1 22.5,437.4 	"/>
                    </g>
                    <g id="wyjscie_CWU">
                        <line className="st22" x1="738.6" y1="347.8" x2="673.4" y2="347.8"/>
                        <line className="st22" x1="738.6" y1="444.6" x2="673.4" y2="444.6"/>
                        <polygon className="st23" points="733.8,347.8 733.8,340.4 740.3,344.1 746.7,347.8 740.3,351.6 733.8,355.3 	"/>
                        <polygon className="st23" points="743.3,444.8 743.3,452.3 736.9,448.5 730.4,444.8 736.9,441.1 743.3,437.4 	"/>
                    </g>
                    <g id="zawor_rozprezny">
                        <path className="st30" d="M567.1,233.6v4.9c0,0.3-0.2,0.5-0.5,0.5h-17.4c-0.3,0-0.5-0.2-0.5-0.5v-4.9c0-0.3,0.2-0.5,0.5-0.5h17.4
                            C566.9,233.1,567.1,233.3,567.1,233.6z"/>
                        <g>
                            <path className="st31" d="M548.7,232.6v6.8c0,0.3-0.2,0.5-0.5,0.5h-0.5c-0.3,0-0.5-0.2-0.5-0.5v-6.8c0-0.3,0.2-0.5,0.5-0.5h0.5
                                C548.5,232.2,548.7,232.4,548.7,232.6z"/>
                        </g>
                        <g>
                            <path className="st31" d="M567.1,239.5v-6.8c0-0.3,0.2-0.5,0.5-0.5h0.5c0.3,0,0.5,0.2,0.5,0.5v6.8c0,0.3-0.2,0.5-0.5,0.5h-0.5
                                C567.3,240,567.1,239.8,567.1,239.5z"/>
                        </g>
                        <path className="st31" d="M561.5,233.1v5.9c0,0.3-0.2,0.5-0.5,0.5h-6.3c-0.3,0-0.5-0.2-0.5-0.5v-5.9c0-0.3,0.2-0.5,0.5-0.5h6.3
                            C561.3,232.6,561.5,232.9,561.5,233.1z"/>
                        <path className="st30" d="M560.6,236L560.6,236c0-0.3-0.2-0.5-0.5-0.5h-4.4c-0.3,0-0.5,0.2-0.5,0.5v0c0,0.3,0.2,0.5,0.5,0.5h4.4
                            C560.4,236.5,560.6,236.3,560.6,236z"/>
                    </g>
                    <g id="wyjscie_chlodzenia">
                        <g>
                            <line className="st24" x1="94.9" y1="84.7" x2="29.8" y2="84.7"/>
                            <line className="st24" x1="94.9" y1="175.8" x2="29.8" y2="175.8"/>
                            <polygon className="st25" points="21.6,175.9 21.6,168.4 28,172.2 34.5,175.9 28,179.6 21.6,183.4 		"/>
                            <polygon className="st25" points="34.5,84.3 34.5,91.7 28,88 21.6,84.3 28,80.5 34.5,76.8 		"/>
                        </g>
                    </g>
                    <g id="pompa_obiegu_grzewczego">
                        <g>
                            <circle className="st23" cx="64.4" cy="347.8" r="22.3"/>
                            <g className={scada.SIO_R02 === 1 ? 'heatingCircuitFan' : null }> 
                                <g>
                                    <path className="st5" d="M69.3,347.4c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                        C66.8,343.2,69,344,69.3,347.4z"/>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M61.6,343.7c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                            C59.2,348,58.8,345.7,61.6,343.7z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M62.3,352.2c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                            C67.2,352.1,65.4,353.6,62.3,352.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <circle className="st5" cx="64.4" cy="347.8" r="2.9"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="sprezarka">
                        <g id="elementy_stale_od_sprezarki">
                            <rect x="571.7" y="29.7" className="st30" width="6.5" height="5.9"/>
                            <g>
                                <rect x="578.2" y="28.8" className="st31" width="1.5" height="7.8"/>
                            </g>
                            <rect x="526" y="29.6" className="st30" width="6.5" height="5.9"/>
                            <g>
                                <rect x="524.6" y="28.6" className="st31" width="1.5" height="7.8"/>
                            </g>
                        </g>
                        <g id="sprezarka_czesc_obrotowa" className={scada.SIO_R06 === 1 ? 'compressor' : null }>
                            <circle className="st32" cx="552.2" cy="32.5" r="20.6"/>
                            <circle className="st33" cx="552.2" cy="32.5" r="16.3"/>
                            <circle className="st32" cx="546.8" cy="24.3" r="5.4"/>
                        </g>
                    </g>
                    <g id="pompa_gornego_zrodla">
                        <circle className="st23" cx="687.9" cy="175.8" r="22.3"/>
                        <g className={scada.SIO_R08 === 1 ? 'upperSourceFan' : null }>
                            <g>
                                <path className="st5" d="M692.8,175.4c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                    C690.3,171.2,692.4,172,692.8,175.4z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M685.1,171.8c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                        C682.7,176.1,682.3,173.8,685.1,171.8z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M685.8,180.3c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                        C690.7,180.2,688.9,181.7,685.8,180.3z"/>
                                </g>
                            </g>
                            <g>
                                <circle className="st5" cx="687.9" cy="175.8" r="2.9"/>
                            </g>
                        </g>
                    </g>
                    <g id="pompy_regeneracji">
                        <g id="pompa_regen_glikol">
                            <circle className="st4" cx="498.1" cy="347.8" r="22.3"/>
                            <g className={scada.SIO2_R11_P_REGEN === 1 ? 'regenPumpFan_1' : null }>
                                <g>
                                    <path className="st5" d="M503,347.4c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                        C500.4,343.2,502.6,344,503,347.4z"/>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M495.2,343.7c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                            C492.9,348,492.5,345.7,495.2,343.7z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M495.9,352.2c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                            C500.9,352.1,499.1,353.6,495.9,352.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <circle className="st5" cx="498.1" cy="347.8" r="2.9"/>
                                </g>
                            </g>
                        </g>
                        <g id="pompa_regen_woda">
                            <circle className="st25" cx="406.9" cy="347.8" r="22.3"/>
                            <g className={scada.SIO2_R11_P_REGEN === 1 ? 'regenPumpFan_2' : null }>
                                <g>
                                    <path className="st5" d="M411.8,347.4c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                        C409.3,343.2,411.4,344,411.8,347.4z"/>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M404.1,343.7c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                            C401.7,348,401.3,345.7,404.1,343.7z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M404.8,352.2c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                            C409.7,352.1,407.9,353.6,404.8,352.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <circle className="st5" cx="406.9" cy="347.8" r="2.9"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="pompa_cyrkulacyjna_CWU">
                        <circle className="st23" cx="704.9" cy="347.8" r="22.3"/>
                            <g className={scada.SIO_R03 === 1 ? 'CWUCircuitFan' : null }>
                                <g>
                                    <path className="st5" d="M709.8,347.4c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                        C707.3,343.2,709.4,344,709.8,347.4z"/>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M702.1,343.7c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                            C699.7,348,699.3,345.7,702.1,343.7z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st5" d="M702.8,352.2c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                            C707.7,352.1,705.9,353.6,702.8,352.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <circle className="st5" cx="704.9" cy="347.8" r="2.9"/>
                                </g>
                            </g>
                    </g>
                    <g id="pompa_obiegu_chlodzacego">
                        <circle className="st25" cx="61.3" cy="84.7" r="22.3"/>
                        <g className={
                            ((version_1 > 871) || (version_1 === 871 && version_2 >= 1)) ? 
                                scada.MAG_C_CH === 1 ? 
                                    scada.SIO2_R08_PCHL === 1 ?
                                        'coolingCircuiteFan' 
                                        : null 
                                    : scada.SIO_R05 === 1 ? 
                                        'coolingCircuiteFan' 
                                        : null
                                : scada.SIO_R05 === 1 ? 
                                    'coolingCircuiteFan' 
                                    : null        
                        }>
                            <g>
                                <path className="st5" d="M66.2,84.3c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                    C63.6,80.1,65.8,80.9,66.2,84.3z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M58.4,80.6c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                        C56.1,84.9,55.7,82.6,58.4,80.6z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M59.1,89.2c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                        C64,89,62.3,90.5,59.1,89.2z"/>
                                </g>
                            </g>
                            <g>
                                <circle className="st5" cx="61.3" cy="84.7" r="2.9"/>
                            </g>
                        </g>
                    </g>
                    <g id="pompa_dolnego_zrodla">
                        <circle className="st25" cx="405.4" cy="83.8" r="22.3"/>
                        <g className={scada.SIO_R07 === 1 ? 'lowerSourceFan' : null }>
                            <g>
                                <path className="st5" d="M410.4,83.4c7.3-2.2,9.1-8.3,5.6-12.8c-1.6-2.1-6-2.6-7.7,0.5c-1.7,2.9,0.3,5.4-2,8
                                    C407.8,79.2,410,80,410.4,83.4z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M402.6,79.8c-5.6-5.2-11.7-3.7-13.9,1.5c-1,2.5,0.7,6.4,4.3,6.4c3.3,0,4.6-3,8-2.3
                                        C400.3,84.1,399.9,81.8,402.6,79.8z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M403.3,88.3c-1.7,7.4,2.7,12,8.3,11.3c2.7-0.4,5.2-3.9,3.5-6.9c-1.6-2.9-4.9-2.5-6-5.8
                                        C408.2,88.2,406.5,89.7,403.3,88.3z"/>
                                </g>
                            </g>
                            <g>
                                <circle className="st5" cx="405.4" cy="83.8" r="2.9"/>
                            </g>
                        </g>                     
                    </g>
                </g>
            </svg>
        </React.Fragment>
    )
}

export default ScadaNCBR