import React, { useEffect, useState } from 'react'
import { Typography, Grid, TextField } from '@material-ui/core'
import fetchCustomer from '../../../../data/fetchCustomer'
import fetchCustomerUpdate from '../../../../data/fetchCustomerUpdate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import formatString from "format-string-by-pattern"

const formatPhone = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const formatPostCode = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('99-999', onlyNumbers)
}

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const CustomerContact = ({ token, installation_id, address, setMoreInformation, role }) => {
    const [customer, setCustomer] = useState(null)
    const [changeData, setChangeData] = useState({ change: null, data: { first_name: "", last_name: "", email: "", phone: "", street: "", street_number: "", post_code: "", city: "" } })
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        if (installation_id) {
            fetchCustomer(token, installation_id)
                .then(response => {
                    setCustomer(response)
                    setChangeData({
                        change: null, data: {
                            first_name: response.first_name ? response.first_name : "",
                            last_name: response.last_name ? response.last_name : "",
                            email: response.email ? response.email : "",
                            phone: response.phone ? response.phone : "",
                            street: response.street ? response.street : "",
                            street_number: response.street_number ? response.street_number : "",
                            post_code: response.post_code ? response.post_code : "",
                            city: response.city ? response.city : ""
                        }
                    })
                    setMoreInformation({ type: "customer", payload: response.id })
                    // setMoreInformation({ type: "customerAll", payload: response })
                    return response
                }).then(response => setMoreInformation({ type: "customerAll", payload: response }))
        }
    }, [installation_id, token, setMoreInformation])

    const handleChangeCustomerData = (e) => {
        let newData
        if (e.target.name === "phone") {
            newData = formatPhone(e.target.value)
        } else if (e.target.name === "post_code") {
            newData = formatPostCode(e.target.value)
        } else {
            newData = e.target.value
        }
        setChangeData({
            ...changeData,
            data: {
                ...changeData.data,
                [e.target.name]: newData
            }
        })
    }

    const handleChange = (value) => {
        if (changeData.change === value) {
            setChangeData({
                ...changeData,
                change: null
            })
        } else {
            setChangeData({
                ...changeData,
                change: value
            })
        }
    }

    const sendCustomerData = (val) => {
        let newCustomerData = { ...customer }
        let newData = undefined

        if (val === "email") {
            newData = formatEmail(changeData.data[val])
        } else {
            handleChange(val)
        }

        if (newData) {
            setErrors(newData)
        } else {
            if (val === "first_name") {
                newCustomerData = { ...newCustomerData, first_name: changeData.data.first_name, last_name: changeData.data.last_name }
            } else if (val === "street") {
                newCustomerData = { ...newCustomerData, street: changeData.data.street, street_number: changeData.data.street_number, post_code: changeData.data.post_code, city: changeData.data.city }
            } else {
                newCustomerData = { ...newCustomerData, [val]: changeData.data[val] }
            }

            setCustomer(newCustomerData)
            handleChange(val)
            fetchCustomerUpdate(newCustomerData).then(response => console.log(response))
        }

        setCustomer(newCustomerData)
        setChangeData({
            ...changeData,
            change: null
        })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Kontakt {customer && parseInt(customer.id) === 1 && customer.first_name === ""? <span style={{color: "red"}}> - klient nie zarejestrował urządzenia</span> : null}</Typography>
                </Grid>
            </Grid>
            {customer && customer.id !== 1 && changeData && (changeData.change === "first_name" || changeData.change === "last_name") ? (
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={8}>

                            <TextField name="first_name" label="Imię" type="text" value={changeData && changeData.data.first_name ? changeData.data.first_name : ""}
                                onChange={(e) => handleChangeCustomerData(e)} />

                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>

                            <TextField name="last_name" label="Nazwisko" type="text" value={changeData && changeData.data.last_name ? changeData.data.last_name : ""}
                                onChange={(e) => handleChangeCustomerData(e)} />

                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendCustomerData("first_name") }} /><br />
                        </Grid>
                        <Grid item xs={4}>
                            <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("first_name")} />
                        </Grid>
                    </Grid>
                </React.Fragment>

            ) : (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>
                                <p>Inwestor: {customer ? (`${customer.first_name} ${customer.last_name}`) : null}</p>
                            </Grid>
                            {customer && customer.id !== 1 && role === "admin"? <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" onClick={() => handleChange("first_name")} />
                            </Grid> : null}
                        </Grid>
                    </React.Fragment>
                )}
            {changeData && (changeData.change === "street" || changeData.change === "street_number" || changeData.change === "post_code" || changeData.change === "city") ?
                (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>

                                <TextField name="street" label="Ulica" type="text" value={changeData && changeData.data.street ? changeData.data.street : ""}
                                    onChange={(e) => handleChangeCustomerData(e)} />

                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={8}>

                                <TextField name="street_number" label="Numer ulicy" type="text" value={changeData && changeData.data.street_number ? changeData.data.street_number : ""}
                                    onChange={(e) => handleChangeCustomerData(e)} />

                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={8}>

                                <TextField name="post_code" label="Kod pocztowy" type="text" value={changeData && changeData.data.post_code ? changeData.data.post_code : "00-000"}
                                    onChange={(e) => handleChangeCustomerData(e)} />

                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={8}>

                                <TextField name="city" label="Miasto" type="text" value={changeData && changeData.data.city ? changeData.data.city : ""}
                                    onChange={(e) => handleChangeCustomerData(e)} />

                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendCustomerData("street") }} /><br />
                            </Grid>
                            <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("street")} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
                : (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={8}>
                                <p>Adres: {customer && customer.street !== ""? `ul. ${customer.street} ${customer.street_number}, ${customer.post_code} ${customer.city}` : null}</p>
                            </Grid>
                            {customer && customer.id !== 1? <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" onClick={() => handleChange("street")} />
                            </Grid>:null}
                        </Grid>
                    </React.Fragment>
                )}
            {changeData && changeData.change === "phone" ?
                (<React.Fragment>
                    <Grid container>
                        <Grid item xs={8}>

                            <TextField name="phone" label="Telefon" type="text" value={changeData && changeData.data.phone ? changeData.data.phone : "000-000-000"}
                                onChange={(e) => handleChangeCustomerData(e)} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendCustomerData("phone") }} /><br />
                        </Grid>
                        <Grid item xs={4}>
                            <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("phone")} />
                        </Grid>
                    </Grid>
                </React.Fragment>) : (<Grid container>
                    <Grid item xs={8}>
                        <p>Telefon: {customer && customer.id !== 1? customer.phone : null}</p>
                    </Grid>
                    {customer && customer.id !== 1? <Grid item xs={4}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" onClick={() => handleChange("phone", "")} />
                    </Grid>:null}
                </Grid>
                )}
            {changeData && changeData.change === "email" ?
                (<React.Fragment>
                    <Grid container>
                        <Grid item xs={8}>
                            <TextField error={errors ? true : false} helperText={errors} name="email" label="E-mail" type="email" value={changeData && changeData.data.email ? changeData.data.email : "xxx@xxx.xx"}
                                onChange={(e) => handleChangeCustomerData(e)} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendCustomerData("email") }} />
                        </Grid>
                        <Grid item xs={4}>
                            <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("email")} />
                        </Grid>
                    </Grid>
                </React.Fragment>)
                : (
                    <Grid container>
                        <Grid item xs={8}><p>E-mail: {customer ? customer.email : "nie podano"}</p></Grid>
                        {customer && customer.id !== 1? <Grid item xs={4}><FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" onClick={() => handleChange("email", "")} /></Grid>:null}
                    </Grid>
                )}
        </React.Fragment>
    )
}

export default CustomerContact