import React, { useState, useEffect } from 'react'
import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../../../theme'
import { CarouselInput } from '../../../../../../components'
import { dataRange } from '../../../../../../data/dataRange'

const rangeMinute = (start, stop) => {
    if(start.PG === stop.KG){
        if((start.PM / 10) < 5)
        return (start.PM / 10) + 1
        else
        return start.PM / 10
    } else {
        return 0
    }
}

const TimeZone = ({ timeZone, num, stamp, day, blok, handleChangeTime, daySwitch, setPublish }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", type: "", min: 0, max: 0, val: 0 })
    const [state, setState] = useState(timeZone)

    const handleClickOpen = (element, name, title, type, min, max, val) => {
        setOpen({ status: true, element: element, name: name, title: title, type: type, min: min, max: max, val: val  })
    }
    const handleClose = () => {
        // setState({...state, [open.name]: open.val})
        setOpen({ ...open, status: false })
        if(blok === 'T') {
            setState({...state, 
                'TW2': {
                    ...state.TW2,
                    [open.name.slice(-2)]: open.val
                }
            })
            setPublish({ 
                [open.name]: open.val,
                [`TW${stamp}1P_KG`]: open.name === `TW${stamp}2P_PG` ? open.val : state['TW2'].PG, 
                [`TW${stamp}3P_PG`]: open.name === `TW${stamp}2P_KG` ? open.val : state['TW2'].KG,
             })
        } else if(blok === 'C') {
            setState({...state, 
                'CW2': {
                    ...state.CW2,
                    [open.name.slice(-2)]: open.val
                }
            })
            setPublish({ 
                [open.name]: open.val,
                [`CW${stamp}1P_KG`]: open.name === `CW${stamp}2P_PG` ? open.val : state['CW2'].PG, 
                [`CW${stamp}3P_PG`]: open.name === `CW${stamp}2P_KG` ? open.val : state['CW2'].KG,
            })
        }
    }

    let min, max
    if(blok === "T"){
        min = 15
        max = 35
    } else {
        min = 35
        max = 70
    }
   
    const setNewValue = (name, val) => {
        setOpen({
            ...open,
            val: val
        })
    }

    useEffect(() => {
        let mounted = true
        setState(timeZone)
        return () => {
            mounted = false
        }
    }, [timeZone])

    return (
        <Grid container>
            <Grid item md={2} xs={2} className={"time-mode"}>
                <p className={`${classes.textRight}`}>{num}</p><br />
            </Grid>
                {`${blok}W${stamp}${num}P_PG` === `TW${stamp}2P_PG` || `${blok}W${stamp}${num}P_PG` === `CW${stamp}2P_PG` ?
                <Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen(state[`${blok}W${num}`].PG, `${blok}W${stamp}${num}P_PG`, "Ustaw godzinę rozpoczęcia", "hm", dataRange()[`${blok}W${stamp}${num}P_PG`].min, state[`${blok}W${num}`].KG - 1, state[`${blok}W${num}`].PG)}>
                        <div className="maskLikeInput">
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{state[`${blok}W${num}`].PG < 10? `0${state[`${blok}W${num}`].PG}` : state[`${blok}W${num}`].PG}</Typography>
                        </div>
                    </Grid>
                    : <Grid item md={2} xs={3} className="np">
                        <div className="maskLikeInput">
                            {`${blok}W${stamp}${num}P_PG` === `CW${stamp}${num}P_PG` ?
                                <Typography variant="body2" className={"carousel-input-click2"} style={{ color: 'grey', borderColor: 'grey', margin: '0px auto', boxSizing: 'content-box' }}>{`${blok}W${stamp}${num}P_PG` === `CW${stamp}1P_PG` ? "00" : `${blok}W${stamp}${num}P_PG` === `CW${stamp}3P_PG` ? state[`${blok}W${num - 1}`].KG < 10 ? `0${state[`${blok}W${num - 1}`].KG}` : state[`${blok}W${num - 1}`].KG : state[`${blok}W${num + 1}`].PG}</Typography>    
                                : <Typography variant="body2" className={"carousel-input-click2"} style={{ color: 'grey', borderColor: 'grey', margin: '0px auto', boxSizing: 'content-box' }}>{`${blok}W${stamp}${num}P_PG` === `TW${stamp}1P_PG` ? "00" : `${blok}W${stamp}${num}P_PG` === `TW${stamp}3P_PG` ? state[`${blok}W${num - 1}`].KG < 10 ? `0${state[`${blok}W${num - 1}`].KG}` : state[`${blok}W${num - 1}`].KG : state[`${blok}W${num + 1}`].PG}</Typography> 
                            }                 
                        </div>
                    </Grid>
                }
            {/*<Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen({hour: timeZone[`${blok}W${num}`].PG, minute: timeZone[`${blok}W${num}`].PM}, {hour: `${blok}W${stamp}${num}P_PG`, minute: `${blok}W${stamp}${num}P_PM`}, "Ustaw godzinę rozpoczęcia", "hm", {hour: 0, minute: 0}, {hour: 23, minute: 6})}>
                <div className="maskLikeInput">
                    <Typography variant="body2" className={"carousel-input-click2"}>{timeZone[`${blok}W${num}`].PG < 10? `0${timeZone[`${blok}W${num}`].PG}`:timeZone[`${blok}W${num}`].PG} : {timeZone[`${blok}W${num}`].PM < 10? `0${timeZone[`${blok}W${num}`].PM}`:timeZone[`${blok}W${num}`].PM}</Typography>
                </div>
            </Grid>*/}
            {`${blok}W${stamp}${num}P_KG` === `TW${stamp}2P_KG` || `${blok}W${stamp}${num}P_KG` === `CW${stamp}2P_KG` ?
                <Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen(state[`${blok}W${num}`].KG, `${blok}W${stamp}${num}P_KG`, "Ustaw godzinę zakończenia", "hm", state[`${blok}W${num}`].PG + 1, dataRange()[`${blok}W${stamp}${num}P_KG`].max, state[`${blok}W${num}`].KG)}>
                    <div className="maskLikeInput">
                        <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{state[`${blok}W${num}`].KG < 10? `0${state[`${blok}W${num}`].KG}` : state[`${blok}W${num}`].KG}</Typography>
                    </div>
                </Grid>
                : <Grid item md={2} xs={3} className="np">
                    <div className="maskLikeInput">
                        {`${blok}W${stamp}${num}P_KG` === `CW${stamp}${num}P_KG` ?
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ color: 'grey', borderColor: 'grey', margin: '0px auto', boxSizing: 'content-box' }}>{`${blok}W${stamp}${num}P_KG` === `CW${stamp}1P_KG` ? state[`${blok}W${num + 1}`].PG < 10 ? `0${state[`${blok}W${num + 1}`].PG}` : state[`${blok}W${num + 1}`].PG : `${blok}W${stamp}${num}P_KG` === `CW${stamp}3P_KG` ? "24" : state[`${blok}W${num}`].PG < 10 ? `0${state[`${blok}W${num}`].KG}` : state[`${blok}W${num}`].KG}</Typography>   
                            : <Typography variant="body2" className={"carousel-input-click2"} style={{ color: 'grey', borderColor: 'grey', margin: '0px auto', boxSizing: 'content-box' }}>{`${blok}W${stamp}${num}P_KG` === `TW${stamp}1P_KG` ? state[`${blok}W${num + 1}`].PG < 10 ? `0${state[`${blok}W${num + 1}`].PG}` : state[`${blok}W${num + 1}`].PG : `${blok}W${stamp}${num}P_KG` === `TW${stamp}3P_KG` ? "24" : state[`${blok}W${num}`].PG < 10 ? `0${state[`${blok}W${num}`].KG}` : state[`${blok}W${num}`].KG}</Typography>   
                        }                 
                    </div>
                </Grid>
            }
            {/*<Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen({hour: timeZone[`${blok}W${num}`].KG, minute: timeZone[`${blok}W${num}`].KM}, {hour: `${blok}W${stamp}${num}P_KG`, minute: `${blok}W${stamp}${num}P_KM`}, "Ustaw godzinę zakończenia", "hm", {hour: 0, minute: 0}, {hour: 23, minute: 6})}>
                <div className="maskLikeInput">
                    <Typography variant="body2" className={"carousel-input-click2"}>{timeZone[`${blok}W${num}`].KG < 10? `0${timeZone[`${blok}W${num}`].KG}` : timeZone[`${blok}W${num}`].KG} : {timeZone[`${blok}W${num}`].KM < 10? `0${timeZone[`${blok}W${num}`].KM}`:timeZone[`${blok}W${num}`].KM}</Typography> 
                </div>
            </Grid>*/}
            <Grid item md={2} xs={3} className="np" onClick={() => handleClickOpen(timeZone[`${blok}W${num}`].Temp, `${blok}W${stamp}${num}T`, "Ustaw temperaturę", "", dataRange()[`${blok}W${stamp}${num}T`].min, dataRange()[`${blok}W${stamp}${num}T`].max, timeZone[`${blok}W${num}`].Temp)}> 
                <div className="maskLikeInput">
                    
                    <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{timeZone[`${blok}W${num}`].Temp}{dataRange()[`${blok}W${stamp}${num}T`].symbol}</Typography>
                </div>
            </Grid>
            
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <CarouselInput style={{ float: "left" }} init={open.element} name={open.name} min={open.min} max={open.max} title={open.title} setPublish={setPublish} type={open.type} setVal={setNewValue}/>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default TimeZone