import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField, Select, Checkbox } from 'final-form-material-ui'
import { Button, Grid, Typography, FormControl, MenuItem, FormControlLabel } from '@material-ui/core'
import formatString from 'format-string-by-pattern'

import { errorMessage } from '../../validation'
import { fetchAddUser } from '../../../../data'
import { default as userDicts } from '../../../../dictionary/userPanelDictionary'

const required = value => (value ? undefined : "Pole obowiązkowe")

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const passConfirm = pass => value => (value && value === pass ? undefined : "Hasła nie są jednakowe")
const minLengthPass = min => value => value === undefined || value.length < min ? `Hasło powinno się składać z minimum ${min} znaków` : null

const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")

const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const NewUser = ({ handleClose, roles, userStatus, userType, users, setUsers, partners }) => {
    const [error, setError] = useState({ status: false, message: "" })
    const [sendStatus, setSendStatus] = useState(null)
    const [pass, setPass] = useState(null)
    const [editValues, setEditValues] = useState({ first_name: "", last_name: "", email: "", phone: "", admin: false, role_id: 0,
        status: 0, user_type: 0, partners: [] })

    const handlePass = e => {
        setPass(e.target.value)
    }

    const onSubmit = async values => {
        
        let newUser = { email: values.email, password: values.password, first_name: values.first_name,
                last_name: values.last_name, admin: values.admin, role_id: values.role_id, user_type: values.user_type,
                partners: values.partners }
        newUser.fullName = values.first_name + ' ' + values.last_name
        newUser.status = values.status
        // newUser.status = (values.status in userDicts.userStatus ? userDicts.userStatus[values.status] : values.status)
        newUser.type = newUser.user_type in userDicts.userType ? userDicts.userType[newUser.user_type] : newUser.user_type
        newUser.role = roles.map((role) => {
            if(role.id === newUser.role_id) {
                return role.role
            }
        }).join('')
        
        fetchAddUser({ email: values.email,
            password: values.password,
            phone: values.phone,
            first_name: values.first_name,
            last_name: values.last_name,
            admin: values.admin,
            role_id: values.role_id,
            status: values.status,
            user_type: values.user_type,
            partners: values.partners })
        .then(res => {
            console.log(res.status)
            if (res.status === 200 || res.status === "ok") {
                return res.json()
            } else {
                setSendStatus("")
                setError({ status: true, message: errorMessage(res.status, "user") })
                return "error"
            }
           
        })
        .then(response => {
            if (response.status === 200 || response.status === "ok") {
                newUser.id = response.data.id
                newUser.phone = response.data.phone
                newUser.status_org = response.data.status
                newUser.partners_org = response.data.partners
                let newUsers = [...users, newUser]
                setUsers({ payload: newUsers, roles: roles, partners: partners })
                setSendStatus("Pomyślnie dodano użytkownika.")
                setError({ status: false, message:"" })
                return response
            } else {
                return "error"
            }
        })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Form
                        initialValues={editValues}
                        onSubmit={onSubmit}
                    >
                        {({ handleSubmit, form, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h2">Dodawanie nowego użytkownika</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="first_name"
                                            component={TextField}
                                            type="first_name"
                                            label="Imię"
                                            fullWidth
                                            validate={composeValidators(required, textOnly)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="last_name"
                                            component={TextField}
                                            type="last_name"
                                            label="Nazwisko"
                                            fullWidth
                                            validate={composeValidators(required, textOnly)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="email"
                                            component={TextField}
                                            type="email"
                                            label="Adres e-mail"
                                            fullWidth
                                            validate={composeValidators(required, formatEmail)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="phone"
                                            component={TextField}
                                            type="phone"
                                            label="Numer telefonu"
                                            fullWidth
                                            validate={composeValidators(required, minLength(11))}
                                            parse={e => formatOnlyNumbersTel(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {/* <FormControl>
                                            <Field
                                                name="admin"
                                                labelId="admin"
                                                label="Admin"
                                                id="admin"
                                                component={Select}
                                                validate={composeValidators(required)}
                                            >
                                                <MenuItem key="false" value="false">Nie</MenuItem>
                                                <MenuItem key="true" value="true">Tak</MenuItem>
                                            </Field>
                                        </FormControl> */}
                                        <FormControlLabel
                                            label="Admin"
                                            control={
                                                <Field
                                                    name="admin"
                                                    component={Checkbox}
                                                    type="checkbox"
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl>
                                            <Field
                                                name="role_id"
                                                labelId="role_id"
                                                label="Rola"
                                                id="role_id"
                                                component={Select}
                                                validate={composeValidators(required)}
                                            >
                                                {roles.map(role => <MenuItem key={role.id} value={role.id}>{role.role}</MenuItem>)}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl>
                                            <Field
                                                name="status"
                                                labelId="status"
                                                label="Status"
                                                id="status"
                                                component={Select}
                                                validate={composeValidators(required)}
                                            >
                                                {Object.keys(userStatus).map(status => <MenuItem key={status} value={status}>{userStatus[status]}</MenuItem>)}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl>
                                            <Field
                                                name="user_type"
                                                labelId="user_type"
                                                label="Typ"
                                                id="user_type"
                                                component={Select}
                                                validate={composeValidators(required)}
                                            >
                                                {Object.keys(userType).map(type => <MenuItem key={type} value={type}>{userType[type]}</MenuItem>)}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl>
                                            <Field
                                                name="partners"
                                                labelId="partners"
                                                label="Partnerzy"
                                                id="partners"
                                                component={Select}
                                                validate={composeValidators(required)}
                                                multiple
                                                value={[]}
                                                format={value => value || []}
                                            >
                                                {partners.map(partner => <MenuItem key={partner.id} value={partner.id}>{partner.name}</MenuItem>)}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="password"
                                            component={TextField}
                                            type="password"
                                            label="Wpisz hasło"
                                            fullWidth
                                            validate={composeValidators(minLengthPass(5), required)}
                                            onKeyUp={e => handlePass(e)}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="passwordConfirm"
                                            component={TextField}
                                            type="password"
                                            label="Powtórz hasło"
                                            fullWidth
                                            validate={composeValidators(passConfirm(pass), required)}
                                        />
                                    </Grid>
                                </Grid>

                                {error.status ? <Grid container direction="row"><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}
                                <Grid container direction="row" >
                                    <Grid item xs={12} md={6}><br />
                                        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                                            Dodaj
                                        </Button>
                                    </Grid>
                                    {sendStatus && sendStatus !== "ok" && sendStatus !== "new" && sendStatus !== "edit" ?
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ color: "grey", marginTop: "20px" }}>{sendStatus}</Typography>
                                        </Grid> : null}
                                </Grid>
                            </form>
                        )}
                    </Form>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NewUser