import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { CarouselInputMulti } from '../../../../components'

const DataTime = ({ settings, setNewValue2, element }) => {
    
    // const setNewValue = (num, val) => {
    //     if (num === `ST_MIESIAC`) {
    //         setPublish({ [num]: val })
    //     } else {
    //         setPublish({ [num]: val })
    //     }
    // }

    return (
        <React.Fragment>
            {element === "date" ? <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">Ustaw datę (dd-mm-rrrr): </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={settings.DZIEN} name={`ST_DZIEN`} min={1} max={31} setVal={setNewValue2} num={`ST_DZIEN`} />
                        </Grid>
                        <Grid item xs={1}><span style={{ fontSize: "86px", margin: "89px auto 0", display: "block", textAlign: "center" }}>.</span></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={settings.MIESIAC} name={`ST_MIESIAC`} min={1} max={12} setVal={setNewValue2} num={`ST_MIESIAC`} />
                        </Grid>
                        <Grid item xs={1}><span style={{ fontSize: "86px", margin: "89px auto 0", display: "block", textAlign: "center" }}>.</span></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={settings.ROK} name={`ST_ROK`} min={2020} max={2099} setVal={setNewValue2} num={`ST_ROK`} />
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Grid>
            </Grid> : null}
            {element === "dayweek" ? <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">Ustaw dzień tygodnia: </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                    <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                            <CarouselInputMulti init={settings.DZIEN_TYG} name={`ST_DZEN_TYG`} min={0} max={6} setVal={setNewValue2} num={`ST_DZEN_TYG`} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> : null}
            {element === "time" ? <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">Ustaw czas (hh:mm:ss): </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={settings.GODZINY} name={`ST_GODZINY`} min={0} max={23} setVal={setNewValue2} num={`ST_GODZINY`} />
                        </Grid>
                        <Grid item xs={1}><span style={{ fontSize: "86px", margin: "65px auto 0", display: "block", textAlign: "center" }}>:</span></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={settings.MINUTY} name={`ST_MINUTY`} min={0} max={59} setVal={setNewValue2} num={`ST_MINUTY`} />
                        </Grid>
                        <Grid item xs={1}><span style={{ fontSize: "86px", margin: "65px auto 0", display: "block", textAlign: "center" }}>:</span></Grid>
                        <Grid item xs={2}>
                            <CarouselInputMulti init={settings.SEKUNDY} name={`ST_SEKUNDY`} min={0} max={59} setVal={setNewValue2} num={`ST_SEKUNDY`} />
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Grid>
            </Grid> : null}
        </React.Fragment>
    )
}

export default DataTime