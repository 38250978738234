import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../theme'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const Navigation = ({ setBaseLogin, perm, accessScada, role }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = mainStyleClasses()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    
    const handleLogout = () => {
        setBaseLogin({ type: "logout" })
        sessionStorage.removeItem("APIToken")
        sessionStorage.removeItem("APIToken-refresh")
        sessionStorage.removeItem("Expiration")
        sessionStorage.removeItem("ScadaAccess")
    }

    return (
        <React.Fragment>
            {perm && perm !== null? <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Menu
            </Button>: null}
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} to="/urzadzenia">Lista urządzeń</MenuItem>
                {accessScada && (role === "partner" && accessScada.partner_id !== 1)? null : <React.Fragment><MenuItem onClick={handleClose} component={Link} to="/serwis">Panel serwisowy</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/dodaj" disabled={perm && (!perm.includes("device_c") && !perm.includes("admin"))? true: false}>Dodaj urządzenie</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/partnerzy" disabled={perm && (!perm.includes("device_c") && !perm.includes("admin"))? true: false}>Partnerzy</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/instalatorzy" disabled={perm && (!perm.includes("device_c") && !perm.includes("admin"))? true: false}>Instalatorzy</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/uzytkownicy" disabled={perm && (!perm.includes("admin"))? true: false}>Użytkownicy</MenuItem></React.Fragment>}
                <MenuItem onClick={() => {handleLogout(); handleClose()}} component={Link} to="/">Wyloguj</MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default Navigation