import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../../../theme'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const Navigation = ({ url, locState, extenders }) => {
    const isExt = parseFloat(sessionStorage.getItem('isExt')) 

    const [anchorEl, setAnchorEl] = useState(null)
    const classes = mainStyleClasses()
    const deviceName = sessionStorage.getItem('deviceName')
    const extDeviceName1 = sessionStorage.getItem('extDeviceName1') 

    const hostOffline = window.location.hostname

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleRefresh = () => {
        setTimeout(() => {
            window.location.reload()
        })
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    
    return (
        <React.Fragment>
            <Button style={{ border: '2px solid #fff', borderRadius: '10px', margin: '0 0 11% 0', padding: '2px', width: '100%' }} variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Menu
            </Button>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/`}>Home</MenuItem>
                {/*<MenuItem onClick={handleClose} component={Link} to={`${url}/wizzard`}>Kreator instalacji</MenuItem>*/}
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/errors`}>Błędy</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/emergencystop`}>Wyłączenia Awaryjne</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/statistics`}>Statystyki</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/antylegionella`}>Antylegionella</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/datetime`}>Data i czas</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/parameters`}>Parametry</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/modes`}>Tryby</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/blockade`}>Blokada</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/analysis`}>Analiza</MenuItem>
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/periphery`}>Peryferia</MenuItem>
                {deviceName === 'GEO_1' ? null 
                    : <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/outdoor`}>Jednostka zewnętrzna</MenuItem>
                }
                <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/identyfication`}>Identyfikacja</MenuItem>
                {!Object.keys(extenders)[0] || isExt === 0 ? 
                    null 
                    : <MenuItem style={{fontSize: '19px'}} onClick={handleClose} component={Link} to={`${url}/extenders`}>Extenders</MenuItem> 
                     
                }  
                {/*
                    <MenuItem onClick={handleClose} component={Link} to={`${url}/montage`}>Montaż</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to={`${url}/test`}>Test</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={`${url}/information`}>Informacje</MenuItem>*/}
                <MenuItem style={{fontSize: '19px'}} onClick={handleRefresh} component={Link}
                    to={{ pathname: `${url.replace("monitor", "box")}`,
                        state: { device_sn: locState.device_sn, installation_id: locState.installation_id, last_telemetry_update: locState.last_telemetry_update } }}>
                    Euros Box
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default Navigation