import React, { useReducer } from 'react'
import { descriptionDictionary } from '../../dataFilter/descriptionDictionary'
import { dataRangeDictionary } from '../../dataFilter/dataRangeDictionary'
import { TitleRow } from '../../components'

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Crot = ({ crot, setPublish }) => {

    const switchOption = (state, action) => {
        switch (action.type) {
            case 'TOUT_NUM':
                return { ...state, TOUT_NUM: action.payload };
                
            case "UPDATE_FROM_BROKER":
                return {TOUT_NUM: action.payload};
            default:
                return { state };
        }
    }

    const initState = { TOUT_NUM: parseInt(crot.TOUT_NUM) };

    const [crotValue, setCrot] = useReducer(switchOption, initState);
    
    const handleSendCrot = (e) => {
        const sendKey = e.target.name;
        const sendData = e.target.value;

        setPublish({ [sendKey]: sendData })
       
    }

    return (
        <>
            <Container>
                <TitleRow title="CROT" />
                <Row>
                    <Col className="text-left" xs={10} md={10}>
                        <p>{descriptionDictionary.crot.TOUT_KOR}</p>
                    </Col>
                    <Col className="text-right" xs={2} md={2}>
                        {crot.TOUT_KOR}
                    </Col>
                </Row>


                <Form>
                    <Form.Group as={Row} controlId="formBasicRangeCustom">
                        <Col className="text-left" xs={12} md={5}>
                            <Form.Label>{descriptionDictionary.crot.TOUT_NUM}</Form.Label>
                        </Col>
                        <Col className="text-right" xs={10} md={6}>
                            <Form.Control type="range" custom name="TOUT_NUM" value={crotValue.TOUT_NUM}
                                min={dataRangeDictionary.crot.TOUT_NUM.min} max={dataRangeDictionary.crot.TOUT_NUM.max} step={1}
                                onChange={(e) => setCrot({ type: "TOUT_NUM", payload: e.target.value })} onMouseUp={handleSendCrot} onTouchEnd={handleSendCrot} />
                        </Col>
                        <Col xs={2} md={1} className="text-right">{crotValue.TOUT_NUM}</Col>
                    </Form.Group>
                </Form>
            </Container>
        </>
    );
}

export default Crot;