import React from 'react'

const ScadaCircuit2 = ({ scada, circArr }) => {
    return (
        <React.Fragment>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 765 555" className='scadaCircuit2'>
                <defs>
                    <linearGradient id="podłogówka" gradientUnits="userSpaceOnUse" x1="492.2747" y1="323.2142" x2="665.2595" y2="323.2142">
                        <stop  offset="0" style={{ stopColor: '#D62027' }} />
                        <stop  offset="9.620906e-02" style={{ stopColor: '#CD242E' }} />
                        <stop  offset="0.2563" style={{ stopColor: '#B32D3F' }} />
                        <stop  offset="0.4605" style={{ stopColor: '#8A3D5C' }} />
                        <stop  offset="0.7009" style={{ stopColor: '#505384' }} />
                        <stop  offset="0.969" style={{ stopColor: '#076EB7' }} />
                        <stop  offset="0.9944" style={{ stopColor: '#0071BC' }} />
                    </linearGradient>  
                </defs>
                <g id="black">
                    <rect id="czarne_tlo" y="0" width="765" height="555"/>
                </g>
                <g id='belka'>
                    <g id="temperatura_zewnetrzna" data-name="temperatura zewnetrzna">
                        <g id="symbol_pogody" data-name="symbol pogody">
                            <path className="cls-3" d="m5.28,14.77c-2.82,0-4.28,2.16-4.28,4.3,0,2.28,1.85,4.13,4.13,4.13h16.15c1.79,0,3.24-1.46,3.24-3.24s-1.46-3.25-3.24-3.25h-.52l.02-.52s.04-1.44-.87-2.38c-.56-.58-1.37-.87-2.42-.87-.98,0-1.73.37-2.36,1.17l-.21.27-.88-.27v-.37c0-2.28-1.81-4.06-4.12-4.06s-4.13,2.02-4.13,4.59v.5h-.5Z"/>
                            <g>
                                <g>
                                    <path className="cls-2" d="m24.64,12.12h-2.16c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.16c.28,0,.5.22.5.5s-.22.5-.5.5Z"/>
                                    <path className="cls-2" d="m10.77,7.31c-.13,0-.26-.05-.35-.15l-1.41-1.41c-.19-.2-.19-.51,0-.71.2-.19.51-.2.71,0l1.41,1.41c.19.2.19.51,0,.71-.1.1-.23.15-.35.15Z"/>
                                    <path className="cls-2" d="m15.58,5.32c-.28,0-.5-.22-.5-.5v-2.09c0-.28.22-.5.5-.5s.5.22.5.5v2.09c0,.28-.22.5-.5.5Z"/>
                                    <path className="cls-2" d="m20.39,7.31c-.13,0-.26-.05-.35-.15-.2-.2-.19-.51,0-.71l1.42-1.41c.2-.2.51-.2.71,0,.2.2.19.51,0,.71l-1.42,1.41c-.1.1-.23.15-.35.15Z"/>
                                </g>
                                <path className="cls-1" d="m19.59,13.42c-.05,0-.11,0-.16-.03-.26-.09-.4-.38-.31-.64.13-.38.2-.78.2-1.19,0-2.03-1.65-3.68-3.68-3.68-1.37,0-2.61.75-3.25,1.96-.13.24-.43.34-.68.21-.24-.13-.34-.43-.21-.68.81-1.54,2.4-2.5,4.14-2.5,2.58,0,4.68,2.1,4.68,4.68,0,.52-.09,1.03-.25,1.52-.07.21-.27.34-.47.34Z"/>
                            </g>
                        </g>
                    </g>
                    <g id="obieg_1" data-name="obieg 1">
                        <path className="cls-1" d="m187.22,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                        {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 ?
                            <path className="cls-5" d="m178.33,10.16c.19-.03.42,0,.42.42v7.87h1.79c.31,0,.45.1.45.42v1.17c0,.31-.13.44-.45.44h-6.12c-.31,0-.45-.12-.45-.44v-1.17c0-.31.13-.42.45-.42h1.86v-5.92l-1.86.27c-.28.04-.45-.06-.45-.38v-1.17c0-.31.17-.44.45-.48l3.92-.61Z"/>
                            : null
                        }
                    </g>
                    {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 ?
                        <g id="obieg_2" data-name="obieg 2">
                            <path className="cls-1" d="m351.8,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.25-.54-.36-.92-.36Z"/>
                            <text className="cls-6" x="338" y="20">{`${parseInt(circArr[0]) + 1}`}</text>
                        </g>
                        : null
                    }
                    {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 ?
                        <g id="obieg_3" data-name="obieg 3">
                            <path className="cls-1" d="m516.38,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                            <text className="cls-6" x="502.5" y="20">{`${parseInt(circArr[1]) + 1}`}</text>
                        </g>
                        : null
                    }
                    {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                        <g id="obieg_4" data-name="obieg 4">
                            <path className="cls-1" d="m680.96,24.21h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64.29-1.24.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72.67,0,1.27.24,1.63.66l9.68,8.53c.49.43.77,1.05.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66.18-.88.43l-9.73,8.5c-.18.15-.47.46-.47.92v11.15c0,.67.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                            <text className="cls-6" x="667" y="20">{`${parseInt(circArr[2]) + 1}`}</text>
                        </g>
                        : null
                    }
                </g>
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                    <g id="obieg_2">
                        <g id="rurociag_obiegu_2">
                            <line className="st0" x1="97.3" y1="148.5" x2="160.8" y2="148.5"/>
                            <line className="st1" x1="97.3" y1="223.4" x2="651.7" y2="223.4"/>
                            <line className="st0" x1="492.3" y1="148.5" x2="226.1" y2="148.5"/>
                            <polygon className="st2" points="92.7,148.5 92.7,141.3 99,144.9 105.2,148.5 99,152.1 92.7,155.7 		"/>
                            <polygon className="st3" points="105.2,223.4 105.2,230.6 99,227 92.7,223.4 99,219.8 105.2,216.2 		"/>

                            <path style={{ fill: 'none', stroke: 'url(#podłogówka)', strokeWidth: 4.922, strokeMiterlimit: '10' }} d="M651.1,223.4h1.4c5.7,0,10.3-4.6,10.3-10.3v-77.7c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62
                                c0,5.6-4.5,10.1-10.1,10.1l0,0c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6H612c-5.3,0-9.6,4.3-9.6,9.6v62
                                c0,5.6-4.5,10.1-10.1,10.1l0,0c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62
                                c0,5.6-4.5,10.1-10.1,10.1l0,0c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62
                                c0,5.6-4.5,10.1-10.1,10.1h0c-5.6,0-10.1-4.5-10.1-10.1v-39.3c0-5.3-4.3-9.6-9.6-9.6l0,0"/>
                        </g>
                        <g id="pompa_obiegu_2">
                            <circle className="st2" cx="299.6" cy="148.3" r="21.5"/>
                            {scada[`OB${circArr[0]}_PCO`] === 100 ? 
                                <g>
                                    <path className="st5" d="M304.3,147.9c7.1-2.1,8.7-8,5.4-12.4c-1.6-2-5.7-2.5-7.4,0.4c-1.6,2.8,0.3,5.2-1.9,7.8
                                        C301.9,143.9,304,144.7,304.3,147.9z"/>
                                    <path className="st5" d="M296.9,144.4c-5.4-5-11.3-3.6-13.4,1.5c-1,2.4,0.7,6.2,4.1,6.2c3.2,0,4.4-2.9,7.7-2.2
                                        C294.6,148.6,294.2,146.4,296.9,144.4z"/>
                                
                                    <path className="st5" d="M297.6,152.6c-1.7,7.2,2.6,11.6,8,10.9c2.6-0.3,5-3.7,3.3-6.7c-1.6-2.8-4.7-2.4-5.8-5.5
                                        C302.3,152.5,300.6,154,297.6,152.6z"/>
                                    <circle className="st5" cx="299.6" cy="148.3" r="2.8"/>
                                </g>
                                : null
                            } 
                        </g>
                        {scada[`OB${circArr[0]}_TCO`] > 0 ? 
                            <g id="zawor_obiegu_2">
                                <g id="zawor_obiegu_2">
                                    <line id="z_zaworem_2" className="st1" x1="193.4" y1="180.9" x2="193.6" y2="223.4"/>
                                    <g>
                                        <polygon className="st5" points="193.4,182.6 178.9,182.6 186.1,165.6 193.4,148.7 200.6,165.6 207.8,182.6"/>
                                        <polygon className="st5" points="159.5,148.7 159.5,134.2 176.4,141.5 193.4,148.7 176.4,155.9 159.5,163.1"/>
                                        <polygon className="st5" points="227.3,148.7 227.3,163.1 210.3,155.9 193.4,148.7 210.3,141.5 227.3,134.2"/>
                                        <path className="st5" d="M193.4,102.7c-9.2,0-16.6,7.4-16.6,16.6c0,8.8,6.8,16,15.5,16.6v8.8c0,0.6,0.5,1.1,1.1,1.1
                                            c0.6,0,1.1-0.5,1.1-1.1v-8.8c8.6-0.6,15.5-7.8,15.5-16.6C209.9,110.1,202.5,102.7,193.4,102.7z M193.4,133.6
                                            c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C207.7,127.2,201.3,133.6,193.4,133.6z"/>
                                        <path className="st5" d="M198.4,120c-0.1-2-0.2-4.3-0.2-6.1h-0.1c-0.5,1.7-1.1,3.4-1.8,5.4l-2.5,6.8h-1.4l-2.3-6.7
                                            c-0.7-2-1.2-3.8-1.6-5.5h0c0,1.8-0.1,4.1-0.3,6.2l-0.4,6h-1.7l1-14.1h2.3l2.4,6.8c0.6,1.7,1.1,3.3,1.4,4.7h0.1
                                            c0.4-1.4,0.9-3,1.5-4.7l2.5-6.8h2.3l0.9,14.1h-1.8L198.4,120z"/>
                                    </g>
                                </g>
                                <g id="ikonki">
                                    <path id="temperatura_obiegu_2" className="st5" d="M370.2,134.8c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6c0-1.5,1-2.9,2.4-3.4v-8.9c0-0.7,0.5-1.3,1.2-1.3s1.2,0.6,1.2,1.3v8.9C369.2,131.9,370.2,133.2,370.2,134.8z"/>
                                    <polygon id="setpoint_obiegu_2" className="st5" points="360.4,167.4 360.4,158.7 368,163 375.6,167.4 368,171.8 360.4,176.2 	"/>
                                </g>
                            </g>
                            : <line id="jesli_bez_zaworu_2" className="st0" x1="160.5" y1="148.5" x2="226.2" y2="148.5"/>  
                        }
                        <text id="napis_OBIEG_4_00000106124418591328257180000018074782570344405690_" transform="matrix(1 0 0 1 539.5405 108.5614)" className="st9 st10 st11">{`OBIEG ${parseInt(circArr[0]) + 1}`}</text>
                    </g>   
                    : null
                }
                <g id='obieg_2-3'>
                    <g id="rurociag_obiegu">
                        <line className="st0" x1="97.3" y1="297.1" x2="160.8" y2="297.1"/>
                        <line className="st1" x1="97.3" y1="372" x2="651.7" y2="372"/>
                        <line className="st0" x1="492.3" y1="297.1" x2="226.1" y2="297.1"/>
                        <polygon className="st2" points="92.7,297.1 92.7,289.9 99,293.5 105.2,297.1 99,300.7 92.7,304.3"/>
                        <polygon className="st3" points="105.2,372 105.2,379.2 99,375.6 92.7,372 99,368.4 105.2,364.8"/>
               
                        <path style={{ fill: 'none', stroke: 'url(#podłogówka)', strokeWidth: 4.922, strokeMiterlimit: '10' }} d="
                        M651.1,372h1.4c5.7,0,10.3-4.6,10.3-10.3V284c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1l0,0
                        c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6H612c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1l0,0
                        c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1l0,0
                        c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1h0
                        c-5.6,0-10.1-4.5-10.1-10.1v-39.3c0-5.3-4.3-9.6-9.6-9.6l0,0"/>
                    </g>
                    <g id="pompa_obiegu_2-3">
                        <circle className="st2" cx="299.6" cy="297" r="21.5"/>
                        {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                            scada[`OB${circArr[1]}_PCO`] === 100 ? 
                                <g>
                                    <path className="st5" d="M304.3,296.6c7.1-2.1,8.7-8,5.4-12.4c-1.6-2-5.7-2.5-7.4,0.4c-1.6,2.8,0.3,5.2-1.9,7.8
                                        C301.9,292.5,304,293.3,304.3,296.6z"/>
                                    <path className="st5" d="M296.9,293c-5.4-5-11.3-3.6-13.4,1.5c-1,2.4,0.7,6.2,4.1,6.2c3.2,0,4.4-2.9,7.7-2.2
                                            C294.6,297.2,294.2,295,296.9,293z"/>
                                    <path className="st5" d="M297.6,301.3c-1.7,7.2,2.6,11.6,8,10.9c2.6-0.3,5-3.7,3.3-6.7c-1.6-2.8-4.7-2.4-5.8-5.5
                                            C302.3,301.2,300.6,302.6,297.6,301.3z"/>
                                    <circle className="st5" cx="299.6" cy="297" r="2.8"/>
                                </g>
                                : null
                            : scada[`OB${circArr[0]}_PCO`] === 100 ? 
                                <g>
                                    <path className="st5" d="M304.3,296.6c7.1-2.1,8.7-8,5.4-12.4c-1.6-2-5.7-2.5-7.4,0.4c-1.6,2.8,0.3,5.2-1.9,7.8
                                        C301.9,292.5,304,293.3,304.3,296.6z"/>
                                    <path className="st5" d="M296.9,293c-5.4-5-11.3-3.6-13.4,1.5c-1,2.4,0.7,6.2,4.1,6.2c3.2,0,4.4-2.9,7.7-2.2
                                            C294.6,297.2,294.2,295,296.9,293z"/>
                                    <path className="st5" d="M297.6,301.3c-1.7,7.2,2.6,11.6,8,10.9c2.6-0.3,5-3.7,3.3-6.7c-1.6-2.8-4.7-2.4-5.8-5.5
                                            C302.3,301.2,300.6,302.6,297.6,301.3z"/>
                                    <circle className="st5" cx="299.6" cy="297" r="2.8"/>
                                </g>
                                : null
                        } 
                    </g>
                    {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                        scada[`OB${circArr[1]}_TCO`] > 0 ? 
                            <g id="zawor_obiegu_2-3">
                                <line id="z_zaworem_-_linia" className="st1" x1="193.4" y1="329.6" x2="193.6" y2="372"/>
                                <g>
                                    <polygon className="st5" points="193.4,331.2 178.9,331.2 186.1,314.3 193.4,297.3 200.6,314.3 207.8,331.2"/>
                                    <polygon className="st5" points="159.5,297.3 159.5,282.9 176.4,290.1 193.4,297.3 176.4,304.5 159.5,311.8"/>
                                    <polygon className="st5" points="227.3,297.3 227.3,311.8 210.3,304.5 193.4,297.3 210.3,290.1 227.3,282.9"/>
                                    <path className="st5" d="M193.4,251.3c-9.2,0-16.6,7.4-16.6,16.6c0,8.8,6.8,16,15.5,16.6v8.8c0,0.6,0.5,1.1,1.1,1.1
                                        c0.6,0,1.1-0.5,1.1-1.1v-8.8c8.6-0.6,15.5-7.8,15.5-16.6C209.9,258.8,202.5,251.3,193.4,251.3z M193.4,282.2
                                        c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C207.7,275.8,201.3,282.2,193.4,282.2z"/>
                                    <path className="st5" d="M198.4,268.7c-0.1-2-0.2-4.3-0.2-6.1h-0.1c-0.5,1.7-1.1,3.4-1.8,5.4l-2.5,6.8h-1.4l-2.3-6.7
                                        c-0.7-2-1.2-3.8-1.6-5.5h0c0,1.8-0.1,4.1-0.3,6.2l-0.4,6h-1.7l1-14.1h2.3l2.4,6.8c0.6,1.7,1.1,3.3,1.4,4.7h0.1
                                        c0.4-1.4,0.9-3,1.5-4.7l2.5-6.8h2.3l0.9,14.1h-1.8L198.4,268.7z"/>
                                </g>
                                <g id="ikonki">
                                    <path id="temperatura_obiegu_2-3" className="st5" d="M370.2,283.4c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6c0-1.5,1-2.9,2.4-3.4v-8.9c0-0.7,0.5-1.3,1.2-1.3s1.2,0.6,1.2,1.3v8.9C369.2,280.5,370.2,281.8,370.2,283.4z"/>
                                    <polygon id="setpoint_obiegu_2-3" className="st5" points="360.4,316.1 360.4,307.3 368,311.7 375.6,316.1 368,320.5 360.4,324.8"/>
                                </g>
                            </g>
                            : <line id="jesli_bez_zaworu_-_linia" className="st0" x1="160.5" y1="297.1" x2="226.2" y2="297.1"/>
                        : scada[`OB${circArr[0]}_TCO`] > 0 ? 
                            <g id="zawor_obiegu_2-3">
                                    <line id="z_zaworem_-_linia" className="st1" x1="193.4" y1="329.6" x2="193.6" y2="372"/>
                                <g>
                                    <polygon className="st5" points="193.4,331.2 178.9,331.2 186.1,314.3 193.4,297.3 200.6,314.3 207.8,331.2"/>
                                    <polygon className="st5" points="159.5,297.3 159.5,282.9 176.4,290.1 193.4,297.3 176.4,304.5 159.5,311.8"/>
                                    <polygon className="st5" points="227.3,297.3 227.3,311.8 210.3,304.5 193.4,297.3 210.3,290.1 227.3,282.9"/>
                                    <path className="st5" d="M193.4,251.3c-9.2,0-16.6,7.4-16.6,16.6c0,8.8,6.8,16,15.5,16.6v8.8c0,0.6,0.5,1.1,1.1,1.1
                                        c0.6,0,1.1-0.5,1.1-1.1v-8.8c8.6-0.6,15.5-7.8,15.5-16.6C209.9,258.8,202.5,251.3,193.4,251.3z M193.4,282.2
                                        c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C207.7,275.8,201.3,282.2,193.4,282.2z"/>
                                    <path className="st5" d="M198.4,268.7c-0.1-2-0.2-4.3-0.2-6.1h-0.1c-0.5,1.7-1.1,3.4-1.8,5.4l-2.5,6.8h-1.4l-2.3-6.7
                                        c-0.7-2-1.2-3.8-1.6-5.5h0c0,1.8-0.1,4.1-0.3,6.2l-0.4,6h-1.7l1-14.1h2.3l2.4,6.8c0.6,1.7,1.1,3.3,1.4,4.7h0.1
                                        c0.4-1.4,0.9-3,1.5-4.7l2.5-6.8h2.3l0.9,14.1h-1.8L198.4,268.7z"/>
                                </g>
                                <g id="ikonki">
                                    <path id="temperatura_obiegu_2-3" className="st5" d="M370.2,283.4c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6c0-1.5,1-2.9,2.4-3.4v-8.9c0-0.7,0.5-1.3,1.2-1.3s1.2,0.6,1.2,1.3v8.9C369.2,280.5,370.2,281.8,370.2,283.4z"/>
                                    <polygon id="setpoint_obiegu_2-3" className="st5" points="360.4,316.1 360.4,307.3 368,311.7 375.6,316.1 368,320.5 360.4,324.8"/>
                                </g>
                            </g>
                            : <line id="jesli_bez_zaworu_-_linia" className="st0" x1="160.5" y1="297.1" x2="226.2" y2="297.1"/>
                    }
                    <text id="napis_OBIEG_2-3" transform="matrix(1 0 0 1 539.5405 257.1951)" className="st9 st10 st11">{scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ? `OBIEG ${parseInt(circArr[1]) + 1}` : `OBIEG ${parseInt(circArr[0]) + 1}`}</text>
                </g>
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 ?
                    <g id='obieg_3-4'>
                        <g id="rurociag_obiegu_3-4">
                            <line className="st0" x1="97.3" y1="443.6" x2="160.8" y2="443.6"/>
                            <line className="st1" x1="97.3" y1="518.5" x2="651.7" y2="518.5"/>
                            <line className="st0" x1="492.3" y1="443.6" x2="226.1" y2="443.6"/>
                            <polygon className="st2" points="92.7,443.6 92.7,436.4 99,440 105.2,443.6 99,447.2 92.7,450.8 		"/>
                            <polygon className="st3" points="105.2,518.5 105.2,525.7 99,522.1 92.7,518.5 99,514.9 105.2,511.3 		"/>
              
                            <path style={{ fill: 'none', stroke: 'url(#podłogówka)', strokeWidth: 4.922, strokeMiterlimit: '10' }} d="
                            M651.1,518.5h1.4c5.7,0,10.3-4.6,10.3-10.3v-77.7c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1
                            l0,0c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6H612c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1l0,0
                            c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1l0,0
                            c-5.6,0-10.1-4.5-10.1-10.1v-62c0-5.3-4.3-9.6-9.6-9.6h-0.9c-5.3,0-9.6,4.3-9.6,9.6v62c0,5.6-4.5,10.1-10.1,10.1h0
                            c-5.6,0-10.1-4.5-10.1-10.1v-39.3c0-5.3-4.3-9.6-9.6-9.6l0,0"/>
                        </g>
                        <g id="pompa_obiegu_3-4">
                            <circle className="st2" cx="299.6" cy="443.5" r="21.5"/>
                            {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                                scada[`OB${circArr[2]}_PCO`] === 100 ? 
                                    <g>
                                        <g> 
                                            <path className="st5" d="M304.3,443.1c7.1-2.1,8.7-8,5.4-12.4c-1.6-2-5.7-2.5-7.4,0.4c-1.6,2.8,0.3,5.2-1.9,7.8
                                            C301.9,439,304,439.8,304.3,443.1z"/>
                                            <path className="st5" d="M296.9,439.5c-5.4-5-11.3-3.6-13.4,1.5c-1,2.4,0.7,6.2,4.1,6.2c3.2,0,4.4-2.9,7.7-2.2
                                                C294.6,443.7,294.2,441.5,296.9,439.5z"/>
                                            <path className="st5" d="M297.6,447.8c-1.7,7.2,2.6,11.6,8,10.9c2.6-0.3,5-3.7,3.3-6.7c-1.6-2.8-4.7-2.4-5.8-5.5
                                                C302.3,447.7,300.6,449.1,297.6,447.8z"/>
                                            <circle className="st5" cx="299.6" cy="443.5" r="2.8"/>   
                                        </g>
                                    </g>
                                    : null
                                : scada[`OB${circArr[1]}_PCO`] === 100 ? 
                                <g>
                                    <g> 
                                        <path className="st5" d="M304.3,443.1c7.1-2.1,8.7-8,5.4-12.4c-1.6-2-5.7-2.5-7.4,0.4c-1.6,2.8,0.3,5.2-1.9,7.8
                                        C301.9,439,304,439.8,304.3,443.1z"/>
                                        <path className="st5" d="M296.9,439.5c-5.4-5-11.3-3.6-13.4,1.5c-1,2.4,0.7,6.2,4.1,6.2c3.2,0,4.4-2.9,7.7-2.2
                                            C294.6,443.7,294.2,441.5,296.9,439.5z"/>
                                        <path className="st5" d="M297.6,447.8c-1.7,7.2,2.6,11.6,8,10.9c2.6-0.3,5-3.7,3.3-6.7c-1.6-2.8-4.7-2.4-5.8-5.5
                                            C302.3,447.7,300.6,449.1,297.6,447.8z"/>
                                        <circle className="st5" cx="299.6" cy="443.5" r="2.8"/>   
                                    </g>
                                </g>
                                : null
                            } 
                        </g>
                        {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                            scada[`OB${circArr[2]}_TCO`] > 0 ? 
                                <g id="zawor_obiegu_3-4">
                                    <line id="z_zaworem_-_linia_3-4" className="st1" x1="193.4" y1="476.1" x2="193.6" y2="518.5"/>
                                    <g>
                                        <polygon className="st5" points="193.4,477.7 178.9,477.7 186.1,460.8 193.4,443.8 200.6,460.8 207.8,477.7"/>
                                        <polygon className="st5" points="159.5,443.8 159.5,429.4 176.4,436.6 193.4,443.8 176.4,451 159.5,458.3"/>
                                        <polygon className="st5" points="227.3,443.8 227.3,458.3 210.3,451 193.4,443.8 210.3,436.6 227.3,429.4"/>
                                        <path className="st5" d="M193.4,397.8c-9.2,0-16.6,7.4-16.6,16.6c0,8.8,6.8,16,15.5,16.6v8.8c0,0.6,0.5,1.1,1.1,1.1
                                            c0.6,0,1.1-0.5,1.1-1.1V431c8.6-0.6,15.5-7.8,15.5-16.6C209.9,405.3,202.5,397.8,193.4,397.8z M193.4,428.7
                                            c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C207.7,422.3,201.3,428.7,193.4,428.7z"/>
                                        <path className="st5" d="M198.4,415.2c-0.1-2-0.2-4.3-0.2-6.1h-0.1c-0.5,1.7-1.1,3.4-1.8,5.4l-2.5,6.8h-1.4l-2.3-6.7
                                            c-0.7-2-1.2-3.8-1.6-5.5h0c0,1.8-0.1,4.1-0.3,6.2l-0.4,6h-1.7l1-14.1h2.3l2.4,6.8c0.6,1.7,1.1,3.3,1.4,4.7h0.1
                                            c0.4-1.4,0.9-3,1.5-4.7l2.5-6.8h2.3l0.9,14.1h-1.8L198.4,415.2z"/>
                                    </g>
                                    <g id="ikonki">
                                        <path id="temperatura_obiegu_3-4" className="st5" d="M370.2,429.9c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6c0-1.5,1-2.9,2.4-3.4v-8.9c0-0.7,0.5-1.3,1.2-1.3s1.2,0.6,1.2,1.3v8.9C369.2,427,370.2,428.3,370.2,429.9z"/>
                                        <polygon id="setpoint_obiegu_3-4" className="st5" points="360.4,462.6 360.4,453.8 368,458.2 375.6,462.6 368,467 360.4,471.3 	"/>
                                    </g>
                                </g>
                                : <line id="jesli_bez_zaworu_-_linia_3-4" className="st0" x1="160.5" y1="443.6" x2="226.2" y2="443.6"/>
                            : scada[`OB${circArr[1]}_TCO`] > 0 ? 
                                <g id="zawor_obiegu_3-4">
                                    <line id="z_zaworem_-_linia_3-4" className="st1" x1="193.4" y1="476.1" x2="193.6" y2="518.5"/>
                                    <g>
                                        <polygon className="st5" points="193.4,477.7 178.9,477.7 186.1,460.8 193.4,443.8 200.6,460.8 207.8,477.7"/>
                                        <polygon className="st5" points="159.5,443.8 159.5,429.4 176.4,436.6 193.4,443.8 176.4,451 159.5,458.3"/>
                                        <polygon className="st5" points="227.3,443.8 227.3,458.3 210.3,451 193.4,443.8 210.3,436.6 227.3,429.4"/>
                                        <path className="st5" d="M193.4,397.8c-9.2,0-16.6,7.4-16.6,16.6c0,8.8,6.8,16,15.5,16.6v8.8c0,0.6,0.5,1.1,1.1,1.1
                                            c0.6,0,1.1-0.5,1.1-1.1V431c8.6-0.6,15.5-7.8,15.5-16.6C209.9,405.3,202.5,397.8,193.4,397.8z M193.4,428.7
                                            c-7.9,0-14.3-6.4-14.3-14.3c0-7.9,6.4-14.3,14.3-14.3c7.9,0,14.3,6.4,14.3,14.3C207.7,422.3,201.3,428.7,193.4,428.7z"/>
                                        <path className="st5" d="M198.4,415.2c-0.1-2-0.2-4.3-0.2-6.1h-0.1c-0.5,1.7-1.1,3.4-1.8,5.4l-2.5,6.8h-1.4l-2.3-6.7
                                            c-0.7-2-1.2-3.8-1.6-5.5h0c0,1.8-0.1,4.1-0.3,6.2l-0.4,6h-1.7l1-14.1h2.3l2.4,6.8c0.6,1.7,1.1,3.3,1.4,4.7h0.1
                                            c0.4-1.4,0.9-3,1.5-4.7l2.5-6.8h2.3l0.9,14.1h-1.8L198.4,415.2z"/>
                                    </g>
                                    <g id="ikonki">
                                        <path id="temperatura_obiegu_3-4" className="st5" d="M370.2,429.9c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6c0-1.5,1-2.9,2.4-3.4v-8.9c0-0.7,0.5-1.3,1.2-1.3s1.2,0.6,1.2,1.3v8.9C369.2,427,370.2,428.3,370.2,429.9z"/>
                                        <polygon id="setpoint_obiegu_3-4" className="st5" points="360.4,462.6 360.4,453.8 368,458.2 375.6,462.6 368,467 360.4,471.3 	"/>
                                    </g>
                                </g>
                                : <line id="jesli_bez_zaworu_-_linia_3-4" className="st0" x1="160.5" y1="443.6" x2="226.2" y2="443.6"/>
                        }
                        <text id="napis_OBIEG_3-4" transform="matrix(1 0 0 1 539.5405 403.6902)" className="st9 st10 st11">{scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ? `OBIEG ${parseInt(circArr[2]) + 1}` : `OBIEG ${parseInt(circArr[1]) + 1}`}</text>
                    </g>
                    : null   
                }
                <g id="domki">
                    {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                        <g id="obieg_4">
                            <path id="dom_obiegi_do_4" className="st8" d="M663.2,535.7h-164c-9.5,0-17.2-7.7-17.2-17.2v-409c0-6.2,3.3-11.9,8.7-14.9l82-46.5c5.3-3,11.7-3,17,0l82,46.5c5.4,3.1,8.7,8.8,8.7,14.9v409C680.4,528,672.7,535.7,663.2,535.7z"/> 
                        </g>
                        : scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 ?
                            <g id="obieg_3">
                                <path id="dom_obiegi_do_3" className="st8" d="M663.2,535.7h-164c-9.5,0-17.2-7.7-17.2-17.2V254.9c0-6.2,3.3-11.9,8.7-14.9l82-46.5c5.3-3,11.7-3,17,0l82,46.5c5.4,3.1,8.7,8.8,8.7,14.9v263.6C680.4,528,672.7,535.7,663.2,535.7z"/>
                            </g>
                            : <g id="obieg_2">
                                <path id="dom_obiegi_do_2" className="st8" d="M663.2,389.9h-164c-9.5,0-17.2-7.7-17.2-17.2V254.9c0-6.2,3.3-11.9,8.7-14.9l82-46.5c5.3-3,11.7-3,17,0l82,46.5c5.4,3.1,8.7,8.8,8.7,14.9v117.8C680.4,382.2,672.7,389.9,663.2,389.9z"/>
                            </g>
                    }
                </g>
                {scada.TSM9 < -45 ?
                    <text x="40" y="24.21" className="text-scada">E 1</text>
                    : scada.TSM9 > 145 ?
                        <text x="40" y="24.21" className="text-scada">E 2</text>
                        : <text x="40" y="24.21" className="text-scada">{scada.TSM9.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                }
                {scada.TSM10 < -45 ?
                    <text x="205" y="24.21" className="text-scada">E 1</text>
                    : scada.TSM10 > 145 ?
                        <text x="205" y="24.21" className="text-scada">E 2</text>
                        : <text x="205" y="24.21" className="text-scada">{scada.TSM10.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                }
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 ? 
                    scada[`OB${circArr[0]}_TWEW`] < -45 ?
                        <text x="368" y="24.21" className="text-scada">E 1</text>
                        : scada[`OB${circArr[0]}_TWEW`] > 145 ?
                            <text x="368" y="24.21" className="text-scada">E 2</text>
                            : <text x="368" y="24.21" className="text-scada">{scada[`OB${circArr[0]}_TWEW`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                    : null
                }
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 ?  
                    scada[`OB${circArr[1]}_TWEW`] < -45 ?
                        <text x="533" y="24.21" className="text-scada">E 1</text>
                        : scada[`OB${circArr[1]}_TWEW`] > 145 ?
                            <text x="533" y="24.21" className="text-scada">E 2</text>
                            : <text x="533" y="24.21" className="text-scada">{scada[`OB${circArr[1]}_TWEW`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                    : null
                }
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                    scada[`OB${circArr[2]}_TWEW`] < -45 ?
                        <text x="698" y="24.21" className="text-scada">E 1</text>
                        : scada[`OB${circArr[2]}_TWEW`] > 145 ?
                            <text x="698" y="24.21" className="text-scada">E 2</text>
                            : <text x="698" y="24.21" className="text-scada">{scada[`OB${circArr[2]}_TWEW`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                    : null
                }
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                    scada[`OB${circArr[0]}_TCO`] > 0 ?
                        <g>
                            {scada[`OB${circArr[0]}_TCO`] < -45 ?
                                <text x="380" y="138" className="text-scada">E 1</text>
                                : scada[`OB${circArr[0]}_TCO`] > 145 ?
                                    <text x="380" y="138" className="text-scada">E 2</text>
                                    : <text x="380" y="138" className="text-scada">{scada[`OB${circArr[0]}_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            {scada[`OB${circArr[0]}_ZAD_TCO`] < -45 ?
                                <text x="380" y="176" className="text-scada">E 1</text>
                                : scada[`OB${circArr[0]}_ZAD_TCO`] > 145 ?
                                    <text x="380" y="176" className="text-scada">E 2</text>
                                    : <text x="380" y="176" className="text-scada">{scada[`OB${circArr[0]}_ZAD_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                        </g>
                        : null
                    : null
                }
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                    scada[`OB${circArr[1]}_TCO`] > 0 ?
                        <g>
                            {scada[`OB${circArr[1]}_TCO`] < -45 ?
                                <text x="380" y="287" className="text-scada">E 1</text>
                                : scada[`OB$"{circArr[1]}_TCO`] > 145 ?
                                    <text x="380" y="287" className="text-scada">E 2</text>
                                    : <text x="380" y="287" className="text-scada">{scada[`OB${circArr[1]}_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            {scada[`OB${circArr[1]}_ZAD_TCO`] < -45 ?
                                <text x="380" y="324" className="text-scada">E 1</text>
                                : scada[`OB${circArr[1]}_ZAD_TCO`] > 145 ?
                                    <text x="380" y="324" className="text-scada">E 2</text>
                                    : <text x="380" y="324" className="text-scada">{scada[`OB${circArr[1]}_ZAD_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                        </g>
                        : null
                    : scada[`OB${circArr[0]}_TCO`] > 0 ?
                        <g>
                            {scada[`OB${circArr[0]}_TCO`] < -45 ?
                                <text x="380" y="287" className="text-scada">E 1</text>
                                : scada[`OB$"{circArr[0]}_TCO`] > 145 ?
                                    <text x="380" y="287" className="text-scada">E 2</text>
                                    : <text x="380" y="287" className="text-scada">{scada[`OB${circArr[0]}_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            {scada[`OB${circArr[0]}_ZAD_TCO`] < -45 ?
                                <text x="380" y="324" className="text-scada">E 1</text>
                                : scada[`OB${circArr[0]}_ZAD_TCO`] > 145 ?
                                    <text x="380" y="324" className="text-scada">E 2</text>
                                    : <text x="380" y="324" className="text-scada">{scada[`OB${circArr[0]}_ZAD_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                        </g>
                        : null
                }
                {scada[`OB${circArr[0]}_XZM_TRANSM`] === 0 && scada[`OB${circArr[1]}_XZM_TRANSM`] === 0 ?
                    scada[`OB${circArr[2]}_XZM_TRANSM`] === 0 ?
                        scada[`OB${circArr[2]}_TCO`] > 0 ?
                            <g>
                                {scada[`OB${circArr[2]}_TCO`] < -45 ?
                                    <text x="380" y="433" className="text-scada">E 1</text>
                                    : scada[`OB${circArr[2]}_TCO`] > 145 ?
                                        <text x="380" y="433" className="text-scada">E 2</text>
                                        : <text x="380" y="433" className="text-scada">{scada[`OB${circArr[2]}_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                {scada[`OB${circArr[2]}_ZAD_TCO`] < -45 ?
                                    <text x="380" y="471" className="text-scada">E 1</text>
                                    : scada[`OB${circArr[2]}_ZAD_TCO`] > 145 ?
                                        <text x="380" y="471" className="text-scada">E 2</text>
                                        : <text x="380" y="471" className="text-scada">{scada[`OB${circArr[2]}_ZAD_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                            </g>
                            : null
                        : scada[`OB${circArr[1]}_TCO`] > 0 ?
                            <g>
                                {scada[`OB${circArr[1]}_TCO`] < -45 ?
                                    <text x="380" y="433" className="text-scada">E 1</text>
                                    : scada[`OB${circArr[1]}_TCO`] > 145 ?
                                        <text x="380" y="433" className="text-scada">E 2</text>
                                        : <text x="380" y="433" className="text-scada">{scada[`OB${circArr[1]}_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                                {scada[`OB${circArr[1]}_ZAD_TCO`] < -45 ?
                                    <text x="380" y="471" className="text-scada">E 1</text>
                                    : scada[`OB${circArr[1]}_ZAD_TCO`] > 145 ?
                                        <text x="380" y="471" className="text-scada">E 2</text>
                                        : <text x="380" y="471" className="text-scada">{scada[`OB${circArr[1]}_ZAD_TCO`].toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                                }
                            </g>
                            : null
                    : null
                }
            </svg>
            {/*<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 765 555" style={{ margin: "40px 0" }}>
                <g id="Layer_1" className='scadaCircuit2'>
                    <rect id="tlo" className="st0" width="765" height="555"/>
                    <g id="back">
                        <rect id="tlo" className="cls-4" width="765" height="555"/>
                    </g>
                    <g id="linijka">
                        <g id="symbol_pogody">
                        <path className="cls-3" d="M57.55,24.03c-1.31,0-2.28,.54-3.04,1.5l-.21-.06c0-2.82-2.28-5.03-5.1-5.03s-5.1,2.44-5.1,5.61h-.04c-3.26,0-5.23,2.47-5.23,5.29s2.28,5.1,5.1,5.1h17.8c2.28,0,4.13-1.85,4.13-4.13s-1.85-4.13-4.13-4.13c0,0,.16-4.15-4.19-4.15Z"/>
                            <g>
                                <g><line className="cls-2" x1="63.06" y1="23.12" x2="65.44" y2="23.12"/>
                                    <line className="cls-2" x1="48.6" y1="16.26" x2="50.15" y2="17.82"/>
                                    <line className="cls-2" x1="55.46" y1="13.32" x2="55.46" y2="15.63"/>
                                    <line className="cls-2" x1="62.33" y1="16.27" x2="60.76" y2="17.82"/>
                                </g><path className="cls-2" d="M51.44,20.92c.77-1.46,2.31-2.46,4.07-2.46,2.54,0,4.61,2.06,4.61,4.61,0,.52-.09,1.03-.25,1.49"/>
                            </g>
                        </g>
                        <g>
                            <path className="cls-1" d="M187.98,37.1h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64,.29-1.24,.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72,.67,0,1.27,.24,1.63,.66l9.68,8.53c.49,.43,.77,1.05,.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66,.18-.88,.43l-9.73,8.5c-.18,.15-.47,.46-.47,.92v11.15c0,.67,.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                            {scada.OB1_TWEW < 200 ?
                                <path className="cls-5" d="M179.1,21.19c.19-.03,.42,0,.42,.42v7.87h1.79c.31,0,.45,.11,.45,.42v1.17c0,.32-.13,.44-.45,.44h-6.12c-.31,0-.45-.12-.45-.44v-1.17c0-.31,.13-.42,.45-.42h1.86v-5.92l-1.86,.27c-.28,.04-.45-.06-.45-.38v-1.17c0-.32,.17-.44,.45-.48l3.92-.61Z"/>
                                : null
                            }
                        </g>
                    {scada.OB1_TWEW < 200 ? 
                            <g>
                                <path className="cls-1" d="M300.68,37.1h-19.75c-1.22,0-2.21-.99-2.21-2.21v-11.15c0-.64,.29-1.24,.82-1.68l9.68-8.45c.38-.43,1-.72,1.58-.72,.67,0,1.27,.24,1.63,.66l9.68,8.53c.49,.43,.77,1.05,.77,1.7v11.13c0,1.21-.99,2.2-2.2,2.2Zm-9.88-23.21c-.29,0-.66,.18-.88,.43l-9.73,8.5c-.18,.15-.47,.46-.47,.92v11.15c0,.67,.54,1.21,1.21,1.21h19.75c.66,0,1.2-.54,1.2-1.2v-11.13c0-.36-.16-.71-.43-.95l-9.73-8.58c-.22-.24-.55-.36-.92-.36Z"/>
                                <path className="cls-5" d="M294.02,29.48c.31,0,.45,.11,.45,.42v1.17c0,.32-.14,.44-.45,.44h-6.21c-.31,0-.45-.12-.45-.44v-2.7c0-.81,.38-1.07,.92-1.32l3.2-1.46c.27-.12,.48-.3,.48-.79v-.66c0-.69-.51-.97-1.21-.97s-1.2,.29-1.2,.97v.54c0,.32-.1,.45-.42,.45h-1.54c-.31,0-.43-.13-.43-.45v-.93c0-1.29,.9-2.56,3.6-2.56s3.65,1.27,3.66,2.56v.96c0,1.58-.78,2.19-1.46,2.49l-2.54,1.15c-.46,.21-.57,.32-.57,.75v.38h4.18Z"/>
                            </g>
                            : null
                    }
                    </g>
                    <line id="powrot" className="st2" x1="174.7" y1="345.3" x2="699.2" y2="345.3"/>
                    <path id="domek" className="st3" d="M733.5,241.9c0-4.7-2-9.2-5.6-12.4l-91-80.2c-2.8-3.2-7.4-4.5-11.7-4.5c-4.3,0-8.9,2.4-11.7,5.6
                        l-90.6,79.1c-3.5,2.9-6,7.2-6,12.1v25.2v53.8v25.4c0,8.8,7.2,16,16,16h184.7c8.8,0,15.9-7.1,15.9-15.9V241.9z"/>
                    <line id="zasilenie" className="st1" x1="537.5" y1="269.2" x2="277.4" y2="269.2"/>
                    <linearGradient id="podlogowka_00000057112749693456862590000002914891455407187371_" gradientUnits="userSpaceOnUse" x1="536.8577" y1="295.7044" x2="712.5854" y2="295.7044">
                        <stop  offset="0" style={{ stopColor: '#D62027' }} />
                        <stop  offset="9.620906e-02" style={{ stopColor: '#CD242E' }} />
                        <stop  offset="0.2563" style={{ stopColor: '#B32D3F' }} />
                        <stop  offset="0.4605" style={{ stopColor: '#8A3D5C' }} />
                        <stop  offset="0.7009" style={{ stopColor: '#505384' }} />
                        <stop  offset="0.969" style={{ stopColor: '#076EB7' }} />
                        <stop  offset="0.9944" style={{ stopColor: '#0071BC' }} />
                    </linearGradient>
                    <path id="podlogowka" style={{ fill: 'none', stroke: 'url(#podlogowka_00000057112749693456862590000002914891455407187371_)', strokeWidth: 5, strokeMiterlimit: '10' }} d="
                        M698.2,345.3h1.4c5.8,0,10.5-4.7,10.5-10.5v-79c0-5.4-4.4-9.7-9.7-9.7h-1c-5.4,0-9.7,4.4-9.7,9.7v63c0,5.6-4.6,10.2-10.2,10.2l0,0
                        c-5.6,0-10.2-4.6-10.2-10.2v-63c0-5.4-4.4-9.7-9.7-9.7h-1c-5.4,0-9.7,4.4-9.7,9.7v63c0,5.6-4.6,10.2-10.2,10.2l0,0
                        c-5.6,0-10.2-4.6-10.2-10.2v-63c0-5.4-4.4-9.7-9.7-9.7h-1c-5.4,0-9.7,4.4-9.7,9.7v63c0,5.6-4.6,10.2-10.2,10.2h0
                        c-5.6,0-10.2-4.6-10.2-10.2v-63c0-5.4-4.4-9.7-9.7-9.7h-1c-5.4,0-9.7,4.4-9.7,9.7v63c0,5.6-4.6,10.2-10.2,10.2h0
                        c-5.6,0-10.2-4.6-10.2-10.2v-39.9c0-5.4-4.4-9.7-9.7-9.7l0,0"/>
                    <g id="pompka_obiegowa_OB1_x5F_Pco">
                        <circle className="st5" cx="332.4" cy="269" r="21.8"/>
                        {scada.OB1_PCO === 100 ? 
                            <g>
                                <g>
                                    <path className="st6" d="M337.3,268.6c7.2-2.2,8.9-8.1,5.5-12.6c-1.6-2.1-5.8-2.5-7.6,0.5c-1.6,2.8,0.3,5.3-2,7.9
                                        C334.8,264.5,336.9,265.3,337.3,268.6z"/>
                                </g>
                                <g>
                                    <g>
                                        <path className="st6" d="M329.7,265c-5.5-5.1-11.5-3.6-13.6,1.5c-1,2.4,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
                                            C327.4,269.3,327,267,329.7,265z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st6" d="M330.4,273.4c-1.7,7.3,2.6,11.8,8.1,11c2.6-0.3,5.1-3.8,3.4-6.8c-1.6-2.8-4.8-2.4-5.8-5.6
                                            C335.2,273.3,333.4,274.8,330.4,273.4z"/>
                                    </g>
                                </g>
                                <g>
                                    <circle className="st6" cx="332.4" cy="269" r="2.9"/>
                                </g>
                            </g>
                            : null
                        }
                    </g>
                    {scada.OB1_TCO > 0 ? 
                        <g id="zawor_3_drogowy">
                            <g>
                                <line id="zasilenie_z_bufora" className="st1" x1="177.6" y1="269.2" x2="211" y2="269.2"/>
                                <line id="zwrot_do_mieszacza" className="st2" x1="244.2" y1="301.9" x2="244.4" y2="345.1"/>
                                <g>
                                    <g>
                                        <path className="st6" d="M244.1,222.4c-9.3,0-16.9,7.6-16.9,16.9c0,8.9,6.9,16.2,15.7,16.8v8.9c0,0.6,0.5,1.1,1.1,1.1
                                            c0.6,0,1.1-0.5,1.1-1.1v-8.9c8.8-0.6,15.7-7.9,15.7-16.8C261,230,253.4,222.4,244.1,222.4z M244.1,253.9
                                            c-8,0-14.6-6.5-14.6-14.6c0-8,6.5-14.6,14.6-14.6c8,0,14.6,6.5,14.6,14.6C258.7,247.3,252.2,253.9,244.1,253.9z"/>
                                    </g>
                                </g>
                                <g>
                                    <path className="st6" d="M249.3,240.1c-0.1-2-0.2-4.4-0.2-6.2H249c-0.5,1.7-1.1,3.5-1.8,5.4l-2.5,6.9h-1.4l-2.3-6.8
                                        c-0.7-2-1.3-3.9-1.7-5.6h0c0,1.8-0.1,4.2-0.3,6.3l-0.4,6.1h-1.8l1-14.3h2.4l2.4,6.9c0.6,1.8,1.1,3.3,1.4,4.8h0.1
                                        c0.4-1.4,0.9-3,1.5-4.8l2.6-6.9h2.4l0.9,14.3h-1.8L249.3,240.1z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <polygon className="st6" points="244.1,303.6 229.5,303.6 236.8,286.4 244.1,269.2 251.5,286.4 258.8,303.6"/>
                                </g>
                                <g>
                                    <polygon className="st6" points="209.7,269.2 209.7,254.5 226.9,261.8 244.1,269.2 226.9,276.5 209.7,283.9"/>
                                </g>
                                <g>
                                    <polygon className="st6" points="278.6,269.2 278.6,283.9 261.4,276.5 244.1,269.2 261.4,261.8 278.6,254.5"/>
                                </g>
                            </g>
                        </g>
                        : <line id="zasilenie_z_bufora" className="st1" x1="177.6" y1="269.2" x2="278" y2="269.2"/>
                    }
                    {scada.OB1_TCO > 0 ?
                        <g>
                            <polygon className="st6" points="394,278 410,287 394,296"/>
                            <circle cx="402" cy="256"  r="4" fill="#FFFFFF"/>
                            <rect  x="400.5" y="243" ry="2" width="3" height="12" fill="#FFFFFF"/>
                        </g>
                        : null
                    }
                    <path id="bufor" className="st7" d="M166.4,360.5h-122c-4.8,0-8.8-3.9-8.8-8.8V193.4c0-4.8,3.9-8.8,8.8-8.8h122c4.8,0,8.8,3.9,8.8,8.8
                        v158.3C175.2,356.6,171.2,360.5,166.4,360.5z"/>
                        <path className={`st8 ${scada.SXF_TOE === 1 && scada.SBF_OOF === 1 ?
                            scada.SBB_QHT === 1 ? `orange`
                                : scada.SBB_QHL === 1 ? `blue`
                                    : `green`
                            : `green`}`} d="M171.3,200.5v150c0,3.1-2.5,5.7-5.7,5.7H45.1c-3.1,0-5.7-2.5-5.7-5.7V200.4c1.1-0.4,2.2-1,3.3-1.6
                        c3,1.7,6.4,2.7,10.1,2.7c3.8,0,7.3-1,10.4-2.8c3.1,1.9,6.8,3,10.7,3c3.8,0,7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8
                        c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8c3.8,0,7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8
                        c3.8,0,7.4-1,10.4-2.8C169,199.5,170.1,200,171.3,200.5z"/>
                    {scada.TSM9 < -45 ?
                        <text x="80" y="35" className="text-scada">E 1</text>
                        : scada.TSM9 > 145 ?
                            <text x="80" y="35" className="text-scada">E 2</text>
                            : <text x="80" y="35" className="text-scada">{scada.TSM9.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    }
                    {scada.TSM10 < -45 ?
                        <text x="200" y="35" className="text-scada">E 1</text>
                        : scada.TSM10 > 145 ?
                            <text x="200" y="35" className="text-scada">E 2</text>
                            : <text x="200" y="35" className="text-scada">{scada.TSM10.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    }
                    {scada.OB1_TWEW < 200 ? 
                        scada.OB1_TWEW < -45 ?
                            <text x="320" y="35" className="text-scada">E 1</text>
                            : scada.OB1_TWEW > 145 ?
                                <text x="320" y="35" className="text-scada">E 2</text>
                                : <text x="320" y="35" className="text-scada">{scada.OB1_TWEW.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                        : null
                    }
                    {scada.OB1_TCO > 0 ?
                        <g>
                            {scada.OB1_TCO < -45 ?
                                <text x="415" y="260" className="text-scada">E 1</text>
                                : scada.OB1_TCO > 145 ?
                                    <text x="415" y="260" className="text-scada">E 2</text>
                                    : <text x="415" y="260" className="text-scada">{scada.OB1_TCO.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                            {scada.OB1_ZAD_TCO < -45 ?
                                <text x="415" y="295" className="text-scada">E 1</text>
                                : scada.OB1_ZAD_TCO > 145 ?
                                    <text x="415" y="295" className="text-scada">E 2</text>
                                    : <text x="415" y="295" className="text-scada">{scada.OB1_ZAD_TCO.toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            }
                        </g>
                        : null
                    } 
                    <text x="585" y="230" className="text-scada"><tspan baselineShift="sup"></tspan>OBIEG 2</text>
                    <text x="48" y="238" className="text-scada middle"> bufor CO</text>
                    {parseInt(scada.SXB_PV) === 0 ? 
                        parseInt(scada.SWEXT_BUF) === 1 ?
                            (parseInt(scada.TSM17) <= -45 || parseInt(scada.TSM17) >= 100) ?
                                null
                                :<text x="55" y="300" className="text-scada big">{Math.round(parseFloat(scada.TSM17))}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            : (parseInt(scada.T6fiz) <= -45 || parseInt(scada.T6fiz) >= 100) ?
                                null
                                : <text x="55" y="300" className="text-scada big">{Math.round(parseFloat(scada.T6fiz))}<tspan baselineShift="sup">&deg;</tspan>C</text>
                        : <text x="76" y="300" className="text-scada big">PV</text>
                    }
                </g>
                </svg>*/}
        </React.Fragment>
    )
}

export default ScadaCircuit2