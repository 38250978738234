import fetchRefreshToken from './fetchRefreshToken'

const fetchTelelerepeater = async (installation) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customer/extend_telemetry_time/${installation}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${newToken}`
        }
      }).then(response => response.json())
      return res
    })

  return response
}

export default fetchTelelerepeater