import React, { useState, useEffect } from 'react'
import H_on from '../../../../../../img/H_on.svg'
import H_off from '../../../../../../img/H_off.svg'
import AC_on from '../../../../../../img/AC_on.svg'
import AC_off from '../../../../../../img/AC_off.svg'
import FC_on from '../../../../../../img/FC_on.svg'
import FC_off from '../../../../../../img/FC_off.svg'
import X from '../../../../../../img/x.svg'
import { Grid, Typography,Dialog, DialogContent, IconButton } from '@material-ui/core'
import { CarouselInputMulti } from '../../../../../../components'
import { mainStyleClasses } from '../../../../../../theme'
import { dataRange } from '../../../../../../data/dataRange'

const HeatCoolState = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings);
    const [open, setOpen] = useState({ status: false, name: "", init: 0, min: 0, max: 0, num: "", title: "", val: 0 })

    const handleClose = () => {
        setState({ ...state, [open.name]: open.val })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({ 
            ...open, 
            val: val 
        })
        // setPublish({ [num]: val })
    }

    const handleClickOpen = (name, init, min, max, num, title, val) => {
        setOpen({ status: true, name: name, init: init, min: min, max: max, num: num, title: title, val: val })
    }

    const handleModeSwitch = (modeName, mode) => {
        if (mode === 1) {
            setPublish({ [modeName]: 0 })
            setState({...state, [modeName]: 0})
        } else { 
            setPublish({ [modeName]: 1 })
            setState({...state, [modeName]: 1}) 
        }
    }

    useEffect(() => {
        let mounted = true

        setState(settings)

        return () => {
            mounted = false
        }
    }, [settings])
  
    return (
        <React.Fragment>
            {state.SBF_OOF === 1 ?
                state.SXF_TOE === 1 ?
                    <Grid container style={{ padding: state.SBF_OOF === 1 && state.SXF_TOE === 1 && state.SBF_HTX === 0 && state.SBF_FCX === 0 && state.SBF_ACX === 0 ? null : "30px 25px" }}>
                        <Grid item xs={9}>
                            {state.SBF_HTX === 1 ? 
                                state.SBF_HTS === 1 ?
                                    <Grid container>
                                        <Grid item xs={3} onClick={() => handleModeSwitch('SBF_HTS', state.SBF_HTS)}>
                                            <img alt='ogrzewanie włączone' className='mode-icon' src={H_on} />
                                        </Grid>
                                        <Grid item xs={9} className='text-centering'>
                                            <Typography variant='h5' style={{ margin: 0 }}>ogrzewanie uruchomi się <br/> poniżej temperatury zewnętrznej</Typography>
                                        </Grid>
                                    </Grid>
                                    :  <Grid container>
                                            <Grid item xs={3} onClick={() => handleModeSwitch('SBF_HTS', state.SBF_HTS)}>
                                                <img alt='ogrzewanie wyłączone' className='mode-icon' src={H_off} />
                                            </Grid>
                                            <Grid item xs={9} className='text-centering'>
                                                <Typography variant='h5' style={{ margin: 0 }}>ogrzewanie wyłączone</Typography>
                                            </Grid>
                                        </Grid>
                                : null
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {state.SBF_HTX === 1 && state.SBF_HTS === 1 ?
                                <Grid item xs={12} onClick={() => handleClickOpen("SX4_PH", state.SX4_PH, dataRange().SX4_PH.min, state.SX4_PC - 1, "SX4_PH", "Próg załączenia ogrzewania", state.SX4_PH)}>
                                    <Typography variant="h5" style={{ margin: '3px auto' }} className="modeEdit-box">{state.SX4_PH}</Typography>
                                </Grid> 
                            : null 
                            }
                        </Grid>
                        <Grid item xs={9}>
                            {state.SBF_FCX === 1 ? 
                                state.SBF_FCS === 1 ?
                                    <Grid container>
                                        <Grid item xs={3} onClick={() => handleModeSwitch('SBF_FCS', state.SBF_FCS)}>
                                            <img alt='chłodzenie pasywne włączone' className='mode-icon' src={FC_on} />
                                        </Grid>
                                        <Grid item xs={9} className='text-centering'>
                                            <Typography variant='h5' style={{ margin: 0 }}>chłodzenie pasywne uruchomi się <br/> powyżej temperatury zewnętrznej</Typography>
                                        </Grid>
                                    </Grid>
                                    :  <Grid container>
                                            <Grid item xs={3} onClick={() => handleModeSwitch('SBF_FCS', state.SBF_FCS)}>
                                                <img alt='chłodzenie pasywne wyłączone' className='mode-icon' src={FC_off} />
                                            </Grid>
                                            <Grid item xs={9} className='text-centering'>
                                                <Typography variant='h5' style={{ margin: 0 }}>chłodzenie pasywne wyłączone</Typography>
                                            </Grid>
                                        </Grid>
                                : null
                            }
                            {state.SBF_ACX === 1 ? 
                                state.SBF_ACS === 1 ?
                                        <Grid container>
                                            <Grid item xs={3} onClick={() => handleModeSwitch('SBF_ACS', state.SBF_ACS)}>
                                                <img alt='chłodzenie aktywne włączone' className='mode-icon' src={AC_on} />
                                            </Grid>
                                            <Grid item xs={9} className='text-centering'>
                                                <Typography variant='h5' style={{ margin: 0 }}>chłodzenie aktywne uruchomi się <br/> powyżej temperatury zewnętrznej</Typography>
                                            </Grid>
                                        </Grid>
                                        :  <Grid container>
                                                <Grid item xs={3} onClick={() => handleModeSwitch('SBF_ACS', state.SBF_ACS)}>
                                                    <img alt='chłodzenie aktywne wyłączone' className='mode-icon' src={AC_off} />
                                                </Grid>
                                                <Grid item xs={9} className='text-centering'>
                                                    <Typography variant='h5' style={{ margin: 0 }}>chłodzenie aktywne wyłączone</Typography>
                                                </Grid>
                                            </Grid>
                                : null
                            }
                        </Grid>
                        <Grid item xs={3}> 
                            {state.SBF_FCX === 1 || state.SBF_ACX === 1 ?
                                state.SBF_FCX === 1 && state.SBF_ACX === 1 ? 
                                    <Grid container>
                                        {state.SBF_FCS === 1 || state.SBF_ACS === 1 ?
                                            <Grid item xs={12} onClick={() => handleClickOpen("SX4_PC", state.SX4_PC, state.SX4_PH + 1, dataRange().SX4_PC.max, "SX4_PC", "Próg załączenia chłodzenia", state.SX4_PC)}>
                                                <Typography variant="h5" style={{ margin: '3px auto' }} className="doubleModeEdit-box">{state.SX4_PC}</Typography>
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                    :   <Grid container>
                                            {state.SBF_FCS === 1 || state.SBF_ACS === 1 ?
                                                <Grid item xs={12} onClick={() => handleClickOpen("SX4_PC", state.SX4_PC, state.SX4_PH + 1, dataRange().SX4_PC.max, "SX4_PC", "Próg załączenia chłodzenia", state.SX4_PC)}>
                                                    <Typography variant="h5" style={{ margin: '3px auto' }} className="modeEdit-box">{state.SX4_PC}</Typography>
                                                </Grid>
                                                :   null
                                            }
                                        </Grid>
                                : null
                            }
                        </Grid>
                    </Grid>
                :
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className='text-centering'>
                            <Typography variant='h5' style={{ marginTop: '15px', marginBottom: '15px', textAlign: 'center' }}>Uwaga, obieg grzewczo-chłodniczy jest wyłączony.<br/>Wszystkie funkcje są niedostępne.<br/>Włącz obieg grzewczo-chłodniczy, aby ustawić funkcje.</Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                : 
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className='text-centering'>
                        <Typography variant='h5' style={{ marginTop: '15px', marginBottom: '15px', textAlign: 'center' }}>Uwaga, urządzenie jest wyłączone. Wszystkie funkcje są niedostępne. <br/> Włącz urządzenie, aby ustawić funkcje.</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            }
            {state.SBF_OOF === 1 && state.SXF_TOE === 1 && state.SBF_HTX === 0 && state.SBF_FCX === 0 && state.SBF_ACX === 0 ? 
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className='text-centering'>
                        <Typography variant='h5' style={{ marginTop: '15px', marginBottom: '15px', textAlign: 'center' }}>Uwaga, brak zezwoleń na grzanie i chłodzenie.<br/>Wszystkie funkcje są niedostępne.<br/>Aktywuj zezwolenia, aby ustawić funkcje.</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                : null
            }
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default HeatCoolState