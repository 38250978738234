const calcCurvePoints = (p1, p2, p3, upCurve) => {
    const pointsTable = []
    for(let i = -30; i <= 20; i +=5){
        let yhelp = (p1 * i * i - p2 * i + p3) + upCurve
        pointsTable.push({ x: i, y: yhelp >= 55 ? 55 : (p1 * i * i - p2 * i + p3) + upCurve})
    }

    return pointsTable
}

export const curveCalc = (number, upCurve) => {
    switch (number) {
        case 0:
            return calcCurvePoints(-0.0033, 0.2241, 25.883, upCurve)

        case 1:
            return calcCurvePoints(-0.0027, 0.319, 27.704, upCurve)

        case 2:
            return calcCurvePoints(-0.0033, 0.4115, 29.877, upCurve)

        case 3:
            return calcCurvePoints(-0.0054, 0.5008, 32.49, upCurve)

        case 4:
            return calcCurvePoints(-0.0066, 0.5716, 34.451, upCurve)

        case 5:
            return calcCurvePoints(-0.0068, 0.6412, 35.982, upCurve)

        case 6:
            return calcCurvePoints(-0.0079, 0.7268, 37.798, upCurve)

        case 7:
            return calcCurvePoints(-0.0099, 0.8003, 40.012, upCurve)

        case 8:
            return calcCurvePoints(-0.0095, 0.7635, 46.729, upCurve)

        case 9:
            return calcCurvePoints(-0.0104, 0.9367, 50.851, upCurve)
    
        default:
            break;
    }    
}