import React from 'react'
import { Typography, Grid } from '@material-ui/core'

const InstallerData = ({ installator }) => {
    return (
        <React.Fragment>
            <Typography variant="h2">Dane instalatora:</Typography>
                <Typography variant="body1">Imię i nazwisko: {installator.first_name} {installator.last_name}</Typography>
                <Typography variant="body1">E-mail: {installator.email}</Typography>
                <Typography variant="body1">Telefon: {installator.phone}</Typography>
                <Typography variant="body1">Nr certyfikatu: {installator.installator_cert.cert_nr}</Typography>
            {installator.partners[0]? <Typography variant="body1">Firma: {installator.partners[0].name}</Typography>
            : null}
        </React.Fragment>
    )
}

export default InstallerData