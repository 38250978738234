import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { descriptionDictionary } from '../../../../../dataFilter/descriptionDictionary'
// import Socket from '../../../../../Socket'
// import { ToastInfo } from '../../../../../components'
import autoIcon from '../../../../../../../img/AUTO.svg'
import awayIcon from '../../../../../../../img/AWAY.svg'
import ecoIcon from '../../../../../../../img/ECO.svg'
import timeIcon from '../../../../../../../img/TIME.svg'
import offIcon from '../../../../../../../img/OFF.svg'

const iconTable = {
    AUTO: autoIcon,
    SXF_AWA: awayIcon,
    SXF_ECO: ecoIcon,
    SXF_TIME: timeIcon,
    SBF_OOF: offIcon
}

const SelectOption = ({ mode }) => <option value={mode[0]}>{descriptionDictionary.main.MODE[mode[0]].name}</option>

const Mode = ({ mode, setPublish }) => {

    const [modeStateValue, setModeState] = useState("AUTO");
    const [state, setState] = useState(null);

    let response;
    const handleModeChange = (e) => {

        let sendData

        switch (e.target.value) {
            case "AUTO":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 0,
                    SXF_ECO: 0,
                    SXF_TIME: 0,
                }
                break;
            case "SXF_ECO":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 0,
                    SXF_ECO: 1,
                    SXF_TIME: 0,
                }
                break;
            case "SXF_AWA":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 1,
                    SXF_ECO: 0,
                    SXF_TIME: 0,
                }
                break;
            case "SBF_OOF":
                sendData = {
                    SBF_OOF: 0,
                    SXF_AWA: 0,
                    SXF_ECO: 0,
                    SXF_TIME: 0,
                }
                break;
            case "SXF_TIME":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 0,
                    SXF_ECO: 0,
                    SXF_TIME: 1,
                }
                break;
            default:
                console.log(`Error`);
        }
        setPublish(sendData)
        // response = Socket.onPublish(client, sendData);
        // setModeState(e.target.value)

        // if (response !== null && response !== undefined) {
        //     setState(response);
        //     response = null;
        // } else if (response === null || response === undefined) {
        //     setState(null);
        // }
    }

    const modeList = Object.entries(mode).map(entry => <SelectOption key={entry[0]} mode={entry} />)

    const modeOnOff = mode => {

        if (mode.SBF_OOF === 1) {
            const [modeState,] = Object.entries(mode).filter(entry => entry[0] !== "SBF_OOF" && entry[1] === 1 ? entry[0] : null)

            if (modeState) {
                setModeState(modeState[0])
            } else {
                setModeState("AUTO")
            }
        } else {
            setModeState("SBF_OOF")
        }
    }

    useEffect(() => {
        modeOnOff(mode)
    }, [mode])

    return (
        <Row>
            <Col xs={4} className="np">
                {modeStateValue ? <img alt="tryb" className="mode-icon" src={iconTable[modeStateValue]} /> : null}
            </Col>
            <Col xs={8}>
                <Form.Control as="select" custom onChange={e => handleModeChange(e)} value={modeStateValue} >
                    {modeList}
                </Form.Control>
            </Col>
            {/*state ? (<ToastInfo response={state} close={() => setState(null)} />) : null*/}
        </Row>
    )
}

export default Mode