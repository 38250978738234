import React, { useReducer, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Navbar from 'react-bootstrap/Navbar'
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary'
import awayIcon from '../../../../../../img/AWAY2.svg'
import { NumberInput } from '../../../../components'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: parseInt(action.payload) }
    }
}

const Away = ({ away, setPublish }) => {
    const [controller, setAwayState] = useReducer(switchOption, away)

    useEffect(() => {
        setAwayState({ payload: away, case: "UPDATE_FROM_BROKER" })
    }, [away])

    
    const handleChangeAway = e => {
        setAwayState({ type: e.id, payload: e.value })
        const sendKey = e.id;
        const sendData = e.value;

        setPublish({ [sendKey]: sendData})
    }

    return (
        <React.Fragment>
            <Row>
                <Navbar bg="primary" variant="dark" style={{ width: "100%" }}>
                    <Navbar.Brand className="mr-auto"><img alt="tryb away" className="mode-icon" src={awayIcon} /></Navbar.Brand>
                    <Navbar.Brand>AWAY</Navbar.Brand>
                </Navbar>
            </Row>
            <Row>
                <Col lg={6} xs={12}>
                    <Row className="bottom-margin">
                        <Col xs={6} className="text-left">
                            <p>{descriptionDictionary.away.descript}</p><br />
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <Col xs={4}> &nbsp;</Col>
                                <NumberInput min={0} max={99} descript={descriptionDictionary.away.AWAY_CLOCK} value={controller["AWAY_CLOCK"]}
                                    stamp={"AWAY_CLOCK"} stateStamp={"AWAY_CLOCK"} setTemperatureState={setAwayState} handleSendTemperatureState={handleChangeAway} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Away