import React, { useState, useEffect } from 'react'
import { Typography, Grid, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTheme, VictoryScatter, VictoryLabel } from 'victory'
import { curveCalc } from '../HeatingCurve/curveCalc'
import { CarouselInputMulti } from '../../../../components'
import { mainStyleClasses } from '../../../../theme'
import X from '../../../../img/x.svg'
import { dataRange } from '../../../../data/dataRange'

const drawChartLine = (OB1_KRZYWA, upCurve) => {
    const lineTable = []

    // for (let i = 0; i < 10; i++) {
    //     lineTable.push(
    //         <VictoryLine
    //             key={`CUN_HCU_${i}`}
    //             interpolation="basis"
    //             data={curveCalc(i, 0)}
    //             style={{
    //                 data: { stroke: i === CUN_HCU ? "#42bd6b" : "#e7e7e7", strokeWidth: i === CUN_HCU ? 0.5 : 0.5 }
    //             }}
    //         />
    //     )
    // }

    lineTable.push(
        <VictoryLine
            key={`OB1_KRZYWA_active`}
            interpolation="basis"
            data={curveCalc(OB1_KRZYWA, upCurve)}
            style={{
                data: { stroke: "#42bd6b", strokeWidth: 2 }
            }}
        />
    )

    return lineTable
}

const HeatingCurve2 = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings)
    const [open, setOpen] = useState({ status: false, name: "", init: 0, min: 0, max: 0, num: "", title: "" })

    const handleClickOpen = (name, init, min, max, num, title) => {
        setOpen({ status: true, name: name, init: init, min: min, max: max, num: num, title: title })
    }

    const handleClose = () => {
        setState({ [open.num]: open.init })
        setPublish({ [open.num]: open.init })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({ 
            ...open, 
            init: val 
        })
    }

    useEffect(() => {
        let mounted = true
        
        setState(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">Parametry krzywej grzewczej drugiego obiegu</Typography>
                </Grid>
                <Grid item xs={5} style={{zIndex: 1000}}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{ lineHeight: "60px" }}>Numer krzywej grzewczej</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" className="edit-box" onClick={() => handleClickOpen('OB1_KRZYWA', state.OB1_KRZYWA, dataRange().OB1_KRZYWA.min, dataRange().OB1_KRZYWA.max, 'OB1_KRZYWA', 'Wybierz numer krzywej')}>{state.OB1_KRZYWA}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
                {/*<Grid item xs={5} style={{zIndex: 1000}}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{lineHeight: "60px"}}>Podbicie krzywej grzewczej</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" className="edit-box" onClick={() => handleClickOpen('CUN_D_HCU', settings.CUN_D_HCU, 0, 10, 'CUN_D_HCU', 'Ustaw podbicie krzywej')}>{settings.CUN_D_HCU}</Typography>
                        </Grid>
                    </Grid>
    </Grid>*/}
                <Grid item xs={8} style={{marginTop: "-60px"}}>
                    <VictoryChart>
                        <VictoryAxis crossAxis
                            width={300}
                            domain={[-30, 20]}
                            offsetY={50}
                            standalone={false}
                            tickValues={[-30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20]}
                            tickLabelComponent={<VictoryLabel dy={0} style={{ fontSize: 12, fill: '#ffffff' }} />}
                            label="Temperatura zewnętrzna &deg;C"
                            style={{
                                axisLabel: { padding: 35, fill: '#ffffff', fontSize: 14 },
                                axis: { stroke: "#ffffff" },
                                ticks: { stroke: "grey", size: 3 },
                                data: { fill: '#ffffff', stroke: "#ffffff" }
                            }}
                        />
                        <VictoryAxis dependentAxis
                            width={300}
                            domain={[20, 60]}
                            offsetX={50}
                            standalone={false}
                            tickValues={[20, 25, 30, 35, 40, 45, 50, 55, 60]}
                            tickLabelComponent={<VictoryLabel dx={0} style={{ fontSize: 12, fill: '#ffffff' }} />}
                            label="Temperatura obiegu &deg;C"
                            style={{
                                axisLabel: { padding: 35, fill: '#ffffff', fontSize: 14 },
                                axis: { stroke: "#ffffff" },
                                ticks: { stroke: "grey", size: 3 },
                                data: { fill: '#ffffff', stroke: "#ffffff" }
                            }}
                        />

                        {drawChartLine(state.OB1_KRZYWA, 0)}

                    </VictoryChart>
                </Grid>
            </Grid>
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={4}></Grid>
                        <Grid item xs={4} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default HeatingCurve2