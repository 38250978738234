import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const DeviceInspection = ({ required, setValues, isDamaged }) => {

    useEffect(() => {
        setValues({type: "childName", value: "DeviceInspection"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Oględziny urządzenia</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Czy Urządzenie jest wolne od uszkodzeń mechanicznych?<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Należy sprawdzić wzrokowo stan obudowy Urządzenia po dostarczeniu na miejsce instalacji,
                        jeżeli występują uszkodzenia opisać poniżej.</span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Tak"
                                control={
                                    <Field
                                        name="inspection"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "isDamaged", value: false})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie"
                                control={
                                    <Field
                                        name="inspection"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "isDamaged", value: true})}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="inspection" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Uwagi:<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Miejsce, rodzaj itp.</span>
                    </Typography>
                    <Field
                        name="inspectionComments"
                        component={TextField}
                        label=""
                        multiline
                        fullWidth
                        validate={value => (!isDamaged || value) ? undefined : "Pole obowiązkowe"}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DeviceInspection;