import React from 'react'
import { Typography, Tab, Tabs, Box } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}

export const StyledTabs = withStyles({
    root: {
      backgroundColor: '#fff',
      boxShadow: '0px'
    },
    indicator: {
      display: 'flex',
      '& > span': {
        width: '100%',
        backgroundColor: '#005492',
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
export const StyledTab = withStyles((theme) => ({
    root: {
      // minWidth: '112px',
      // maxWidth: '112px',
      color: '#000',
      '&:focus': {
        color: '#005492',
      },
      '&.Mui-selected': {
        color: '#005492',
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  