import fetchRefreshToken from './fetchRefreshToken'

const fetchDeviceUpdate = async (payload) => {
    // console.log(JSON.stringify(payload))
    const response = await fetchRefreshToken().then(async newToken => {
      
      console.log(JSON.stringify(payload))
        const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/devices`, {
          method: "PUT",
          headers: {
            "Authorization": `Bearer ${newToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }).then(response => response.json())
        
        return res
      })
    return response
  }
  
  export default fetchDeviceUpdate