const fetchPartnerAddEditToken = async (payload, action, id, newToken) => {
   console.log(payload)
    let response
    if(action === "POST"){
    response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/partners`, {
      method: action,
      headers:{
        "Authorization": `Bearer ${newToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response)
  } else if(action === "PUT"){
      response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/partners/${id}`, {
      method: action,
      headers:{
        "Authorization": `Bearer ${newToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
  }

    return response
 }

  export default fetchPartnerAddEditToken