export const registerDictionary = (data) => {

    const deviceName = sessionStorage.getItem('deviceName')
    
    let appTexts = {
        heatingControlCWU: {
            title: "3. Sterowanie podgrzewaniem CWU",
            data: {
                TSM15: "3.1 Temperatura w zasobniku CWU",
                SPDHW_ZAD: "3.2 Temperatura zadana bazowa",
                SPDHW_HIST: "3.3 Histereza regulacji",
                SCDHW: "3.4 Korekta wartości zadanej",
                SX5: "3.5 Temperatura zadana po korekcie",
            },
        },
        internalTempControl: {
            title: "1. Sterowanie temperaturą wewnętrzną",
            data: {
                TSM10: "1.1 Temperatura wewnętrzna",
                SPHT_ZAD: "1.2 Temperatura zadana bazowa wew.",
                SPHT_HIST: "1.3 Histereza regulacji",
                SCHT: "1.4 Korekcja czasowa temp. zad. wew.",
                SX6: "1.5 Temp. zad. wew. po korektach",
                COC: "1.6 Wsp. sterujący mechanizm REG+",
            }
        },
        heatingCoolingControlCrot: {
            title: "2. Sterowanie grzanie/chłodzenie (CROT)",
            data: {
                TSM9: "2.1 Temperatura zewnętrzna",
                TOUT_KOR: "2.2 Temp. zew. skorygowana - CROT",
                TOUT_NUM: "2.3 Siła mechanizmu CROT",
                SX4_PH: "2.4 Próg zał. ogrzewania wzgl. TOUT_KOR",
                SX4_PC: "2.5 Próg zał. chłodzenia wzgl. TOUT_KOR",
            }
        },
        coldReceptionControl: {
            title: "7. Kontrola temp. min. odbioru chłodu",
            data: {
                TSM14: "7.1 Temp. niższa na wym. odbioru",
                SPRMIN_ZAD: "7.2 Temp. minimalna odbioru chłodu",
                SPRMIN_HIST: "7.3 Hist. temp. min. odbioru chłodu",
            }
        },
        othersTemp: {
            title: "11. Pozostałe temperatury",
            data: {
                TSM16: "11.1 Temperatura tłoczenia",
                T3fiz: "11.2 Temperatura powietrza wejściowego",
                T4fiz: "11.3 Temperatura powietrza wyjściowego",
                SPRDIS_ZAD: "11.4 Temperatura tłoczenia maksymalna",
                SPRDIS_HIST: "11.5 Histereza maks. temperatury tłoczenia",
            }
        },
        heatReceptionControlMax: {
            title: "6. Kontrola temp. max. odbioru ciepła",
            data: {
                TSM13: "6.1 Temp. wyższa na wym. odbioru",
                SPRMAX_ZAD: "6.2 Temp. maksymalna odbioru ciepła",
                SPRMAX_HIST: "6.3 Hist. temp. max. odbioru ciepła",
            }
        },
        refrigerationSystem: {
            title: "12. Układ chłodniczy",
            data: {
                ZM_PRES_EVAP: "12.1 Ciśnienie ssania",
                ZM_PRES_COND: "12.2 Ciśnienie tłoczenia",
            }
        },
        coolignSource: {
            title: "12. Kontrola temp. maks. żródła chłodu",
            data: {
                TSM12: "12.1 Temp. wyższa na wym. żródła",
                SPSMAX_ZAD: "12.2 Temp. maksymalna żródła chłodu",
                SPSMAX_HIST: "12.3 Hist temp. maks. żródła chłodu",
            }
        },
        heatingCurveControl: {
            title: "4. Kontrola krzywej grzewczej",
            data: {
                TSM17: "4.1 Temperatura bufora / sygnał z PV",
                TSM13: "4.2 Temp. wyższa na wym. odbioru",
                CUN_HCU: "4.3 Numer krzywej grzewczej",
                CUN_D_HCU: "4.4 Podbicie krzywej grzewczej",
                SPCU: "4.5 Setpoint z krzywej grzewczej",
                SX1: "4.6 Wył. wart. korekty temp. odbioru",
                TB1: "4.7 Setp. finalny dla krzywej grzewczej",
                SX3_HG: "4.8 Hist. setp. dla krzywej grzewczej",
            }
        },
        coolingCurveControl: {
            title: "5. Kontrola krzywej chłodzenia",
            data: {
                TSM17: "5.1 Temperatura bufora / sygnał z PV",
                TSM14: "5.2 Temp. niższa na wym. odbioru",
                SX2_P1: "5.3 Punkt P1 krzywej chłodzenia",
                SX2_P2: "5.4 Punkt P2 krzywej chłodzenia",
                SCCU: "5.5 Setpoint z krzywej chłodzenia",
                SX1: "5.6 Wył. wart. korekty temp. odbioru",
                TB2: "5.7 Setp. finalny dla krzywej chłodzenia",
                SX3_HC: "5.8 Hist. setp. dla krzywej chłodzenia",
            }
        },
        permits: {
            title: "13. Zezwolenia",
            data: {
                SBF_OOF: "13.1 Załączenie całego urządzenia",
                SBF_DHX: "13.2 Możliwość załączenia podgrzewania cwu",
                SBF_DHS: "13.3 Załączenie podgrzewania cwu",
                SBF_HTX: "13.4 Możliwość załączenia ogrzewania budynku",
                SBF_HTS: "13.5 Załączenie ogrzewania budynku",
                SBF_ACX: "13.6 Możliwość załączenia active-coolingu (AC)",
                SBF_ACS: "13.7 Załączenie active-coolingu (AC)",
                SXF_TOE: "13.8 Zezw. na kontrolę temp. wewnętrznej",
                SBF_FCX: "13.9 Możliwość załączenia free-cooling (FC)",
                SBF_FCS: "13.10 Załączenie free-coolingu (FC)",
            }
        },
        modes: {
            title: "14. Tryby",
            data: {
                SXF_ECO: "14.1 ECO",
                SXF_TIME: "14.2 TIME",
                SXF_AWA: "14.3 AWAY",
            }
        },
        antylegionella: {
            ANL_ALL: "Aktywny tryb antylegionella",
            ANL_1PH: "Tryb antylegionella w fazie 1",
            ANL_2PH: "Tryb antylegionella w fazie 2",
            ANL_3PH: "Tryb antylegionella w fazie 3",
            SBF_PHT: "Manualne załączenie funkcji antylegionella",
            SXF_ANL: "Zezwolenie na załączenie trybu antylegionella",
            ANL_DAY: "Liczba dób dla cyklicznego uruchomienia trybu antylegionella",
            ANL_CLOCK: "Liczba dób pozostał do uruchomenia trybu antylegionella",
            TSM15: "Temperatura w zasobniku CWU",
            ANL_SETP_ZAD: "Wartość zadana do kontroli temp.cwu w trybie  wygrzewania antylegionella",
            ANL_SETP_HIST: "Histereza kontroli wartości tempeatury cwu w trybie wygrzewania antylegionella",
            SIO_R01: "Załączenie grzałki CWU",
            SXF_ENACYR: "Zezwolenie na załączenie pompy cyrkulacyjnej cwu",
            SIO_R11: "Załączenie pompy cyrkulacyjnej CWU",
        },
        heatingCurveParameters: { 
            parameters: {
                title: "Parametry krzywej grzewczej",
                data: {
                    TSM9: "Temperatura zewnętrzna (TSM9)",
                    CUN_HCU: "Numer krzywej grzewczej (CUN_HCU)",
                    CUN_D_HCU: "Podbicie krzywej grzewczej (CUN_D_HCU)",
                    SPCU: "Setpoint z krzywej grzewczej (SPCU)",
                    SX1: "Wyliczona wart. korekty temp. odbioru (SX1)",
                    TB1: "Setp. finalny dla krzywej grzewczej (TB1)",
                    SX3_HG: "Hist. setp. dla krzywej grzewczej (SX3_HG)",
                },
            },
            temp: {
                title: "Osiągnięta temperatura odbioru",
                data: {
                    TSM13: "Temp. wyższa na wymienniku odbioru (TSM13)",
                }
            },
            reg: {
                title: "Wpływ mechanizmu REG+",
                data: {
                    TSM10: "Temperatura wewnętrzna (TSM10)",
                    SX6: "Temp. zadana wewnętrzna po korektach (SX6)",
                    COC: "Wspł. sterujący mechanizmu REG+ (COC)",
                }
            }
        },
        coolingCurveParameters: { 
            parameters: {
                title: "Parametry krzywej chłodzenia",
                data: {
                    TSM9: "Temperatura zewnętrzna",
                    SX2_P1: "Punkt P1 krzywej chłodzenia",
                    SX2_P2: "Punkt P2 krzywej chłodzenia",
                    SCCU: "Setpoint z krzywej chłodzenia",
                    SX1: "Wyliczona wart. korekty temp. odbioru",
                    TB2: "Setp. finalny dla krzywej chłodzenia",
                    SX3_HC: "Hist. setp. dla krzywej grzewczej",
                },
            },
            temp: {
                title: "Osiągnięta temperatura odbioru",
                data: {
                    TSM14: "Temp. niższa na wymienniku odbioru"
                }
            },
            reg: {
                title: "Wpływ mechanizmu REG+",
                data: {
                    TSM10: "Temperatura wewnętrzna",
                    SX6: "Temp. zadana wewnętrzna po korektach",
                    COC: "Wspł. sterujący mechanizmu REG+ (COC)"
                }
            }
        },
        away: {
            controllCryteria: {
                title: "Kryteria sterowania:",
                data: {
                    TSM9: "Temperatura zewnętrzna",
                    SPHT_ZAD: "Temperatura zadana bazowa wewnętrzna",
                    AWAY_COR: "Korekcja temp. zad. wew. (D-AWAY)",
                    SCHT: "Korekcja czasowa temp. zad. wew.",
                    SX6: "Tepmperatura zad. wew. po korektach",
                    SXB_PV: "Wymuszenie z int. PV",
                    SBB_QHOUT: "Temp. zew. niższa od progu ogrzewania",
                    SBB_QCOUT: "Temp. zew. wyższa od progu chłodzenia",
                },
            },
            dawayControll: {
                title: "Sterowanie D-AWAY:",
                data: { 
                    SXF_AWA: "Załączenie trybu AWAY",
                    AWAY_CLOCK: "Liczba dni urlopu",
                },
            },
        },
        eco: {
            modeTime: {
                ECOT1R: "Godzina początku obowiązywania II taryfy rano (podana w godzinach od początku doby)",
                ECOT1S: "Godzina końca obowiązywania II taryfy rano (podana w godzinach od początku doby)",
                ECOT2R_Z: "Godzina początku obowiązywania II taryfy po południu - okres zimowy (podana w godzinach od początku doby)",
                ECOT2S_Z: "Godzina końca obowiązywania II taryfy po południu - okres zimowy (podana w godzinach od początku doby)",
                ECOT2R_L: "Godzina początku obowiązywania II taryfy po południu - okres letni (podana w godzinach od początku doby)",
                ECOT2S_L: "Godzina końca obowiązywania II taryfy po południu - okres letni (podana w godzinach od początku doby)",
                ECOT3R: "Godzina początku obowiązywania II taryfy wieczorem (podana w godzinach od początku doby)",
                ECOT3S: "Godzina końca obowiązywania II taryfy wieczorem (podana w godzinach od początku doby)",
            },
            tempcwu: {
                title: "Korekcja temperatury zadanej CWU",
                data: {
                    ECOX1: "Zwiększenie w godzinach porannych i wieczornych taniej energii",
                    ECOX2: "Zwiększenie w godzinach popołudniowych taniej energii",
                    ECODHW: "Zmniejszenie w czasie drogiej energii",
                },
            },
           temp: {
                title: "Korekcja temperatury zadanej wewnętrznej",
                data: {
                    ECOY1: "Zwiększenie w godzinach porannych i wieczornych taniej energii",
                    ECOY2: "Zwiększenie w godzinach popołudniowych taniej energii",
                    ECOTIN: "Zmniejszenie w czasie drogiej energii",
                },
            },
            modyficators: {
                title: "Modyfikatory",
                data: {
                    SXF_ECO: "Załączenie trybu ECO",
                    SXF_WEK: "Taryfa weekendowa",
                    SXF_AZP: "Sezonowa zmiana godzin popołudniowych",
                    SCDHW: "Korekcja temp. zadanej cwu",
                    SCHT: "Korekcja tamp. zadanej wewnętrznej",
                },
            },  
            SXB_ECACT: "Obowiązująca tania taryfa energii elektrycznej",
        },
        heatingCurve: {
            CUN_HCU: "Numer krzywej grzewczej",
            CUN_D_HCU: "Podbicie krzywej grzewczej",
            SX3_HG: "Hist setp. dla krzywej grzewczej",
            COC: "Wsp. sterujący mechanizmu REG+",
        },
        coolingCurve: {
            SX2_P1: "Punkt P1 krzywej chłodzenia",
            SX2_P2: "Punkt P2 krzywej chłodzenia",
            SX3_HC: "Hist setp. dla krzywej chłodzenia",
            COC: "Wsp. sterujący mechanizmu REG+",
        },
        blockade: {
            SXF_ACT: "Aktywność blokady czasowej",
            BLK_LW: "Liczba dni do wyłączenia - bilans",
            BLK_LK: "Liczba poprawnie wprowadzonych kodów",
            BLK_ENA: "Zezwolenie na blokadę czasową",
            BLK_KD1: "Pierwsza część kodu",
            BLK_KD2: "Druga część kodu",
        },
        analysis: {
            cwu: {
                SBF_OOF: "Załączenie całego urządzenia",
                SBF_DHX: "Możliwość załączenia podgrzewania cwu",
                SBF_DHS: "Załączenie podgrzewania cwu",
                SBB_QDHW: "Woda w zasobniku cwu za zimna",
                REW_ERR: "Zbiorcza flaga stanu awaryjnego",
                SBB_QDIS: "Temperatura tłoczenia przekroczona",
                SBB_QRMAX: "Temp. odbioru ciepła za wysoka",
                SBB_QCS: "Wymagany postój sprężarki",
                REW_RMD: "Przekr. temp. krytycznej w wym. odbioru",
            },
            heating: {
                SBF_OOF: "Załączenie całego urządzenia",
                SBF_HTX: "Możliwość załączenia ogrzewania budynku",
                SBF_HTS: "Załączenie ogrzewania budynku",
                SXF_TOE: "Zezw. na kontrolę temp. wewnętrznej",
                SBB_QHW: "Warunki temp. podgrz. cwu spełnione i podgrz. cwu zał.",
                SWEXT_TWP: "Temp. wewnętrzna niższa od zadanej",
                SBB_QHOUT: "Temp. zewn. niższa od progu ogrzewania",
                SBB_QSMD: "Temp. na wyjściu wym. odbioru niższa niż SP k. grzewczej",
                REW_ERR: "Zbiorcza flaga stanu awaryjnego",
                SBB_QDIS: "Temperatura tłoczenia przekroczona",
                SBB_QRMAX: "Temp. odbioru ciepła za wysoka",
                SBB_QCS: "Wymagany postój sprężarki",
                REW_RMD: "Przekr. temp. krytycznej w wym. odbioru",
            },
            coolingFC: {
                SBF_OOF: "Załączenie całego urządzenia",
                SBF_FCX: "Możliwość załączenia free cooling",
                SBF_FCS: "Załączenie free cooling",
                SXF_TOE: "Zezw. na kontrolę temp. wewnętrznej",
                SBB_QHW: "Warunki temp. podgrzewania cwu spełnione i podgrz. cwu załączone",
                SBB_QTIN: "Kontrola temperatury wewnętrznej",
                SBB_QCOUT: "Kontrola progu chłodzenia",
                SXB_QFC: "Warunek temp. załaczenia FC spełniony",
                REW_SMD: "Przekroczenie temperatury krytycznej w wymienniku źródła",
                REW_RMD: "Przekr. temp. krytycznej w wym. odbioru",
            },
            coolingAC: {
                SBF_OOF: "Załączenie całego urządzenia",
                SBF_ACX: "Możliwość załączenia active cooling",
                SBF_ACS: "Załączenie active cooling",
                SXF_TOE: "Zezw. na kontrolę temp. wewnętrznej",
                SBB_QHW: "Warunki temp. podgrzewania cwu spełnione i podgrz. cwu załączone",
                SBB_QTIN: "Kontrola temperatury wewnętrznej",
                SBB_QCOUT: "Kontrola progu chłodzenia",
                SBB_RMD: "Kontrola krzywej chłodzenia",
                REW_ERR: "Zbiorcza flaga stanu awaryjnego",
                SBB_QDIS: "Temperatura tłoczenia przekroczona",
                SBB_QRMIN: "Temperatura odbioru chłodu za niska",
                SBB_QCS: "Wymagany postój sprężarki",
                REW_RMD: "Przekr. temp. krytycznej w wym. odbioru",
                SWEXT_TWP: "Kontrola temperatury wewnętrznej",
            },
        },
        periphery: {
            circulation: {
                SBF_OOF: "Załączenie całego urządzenia",
                SBF_DHX: "Możliwość załączenia podgrzewania cwu",
                SBF_DHS: "Załączenie podgrzewania cwu",
                ANL_3PH: "Tryb antylegionella w fazie 3",
                SXF_ENACYR: "Zezwolenie na załączenie pompy cyrkulacyjnej",
                SXF_FORCYR: "Wymuszenie pracy pompy cyrkulacyjnej",
                SXF_TIMCYR: "Zezwolenie na sterowanie czasowe pompą cyrkulacyjną",
                CYR_FILL: "Wypełnienie czasowe pracy pompy cyrkulacyjnej",
                cyrBreak: "Przerwa w pracy pompy cyrkulacyjnej",
                SXF_FLPCYR: "Wymuszenie czasowe pracy pompy cyrkulacyjnej",
                TIM_CYR: "Timer modułu cyrkulacji",
                CYRSW_S1: "Załączenie sterowania czasowego CWU dla strefy 1",
                CYRSW_S2: "Załączenie sterowania czasowego CWU dla strefy 2",
                CYRSW_S3: "Załączenie sterowania czasowego CWU dla strefy 3",
            },
            peakSource: {
                settings: {
                    title: "Ustawienia i stan funkcji sterowania źródłem szczytowym",
                    data: {
                        SBF_OOF: "Załączenie całego urządzenia",
                        SBF_HTX: "Możliwość załączenia ogrzewania budynku",
                        SBF_HTS: "Załączenie ogrzewania budynku",
                        SXF_TOE: "Zezw. na kontrolę temp. wewnętrznej",
                    },
                },
                step_1: {
                    title: "Stopień I",
                    data: {
                        SBF_AHX: "Możliwość załączenia stopnia 1 źródła szczytowego",
                        SBF_AHS: "Dopuszczenie załączenia stopnia 1 źródła szczytowego tylko w tanim prądzie",
                        TB5: "Czas załączenia 1 stopnia źródła szczytowego dla c.o.",
                        TSM9: "Temperatura zewnętrzna",
                        TB6: "Temperatura graniczna pracy stopnia 1 źródła szczytowego dla c.o.",
                        TB5W: "Czas załączenia źródła szczytowego dla cwu",
                        SWEXT_ZBC: "Dopuszczenie pracy źródła biwalentnego w funkcji cwu",
                        TB4: "Wskaźnik dynamiki dla ogrzewania",
                        SXB_ECACT: "Obowiązująca tania taryfa energii elektrycznej",
                        SWEXT_ZBST1: "Załączenie logiczne stopnia 1",
                        HPOM: "Limit licznika dynamiki dla ogrzewania",
                    },
                },
                step_2: {
                    title: "Stopień II",
                    data: {
                        SWEXT_AHX2: "Możliwość załączenia stopnia 2 źródła szczytowego",
                        SWEXT_AHS2: "Dopuszczenie załączenia stopnia 2 źródła szczytowego w tanim prądzie",
                        A1: "Czas załączenia 2 stopnia źródła szczytowego dla c.o.",
                        TSM9: "Temperatura zewnętrzna",
                        A2: "Temperatura graniczna pracy stopnia 2 źródła szczytowego dla c.o.",
                        SIO_R06: "Sygnał startu dla jednostki zewnętrznej",
                        SWEXT_ZBST2: "Załączenie logiczne stopnia 2",
                    },  
                },
                step_geo: {
                    data: {
                        SBF_AHX: "Możliwość załączenia żródła szczytowego",
                        SBF_AHS: "Dopuszczenie zał. źr. szczytowego tylko w tanim prądzie",
                        TB5: "Czas załączenia źródła szczytowego dla c.o.",
                        TSM9: "Temperatura zewnętrzna",
                        TB6: "Temp. graniczna pracy źródła szczytowego dla c.o.",
                        TB5W: "Czas załączenia źródła szczytowego dla c.w.u.",
                        SWEXT_ZBC: "Dopuszczenie pracy źr. biw. w funkcji c.w.u.",
                    },
                    status: {
                        title: "Status",
                        data: {
                            TB4: "Wskaźnik dynamiki dla ogrzewania",
                            SIO_R06: "Zał. przek. sprężarki",
                            SIO_R11: "Zał. przek. źródła biwalentnego",
                            SXB_ECACT: "Obowiązująca taryfa energii elektrycznej",
                        },
                    },
                },
                heater: {
                    SIO_R11: "Grzałka wewnętrzna",
                    SIO_R01: "Grzałka zewnętrzna",
                },
            },
            coopInstallation: {
                SWEXT_BUF: "Wybór sposobu współpracy z instalacją c.o.",
            },
            circuits: {
                SBF_OOF: "Załączenie całego urządzenia",
                SBF_HTX: "Możliwość załączenia ogrzewania budynku",
                SBF_HTS: "Załączenie ogrzewania budynku",
                SXF_TOE: "Zezw. na kontrolę temp. wewnętrznej",
                SBF_ACX: "Możliwość załączenia active cooling",
                SBF_ACS: "Załączenie active cooling",
                SBB_QHOUT: "Temp. zewn. niższa od progu ogrzewania",
                SBB_QCOUT: "Temp. zewn. wyższa od progu chłodzenia",
                SBB_QTIN: "Temp. wewn. niższa od zadanej",
                SIO_R02: "Zał. obw. grzania",
                SIO_R05: "Zał. obw. chłodzenia",
                RGRCH: "Rejestr konfiguracji obiegów odbiorczych",
                SWEXT_DCP: "Przeł. trybu komfortowego/ekonomicznego dla PGRZ i PCHL",
            },
            heatingCircuits: {
                main: {
                    TSM10: "Temp. wewnętrzna",
                    SPHT_ZAD: "Temp. zadana bazowa",
                    SX6: "Temp. zad. po korektach",
                    CUN_HCU: "Krzywa grzewcza",
                    SX3_HG: "Histereza regulacji krzywej grz.",
                    SWEXT_BUF: "Odczyt temp. obiegu (temp. bufora)",
                    TB1: "Temp. obiegu wyliczona z krzywej grz.",
                    SIO_R02: "Stan pracy obiegu",
                },
                additional: {
                    OB1_TWEW: "Temp. wewnętrzna",
                    OB1_TKMF_BAZ: "Temp. zadana bazowa",
                    OB1_TKMF: "Temp. zad. po korektach",
                    OB1_KRZYWA: "Krzywa grzewcza",
                    SX3_HG: "Histereza regulacji krzywej grz.",
                    OB1_TCO: "Odczyt temp. obiegu (temp. bufora)",
                    OB1_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.",
                    OB1_PCO: "Stan pracy obiegu",
                    OB2_TWEW: "Temp. wewnętrzna", 
                    OB2_TKMF_BAZ: "Temp. zadana bazowa", 
                    OB2_TKMF: "Temp. zad. po korektach", 
                    OB2_KRZYWA: "Krzywa grzewcza", 
                    OB2_TCO: "Odczyt temp. obiegu (temp. bufora)", 
                    OB2_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.", 
                    OB2_PCO: "Stan pracy obiegu", 
                    OB3_TWEW: "Temp. wewnętrzna", 
                    OB3_TKMF_BAZ: "Temp. zadana bazowa", 
                    OB3_TKMF: "Temp. zad. po korektach", 
                    OB3_KRZYWA: "Krzywa grzewcza", 
                    OB3_TCO: "Odczyt temp. obiegu (temp. bufora)", 
                    OB3_ZAD_TCO: "Temp. obiegu wyliczona z krzywej grz.", 
                    OB3_PCO: "Stan pracy obiegu", 
                },
            },
            dr4Controll: {
                SIO_R10: "Zał. przek. grzałki cwu",
                SXF_4EN: "Załączenie kontroli poprawności pracy zaworu 4-DR",
                SXB_4DR: "Niepoprawna praca zaw. 4dr lub wyjście poza kopertę pr.",
                TB3: "Czas opóźnienia analizy pracy zaworu 4-DR"
            }
        },
        outdoor: {
            info: {
                title: "Odczyt informacji",
                data: {
                    MB_COMM2_XZM_TRANSM: "Błąd transmisji",
                },
            },
            parameters: {
                title: "Odczyt prametrów",
                data: { 
                    ZM_AC_IN_VOL: "Napięcie wejściowe",
                    ZM_AC_IN_CUR: "Prąd wejściowy",
                    ZM_CUR_MAX_FREQ: "Aktualna częstotliwość maksymalna",
                    ZM_CUR_COMP_FREQ: "Aktualna częstotliwość sprężarki",
                    ZM_FAN_MAX_SPE: "Maksymalna prędkość wentylatora",
                    ZM_FAN1_SPE: "Prędkość wentylatora numer 1",
                    ZM_FAN2_SPE: "Prędkość wentylatora numer 2",
                    ZM_EEV_OPEN: "Otwarcie zaworu rozprężnego",
                },
               
            },
            temp: {
                title: "Temperatury",
                data: {
                    ZM_OUT_TEMP: "Temperatura otoczenia",
                    ZM_COIL_TEMP: "Temperatura wymiennika",
                    ZM_DIS_TEMP: "Temperatura tłoczenia",
                    ZM_SUCT_TEMP: "Temperatura ssania",
                },
            },
            control_registers: {
                title: "Rejestry kontrolne",
                data: {
                    ZMB_SET_COMP_SPEED: "Nastawa prędkości sprężarki",
                    ZMB_SET_EEV_OPEN: "Nastawa otwarcia zaworu",
                    ZMB_SET_INW_TEMP: "Nastawa temperatury TUI",
                    ZM_CUR_OP_MOD: "Aktualny tryb pracy",
                },
            },
            errors_registers: {
                title: "Rejestry błedów",
                data: {
                    ZM_ERR1: "Rejestr błędu jednostki zewnętrznej - numer 1",
                    ZM_ERR2: "Rejestr błędu jednostki zewnętrznej - numer 2",
                    ZM_ERR3: "Rejestr błędu jednostki zewnętrznej - numer 3",
                },
            },
            errors_outdoor: {
                title: "Błędy jednostki zewnętrznej",
                data: {
                    ERR1_MCUR: "Błąd limitu prądu w linii zasilającej",
                    ERR1_PCUR: "Błąd limitu prądu w linii zasilania sprężarki",
                    ERR1_IPM: "Błąd limitu prądu, mocy, temperatury lub startowej różnicy ciśnień",
                    ERR1_OIL: "Błąd - zakłócony powrót oleju do sprężarki",
                    ERR1_PDF: "Błąd zbyt wysokiego ciśnienia - ograniczenie częstotliwości",
                    ERR1_OGTS: "Zbyt wysoka temperatura tłoczenia ",
                    ERR1_COIL_PROT: "Błąd przekroczenia zakresu temperatury zewnętrznej ",
                    ERR1_VOLT: "Przekroczony zakres napięcia zasilającego",
                    ERR1_FREQ_LT: "Przekroczony zakres częstotliwości względem temperatury otoczenia",
                    ERR2_EPTRA_F: "Błąd przetwornika ciśnienia",
                    ERR2_CPTRA_F: "Błąd przetwornika ciśnienia",
                    ERR2_HP_F: "Błąd - zbyt wysokie ciśnienie",
                    ERR2_LP_F: "Błąd - zbyt niskie ciśnienie",
                },
            },
            efficiency_regulation: {
                title: "Regulacja wydajności",
                data: {
                    SWEXT_FIXREG: "Wyłączanie/załączanie regulacji wydajności",
                    TNKOR: "Ograniczenie minimalnej wydajności",
                    MAX_PERF: "Ograniczenie maksymalnej wydajności",
                    DETE: "dT na wymienniku odbioru",
                    FSET_CO: "Nastawa wydajności dla ogrzewania",
                    FSET_CWU: "Nastawa wydajności dla CWU",
                    FSET_AC: "Nastawa wydajności dla chłodzenia",
                    IHW: "Intelligent Hot Water - wskaźnik minimalnej wydajności",
                    ZM_DEFR_SIGN: "Sygnał rozszraniania"
                },
            }, 
        },
        identyfication: {
            identyfication_controller: {
                title: "Identyfikacja sterownika",
                data: {
                    NUMER_STER: "Numer sterownika w sieci MODBUS",
                    VER_S1: "Wersja oprogramowania sterownika",
                    IDM_MAN: "Identyfikator producenta",
                    IDM_GRO: "Identyfikator produktu",
                    PSN: "Numer seryjny produktu",
                    PDP_WEE: "Tydzień daty produkcji",
                    PDP_YEA: "Rok daty produkcji",
                }
            },
            controller_configuration: {
                title: "Konfiguracja układu sterownika",
                data: {
                    VR_FLO: "Możliwość podłączenia zewn. czujnika przepływu",
                    VR_FLI: "Wbudowany czujnik przepływu w ob. odbioru",
                    VR_ZCH: "Możliwość podłączenia pompy PGRZ",
                    VR_ZKO: "Możliwość podłączenia pompy PCHL",
                    VR_ANO: "Możliwość podłączenia grzałki cwu",
                    VR_ZCW: "Wbudowany zawór cwu",
                    VR_FCO: "Możliwość podłączenia zewnętrznego FC",
                    VR_FCI: "FC wbudowane w urządzenie",
                    VR_PO: "Wbudowana pompa obiegowa odbioru",
                    VR_PZ: "Wbudowana pompa obiegowa źródła",
                    VR_REV: "Możliwość podłączenia źródła szczytowego",
                }
            },
            characteristics: {
                title: "Cechy",
                data: {
                    IDP_PWR: "Moc nominalna urządzenia [kW]",
                    IDP_SUB: "Linia produktu",
                    IDP_MOD: "Funkcja",
                    IDP_EXP: "Typ elementu rozprężnego",
                    PANVER: "Wersja panelu operatorskiego",
                }
            },
        },
        emergencyStop: {
            title: "Status",
            data: {
                OOF_HT: "Liczba zdarzeń aw. i ostrz. podczas ogrzewania",
                OOF_DHW: "Liczba zdarzeń aw. i ostrz. podczas przygotowania cwu",
                OOF_CL: "Liczba zdarzeń aw. i ostrz. podczas chłodzenia",
                OOF_PWR: "Liczba załączeń zasilania w bierzącej dobie",
                SXB_FER: "Blokada awaryjna urządzenia lub jednej funkcji",
                SXB_HTO: "Blokada awaryjna funkcji-ogrzewanie",
                SXB_DHWO: "Blokada awaryjna funkcji-podgrzewanie cwu",
                SXB_CLO: "Blokada awaryjna funkcji-chłodzenie aktywne",
                SXB_PWRO: "Blokada awaryjna urządzenia-problemy z zasilaniem",
                SXB_4DR: "Niepoprawna praca zaw. 4dr lub wyjście poza kopertę pr.",
            },
            title2: "Ustawienia",
            data2: {
                OOF_NUM: "max. liczba zdarzeń aw. i ostrz. w ciągu doby",
            },
        },
        dateTimeSettings: {
            SBB_MAIN: "Rozkaz uruchimienia sprężarki - aktywacja modułu wyk.",
            SBB_QCS: "Wymagany postój sprężarki",
            TCR: "Czas postoju sprężarki - pozostało",
            TCH: "Czas postoju sprężarki - wymuszony",
            TRR: "Czas przedbiegu pompy obiegowej odbioru",
            TSR: "Czas wybiegu pompy obiegowej odbioru",
            TRS: "Czas przedbiegu pompy obiegowej źródła",
            TSS: "Czas wybiegu pompy obiegowej źródła",
        },
        errorWarnings: {
            title: "10. Błędy i ostrzeżenia",
            data: {   
                REW_ERR: "10.1 Zbiorcza flaga stanu awaryjnego",
                REW_HP: "10.3 Błąd z presostatu wysokiego ciśnienia",
                REW_FLO: "10.4 Sygnał z czujnika przepływu",
                REW_DIS: "10.5 Przekroczenie temperatury tłoczenia",
                REW_RMD: "10.6 Przekr. temp. krytycznej w wymienniku odbioru",
                REW_WRN: "10.7 Zbiorcza flaga stanu ostrzegawczego",
                REW_RMI: "10.9 Przekr. temp. min. odb. chłodu (chłodzenie)",
                REW_RMA: "10.10 Przekr. temp. max. odb. ciepła (ogrzewanie)",
            }
         
        },
        devicesPeriphery: {
            title: "9. Urządzenia/Peryferia",
            data: {
                SIO_R08: "9.2 Zał. przek. pompy obiegowej odbioru",
                SIO_R09: "9.3 Zał. przek. zaworu cwu/pompy ob. cwu",
                SIO_R10: "9.4 Zał. przek. grzałki cwu",
                SIO_R02: "9.5 Zał. obw. grzania",
                SIO_R05: "9.6 Zał. obw. chłodzenia",
                SIO_R03: "9.7 Zał. przek. pompy cyrkulacyjnej cwu",
            }
        },
        extender: { 
            settingsPV: { 
                title: "Nastawy progów od PV", 
                data: { 
                    LICZNIK_PV_PWR_NADPROD: "Aktualna nadprodukcja z PV [W]", 
                    PW_G: "Moc grzałki CWU [W]", 
                    PZ_G: "Histereza dla grzałki CWU [W]", 
                    PW_P: "Moc grzałki bufora [W]", 
                    PZ_P: "Histereza dla grzałki bufora [W] ", 
                    WYLI_PROG_ZAL_PC: "Wyliczona moc pompy ciepła [W]", 
                    PZ_P_G: "Histereza dla pompy ciepła [W]", 
                    LICZNIK_PV_PWR_NADPROD_AR: "Nadprodukcja z PV - ręczne wymuszenie"
                } 
            }, 
            tempSettings: { 
                title: "Nastawy temperatur ", 
                data: { 
                    SP_CO_G: "Nastawa temperatury w buforze ciepła przy grzaniu grzałkami", 
                    TSM17: "Temperatura w buforze", 
                    SP_CWU_G: "Nastawa temperatury w zasobniku CWU przy grzaniu grzałkami", 
                    TSM15: "Temperatura CWU", 
                    SP_T_REGEN: "Nastawa temperatury regeneracji dolnego źródła", 
                    T_ODW_IN: "Temperatura regeneracji", 
                    SP_CWU_REGENER: "Nastawa temperatury w zasobniku CUW, przy której załączana jest regeneracja dolnego źródła", 
                    SP_CH_Z2: "Nastawa temperatury w buforze chłodu Z2", 
                    T_Z2_1: "Temperatura w buforze Z2 - dół", 
                } 
            },
            status: {
                title: "Statusy",
                data: {
                    SIO2_R01_G1: "Grzałka zasobnika CWU (R01)",
                    SIO2_R02_G2: "Grzałka bufora (R02)",
                    SIO2_R11_P_REGEN: "Regeneracja gruntu (R11)",
                    SIO2_R07_DZ: "Dolne źródło (R07)",
                    LICZNIK_PV_KOM: "Komunikacja z licznikiem PV",
                    LICZNIK_PC_KOM: "Komunikacja z licznikiem PC",
                    AOR_FB: "Nadpisanie w trybie ręka",
                },
            },
            forcePV: {
                title: "Wymuszenie od PV",
                data: {
                    SIO2_R09_PV_READY: "Wymuszenie zbiorcze",
                    PV_READY_AC: "Wymuszenie AC dla pompy ciepła",
                    PV_READY_THR1: "1. Warunki na załączenie grzałki CWU", 
                    PV_READY_THR2: "2. Warunki na załączenie grzałki bufora", 
                    PV_READY_THR3: "3. Warunki na załączenie popmpy ciepła", 
                },
            },
            tempDisplay: {
                title : "Odczyty temperatur",
                data: {
                    T_CWU_2: "Dodatkowy pomiar temperatury  CWU (T01)",
                    T_BUF_2: "Dodatkowy pomiar temperatury  bufora  (T02)",
                    T_Z2_1: "Temperatura w buforze chłodu - dół (T03)",
                    T_Z2_2: "Temperatura w buforze chłodu - środek (T04)",
                    T_Z2_3: "Temperatura w buforze chłodu - góra (T05)",
                    T_PVT: "Temperatura PVT (T06)",
                    T_OB_CHL: "Temperatura w obiegu chłodu (za mieszaczem, T07)",
                    T_ODW_IN: "Temperatura wejściowa do odwiertu (T08)",
                    T_ODW_OUT: "Temperatura wyjściowa z odwiertu (T09)",
                },
            },
            mainDriver: {
                title: "Odczyt nastaw z głównego sterownika",
                data: {
                    TB1: "Setp. finalny dla krzywej grzewczej",
                    SX3_HG: "Hist. setp. dla krzywej grzewczej",
                    TB2: "Setp. finalny dla krzywej chłodzenia",
                    SX3_HC: "Hist. setp. dla krzywej chłodzenia",
                    SPDHW_ZAD: "Temperatura zadana bazowa CWU",
                    SX5: "Temperatura zadana CWU po korektach",
                    SPDHW_HIST: "Histereza regulacji CWU",
                },
            },
            tempMode: { 
                title: "Temperatury - nadpisywanie w trybie ręka",
                data: { 
                    AOR_OFF_TRIGG: "Wyłącz nadpisania w trybie ręka", 
                    T01_AOR_EN: "T01_AOR_EN", 
                    T01_MAN: "T01_MAN", 
                    T02_AOR_EN: "T02_AOR_EN", 
                    T02_MAN: "T02_MAN", 
                    T03_AOR_EN: "T03_AOR_EN", 
                    T03_MAN: "T03_MAN", 
                    T04_AOR_EN: "T04_AOR_EN", 
                    T04_MAN: "T04_MAN", 
                    T05_AOR_EN: "T05_AOR_EN", 
                    T05_MAN: "T05_MAN", 
                    T06_AOR_EN: "T06_AOR_EN", 
                    T06_MAN: "T06_MAN", 
                    T07_AOR_EN: "T07_AOR_EN", 
                    T07_MAN: "T07_MAN", 
                    T08_AOR_EN: "T08_AOR_EN", 
                    T08_MAN: "T08_MAN", 
                    T09_AOR_EN: "T09_AOR_EN", 
                    T09_MAN: "T09_MAN", 
                } 
            }, 
            digitalModeIn: { 
                title: "Wejścia cyfrowe - nadpisywanie w trybie ręka",
                data: { 
 
                }   
            }, 
            digitalModeOut: { 
                title: "Wyjścia cyfrowe - nadpisywanie w trybie ręka",  
                data: { 
                    R01_AOR_EN: "R01_AOR_EN", 
                    R01_AOR: "R01_AOR", 
                    R02_AOR_EN: "R02_AOR_EN", 
                    R02_AOR: "R02_AOR", 
                    R03_AOR_EN: "R03_AOR_EN", 
                    R03_AOR: "R03_AOR", 
                    R04_AOR_EN: "R04_AOR_EN", 
                    R04_AOR: "R04_AOR", 
                    R05_AOR_EN: "R05_AOR_EN", 
                    R05_AOR: "R05_AOR", 
                    R06_AOR_EN: "R06_AOR_EN", 
                    R06_AOR: "R06_AOR", 
                    R07_AOR_EN: "R07_AOR_EN", 
                    R07_AOR: "R07_AOR", 
                    R08_AOR_EN: "R08_AOR_EN", 
                    R08_AOR: "R08_AOR", 
                    R09_AOR_EN: "R09_AOR_EN", 
                    R09_AOR: "R09_AOR", 
                    R010_AOR_EN: "R010_AOR_EN", 
                    R010_AOR: "R010_AOR", 
                    R011_AOR_EN: "R011_AOR_EN", 
                    R011_AOR: "R011_AOR", 
                }   
            }, 
            PWMOut: { 
                title: "Wyjścia PWM - nadpisywanie w trybie ręka",  
                data: { 
 
                }   
            }, 
        }
    }
    
    switch (deviceName) {
        case 'GEO_1':
            appTexts = {...appTexts,
                errorWarnings: {
                    title: appTexts.errorWarnings.title,
                    data: {   
                        REW_LPH: "10.2 Błąd z pres. niskiego ciś. dla ogrzewania",
                        REW_SMI: "10.8 Przekr. temp. min. źródła ciepła (ogrzewanie)",
                        REW_SMA: "10.11 Przekr. temp. max. źródła chłodu (chłodzenie)",
                        REW_LPC: "10.12 Błąd z pres. niskiego ciś. dla chłodzenia",
                        REW_SUP: "10.13 Błąd softstartera/czujnika kolejności faz",
                        REW_TER: "10.14 Błąd z modułu termicznego sprężarki",
                        REW_SMD: "10.15 Przekr. temp. krytycznej w wymienniku źródła",
                        REW_FCS: "10.16 Awaria układu kontr. wejść binarnych",
                        ...appTexts.errorWarnings.data
                    }
                },
                sensorEjaculationControlMax: {
                    title: "8. Kontrola temp. min. źródła ciepła",
                    data: {
                        TSM12: "8.1 Temp. niższa na wym. żródła",
                        SPSMIN_ZAD: "8.2 Temp. minimalna źródła ciepła",
                        SPSMIN_HIST: "8.3 Hist. temp. min. źródła ciepła",
                        }
                },
                devicesPeriphery: {
                    title: appTexts.devicesPeriphery.title,
                    data: {
                        SIO_R06: "9.1 Sygnał startu dla sprężarki",
                        SIO_R11: "9.8 Zał. przek. źródła biwalentnego",
                        SIO_R01: "9.9 Zał. przek. zaw. 4dr/zaw. odwr. obiegi",
                        SIO_R07: "9.10 Zał. przek. pompy obiegowej źródła",
                        SIO_R04: "9.11 Zał. przek. free-cooling",
                        ...appTexts.devicesPeriphery.data
                    }
                },
                acfcControll: {
                    title: "15. Kontrola progu AC/FC",
                    data: {
                        TSM11: "15.1 Temp niższa na wym. źródła",
                        SCCU: "15.2 Setpoint z krzywej chłodzenia",
                    }
                },
                analysis: {
                    ...appTexts.analysis,
                    cwu: {
                        // SBB_QSMAX: "Temperatura źródła chłodu za wysoka",
                        SBB_QSMIN: "Temperatura źródła ciepła za niska",
                        SXB_4DR: "Niepoprawna praca zaw. 4dr lub wyjście poza kopertę pr.",
                        REW_SMD: "Przekr. temp. krytycznej w wym. źródła",
                        ...appTexts.analysis.cwu
                    },
                    heating: {
                        SBB_QSMIN: "Temperatura źródła ciepła za niska",
                        SXB_4DR: "Niepoprawna praca zaw. 4dr lub wyjście poza kopertę pr.",
                        REW_SMD: "Przekr. temp. krytycznej w wym. źródła",
                        ...appTexts.analysis.heating
                    },
                    coolingAC: {
                        SBB_QSMIN: "Temperatura źródła ciepła za niska",
                        SBB_QSMAX: "Temperatura źródła chłodu za wysoka",
                        SXB_4DR: "Niepoprawna praca zaw. 4dr lub wyjście poza kopertę pr.",
                        REW_SMD: "Przekr. temp. krytycznej w wym. źródła",
                        SXB_ACBL: "Kontrola temperatury minimalnej źródła chłodu przy AC",
                        SBF_FCS: "Załączenie free-coolingu (FC)",
                        ...appTexts.analysis.coolingAC
                    },
                },
                dateTimeSettings: {
                    SXB_F4D: "Wymuszona praca pomp obiegowych - zmiana chłodzenie/grzanie",
                    SXB_F4DP: "Wymuszona praca pomp obiegowych - zmiana grzanie/chłodzenie",
                    TB3: "Czas opóźnienia analizy pracy zaworu 4-DR",
                    ...appTexts.dateTimeSettings
                },
            }
            break
        case 'ATMO_1': 
                appTexts = {...appTexts,
                    errorWarnings: {
                        title: appTexts.errorWarnings.title,
                        data: {
                            REW_LPH: "10.2 Błąd z presostatu niskiego ciśnienia",
                            REW_RMD: "10.6 Przekr. temp. krytycznej w wymienniku odbioru",
                            REW_SMI: "10.8 Przekr. temp. min. wtr. czynnika (chłodzenie)",
                            REW_SMA: "10.11 Przekr. temp. max. powrotu jedn. zewn.",
                            MB_COMM2_XZM_TRANSM: "10.12 Błąd komunikacji z jednostką zewnętrzną",
                            REW_SMD: "10.13 Praca źródła szczyt. bez jedn. zewn.",
                            ...appTexts.errorWarnings.data
                        }
                    },
                    sensorEjaculationControlMax: {
                        title: "8. Kontrola temp. min. wtrysku czynnika",
                        data: {
                            TSM12: "8.1 Temp. wtrysku czynnika chłodniczego",
                            SPSMIN_ZAD: "8.2 Temp. minimalna wtrysku",
                            SPSMIN_HIST: "8.3 Hist. temp. min. wtrysku czynnika",
                        }
                    },  
                    devicesPeriphery: {
                        title: appTexts.devicesPeriphery.title,
                        data: {
                            SIO_R06: "9.1 Sygnał startu dla jedn. zewnętrznej",
                            SIO_R11: data && data.SWEXT_ZBCRO === 0 ? "9.8 Załączenie grzałki ST1" : "9.8 Zał.przek. grzałki wewnętrznej",
                            SIO_R01: data && data.SWEXT_ZBCRO === 0 ? "9.9 Załączenie grzałki ST2" : "9.9 Zał.przek. kotła szczytowego",
                            SWEXT_ZBCRO: "9.10 Krosowanie wyjść - współpraca z kotłem",
                            ...appTexts.devicesPeriphery.data
                        }
                    },
                    outdoorTempControl: {
                        title: "15. Kontrola temp. maks. powr. jedn. zewn.",
                        data: {
                            TSM14: "15.1 Temp niższa na wym. odbioru",
                            MAX_RET_TEMP: "15.2 Temp. maks. powrotu jedn. zewn.",
                            MAX_RET_TEMP_HIST: "15.3 Hist. temp. maks. powr. jedn. zewn.",
                        }
                    },
                    statistics: {
                        compressor: {
                            CORSTA_SPRMAX: "-CWU:",
                            CORSTA_SPRMIN: "-CO:",
                            CORSTA_SPSMAX: "-AC:",
                        },
                    },
                    analysis: {
                        ...appTexts.analysis,
                        cwu: {
                            SBB_QSMAX: "Temperatura powrotu jednostki zewnętrznej za wysoka",
                            ...appTexts.analysis.cwu
                        },
                        heating: {
                            SBB_QSMAX: "Temperatura powrotu jedn. zewn. za wysoka",
                            ...appTexts.analysis.heating
                        },
                        coolingAC: {
                            SBB_QSMIN: "Temperatura wtrysku czynnika za niska",
                            ...appTexts.analysis.coolingAC
                        },
                    },
                    dateTimeSettings: {
                        SXB_F4D: "Zanik zasilania",
                        SXB_F4DP: "Obecność układu zasilania waryjnego",
                        ...appTexts.dateTimeSettings
                    },
                } 
            break
        case 'ALLINONE_1': 
            appTexts = {...appTexts,
                errorWarnings: {
                    title: appTexts.errorWarnings.title,
                    data: {
                        REW_LPH: "10.2 Błąd z presostatu niskiego ciśnienia",
                        REW_RMD: "10.6 Przekr. temp. krytycznej w wymienniku odbioru",
                        REW_SMI: "10.8 Przekr. temp. min. wtr. czynnika (chłodzenie)",
                        REW_SMA: "10.11 Przekr. temp. max. powrotu jedn. zewn.",
                        MB_COMM2_XZM_TRANSM: "10.12 Błąd komunikacji z jednostką zewnętrzną",
                        REW_SMD: "10.13 Praca źródła szczyt. bez jedn. zewn.",
                        ...appTexts.errorWarnings.data
                    }
                },
                sensorEjaculationControlMax: {
                    title: "8. Kontrola temp. min. wtrysku czynnika",
                    data: {
                        TSM12: "8.1 Temp. wtrysku czynnika chłodniczego",
                        SPSMIN_ZAD: "8.2 Temp. minimalna wtrysku",
                        SPSMIN_HIST: "8.3 Hist. temp. min. wtrysku czynnika",
                    }
                },
                devicesPeriphery: {
                    title: appTexts.devicesPeriphery.title,
                    data: {
                        SIO_R06: "9.1 Sygnał startu dla jedn. zewnętrznej",
                        SIO_R11: data && data.SWEXT_ZBCRO === 0 ? "9.8 Załączenie grzałki ST1" : "9.8 Zał.przek. grzałki wewnętrznej",
                        SIO_R01: data && data.SWEXT_ZBCRO === 0 ? "9.9 Załączenie grzałki ST2" : "9.9 Zał.przek. kotła szczytowego",
                        SWEXT_ZBCRO: "9.10 Krosowanie wyjść - współpraca z kotłem",
                        ...appTexts.devicesPeriphery.data
                    }
                },
                outdoorTempControl: {
                    title: "15. Kontrola temp. maks. powr. jedn. zewn.",
                    data: {
                        TSM14: "15.1 Temp niższa na wym. odbioru",
                        MAX_RET_TEMP: "15.2 Temp. maks. powrotu jedn. zewn.",
                        MAX_RET_TEMP_HIS: "15.3 Hist. temp. maks. powr. jedn. zewn.",
                    }
                },
                statistics: {
                    compressor: {
                        CORSTA_SPRMAX: "-CWU",
                        CORSTA_SPRMIN: "-CO",
                        CORSTA_SPSMAX: "-AC",
                    },
                },
                analysis: {
                    ...appTexts.analysis,
                    cwu: {
                        SBB_QSMAX: "Temperatura powrotu jednostki zewnętrznej za wysoka",
                        ...appTexts.analysis.cwu
                    },
                    heating: {
                        SBB_QSMAX: "Temperatura powrotu jedn. zewn. za wysoka",
                        ...appTexts.analysis.heating
                    },
                    coolingAC: {
                        SBB_QSMIN: "Temperatura wtrysku czynnika za niska",
                        ...appTexts.analysis.coolingAC
                    },
                },
                dateTimeSettings: {
                    SXB_F4D: "Zanik zasilania",
                    SXB_F4DP: "Obecność układu zasilania waryjnego",
                    ...appTexts.dateTimeSettings
                }
            }
            break
        default:
            break
    }    

    return appTexts
}