import React, { useRef, useState, useEffect, useReducer } from 'react'
import { Grid, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableFooter, Paper, Button } from '@material-ui/core'
import { mqttConfig } from '../../../../config/mqttConfig'
import { mqttClient } from '../../../../config/mqttClient'
import { registerValue } from '../../../../data/dataFilter'

const Diagnostics = ({ installation }) => {
    const clientRef = useRef(null)
    const [messages, setMessages] = useState(null) // {"TimeStamp":"2022-12-07T17:45:02.9565029+01:00","ExpirationTime":600000,"Alarms":[["BT_1","0x02"],["BT_1","0x03"]]}
    const [connectionStatus, setConnectionStatus] = useState(false)

    const errors = (code) => {
        switch(code) {
            case "0x01":
                return "Błąd otwarcia portu modbus";
            case "0x02":
                return "Błąd sumy kontrolnej modbus";
            case "0xFE":
                return "Nieznany błąd modbus";
            case "0x03":
                return "Przekroczony czas na zgłoszenie modułu";
            case "0x04":
                return "Błąd połączenia do brokera MQTT";
            case "0x05":
                return "Błąd wznowienia połączenia do brokera MQTT";
            case "0x06":
                return "Błąd subskrybcji do brokera MQTT";
            case "0x07":
                return "Błąd publikacji na brokerze MQTT";
            case "0xFF":
                return "Nieznany błąd";
            default:
                return "Nieznany błąd"
        }
    }

    const remarks = (code) => {
        switch(code) {
            case "0x01":
                return "Sprawdzić nakładkę lub stick usb";
            case "0x02":
                return "Sprawdzić połączenie kablowe pomiędzy modułami";
            case "0xFE":
                return "";
            case "0x03":
                return "Tylko dla euros button";
            case "0x04":
                return "";
            case "0x05":
                return "";
            case "0x06":
                return "";
            case "0x07":
                return "";
            case "0xFF":
                return "";
            default:
                return ""
        }
    }
    
    useEffect(() => {
        console.log(installation)
        clientRef.current = mqttClient.getClient(mqttConfig())
        clientRef.current.subscribe(`${installation}/Diagnostics/Agent`)
        clientRef.current.on('connect', () => setConnectionStatus(true))
        clientRef.current.on('message', function (topic, message, packet) {
            
            if (topic === `${installation}/Diagnostics/Agent` && JSON.parse(message)) {
                setMessages(JSON.parse(message))
            }
        })
        
        return () => {
            if (clientRef.current) {
                clientRef.current.unsubscribe(`${installation}/Diagnostics/Agent`)
                clientRef.current.end(clientRef.current)
            }
        }

    }, [])

    return (
        <React.Fragment>
            <Grid container direction="row" >
                <Typography variant="h2">Diagnostyka - zestawienie błędów</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography component="div" height="100%">
                                        <Box fontWeight="fontWeightBold" height="100%">
                                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    Czas błędu
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div" height="100%">
                                        <Box fontWeight="fontWeightBold" height="100%">
                                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    Moduł
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    Kod błędu
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    Opis błędu
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component="div">
                                        <Box fontWeight="fontWeightBold">
                                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    Uwagi
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {messages && messages.Alarms.length > 0 ? (
                                messages.Alarms.map((alarm, ind) => (
                                    <TableRow>
                                        <TableCell>{new Date(messages.TimeStamp).toLocaleString()} {Date.now() - Date.parse(messages.TimeStamp) > parseInt(messages.ExpirationTime) ? (<p style={{ color: "red" }}><br />{`Błąd został zgłoszony ponad ${parseInt(messages.ExpirationTime)/60000} min temu. Może być już nieaktualny.`}</p>) : null}</TableCell>
                                        <TableCell>{alarm[0]}</TableCell>
                                        <TableCell>{alarm[1]}</TableCell>
                                        <TableCell>{errors(alarm[1])}</TableCell>
                                        <TableCell>{remarks(alarm[1])}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow><TableCell align="center" colSpan={12}>Nie zgłoszono alarmów</TableCell></TableRow>
                            )}
                        </TableBody>
                        </Table>
                </TableContainer>
            </Grid>
        </React.Fragment>
    )
}

export default Diagnostics