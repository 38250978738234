import React, { useReducer, useEffect, useState } from 'react'
import {
    Grid, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, TableFooter, Paper, Button, Select, FormControl, MenuItem, InputLabel, Dialog, DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchUserRoles, fetchUsers, fetchAddUser, fetchEditUser, fetchPartners } from '../../data'
import { default as userDicts } from '../../dictionary/userPanelDictionary'
import { mainStyleClasses } from '../../theme'
import { NewUser, UserDetails } from './components'
import EditIcon from '@material-ui/icons/Edit'

const userListReducer = (state, action) => {
    let data = action.payload
    let roles = action.roles
    let partners = action.partners
    
    data.map((user) => {
        let userPartners = user.partners
        user.fullName = user.first_name + ' ' + user.last_name
        user.status_org = user.status_org ? user.status_org : user.status
        user.status = user.status_org ?
                        (user.status_org in userDicts.userStatus ? userDicts.userStatus[user.status_org] : user.status_org)
                        : (user.status in userDicts.userStatus ? userDicts.userStatus[user.status] : user.status)
        user.type = user.user_type in userDicts.userType ? userDicts.userType[user.user_type] : user.user_type
        user.role = roles.map((role) => {
            if(role.id === user.role_id) {
                return role.role
            }
        }).join('')

        user.partners = partners ? (user.partners_org ? partners.map((partner) => {
            if(user.partners_org.includes(partner.id)) {
                return partner.name
            } else return null
        }).filter(Boolean).join(', ') :
        (partners.map((partner) => {
            if(userPartners.includes(partner.id)) {
                return partner.name
            } else return null
        }).filter(Boolean).join(', '))) : data.partners

        user.partners_org = partners ? (user.partners_org ? user.partners_org :
            partners.map((partner) => {
                if(userPartners.includes(partner.id)) {
                    return partner.id
                } else return null
            }).filter(Boolean)) : data.partners
    })
    
    return data
}

const UserPanel = ({ token, role, user }) => {
    const classes = mainStyleClasses()
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useReducer(userListReducer, [])
    const [open, setOpen] = useState({ status: false, new: false, edit: false, index: null })
    const [partners, setPartners] = useState([])
    
    const handleClickOpen = (type, index) => {
        setOpen({ ...open, [type]: true, status: true, index: index ? index : null })
    }
    
    const columns = [
        { field: 'fullName', headerName: 'Imię i nazwisko', flex: 1, headerClassName: 'user-table-header' },
        { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'user-table-header' },
        { field: 'phone', headerName: 'Nr telefonu', width: 150, headerClassName: 'user-table-header' },
        // { field: 'password', headerName: 'Hasło', flex: 1, headerClassName: 'user-table-header' },
        { field: 'admin', headerName: 'Admin', width: 105, headerClassName: 'user-table-header' },
        { field: 'role', headerName: 'Rola', width: 110, headerClassName: 'user-table-header' },
        { field: 'status', headerName: 'Status', width: 115, headerClassName: 'user-table-header' },
        { field: 'type', headerName: 'Typ', width: 100, headerClassName: 'user-table-header' },
        { field: 'partners', headerName: 'Partnerzy', flex: 1, headerClassName: 'user-table-header', cellClassName: 'desc-styling2' },
        {
            field: "edit",
            headerName: "Edycja",
            sortable: false,
            headerClassName: 'user-table-header',
            width: 120,
            renderCell: (params) => {
              const onClick = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                
                handleClickOpen("edit", params.row.id)
              };
        
              return <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={(e) => onClick(e)}>Edytuj</Button>;
            }
        },
    ];

    useEffect(() => {
        fetchUserRoles()
            .then(response => {
                setRoles(response)
            })
    }, [])

    useEffect(() => {
        fetchPartners()
            .then(response => {
                setPartners(response.data)
            })
    }, [])

    useEffect(() => {
        fetchUsers()
            .then(response => {
                setUsers({ payload: response, roles: roles, partners: partners })
            })
    }, [roles, partners])

    const handleClose = () => {
        setOpen({ status: false, new: false, edit: false, index: null })
    }
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6} style={{padding: 0}}>
                    <Typography variant="h2">Lista użytkowników</Typography>
                </Grid>
                <Grid item xs={6} style={{padding: 0}}>
                    <Button className={classes.floatRight} variant="contained" color="primary" onClick={() => handleClickOpen("new")}>Nowy użytkownik</Button>
                </Grid>
            </Grid>
            {users ? (<Grid container>
                <Grid item xs={12}>
                    <DataGrid
                        columns={columns}
                        rows={users}
                        autoHeight
                    />
                </Grid>
            </Grid>) : <CircularProgress />}
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    {open.new ?
                        <NewUser handleClose={handleClose} roles={roles} userStatus={userDicts.userStatus}
                            userType={userDicts.userType} users={users} setUsers={setUsers} partners={partners} /> : null}
                    {open.edit ?
                        <UserDetails handleClose={handleClose} roles={roles} userStatus={userDicts.userStatus}
                            userType={userDicts.userType} user={users.filter(user => {
                                return user.id === open.index;
                            })[0]} user_id={open.index} users={users} setUsers={setUsers} partners={partners} /> : null}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default UserPanel