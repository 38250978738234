import fetchRefreshToken from './fetchRefreshToken'

const fetchSendEmail = async (payload) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/user/send_email`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      return res
    })
    
    return response
  }


  export default fetchSendEmail