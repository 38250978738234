import React, { useEffect, useState } from 'react'
import {
    Grid, Button, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableFooter, Paper,
    Dialog, DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import { NewPartner, EditPartner } from './components'
import { mainStyleClasses } from '../../theme'
import { default as fetchPartnersList } from '../../data/fetchPartnersList'

const filteredPartnersList = (partnersList, filter, handleClickOpen) => {

    let partners, partnersNewList

    switch (filter.searchKey) {
        case "all":
            partnersNewList = partnersList.map((partner, index) => <PartnersItem partnersList={partnersList} key={partner.id} handleClickOpen={handleClickOpen} partner={partner} index={index} />)
            break;
        case "name":
            partners = partnersList.filter(partner => partner.name !== null && partner.name.toUpperCase().includes(filter.searchValue) === true)
            break;
        case "nip":
            partners = partnersList.filter(partner => partner.nip !== null && partner.nip.includes(filter.searchValue) === true)
            break;
        case "phone":
            partners = partnersList.filter(partner => partner.phone !== null && partner.phone.includes(filter.searchValue) === true)
            break;
        case "email":
            partners = partnersList.filter(partner => partner.email !== null && partner.email.toUpperCase().includes(filter.searchValue) === true)
            break;
        case "address":
            partners = partnersList.filter(partner => ((partner.city.toUpperCase().includes(filter.searchValue) || partner.post_code.toUpperCase().includes(filter.searchValue) || partner.street.toUpperCase().includes(filter.searchValue)) === true))
            break;

        default:
            partnersNewList = partnersList.map((partner, index) => <PartnersItem partnersList={partnersList} key={partner.id} handleClickOpen={handleClickOpen} partner={partner} index={index} />)
            break;
    }

    if (partners)
        partnersNewList = partners.map((partner, index) => (
            <PartnersItem partnersList={partnersList} key={partner.id} handleClickOpen={handleClickOpen} partner={partner} index={index} />
        ))

    return partnersNewList
}

const PartnersItem = ({ partner, index, handleClickOpen, partnersList }) => {
    const parent_partner = partner.parent_partner_id ? partnersList.filter(partners => partners.id === partner.parent_partner_id) : null

    return (
        <TableRow>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{partner.name}</TableCell>
            <TableCell>{partner.nip}</TableCell>
            <TableCell>{partner.phone}</TableCell>
            <TableCell>{partner.email}</TableCell>
            <TableCell>{`${partner.street} ${partner.street_number}, ${partner.post_code} ${partner.city}`}</TableCell>
            <TableCell>{partner.parent_partner_id ? parent_partner[0].name : "bezpośrednio"}</TableCell>
            <TableCell><Button variant="contained" color="primary" onClick={() => handleClickOpen("edit", index)}>Edytuj</Button></TableCell>
        </TableRow>
    )
}

const Partners = () => {
    const classes = mainStyleClasses()
    const [partnersList, setPartnersList] = useState(null)
    const [open, setOpen] = useState({ new: false, edit: false, status: false, index: null })
    const [filter, setFilter] = useState({ searchKey: "all", searchValue: "" })

    const handleClickOpen = (type, index) => {
        console.log(index)
        setOpen({ ...open, [type]: true, status: true, index: index })
    }

    const handleClose = () => {
        setOpen({ new: false, edit: false, status: false, index: null })
    }

    const refreshPartnersList = () => {
        fetchPartnersList().then(res => setPartnersList(res.data))
    }

    useEffect(() => {
        let mounted = true

        refreshPartnersList()
        return () => {
            mounted = false
        }
    }, [])

    const filterPartnerList = (e) => {
        if (e.target.value === "")
            setFilter({ searchKey: "all", searchValue: "" })
        else
            setFilter({ searchKey: e.target.name, searchValue: e.target.value.toUpperCase() })
    }


    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant="h2">Lista partnerów i podwykonawców</Typography>
                </Grid>
                <Grid item xs={4}><br />
                    <Button className={classes.floatRight} variant="contained" color="primary" onClick={() => handleClickOpen("new")}>Nowy partner</Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                            <Box fontWeight="fontWeightBold">
                                                Lp
                                            </Box>
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="Nazwa firmy" name="name" type="text" onChange={(e) => filterPartnerList(e)}
                                            value={filter.searchKey === "name" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="NIP" name="nip" type="text" onChange={(e) => filterPartnerList(e)}
                                            value={filter.searchKey === "nip" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="Telefon" name="phone" type="text" onChange={(e) => filterPartnerList(e)}
                                            value={filter.searchKey === "phone" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="E-mail" name="email" type="text" onChange={(e) => filterPartnerList(e)}
                                            value={filter.searchKey === "email" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="Adres" name="adress" type="text" onChange={(e) => filterPartnerList(e)}
                                            value={filter.searchKey === "adress" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                            <Box fontWeight="fontWeightBold">
                                                Umowa
                                            </Box>
                                    </TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partnersList ? filteredPartnersList(partnersList, filter, handleClickOpen) : <CircularProgress />}

                            </TableBody>

                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    {open.new ? <NewPartner partnersList={partnersList} refreshPartnersList={refreshPartnersList} handleClose={handleClose} />
                        : <EditPartner index={open.index} partner={partnersList ? partnersList[open.index] : null} handleClose={handleClose} partnersList={partnersList} refreshPartnersList={refreshPartnersList} />}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Partners