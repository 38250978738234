import React, { useEffect, useState } from 'react'
import { Typography, Grid, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import pompyA from '../../../../img/pompy-A.svg'
import pompyB from '../../../../img/pompy-B.svg'

const CWUCO = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)

    const handleRadioSwitchValue = name => (e, newValue) => {
        if (!newValue || parseInt(newValue) === 0) {
            setItemValue({
                ...itemValue,
                [name]: 0})
            setPublish({ [name]: 0 })
        } else {
            setItemValue({
                ...itemValue,
                [name]: 1})
            setPublish({ [name]: 1 })
        }
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Wybór sposobu rozdziału pomiędzy CWU a instalacją</Typography>
                </Grid>
            </Grid>
            <Grid container>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <RadioGroup name="SXF_DHM" value={itemValue.SXF_DHM} onChange={handleRadioSwitchValue("SXF_DHM")} >
                        <Grid container>
                            <Grid item xs={6}>
                                <label style={{ display: "block", height: "100%"}}>
                                    <img alt="Euros Energy" src={pompyA} style={{ maxWidth: "50%", height: "180px", margin: "0 auto" }} /><br />
                                    <FormControlLabel value={0} control={<Radio color="primary" />} />
                                </label>
                            </Grid>
                            <Grid item xs={6}>
                                <label style={{ display: "block", height: "100%"}}>
                                    <img alt="Euros Energy" src={pompyB} style={{ maxWidth: "50%", height: "180px" }} /><br />
                                    <FormControlLabel value={1} control={<Radio color="primary" />} />
                                </label>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default CWUCO