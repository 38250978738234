import React, { useReducer, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TimeOption } from './TimeOption'

const rangeMinute = (start, stop) => {
    if(start.PG === stop.KG){
        if((start.PM / 10) < 5)
        return (start.PM / 10) + 1
        else
        return start.PM / 10
    } else {
        return 0
    }
}

const TimeZone = ({ ptc, day, setPublish, stamp, number }) => {
    const switchOption = (state, action) => {
        if (action.case === "UPDATE_FROM_BROKER") {
            return (action.payload)
        } else {
            let timeType = action.type.substr(action.type.length - 2, action.type.length);
            let zoneType = action.type.substr(0, action.type.length - 3);

            return {
                ...state, [zoneType]: {
                    ...state[zoneType],
                    [timeType]: action.payload,
                }
            }
        }
    }

    const [timeControl, setTimeControl] = useReducer(switchOption, ptc)

    useEffect(() => {
        setTimeControl({ payload: ptc, case: "UPDATE_FROM_BROKER" })
    }, [ptc])

    
    const handleTimeControl = (e) => {
        const sendKey = e.target.name
        const sendData = e.target.value
        console.log(timeControl)

        setPublish({ [sendKey]: sendData })
    }


    return (
        <React.Fragment>
            <Row className="cwu-row">
                <Col md={2} xs={2} className="text-right np"><p className="text-right">{number}</p><br /></Col>
                <Col className="text-center" xs={4} md={2}>
                    <div className="maskLikeInput">
                        <Form.Control name={`CYR${stamp}${number}P_PG`} as="select" value={timeControl["CYR" + number].PG} custom
                            onChange={(e) => { setTimeControl({ type: `CYR${number}_PG`, payload: parseInt(e.target.value) }); handleTimeControl(e) }} disabled={ptc.SWITCH === 0? true:false}>
                            <TimeOption nameKey={`CYR${stamp}${number}P_PG`} start={0} stop={24} />
                        </Form.Control>
                                            :
                        <Form.Control name={`CYR${stamp}${number}P_PM`} as="select" value={timeControl["CYR" + number].PM} custom
                            onChange={(e) => { setTimeControl({ type: `CYR${number}_PM`, payload: parseInt(e.target.value) }); handleTimeControl(e) }} disabled={ptc.SWITCH === 0? true:false}>
                            <TimeOption nameKey={`CYR${stamp}${number}P_PM`} start={0} stop={6} multiplier={10} />
                        </Form.Control>
                    </div>
                </Col>
                <Col className="text-center" xs={4} md={2}>
                    <div className="maskLikeInput">
                        <Form.Control name={`CYR${stamp}${number}P_KG`} as="select" value={timeControl["CYR" + number].KG} custom
                            onChange={(e) => { setTimeControl({ type: `CYR${number}_KG`, payload: parseInt(e.target.value) }); handleTimeControl(e) }} disabled={ptc.SWITCH === 0? true:false}>
                            <TimeOption nameKey={`CYR${stamp}${number}P_KG`} start={timeControl["CYR" + number].PG} stop={24} />
                        </Form.Control>
                                            :
                        <Form.Control name={`CYR${stamp}${number}P_KM`} as="select" value={timeControl["CYR" + number].KM} custom
                            onChange={(e) => { setTimeControl({ type: `CYR${number}_KM`, payload: parseInt(e.target.value) }); handleTimeControl(e) }} disabled={ptc.SWITCH === 0? true:false}>
                            <TimeOption nameKey={`CYR${stamp}${number}P_KM`} start={rangeMinute({PG: timeControl["CYR" + number].PG, PM: timeControl["CYR" + number].PM}, {KG: timeControl["CYR" + number].KG})} stop={6} multiplier={10} />
                        </Form.Control>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default TimeZone