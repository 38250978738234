import React, { useState, useEffect } from 'react'
import { Grid, Typography, Table, Paper, TableBody, TableContainer, TableRow, TableCell, TableHead, Box } from '@material-ui/core'
import { fetchPartnersList, fetchUsers } from '../../data'

const DeviceGangeLogList = ({ deviceLog, partnersList, usersList }) => {
    const deviceChangeLogList = deviceLog.reverse().map((item, index) => {
        const userInfo = usersList.filter(user => user.id === item.user_id)[0]
        return (
            <TableRow key={`${item.change_name}${index}`}>
                <TableCell><Typography variant='body2'>{`${index + 1}`}</Typography></TableCell>
                <TableCell><Typography variant='body2'>{item.date}</Typography></TableCell>
                <TableCell><Typography variant='body2'>{item.change_name}</Typography></TableCell>
                {JSON.parse(item.change).old_partner_id === null ?
                    <TableCell><Typography variant='body2'>brak</Typography></TableCell>
                    : <TableCell><Typography variant='body2'>{partnersList ? partnersList.filter(partner => partner.id === JSON.parse(item.change).old_partner_id)[0].name
                        : null}</Typography></TableCell>
                }
                <TableCell><Typography variant='body2'>{partnersList ? partnersList.filter(partner => partner.id === JSON.parse(item.change).new_partner_id)[0].name
                    : null}</Typography></TableCell>
                <TableCell><Typography variant='body2'>{usersList ? `${userInfo.first_name} ${userInfo.last_name}${userInfo.email? `${userInfo.email}` : '' }${userInfo.phone? `, ${userInfo.phone}` : ''} ` : null}</Typography></TableCell>
            </TableRow>
        )

    })

    return deviceChangeLogList

}

const DeviceChangeLog = ({ deviceLog }) => {
    const [partnersList, setPartnersList] = useState(null)
    const [usersList, setUsersList] = useState(null)

    const partnersListGet = () => {
        fetchPartnersList().then(res => setPartnersList(res.data))
    }
    const usersListGet = () => {
        fetchUsers().then(res => setUsersList(res))
    }

    useEffect(() => {
        let mounted = true

        partnersListGet()
        usersListGet()

        return () => {
            mounted = false
        }
    }, [])

    return (
        <React.Fragment>
            <Grid row>
                <Grid item={12}>
                {deviceLog.length !== 0? <React.Fragment><Typography variant='h2'> Lista zmian: </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                LP
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Data
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Typ zmiany
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Dawniej
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Nowe
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Pracownik
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partnersList && usersList ? <DeviceGangeLogList usersList={usersList} partnersList={partnersList} deviceLog={deviceLog} /> : null}
                            </TableBody>
                        </Table>
                    </TableContainer></React.Fragment> : null}


                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default DeviceChangeLog