import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'
import formatString from "format-string-by-pattern"

const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
  
    return formatString('999', onlyNumbers)
}

const MainParametersRegulationEON = ({ required, setValues, tempCWU, tempCO, startCoTemp }) => {

    useEffect(() => {
        setValues({type: "childName", value: "MainParametersRegulationEON"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Regulacja parametrów kluczowych</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Procedura uruchomienia w aplikacji serwisowej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="launchAtmoMonitor"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="launchAtmoMonitor"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="launchAtmoMonitor" />
                </Grid>
                <Grid container item spacing={0}>
                    <Grid item xs={12} sx={{paddingBottom: 0, m:-2, p: -2}} alignContent='flex-end'>
                        <Typography variant='h3' sx={{paddingBottom: 0, m:-2, p: -2}}>Ustawienie temperatur dla:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='h3'>CWU</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel
                                    label="45&#x2103;"
                                    control={
                                        <Field
                                            name="tempAdjustCWU"
                                            component={Radio}
                                            type="radio"
                                            value="Tak"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => {
                                                setValues({type: "tempCWU", value: false})
                                            }}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Inna wartość"
                                    control={
                                        <Field
                                            name="tempAdjustCWU"
                                            component={Radio}
                                            type="radio"
                                            value="Inna"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => {
                                                setValues({type: "tempCWU", value: true})
                                            }}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl><br />
                        <Error name="tempAdjustCWU" />
                        {tempCWU ? <Grid item xs={12} sm={6}>
                            <Field name="tempCwuValue"
                                component={TextField}
                                type="text"
                                label="Wartość [&#x2103;]"
                                fullWidth
                                validate={required}
                                parse={e => formatOnlyNumbers(e)}
                            />
                        </Grid> : null}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='h3'>CO</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel
                                    label="21&#x2103;"
                                    control={
                                        <Field
                                            name="tempAdjustCO"
                                            component={Radio}
                                            type="radio"
                                            value="Tak"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => {
                                                setValues({type: "tempCO", value: false})
                                            }}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Inna wartość"
                                    control={
                                        <Field
                                            name="tempAdjustCO"
                                            component={Radio}
                                            type="radio"
                                            value="Inna"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => {
                                                setValues({type: "tempCO", value: true})
                                            }}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl><br />
                        <Error name="tempAdjustCO" />
                        {tempCO ? <Grid item xs={12} sm={6}>
                            <Field name="tempCoValue"
                                component={TextField}
                                type="text"
                                label="Wartość [&#x2103;]"
                                fullWidth
                                validate={required}
                                parse={e => formatOnlyNumbers(e)}
                            />
                        </Grid> : null}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Temperatura powietrza umożliwiająca start CO</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="20&#x2103;"
                                control={
                                    <Field
                                        name="startCoTemp"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => {
                                            setValues({type: "startCoTemp", value: false})
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Inna wartość"
                                control={
                                    <Field
                                        name="startCoTemp"
                                        component={Radio}
                                        type="radio"
                                        value="Inna"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => {
                                            setValues({type: "startCoTemp", value: true})
                                        }}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="startCoTemp" />
                    {startCoTemp ? <Grid item xs={12} sm={6}>
                        <Field name="startCoTempValue"
                            component={TextField}
                            type="text"
                            label="Wartość [&#x2103;]"
                            fullWidth
                            validate={required}
                            parse={e => formatOnlyNumbers(e)}
                        />
                    </Grid> : null}
                </Grid>
            </Grid>
        </>
    )
}

export default MainParametersRegulationEON;