import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import { LeftSide, RightSide, CenterSide } from './components'
// import { useLocation, useParams } from "react-router-dom";

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
//   }
const Main = ({ settings, extSettings, setPublish, url, transmState }) => {
    const [modes, setModes] = useState(settings.modes)
    // let location = useLocation()
    // let location2 = useParams()
    useEffect(() => {
        let mounted = true

        setModes(settings.modes)

        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={2}>
                    <LeftSide settings={modes} setPublish={setPublish} setModes={setModes} />
                </Grid>
                <Grid item xs={8}>
                    <CenterSide settings={settings.circuit} extSettings={extSettings} statistics={settings.statistics} scada={settings.scada} modes={modes}
                        setPublish={setPublish} url={url} transmState={transmState} />
                </Grid>
                <Grid item xs={2}>
                    <RightSide settings={settings.stateDevice} setPublish={setPublish} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Main