import React, { useState, useEffect, useReducer } from 'react'
import { Grid, Tabs, Tab, Box, Typography, Switch } from '@material-ui/core'
import { mainStyleClasses } from '../../../../../../theme'
import { TimeZone } from '../'
import cyrkulacja from '../../../../../../img/Cyrkulacja.svg'
import cyrkulacja2 from '../../../../../../img/Cyrkulacja2.svg'

const a11yProps = (index) => {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const DayTab = ({ children, value, index, handleChangeSwitchDay, day, stamp, daySwitch, timeZone, handleChangeTime, setPublish, ...props }) => {
    const classes = mainStyleClasses()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...props}>
            {value === index && (<React.Fragment>
                <Grid container className={`gray-bg ${classes.textCenter}`}>
                    <Grid item md={2} xs={2} className={`${classes.textLeft}`}>
                        {daySwitch === 0 ?
                            <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 1, stamp: day })} className="mode-icon" src={cyrkulacja2} />
                        : <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 0, stamp: day })} className="mode-icon" src={cyrkulacja} />}

                        <p className={`${classes.floatRight} d-none d-md-block`}>Nr</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Start</p>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <p className={`${classes.textCenter}`}>Stop</p>
                    </Grid>
                </Grid>
                <TimeZone key={`T1${day}`} setPublish={setPublish} daySwitch={daySwitch} handleChangeTime={handleChangeTime} timeZone={timeZone} day={day} num={1} stamp={stamp} />
                <TimeZone key={`T2${day}`} setPublish={setPublish} daySwitch={daySwitch} handleChangeTime={handleChangeTime} timeZone={timeZone} day={day} num={2} stamp={stamp} />
                <TimeZone key={`T3${day}`} setPublish={setPublish} daySwitch={daySwitch} handleChangeTime={handleChangeTime} timeZone={timeZone} day={day} num={3} stamp={stamp} />
                    
            </React.Fragment>)}
        </div>
    )
}

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else if (action.case === "DAY_SWITCH") {
        return {
            ...state,
                [action.stamp]: {
                    ...state[action.stamp],
                    SWITCH: action.value
                }
        }
    } else {
        return {
            ...state,
            [action.blok]: {
                ...state[action.blok],
                [action.stamp]: {
                    ...state[action.blok][action.stamp],
                    [`${action.blok}W${action.num}`]: {
                        ...state[action.blok][action.stamp][`${action.blok}W${action.num}`],
                        [action.type]: action.value
                    }
                }
            }
        }
    }
}

const TimeCirculation = ({ circulation, setPublish }) => {
    const classes = mainStyleClasses()
    const [controller, setTimeZoneState] = useReducer(switchOption, circulation)
    const [value, setValue] = useState(0)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", type: "", min: null, max: null })
    const handleClickOpen = (element, name, title, type, min, max) => {

        setOpen({ status: true, element: element, name: name, title: title, type: type, min: min, max: max })
    }

    const handleClose = () => {
        setOpen({ status: false, element: 0, name: "", title: "", type: "", min: null, max: null })
    }

    useEffect(() => {
        setTimeZoneState({ payload: circulation, case: "UPDATE_FROM_BROKER" })
    }, [circulation])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeTime = (e) => {
        setTimeZoneState({ type: e.type, stamp: e.stamp, num: e.num, blok: e.blok, value: parseInt(e.value) })

        // if (e.type === "Temp") {
        //     handleSendTimeZoneState({ id: `${e.blok}W${e.more}${e.num}T`, value: parseInt(e.value) }, setState)
        // } else {
        //     handleSendTimeZoneState({ id: `${e.blok}W${e.more}${e.num}P_${e.type}`, value: parseInt(e.value) }, setState)
        // }
    }

    const handleChangeSwitchDay = (e) => {
        setTimeZoneState({ case: e.case, stamp: e.stamp, value: parseInt(e.value) })

        handleSendTimeZoneState({ id: `CYRSW_${e.stamp}`, value: parseInt(e.value) })
    }

    const handleSendTimeZoneState = (e) => {
        const sendKey = e.id;
        const sendData = e.value;

        setPublish({ [sendKey]: sendData })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                    >
                        <Tab label="PN" {...a11yProps(0)} style={{ minWidth:"14.28%" }} />
                        <Tab label="WT" {...a11yProps(1)} style={{ minWidth:"14.28%" }} />
                        <Tab label="ŚR" {...a11yProps(2)} style={{ minWidth:"14.28%" }} />
                        <Tab label="CZ" {...a11yProps(3)} style={{ minWidth:"14.28%" }} />
                        <Tab label="PT" {...a11yProps(4)} style={{ minWidth:"14.28%" }} />
                        <Tab label="SB" {...a11yProps(5)} style={{ minWidth:"14.28%" }} />
                        <Tab label="ND" {...a11yProps(6)} style={{ minWidth:"14.28%" }} />
                    </Tabs>
                </Grid>
            </Grid>
            <DayTab value={value} index={0} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.PN.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.PN} day={'PN'} stamp={'P'} />

            <DayTab value={value} index={1} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.WT.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.WT} day={'WT'} stamp={'W'} />

            <DayTab value={value} index={2} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.SR.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.SR} day={'SR'} stamp={'S'} />

            <DayTab value={value} index={3} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.CZ.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.CZ} day={'CZ'} stamp={'C'} />

            <DayTab value={value} index={4} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.PT.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.PT} day={'PT'} stamp={'PT'} />

            <DayTab value={value} index={5} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.SO.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.SO} day={'SO'} stamp={'SO'} />

            <DayTab value={value} index={6} setPublish={setPublish} handleChangeSwitchDay={handleChangeSwitchDay} daySwitch={controller.ND.SWITCH} 
    handleChangeTime={handleChangeTime} timeZone={controller.ND} day={'ND'} stamp={'N'} />
    
        </React.Fragment>
    )
}

export default TimeCirculation