import fetchRefreshToken from './fetchRefreshToken'

const fetchAcceptanceReport = async (token, sn) => {
  // const newToken = await fetchRefreshToken().then(response => response)
  //   //sessionStorage.setItem('APIToken', newToken)
  //   const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_report/device_sn/${sn}`, {
  //     method: "GET",
  //     headers:{
  //       "Authorization": `Bearer ${newToken}`
  //     }
  //   }).then(response => response)

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_report/device_sn/${sn}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${newToken}`
        }
      })
      return res
    })

    return response
  }


  export default fetchAcceptanceReport