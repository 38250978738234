import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, Checkbox, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const SensorConnectionsEON = ({ required, setValues, heatExposure, sensors, setSensors }) => {

    useEffect(() => {
        setValues({type: "childName", value: "SensorConnectionsEON"})
    }, []);

    const handleClick = (sensor) => {
        let connected = sensors.CWU + sensors.bufor + sensors.Tzewn + sensors.Twewn
        let newSensor = !sensors[sensor]
        setSensors({...sensors, [sensor]: newSensor})
        let newValue = newSensor ? true : (connected > 1 ? true : false)
        setValues({type: "sensorsConnected", value: newValue})
    }

    return (
        <>
            <Typography variant='h2'>Podłączenie czujników</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Podłączenie czujników temperatury:</Typography>
                    <FormControl component="fieldset">
                        <FormGroup row>
                            <FormControlLabel
                                label="Czujnik zasobnika CWU"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="CWU"
                                        color="primary"
                                        checked={sensors.CWU}
                                        onClick={() => handleClick("CWU")}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Czujnik bufora"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="bufor"
                                        color="primary"
                                        checked={sensors.bufor}
                                        onClick={() => handleClick("bufor")}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Czujnik temperatury zewnętrznej"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="temperatura zewnętrzna"
                                        color="primary"
                                        checked={sensors.Tzewn}
                                        onClick={() => handleClick("Tzewn")}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Czujnik temperatury wewnętrznej"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="temperatura wewnętrzna"
                                        color="primary"
                                        checked={sensors.Twewn}
                                        onClick={() => handleClick("Twewn")}
                                    />
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Umiejscowienie czujnika temperatury zewnętrznej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Ściana północna"
                                control={
                                    <Field
                                        name="sensorOutsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Ściana północna"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Ściana inna niż północna"
                                control={
                                    <Field
                                        name="sensorOutsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="inna"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="sensorOutsideTempPlace" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Czy miejsce montażu czujnika jest zacienione?</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Tak"
                                control={
                                    <Field
                                        name="sensorOutsideTempShade"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie"
                                control={
                                    <Field
                                        name="sensorOutsideTempShade"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="sensorOutsideTempShade" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Umiejscowienie czujnika temperatury wewnętrznej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Pomieszczenie reprezentatywne"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Pomieszczenie reprezentatywne"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Kuchnia"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Kuchnia"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Hall"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Hall"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Łazienka"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Łazienka"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="sensorInsideTempPlace" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Czy miejsce montażu czujnika jest narażone na nasłonecznienie lub znajduje się przy źródle ciepła?<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Jeżeli tak, opisać</span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Tak"
                                control={
                                    <Field
                                        name="heatExposure"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "heatExposure", value: true})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie"
                                control={
                                    <Field
                                        name="heatExposure"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "heatExposure", value: false})}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="heatExposure" />
                </Grid>
                {heatExposure ? <Grid item xs={12}>
                    <Field
                        name="heatExposureComments"
                        component={TextField}
                        label="Opis"
                        multiline
                        fullWidth
                        validate={required}
                    />
                </Grid> : null}
            </Grid>
        </>
    )
}

export default SensorConnectionsEON;