import React, { useState } from 'react'
import { Button, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const factorySettings = async (setPublish, setStepFactorySettings) => {
    await sleep(1000)
    setPublish({
        DEFA_ST1: 0
    })
    setStepFactorySettings(2)

    await sleep(3000)
    setPublish({
        DEFA_ST2: 0
    })
    setStepFactorySettings(3)

    await sleep(3000)
    setPublish({
        DEFA_ST1: 1
    })
    setStepFactorySettings(4)

    await sleep(3000)
    setPublish({
        DEFA_ST2: 1
    })

    await sleep(5000)
    setStepFactorySettings(5)
}

const FactorySettings = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()

    const [stepFactorySettings, setStepFactorySettings] = useState(0)
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleFactorySettings = () => {
        factorySettings(setPublish, setStepFactorySettings)
        setOpen(false)
    }


    return (
        <React.Fragment>
        <Typography variant="h2">Przywracanie ustawień fabrycznych</Typography>
        <Typography variant="body1">Kliknij przycisk poniżej, aby przywrócić ustawienia fabryczne.</Typography>
            <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={handleClickOpen}>Przywróć ustawienia fabryczne</Button>
            {stepFactorySettings === 5 && settings.DEFA_ST1 === "0" && settings.DEFA_ST2 === "0"? <Typography variant="body1">Ustawienia fabryczne PRZYWRÓCONO</Typography> : stepFactorySettings > 0 && stepFactorySettings < 5? <Typography variant="body1">Trwa przywracanie ustawień fabrycznych, proszę czekać...</Typography> : null}
        <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent style={{ padding: "10px 25px 25px" }}>
                <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                    <CloseIcon />
                </IconButton>

                <Typography variant="h2">Czy na pewno chcesz przywrócić ustawienia fabryczne?</Typography>
                <Typography variant="body2">Proces jest nieodwracalny.</Typography>
                <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleFactorySettings()}>Tak, przywracam ustawienia fabryczne</Button>
            </DialogContent>
        </Dialog>
        </React.Fragment>
    )
}

export default FactorySettings