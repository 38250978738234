import React, { useState } from 'react'
import { Button, Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../../../../../theme'
import { fetchPin } from '../../../../../../data'
import { ConfirmSendForm } from '../'

const ListItem = ({ index, device, perm, handleClickOpen }) => {
    const classes = mainStyleClasses()
    const [pin, setPin] = useState(null)
    const dataReport = JSON.parse(device.acceptance_reports[0].content)

    const downloadPin = () => {
        fetchPin(device.device_sn).then(response => setPin(response))
    }

    return (
        <TableRow key={`row-${device.key}`} className={device.acceptance_reports[0].status === "new" ? `${classes.backgroundRed}` : device.acceptance_reports[0].status === "filled" ? `${classes.backgroundYellow}` : `${classes.backgroundGreen}`}>
            <TableCell style={{ textAlign: "left" }}>{index + 1}</TableCell>
            <TableCell style={{ textAlign: "left" }}>{dataReport.date}</TableCell>
            <TableCell style={{ textAlign: "left" }}>{`ul. ${dataReport.street} ${dataReport.streetNumber}`} <br /> {`${dataReport.postNumber} ${dataReport.city}`}</TableCell>
            <TableCell style={{ textAlign: "left" }}>
                {dataReport.comments ? <Button variant="contained" color="primary" type="button" onClick={() => handleClickOpen(dataReport.comments, device.id, "comment")}>Instalator</Button> : null}
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
                {device.acceptance_reports[0].customer_content ? <Button variant="contained" color="primary" type="button" onClick={() => handleClickOpen(device.acceptance_reports[0].customer_content, device.id, "comment")}>Klient</Button> : null}</TableCell>
            <TableCell style={{ textAlign: "left" }}>{pin && pin.status === "ok" ? <Typography variant="body1">{pin.data.pin}</Typography> : <Button variant="contained" color="primary" type="button"
                onClick={() => downloadPin()}>Pobierz</Button>}</TableCell>
            <TableCell style={{ textAlign: "right" }}>
                <Button variant="contained" color="primary" type="button" onClick={() => {handleClickOpen(dataReport, device.id, "report", device.acceptance_reports[0].id)}}>Protokół</Button>
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
                {perm.length === 3 && perm.includes("device_r") && perm.includes("customer_r") && perm.includes("installation_r") ? null : (<Link to={`/szczegoly/${device.key}/${device.device_sn ? device.device_sn : "brak-nr-seryjnego"}`}>
                    <Button variant="contained" color="primary">Instalacja</Button></Link>)}
            </TableCell>
        </TableRow>
    )
}

const deviceList = (devices, perm, handleClickOpen) => {
    return devices.map((device, index) => <ListItem key={device.id} perm={perm} index={index} device={device} handleClickOpen={handleClickOpen} />)
}

const InstallationList = ({ devices, perm, refreshInstallator }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ status: false, data: null, id: null, dialog: null, reportId: null })

    const handleClickOpen = (data, id, dialog, reportId) => {
        setOpen({ status: true, data: data, id: id, dialog: dialog, reportId: reportId })
    }

    const handleClose = () => {
        setOpen({ status: false, data: null, id: null, dialog: null, reportId: null })
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Lista instalacji</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Lp
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Data
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Adres
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Uwagi
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">

                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Hasło
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>

                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>

                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deviceList(devices, perm, handleClickOpen)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status && open.dialog === "report"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    {open.data ? <ConfirmSendForm report={open.data} reportId={open.reportId} refreshInstallator={refreshInstallator} /> : null}
                </DialogContent>
            </Dialog>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status && open.dialog === "comment"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h2">Przesłane uwagi:</Typography>
                    {open.data ? open.data : null}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default InstallationList