import React, {useEffect, useReducer} from 'react'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary'


const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        if (state[action.type] === 0) {
            return { ...state, [action.type]: 1 };
        } else if (state[action.type] === 1) {
            return { ...state, [action.type]: 0 };
        }
    }
};

const handleSendFunctionState = (e, setPublish) => {
    const sendKey = e.target.id;
    let sendData;
    if (e.target.checked) {
        sendData = 1;
    } else {
        sendData = 0;
    }

    setPublish({ [sendKey]: sendData })
    
}

const ControlFunctionItem = ({ setPublish, cfs, stamp}) => {
    const [controller, setFunctionState] = useReducer(switchOption, cfs)

    useEffect(() => {
        setFunctionState({ payload: cfs, case: "UPDATE_FROM_BROKER" })
    }, [cfs])

    return (
        <>
        <Form as={Row} key={stamp}>
                <Form.Check
                    type="switch"
                    id={stamp}
                    label={descriptionDictionary.cfs[stamp]}
                    checked={controller[stamp] === 1 ? true : false}
                    onChange={(e) => { setFunctionState({ type: stamp }); handleSendFunctionState(e, setPublish) }}
                />
            </Form>
        </>
    )
}

export default ControlFunctionItem;