import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
const SoftwareList = ({ software }) => {
    
    return(
            <TableRow>
                <TableCell>{software.name}</TableCell> 
                <TableCell>{software.ver}</TableCell>
            </TableRow>      
    )
}

export default SoftwareList