import React, { useState, useEffect } from 'react'
import _ from 'lodash';


const chartBars = (items) => {
    let bars = [];
    bars.push(
        [items.SS0[`PDHWT1`] + items.SS0[`PDHWT2`], items.SS0[`PHTT1`] + items.SS0[`PHTT2`], items.SS0[`PACT1`] + items.SS0[`PACT2`]]
    )

    for (let i = 1; i <= 12; i++) {
        bars.push(
            [items[`SS${i}`][`SS${i}_PDHWT1`] + items[`SS${i}`][`SS${i}_PDHWT2`], items[`SS${i}`][`SS${i}_PHTT1`] + items[`SS${i}`][`SS${i}_PHTT2`], items[`SS${i}`][`SS${i}_PACT1`] + items[`SS${i}`][`SS${i}_PACT2`]]
        );
    };

    return bars
};

const handleDate = (curr, year, num) => {
    if (curr - num <= 0) {
        return [curr + 12 - num, year - 1]
    } else {
        return [curr - num, year]
    }
};

const chartTicks = (items) => {
    let ticks = [];

    const current = items.MIESIAC
    const currentYear = items.ROK

    for (let i = 0; i <= 12; i++) {
        ticks.push(handleDate(current, currentYear, i));
    }

    return ticks
};

const Statistics = ({ settings }) => {
    const [itemValue, setItemValue] = useState(null)
    const [barsItem, setBarsItem] = useState(null)
    const [ticksItem, setTicksItem] = useState(null)

    // const bars = useMemo(
    //     () => chartBars(itemValue), 
    //     [itemValue]
    //   );
      
    // const ticks = useMemo(
    //     () => chartTicks(itemValue),
    //     [itemValue]
    // );
    const setY = (monthid, tempid, type) => {
        let yValue

        if (barsItem === null) return
        else if ((barsItem[monthid][0] + barsItem[monthid][1] + barsItem[monthid][2]) / 60 < 0 || (barsItem[monthid][0] + barsItem[monthid][1] + barsItem[monthid][2]) / 60 > 744) {
            yValue = 499
        }
        else if (tempid === 0) {
            yValue = 499 - barsItem[monthid][0]*0.5375 / 60
        }
        else if (tempid === 1) {
            yValue = 499 - barsItem[monthid][0]*0.5375 / 60 - barsItem[monthid][1]*0.5375 / 60
        }
        else if (tempid === 2) {
            yValue = 499 - barsItem[monthid][0]*0.5375 / 60 - barsItem[monthid][1]*0.5375 / 60 - barsItem[monthid][2]*0.5375 / 60
        }

        if (type === "text")
        return yValue - 5
        else
        return yValue
    }

    const handleBarLabel = (monthid) => {
        if (barsItem === null) return
        else if (barsItem[monthid][0] < 0 || barsItem[monthid][1] < 0 || barsItem[monthid][2] < 0) {
            return "E 1"
        }
        else if ((barsItem[monthid][0] + barsItem[monthid][1] + barsItem[monthid][2]) / 60 > 744) {
            return "E 2"
        }
        else {
            return Math.floor((barsItem[monthid][0] + barsItem[monthid][1] + barsItem[monthid][2]) / 60)
        }
    };

    const handleBarHeight = (monthid, tempid) => {
        if (barsItem === null) return
        else if (barsItem[monthid][0] < 0 || barsItem[monthid][1] < 0 || barsItem[monthid][2] < 0 || (barsItem[monthid][0] + barsItem[monthid][1] + barsItem[monthid][2]) / 60 > 744) {
            return 0
        }
        else {
           return barsItem[monthid][tempid]*0.5375 / 60
        }
    };
    
    useEffect(() => {
        let mounted = true

        if (!_.isEqual(settings, itemValue)) {
            setItemValue(settings)
            setBarsItem(chartBars(settings))
            setTicksItem(chartTicks(settings))
        } else {
        }
             return () => {
            mounted = false 
        }
    }, [settings])


    return (
        <React.Fragment>
            <div style={{ width: '95%', margin: '0 auto' }}>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 762 580" className="chart">
                    <text x="15" y="20" transform="matrix(1 0 0 1 107.1104 33.0996)" className="stat4 stat9 stat10">Statystyki urządzenia</text>
                    <line className="stat0" x1="58" y1="500" x2="720" y2="500"/>
                    <line className="stat0" x1="58" y1="500" x2="58" y2="70"/>

                    <text x="74.5" y={setY(12, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(12)}</text>
                    <rect x="62.5" y={setY(12, 2)} className="stat3" width="24.407" height={handleBarHeight(12, 2)}/>
                    <rect x="62.5" y={setY(12, 1)} className="stat2" width="24.407" height={handleBarHeight(12, 1)}/>
                    <rect x="62.5" y={setY(12, 0)} className="stat1" width="24.407" height={handleBarHeight(12, 0)}/>
                    
                    <text x="123.314" y={setY(11, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(11)}</text>
                    <rect x="111.314" y={setY(11, 2)} className="stat3" width="24.407" height={handleBarHeight(11, 2)}/>
                    <rect x="111.314" y={setY(11, 1)} className="stat2" width="24.407" height={handleBarHeight(11, 1)}/>
                    <rect x="111.314" y={setY(11, 0)} className="stat1" width="24.407" height={handleBarHeight(11, 0)}/>

                    <text x="172.128" y={setY(10, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(10)}</text>
                    <rect x="160.128" y={setY(10, 2)} className="stat3" width="24.407" height={handleBarHeight(10, 2)}/>
                    <rect x="160.128" y={setY(10, 1)} className="stat2" width="24.407" height={handleBarHeight(10, 1)}/>
                    <rect x="160.128" y={setY(10, 0)} className="stat1" width="24.407" height={handleBarHeight(10, 0)}/>

                    <text x="220.942" y={setY(9, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(9)}</text>
                    <rect x="208.942" y={setY(9, 2)} className="stat3" width="24.407" height={handleBarHeight(9, 2)}/>
                    <rect x="208.942" y={setY(9, 1)} className="stat2" width="24.407" height={handleBarHeight(9, 1)}/>
                    <rect x="208.942" y={setY(9, 0)} className="stat1" width="24.407" height={handleBarHeight(9, 0)}/>

                    <text x="269.756" y={setY(8, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(8)}</text>
                    <rect x="257.56" y={setY(8, 2)} className="stat3" width="24.407" height={handleBarHeight(8, 2)}/>
                    <rect x="257.56" y={setY(8, 1)} className="stat2" width="24.407" height={handleBarHeight(8, 1)}/>
                    <rect x="257.56" y={setY(8, 0)} className="stat1" width="24.407" height={handleBarHeight(8, 0)}/>

                    <text x="318.57" y={setY(7, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(7)}</text>
                    <rect x="306.57" y={setY(7, 2)} className="stat3" width="24.407" height={handleBarHeight(7, 2)}/>
                    <rect x="306.57" y={setY(7, 1)} className="stat2" width="24.407" height={handleBarHeight(7, 1)}/>
                    <rect x="306.57" y={setY(7, 0)} className="stat1" width="24.407" height={handleBarHeight(7, 0)}/>
                    
                    <text x="367.384" y={setY(6, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(6)}</text>
                    <rect x="355.384" y={setY(6, 2)} className="stat3" width="24.407" height={handleBarHeight(6, 2)}/>
                    <rect x="355.384" y={setY(6, 1)} className="stat2" width="24.407" height={handleBarHeight(6, 1)}/>
                    <rect x="355.384" y={setY(6, 0)} className="stat1" width="24.407" height={handleBarHeight(6, 0)}/>

                    <text x="416.198" y={setY(5, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(5)}</text>
                    <rect x="404.198" y={setY(5, 2)} className="stat3" width="24.407" height={handleBarHeight(5, 2)}/>
                    <rect x="404.198" y={setY(5, 1)} className="stat2" width="24.407" height={handleBarHeight(5, 1)}/>
                    <rect x="404.198" y={setY(5, 0)} className="stat1" width="24.407" height={handleBarHeight(5, 0)}/>

                    <text x="465.012" y={setY(4, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(4)}</text>
                    <rect x="453.012" y={setY(4, 2)} className="stat3" width="24.407" height={handleBarHeight(4, 2)}/>
                    <rect x="453.012" y={setY(4, 1)} className="stat2" width="24.407" height={handleBarHeight(4, 1)}/>
                    <rect x="453.012" y={setY(4, 0)} className="stat1" width="24.407" height={handleBarHeight(4, 0)}/>

                    <text x="513.826" y={setY(3, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(3)}</text>
                    <rect x="501.826" y={setY(3, 2)} className="stat3" width="24.407" height={handleBarHeight(3, 2)}/>
                    <rect x="501.826" y={setY(3, 1)} className="stat2" width="24.407" height={handleBarHeight(3, 1)}/>
                    <rect x="501.826" y={setY(3, 0)} className="stat1" width="24.407" height={handleBarHeight(3, 0)}/>

                    <text x="562.64" y={setY(2, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(2)}</text>
                    <rect x="550.64" y={setY(2, 2)} className="stat3" width="24.407" height={handleBarHeight(2, 2)}/>
                    <rect x="550.64" y={setY(2, 1)} className="stat2" width="24.407" height={handleBarHeight(2, 1)}/>
                    <rect x="550.64" y={setY(2, 0)} className="stat1" width="24.407" height={handleBarHeight(2, 0)}/>

                    <text x="611.454" y={setY(1, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(1)}</text>
                    <rect x="599.454" y={setY(1, 2)} className="stat3" width="24.407" height={handleBarHeight(1, 2)}/>
                    <rect x="599.454" y={setY(1, 1)} className="stat2" width="24.407" height={handleBarHeight(1, 1)}/>
                    <rect x="599.454" y={setY(1, 0)} className="stat1" width="24.407" height={handleBarHeight(1, 0)}/>
                    
                    <text x="660.268" y={setY(0, 2, "text")} textAnchor="middle" className="stat4 stat5 stat6">{handleBarLabel(0)}</text>
                    <rect x="648.268" y={setY(0, 2)} className="stat3" width="24.407" height={handleBarHeight(0, 2)}/>
                    <rect x="648.268" y={setY(0, 1)} className="stat2" width="24.407" height={handleBarHeight(0, 1)}/>
                    <rect x="648.268" y={setY(0, 0)} className="stat1" width="24.407" height={handleBarHeight(0, 0)}/>

                    <text x="10" y="76" className="stat4 stat5 stat6">800 h</text>
                    <text x="10" y="129.75" className="stat4 stat5 stat6">700 h</text>
                    <text x="10" y="183.5" className="stat4 stat5 stat6">600 h</text>
                    <text x="10" y="237.25" className="stat4 stat5 stat6">500 h</text>
                    <text x="10" y="291" className="stv4 stat5 stat6">400 h</text>
                    <text x="10" y="344.75" className="stat4 stat5 stat6">300 h</text>
                    <text x="10" y="398.5" className="stat4 stat5 stat6">200 h</text>
                    <text x="10" y="452.25" className="stat4 stat5 stat6">100 h</text>

                    <rect x="320" y="550" className="stat7" width="30" height="30"/>
                    <text x="360" y="569" className="stat4 stat5 stat8">ogrzewanie budynku</text>
                    <rect x="38" y="550" className="stat1" width="30" height="30"/>
                    <text x="78" y="569" className="stat4 stat5 stat8">ogrzewanie CWU</text>
                    <rect x="570" y="550" className="stat3" width="30" height="30"/>
                    <text x="610" y="569" className="stat4 stat5 stat8">chłodzenie budynku</text>

                    <text x="74.5" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[12][0] < 10 ? `0${ticksItem[12][0]}` : ticksItem[12][0]}</text>
                    <text x="74.5" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[12][1]}</text>
                    <text x="123.314" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[11][0] < 10 ? `0${ticksItem[11][0]}` : ticksItem[11][0]}</text>
                    <text x="123.314" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[11][1]}</text>
                    <text x="172.128" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[10][0] < 10 ? `0${ticksItem[10][0]}` : ticksItem[10][0]}</text>
                    <text x="172.128" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[10][1]}</text>
                    <text x="220.942" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[9][0] < 10 ? `0${ticksItem[9][0]}` : ticksItem[9][0]}</text>
                    <text x="220.942" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[9][1]}</text>
                    <text x="269.756" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[8][0] < 10 ? `0${ticksItem[8][0]}` : ticksItem[8][0]}</text>
                    <text x="269.756" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[8][1]}</text>
                    <text x="318.57" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[7][0] < 10 ? `0${ticksItem[7][0]}` : ticksItem[7][0]}</text>
                    <text x="318.57" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[7][1]}</text>
                    <text x="367.384" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[6][0] < 10 ? `0${ticksItem[6][0]}` : ticksItem[6][0]}</text>
                    <text x="367.384" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[6][1]}</text>
                    <text x="416.198" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[5][0] < 10 ? `0${ticksItem[5][0]}` : ticksItem[5][0]}</text>
                    <text x="416.198" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[5][1]}</text>
                    <text x="465.012" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[4][0] < 10 ? `0${ticksItem[4][0]}` : ticksItem[4][0]}</text>
                    <text x="465.012" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[4][1]}</text>
                    <text x="513.826" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[3][0] < 10 ? `0${ticksItem[3][0]}` : ticksItem[3][0]}</text>
                    <text x="513.826" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[3][1]}</text>
                    <text x="562.64" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[2][0] < 10 ? `0${ticksItem[2][0]}` : ticksItem[2][0]}</text>
                    <text x="562.64" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[2][1]}</text>
                    <text x="611.454" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[1][0] < 10 ? `0${ticksItem[1][0]}` : ticksItem[1][0]}</text>
                    <text x="611.454" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[1][1]}</text>
                    <text x="660.268" y="515" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[0][0] < 10 ? `0${ticksItem[0][0]}` : ticksItem[0][0]}</text>
                    <text x="660.268" y="530" textAnchor="middle" className="stat4 stat5 stat6">{ticksItem === null ? "--" : ticksItem[0][1]}</text>
                </svg>
            </div>
        </React.Fragment>
    )
}

export default Statistics
