import React from 'react'
import { TimeCWU, TimeHeating } from './pages'
import { Switch, Route, Link } from 'react-router-dom'


const TimeMode = ({ settings, setPublish, setTouchStatus, url }) => {

    return (
        <React.Fragment>
            <Switch>
                <Route path={`${url}/timemode/timecwu`}><TimeCWU setTouchStatus={setTouchStatus} settings={settings.cwu} setPublish={setPublish} url={url} /></Route>
                <Route path={`${url}/timemode/timeheating`}><TimeHeating setTouchStatus={setTouchStatus} settings={settings.air} setPublish={setPublish} url={url} /></Route>
            </Switch>
        </React.Fragment>
    )
}

export default TimeMode