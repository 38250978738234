import React, { useEffect, useState, useReducer } from 'react'
import { Typography, Grid, Switch, Dialog, DialogContent, IconButton, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { registerDictionary } from '../../../../../../data/dataDictionary'
import { dataRange } from '../../../../../../data/dataRange'
import CloseIcon from '@material-ui/icons/Close'
import { CarouselInputMulti } from '../../../../../../components'
import { ItemMapping } from '../../../../../../components'
import { mainStyleClasses } from '../../../../../../theme'

const StepGeo = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary().periphery.peakSource.step_geo
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: "" })

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }
    
    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    {ItemMapping(itemValue.map, handleSwitchValue, handleClickOpen, dictionary.data, classes, filteredItems(itemValue.map), 'map')}
                </Grid>
                {/*<Grid item xs={12}>
                    <Typography variant="h2">{dictionary.status.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {ItemMapping(itemValue.status, handleSwitchValue, handleClickOpen, dictionary.status.data, classes, filteredItems(itemValue.status), 'status')}
                </Grid>
                <Grid container className={classes.containerVerticalCenter}>
                    <Grid item xs={9}>
                        <Typography variant="body2">Obowiązująca taryfa energii elektrycznej</Typography> 
                    </Grid>
                    {itemValue.SXB_ECACT === 0 ? 
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                            <Typography variant="body2">drogi prąd</Typography>  
                        </Grid>
                        : <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                            <Typography variant="body2">tani prąd</Typography> 
                        </Grid>
                    }
                </Grid>*/}
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default StepGeo