import React, { useReducer, useState, useEffect } from 'react'
import { mainStyleClasses } from '../../theme'
import { Typography, Grid, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { registerDictionary } from '../../data/dataDictionary'
import { CarouselInputMulti, ItemMapping } from '../../components'
import { dataRange } from '../../data/dataRange'
import { SwitchControl } from '../../components'

// const switchOption = (state, action) => {
//     if (action.case === "UPDATE_FROM_BROKER") {
//         return (action.payload)
//     } else {
//         return { ...state, [action.type]: action.payload };
//     }
// }

const Outdoor = ({ settings, setPublish }) => {
    // const [outdoor, setOutdoor] = useState(settings)
    const [itemValue, setItemValue] = useState(settings)
    // const [itemValue, setItemValue] = useReducer(switchOption, outdoor.efficiency_regulation)
    const dictionary = registerDictionary().outdoor
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: ""  })
    const version_1 = parseFloat(sessionStorage.getItem('version_1')) 
    const version_2 = parseFloat(sessionStorage.getItem('version_2')) 

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    // const handleSwitchValue = name => (e, newValue) => {
    //     if (!newValue) {
    //         setOutdoor({ ...outdoor, [name]: 0 })
    //         setPublish({ [name]: 0 })
    //     } else {
    //         setOutdoor({ ...outdoor, [name]: 1 })
    //         setPublish({ [name]: 1 })
    //     }
    // }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const handleSwitchDefrost = (e) => {
        setItemValue({ ...itemValue,
                        defrost: {
                            ...itemValue,
                            XSCHT: 32000
                        }                  
        })
        setPublish({ XSCHT: 32000 })
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok], 
                                [open.name]: open.val 
                            }
            })
            setPublish({ [open.name]: open.val})
            setOpen({ ...open, status: false })
        }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }
    
    useEffect(() => {
        let mounted = true
        if(itemValue.efficiency_regulation.TNKOR > itemValue.efficiency_regulation.MAX_PERF) {
            // setOutdoor({ ...outdoor,
            //     [open.blok]: {
            //         ...outdoor[open.blok],
            //         TNKOR: outdoor.efficiency_regulation.MAX_PERF
            //     }
            // })
            setPublish({ TNKOR: itemValue.efficiency_regulation.MAX_PERF})
        }
        setItemValue(settings)
        // setOutdoor(settings)
        // return () => {
        //     mounted = false
        // }
    }, [settings])

    // const switchControl = (entry, item, blok) => {
    //     switch (dataRange()[entry[0]].controller) {
    //         case 1:
    //             return (
    //                     <Grid item xs={2} className={classes.textRight}>
    //                         <div>
    //                             <Switch
    //                                 checked={parseInt(itemValue[entry[0]]) === 0 ? false : true}
    //                                 onChange={handleSwitchValue(entry[0])}
    //                                 color="primary"
    //                                 name={entry[0]}
    //                                 inputProps={{ 'aria-label': 'primary checkbox' }}
    //                             />
    //                         </div>
    //                     </Grid>
    //             )
    //         case 2:
    //             let minValue = typeof (dataRange()[entry[0]].min) === "string" ? parseFloat(item[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
    //             let maxValue = dataRange()[entry[0]] === 'TNKOR' ? outdoor.MAX_PERF : typeof (dataRange()[entry[0]].max) === "string" ? parseFloat(item[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)
    //             return (
    //                 <Grid item xs={2} className={classes.textRight} onClick={() => handleClickOpen(entry[1], entry[0], dictionary[entry[0]], minValue, maxValue, dataRange()[entry[0]].step, entry[0], blok, entry[1])}>
    //                     <Typography variant="body2" className={"carousel-input-click"}>{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]} {dataRange()[entry[0]].symbol}</Typography>
    //                 </Grid>
    //             )
    //         default:
    //             break;
    //     }
    // }

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    const Ps =  itemValue.ref_system.ZM_PRES_EVAP + 1	
    const Pc = itemValue.ref_system.ZM_PRES_COND + 1	
   
    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid container style={{ paddingLeft: '24px'}}>
                <Grid item xs={12}>
                    <Typography variant="h2">Jednostka zewnętrzna - odczyt parametrów pracy</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} style={{ padding: '0 12px 0 24px' }}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.info.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.info, handleSwitchValue, handleClickOpen, dictionary.info.data, classes, filteredItems(itemValue.info), 'info')}
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">Układ chłodniczy</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Ciśnienie ssania</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{(itemValue.ref_system.ZM_PRES_EVAP).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Ciśnienie tłoczenia</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{(itemValue.ref_system.ZM_PRES_COND).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Otwarcie zaworu rozprężnego [%] (STP%)</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{(100 - itemValue.ref_system.ZM_EEV_OPEN / 5).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Temperatura parowania (Ts)</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{(-56.44768 + 11.14015*Ps - 0.699065*Ps*Ps + 0.02652225*Ps*Ps*Ps - 0.0004971089*Ps*Ps*Ps*Ps + 0.000003560177*Ps*Ps*Ps*Ps*Ps).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Temperatura skraplania (Tc)</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{(-56.44768 + 11.14015*Pc - 0.699065*Pc*Pc + 0.02652225*Pc*Pc*Pc - 0.0004971089*Pc*Pc*Pc*Pc + 0.000003560177*Pc*Pc*Pc*Pc*Pc).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Przegrzanie (SH)</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{(itemValue.ref_system.ZM_SUCT_TEMP - (-56.44768 + 11.14015*Ps - 0.699065*Ps*Ps + 0.02652225*Ps*Ps*Ps - 0.0004971089*Ps*Ps*Ps*Ps + 0.000003560177*Ps*Ps*Ps*Ps*Ps)).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Dochłodzenie (SC)</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body2">{((-56.44768 + 11.14015*Pc - 0.699065*Pc*Pc + 0.02652225*Pc*Pc*Pc - 0.0004971089*Pc*Pc*Pc*Pc + 0.000003560177*Pc*Pc*Pc*Pc*Pc) - itemValue.ref_system.TSM12).toFixed(1)}</Typography></Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">{dictionary.parameters.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.parameters, handleSwitchValue, handleClickOpen, dictionary.parameters.data, classes, filteredItems(itemValue.parameters), 'parameters')}
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">{dictionary.temp.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.temp, handleSwitchValue, handleClickOpen, dictionary.temp.data, classes, filteredItems(itemValue.temp), 'temp')}
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">{dictionary.control_registers.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.control_registers, handleSwitchValue, handleClickOpen, dictionary.control_registers.data, classes, filteredItems(itemValue.control_registers), 'control_registers')}
                    </Grid>
                </Grid>
                <Grid item xs={6} style={{ padding: '0 24px 0 12px' }}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.errors_outdoor.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.errors_outdoor, handleSwitchValue, handleClickOpen, dictionary.errors_outdoor.data, classes, filteredItems(itemValue.errors_outdoor), 'errors_outdoor')}
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">{dictionary.efficiency_regulation.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.efficiency_regulation, handleSwitchValue, handleClickOpen, dictionary.efficiency_regulation.data, classes, filteredItems(itemValue.efficiency_regulation), 'efficiency_regulation')}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.containerVerticalCenter}  style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">Wymuszenie odszraniania</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}>
                                <Switch
                                    checked={itemValue.defrost.XSCHT === 0 ? false : true}
                                    onChange={(e) => handleSwitchDefrost(e.target)}
                                    color="primary"
                                    name={'XSCHT'}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={((version_1 > 4) || (version_1 === 4 && version_2 >= 50)) && ((version_1 < 4) || (version_1 === 4 && version_2 < 91)) && itemValue.defrost.XSCHT > 30 ?
                                                false
                                                : ((version_1 > 4) || (version_1 === 4 && version_2 >= 91)) && itemValue.defrost.XSCHT > 1800 ? 
                                                    false
                                                    : true
                                    }
                                />
                            </Grid>                         
                        </Grid>
                    </Grid>
                    <Grid item xs={12}> 
                        <Grid container className={classes.containerVerticalCenter}  style={{ paddingRight: '24px' }}> 
                            <Grid item xs={10}><Typography variant="body2">Timer defrostu [minuty]</Typography></Grid> 
                            <Grid item xs={2} className={classes.textRight}> 
                                <Typography variant="body2">
                                {((version_1 > 4) || (version_1 === 4 && version_2 >= 50)) && ((version_1 < 4) || (version_1 === 4 && version_2 < 91)) ?
                                        itemValue.defrost.XSCHT 
                                        : (version_1 > 4) || (version_1 === 4 && version_2 >= 91) ? 
                                            (itemValue.defrost.XSCHT / 60).toFixed(1) 
                                            : "N/A" 
                                    }
                                </Typography>
                            </Grid>                          
                        </Grid> 
                    </Grid> 
                    <Grid item>
                        <Typography variant="h2">{dictionary.errors_registers.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {ItemMapping(itemValue.errors_registers, handleSwitchValue, handleClickOpen, dictionary.errors_registers.data, classes, filteredItems(itemValue.errors_registers), 'errors_registers')}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step}/>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Outdoor