export const deviceType = () => {

    const deviceName = sessionStorage.getItem('deviceName')
	const version_1 = parseInt(sessionStorage.getItem('version_1'))
	const version_2 = parseInt(sessionStorage.getItem('version_2'))
    
    let deviceList = [
       "nieznany",
       "EUROS GEO 6 TH",
       "EUROS GEO 8 TH",
       "EUROS GEO 11 TH",
       "EUROS GEO 14 TH",
       "EUROS GEO 18 TH",
       "EUROS GEO AC 5 TR",
       "EUROS GEO AC 7 TR",
       "EUROS GEO AC 10 TR",
       "EUROS GEO AC 13 TR",
       "EUROS GEO AC 17 TR",
       "EUROS GEO ECO 8 TH",
       "EUROS GEO ECO 11 TH",
       "EUROS GEO ECO 13 TH",
       "EUROS GEO ECO 17 TH",
       "EUROS ATMO 11",
       "FOTON TECHNIK AIR 11",
       "EUROS ATMO 19",
       "FOTON TECHNIK AIR 19",
    ]
    
    switch (true) {
        case (version_1 > 870) || (version_1 === 870 && version_2 >= 10):
            deviceList = [...deviceList,
                "EUROS ATMO 7",
                "FOTON TECHNIK AIR 7",
                "E.ON FOTON AIR 7",
                "E.ON FOTON AIR 11",
                "E.ON FOTON AIR 19",
                "E.ON FOTON AIR AIO",
                "EUROS HYDRO 200 AIO",
                "EUROS GEO 11 SH",
                "EUROS GEO 14 SH",
                "EUROS GEO 18 SH",
                "EUROS GEO 11 AC",
                "EUROS GEO 14 AC",
                "EUROS GEO 18 AC",
                "EUROS GEO 6 SH",
                "EUROS GEO 8 SH",
                "EUROS GEO 6 AC",
                "EUROS GEO 8 AC",
                "EURSO ATMO 15",
                "EUROS GEO+ 09HT",
                "EUROS GEO+ 11HT",
            ]
            break
        case deviceName === 'GEO_1':
            deviceList = [...deviceList,
                "EUROS GEO ECO 11 SH",
                "EUROS GEO ECO 14 SH",
                "EUROS GEO ECO 18 SH",
                "EUROS GEO ECO 11 AC",
                "EUROS GEO ECO 14 AC",
                "EUROS GEO ECO 18 AC",
            ]	
            break
        case deviceName === 'ATMO_1': 
                deviceList = [...deviceList,
                  "EUROS ATMO 7",
                  "FOTON TECHNIK AIR 7",
                  "E.ON FOTON AIR 7",
                  "E.ON FOTON AIR 11",
                  "E.ON FOTON AIR 19",
                  "E.ON FOTON AIR AIO",
                  "EUROS HYDRO 200 AIO",
                ] 
                switch (true) {
                    case (version_1 > 4) || (version_1 === 4 && version_2 >= 94):
                        deviceList = [...deviceList,
                           "EUROS GEO 11 SH",
                           "EUROS GEO 14 SH",
                           "EUROS GEO 18 SH",
                           "EUROS GEO 11 AC",
                           "EUROS GEO 14 AC",
                           "EUROS GEO 18 AC",
                           "EUROS GEO 6 SH",
                           "EUROS GEO 8 SH",
                           "EUROS GEO 6 AC",
                           "EUROS GEO 8 AC",
                           "EURSO ATMO 15",
                           "EUROS GEO+ 09HT",
                           "EUROS GEO+ 11HT",
                        ]
                    break
                default:
                    break
            }
            break
        case deviceName === 'ALLINONE_1': 
            deviceList = [...deviceList,
                "EUROS ATMO 7",
                "FOTON TECHNIK AIR 7",
                "E.ON FOTON AIR 7",
                "E.ON FOTON AIR 11",
                "E.ON FOTON AIR 19",
                "E.ON FOTON AIR AIO",
                "EUROS HYDRO 200 AIO",
            ] 
            switch (true) {
                case (version_1 > 4) || (version_1 === 4 && version_2 >= 94):
                    deviceList = [...deviceList,
                        "EUROS GEO 11 SH",
                        "EUROS GEO 14 SH",
                        "EUROS GEO 18 SH",
                        "EUROS GEO 11 AC",
                        "EUROS GEO 14 AC",
                        "EUROS GEO 18 AC",
                        "EUROS GEO 6 SH",
                        "EUROS GEO 8 SH",
                        "EUROS GEO 6 AC",
                        "EUROS GEO 8 AC",
                        "EURSO ATMO 15",
                        "EUROS GEO+ 09HT",
                        "EUROS GEO+ 11HT",
                    ]
                break
            default:
                break
        }
        break
    default:
        break
    }    

    return deviceList
}