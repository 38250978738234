import fetchRefreshToken from './fetchRefreshToken'

const fetchInspection = async (action, device, payload, inspection) => {

  const response = await fetchRefreshToken().then(async newToken => {
    switch (action) {
      case "GET":
        const resGet = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/device/${device}/inspection`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${newToken}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
        return resGet

      case "POST":
        const resPost = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/device/${device}/inspection`, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${newToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
          .then(response => response.json())
        return resPost

      case "PUT":
        const resPut = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/device/${device}/inspection`, {
          method: "PUT",
          headers: {
            "Authorization": `Bearer ${newToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)

        })
          .then(response => response.json())
        return resPut

      case "DELETE":
        const resDelete = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/device/${device}/inspection/${inspection}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${newToken}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
        return resDelete

      default:
        break;
    }

  })

  return response
}

export default fetchInspection