import React, { useReducer, useEffect, useState } from 'react'
import clsx from 'clsx';
import { mainStyleClasses } from '../../theme'
import {
    Redirect
} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    Grid, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, TableFooter, Paper, Button
} from '@material-ui/core'
import { TablePaginationActions } from '../../components'
import { FilterDrawer } from './component'
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import fetchConfigDevices from '../../data/fetchConfigDevices'
import fetchDeviceOnlineDate from '../../data/fetchDeviceOnlineDate';

import { filtersandsort } from '../../functions'

const drawerWidth = 280;

const deviceListReducer = (state, action) => {
    switch (action.type) {
        case "DEVICE_LIST":
            return {
                ...state,
                devices: action.payload
            }

        case "LOAD":
            return {
                ...state,
                load: action.payload
            }

        default:
            return state
    }

}

const DeviceList = ({ token, perm, subLogin, role, setFilterDevice, filterDevice, accessScada }) => {
    const [deviceList, setDeviceList] = useReducer(deviceListReducer,
        { devices: null, load: false })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const classes = mainStyleClasses({ drawerWidth: drawerWidth });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    useEffect(() => {
        let mounted = true
        if (token && deviceList.load !== true) {
            setDeviceList({ type: "LOAD", payload: true })
            fetchConfigDevices()
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error(response.status)
                    }
                })
                .then(response => { setDeviceList({ type: "DEVICE_LIST", payload: response.data }) })
                .catch(error => {
                    console.log(error)
                })
        }


        return function cleanup() {
            mounted = false
        }
    })



    const handleRefreshDevice = (e, sn) => {
        fetchDeviceOnlineDate(sn).then(response => response.json()).then(res => {
            if (res.status === "ok") {
                let newList = deviceList.devices.map(item => {
                    if (item.device_sn === sn) {
                        console.log({przed: item.last_telemetry_update})
                        console.log({po: res.data.last_telemetry_update})
                        item.last_telemetry_update = res.data.last_telemetry_update
                        return item
                    } else {
                        return item
                    }
                })

                setDeviceList({ type: "DEVICE_LIST", payload: newList })
            } else {
                console.log(res.status)
            }
        })
    };

    let filteredList
    if (deviceList.devices && filterDevice.filters && token) {
        let sortedList = deviceList.devices;
        if (filterDevice.sort) {
            sortedList = filtersandsort.sortedInstallationList(deviceList.devices, filterDevice.sort)
        }
        filteredList = filtersandsort.filteredInstallationList(sortedList, filterDevice.filters, token, perm, subLogin, role, handleRefreshDevice, accessScada)
    }

    const [open, setOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleResetFilters = () => {
        setFilterDevice({
            type: "FILTERS", payload: {
                typeFilter: false, typeValue: "", SNFilter: false, SNValue: "", keyFilter: false, keyValue: "",
                onlineFilter: false, onlineValue: "", onlineFrom: "", onlineTo: "", startFilter: false, startValue: "",
                startFrom: "", startTo: "", customerFilter: false, customerValue: "", location: false, locationValue: "",
                registrationFilter: false, registrationValue: "", partnerFilter: false, partnerValue: ""
            }
        })
    };

    return (
        <React.Fragment>

            <FilterDrawer
                accessScada={accessScada ? accessScada.partner_id : null}
                open={open}
                handleDrawerClose={handleDrawerClose}
                handleResetFilters={handleResetFilters}
                drawerWidth={drawerWidth}
                deviceSnValue={filterDevice.filters.SNFilter === true ? filterDevice.filters.SNValue : ""}
                deviceKeyValue={filterDevice.filters.keyFilter === true ? filterDevice.filters.keyValue : ""}
                customerNameValue={filterDevice.filters.customerFilter === true ? filterDevice.filters.customerValue : ""}
                locationValue={filterDevice.filters.locationFilter === true ? filterDevice.filters.locationValue : ""}
                typeValue={filterDevice.filters.typeFilter === true ? filterDevice.filters.typeValue : "WSZYSTKIE"}
                onlineValue={filterDevice.filters.onlineFilter === true ? filterDevice.filters.onlineValue : "WSZYSTKIE"}
                onlineFromValue={filterDevice.filters.onlineFrom}
                onlineToValue={filterDevice.filters.onlineTo}
                registrationValue={filterDevice.filters.registrationFilter === true ? filterDevice.filters.registrationValue : "WSZYSTKIE"}
                telemetryValue={filterDevice.filters.startFilter === true ? filterDevice.filters.startValue : "WSZYSTKIE"}
                telemetryFromValue={filterDevice.filters.startFrom}
                telemetryToValue={filterDevice.filters.startTo}
                partnerValue={filterDevice.filters.partnerFilter === true ? filterDevice.filters.partnerValue : "WSZYSTKIE"}
                setFilterDevice={setFilterDevice}
                filterDevice={filterDevice}
                setPage={setPage}
            />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Grid container direction="row">
                    <Grid item xs={10}>
                        <Typography variant="h2">Lista urządzeń</Typography>
                    </Grid>
                    <Grid container xs={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={4}> </Grid>
                        <Grid item xs={8}>
                            <Button variant="contained" color="primary" startIcon={<FilterListIcon />} onClick={handleDrawerToggle}>
                                Filtry
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                {!token ? <Redirect to="/" /> : null}
                {deviceList.devices && filterDevice ? (
                    <React.Fragment>
                        <Grid container direction="row" >
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography component="div" height="100%">
                                                    <Box fontWeight="fontWeightBold" height="100%">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={11}>
                                                                Lp
                                                            </Grid>
                                                            <Grid xs={1}>
                                                                <br />
                                                                <br />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={8}>
                                                                Typ
                                                            </Grid>
                                                            <Grid xs={4}>
                                                                {filterDevice.sort.sortColumn === "deviceType" && filterDevice.sort.direction === "desc" ?
                                                                    <IconButton onClick={filtersandsort.sortInstallationList("deviceType", setFilterDevice, filterDevice)}>
                                                                        <ArrowDownwardIcon className={clsx(classes.activeIcon)} />
                                                                    </IconButton> :
                                                                    filterDevice.sort.sortColumn === "deviceType" && filterDevice.sort.direction === "asc" ?
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("deviceType", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.activeIcon)} />
                                                                        </IconButton> :
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("deviceType", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.icon)} />
                                                                        </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={11}>
                                                                Nr seryjny
                                                            </Grid>
                                                            <Grid xs={1}>
                                                                <br />
                                                                <br />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={8}>
                                                                Klucz
                                                            </Grid>
                                                            <Grid xs={4}>
                                                                {filterDevice.sort.sortColumn === "telemetryUpdate" && filterDevice.sort.direction === "desc" ?
                                                                    <IconButton onClick={filtersandsort.sortInstallationList("telemetryUpdate", setFilterDevice, filterDevice)}>
                                                                        <ArrowDownwardIcon className={clsx(classes.activeIcon)} />
                                                                    </IconButton> :
                                                                    filterDevice.sort.sortColumn === "telemetryUpdate" && filterDevice.sort.direction === "asc" ?
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("telemetryUpdate", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.activeIcon)} />
                                                                        </IconButton> :
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("telemetryUpdate", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.icon)} />
                                                                        </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={8}>
                                                                Start telemetrii
                                                            </Grid>
                                                            <Grid xs={4}>
                                                                {filterDevice.sort.sortColumn === "telemetryStart" && filterDevice.sort.direction === "desc" ?
                                                                    <IconButton onClick={filtersandsort.sortInstallationList("telemetryStart", setFilterDevice, filterDevice)}>
                                                                        <ArrowDownwardIcon className={clsx(classes.activeIcon)} />
                                                                    </IconButton> :
                                                                    filterDevice.sort.sortColumn === "telemetryStart" && filterDevice.sort.direction === "asc" ?
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("telemetryStart", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.activeIcon)} />
                                                                        </IconButton> :
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("telemetryStart", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.icon)} />
                                                                        </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={8}>
                                                                Klient
                                                            </Grid>
                                                            <Grid xs={4}>
                                                                {filterDevice.sort.sortColumn === "customerName" && filterDevice.sort.direction === "desc" ?
                                                                    <IconButton onClick={filtersandsort.sortInstallationList("customerName", setFilterDevice, filterDevice)}>
                                                                        <ArrowDownwardIcon className={clsx(classes.activeIcon)} />
                                                                    </IconButton> :
                                                                    filterDevice.sort.sortColumn === "customerName" && filterDevice.sort.direction === "asc" ?
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("customerName", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.activeIcon)} />
                                                                        </IconButton> :
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("customerName", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.icon)} />
                                                                        </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid xs={8}>
                                                                Lokalizacja
                                                            </Grid>
                                                            <Grid xs={4}>
                                                                {filterDevice.sort.sortColumn === "customerCity" && filterDevice.sort.direction === "desc" ?
                                                                    <IconButton onClick={filtersandsort.sortInstallationList("customerCity", setFilterDevice, filterDevice)}>
                                                                        <ArrowDownwardIcon className={clsx(classes.activeIcon)} />
                                                                    </IconButton> :
                                                                    filterDevice.sort.sortColumn === "customerCity" && filterDevice.sort.direction === "asc" ?
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("customerCity", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.activeIcon)} />
                                                                        </IconButton> :
                                                                        <IconButton onClick={filtersandsort.sortInstallationList("customerCity", setFilterDevice, filterDevice)}>
                                                                            <ArrowUpwardIcon className={clsx(classes.icon)} />
                                                                        </IconButton>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            {accessScada && role === "partner" && accessScada.partner_id !== 1 ? null 
                                                : <React.Fragment>
                                                    <TableCell>
                                                        <Box fontWeight="fontWeightBold">
                                                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                                <Grid xs={8} style={{padding: '8px'}}>
                                                                    Partner
                                                                </Grid>
                                                                <Grid xs={4}></Grid>
                                                            </Grid>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                </React.Fragment>
                                            }
                                            <TableCell>
                                                <p></p>
                                            </TableCell>
                                            <TableCell>
                                                <p></p>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : filteredList
                                        ).map((row, index) => (
                                            <React.Fragment key={index}>{row}</React.Fragment>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            {<TablePagination
                                                rowsPerPageOptions={[15, 30, 50, { label: 'All', value: -1 }]}
                                                colSpan={8}
                                                count={filteredList.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'Ilość wyników' },
                                                    native: true,
                                                }}
                                                label={`Ilość wyników`}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </React.Fragment>)
                    : <CircularProgress />}
            </Grid>
        </React.Fragment>
    )
}

export default DeviceList