import fetchRefreshToken from './fetchRefreshToken'

const fetchAddDevice = async (token, payload) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/devices/add?dev_type=${payload.dev_type}&device_sn=${payload.device_sn}&partner_id=${payload.partner_id}&distribution_id=${payload.distribution_id}`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${newToken}`
        }
      })
      return res
    })
    
    return response
  }


  export default fetchAddDevice