import React, { useEffect } from 'react'
import { defaultData } from '../../functions'

const DefaultValueSet = ({ defaultValue, setDefault, settings, setPublish }) => {

useEffect(() => {
    let mounted = true
    if(!defaultValue){
        setDefault(true)
        defaultData(settings, setPublish)
    }
    return () => {
        mounted = false
    }
}, [defaultValue, setDefault, setPublish])

    return (
        <React.Fragment>
            
        </React.Fragment>
    )
}

export default DefaultValueSet