import React, { useEffect, useState } from 'react'
import { Typography, Grid, Switch, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { registerDictionary } from '../../../../data/dataDictionary'
import { dataRange } from '../../../../data/dataRange'

const Circuits = ({ settings, setPublish }) => {
    const [circuits, setCircuits] = useState(settings)
    const dict = registerDictionary().periphery.circuits

    const handleSwitchValue = (name, section) => (e, newValue) => {
       setCircuits({
           ...circuits,
           settings: {
               ...circuits['settings'],
               [name]: newValue
           } 
       })

       setPublish({[name] : newValue})
    }

    const handleOnClick = () => {
        if(circuits.settings.SWEXT_DCP === 1){
            setCircuits({
                ...circuits,
           settings: {
               ...circuits['settings'],
               SWEXT_DCP: 0
           } 
            })
            setPublish({SWEXT_DCP : 0})
        } else {
            setCircuits({
                ...circuits,
           settings: {
               ...circuits['settings'],
               SWEXT_DCP: 1
           } 
            })
            setPublish({SWEXT_DCP : 1})
        }
    }

    useEffect(() => {
        let mounted = true
        setCircuits(settings)
        return () => {
            mounted = false
        }
    }, [settings])
   
    const filteredItems = Object.entries(settings ? settings.status : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Konfiguracja obiegów odbiorczych ogrzewania i chłodzenia</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h2">Status</Typography>
                    {filteredItems.map((entry, index) => {
                        return (
                            <Grid container key={entry[0]} style={{ backgroundColor: index % 2 === 1 ? '#F8F8F8' : null }}>
                                <Grid item xs={10}>
                                    <Typography variant="body2">{dict[entry[0]]}</Typography> 
                                </Grid>
                                <Grid item xs={2}>{entry[1] === 1 ? <FiberManualRecord style={{ color: "green" }} /> : <FiberManualRecord style={{ color: "red" }} />}</Grid>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h2">Ustawienia</Typography>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="body2">{dict.RGRCH}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2">{settings.settings.RGRCH}</Typography>
                        </Grid>
                    </Grid>
                    {/*<Grid container>
                        <FormControl component="fieldset">
                            <RadioGroup name="RGRCH" value={0 <= parseInt(circuits.settings.RGRCH) <= 3 ? 0 : parseInt(circuits.settings.RGRCH) } onChange={handleSwitchValue("RGRCH")}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={0} control={<Radio color="primary" />} label="Praca obiegów niezależna"/>
                                        </label>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>*/}
                    <Grid container>
                        <FormControl component="fieldset">
                            <RadioGroup name="RGRCH" value={parseInt(circuits.settings.RGRCH)} onChange={handleSwitchValue("RGRCH")}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={0} control={<Radio color="primary" />} label="Praca obiegów niezależna"/>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={1} control={<Radio color="primary" />} label="Zawsze obieg grzania z obiegiem chłodzenia"/>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={2} control={<Radio color="primary" />}  label="Zawsze obieg chłodzenia z obiegiem grzania"/>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={3} control={<Radio color="primary"/>}  label="Praca obiegów rownoległa" />
                                        </label>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="body2">{dict.SWEXT_DCP}</Typography>
                        </Grid>
                        <Grid item xs={4} onClick={handleOnClick}><Button variant="contained" color="primary" style={{ margin: "5px 20px" }}>{settings.settings.SWEXT_DCP === 1 ? "Komfortowy" : "Ekonomiczny"}</Button></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Circuits