import React from 'react'
import {
    Button,
    MenuItem,
    Grid,
    Typography,
    Dialog,
    DialogContent, Paper, IconButton, Checkbox, FormGroup, FormLabel, FormControlLabel
} from '@material-ui/core'

const TypesParts = ({ handleCloseType, handleSetParts, partsList, chceckPart }) => {
    return (
        <React.Fragment>
            <Grid container>
                <FormLabel component="legend">Możesz zaznaczyć dowolną liczbę</FormLabel>
                
                    {partsList? partsList.map(part => <Grid item xs={6} key={part.name.replaceAll(' ', '-')}><FormControlLabel 
                        control={<Checkbox checked={chceckPart.find(item => item.name === part.name)? true : false} name={part.name.replaceAll(' ', '-')} onChange={() => handleSetParts({part})} color="primary" />}
                        label={part.name}
                    /></Grid>) : null}
                <Grid item xs={12}>
                <Button style={{ marginTop: "30px" }} variant="contained" color="primary"
                onClick={handleCloseType}>Zapisz</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default TypesParts