import mqtt from 'mqtt'

const getClient = (options) => {
    const mqttClient = mqtt.connect(options)
    mqttClient.on('connect', function () {
        console.info('Mqtt client - connected')
    })

    mqttClient.on('error', function (Error) {

        console.error("Mqtt client error " + Error)
    })

    return mqttClient
}

const closeConnection = (client) => {
    client.end = function () {
        console.log('echo-protocol Client Closed');
    };
}

export const mqttClient = {
    getClient,
    closeConnection,
}