import React, { useRef, useState, useEffect, useReducer } from 'react'
import { Container, Grid, Typography, Box,  } from '@material-ui/core'
import { mqttConfig } from '../../../../config/mqttConfig'
import { mqttClient } from '../../../../config/mqttClient'
import { registerValue } from '../../../../data/dataFilter'
import alarm from '../../../../img/scada/alarm.svg'
import cyrkulacja from '../../../../img/scada/cyrkulacja.svg'
import grzanie from '../../../../img/scada/grzanie.svg'
import grzanie_chlodzenie from '../../../../img/scada/grzanie_chlodzenie.svg'
import chlodzenie from '../../../../img/scada/chlodzenie.svg'
import grz_chl_off from '../../../../img/scada/grz_chl_off.svg'
import off from '../../../../img/scada/off.svg'
import time from '../../../../img/scada/time.svg'
import eco from '../../../../img/scada/eco.svg'
import away from '../../../../img/scada/away.svg'
import auto from '../../../../img/scada/auto.svg'
import cwu from '../../../../img/scada/cwu.svg'
import cwu_a_legionella from '../../../../img/scada/cwu_a_legionella.svg'
import cwu_off from '../../../../img/scada/cwu_off.svg'
import cwu_turbo from '../../../../img/scada/cwu_turbo.svg'
import hl from '../../../../img/scada/hl.svg'
import fetchTelelerepeater from '../../../../data/fetchTelerepeater'

const Scada = ({ installation, address, client, telemetry, sn, phone, street, lastUpdate }) => {
    const clientRef = useRef(null)
    const [messages, setMessages] = useState(null)
    const [connectionStatus, setConnectionStatus] = useState(false)

    useEffect(() => {
        console.log(installation)
        clientRef.current = mqttClient.getClient(mqttConfig())
        clientRef.current.subscribe(`${installation}/BasicTelemetry`)
        clientRef.current.on('connect', () => setConnectionStatus(true))
        clientRef.current.on('message', function (topic, message, packet) {

            if (topic === `${installation}/BasicTelemetry` && JSON.parse(message).Devices) {
                let saveToState = registerValue(JSON.parse(message).Devices)
                saveToState.expirationData = {
                    ExpirationTime: JSON.parse(message).ExpirationTime,
                    TimeStamp: JSON.parse(message).TimeStamp
                }
                setMessages(saveToState)
                console.log(saveToState)
            }
        })
        return () => {
            if (clientRef.current) {
                clientRef.current.unsubscribe(`${installation}/BasicTelemetry`)
                clientRef.current.end(clientRef.current)
            }
        }
    }, [])

    let telerepeaterInterval

    const telerepeater = () => {
        fetchTelelerepeater(installation)
    }

    useEffect(() => {
        let mounted = true
        fetchTelelerepeater(installation)
        telerepeaterInterval = setInterval(telerepeater, 60000)
        return function cleanup() {
            mounted = false
            clearInterval(telerepeaterInterval)
        }
    }, [])
    console.log(messages)
    return (
        <React.Fragment>
            <Grid container className='infobox'>
                <Grid item xs={4}>
                    <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "5px 0" }}>{sn}</Typography>
                    <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "5px 0" }}>{client}</Typography>
                    <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "5px 0" }}>{phone}</Typography>

                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "5px 0" }}>{installation}</Typography>
                    <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "5px 0" }}>ul. {street}</Typography>
                    <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "5px 0" }}>{address}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h3" className='text-scada' style={{ color: `${lastUpdate.includes('Yellow')? '#FFFFC0' : lastUpdate.includes('Green')? '#C0FFC0' : '#FFC0C0'}`, margin: "5px 0" }}>Ostatnio online: </Typography>
                    <Typography variant="h3" className='text-scada' style={{ color: `${lastUpdate.includes('Yellow')? '#FFFFC0' : lastUpdate.includes('Green')? '#C0FFC0' : '#FFC0C0'}`, margin: "5px 0" }}>{telemetry}</Typography>
                </Grid>
            </Grid>
            <Grid container className='apla-scada'>
                <Grid item xs={2}></Grid>
                <Grid item xs={8} style={{ border: "1px solid #fff", borderRadius: "12px", padding: "0 50px" }}>
                    {messages && (messages.device.Name === "ATMO_1" || messages.device.Name === "ALLINONE_1") ?
                        <svg version="1.1" x="0px" y="0px"
                            viewBox="0 0 762.3 498.5" style={{ margin: "40px 0" }}>
                            <g className='atmo'>
                            <g id="Layer_1">
                                <rect x="430.3" y="222.4" className="st0" width="17.6" height="4.4" />
                                <path className={`st1 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                    messages.scada.SBB_QHT === 1 ? `red`
                                        : messages.scada.SBB_QHL === 1 ? `blue`
                                            : `red`
                                    : `red`}`} d="M439,226.8v19.4c0,3.5-2.9,6.4-6.4,6.4H360" />
                                <path className={`st2 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                    messages.scada.SBB_QHT === 1 ? `red`
                                        : messages.scada.SBB_QHL === 1 ? `blue`
                                            : `red`
                                    : `red`}`} d="M338.6,53.3h94.6c3.3,0,5.9,2.6,5.9,5.9V80" />
                                <path className="st3" d="M358.7,250.3v4.8c0,0.3-0.2,0.5-0.5,0.5h-17.1c-0.3,0-0.5-0.2-0.5-0.5v-4.8c0-0.3,0.2-0.5,0.5-0.5h17.1
                        C358.5,249.8,358.7,250,358.7,250.3z"/>
                                <g>
                                    <g>
                                        <path className="st44" d="M340.6,249.3v6.7c0,0.3-0.2,0.5-0.5,0.5h-0.5c-0.3,0-0.5-0.2-0.5-0.5v-6.7c0-0.3,0.2-0.5,0.5-0.5h0.5
                        C340.4,248.8,340.6,249.1,340.6,249.3z"/>
                                    </g>
                                    <g>
                                        <path className="st44" d="M358.7,256v-6.7c0-0.3,0.2-0.5,0.5-0.5h0.5c0.3,0,0.5,0.2,0.5,0.5v6.7c0,0.3-0.2,0.5-0.5,0.5h-0.5
                        C358.9,256.5,358.7,256.3,358.7,256z"/>
                                    </g>
                                </g>
                                <g>
                                    <rect x="338.5" y="50.5" className="st3" width="6.4" height="5.8" />
                                    <g>
                                        <rect x="344.9" y="49.5" className="st44" width="1.4" height="7.7" />
                                    </g>
                                </g>
                                <path className="st44" d="M353.2,249.8v5.8c0,0.3-0.2,0.5-0.5,0.5h-6.2c-0.3,0-0.5-0.2-0.5-0.5v-5.8c0-0.3,0.2-0.5,0.5-0.5h6.2
                        C353,249.3,353.2,249.5,353.2,249.8z"/>
                                <path className="st3" d="M352.3,252.6L352.3,252.6c0-0.3-0.2-0.5-0.5-0.5h-4.3c-0.3,0-0.5,0.2-0.5,0.5l0,0c0,0.3,0.2,0.5,0.5,0.5h4.3
                        C352.1,253.1,352.3,252.9,352.3,252.6z"/>
                                <path className="st55" d="M431.6,222.4h14.9c7.8,0,14.1-6.3,14.1-14.1V94.2c0-7.8-6.3-14.1-14.1-14.1h-14.9c-7.8,0-14.1,6.3-14.1,14.1
                        v114.1C417.5,216.1,423.8,222.4,431.6,222.4z"/>
                                <path className="st66" d="M456.3,94.2v114.4c0,5.3-4.3,9.5-9.5,9.5h-15.6c-5.3,0-9.5-4.3-9.5-9.5V94.2c0-5.3,4.3-9.5,9.5-9.5h15.6
                        C452.1,84.7,456.3,88.9,456.3,94.2z"/>
                                <path className="st77" d="M456.3,178.3v30.3c0,5.3-4.3,9.5-9.5,9.5h-15.6c-5.3,0-9.5-4.3-9.5-9.5v-30.3H456.3z" />
                                <g>
                                    <path className="st77" d="M441,126.1c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5S441,125.3,441,126.1z" />
                                    <path className="st77" d="M423.1,122.2c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3C423.7,120.8,423.1,121.4,423.1,122.2z"
                                    />
                                    <path className="st77" d="M436.1,141.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9C436.5,140.9,436.1,141.3,436.1,141.8z"
                                    />
                                    <path className="st77" d="M437.8,164.9c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6C438.1,164.3,437.8,164.6,437.8,164.9z"
                                    />
                                    <path className="st77" d="M449.5,152.8c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6S449.5,151.9,449.5,152.8z" />
                                    <path className="st77" d="M450.3,138.6c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2S450.3,138,450.3,138.6z" />
                                    <path className="st77" d="M423.1,140.3c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7
                            C423.9,138.6,423.1,139.4,423.1,140.3z"/>
                                    <path className="st77" d="M423.1,153.2c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3C423.7,151.8,423.1,152.4,423.1,153.2z"
                                    />
                                </g>
                                <path className="st77" d="M435.9,126.1c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5S435.9,126.9,435.9,126.1z" />
                                <path className="st77" d="M453.8,130.1c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3C453.2,131.4,453.8,130.8,453.8,130.1z"
                                />
                                <path className="st77" d="M440.9,110.4c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9S440.9,110.9,440.9,110.4z" />
                                <path className="st77" d="M437.6,102.9c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6S437.6,103.2,437.6,102.9z" />
                                <path className="st77" d="M427.5,99.4c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6C426.7,101,427.5,100.3,427.5,99.4z" />
                                <path className="st77" d="M426.7,113.6c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2S426.7,114.3,426.7,113.6z" />
                                <path className="st77" d="M453.8,111.9c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7S453.8,112.9,453.8,111.9z"
                                />
                                <path className="st77" d="M453.8,99c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3C453.2,100.4,453.8,99.8,453.8,99z" />
                                <g>
                                    <rect x="293.8" y="50.3" className="st3" width="6.4" height="5.8" />
                                    <g>
                                        <rect x="292.3" y="49.3" className="st44" width="1.4" height="7.7" />
                                    </g>
                                </g>
                                <circle className="st8" cx="319.4" cy="53.2" r="20.2" />
                                <circle className="st9" cx="319.4" cy="53.2" r="15.9" />
                                <circle className="st8" cx="314.1" cy="45.2" r="5.3" />
                                <g>
                                    <path className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} d="M417.3,155.4c-3.5,0-6.4,2.8-6.4,6.4c0,3.5,2.8,6.4,6.4,6.4h42.9c3.5,0,6.4,2.8,6.4,6.4
                            c0,3.5-2.8,6.4-6.4,6.4"/>
                                    <path className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} d="M417.3,130c-3.5,0-6.4,2.8-6.4,6.4c0,3.5,2.8,6.4,6.4,6.4h42.9c3.5,0,6.4,2.8,6.4,6.4c0,3.5-2.8,6.4-6.4,6.4"/>
                                    <path className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} d="M417.3,104.5c-3.5,0-6.4,2.8-6.4,6.4c0,3.5,2.8,6.4,6.4,6.4h42.9c3.5,0,6.4,2.8,6.4,6.4
                            c0,3.5-2.8,6.4-6.4,6.4"/>
                                    <line className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} x1="680" y1="104.5" x2="460.3" y2="104.5" />
                                    <path className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} d="M684.7,193.5H502.4h-84.9c-3.8,0-7-2.6-7.2-6c-0.2-3.6,3-6.7,7-6.7" />
                                    {parseInt(messages.scada.SIO_R08) === 1 ? <circle className={`st11 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} cx="528" cy="104.5" r="21.7" /> : null}
                                    <polygon className={`st11 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} points="675,104.6 675,97.3 681.3,101 687.6,104.6 681.3,108.3 675,112 		" />
                                    <polygon className={`st11 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} points="687.6,193.5 687.6,200.8 681.3,197.2 675,193.5 681.3,189.8 687.6,186.2 		" />
                                </g>
                                <path className={`st12 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                    messages.scada.SBB_QHT === 1 ? `blue`
                                        : messages.scada.SBB_QHL === 1 ? `red`
                                            : `blue`
                                    : `blue`}`} d="M292.3,53.2h-18.5c-3.3,0-5.9,2.6-5.9,5.9v18.6c0,4.9-4,8.9-8.9,8.9h-78.2c-5,0-9,4-9,9l0,0c0,5,4,9,9,9h79.4
                            c5,0,9,4,9,9l0,0c0,5-4,9-9,9H195c-5,0-9,4-9,9l0,0c0,5,4,9,9,9h74.4c5,0,9,4,9,9l0,0c0,5-4,9-9,9h-72.7c-5,0-9,4-9,9l0,0
                            c0,5,4,9,9,9h63.6c5,0,9,4,9,9l0,0c0,5-4,9-9,9h-79.5c-5,0-9,4-9,9l0,0c0,5,4,9,9,9h81.3c3.3,0,5.9,2.7,5.9,5.9v27.6
                            c0,3.5,2.9,6.4,6.4,6.4h64.9"/>
                                <g>
                                    <path className="st13" d="M56.2,172.6c9.7-7.6,21.3-12.2,33.4-13.6c0.7,1.4,1.5,2.8,2.5,4l-0.6,0.5c-4.4,3.4-8,7.6-10.7,12.4
                                c-2.7,4.8-4.5,10-5.1,15.5c-0.3,2-1.3,3.9-2.9,5.1c-3.4,2.6-8.2,2-10.8-1.3l-7.3-9.4C51.3,181.8,52.1,175.9,56.2,172.6z"/>
                                    <path className="st13" d="M55.5,128.3c12.2,1.5,23.7,6.4,33.3,14.1c-0.5,1.5-0.9,3-1.1,4.6l-0.8-0.1c-11-1.4-22.2,1.7-31,8.5
                            c-1.6,1.3-3.6,1.8-5.7,1.6c-4.2-0.5-7.2-4.4-6.7-8.6l1.5-11.8C45.6,131.4,50.3,127.7,55.5,128.3z"/>
                                    <path className="st13" d="M95.4,131.8c-3.4-4.4-7.6-8-12.4-10.7s-10-4.5-15.5-5.1c-2-0.3-3.9-1.3-5.1-2.9c-2.6-3.4-2-8.2,1.3-10.8
                            l9.4-7.3c4.1-3.2,10.1-2.5,13.3,1.7c7.6,9.7,12.2,21.3,13.6,33.4c-1.4,0.7-2.8,1.5-4,2.5L95.4,131.8z"/>
                                    <path className="st13" d="M112.1,127.2c1.4-11-1.7-22.2-8.5-31c-1.3-1.6-1.8-3.6-1.6-5.7c0.5-4.2,4.4-7.2,8.6-6.7l11.8,1.5
                            c2.6,0.3,4.8,1.7,6.3,3.6s2.2,4.4,1.9,7c-1.5,12.2-6.4,23.7-14.1,33.3c-1.5-0.5-3-0.9-4.6-1.1L112.1,127.2z"/>
                                    <path className="st13" d="M127.2,135.8c8.8-6.8,14.5-16.9,15.9-27.9c0.3-2,1.3-3.9,2.9-5.1c3.4-2.6,8.2-2,10.8,1.3l7.3,9.4
                            c1.6,2.1,2.2,4.6,1.9,7s-1.5,4.7-3.6,6.3c-9.7,7.6-21.3,12.2-33.4,13.6c-0.7-1.4-1.5-2.8-2.5-4L127.2,135.8z"/>
                                    <path className="st13" d="M131.8,152.5c11,1.4,22.2-1.7,31-8.5c1.6-1.3,3.6-1.8,5.7-1.6c4.2,0.5,7.2,4.4,6.7,8.6l-1.5,11.8
                            c-0.7,5.2-5.4,8.8-10.6,8.2c-12.2-1.5-23.7-6.4-33.3-14.1c0.5-1.5,0.9-3,1.1-4.6L131.8,152.5z"/>
                                    <path className="st13" d="M132.3,202.8c-7.6-9.7-12.2-21.3-13.6-33.4c1.4-0.7,2.8-1.5,4-2.5l0.5,0.6c6.8,8.8,16.9,14.5,27.9,15.9
                            c2,0.3,3.9,1.3,5.1,2.9c2.6,3.4,2,8.2-1.3,10.8l-9.4,7.3C141.4,207.7,135.5,207,132.3,202.8z"/>
                                    <path className="st13" d="M106.5,172.2c-1.4,11,1.7,22.2,8.5,31c1.3,1.6,1.8,3.6,1.6,5.7c-0.5,4.2-4.4,7.2-8.6,6.7l-11.8-1.5
                            c-5.2-0.7-8.8-5.4-8.2-10.6c1.5-12.2,6.4-23.7,14.1-33.3c1.5,0.5,3,0.9,4.6,1.1L106.5,172.2z"/>
                                    <path className="st13" d="M89.6,159.1c0.7,1.4,1.5,2.8,2.5,4c2.7,3.4,6.1,5.8,9.9,7.1c1.5,0.5,3,0.9,4.6,1.1c4.1,0.5,8.3-0.1,12.1-1.9
                            c1.4-0.7,2.7-1.5,4-2.5c3.4-2.7,5.8-6.1,7.1-9.9c0.5-1.5,0.9-3,1.1-4.6c0.5-4.1-0.1-8.3-1.9-12.1c-0.7-1.4-1.5-2.8-2.5-4
                            c-2.7-3.4-6.1-5.8-9.9-7.1c-1.5-0.5-3-0.9-4.6-1.1c-4.1-0.5-8.3,0.1-12.1,1.9c-1.4,0.7-2.8,1.5-4,2.5c-3.4,2.7-5.8,6.1-7.1,9.9
                            c-0.5,1.5-0.9,3-1.1,4.6C87.2,151,87.8,155.2,89.6,159.1z M107.1,146.9c1.6-1.2,3.8-0.9,5,0.6c1.2,1.6,0.9,3.8-0.6,5
                            c-1.6,1.2-3.8,0.9-5-0.6C105.3,150.3,105.6,148.1,107.1,146.9z"/>
                                </g>
                                <g>
                                    <path className="st14" d="M101.2,55c0-2.9,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2s-2.3,5.2-5.2,5.2h-21" />
                                    <path className="st14" d="M116.2,58.4c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2s-2.3,5.2-5.2,5.2H88" />
                                    <path className="st14" d="M111.8,72.3c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2H91.1" />
                                </g>
                                <g>
                                    <path className="st14" d="M101.2,229.6c0-2.9,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2s-2.3,5.2-5.2,5.2h-21" />
                                    <path className="st14" d="M116.2,233.1c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2s-2.3,5.2-5.2,5.2H88" />
                                    <path className="st14" d="M111.8,246.9c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2H91.1" />
                                </g>
                                <path className="st15" d="M579,496.3H457c-4.8,0-8.8-3.9-8.8-8.8V329.1c0-4.8,3.9-8.8,8.8-8.8h122c4.8,0,8.8,3.9,8.8,8.8v158.3
                        C587.8,492.3,583.8,496.3,579,496.3z"/>
                                <path className={`st16 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                    messages.scada.SBB_QHT === 1 ? `orange`
                                        : messages.scada.SBB_QHL === 1 ? `blue`
                                            : `green`
                                    : `green`}`} d="M583.9,336.3v150c0,3.1-2.5,5.7-5.7,5.7H457.7c-3.1,0-5.7-2.5-5.7-5.7V336.2c1.1-0.4,2.2-1,3.3-1.6
                        c3,1.7,6.4,2.7,10.1,2.7c3.8,0,7.3-1,10.4-2.8c3.1,1.9,6.8,3,10.7,3c3.8,0,7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8
                        c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8c3.8,0,7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8
                        c3.8,0,7.4-1,10.4-2.8C581.6,335.3,582.7,335.8,583.9,336.3z"/>
                                <path className="st15" d="M224.6,496.3h-122c-4.8,0-8.8-3.9-8.8-8.8V329.1c0-4.8,3.9-8.8,8.8-8.8h122c4.8,0,8.8,3.9,8.8,8.8v158.3
                        C233.4,492.3,229.4,496.3,224.6,496.3z"/>
                                <path className={`st17 ${messages.scada.SBF_DHS === 1 && messages.scada.SBF_DHX === 1 && messages.scada.SBF_OOF === 1 && messages.scada.SBB_QHW === 1 ?
                                    `orange`
                                    : `green`}`} d="M229.5,334.7v151.5c0,3.1-2.5,5.7-5.7,5.7H103.3c-3.1,0-5.7-2.5-5.7-5.7V334.7c1.1-0.4,2.2-1,3.3-1.6
                        c3,1.7,6.4,2.7,10.1,2.7c3.8,0,7.3-1,10.4-2.8c3.1,1.9,6.8,3,10.7,3c3.8,0,7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8
                        c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8c3.1,1.8,6.6,2.8,10.4,2.8s7.4-1,10.4-2.8
                        C227.2,333.8,228.3,334.3,229.5,334.7z"/>
                                <g id="symbol_pogody_00000067207584534669482630000003526399760340429702_">
                                    <path className="st18" d="M47.2,10.2c-1.2,0-2.1,0.5-2.8,1.4l-0.2-0.1c0-2.6-2.1-4.6-4.6-4.6C37,6.9,35,9.1,35,12l0,0
                        c-3,0-4.7,2.2-4.7,4.8s2.1,4.6,4.6,4.6H51c2.1,0,3.7-1.7,3.7-3.7S53,14,51,14C51,13.9,51.2,10.2,47.2,10.2z"/>
                                    <g>
                                        <g>
                                            <g>
                                                <line className="st19" x1="52.2" y1="9.4" x2="54.4" y2="9.4" />
                                            </g>
                                            <g>
                                                <line className="st19" x1="39.1" y1="3.1" x2="40.5" y2="4.5" />
                                            </g>
                                            <g>
                                                <line className="st19" x1="45.3" y1="0.5" x2="45.3" y2="2.6" />
                                            </g>
                                            <g>
                                                <line className="st19" x1="51.6" y1="3.1" x2="50.2" y2="4.5" />
                                            </g>
                                        </g>
                                        <path className="st19" d="M41.7,7.4c0.7-1.3,2.1-2.2,3.7-2.2c2.3,0,4.2,1.9,4.2,4.2c0,0.5-0.1,0.9-0.2,1.4" />
                                    </g>
                                </g>
                                <path className="st20" d="M176.7,11.1c0-0.4-0.2-0.8-0.5-1.1l-7.9-6.9c-0.2-0.3-0.6-0.4-1-0.4s-0.8,0.2-1,0.5l-7.8,6.8
                        c-0.3,0.3-0.5,0.6-0.5,1v2.2v4.6V20c0,0.8,0.6,1.4,1.4,1.4h16c0.8,0,1.4-0.6,1.4-1.4L176.7,11.1L176.7,11.1z"/>
                                <g>
                                    <line className="st10" x1="338.7" y1="362.2" x2="235.7" y2="362.2" />
                                    {/* {parseInt(messages.scada.SIO_R03) === 1 ? <circle className="st11" cx="284.7" cy="362.2" r="21.8" /> : null} */}
                                    <circle className="st11" cx="284.7" cy="362.2" r="21.8" />
                                    <polygon className="st11" points="334.4,362.3 334.4,355 340.7,358.7 347,362.3 340.7,366 334.4,369.6 		" />
                                </g>
                                <g>
                                    <line className="st10" x1="338.7" y1="457" x2="235.7" y2="457" />
                                    <polygon className="st11" points="340.7,457 340.7,464.4 334.4,460.7 328,457 334.4,453.4 340.7,449.7 		" />
                                </g>
                                <g>
                                    <line className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} x1="693.7" y1="362.2" x2="590.6" y2="362.2" />
                                    {parseInt(messages.scada.SIO_R02) === 1 || parseInt(messages.scada.SIO_R05) === 1 ? <circle className={`st11 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} cx="639.7" cy="362.2" r="21.8" /> : null}
                                    <polygon className={`st11 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} points="691.4,362.3 691.4,355 697.7,358.7 704.1,362.3 697.7,366 691.4,369.6 		" />
                                </g>
                                <g>
                                    <line className={`st10 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} x1="693.7" y1="457" x2="590.6" y2="457" />
                                    <polygon className={`st11 ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                                        messages.scada.SBB_QHT === 1 ? `red`
                                            : messages.scada.SBB_QHL === 1 ? `blue`
                                                : `red`
                                        : `red`}`} points="697.7,457 697.7,464.4 691.4,460.7 685.1,457 691.4,453.4 697.7,449.7 		" />
                                </g>
                                {parseInt(messages.scada.SIO_R08) === 1 ? <g>
                                    <g>
                                        <path className="st21" d="M533.4,104.2c7.2-2.2,8.9-8.1,5.5-12.6c-1.6-2.1-5.8-2.5-7.6,0.5c-1.6,2.8,0.3,5.3-2,7.9
                        C530.9,100.1,533.1,100.9,533.4,104.2z"/>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st21" d="M525.8,100.7c-5.5-5.1-11.5-3.6-13.6,1.5c-1,2.4,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
                            C523.5,104.9,523.1,102.6,525.8,100.7z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st21" d="M526.5,109c-1.7,7.3,2.6,11.8,8.1,11c2.6-0.3,5.1-3.8,3.4-6.8c-1.6-2.8-4.8-2.4-5.8-5.6
                            C531.3,108.9,529.6,110.4,526.5,109z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <circle className="st21" cx="528.6" cy="104.6" r="2.9" />
                                    </g>
                                </g> : null}
                                {parseInt(messages.scada.SIO_R03) === 1 ? <g>
                                    <g>
                                        <path className="st21" d="M289.6,361.8c7.2-2.2,8.9-8.1,5.5-12.6c-1.6-2.1-5.8-2.5-7.6,0.5c-1.6,2.8,0.3,5.3-2,7.9
                        C287.1,357.7,289.2,358.4,289.6,361.8z"/>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st21" d="M282,358.2c-5.5-5.1-11.5-3.6-13.6,1.5c-1,2.4,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
                            C279.7,362.4,279.3,360.2,282,358.2z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st21" d="M282.7,366.5c-1.7,7.3,2.6,11.8,8.1,11c2.6-0.3,5.1-3.8,3.4-6.8c-1.6-2.8-4.8-2.4-5.8-5.6
                            C287.5,366.4,285.7,367.9,282.7,366.5z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <circle className="st21" cx="284.7" cy="362.2" r="2.9" />
                                    </g>
                                </g> : null}
                                {parseInt(messages.scada.SIO_R02) === 1 || parseInt(messages.scada.SIO_R05) === 1 ? <g>
                                    <g>
                                        <path className="st21" d="M644.5,361.8c7.2-2.2,8.9-8.1,5.5-12.6c-1.6-2.1-5.8-2.5-7.6,0.5c-1.6,2.8,0.3,5.3-2,7.9
                        C642,357.7,644.2,358.4,644.5,361.8z"/>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st21" d="M636.9,358.2c-5.5-5.1-11.5-3.6-13.6,1.5c-1,2.4,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
                            C634.6,362.4,634.2,360.2,636.9,358.2z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st21" d="M637.6,366.5c-1.7,7.3,2.6,11.8,8.1,11c2.6-0.3,5.1-3.8,3.4-6.8c-1.6-2.8-4.8-2.4-5.8-5.6
                            C642.4,366.4,640.7,367.9,637.6,366.5z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <circle className="st21" cx="639.7" cy="362.2" r="2.9" />
                                    </g>
                                </g> : null}
                            </g>
                            <text x="0" y="70" className="text-scada">{parseFloat(messages.scada.ZM_OUT_TEMP).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="280" y="240" className="text-scada">{parseFloat(messages.scada.ZM_SUCT_TEMP).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="140" y="245" className="text-scada">{parseFloat(messages.scada.ZM_COIL_TEMP).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="355" y="43" className="text-scada">{parseFloat(messages.scada.ZM_DIS_TEMP).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="15" y="285" className="text-scada">{messages.scada.ZM_FAN1_SPE} RPM</text>
                            <text x="130" y="285" className="text-scada">{messages.scada.ZM_FAN2_SPE} RPM</text>
                            <text x="133" y="380" className="text-scada middle">CWU</text>
                            <text x="120" y="430" className="text-scada big">{messages.scada.TSM15}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="70" y="20" className="text-scada">{messages.scada.TSM9}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="190" y="20" className="text-scada">{messages.scada.TSM10}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="245" y="405" className="text-scada">Pompa</text>
                            <text x="245" y="435" className="text-scada">cyrkulacyjna CWU</text>
                            <text x="600" y="405" className="text-scada">Pompa CO</text>
                            {messages.scada.SBF_DHS === 1 && messages.scada.SBF_DHX === 1 && messages.scada.SBF_OOF === 1 && messages.scada.SBB_QHW === 1 ? <text x="700" y="115" className="text-scada">CWU</text> : null}
                            {messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 &&
                                (messages.scada.SBB_QHT === 1 || messages.scada.SBB_QHL === 1) ? <text x="700" y="115" className="text-scada">CO</text> : null}
                            {/*<text x="605" y="95" className="text-scada">{messages.scada.T6fiz}<tspan baselineShift="sup">&deg;</tspan>C</text>*/}
                            {/*<text x="605" y="20" className="text-scada">COP {messages.scada.COP === "NA"? "--" : messages.scada.COP}</text>*/}
                            {/*<text x="605" y="185" className="text-scada">{messages.scada.T5fiz}<tspan baselineShift="sup">&deg;</tspan>C</text>*/}
                            <text x="605" y="95" className={`text-scada ${parseInt(messages.scada.SIO_R08) === 1 ? "white" : "grey"}`}>{messages.scada.SSB_QHL === 1 ? messages.scada.TSM14 : messages.scada.TSM13}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="605" y="185" className={`text-scada ${parseInt(messages.scada.SIO_R08) === 1 ? "white" : "grey"}`}>{messages.scada.SSB_QHL === 1 ? messages.scada.TSM13 : messages.scada.TSM14}<tspan baselineShift="sup">&deg;</tspan>C</text>
                            <text x="295" y="95" className="text-scada">{messages.scada.ZM_CUR_COMP_FREQ} Hz</text>
                            <text x="465" y="380" className="text-scada middle">bufor CO</text>
                            {parseInt(messages.scada.TSM17) <= -45 || parseInt(messages.scada.TSM17) >= 100 ? null : <text x="465" y="430" className="text-scada big">{messages.scada.TSM17}<tspan baselineShift="sup">&deg;</tspan>C</text>}
                            {parseInt(messages.scada.SIO_R08) === 1 && messages.scada.PWM_1 ? <text x="515" y="75" className="text-scada">{messages.scada.PWM_1}%</text> : null}
                        </g>
                            </svg>
                        : messages && messages.device.Name === "GEO_1" ?
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                id="Layer_1" x="0px" y="0px" viewBox="0 0 765.4 580.2">
                <g className='geo'>
                    <g id="wymiennik_x0020_dolnego_x0020_zrodla">
                        <polygon className="st0" points="249.7,268.6 267.3,268.6 267.3,273 249.7,273 	" />
                        <path className="st1" d="M266,268.6h-15c-7.8,0-14.1-6.3-14.1-14.1V140.1c0-7.8,6.3-14.1,14.1-14.1h15.1c7.8,0,14.1,6.3,14.1,14.1
           v114.4C280.2,262.3,273.9,268.6,266,268.6L266,268.6z"/>
                        <path className="st2" d="M241.1,140.1v114.8c0,5.3,4.2,9.5,9.5,9.5h15.7c5.3,0,9.5-4.2,9.5-9.5V140.1c0-5.3-4.2-9.5-9.5-9.5h-15.7
           C245.5,130.5,241.1,134.7,241.1,140.1L241.1,140.1z"/>
                    </g>
                    <g id="gaz_x0020_ciecz_x0020_dolne_x0020_zrodlo">
                        <path className="st3" d="M241.1,207.2v47.6c0,5.3,4.2,9.5,9.5,9.5h15.7c5.3,0,9.5-4.2,9.5-9.5v-47.6L241.1,207.2L241.1,207.2z" />
                        <path className="st3" d="M256.5,172.1c0,0.9-0.6,1.6-1.6,1.6s-1.6-0.6-1.6-1.6s0.6-1.6,1.6-1.6C255.9,170.6,256.5,171.2,256.5,172.1z"
                        />
                        <path className="st3" d="M274.5,168.1c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.6,0.6-1.3,1.3-1.3
           C273.9,166.6,274.5,167.4,274.5,168.1z"/>
                        <path className="st3" d="M261.5,187.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C261.1,186.9,261.5,187.3,261.5,187.8z"
                        />
                        <path className="st3" d="M259.8,211c0,0.4-0.2,0.6-0.6,0.6s-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6C259.5,210.5,259.8,210.6,259.8,211z" />
                        <path className="st3" d="M248.1,198.9c0,0.9-0.7,1.7-1.6,1.7c-0.8,0-1.6-0.7-1.6-1.7c0-0.9,0.7-1.6,1.6-1.6
           C247.3,197.3,248.1,197.9,248.1,198.9z"/>
                        <path className="st3" d="M247.1,184.5c0,0.6-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2C246.6,183.4,247.1,183.9,247.1,184.5z"
                        />
                        <path className="st3" d="M274.5,186.2c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7S274.5,185.4,274.5,186.2z" />
                        <path className="st3" d="M274.5,199.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
           C273.9,197.8,274.5,198.4,274.5,199.2z"/>
                        <path className="st3" d="M261.7,171.9c0-0.9,0.6-1.6,1.6-1.6s1.6,0.6,1.6,1.6s-0.6,1.6-1.6,1.6S261.7,172.8,261.7,171.9z" />
                        <path className="st3" d="M243.7,175.9c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3c0,0.6-0.6,1.3-1.3,1.3
           C244.4,177.4,243.7,176.7,243.7,175.9z"/>
                        <path className="st3" d="M256.6,156.3c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9C257,157.1,256.6,156.8,256.6,156.3z" />
                        <path className="st3" d="M260.1,148.7c0-0.4,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6S260.1,149.1,260.1,148.7z" />
                        <path className="st3" d="M270.2,145.3c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
           C270.8,146.9,270.2,146.1,270.2,145.3z"/>
                        <path className="st3" d="M270.8,159.6c0-0.6,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2S270.8,160.2,270.8,159.6z" />
                        <path className="st3" d="M243.7,157.9c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7
           C244.5,159.6,243.7,158.7,243.7,157.9z"/>
                        <path className="st3" d="M243.7,144.9c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3C244.4,146.3,243.7,145.7,243.7,144.9z"
                        />
                    </g>
                    <g id="wymiennik_x0020_gornego_x0020_zrodla">
                        <path className="st1" d="M452.8,269.2h15.1c7.8,0,14.1-6.3,14.1-14.1V140.7c0-7.8-6.3-14.1-14.1-14.1h-15.1c-7.8,0-14.1,6.3-14.1,14.1
           v114.4C438.6,262.9,445.1,269.2,452.8,269.2z"/>
                        <path className="st2" d="M477.7,140.7v114.8c0,5.3-4.2,9.5-9.5,9.5h-15.7c-5.3,0-9.5-4.2-9.5-9.5V140.7c0-5.3,4.2-9.5,9.5-9.5h15.7
           C473.5,131.1,477.7,135.4,477.7,140.7L477.7,140.7z"/>
                        <polygon className="st0" points="469.1,269.2 451.5,269.2 451.5,273.7 469.1,273.7 	" />
                    </g>
                    <g id="gaz_x0020_ciecz_x0020_gorne_x0020_zrodlo">
                        <path className="st4" d="M477.7,225.1v30.4c0,5.3-4.2,9.5-9.5,9.5h-15.7c-5.3,0-9.5-4.2-9.5-9.5v-30.4H477.7L477.7,225.1z" />
                        <path className="st4" d="M462.2,172.7c0,0.9,0.6,1.6,1.6,1.6s1.6-0.6,1.6-1.6s-0.6-1.6-1.6-1.6C463.1,171.2,462.2,171.8,462.2,172.7z"
                        />
                        <path className="st4" d="M444.4,168.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.6-0.6-1.3-1.3-1.3
           C444.9,167.4,444.4,168,444.4,168.7z"/>
                        <path className="st4" d="M457.3,188.4c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9C457.8,187.6,457.3,188,457.3,188.4z" />
                        <path className="st4" d="M459.1,211.6c0,0.4,0.2,0.6,0.6,0.6s0.6-0.2,0.6-0.6s-0.2-0.6-0.6-0.6S459.1,211.2,459.1,211.6z" />
                        <path className="st4" d="M470.8,199.5c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6S470.8,198.6,470.8,199.5z" />
                        <path className="st4" d="M471.6,185.3c0,0.6,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2S471.6,184.5,471.6,185.3z" />
                        <path className="st4" d="M444.4,186.9c0,0.9,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7
           C445.1,185.3,444.4,185.9,444.4,186.9z"/>
                        <path className="st4" d="M444.4,199.9c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.6-0.6-1.3-1.3-1.3
           C444.9,198.6,444.4,199.1,444.4,199.9z"/>
                        <path className="st4" d="M457.3,172.7c0-0.9-0.6-1.6-1.6-1.6s-1.6,0.6-1.6,1.6s0.6,1.6,1.6,1.6C456.4,174.2,457.3,173.5,457.3,172.7z"
                        />
                        <path className="st4" d="M475.1,176.7c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.6,0.6,1.3,1.3,1.3
           C474.6,178,475.1,177.4,475.1,176.7z"/>
                        <path className="st4" d="M462.1,156.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9C461.7,157.9,462.1,157.5,462.1,156.9z"
                        />
                        <path className="st4" d="M458.9,149.4c0-0.4-0.2-0.6-0.6-0.6s-0.6,0.2-0.6,0.6c0,0.4,0.2,0.6,0.6,0.6
           C458.7,150,458.9,149.8,458.9,149.4z"/>
                        <path className="st4" d="M448.6,145.9c0-0.9-0.7-1.6-1.6-1.6c-0.7,0-1.7,0.7-1.7,1.6c0,0.7,0.7,1.6,1.6,1.6S448.6,146.7,448.6,145.9z"
                        />
                        <path className="st4" d="M447.9,160.2c0-0.6-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2C447.4,161.3,447.9,160.8,447.9,160.2z"
                        />
                        <path className="st4" d="M475.1,158.5c0-0.9-0.7-1.7-1.7-1.7s-1.7,0.7-1.7,1.7s0.7,1.7,1.7,1.7C474.5,160.2,475.1,159.4,475.1,158.5z"
                        />
                        <path className="st4" d="M475.1,145.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
           C474.6,146.9,475.1,146.3,475.1,145.6z"/>
                    </g>
                    <g id="zasobnik_x0020_CWU">
                        <g id="_2963798729760">
                            <path className="st5" d="M245.3,543.9H122.8c-4.8,0-8.8-4-8.8-8.8V376.3c0-4.8,4-8.8,8.8-8.8h122.3c4.8,0,8.8,4,8.8,8.8V535
               C254.1,540,250.2,543.9,245.3,543.9L245.3,543.9z"/>
                            <path className={`st6 ${messages.scada.SBF_DHS === 1 && messages.scada.SBF_DHX === 1 && messages.scada.SBF_OOF === 1 && messages.scada.SBB_QHW === 1 ?
                                `orange`
                                : `green`}`} d="M250.3,382v151.9c0,3.1-2.5,5.7-5.7,5.7h-121c-3.1,0-5.7-2.5-5.7-5.7v-152c1.2-0.4,2.2-0.9,3.2-1.6
               c2.9,1.7,6.4,2.6,10.1,2.6c3.7,0,7.4-1.1,10.4-2.7c3.1,2,6.8,3.1,10.9,3.1c3.8,0,7.5-1.1,10.5-2.7c3.1,1.8,6.7,2.7,10.5,2.7
               s7.5-1.1,10.5-2.7c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7
               c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7C247.7,380.9,249,381.5,250.3,382L250.3,382L250.3,382z"/>
                            <polygon className="st7" points="359.8,412 256.4,412 256.4,407 359.8,407 		" />
                            <polygon className="st7" points="359.8,507 256.4,507 256.4,502.1 359.8,502.1 		" />
                            <path className="st7" d="M317.2,428.1c-10.3,6.4-23.7,3.3-30.2-7c-6.4-10.3-3.3-23.7,7-30.2c10.3-6.4,23.7-3.3,30.2,7
               C330.6,408.1,327.4,421.7,317.2,428.1z"/>
                        </g>
                    </g>
                    <g id="bufor">
                        <path id="bufor_x0020_naczynie" className="st5" d="M600.7,543.9H478.4c-4.8,0-8.8-4-8.8-8.8V376.3c0-4.8,4-8.8,8.8-8.8h122.3
           c4.8,0,8.8,4,8.8,8.8V535C609.4,540,605.4,543.9,600.7,543.9z"/>
                        <path id="woda_x0020_w_x0020_buforze" className={`st8  ${messages.scada.SXF_TOE === 1 && messages.scada.SBF_OOF === 1 ?
                            messages.scada.SBB_QHT === 1 ? `orange`
                                : messages.scada.SBB_QHL === 1 ? `blue`
                                    : `green`
                            : `green`}`} d="M605.6,383.5V534c0,3.1-2.5,5.7-5.7,5.7H479.1c-3.1,0-5.7-2.5-5.7-5.7V383.4
           c1.2-0.4,2.2-0.9,3.2-1.6c2.9,1.7,6.4,2.6,10.1,2.6s7.4-1.1,10.4-2.7c3.1,2,6.8,3.1,10.9,3.1c3.8,0,7.5-1.1,10.5-2.7
           c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7
           c3.1,1.8,6.7,2.7,10.5,2.7s7.5-1.1,10.5-2.7C603.3,382.5,604.4,383,605.6,383.5L605.6,383.5L605.6,383.5z"/>
                    </g>
                    <g id="dolne_x0020_zrodlo">
                        <g>
                            <path className="st9" d="M360.2,297.9h-95.6c-5,0-8.9-3.9-8.9-8.9v-15.4h5V289c0,2.3,1.6,3.9,3.9,3.9h95.6V297.9z" />
                        </g>
                        <g>
                            <path className="st9" d="M263.9,126.4h-5.1v-20.9c0-4.6,3.7-8.4,8.4-8.4h47.3v5.1h-47.2c-2,0-3.5,1.6-3.5,3.5L263.9,126.4L263.9,126.4
               L263.9,126.4z"/>
                        </g>
                        <g>
                            <path className="st9" d="M237,229.9c-4.8,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h43.3c2.2,0,3.8-1.8,3.8-3.8c0-2.2-1.8-3.8-3.8-3.8v-5.1
               c4.8,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H237c-2.2,0-3.8,1.8-3.8,3.8c0,2.2,1.8,3.8,3.8,3.8V229.9L237,229.9z"/>
                        </g>
                        <g>
                            <path className="st9" d="M237,204.2c-4.8,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h43.3c2.2,0,3.8-1.8,3.8-3.8c0-2.2-1.8-3.8-3.8-3.8v-5.1
               c4.8,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H237c-2.2,0-3.8,1.8-3.8,3.8c0,2.2,1.8,3.8,3.8,3.8V204.2z"/>
                        </g>
                        <g>
                            <path className="st9" d="M237,178.6c-4.8,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h43.3c2.2,0,3.8-1.8,3.8-3.8c0-2.2-1.8-3.8-3.8-3.8v-5.1
               c4.8,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H237c-2.2,0-3.8,1.8-3.8,3.8c0,2.2,1.8,3.8,3.8,3.8V178.6z"/>
                        </g>
                        <g>
                            <path className="st9" d="M75.7,306.9l5.1-4.1v172.5c0,8.4-7,15.4-15.4,15.4s-15.5-7-15.5-15.4V327.8l5.1-4.1v151.6
               c0,5.8,4.6,10.4,10.4,10.4s10.3-4.6,10.3-10.4V306.9L75.7,306.9z"/>
                        </g>
                        <g>
                            <path className="st9" d="M236.9,148v5.1H59c-2.2,0-4.1,1.8-4.1,4.1v146.2l-5.1,4.1V157c0-5.1,4.1-9.1,9.1-9.1h178V148z" />
                        </g>
                        <g>
                            <path className="st9" d="M289.8,234.3c-0.2,4.7-4.5,8.4-9.8,8.4H84.7c-2.2,0-4.1,1.8-4.1,4.1v35.5l-5.1,4.1v-39.5
               c0-5.1,4.1-9.1,9.1-9.1h195.3c2.5,0,4.6-1.7,4.7-3.7c0-0.9-0.2-2-1.1-2.6c-0.9-0.9-2.2-1.5-3.5-1.5v-5c2.6,0,5.3,1.2,7.3,2.9
               C289,229.6,290,231.8,289.8,234.3L289.8,234.3L289.8,234.3z"/>
                        </g>
                        <g>
                            <path className="st9" d="M205.1,169.7c-10.3,6.4-23.7,3.3-30.2-7c-6.4-10.3-3.3-23.7,7-30.2c10.3-6.4,23.7-3.3,30.2,7
               C218.4,149.8,215.2,163.4,205.1,169.7L205.1,169.7z"/>
                        </g>
                    </g>
                    <g id="gorne_x0020_zrodlo">
                        <path className="st7" d="M453.9,298.1h-72.8V293h72.8c2.2,0,3.8-1.8,3.8-3.8v-15.6h5.1v15.6C462.8,294.1,458.9,298.1,453.9,298.1
           L453.9,298.1z"/>
                        <path className="st10" d="M463,126.5h-5.1v-20.8c0-2-1.6-3.5-3.5-3.5h-88.6v-5.1h88.6c4.6,0,8.4,3.7,8.4,8.4L463,126.5L463,126.5
           L463,126.5z"/>
                        <path className="st7" d="M481.8,230.5v-5.1c2.2,0,3.8-1.8,3.8-3.8c0-2.2-1.8-3.8-3.8-3.8h-43.3c-4.8,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8v5.1
           c-2.2,0-3.8,1.8-3.8,3.8c0,2.2,1.8,3.8,3.8,3.8h43.3c4.8,0,8.8,4,8.8,8.8C490.7,226.5,486.7,230.5,481.8,230.5L481.8,230.5z"/>
                        <path className="st7" d="M481.8,205v-5.1c2.2,0,3.8-1.8,3.8-3.8c0-2.2-1.8-3.8-3.8-3.8h-43.3c-4.8,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8v5.1
           c-2.2,0-3.8,1.8-3.8,3.8c0,2.2,1.8,3.8,3.8,3.8h43.3c4.8,0,8.8,4,8.8,8.8C490.6,200.9,486.7,205,481.8,205L481.8,205z"/>
                        <path className="st7" d="M481.8,179.3v-5.1c2.2,0,3.8-1.8,3.8-3.8s-1.8-3.8-3.8-3.8h-43.3c-4.8,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8v5.1
           c-2.2,0-3.8,1.8-3.8,3.8c0,2.2,1.8,3.8,3.8,3.8h43.3c4.8,0,8.8,4,8.8,8.8C490.6,175.4,486.7,179.3,481.8,179.3L481.8,179.3z"/>
                        <line className="st11" x1="692.3" y1="151.1" x2="481.9" y2="151.1" />
                        <path className="st7" d="M697.1,243.4H438.9c-5.3,0-9.5-3.7-9.8-8.4c-0.1-2.3,0.7-4.6,2.5-6.3c1.8-2,4.3-2.9,7.3-2.9v5.1
           c-1.5,0-2.5,0.5-3.5,1.5c-0.6,0.7-1.1,1.7-1.1,2.6c0.1,2.1,2.2,3.7,4.7,3.7h258.2L697.1,243.4L697.1,243.4L697.1,243.4z"/>
                        <path className="st7" d="M561.7,169.7c-10.3,6.4-23.7,3.3-30.2-7s-3.3-23.7,7-30.2c10.3-6.4,23.7-3.3,30.2,7S571.9,163.4,561.7,169.7z"
                        />
                        <polygon className="st7" points="685.9,151.1 685.9,143.9 692.3,147.6 698.5,151.1 692.3,154.9 685.9,158.5 	" />
                        <polygon className="st7" points="698.5,240.4 698.5,247.6 692.3,244.1 685.9,240.4 692.3,236.6 698.5,233 	" />
                        <line className="st11" x1="715.6" y1="409.5" x2="612.5" y2="409.5" />
                        <line className="st11" x1="715.6" y1="504.6" x2="612.5" y2="504.6" />
                        <path className="st7" d="M673.2,428.1c-10.3,6.4-23.7,3.3-30.2-7c-6.4-10.3-3.3-23.7,7-30.2c10.3-6.4,23.7-3.3,30.2,7
           C686.5,408.1,683.5,421.7,673.2,428.1z"/>
                    </g>
                    {parseInt(messages.scada.SIO_R07) === 1 ? <g id="pompka_x0020_dolnego_x0020_zrodla">
                        <path className="st12" d="M198.2,150.8c7.3-2.2,8.8-8.2,5.6-12.5c-1.7-2.1-5.9-2.5-7.5,0.5c-1.7,2.7,0.2,5.4-2,7.9
           C195.7,146.6,197.8,147.5,198.2,150.8L198.2,150.8z"/>
                        <path className="st12" d="M190.6,147.1c-5.6-5.2-11.6-3.6-13.6,1.6c-1.1,2.5,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
           C188.3,151.4,187.9,149.1,190.6,147.1z"/>
                        <path className="st12" d="M191.3,155.6c-1.7,7.4,2.5,11.9,8,11.1c2.5-0.4,5.2-3.8,3.3-6.8c-1.7-2.7-4.7-2.5-5.9-5.7
           C196.2,155.5,194.4,156.9,191.3,155.6L191.3,155.6L191.3,155.6z"/>
                        <path className="st12" d="M195.8,149.7c0.9,1.5,0.4,3.1-0.9,4c-1.5,0.9-3.1,0.4-4-0.9c-0.9-1.5-0.4-3.1,0.9-4
           C193.2,147.8,195.1,148.3,195.8,149.7L195.8,149.7z"/>
                    </g> : null}
                    {parseInt(messages.scada.SIO_R08) === 1 ? <g id="pomka_x0020_gornego_x0020_zrodla">
                        <path className="st12" d="M554.9,150.8c7.3-2.2,8.8-8.2,5.6-12.5c-1.7-2.1-5.9-2.5-7.5,0.5c-1.7,2.7,0.2,5.4-2,7.9
           C552.4,146.6,554.5,147.5,554.9,150.8L554.9,150.8z"/>
                        <path className="st12" d="M547.5,147.1c-5.6-5.2-11.6-3.6-13.6,1.6c-1.1,2.5,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
           C544.9,151.4,544.5,149.1,547.5,147.1z"/>
                        <path className="st12" d="M548.1,155.6c-1.7,7.4,2.5,11.9,8,11.1c2.5-0.4,5.2-3.8,3.3-6.8c-1.7-2.7-4.7-2.5-5.9-5.7
           C552.8,155.5,551.2,156.9,548.1,155.6L548.1,155.6L548.1,155.6z"/>
                        <path className="st12" d="M552.5,149.7c0.9,1.5,0.4,3.1-0.9,4c-1.5,0.9-3.1,0.4-4-0.9c-0.9-1.5-0.4-3.1,0.9-4
           C550,147.8,551.8,148.3,552.5,149.7L552.5,149.7z"/>
                    </g> : null}
                    {parseInt(messages.scada.SIO_R03) === 1 ? <g id="pompka_x0020_cyrkulacyjna">
                        <path className="st12" d="M310.5,409.1c7.3-2.2,8.8-8.2,5.6-12.5c-1.7-2.1-5.9-2.5-7.5,0.5c-1.7,2.7,0.2,5.4-2,7.9
           C307.9,404.9,310.1,405.7,310.5,409.1L310.5,409.1L310.5,409.1z"/>
                        <path className="st12" d="M302.7,405.5c-5.6-5.2-11.6-3.6-13.6,1.6c-1.1,2.5,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
           C300.5,409.7,300.1,407.4,302.7,405.5L302.7,405.5L302.7,405.5z"/>
                        <path className="st12" d="M303.6,413.9c-1.7,7.4,2.5,11.9,8,11.1c2.5-0.4,5.2-3.8,3.3-6.8c-1.7-2.7-4.7-2.5-5.9-5.7
           C308.3,413.7,306.6,415.2,303.6,413.9L303.6,413.9L303.6,413.9z"/>
                        <path className="st12" d="M308,407.9c0.9,1.5,0.4,3.1-0.9,4c-1.5,0.9-3.1,0.4-4-0.9c-0.9-1.5-0.4-3.1,0.9-4
           C305.4,406,307.2,406.6,308,407.9z"/>
                    </g> : null}
                    <g id="sprezarka">
                        <polygon className="st13" points="365.9,102.6 359.5,102.6 359.5,96.8 365.9,96.8 	" />
                        <polygon className="st13" points="314.6,96.7 321,96.7 321,102.5 314.6,102.5 	" />
                        <path className="st14" d="M360.5,99.5c0,11.2-9.1,20.3-20.3,20.3s-20.3-9.1-20.3-20.3s9.1-20.3,20.3-20.3
           C351.4,79.4,360.5,88.4,360.5,99.5z"/>
                        <path className="st15" d="M356.3,99.5c0,8.8-7.3,15.9-15.9,15.9s-15.9-7.3-15.9-15.9c0-8.8,7.3-15.9,15.9-15.9
           C349.2,83.6,356.3,90.9,356.3,99.5z"/>
                        <path className="st14" d="M340.3,91.5c0,2.9-2.5,5.3-5.3,5.3c-2.9,0-5.3-2.5-5.3-5.3c0-2.9,2.5-5.3,5.3-5.3
           C337.9,86.2,340.3,88.7,340.3,91.5z"/>
                        <polygon className="st16" points="365.9,96 367.3,96 367.3,103.5 365.9,103.5 	" />
                        <polygon className="st16" points="314.6,103.4 313.2,103.4 313.2,95.7 314.6,95.7 	" />
                    </g>
                    {parseInt(messages.scada.SIO_R02) === 1 || parseInt(messages.scada.SIO_R05) === 1 ? <g id="pompka_x0020_CO">
                        <path className="st12" d="M666.3,409.1c7.3-2.2,8.8-8.2,5.6-12.5c-1.7-2.1-5.9-2.5-7.5,0.5c-1.7,2.7,0.2,5.4-2,7.9
           C663.9,404.9,666.1,405.7,666.3,409.1L666.3,409.1L666.3,409.1z"/>
                        <path className="st12" d="M658.7,405.5c-5.6-5.2-11.6-3.6-13.6,1.6c-1.1,2.5,0.7,6.3,4.2,6.3c3.2,0,4.5-2.9,7.8-2.2
           C656.5,409.7,656.1,407.4,658.7,405.5L658.7,405.5z"/>
                        <path className="st12" d="M659.5,413.9c-1.7,7.4,2.5,11.9,8,11.1c2.5-0.4,5.2-3.8,3.3-6.8c-1.7-2.7-4.7-2.5-5.9-5.7
           C664.3,413.7,662.6,415.2,659.5,413.9L659.5,413.9L659.5,413.9z"/>
                        <path className="st12" d="M664,407.9c0.9,1.5,0.4,3.1-0.9,4c-1.5,0.9-3.1,0.4-4-0.9c-0.9-1.5-0.4-3.1,0.9-4S663.1,406.6,664,407.9z" />
                    </g> : null}
                    {parseInt(messages.scada.SXB_QSFC) === 1 ? <g id="Free_x0020_Cooling">
                        <path className="st9" d="M541.8,337.9l-7.4,27.3l-7.4-27.3h4.7v-16.3c0-2.2-1.8-3.8-3.8-3.8h-328c-4.8,0-8.8-4-8.8-8.8v-36.7h-4.7
           l7.4-27.3l7.4,27.3h-4.7v36.8c0,2.2,1.8,3.8,3.8,3.8h328.3c4.8,0,8.8,4,8.8,8.8V338L541.8,337.9L541.8,337.9L541.8,337.9z"/>
                    </g> : null}
                    <g id="temperatura_x0020_zewnetrzna">
                        <path className="st17" d="M75.6,50.5c-1.7,0-3.1,0.7-4.1,2.2h-0.3c0-3.8-3.1-7-7-7s-7,3.2-7,7.6l0,0c-4.4,0-7.1,3.4-7.1,7.1
           c0,3.7,3.2,7,7.1,7h24c3.1,0,5.5-2.5,5.5-5.5c0-3.1-2.5-5.5-5.5-5.5C81.4,56.2,81.6,50.5,75.6,50.5L75.6,50.5z"/>
                        <line className="st18" x1="83.1" y1="49.5" x2="86.3" y2="49.5" />
                        <line className="st18" x1="63.6" y1="40.1" x2="65.8" y2="42.1" />
                        <line className="st18" x1="72.8" y1="36.3" x2="72.8" y2="39.4" />
                        <line className="st18" x1="82.1" y1="40.1" x2="80" y2="42.1" />
                        <path className="st18" d="M67.4,46.4c0.9-1.9,3.1-3.2,5.5-3.2c3.4,0,6.3,2.8,6.3,6.3c0,0.7-0.1,1.4-0.3,2.2" />
                    </g>
                    <g id="zawor_x0020_rozprezny">
                        <path className="st13" d="M379.8,293.3v4.7c0,0.2-0.2,0.5-0.5,0.5H362c-0.2,0-0.5-0.2-0.5-0.5v-4.7c0-0.2,0.2-0.5,0.5-0.5h17.3
           C379.4,292.7,379.8,292.8,379.8,293.3z"/>
                        <path className="st16" d="M361.5,292.2v6.7c0,0.2-0.2,0.5-0.5,0.5h-0.5c-0.2,0-0.5-0.2-0.5-0.5v-6.7c0-0.2,0.2-0.5,0.5-0.5h0.5
           C361.3,291.7,361.5,291.9,361.5,292.2L361.5,292.2z"/>
                        <path className="st16" d="M379.8,298.8v-6.7c0-0.2,0.2-0.5,0.5-0.5h0.5c0.2,0,0.5,0.2,0.5,0.5v6.7c0,0.2-0.2,0.5-0.5,0.5h-0.5
           C380,299.4,379.8,299.2,379.8,298.8z"/>
                        <path className="st16" d="M374.2,292.7v5.8c0,0.2-0.2,0.5-0.5,0.5h-6.2c-0.2,0-0.5-0.2-0.5-0.5v-5.8c0-0.2,0.2-0.5,0.5-0.5h6.2
           C374.1,292.2,374.2,292.4,374.2,292.7z"/>
                        <path className="st13" d="M373.3,295.5L373.3,295.5c0-0.2-0.2-0.5-0.5-0.5h-4.3c-0.2,0-0.5,0.2-0.5,0.5c0,0.2,0.2,0.5,0.5,0.5h4.3
           C373,296,373.3,295.8,373.3,295.5z"/>
                    </g>
                    <g id="temperatura5Fwewnetrzna">
                        <path className="st19" d="M252.8,52.1c0-0.6-0.3-1.1-0.7-1.6l-11.5-9.9c-0.3-0.3-0.9-0.6-1.6-0.6s-1.1,0.3-1.6,0.7l-11.3,9.7
           c-0.3,0.3-0.7,0.9-0.7,1.6v3.2v6.7v3.2c0,1.1,0.9,2.2,2.2,2.2h23.1c1.1,0,2.2-0.9,2.2-2.2L252.8,52.1L252.8,52.1L252.8,52.1z"/>
                    </g>
                    {parseInt(messages.scada.SIO_R10) === 1 ? <g id="grzalka_CWU">
                        <path className="st10" d="M499,54.7L491.6,42l-7.2,12.4c-1.1,1.5-1.7,3.3-1.7,5.2c0,4.8,4,8.8,8.8,8.8s8.8-4,8.8-8.8
           C500.5,57.8,500,56.1,499,54.7L499,54.7z"/>
                        <g>
                            <path className="st10" d="M526.2,59.6c-0.1,2.3-0.4,4.6-1.8,6.7c-1,1.5-2.4,2.4-4.3,2.1c-1.1-0.2-2-0.8-2.7-1.7
               c-1.2-1.5-1.8-3.4-1.9-5.3c-0.5-5.7,1.1-10.8,4.6-15.2c1.7-2.2,4-3.4,6.9-3.2c1.6,0.1,3,0.6,4.3,1.5c0.3,0.2,0.4,0.4,0.2,0.8
               c-0.2,0.4-0.5,0.4-0.9,0.2c-1-0.7-2.1-1.1-3.2-1.2c-2.4-0.3-4.4,0.6-6.1,2.4c-1.5,1.6-2.5,3.6-3.3,5.7c-1.1,2.8-1.7,5.7-1.4,8.7
               c0.2,1.8,0.6,3.5,1.7,5c0.7,0.9,1.6,1.6,2.9,1.4c1.2-0.1,1.9-0.9,2.5-1.8c0.9-1.4,1.3-3,1.4-4.7c0.3-4.6-1-8.8-3.5-12.7
               c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.1-0.6,0.2-0.8c0.3-0.2,0.6-0.1,0.7,0.2c0.6,1.1,1.3,2.2,1.9,3.4
               C525.4,53.5,526.1,56.4,526.2,59.6"/>
                            <path className="st10" d="M537.8,59.6c0,2.3-0.4,4.7-1.8,6.8c-1,1.5-2.4,2.3-4.3,2c-1.5-0.2-2.5-1.2-3.2-2.4c-1.3-2.2-1.6-4.6-1.5-7.1
               c0.2-4.1,1.5-7.9,3.7-11.4c1.1-1.8,2.6-3.2,4.6-4c2.7-1,5.2-0.6,7.6,1c0.3,0.2,0.4,0.4,0.2,0.7c-0.2,0.3-0.5,0.3-0.8,0.2
               c-0.7-0.3-1.3-0.7-2-0.9c-2.9-0.9-5.4-0.1-7.5,2.1c-2,2.1-3.1,4.8-4,7.6c-0.7,2.2-1,4.5-0.8,6.7c0.2,1.7,0.5,3.4,1.6,4.9
               c0.5,0.7,1,1.2,1.8,1.5c1.3,0.5,2.7-0.1,3.7-1.6c1-1.4,1.4-3.1,1.5-4.8c0.3-4.6-1-8.8-3.4-12.7c0-0.1-0.1-0.1-0.2-0.2
               c-0.2-0.3-0.2-0.6,0.1-0.8c0.3-0.2,0.6-0.1,0.8,0.2c0.4,0.7,0.9,1.5,1.3,2.2C536.8,52.7,537.8,56,537.8,59.6"/>
                            <path className="st10" d="M549.4,59.7c0,2-0.3,4.2-1.5,6.1c-0.7,1.3-1.7,2.3-3.2,2.5c-1.8,0.3-3.2-0.4-4.2-1.9
               c-1.1-1.5-1.6-3.2-1.8-5.1c-0.5-5.6,1.1-10.5,4.4-15c1.4-1.8,3.2-3.1,5.5-3.4c2.1-0.3,4.1,0.2,5.9,1.4c0.3,0.2,0.4,0.4,0.2,0.8
               c-0.2,0.3-0.5,0.3-0.8,0.2c-0.6-0.3-1.2-0.7-1.9-0.9c-3-1-5.5-0.1-7.6,2.1c-1.6,1.7-2.7,3.9-3.5,6.1c-1,2.6-1.5,5.4-1.3,8.2
               c0.2,1.9,0.6,3.7,1.8,5.2c1.5,1.7,3.4,1.7,4.9,0c1-1.2,1.5-2.7,1.7-4.3c0.6-5-0.8-9.5-3.4-13.7c-0.1-0.2-0.2-0.4-0.2-0.6
               c0-0.2,0.3-0.4,0.5-0.4c0.2,0,0.5,0.1,0.6,0.3c0.6,1,1.2,2,1.7,3C548.7,53.3,549.4,56.3,549.4,59.7"/>
                            <path className="st10" d="M515.1,43c1.7,0,3.2,0.5,4.5,1.4c0.3,0.2,0.5,0.5,0.3,0.8c-0.2,0.3-0.5,0.3-0.9,0.1c-2.6-1.8-5.3-1.6-7.9,0
               c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.3-0.4-0.5c0-0.2,0.1-0.4,0.3-0.5C511.7,43.5,513.4,43,515.1,43"/>
                        </g>
                    </g> : null}
                    {parseInt(messages.scada.SIO_R11) === 1 ? <g id="grzalka_CO">
                        <path className="st10" d="M644.5,53.6v13.2c0,0.9-0.7,1.7-1.7,1.7h-23.4c-0.9,0-1.7-0.7-1.7-1.7V53.6c0-0.4,0.2-0.9,0.6-1.3l11.5-10
           l0,0c0.3-0.3,0.8-0.6,1.3-0.6s0.9,0.2,1.3,0.4l0,0l11.7,10.2c0.1,0.1,0.2,0.3,0.2,0.4l0,0l0,0c0,0.1,0.1,0.2,0.1,0.3v0.1v0.1
           C644.4,53.6,644.5,53.6,644.5,53.6L644.5,53.6z"/>
                        <g>
                            <path className="st10" d="M670.2,59.6c-0.1,2.3-0.4,4.6-1.8,6.7c-1,1.5-2.4,2.4-4.3,2.1c-1.1-0.2-2-0.8-2.7-1.7
               c-1.2-1.5-1.8-3.4-1.9-5.3c-0.5-5.7,1.1-10.8,4.6-15.2c1.7-2.2,4-3.4,6.9-3.2c1.6,0.1,3,0.6,4.3,1.5c0.3,0.2,0.4,0.4,0.2,0.8
               c-0.2,0.4-0.5,0.4-0.9,0.2c-1-0.7-2.1-1.1-3.2-1.2c-2.4-0.3-4.4,0.6-6.1,2.4c-1.5,1.6-2.5,3.6-3.3,5.7c-1.1,2.8-1.7,5.7-1.4,8.7
               c0.2,1.8,0.6,3.5,1.7,5c0.7,0.9,1.6,1.6,2.9,1.4c1.2-0.1,1.9-0.9,2.5-1.8c0.9-1.4,1.3-3,1.4-4.7c0.3-4.6-1-8.8-3.5-12.7
               c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.1-0.6,0.2-0.8c0.3-0.2,0.6-0.1,0.7,0.2c0.6,1.1,1.3,2.2,1.9,3.4
               C669.4,53.5,670.1,56.4,670.2,59.6"/>
                            <path className="st10" d="M681.8,59.6c0,2.3-0.4,4.7-1.8,6.8c-1,1.5-2.4,2.3-4.3,2c-1.5-0.2-2.5-1.2-3.2-2.4c-1.3-2.2-1.6-4.6-1.5-7.1
               c0.2-4.1,1.5-7.9,3.7-11.4c1.1-1.8,2.6-3.2,4.6-4c2.7-1,5.2-0.6,7.6,1c0.3,0.2,0.4,0.4,0.2,0.7c-0.2,0.3-0.5,0.3-0.8,0.2
               c-0.7-0.3-1.3-0.7-2-0.9c-2.9-0.9-5.4-0.1-7.5,2.1c-2,2.1-3.1,4.8-4,7.6c-0.7,2.2-1,4.5-0.8,6.7c0.2,1.7,0.5,3.4,1.6,4.9
               c0.5,0.7,1,1.2,1.8,1.5c1.3,0.5,2.7-0.1,3.7-1.6c1-1.4,1.4-3.1,1.5-4.8c0.3-4.6-1-8.8-3.4-12.7c0-0.1-0.1-0.1-0.2-0.2
               c-0.2-0.3-0.2-0.6,0.1-0.8c0.3-0.2,0.6-0.1,0.8,0.2c0.4,0.7,0.9,1.5,1.3,2.2C680.8,52.7,681.8,56,681.8,59.6"/>
                            <path className="st10" d="M693.4,59.7c0,2-0.3,4.2-1.5,6.1c-0.7,1.3-1.7,2.3-3.2,2.5c-1.8,0.3-3.2-0.4-4.2-1.9
               c-1.1-1.5-1.6-3.2-1.8-5.1c-0.5-5.6,1.1-10.5,4.4-15c1.4-1.8,3.2-3.1,5.5-3.4c2.1-0.3,4.1,0.2,5.9,1.4c0.3,0.2,0.4,0.4,0.2,0.8
               c-0.2,0.3-0.5,0.3-0.8,0.2c-0.6-0.3-1.2-0.7-1.9-0.9c-3-1-5.5-0.1-7.6,2.1c-1.6,1.7-2.7,3.9-3.5,6.1c-1,2.6-1.5,5.4-1.3,8.2
               c0.2,1.9,0.6,3.7,1.8,5.2c1.5,1.7,3.4,1.7,4.9,0c1-1.2,1.5-2.7,1.7-4.3c0.6-5-0.8-9.5-3.4-13.7c-0.1-0.2-0.2-0.4-0.2-0.6
               c0-0.2,0.3-0.4,0.5-0.4c0.2,0,0.5,0.1,0.6,0.3c0.6,1,1.2,2,1.7,3C692.7,53.3,693.4,56.3,693.4,59.7"/>
                            <path className="st10" d="M659.1,43c1.7,0,3.2,0.5,4.5,1.4c0.3,0.2,0.5,0.5,0.3,0.8c-0.2,0.3-0.5,0.3-0.9,0.1c-2.6-1.8-5.3-1.6-7.9,0
               c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.3-0.4-0.5c0-0.2,0.1-0.4,0.3-0.5C655.7,43.5,657.4,43,659.1,43"/>
                        </g>
                    </g> : null}
                    <text x="100" y="65" className="text-scada">{messages.scada.TSM9}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    <text x="265" y="65" className="text-scada">{messages.scada.TSM10}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    {!messages.scada.ADC_3 || messages.scada.ADC_3 === "NA" ? null : <text x="100" y="110" className="text-scada">{messages.scada.ADC_3} bar</text>}
                    
                    <text x="265" y="455" className="text-scada">Pompa</text>
                    <text x="265" y="480" className="text-scada">cyrkulacyjna CWU</text>
                    <text x="620" y="455" className="text-scada">Pompa CO</text>
                    <text x="705" y="160" className="text-scada">{parseInt(messages.scada.SIO_R09) === 1 ? "CWU" : "CO"}</text>
                    <text x="485" y="420" className="text-scada middle">bufor CO</text>
                    {parseInt(messages.scada.TSM17) <= -45 || parseInt(messages.scada.TSM17) >= 100 ? null : <text x="495" y="460" className="text-scada big">{messages.scada.TSM17}<tspan baselineShift="sup">&deg;</tspan>C</text>}
                    <text x="325" y="145" className="text-scada">{parseInt(messages.scada.SIO_R06) === 1 ? "ON" : "OFF"}</text>
                    <text x="100" y="140" className={`text-scada ${parseInt(messages.scada.SIO_R07) === 1 ? "white" : "grey"}`}>{messages.scada.T3fiz}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    <text x="100" y="230" className={`text-scada ${parseInt(messages.scada.SIO_R07) === 1 ? "white" : "grey"}`}>{messages.scada.T4fiz}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    <text x="610" y="140" className={`text-scada ${parseInt(messages.scada.SIO_R08) === 1 ? "white" : "grey"}`}>{messages.scada.T6fiz}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    <text x="610" y="230" className={`text-scada ${parseInt(messages.scada.SIO_R08) === 1 ? "white" : "grey"}`}>{messages.scada.T5fiz}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    <text x="155" y="420" className="text-scada middle">CWU</text>
                    <text x="140" y="460" className="text-scada big">{messages.scada.TSM15}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    <text x="385" y="85" className="text-scada">{parseFloat(messages.scada.TSM16)}<tspan baselineShift="sup">&deg;</tspan>C</text>
                    {!messages.scada.ADC_1 || messages.scada.ADC_1 === "NA" ? null : <text x="500" y="495" className="text-scada middle">{messages.scada.ADC_1} bar</text>}
                    
                    {!messages.scada.ADC_2 || messages.scada.ADC_2 === "NA" ? null : <text x="145" y="495" className="text-scada middle">{messages.scada.ADC_2} bar</text>}
                    
                </g>
            </svg>
                        : <Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "25px 0" }}>Scada niedostępna dla wybranego urzędzenia.</Typography>}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}><Typography variant="h3" className='text-scada' style={{ color: "#fff", margin: "15px 0 0" }}>Press ESC to exit</Typography></Grid>
                <Grid item xs={6}>
                    {messages &&  (messages.device.Name === "ATMO_1" || messages.device.Name === "ALLINONE_1" || messages.device.Name === "GEO_1") ? <Grid container style={{ paddingLeft: "50px" }}>
                        <Box component="span" xs={{ p: 1, border: '1px dashed grey' }}>
                            {messages.scada.REW_ERR === 1 || messages.scada.REW_WRN === 1 ? 
                                <img alt="Alarm - flaga błędu lub ostrzeżenia" className='small-icon' src={alarm} /> : <img alt="" className='small-icon' src={hl} />}
                        </Box>
                        <Box component="span" xs={{ p: 1, border: '1px dashed grey' }}>
                            {messages.scada.SXF_ENACYR === 1 && (messages.scada.SXF_FLPCYR === 1 || messages.scada.SXF_TIMCYR === 1 || messages.scada.SXF_FORCYR === 1) ?
                                <img alt="Cyrkulacja" className='small-icon' src={cyrkulacja} />
                                : <img alt="" className='small-icon' src={hl} />
                            }
                        </Box>
                        <Box component="span" xs={{ p: 1, border: '1px dashed grey' }}>
                            {messages.scada.SBF_OOF === 1 && messages.scada.SXF_TIME === 1 ?
                                <img alt="Tryb TIME" className='small-icon' src={time} />
                                : messages.scada.SBF_OOF === 1 && messages.scada.SXF_AWA === 1 ?
                                    <img alt="Tryb AWAY" className='small-icon' src={away} />
                                    : messages.scada.SBF_OOF === 1 && messages.scada.SXF_ECO === 1 && messages.scada.SXF_AWA === 0 ?
                                        <img alt="Tryb ECO" className='small-icon' src={eco} />
                                        : messages.scada.SBF_OOF === 1 && messages.scada.SXF_ECO === 0 && messages.scada.SXF_AWA === 0 && messages.scada.SXF_TIME === 0 ?
                                            <img alt="Tryb AUTO" className='small-icon' src={auto} />
                                            : <img alt="Wyłączenie" className='small-icon' src={off} />
                            }
                        </Box>
                        <Box component="span" xs={{ p: 1, border: '1px dashed grey' }}>
                            {messages.scada.SBF_HTS === 0 && messages.scada.SBF_ACS === 0 ?
                                <img alt="Realizowana funkcja" className='small-icon' src={grz_chl_off} />
                                : messages.scada.SBF_HTS === 1 && messages.scada.SBF_ACS === 1 ?
                                    <img alt="Realizowana funkcja" className='small-icon' src={grzanie_chlodzenie} />
                                    : messages.scada.SBF_HTS === 1 && messages.scada.SBF_ACS === 0 ?
                                        <img alt="Realizowana funkcja" className='small-icon' src={grzanie} />
                                        : <img alt="Realizowana funkcja" className='small-icon' src={chlodzenie} />
                            }
                        </Box>
                        <Box component="span" xs={{ p: 1, border: '1px dashed grey' }}>
                            {messages.scada.SBF_DHS === 1 && messages.scada.SBF_DHX === 1 && messages.scada.SBF_OOF === 1 ?
                                messages.scada.SBF_PHT === 1 ?
                                    <img alt="Antylegionella" className='small-icon' src={cwu_a_legionella} />
                                    : messages.scada.TURBO_CWU === 1 ?
                                        <img alt="CWU TURBO" className='small-icon' src={cwu_turbo} />
                                        : <img alt="Woda" className='small-icon' src={cwu} />
                                : <img alt="Woda" className='small-icon' src={cwu_off} />}
                        </Box>
                    </Grid> : null }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Scada