import React, { useState, useEffect } from 'react'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { Slider } from '@mui/material'
import { TimeCWUTemp } from '../'
import { useTheme } from '@material-ui/core/styles'

function valuetext(value) {
    return `${value}°C`;
}

const marks = () => {
    const marksTable = []

    for (let i = 0; i <= 24; i++) {
        marksTable.push({ value: i, label: `${i}` })
    }

    return marksTable
}

const minDistance = 1


const DayTab = ({ day, value, index, settings, stamp, setPublish, setTouchStatus, ...props }) => {
    const theme = useTheme()
    const [value1, setValue1] = useState([settings[`CW${stamp}2P_PG`], settings[`CW${stamp}2P_KG`] === 23 && settings[`CW${stamp}2P_KM`] === 60 ? 24 : settings[`CW${stamp}2P_KG`]])
    const [trackColors, setTrackColors] = useState({ 
        start: (settings[`CW${stamp}2P_PG`] / 24) * 100, 
        stop: settings[`CW${stamp}2P_KG`] === 23 && settings[`CW${stamp}2P_KM`] === 60 ? 100 : (settings[`CW${stamp}2P_KG`] / 24) * 100 
    })
    const handleChange1 = (event, newValue, activeThumb) => {
        setTouchStatus(true)
        if (!Array.isArray(newValue)) {
            return
        }

        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance) === 0 ? 1 : Math.min(newValue[0], value1[1] - minDistance), value1[1]])
            setTrackColors({start: ((Math.min(newValue[0], value1[1] - minDistance) === 0 ? 1 : Math.min(newValue[0], value1[1] - minDistance)) / 24) * 100, stop: (value1[1] / 24) * 100})
        } else {
            setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance) === 24 ? 23 : Math.max(newValue[1], value1[0] + minDistance)])
            setTrackColors({start: (value1[0] / 24) * 100, stop: ((Math.max(newValue[1], value1[0] + minDistance) === 24 ? 23 : Math.max(newValue[1], value1[0] + minDistance)) / 24) * 100})
        }
    }

    const handleTouchMouseEnd = (event, newValue) => {
        setTouchStatus(false)
        if (newValue[1] === 24) {
            setPublish({ 
                [`CW${stamp}1P_KG`]: newValue[0] === 0 ? 1 : newValue[0], 
                [`CW${stamp}2P_PG`]: newValue[0] === 0 ? 1 : newValue[0], 
                [`CW${stamp}2P_KG`]: 23, 
                [`CW${stamp}2P_KM`]: 0, 
                [`CW${stamp}3P_PG`]: 23, 
                [`CW${stamp}3P_PM`]: 0, 
            })
        } else {
            setPublish({ 
                [`CW${stamp}1P_KG`]: newValue[0] === 0 ? 1 : newValue[0], 
                [`CW${stamp}2P_PG`]: newValue[0] === 0 ? 1 : newValue[0], 
                [`CW${stamp}2P_KG`]: newValue[1] === 24 ? 23 : newValue[1], 
                [`CW${stamp}3P_PG`]: newValue[1] === 24 ? 23 : newValue[1],
                [`CW${stamp}2P_KM`]: 0, 
                [`CW${stamp}3P_PM`]: 0, 
            })
        }
        setTouchStatus(false)
    }
    useEffect(() => {
        let mounted = true
        setValue1([settings[`CW${stamp}2P_PG`], settings[`CW${stamp}2P_KG`] === 23 && settings[`CW${stamp}2P_KM`] === 60 ? 24 : settings[`CW${stamp}2P_KG`]])
        setTrackColors({ 
            start: (settings[`CW${stamp}2P_PG`] / 24) * 100, 
            stop: settings[`CW${stamp}2P_KG`] === 23 && settings[`CW${stamp}2P_KM`] === 60 ? 100 : (settings[`CW${stamp}2P_KG`] / 24) * 100 
        })
        return () => {
            mounted = false
        }
    }, [settings, stamp])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...props}>
            {value === index && (<React.Fragment>
                <div style={{ padding: '120px 50px 0' }}>
                <Slider
                getAriaLabel={() => 'Minimum distance'}
                value={value1}
                onChange={handleChange1}
                onChangeCommitted={handleTouchMouseEnd}
                getAriaValueText={valuetext}
                max={24}
                min={0}
                marks={marks()}
                valueLabelDisplay="on"
                track="inverted"
                sx={{
                    '& .MuiSlider-track': {
                         background: `${theme.tempcolors[`color${settings.temp[`CW${stamp}2T`] <= 35? 35 : settings.temp[`CW${stamp}2T`] >= 55? 55 : settings.temp[`CW${stamp}2T`]}`]}`,
                    },
                    '& .MuiSlider-rail': {
                        background: `linear-gradient(90deg, 
                            ${theme.tempcolors[`color${settings.temp[`CW${stamp}1T`] <= 35? 35 : settings.temp[`CW${stamp}1T`] >= 55? 55 : settings.temp[`CW${stamp}1T`]}`]}, 
                            ${theme.tempcolors[`color${settings.temp[`CW${stamp}1T`] <= 35? 35 : settings.temp[`CW${stamp}1T`] >= 55? 55 : settings.temp[`CW${stamp}1T`]}`]} ${trackColors.start}%, 
                            ${theme.tempcolors[`color${settings.temp[`CW${stamp}2T`] <= 35? 35 : settings.temp[`CW${stamp}2T`] >= 55? 55 : settings.temp[`CW${stamp}2T`]}`]} ${trackColors.start}%, 
                            ${theme.tempcolors[`color${settings.temp[`CW${stamp}2T`] <= 35? 35 : settings.temp[`CW${stamp}2T`] >= 55? 55 : settings.temp[`CW${stamp}2T`]}`]} ${trackColors.stop}%, 
                            ${theme.tempcolors[`color${settings.temp[`CW${stamp}3T`] <= 35? 35 : settings.temp[`CW${stamp}3T`] >= 55? 55 : settings.temp[`CW${stamp}3T`]}`]} ${trackColors.stop}%)`,
                    },
                }}
            />
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <TimeCWUTemp settings={settings.temp} stamp={stamp} setPublish={setPublish} />
                    </Grid>
                </Grid>
            </React.Fragment>)}
        </div>
    )
}

export default DayTab