import React, { useState, useEffect, useReducer } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Navbar from 'react-bootstrap/Navbar'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import timeIcon from '../../../../../../img/TIME2.svg'
import cwuIcon from '../../../../../../img/Temp-CWU2.svg'
import domIcon from '../../../../../../img/Temp-dom2.svg'
import cwuIcon2 from '../../../../../../img/Temp-CWU.svg'
import domIcon2 from '../../../../../../img/Temp-dom.svg'
import { default as ControllerTime } from '../../../ControllerTime'

import { TimeZone } from './components'


const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else if (action.case === "DAY_SWITCH") {
        return {
            ...state,
            [action.blok]: {
                ...state[action.blok],
                [action.stamp]: {
                    ...state[action.blok][action.stamp],
                    [`${action.blok}WTSW_${action.stamp}`]: action.value
                }
            }
        }
    } else {
        return {
            ...state,
            [action.blok]: {
                ...state[action.blok],
                [action.stamp]: {
                    ...state[action.blok][action.stamp],
                    [`${action.blok}W${action.num}`]: {
                        ...state[action.blok][action.stamp][`${action.blok}W${action.num}`],
                        [action.type]: action.value
                    }
                }
            }
        }
    }
}


const DayTab = ({handleChangeSwitchDay, ...props}) => {
            return (
                <Tab.Pane transition={false} eventKey={props.day}>
                    <Row className="gray-bg text-center">
                        <Col md={2} xs={2} className="text-left np">
                            {props.daySwitchT === 0 ?
                                <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 1, stamp: props.day, blok: "T" })} className="mode-icon" src={domIcon} />
                                : <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 0, stamp: props.day, blok: "T" })} className="mode-icon" src={domIcon2} />}
    
                            <p className="float-right d-none d-md-block">Nr</p>
                        </Col>
                        <Col md={2} xs={3}>
                            <p className="text-center">Start</p>
                        </Col>
                        <Col md={2} xs={3}>
                            <p className="text-center">Stop</p>
                        </Col>
                        <Col md={2} xs={3}>
                            <p className="text-center">Temperatura</p>
                        </Col>
                    </Row>
                    <TimeZone daySwitch={props.daySwitchT} handleChangeTime={props.handleChangeTime} timeZone={props.timeZone.T} day={props.day} num={1} stamp={props.stamp} blok={'T'} />
                    <TimeZone daySwitch={props.daySwitchT} handleChangeTime={props.handleChangeTime} timeZone={props.timeZone.T} day={props.day} num={2} stamp={props.stamp} blok={'T'} />
                    <TimeZone daySwitch={props.daySwitchT} handleChangeTime={props.handleChangeTime} timeZone={props.timeZone.T} day={props.day} num={3} stamp={props.stamp} blok={'T'} />
                    <Row className="gray-bg text-center">
                        <Col md={2} xs={2} className="text-left np">
                            {props.daySwitchC === 0 ?
                                <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 1, stamp: props.day, blok: "C" })} className="mode-icon" src={cwuIcon} />
                                : <img alt="time" onClick={() => handleChangeSwitchDay({ case: "DAY_SWITCH", value: 0, stamp: props.day, blok: "C" })} className="mode-icon" src={cwuIcon2} />}
    
                            <p className="float-right d-none d-md-block">Nr</p>
                        </Col>
                        <Col md={2} xs={3}>
                            <p className="text-center">Start</p>
                        </Col>
                        <Col md={2} xs={3}>
                            <p className="text-center">Stop</p>
                        </Col>
                        <Col md={2} xs={3}>
                            <p className="text-center">Temperatura</p>
                        </Col>
                    </Row>
                    <TimeZone daySwitch={props.daySwitchC} handleChangeTime={props.handleChangeTime} timeZone={props.timeZone.C} day={props.day} num={1} stamp={props.stamp} blok={'C'} />
                    <TimeZone daySwitch={props.daySwitchC} handleChangeTime={props.handleChangeTime} timeZone={props.timeZone.C} day={props.day} num={2} stamp={props.stamp} blok={'C'} />
                    <TimeZone daySwitch={props.daySwitchC} handleChangeTime={props.handleChangeTime} timeZone={props.timeZone.C} day={props.day} num={3} stamp={props.stamp} blok={'C'} />
                </Tab.Pane>
            )
        }
const Time = ({ time, setPublish, controllertime }) => {
    const [controller, setTimeZoneState] = useReducer(switchOption, time)
    const [state, setState] = useState(null)

    useEffect(() => {
        setTimeZoneState({ payload: time, case: "UPDATE_FROM_BROKER" })
    }, [time])

    const handleChangeTime = (e) => {
        setTimeZoneState({ type: e.type, stamp: e.stamp, num: e.num, blok: e.blok, value: parseInt(e.value) })

        if (e.type === "Temp") {
            handleSendTimeZoneState({ id: `${e.blok}W${e.more}${e.num}T`, value: parseInt(e.value) }, setState)
        } else {
            handleSendTimeZoneState({ id: `${e.blok}W${e.more}${e.num}P_${e.type}`, value: parseInt(e.value) }, setState)
        }
    }

    const handleChangeSwitchDay = (e) => {
        setTimeZoneState({ case: e.case, stamp: e.stamp, blok: e.blok, value: parseInt(e.value) })

        handleSendTimeZoneState({ id: `${e.blok}WTSW_${e.stamp}`, value: parseInt(e.value) }, setState)
    }

    const handleSendTimeZoneState = (e, setState) => {
        const sendKey = e.id;
        const sendData = e.value;

        setPublish({ [sendKey]: sendData })
    }

    return (
        <React.Fragment>
            <Row>
                <Navbar bg="primary" variant="dark" style={{ width: "100%" }}>
                    <Navbar.Brand className="mr-auto"><img alt="tryb time" className="mode-icon" src={timeIcon} /></Navbar.Brand>
                    <Navbar.Brand>TIME</Navbar.Brand>
                </Navbar>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="PN">
                <Row className="bottom-margin">
                    <Col sm={12} className="np">
                        <Nav variant="pills" className="flex" fill>
                            <Nav.Item>
                                <Nav.Link eventKey="PN">PN</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="WT">WT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="SR">ŚR</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="CZ">CZ</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="PT">PT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="SO">SB</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="ND">ND</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="np">
                        <Tab.Content>
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.PN.TWTSW_PN} daySwitchC={controller.C.PN.CWTSW_PN} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.PN, C: controller.C.PN}} day={'PN'} stamp={'P'} />
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.WT.TWTSW_WT} daySwitchC={controller.C.WT.CWTSW_WT} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.WT, C: controller.C.WT}} day={'WT'} stamp={'W'} />
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.SR.TWTSW_SR} daySwitchC={controller.C.SR.CWTSW_SR} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.SR, C: controller.C.SR}} day={'SR'} stamp={'S'} />
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.CZ.TWTSW_CZ} daySwitchC={controller.C.CZ.CWTSW_CZ} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.CZ, C: controller.C.CZ}} day={'CZ'} stamp={'C'} />
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.PT.TWTSW_PT} daySwitchC={controller.C.PT.CWTSW_PT} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.PT, C: controller.C.PT}} day={'PT'} stamp={'PT'} />
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.SO.TWTSW_SO} daySwitchC={controller.C.SO.CWTSW_SO} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.SO, C: controller.C.SO}} day={'SO'} stamp={'SO'} />
                            <DayTab handleChangeSwitchDay={handleChangeSwitchDay} daySwitchT={controller.T.ND.TWTSW_ND} daySwitchC={controller.C.ND.CWTSW_ND} handleChangeTime={handleChangeTime} timeZone={{T: controller.T.ND, C: controller.C.ND}} day={'ND'} stamp={'N'} />                           
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <Row>
                <Col><br className="d-none d-md-block"/><br />
                    <ControllerTime noTitle={true} controllertime={controllertime} setPublish={setPublish} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Time