const fetchRegonApi = async (nip) => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/nip/${nip}`, {
        method: "GET",
        headers: {
            // 'Content-Type': 'application/json'
        }
    }).then(res => res.json())
    

    return response
}

export default fetchRegonApi