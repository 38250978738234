import React, {useState, useEffect} from 'react'
import { mainStyleClasses } from '../../theme'
import {  AppBar } from '@material-ui/core'
import { BufferCO, Circulation, PeakSource, Circuits, InternalTemp, HeatingCircuits, Dr4Controll, CWUCO } from './components'
import { TabPanel, Tab, Tabs } from '../../components'

const DevicesPeriphery = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [value, setValue] = useState(0);
    const deviceName = sessionStorage.getItem('deviceName')

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <AppBar position="static" style={{ boxShadow: "none" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Cyrkulacja" />
                    <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Żródło szczytowe" />
                    <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Bufor C.O." />
                    <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Obiegi odbiorcze" />
                    <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Temp. wewnętrzna" />
                    <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Obiegi grzewcze" />
                    {deviceName === 'GEO_1' ?
                        <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Kontrola zaworu 4-DR" />
                        : null
                    }
                    {deviceName === 'GEO_1' ?
                        <Tab style={{ maxWidth: '12.5%', minWidth: '12.5%', lineHeight: '16px' }} label="Rozdział cwu-C.O." />
                        : null
                    }
                </Tabs> 
            </AppBar>
            <TabPanel value={value} index={0}>
                <Circulation settings={settings.circulation} setPublish={setPublish} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PeakSource settings={settings.peakSource} setPublish={setPublish} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <BufferCO settings={settings.coopInstallation} setPublish={setPublish} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Circuits settings={settings.circuits} setPublish={setPublish} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <InternalTemp settings={settings.sensorInside} setPublish={setPublish} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <HeatingCircuits settings={settings.heatingCircuits} setPublish={setPublish} />
            </TabPanel>
            {deviceName === 'GEO_1' ?
            <TabPanel value={value} index={6}>
                <Dr4Controll settings={settings.dr4Valve} setPublish={setPublish} />
            </TabPanel>
            : null
            }   
            {deviceName === 'GEO_1' ?
            <TabPanel value={value} index={7}>
                <CWUCO settings={settings.cwuco} setPublish={setPublish} />
            </TabPanel>
            : null
            }
            {/*<Grid container>
                <Grid item xs={3}>
                    <Typography variant="body2">Peryferia:</Typography>
                    <Typography variant="body1">(Wybierz, żeby zobaczyć szczegóły)</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Button variant="contained" color="primary" component={Link} to="/periphery/cwu">Cyrkulacja</Button>
                    <Button variant="contained" color="primary" style={{ margin: "5px 20px" }} component={Link} to="/periphery/peaksource">Źródło szczytowe</Button>
                    <Button variant="contained" color="primary" style={{ margin: "5px 20px 5px 0" }} component={Link} to="/periphery/bufferco">Bufor c.o.</Button>
                    <Button variant="contained" color="primary" style={{ margin: "5px 20px 5px 0" }} component={Link} to="/periphery/circuits">Obiegi odbiorcze</Button>
                    <Button variant="contained" color="primary" component={Link} to="/periphery/internaltemp">Temp. wewnętrzna</Button>
                    <Button variant="contained" color="primary" component={Link} to="/periphery/heatingcircuits">Obiegi grzewcze</Button>
                </Grid>
            </Grid>

            <Switch>
                <Route path="/periphery/cwu"><Circulation settings={settings.circulation} setPublish={setPublish} /></Route>
                <Route path="/periphery/peaksource"><PeakSource settings={settings.peakSource} setPublish={setPublish} /></Route>
                <Route path="/periphery/bufferco"><BufferCO settings={settings.coopInstallation} setPublish={setPublish} /></Route>
                <Route path="/periphery/circuits"><Circuits settings={settings.circuits} setPublish={setPublish} /></Route>
                <Route path="/periphery/internaltemp"><InternalTemp settings={settings.sensorInside} setPublish={setPublish} /></Route>
                <Route path="/periphery/heatingcircuits"><HeatingCircuits settings={settings.heatingCircuits} setPublish={setPublish} /></Route>
            </Switch>*/}
        </div>
    )
}

export default DevicesPeriphery