import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
    Grid, Button, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Dialog, DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { NewInstallator, InstallatorDetails, InstallatorsFromFile } from './components'
import { mainStyleClasses } from '../../theme'
import { fetchInstallatorsList, fetchCertInstallator, fetchPartnersList } from '../../data'
import { saveAs } from 'file-saver'

const filteredInstallatorsList = (installatorsList, filter, partnersList, getCertificate) => {
    let installators, installatorsNewList

    switch (filter.searchKey) {
        case "all":
            installatorsNewList = installatorsList.map((installator, index) => <InstallatorsItem getCertificate={getCertificate} partnersList={partnersList} key={installator.id} installator={installator} index={index} />)
            break;
        case "name":
            installators = installatorsList.filter(installator => (installator.first_name.toUpperCase().includes(filter.searchValue) || installator.last_name.toUpperCase().includes(filter.searchValue)) === true)
            break;
        case "phone":
            installators = installatorsList.filter(installator => installator.phone.toUpperCase().includes(filter.searchValue) === true)
            break;
        case "email":
            installators = installatorsList.filter(installator => installator.email.toUpperCase().includes(filter.searchValue) === true)
            break;
        case "company":
            installators = installatorsList.filter(installator => (installator.partners[0] && (installator.partners[0].name.toUpperCase().includes(filter.searchValue) || installator.partners[0].nip.toUpperCase().includes(filter.searchValue))) === true)
            break;
        case "address":
            installators = installatorsList.filter(installator => (installator.partners[0] && (installator.partners[0].street.toUpperCase().includes(filter.searchValue) || installator.partners[0].city.toUpperCase().includes(filter.searchValue) || installator.partners[0].post_code.toUpperCase().includes(filter.searchValue))) === true)
            break;
        default:
            installatorsNewList = installatorsList.map((installator, index) => <InstallatorsItem getCertificate={getCertificate} partnersList={partnersList} key={installator.id} installator={installator} index={index} />)
            break;
    }

    if (installators)
        installatorsNewList = installators.map((installator, index) => (
            <InstallatorsItem getCertificate={getCertificate} partnersList={partnersList} key={installator.id} installator={installator} index={index} />
        ))
    return installatorsNewList
}

const InstallatorsItem = ({ installator, index, partnersList, getCertificate }) => {
    let { url } = useRouteMatch()

    const parent_partner = installator.partners.length > 0 ? partnersList.filter(partner => partner.id === installator.partners[0].parent_partner_id) : null

    return (
        <TableRow>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{installator.first_name} {installator.last_name}</TableCell>
            <TableCell>{installator.phone}</TableCell>
            <TableCell>{installator.email}</TableCell>
            <TableCell> {installator.partners.length > 0 ? `${installator.partners[0].name} nip: ${installator.partners[0].nip}` : null}</TableCell>
            <TableCell> {installator.partners.length > 0 ? `${installator.partners[0].street} ${installator.partners[0].street_number}, ${installator.partners[0].post_code} 
            ${installator.partners[0].city}` : null}</TableCell>
            <TableCell> {installator.partners.length > 0 ? installator.partners[0].parent_partner_id ? parent_partner[0].name
                : "bezpośrednio" : null}</TableCell>
            <TableCell><Link to={`${url}/szczegoly/${installator.id}`}>
                <Button variant="contained" color="primary">szczegóły</Button></Link><br /><br />
                <Button variant="contained" color="primary" onClick={() => getCertificate(installator.id, `${installator.first_name}_${installator.last_name}`)}>Certyfikat</Button>
                </TableCell>
        </TableRow>
    )
}

const Installators = () => {
    const classes = mainStyleClasses()
    const [installatorsList, setInstallatorsList] = useState(null)
    const [partnersList, setPartnersList] = useState(null)
    const [open, setOpen] = useState({ status: false, new: false, edit: false, index: null })
    const [open2, setOpen2] = useState({ status: false, edit: [] })
    const [filter, setFilter] = useState({ searchKey: "all", searchValue: "" })

    const handleClickOpen = (type, index) => {
        setOpen({ ...open, [type]: true, status: true, index: index ? index : null })
    }

    const handleClickOpen2 = () => {
        setOpen2({ ...open2, status: true })
    }

    const handleClose = () => {
        setOpen({ status: false, new: false, edit: false, index: null })
    }

    const handleClose2 = () => {
        setOpen2({ ...open2, status: false })
    }

    const refreshPartnersList = () => {
        fetchPartnersList().then(res => setPartnersList(res.data))
    }

    const refreshInstallatorsList = () => {
        fetchInstallatorsList().then(res => {
            setInstallatorsList(res)
        })
    }

    useEffect(() => {
        let mounted = true

        refreshInstallatorsList()
        refreshPartnersList()

        return () => {
            mounted = false
        }
    }, [])

    const filterInstalatorsList = (e) => {
        if (e.target.value === "")
            setFilter({ searchKey: "all", searchValue: "" })
        else
            setFilter({ searchKey: e.target.name, searchValue: e.target.value.toUpperCase() })
    }
    
    const getCertificate = async(id, name) => {
        const blobCertificate = await fetchCertInstallator(id).then(res => res)
        const file = new Blob([blobCertificate], {type: 'application/pdf'})
        //let fileURL = URL.createObjectURL(file);
        saveAs(file, `certyfikat_${name}.pdf`)
    //   window.open(fileURL);
    //    return file
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={7}>
                    <Typography variant="h2">Lista instalatorów</Typography>
                </Grid>
                <Grid item xs={3}><br />
                    {/*<Button className={classes.floatRight} variant="contained" color="primary" onClick={() => handleClickOpen2()}>Nowi instalatorzy z pliku</Button>*/}
                </Grid>
                <Grid item xs={2}><br />
                    <Button className={classes.floatRight} variant="contained" color="primary" onClick={() => handleClickOpen("new")}>Nowy instalator</Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    <Box fontWeight="fontWeightBold">
                                                Lp
                                            </Box>
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="Osoba" name="name" type="text" onChange={(e) => filterInstalatorsList(e)}
                                            value={filter.searchKey === "name" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="Telefon" name="phone" type="text" onChange={(e) => filterInstalatorsList(e)}
                                            value={filter.searchKey === "phone" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="E-mail" name="email" type="text" onChange={(e) => filterInstalatorsList(e)}
                                            value={filter.searchKey === "email" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField label="Firma" name="company" type="text" onChange={(e) => filterInstalatorsList(e)}
                                            value={filter.searchKey === "company" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                    <TextField label="Adres" name="address" type="text" onChange={(e) => filterInstalatorsList(e)}
                                    value={filter.searchKey === "address" ? filter.searchValue : ""} />
                                    </TableCell>
                                    <TableCell>
                                    <Box fontWeight="fontWeightBold">
                                                Umowa
                                            </Box>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {installatorsList && installatorsList.data && partnersList ? filteredInstallatorsList(installatorsList.data, filter, partnersList, getCertificate) : <TableRow>
                                    <TableCell colSpan={7}>W bazie nie ma zarejestrowanych instalatorów.</TableCell></TableRow>}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {<Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    {open.new ? <NewInstallator partnersList={partnersList} refreshInstallersList={refreshInstallatorsList} handleClose={handleClose} /> : null}
                    {open.edit ? <InstallatorDetails /> : null}
                </DialogContent>
            </Dialog>}
            {<Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open2.status} onClose={handleClose2} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose2} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <InstallatorsFromFile partnersList={partnersList} refreshInstallatorsList={refreshInstallatorsList} />
                </DialogContent>
            </Dialog>}
        </React.Fragment>
    )
}

export default Installators