import React from 'react'
import { MenuItem } from '@material-ui/core'

const OptionList = ({ start, stop, nameKey, multiplier, timeFormat, degreesFormat }) => {
    const items = [];
    for (let i = start; i <= stop; i++) {
        if (multiplier) {
            items.push(<option key={nameKey + i} value={i * 10}> {i * 10} </option>);
        } else if (timeFormat) {
            items.push(<option key={nameKey + i} value={i}> {`${i}:00`} </option>);
        } else if (degreesFormat){
            items.push(<option key={nameKey + i} value={i}> {`+${i} `}&#x2103; </option>);
        } else {
            items.push(<option key={nameKey + i} value={i}> {i} </option>);
        }
    }

    return items;
}
export default OptionList;