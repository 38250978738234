import { dateConvert } from "../../functions"

const actualDate = new Date()

const templateMsg = (type, data) => {
    let contentMsg = JSON.parse(data.content)
    let noteDate = new Date(data.date)
    switch (type) {
        case "emailClient":
            return  `Informacja dotycząca zgłoszenia serwisowego. <br /><br />
                    <b>ID zgłoszenia:</b> ${data.id}<br/>
                    <b>Data zgłoszenia: </b>${dateConvert(noteDate).toLocaleString()}<br />
                    <b>Wiadomość: </b> ${data.statusMsg? `${data.statusMsg} <br />` : ''}  ${data.textMsg? data.textMsg : ''}`

        case "email":
            return  `Twoje zgłoszenie zostało zarejestrowane. <br /><br />
                    <b>ID zgłoszenia:</b> ${data.id}<br/>
                    <b>Data zgłoszenia: </b>${dateConvert(noteDate).toLocaleString()}<br />
                    <b>Imię i nazwisko: </b>${contentMsg[0].first_name} ${contentMsg[0].last_name}<br/>
                    <b>Adres montażu: </b>${contentMsg[0].street} ${contentMsg[0].street_number}, ${contentMsg[0].city} ${contentMsg[0].post_code}<br/>
                    <b>Tel.: </b>${contentMsg[0].phone}<br /> 
                    ${contentMsg[0].user_email? `<b>E-mail: </b>${contentMsg[0].user_email}<br />` : ''}
                    <b>Numer seryjny urządzenia: </b>${data.device_sn}<br />
                    ${contentMsg[0].outside_sn? `<b>Numer seryjny jednostki zewnętrznej: </b>${contentMsg[0].outside_sn}<br />` : ''}
                    <b>Klucz: </b>${data.key}<br />
                    <b>Zgoda na kontakt: </b>${contentMsg[0].confirmation}<br />
                    <b>Zgoda na badanie satysfakcji: </b>${contentMsg[0].confirmation2}<br />
                    <b>Treść zgloszenia: </b>${contentMsg[0].msg}<br /><br /> 
                    Dziękujemy za zgłoszenie. <br />`
            
        case "serwis":
            return  `Zarejestrowano nowe złoszenie. <br /><br />
                    <b>ID zgłoszenia</b> ${data.id}<br/>
                    <b>Data zgłoszenia: </b>${dateConvert(noteDate).toLocaleString()}<br />
                    <b>Imię i nazwisko: </b>${contentMsg[0].first_name} ${contentMsg[0].last_name}<br/>
                    <b>Adres montażu: </b>${contentMsg[0].street} ${contentMsg[0].street_number}, ${contentMsg[0].city} ${contentMsg[0].post_code}<br/>
                    <b>Tel.: </b>${contentMsg[0].phone}<br /> 
                    ${contentMsg[0].user_email? `<b>E-mail: </b>${contentMsg[0].user_email}<br />` : ''}
                    <b>Numer seryjny urządzenia: </b>${data.device_sn}<br />
                    ${contentMsg[0].outside_sn? `<b>Numer seryjny jednostki zewnętrznej: </b>${contentMsg[0].outside_sn}<br />` : ''}
                    <b>Klucz: </b>${data.key}<br />
                    <b>Zgoda na kontakt: </b>${contentMsg[0].confirmation}<br />
                    <b>Zgoda na badanie satysfakcji: </b>${contentMsg[0].confirmation2}<br />
                    <b>Treść zgloszenia: </b>${contentMsg[0].msg}<br /><br /> 
                    Zgłoszenie dostępne jest w panelu serwisowym. <br />`
            
        case "sms-push":
            return  `ID zgłoszenia:${data.id}.
            
${data.statusMsg? `${data.statusMsg}` : ''}  
${data.textMsg? data.textMsg : ''}`
            
    
        default:
            break;
    }
}

export default templateMsg