import fetchRefreshToken from './fetchRefreshToken'

const fetchConfigDevices = async () => {
  
    const response = await fetchRefreshToken().then(async newToken => {
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/devices_with_customers`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${newToken}`
        }
      }).then(response => response)
      
      return res
    })
    
      return response
  
  }


  export default fetchConfigDevices