import React from 'react'
import unplug from '../../img/unplug.svg'

const ErrorPage = () => {
    return (
        <React.Fragment>
            <img alt="trybauto" src={unplug} style={{ width: "95%", margin: "0 auto" }} />
        </React.Fragment>
    )
}

export default ErrorPage