import React, { useState } from 'react'
import { Button, Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import czas from '../../../../img/czas.svg'
import kalendarz from '../../../../img/kalendarz.svg'
import tzew from '../../../../img/t-zew.svg'
import twew from '../../../../img/t-wew.svg'
import gornewe from '../../../../img/gorne-we.svg'
import gornewy from '../../../../img/gorne-wy.svg'
import dolweAtmo from '../../../../img/dolne-we-atmo.svg'
import dolwyAtmo from '../../../../img/dolne-wy-atmo.svg'
import tcwu from '../../../../img/t-cwu.svg'
import tloczenie from '../../../../img/tloczenie.svg'

const deviceName = sessionStorage.getItem('deviceName')

const deletionErrorsState = (setPublish) => {
    setPublish({
        UWNA_1: 0,
        UWNA_2: 0,
        UWNA_3: 0,
        UWNA_4: 0,
        UWNA_5: 0,
        UWNA_6: 0,
        UWNA_7: 0,
        UWNA_8: 0,
        UWNA_9: 0,
        UWNA_10: 0,
        UWNB_1: 0,
        UWNB_2: 0,
        UWNB_3: 0,
        UWNB_4: 0,
        UWNB_5: 0,
        UWNB_6: 0,
        UWNB_7: 0,
        UWNB_8: 0,
        UWNB_9: 0,
        UWNB_10: 0,
        UWNC_1: 0,
        UWNC_2: 0,
        UWNC_3: 0,
        UWNC_4: 0,
        UWNC_5: 0,
        UWNC_6: 0,
        UWNC_7: 0,
        UWNC_8: 0,
        UWNC_9: 0,
        UWNC_10: 0,
        UWND_1: 0,
        UWND_2: 0,
        UWND_3: 0,
        UWND_4: 0,
        UWND_5: 0,
        UWND_6: 0,
        UWND_7: 0,
        UWND_8: 0,
        UWND_9: 0,
        UWND_10: 0,
        UWNE_1: 0,
        UWNE_2: 0,
        UWNE_3: 0,
        UWNE_4: 0,
        UWNE_5: 0,
        UWNE_6: 0,
        UWNE_7: 0,
        UWNE_8: 0,
        UWNE_9: 0,
        UWNE_10: 0,
        UWNF_1: 0,
        UWNF_2: 0,
        UWNF_3: 0,
        UWNF_4: 0,
        UWNF_5: 0,
        UWNF_6: 0,
        UWNF_7: 0,
        UWNF_8: 0,
        UWNF_9: 0,
        UWNF_10: 0,
        UWNG_1: 0,
        UWNG_2: 0,
        UWNG_3: 0,
        UWNG_4: 0,
        UWNG_5: 0,
        UWNG_6: 0,
        UWNG_7: 0,
        UWNG_8: 0,
        UWNG_9: 0,
        UWNG_10: 0,
        UWNH_1: 0,
        UWNH_2: 0,
        UWNH_3: 0,
        UWNH_4: 0,
        UWNH_5: 0,
        UWNH_6: 0,
        UWNH_7: 0,
        UWNH_8: 0,
        UWNH_9: 0,
        UWNH_10: 0,
        UWNI_1: 0,
        UWNI_2: 0,
        UWNI_3: 0,
        UWNI_4: 0,
        UWNI_5: 0,
        UWNI_6: 0,
        UWNI_7: 0,
        UWNI_8: 0,
        UWNI_9: 0,
        UWNI_10: 0,
        UWNJ_1: 0,
        UWNJ_2: 0,
        UWNJ_3: 0,
        UWNJ_4: 0,
        UWNJ_5: 0,
        UWNJ_6: 0,
        UWNJ_7: 0,
        UWNJ_8: 0,
        UWNJ_9: 0,
        UWNJ_10: 0,
        UWNK_1: 0,
        UWNK_2: 0,
        UWNK_3: 0,
        UWNK_4: 0,
        UWNK_5: 0,
        UWNK_6: 0,
        UWNK_7: 0,
        UWNK_8: 0,
        UWNK_9: 0,
        UWNK_10: 0,
        UWNL_1: 0,
        UWNL_2: 0,
        UWNL_3: 0,
        UWNL_4: 0,
        UWNL_5: 0,
        UWNL_6: 0,
        UWNL_7: 0,
        UWNL_8: 0,
        UWNL_9: 0,
        UWNL_10: 0,
        UWNM_1_GODZ: 0,
        UWNM_1_MIN: 0,
        UWNM_1_SEK: 0,
        UWNM_2_GODZ: 0,
        UWNM_2_MIN: 0,
        UWNM_2_SEK: 0,
        UWNM_3_GODZ: 0,
        UWNM_3_MIN: 0,
        UWNM_3_SEK: 0,
        UWNM_4_GODZ: 0,
        UWNM_4_MIN: 0,
        UWNM_4_SEK: 0,
        UWNM_5_GODZ: 0,
        UWNM_5_MIN: 0,
        UWNM_5_SEK: 0,
        UWNM_6_GODZ: 0,
        UWNM_6_MIN: 0,
        UWNM_6_SEK: 0,
        UWNM_7_GODZ: 0,
        UWNM_7_MIN: 0,
        UWNM_7_SEK: 0,
        UWNM_8_GODZ: 0,
        UWNM_8_MIN: 0,
        UWNM_8_SEK: 0,
        UWNM_9_GODZ: 0,
        UWNM_9_MIN: 0,
        UWNM_9_SEK: 0,
        UWNM_10_GODZ: 0,
        UWNM_10_MIN: 0,
        UWNM_10_SEK: 0,
        UWNN_1_DZ: 0,
        UWNN_1_MSC: 0,
        UWNN_1_ROK: 0,
        UWNN_1_AM_PM: 0,
        UWNN_2_DZ: 0,
        UWNN_2_MSC: 0,
        UWNN_2_ROK: 0,
        UWNN_2_AM_PM: 0,
        UWNN_3_DZ: 0,
        UWNN_3_MSC: 0,
        UWNN_3_ROK: 0,
        UWNN_3_AM_PM: 0,
        UWNN_4_DZ: 0,
        UWNN_4_MSC: 0,
        UWNN_4_ROK: 0,
        UWNN_4_AM_PM: 0,
        UWNN_5_DZ: 0,
        UWNN_5_MSC: 0,
        UWNN_5_ROK: 0,
        UWNN_5_AM_PM: 0,
        UWNN_6_DZ: 0,
        UWNN_6_MSC: 0,
        UWNN_6_ROK: 0,
        UWNN_6_AM_PM: 0,
        UWNN_7_DZ: 0,
        UWNN_7_MSC: 0,
        UWNN_7_ROK: 0,
        UWNN_7_AM_PM: 0,
        UWNN_8_DZ: 0,
        UWNN_8_MSC: 0,
        UWNN_8_ROK: 0,
        UWNN_8_AM_PM: 0,
        UWNN_9_DZ: 0,
        UWNN_9_MSC: 0,
        UWNN_9_ROK: 0,
        UWNN_9_AM_PM: 0,
        UWNN_10_DZ: 0,
        UWNN_10_MSC: 0,
        UWNN_10_ROK: 0,
        UWNN_10_AM_PM: 0
    })
}

const listItem = (items) => {
    let tableRowItem = []
    for (let i = 1; i < 11; i++) {
        tableRowItem.push(
            <TableRow key={`bledy_${i}`}>
                <TableCell style={{ textAlign: "center" }}>{i}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNM[`UWNM_${i}_GODZ`]}:{items.UWNM[`UWNM_${i}_MIN`]}:{items.UWNM[`UWNM_${i}_SEK`]} {parseInt(items.UWNN[`UWNN_${i}_AM/PM`]) === 1 ? "AM" : "PM"}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNN[`UWNN_${i}_DZ`]}.{items.UWNN[`UWNN_${i}_MSC`]}.{items.UWNN[`UWNN_${i}_ROK`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNE[`UWNE_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNF[`UWNF_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNG[`UWNG_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNH[`UWNH_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNI[`UWNI_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNJ[`UWNJ_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNK[`UWNK_${i}`]}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{items.UWNL[`UWNL_${i}`]}</TableCell>
            </TableRow>
        )
    }

    return tableRowItem
}

const DeletionErrors = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ deletion: false, legend: false })

    const handleClickOpen = (type) => {
        setOpen({ ...open, [type]: true })
    }

    const handleClose = () => {
        setOpen({ deletion: false, legend: false })
    }
    const handleDeletionErrors = () => {
        deletionErrorsState(setPublish)
        handleClose()
    }
    return (
        <React.Fragment>
        <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h2">Rejestr błędów</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button className={`${classes.buttonMargin} ${classes.floatRight}`} variant="contained" color="primary" onClick={() => handleClickOpen('legend')}>Legenda</Button>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.legend} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Grid container>
                                <Grid xs={12}>
                                <Typography variant="h2">Kasowanie rejestru błędów</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={czas} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Czas wystąpienia</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={kalendarz} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Data wystąpienia</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={tzew} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura zewnętrzna</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={twew} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wewnętrzna</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={gornewe} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennik źródła - góra</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={dolweAtmo} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennik źródła - dół</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={gornewy} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennik odbioru - góra</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={dolwyAtmo} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennik odbioru - dół</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={tcwu} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura w zasobniku cwu</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={2}>
                                    <img alt="Euros Energy" src={tloczenie} style={{ maxWidth: "50%", margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura tłoczenia</Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Lp
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={czas} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={kalendarz} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={tzew} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={twew} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={gornewe} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={dolweAtmo} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={gornewy} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={dolwyAtmo} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={tcwu} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="Euros Energy" src={tloczenie} style={{ maxWidth: "50%", margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listItem(settings)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleClickOpen('deletion')}>Kasuj błędy</Button>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.deletion} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>

                            <Typography variant="h2">Czy napewno chcesz wykasować historię błędów?</Typography>
                            <Typography variant="body2">Proces kasowania jest nieodwracalny.</Typography>
                            <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleDeletionErrors()}>Tak, kasuję permanentnie historię błędów z urządzenia</Button>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default DeletionErrors