import React, { useEffect, useReducer, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar'
import { OptionList } from '../../../../components'
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary'
import { dataRangeDictionary } from '../../../../dataFilter/dataRangeDictionary'
import ecoIcon from '../../../../../../img/ECO2.svg'
import cwuIcon from '../../../../../../img/Temp-CWU2.svg'
import domIcon from '../../../../../../img/Temp-dom2.svg'
//import Socket from '../../../../Socket'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return {
            ...state, [action.label]: {
                ...state[action.label],
                [action.stamp]: {
                    ...state[action.label][action.stamp],
                    [action.type]: parseInt(action.payload)
                }
            }
        }
    }
}


const EcoTime = ({ entryData, stampData, descript, handleEcoChange }) => {
    return (
        <Row>
            <Col xs={6}>
                <p className="text-left">{descript}</p><br />
            </Col>
            <Col xs={3}>
                <Form.Control as="select" custom value={entryData[descriptionDictionary.ecoSettings.firstLabel[stampData].startRegister]} name={descriptionDictionary.ecoSettings.firstLabel[stampData].startRegister} onChange={e => handleEcoChange(e, 'firstLabel', stampData)}>
                    <OptionList timeFormat={true} nameKey={descriptionDictionary.ecoSettings.firstLabel[stampData].startRegister} start={0} stop={entryData[descriptionDictionary.ecoSettings.firstLabel[stampData].stopRegister]} />
                </Form.Control>
            </Col>
            <Col xs={3}>
                <Form.Control as="select" custom value={entryData[descriptionDictionary.ecoSettings.firstLabel[stampData].stopRegister]} name={descriptionDictionary.ecoSettings.firstLabel[stampData].stopRegister} onChange={e => handleEcoChange(e, 'firstLabel', stampData)}>
                    <OptionList timeFormat={true} nameKey={descriptionDictionary.ecoSettings.firstLabel[stampData].stopRegister} start={entryData[descriptionDictionary.ecoSettings.firstLabel[stampData].startRegister]} stop={24} />
                </Form.Control>
            </Col>
        </Row>
    )
}

const EcoCorrectionBuildingTemp = ({ entryData, stampData, descript, handleEcoChange }) => {

    return (
        <Row>
            <Col xs={9}>
                <p className="text-left">{descript}</p><br />
            </Col>
            <Col xs={3}>
                <Form.Control as="select" custom value={entryData[descriptionDictionary.ecoSettings.secondLabel[stampData].register]} name={descriptionDictionary.ecoSettings.secondLabel[stampData].register} onChange={e => handleEcoChange(e, 'secondLabel', stampData)}>
                    <OptionList degreesFormat={true} nameKey={descriptionDictionary.ecoSettings.secondLabel[stampData].register} start={dataRangeDictionary.eco[descriptionDictionary.ecoSettings.secondLabel[stampData].register].min} stop={dataRangeDictionary.eco[descriptionDictionary.ecoSettings.secondLabel[stampData].register].max} />
                </Form.Control>
            </Col>
        </Row>
    )
}

const EcoCorrectionWatherTemp = ({ entryData, stampData, handleEcoChange, descript }) => {

    return (
        <Row>
            <Col xs={9}>
                <p className="text-left">{descript}</p><br />
            </Col>
            <Col xs={3}>
                <Form.Control as="select" custom value={entryData[descriptionDictionary.ecoSettings.thirdLabel[stampData].register]} name={descriptionDictionary.ecoSettings.thirdLabel[stampData].register} onChange={e => handleEcoChange(e, 'thirdLabel', stampData)}>
                    <OptionList degreesFormat={true} nameKey={descriptionDictionary.ecoSettings.thirdLabel[stampData].register} start={dataRangeDictionary.eco[descriptionDictionary.ecoSettings.thirdLabel[stampData].register].min} stop={dataRangeDictionary.eco[descriptionDictionary.ecoSettings.thirdLabel[stampData].register].max}  />
                </Form.Control>
            </Col>
        </Row>
    )
}

const EcoSettings = ({ ecoSettings, setPublish }) => {
    const [controller, setEcoState] = useReducer(switchOption, ecoSettings)

    useEffect(() => {
        setEcoState({ payload: ecoSettings, case: "UPDATE_FROM_BROKER" })
    }, [ecoSettings])

    
    const handleEcoChange = (e, label, stamp) => {
        setEcoState({ type: e.target.name, payload: e.target.value, label: label, stamp: stamp })

        const sendKey = e.target.name;
        const sendData = e.target.value;

        setPublish({ [sendKey]: sendData })
        
    }

    const ecoTime = Object.entries(controller.firstLabel).map(entry => <EcoTime handleEcoChange={handleEcoChange} key={entry[0]} entryData={entry[1]} stampData={entry[0]} descript={descriptionDictionary.ecoSettings.firstLabel[entry[0]].descript} />)
    const ecoCorrectionTempBuilding = Object.entries(ecoSettings.secondLabel).map(entry => <EcoCorrectionBuildingTemp handleEcoChange={handleEcoChange} key={entry[0]} entryData={entry[1]} stampData={entry[0]} descript={descriptionDictionary.ecoSettings.secondLabel[entry[0]].descript} />)
    const ecoCorrectionTempWather = Object.entries(ecoSettings.thirdLabel).map(entry => <EcoCorrectionWatherTemp handleEcoChange={handleEcoChange} key={entry[0]} entryData={entry[1]} stampData={entry[0]} descript={descriptionDictionary.ecoSettings.thirdLabel[entry[0]].descript} />)

    return (
        <React.Fragment>
            <Row>
                <Navbar bg="primary" variant="dark" style={{ width: "100%" }}>
                    <Navbar.Brand className="mr-auto"><img alt="tryb eco" className="mode-icon" src={ecoIcon} /></Navbar.Brand>
                    <Navbar.Brand>ECO</Navbar.Brand>
                </Navbar>
            </Row>
            {ecoTime}
            <div className="circleIcon"><img alt="ogrzewnie" className="mode-icon" src={domIcon} /></div>
            {ecoCorrectionTempBuilding}
            <div className="circleIcon"><img alt="cyrkulacja" className="mode-icon" src={cwuIcon} /></div>
            {ecoCorrectionTempWather}
            <Row className="bottom-margin" style={{marginTop: "60px"}}>
                <Col xs={9} className="text-left">
                    <p>{descriptionDictionary.ecoSettings.fourthLabel.descript}</p>
                </Col>
                <Col xs={3}>
                {controller['fourthLabel'].register['SXF_WEK'] === 1 ? 
                <div onClick={() => handleEcoChange( { target: { name: 'SXF_WEK', value: 0}}, 'fourthLabel', 'register')} className="div-for-radio">TAK</div> 
                : <div onClick={() => handleEcoChange( { target: { name: 'SXF_WEK', value: 1}}, 'fourthLabel', 'register')} className="div-for-radio">NIE</div>}
                </Col>
            </Row>
            <Row>
                <Col xs={9} className="text-left">
                    <p>{descriptionDictionary.ecoSettings.fifthLabel.descript}</p>
                </Col>
                <Col xs={3}>
                {controller['fifthLabel'].register['SXF_AZP'] === 1 ? 
                <div onClick={() => handleEcoChange( { target: { name: 'SXF_AZP', value: 0}}, 'fifthLabel', 'register')} className="div-for-radio">TAK</div> 
                : <div onClick={() => handleEcoChange( { target: { name: 'SXF_AZP', value: 1}}, 'fifthLabel', 'register')} className="div-for-radio">NIE</div>}
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default EcoSettings