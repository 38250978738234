import React, { useState, useEffect } from 'react'
import { Typography, Grid, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { registerDictionary } from '../../../../data/dataDictionary'
import { dataRange } from '../../../../data/dataRange'
import { CarouselInputMulti, ItemMapping } from '../../../../components'

import { TimeCirculation } from './components'

const Circulation = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary().periphery
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0 })
    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }
    const [cyrBreak, setCyrBreak] = useState(itemValue.CYR_BS - itemValue.CYR_FILL)

    const handleClickOpen = (element, name, title, type) => {
        setOpen({ status: true, element: element, name: name, title: title })
    }

    const handleClose = () => {
        setItemValue({...itemValue, [open.name]: open.val})
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (name, val) => {
        setOpen({
            ...open,
            val: val
        })
    }

    const handleSwitchValue = (e, item) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, [e.name]: 0 })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, [e.name]: 1})
            setPublish({ [e.name]: 1 })
        }
    }

    const handleChangeCyrFill = () => {
        setItemValue({ ...itemValue, CYR_FILL: itemValue.CYR_FILL + 5, CYR_BS: itemValue.CYR_FILL + 5 + cyrBreak })
        setPublish({ CYR_FILL: itemValue.CYR_FILL + 5, CYR_BS: itemValue.CYR_FILL + 5 + cyrBreak  })

        if (itemValue.CYR_FILL < 5 || itemValue.CYR_FILL >= 60 || itemValue.CYR_FILL % 5 !== 0) {
            setItemValue({ ...itemValue, CYR_FILL: 5, CYR_BS: 5 + cyrBreak })
            setPublish({ CYR_FILL: 5, CYR_BS: 5 + cyrBreak  })
        }
    }

    const handleChangeCyrBreak = () => {
        setCyrBreak(cyrBreak + 5)
        setPublish({ CYR_BS: cyrBreak + 5 + itemValue.CYR_FILL })

        if (cyrBreak < 5 || cyrBreak >= 60 || cyrBreak % 5 !== 0) {
            setCyrBreak(5)
            setPublish({ CYR_BS: 5 + itemValue.CYR_FILL })
        }
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    useEffect(() => {
        setCyrBreak(itemValue.CYR_BS - itemValue.CYR_FILL)
    }, [itemValue])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h2">Ustawienia sterowania pompą cyrkulacyjną CWU</Typography>
                </Grid>
                <Grid item xs={8}>
                    {ItemMapping(itemValue.map, handleSwitchValue, handleClickOpen, dictionary.circulation, classes, filteredItems(itemValue.map), 'map')}
                </Grid>
            </Grid>
            {/*<Grid container className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SBF_OOF}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SBF_OOF) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SBF_OOF")}
                        color="primary"
                        name="SBF_OOF"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container  className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SBF_DHX}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SBF_DHX) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SBF_DHX")}
                        color="primary"
                        name="SBF_DHX"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container  className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SBF_DHS}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SBF_DHS) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SBF_DHS")}
                        color="primary"
                        name="SBF_DHS"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container  className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SXF_ENACYR}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SXF_ENACYR) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SXF_ENACYR")}
                        color="primary"
                        name="SXF_ENACYR"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container  className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SXF_FORCYR}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SXF_FORCYR) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SXF_FORCYR")}
                        color="primary"
                        name="SXF_FORCYR"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container  className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SXF_TIMCYR}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SXF_TIMCYR) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SXF_TIMCYR")}
                        color="primary"
                        name="SXF_TIMCYR"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container  className={classes.containerVerticalCenter}>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.SXF_FLPCYR}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.textRight}>
                    <Switch
                        checked={parseInt(itemValue.SXF_FLPCYR) === 0 ? false : true}
                        onChange={() => handleSwitchValue("SXF_FLPCYR")}
                        color="primary"
                        name="SXF_FLPCYR"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>*/}
            {/*<Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2">{dictionary.CYR_FILL}</Typography>
                </Grid>
                <Grid item xs={1} onClick={() => handleClickOpen(itemValue.CYR_FILL, "CYR_FILL", "Ustaw wartość wypełnienia")}>
                    <Typography variant="body2" className={"carousel-input-click-text"}>Ustaw</Typography>
                    <Typography variant="body2" className={"carousel-input-click"}>{itemValue.CYR_FILL}</Typography>
                </Grid>
            </Grid>*/}
            <Grid item xs={8}>
                <Grid container style={{ paddingRight: '24px' }}  className={classes.containerVerticalCenter}>
                    <Grid item xs={10}>
                        <Typography variant="body2">{dictionary.circulation.ANL_3PH}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.textRight}>
                    <Typography variant="body2">{itemValue.ANL_3PH === 0 ? "nie" : "tak"}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingRight: '24px' }}  className={classes.containerVerticalCenter}>
                    <Grid item xs={10}>
                        <Typography variant="body2">{dictionary.circulation.CYR_FILL}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.textRight} onClick={handleChangeCyrFill}>
                        <Typography variant="body2" className={"carousel-input-click"}>{itemValue.CYR_FILL}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingRight: '24px' }}  className={classes.containerVerticalCenter}>
                    <Grid item xs={10}>
                        <Typography variant="body2">{dictionary.circulation.cyrBreak}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.textRight} onClick={handleChangeCyrBreak}>
                        <Typography variant="body2" className={"carousel-input-click"}>{cyrBreak}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingRight: '24px' }}  className={classes.containerVerticalCenter}>
                    <Grid item xs={10}>
                        <Typography variant="body2">{dictionary.circulation.TIM_CYR}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.textRight}>
                        <Typography variant="body2" style={{ lineHeight: "42px", marginLeft: '26px' }}>{itemValue.TIM_CYR}</Typography>
                    </Grid>
                </Grid><br /><br />
            </Grid>
            <TimeCirculation circulation={settings} setPublish={setPublish} />
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <CarouselInputMulti init={open.element} name={open.name} min={2} max={60} title={open.title} setPublish={setPublish} type={""} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Circulation