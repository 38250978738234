import React, { useEffect, useReducer, useState } from 'react'
import { Typography, Grid, Switch, FormControl, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent, IconButton} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import { DataTime } from './components'
import { dataRange } from './../../data/dataRange'
import { registerDictionary } from '../../data/dataDictionary'
import { FiberManualRecord } from '@material-ui/icons'
import { CarouselInputMulti, ItemMapping } from '../../components'

const dayWeek = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela']

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: action.payload };
    }
}

// const day = (day) => {
//     switch (parseInt(day)) {
//         case 0:
//             return "Poniedziałek"
//         case 1:
//             return "Wtorek"
//         case 2:
//             return "Środa"
//         case 3:
//             return "Czwartek"
//         case 4:
//             return "Piątek"
//         case 5:
//             return "Sobota"
//         case 6:
//             return "Niedziela"
//         default:
//             return null
//     }
// }

const SetDateTime = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary().dateTimeSettings
    const [itemValue, setItemValue] = useReducer(switchOption, settings)
    const [state, setState] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0 })
    const [more, setMore] = useState({ status: false, name: "", val: {} })
    const filteredItems = Object.entries(state ? state.map : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    
    const deviceName = sessionStorage.getItem('deviceName')

    useEffect(() => {
        let mounted = true
        setItemValue({ case: "UPDATE_FROM_BROKER", payload: settings })
        setState(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    // const date = new Date(parseInt(itemValue.ROK),
    //     parseInt(itemValue.MIESIAC), parseInt(itemValue.DZIEN), parseInt(itemValue.GODZINY), parseInt(itemValue.MINUTY), parseInt(itemValue.SEKUNDY)).toLocaleString();


    // const handleSynchTime = () => {
    //     const actualDate = new Date();
    //     let day
    //     if (actualDate.getDay() === 0)
    //         day = 6
    //     else
    //         day = actualDate.getDay() - 1

    //     setPublish({
    //         "ST_ROK": actualDate.getFullYear(),
    //         "ST_MIESIAC": actualDate.getMonth(),
    //         "ST_DZIEN": actualDate.getDate(),
    //         "ST_GODZINY": actualDate.getHours(),
    //         "ST_MINUTY": actualDate.getMinutes(),
    //         "ST_SEKUNDY": actualDate.getSeconds(),
    //         "ST_DZIEN_TYG": day,
    //     })
    // }

    const handleSwitchValue = name => (e, newValue) => {
        if (!newValue || parseInt(newValue) === 0) {
            setItemValue({ type: name, payload: 0 })
            setPublish({ [name]: 0 })
        } else {
            setItemValue({ type: name, payload: 1 })
            setPublish({ [name]: 1 })
        }
    }

    const handleClickOpenMore = (name) => {
        setMore({ status: true, name: name })
    }

    const handleCloseMore = () => {
        setState({...state, [more.name]: more.val})
        setPublish(more.val)
        setMore({ ...more, status: false })
    }
    
    const handleClickOpen = (element, name, title, min, max, step, num, val) => { 
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val })
    }

    const handleClose = () => {
        setState({...state, 
                    map: {
                        ...state.map,
                        [open.name]: open.val
                    }
                })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const switchControl = (entry) => {
        return (
            <div onClick={() => handleClickOpen(entry[1], entry[0], dictionary[entry[0]], dataRange()[entry[0]].min, dataRange()[entry[0]].max, entry[0], entry[1])}>
                <Typography variant="body2" className={"carousel-input-click"}>{entry[1]} {dataRange()[entry[0]].symbol}</Typography>
            </div>
        )

    }

    const setNewValue = (name, val) => {
        setOpen({ ...open, val: val })
    }

    const setNewValueMore = (name, val) => {
        setMore({
            ...more,
            val: {
                ...more.val,
                [name]: val
            }
        })
    }
  
    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: deviceName === 'GEO_1' ? '119%' : '105%' }}>
            <Grid container style={{ padding: '0 24px' }}>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">Nastawa daty i czasu</Typography>
                        <Typography variant="body2" onClick={() => handleClickOpenMore("date")} className="data-box">{itemValue.DZIEN < 10 ? `0${itemValue.DZIEN}` : itemValue.DZIEN}.{itemValue.MIESIAC < 10 ? `0${itemValue.MIESIAC}` : itemValue.MIESIAC}.{itemValue.ROK}</Typography>
                        <Typography variant="body2" onClick={() => handleClickOpenMore("dayweek")} className="data-box">{dayWeek[itemValue.DZIEN_TYG]}</Typography>
                        <Typography variant="body2" onClick={() => handleClickOpenMore("time")} className="data-box">{itemValue.GODZINY < 10 ? `0${itemValue.GODZINY}` : itemValue.GODZINY}:{itemValue.MINUTY < 10 ? `0${itemValue.MINUTY}` : itemValue.MINUTY}:{itemValue.SEKUNDY < 10 ? `0${itemValue.SEKUNDY}` : itemValue.SEKUNDY}</Typography>
                        {/*<Typography variant="body1">{itemValue.DZIEN_TYG ? `${day(itemValue.DZIEN_TYG)}, ` : null}{date}</Typography>
                            <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleSynchTime()}>Synchronizuj datę i czas</Button>*/}
                    </Grid>
                    <Grid item>
                        <Typography variant="h2" style={{ display: "inline-block" }}>Zezwolenie na automatyczną zmianę czasu</Typography>
                        <Switch
                            checked={parseInt(itemValue.SXF_TIM_WIN_SW) === 0 ? false : true}
                            onChange={handleSwitchValue("SXF_TIM_WIN_SW")}
                            color="primary"
                            name={"SXF_TIM_WIN_SW"}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        </Grid>
                    <FormControl component="fieldset">
                        <RadioGroup name="SXF_TIM_WIN_FL" value={parseInt(itemValue.SXF_TIM_WIN_FL)} onChange={handleSwitchValue("SXF_TIM_WIN_FL")}>
                            <FormControlLabel value={0} control={<Radio color="primary" />} label="Czas letni" />
                            <FormControlLabel value={1} control={<Radio color="primary" />} label="Czas zimowy" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h2" style={{ display: "inline-block" }}>Ustawienia i stan zmiennych związanych z sekwencjami czasowymi</Typography>
                    <Grid item xs={11}>
                        {ItemMapping(settings.map, handleSwitchValue, handleClickOpen, dictionary, classes, filteredItems)}
                        {/*{deviceName === 'ATMO_1' || deviceName === 'ALLINONE_1' ?
                            <Grid container className={classes.containerVerticalCenter} >
                                <Grid item xs={9}><Typography variant="body2">Całkowity czas pracy sprężarki (w godzinach)</Typography></Grid>
                                <Grid item xs={2} className={classes.textRight}>{Math.round(settings.TIME_WEEK * 7 * 24 + settings.TIME_FST / 60)}</Grid>
                            </Grid>
                            : null
                        }*/}      
                    </Grid>
                    {/*<Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            {dictionary.SBB_MAIN}
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>
                            {dataRange().SBB_MAIN.RW === 0 ? <FiberManualRecord style={{ color: "grey" }} /> : <FiberManualRecord style={{ color: "green" }} />}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.SBB_QCS}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>
                            {dataRange().SBB_QCS.RW === 0 ? <FiberManualRecord style={{ color: "grey" }} /> : <FiberManualRecord style={{ color: "green" }} />}
                        </Grid>
                    </Grid>
                    {(version >= 4.92 && (deviceName === "ATMO_1" || deviceName === "ALLINONE_1")) || deviceName === 'GEO_1' ?
                        <Grid container className={classes.containerVerticalCenter} style={{ margin: '5px 0 5px 0' }}>
                            <Grid item xs={9}>
                                <Typography variant="body2">{dictionary.SXB_F4D}</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.textRight}>
                                {dataRange().SXB_F4D.RW === 0 ? <FiberManualRecord style={{ color: "grey" }} /> : <FiberManualRecord style={{ color: "green" }} />}
                            </Grid>
                        </Grid>
                        : null
                    }
                    {(version >= 4.92 && (deviceName === "ATMO_1" || deviceName === "ALLINONE_1")) || deviceName === 'GEO_1' ?
                        <Grid container className={classes.containerVerticalCenter} style={{ margin: '5px 0 5px 0' }}>
                            <Grid item xs={9}>
                                <Typography variant="body2">{dictionary.SXB_F4DP}</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.textRight}>
                                {dataRange().SXB_F4DP.RW === 0 ? <FiberManualRecord style={{ color: "grey" }} /> : <FiberManualRecord style={{ color: "green" }} />}
                            </Grid>
                        </Grid>
                        : null
                    }
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TCR}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>
                            {dataRange().TCR.RW === 0 ? settings.TCR : dataRange().TCR.RW !== 0 ? switchControl(["TCR", settings.TCR], settings) : 
                            <Typography variant="body2">{state.TCR}  {dataRange().TCR.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TCH}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>
                            {dataRange().TCH.RW === 0 ? settings.TCH : dataRange().TCH.RW !== 0 ? switchControl(["TCH", settings.TCH], settings) : 
                            <Typography variant="body2">{state.TCH}  {dataRange().TCH.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TRR}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>{dataRange().TRR.RW === 0 ? settings.TRR : dataRange().TRR.RW !== 0 ? switchControl(["TRR", settings.TRR], settings) :
                            <Typography variant="body2">{state.TRR}  {dataRange().TRR.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TSR}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>{dataRange().TSR.RW === 0 ? settings.TSR : dataRange().TSR.RW !== 0 ? switchControl(["TSR", settings.TSR], settings) : 
                            <Typography variant="body2">{state.TSR}  {dataRange().TSR.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter} style={{ margin: '5px 0 5px 0' }}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TRS}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>{dataRange().TRS.RW === 0 ? settings.TRS : dataRange().TRS.RW !== 0 ? switchControl(["TRS", settings.TSR], settings) : 
                            <Typography variant="body2">{state.TRS}  {dataRange().TRS.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter} style={{ margin: '5px 0 5px 0' }}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TSS}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>{dataRange().TSS.RW === 0 ? settings.TSS : dataRange().TSS.RW !== 0 ? switchControl(["TSS", settings.TSR], settings) : 
                            <Typography variant="body2">{state.TSS}  {dataRange().TSS.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    {deviceName === 'GEO_1' ?
                    <Grid container className={classes.containerVerticalCenter} style={{ margin: '5px 0 5px 0' }}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.TB3}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>{dataRange().TB3.RW === 0 ? settings.TB3 : dataRange().TB3.RW !== 0 ? switchControl(["TB3", settings.TSR], settings) : 
                            <Typography variant="body2">{state.TB3}  {dataRange().TB3.symbol}</Typography>}
                        </Grid>
                    </Grid>
                    : null
                    }*/}
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"sm"} open={more.status} onClose={handleCloseMore} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleCloseMore} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12} className="euros-monitor euros-monitor-carousel">
                            <DataTime settings={itemValue} element={more.name} setVal={setNewValueMore}/>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog scroll={"body"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} /> 
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SetDateTime