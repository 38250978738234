import React, { useState, useEffect } from 'react'
import AUTO_on from '../../../../img/AUTO-on.svg'
import AUTO_off from '../../../../img/AUTO-off.svg'
import ECO_on from '../../../../img/ECO-on.svg'
import ECO_off from '../../../../img/ECO-off.svg'
import TIME_on from '../../../../img/TIME-on.svg'
import TIME_off from '../../../../img/TIME-off.svg'
import AWAY_on from '../../../../img/AWAY-on.svg'
import AWAY_off from '../../../../img/AWAY-off.svg'
import OFF_on from '../../../../img/OFF-on.svg'
import OFF_off from '../../../../img/OFF-off.svg'

import { Grid } from '@material-ui/core'

const LeftSide = ({ settings, setPublish, setModes }) => {
    // const [settings, setModeState] = useState(settings)

    const handleModeClick = (e) => {

        let sendData

        switch (e) {
            case "AUTO":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 0,
                    SXF_ECO: 0,
                    SXF_TIME: 0,
                }
                break;
            case "SXF_ECO":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 0,
                    SXF_ECO: 1,
                    SXF_TIME: 0,
                }
                break;
            case "SXF_AWA":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 1,
                    SXF_ECO: 0,
                    SXF_TIME: 0,
                }
                break;
            case "SBF_OOF":
                sendData = {
                    SBF_OOF: 0,
                    SXF_AWA: 0,
                    SXF_ECO: 0,
                    SXF_TIME: 0,
                }
                break;
            case "SXF_TIME":
                sendData = {
                    SBF_OOF: 1,
                    SXF_AWA: 0,
                    SXF_ECO: 0,
                    SXF_TIME: 1,
                    TWTSW_PN: 1,
                    TWTSW_WT: 1,
                    TWTSW_SR: 1,
                    TWTSW_CZ: 1,
                    TWTSW_PT: 1,
                    TWTSW_SO: 1,
                    TWTSW_ND: 1,
                    CWTSW_PN: 1,
                    CWTSW_WT: 1,
                    CWTSW_SR: 1,
                    CWTSW_CZ: 1,
                    CWTSW_PT: 1,
                    CWTSW_SO: 1,
                    CWTSW_ND: 1,
                    CWTSW_S1: 1,
                    CWTSW_S2: 1,
                    CWTSW_S3: 1,
                    TWTSW_S1: 1,
                    TWTSW_S2: 1,
                    TWTSW_S3: 1,
                }
                break;
            default:
                console.log(`Error`);
        }
        // setModeState(sendData)
        setPublish(sendData)
        setModes(sendData)
    }

    // useEffect(() => {
    //     let mounted = true

    //     setModeState(settings)

    //     return () => {
    //         mounted = false
    //     }
    // }, [settings])

    const handleTouch = (e) => {
        if (e === 'start')
        console.log('start')
        if (e === 'stop')
        console.log('stop')
    }
    
    return (
        <React.Fragment>
            <Grid container onClick={() => handleModeClick("AUTO")}>
                <Grid item xs={12}>
                    {settings.SBF_OOF === 1 && settings.SXF_ECO === 0 && settings.SXF_AWA === 0 && settings.SXF_TIME === 0?
                        <img alt="trybauto" className="mode-icon" src={AUTO_on} />
                    :<img alt="trybauto" className="mode-icon" src={AUTO_off} />}
                </Grid>
            </Grid>
            <Grid container onClick={() => handleModeClick("SXF_ECO")}>
                <Grid item xs={12}>
                {settings.SBF_OOF === 1 && settings.SXF_ECO === 1 && settings.SXF_AWA === 0 ?
                    <img alt="trybeco" className="mode-icon" src={ECO_on} />
                    : <img alt="trybeco" className="mode-icon" src={ECO_off} />}
                </Grid>
            </Grid>
            <Grid container onClick={() => handleModeClick("SXF_TIME")} onTouchStart={() => handleTouch('start')} onTouchEnd={() => handleTouch('stop')}
            onMouseDown={() => handleTouch('start')} onMouseUp={() => handleTouch('stop')}>
                <Grid item xs={12}>
                {settings.SBF_OOF === 1 && settings.SXF_TIME === 1 ?
                    <img alt="trybtime" className="mode-icon" src={TIME_on} />
                    :<img alt="trybtime" className="mode-icon" src={TIME_off} />}
                </Grid>
            </Grid>
            <Grid container onClick={() => handleModeClick("SXF_AWA")}>
                <Grid item xs={12}>
                {settings.SBF_OOF === 1 && settings.SXF_AWA === 1 ?
                    <img alt="trybaway" className="mode-icon" src={AWAY_on} />
                    :<img alt="trybaway" className="mode-icon" src={AWAY_off} />}
                </Grid>
            </Grid>
            <Grid container onClick={() => handleModeClick("SBF_OOF")}>
                <Grid item xs={12}>
                {settings.SBF_OOF === 0 ?
                    <img alt="tryboff" className="mode-icon" src={OFF_on} />
                    :<img alt="tryboff" className="mode-icon" src={OFF_off} />}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LeftSide