import React, {useState, useEffect} from 'react'
import { mainStyleClasses } from '../../theme'
import { AppBar } from '@material-ui/core'
import { CompressorStartCount, DeviceTime, PeakSourceTime} from './components'
import { TabPanel, Tab, Tabs } from '../../components'

const Statistics = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [value, setValue] = useState(0);
    const deviceName = sessionStorage.getItem('deviceName')
    
 
	  const version_1 = parseFloat(sessionStorage.getItem('version_1')) 
	  const version_2 = parseFloat(sessionStorage.getItem('version_2')) 

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // useEffect(() => {
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener('popstate', function (event){
    //       window.history.pushState(null, document.title,  window.location.href);
    //   });
    // }, []);

    return (
      <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
        <AppBar position="static" style={{boxShadow: "none"}}>
            <Tabs value={value} onChange={handleChange} aria-label="nawigacja">
              <Tab label="Statystyki"/>
              {deviceName === 'GEO_1' && ((version_1 < 870) || (version_1 === 870 && version_2 < 9)) ?  
                null
                : <Tab label="Czas pracy źródła szczytowego"/>
              }
              {deviceName === 'GEO_1' ? 
                null
                : <Tab label="Liczba załączeń sprężarki"/>
              }
            </Tabs> 
        </AppBar>
        <TabPanel value={value} index={0}>
          <DeviceTime settings={settings} setPublish={setPublish} />
        </TabPanel>
        {deviceName === 'GEO_1' && ((version_1 < 870) || (version_1 === 870 && version_2 < 9)) ?
          null
          : <TabPanel value={value} index={1}>
            <PeakSourceTime settings={settings.peakSourceTime} setPublish={setPublish} />
          </TabPanel>
        }
        {deviceName === 'GEO_1' ? 
          null
          : <TabPanel value={value} index={2}>
              <CompressorStartCount settings={settings.compressorStartCount} setPublish={setPublish} />
            </TabPanel>
        }
      </div>
    )
}


export default Statistics