import React, { useReducer, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { default as Mode } from './Mode'
import { default as Functions } from './Functions'
import { default as Temperature } from './Temperature'
import { default as Panel } from './Panel'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { 
            ...state, 
            [action.type]: action.payload.val
        }
    }
}

const Main = ({ main, setPublish }) => {
const [temp, setTemp] = useReducer(switchOption, {TSM10: main.TSM10, TSM15: main.TSM15})
const [change, setChange] = useState(null)

    const handleTemperatureChange = (e) => {
        setTemp({type: e.change, payload: {val: e.value}})
        setChange({change: e.change, wather: e.change === "TSM15"? true: false, building: e.change === "TSM10"? true: false})
        setPublish({[e.change]: e.value })
    }

useEffect(()=>{
    setTimeout(() => {
        setTemp({payload: { TSM10: main.TSM10, TSM15: main.TSM15 }, case: "UPDATE_FROM_BROKER"})
        setChange(null)
    }, 2000);
}, [main])

    return (
        <React.Fragment>
            <Row>
                <Col lg={6} xs={12}>
                    <Row className="bottom-margin">
                        <Col xs={6} className="text-left">
                            <p>Tryb pracy</p>
                        </Col>
                        <Col xs={6}>
                            <Mode mode={main.MODE} setPublish={setPublish} />
                        </Col>
                    </Row>

                    <Temperature setPublish={setPublish} 
                    temperatureData={{ buildingTemp: change && change.building? temp.TSM10 : main.BUILDING_TEMP, 
                        watherTemp: change && change.wather? temp.TSM15 : main.WATHER_TEMP }} handleTemperatureChange={handleTemperatureChange} />

                    <Functions functionData={{ heating: main.HEATING, airConditioning: main.AIR_CONDITIONING, 
                        circulation: main.CIRCULATION, antylegionella: main.ANTYLEGIONELLA }} setPublish={setPublish} />
                </Col>
                <Col lg={6} xs={12}>
                    <Panel data={{change: change? change.change: null, TSM10: temp.TSM10, TSM15: temp.TSM15, SBB_QHW: main.SBB_QHW, SBB_QHL: main.SBB_QHL, SBB_QHT: main.SBB_QHT, heater: 0, mode: main.MODE, error: main.REW_WRN, circulation: main.CIRCULATION, heating: main.HEATING, airConditioning: main.AIR_CONDITIONING, antylegionella: main.ANTYLEGIONELLA, SXF_TOE: main.SXF_TOE, SBF_DHS: main.SBF_DHS}} />
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default Main