import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'

import zegar from '../../img/zegar.svg'
import kalendarz from '../../img/kalendarz.svg'
import tzew from '../../img/zew.svg'
import twew from '../../img/wew.svg'
import tempwejAtmo from '../../img/lewy-dol-atmo.svg'
import tempwejGeo from '../../img/lewy-dol-geo.svg'
import tempwyjAtmo from '../../img/lewy-gora-atmo.svg'
import tempwyjGeo from '../../img/lewy-gora-geo.svg'
import wymdolAtmo from '../../img/prawy-dol-atmo.svg'
import wymdolGeo from '../../img/prawy-dol-geo.svg'
import wymgoraAtmo from '../../img/prawy-gora-atmo.svg'
import wymgoraGeo from '../../img/prawy-gora-geo.svg'
import gornewe from '../../img/gorne-we.svg'
import gornewe2 from '../../img/gorne-we-2.svg'
import gornewy from '../../img/gorne-wy.svg'
import gornewy2 from '../../img/gorne-wy-2.svg'
import dolwe from '../../img/dolne-we-atmo.svg'
import dolwy from '../../img/dolne-wy-atmo.svg'
import tcwu from '../../img/t-cwu.svg'
import tloczenieAtmo from '../../img/tloczenie-atmo.svg'
import tloczenieGeo from '../../img/tloczenie-geo.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore' 
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { default as functionMoreInfo } from './Functions/functionMoreInfo'

const deviceName = sessionStorage.getItem('deviceName')

const deletionErrorsState = (setPublish) => {
    setPublish({
        UWNA_1: 0,
        UWNA_2: 0,
        UWNA_3: 0,
        UWNA_4: 0,
        UWNA_5: 0,
        UWNA_6: 0,
        UWNA_7: 0,
        UWNA_8: 0,
        UWNA_9: 0,
        UWNA_10: 0,
        UWNB_1: 0,
        UWNB_2: 0,
        UWNB_3: 0,
        UWNB_4: 0,
        UWNB_5: 0,
        UWNB_6: 0,
        UWNB_7: 0,
        UWNB_8: 0,
        UWNB_9: 0,
        UWNB_10: 0,
        UWNC_1: 0,
        UWNC_2: 0,
        UWNC_3: 0,
        UWNC_4: 0,
        UWNC_5: 0,
        UWNC_6: 0,
        UWNC_7: 0,
        UWNC_8: 0,
        UWNC_9: 0,
        UWNC_10: 0,
        UWND_1: 0,
        UWND_2: 0,
        UWND_3: 0,
        UWND_4: 0,
        UWND_5: 0,
        UWND_6: 0,
        UWND_7: 0,
        UWND_8: 0,
        UWND_9: 0,
        UWND_10: 0,
        UWNE_1: 0,
        UWNE_2: 0,
        UWNE_3: 0,
        UWNE_4: 0,
        UWNE_5: 0,
        UWNE_6: 0,
        UWNE_7: 0,
        UWNE_8: 0,
        UWNE_9: 0,
        UWNE_10: 0,
        UWNF_1: 0,
        UWNF_2: 0,
        UWNF_3: 0,
        UWNF_4: 0,
        UWNF_5: 0,
        UWNF_6: 0,
        UWNF_7: 0,
        UWNF_8: 0,
        UWNF_9: 0,
        UWNF_10: 0,
        UWNG_1: 0,
        UWNG_2: 0,
        UWNG_3: 0,
        UWNG_4: 0,
        UWNG_5: 0,
        UWNG_6: 0,
        UWNG_7: 0,
        UWNG_8: 0,
        UWNG_9: 0,
        UWNG_10: 0,
        UWNH_1: 0,
        UWNH_2: 0,
        UWNH_3: 0,
        UWNH_4: 0,
        UWNH_5: 0,
        UWNH_6: 0,
        UWNH_7: 0,
        UWNH_8: 0,
        UWNH_9: 0,
        UWNH_10: 0,
        UWNI_1: 0,
        UWNI_2: 0,
        UWNI_3: 0,
        UWNI_4: 0,
        UWNI_5: 0,
        UWNI_6: 0,
        UWNI_7: 0,
        UWNI_8: 0,
        UWNI_9: 0,
        UWNI_10: 0,
        UWNJ_1: 0,
        UWNJ_2: 0,
        UWNJ_3: 0,
        UWNJ_4: 0,
        UWNJ_5: 0,
        UWNJ_6: 0,
        UWNJ_7: 0,
        UWNJ_8: 0,
        UWNJ_9: 0,
        UWNJ_10: 0,
        UWNK_1: 0,
        UWNK_2: 0,
        UWNK_3: 0,
        UWNK_4: 0,
        UWNK_5: 0,
        UWNK_6: 0,
        UWNK_7: 0,
        UWNK_8: 0,
        UWNK_9: 0,
        UWNK_10: 0,
        UWNL_1: 0,
        UWNL_2: 0,
        UWNL_3: 0,
        UWNL_4: 0,
        UWNL_5: 0,
        UWNL_6: 0,
        UWNL_7: 0,
        UWNL_8: 0,
        UWNL_9: 0,
        UWNL_10: 0,
        UWNM_1_GODZ: 0,
        UWNM_1_MIN: 0,
        UWNM_1_SEK: 0,
        UWNM_2_GODZ: 0,
        UWNM_2_MIN: 0,
        UWNM_2_SEK: 0,
        UWNM_3_GODZ: 0,
        UWNM_3_MIN: 0,
        UWNM_3_SEK: 0,
        UWNM_4_GODZ: 0,
        UWNM_4_MIN: 0,
        UWNM_4_SEK: 0,
        UWNM_5_GODZ: 0,
        UWNM_5_MIN: 0,
        UWNM_5_SEK: 0,
        UWNM_6_GODZ: 0,
        UWNM_6_MIN: 0,
        UWNM_6_SEK: 0,
        UWNM_7_GODZ: 0,
        UWNM_7_MIN: 0,
        UWNM_7_SEK: 0,
        UWNM_8_GODZ: 0,
        UWNM_8_MIN: 0,
        UWNM_8_SEK: 0,
        UWNM_9_GODZ: 0,
        UWNM_9_MIN: 0,
        UWNM_9_SEK: 0,
        UWNM_10_GODZ: 0,
        UWNM_10_MIN: 0,
        UWNM_10_SEK: 0,
        UWNN_1_DZ: 0,
        UWNN_1_MSC: 0,
        UWNN_1_ROK: 0,
        UWNN_1_AM_PM: 0,
        UWNN_2_DZ: 0,
        UWNN_2_MSC: 0,
        UWNN_2_ROK: 0,
        UWNN_2_AM_PM: 0,
        UWNN_3_DZ: 0,
        UWNN_3_MSC: 0,
        UWNN_3_ROK: 0,
        UWNN_3_AM_PM: 0,
        UWNN_4_DZ: 0,
        UWNN_4_MSC: 0,
        UWNN_4_ROK: 0,
        UWNN_4_AM_PM: 0,
        UWNN_5_DZ: 0,
        UWNN_5_MSC: 0,
        UWNN_5_ROK: 0,
        UWNN_5_AM_PM: 0,
        UWNN_6_DZ: 0,
        UWNN_6_MSC: 0,
        UWNN_6_ROK: 0,
        UWNN_6_AM_PM: 0,
        UWNN_7_DZ: 0,
        UWNN_7_MSC: 0,
        UWNN_7_ROK: 0,
        UWNN_7_AM_PM: 0,
        UWNN_8_DZ: 0,
        UWNN_8_MSC: 0,
        UWNN_8_ROK: 0,
        UWNN_8_AM_PM: 0,
        UWNN_9_DZ: 0,
        UWNN_9_MSC: 0,
        UWNN_9_ROK: 0,
        UWNN_9_AM_PM: 0,
        UWNN_10_DZ: 0,
        UWNN_10_MSC: 0,
        UWNN_10_ROK: 0,
        UWNN_10_AM_PM: 0
    })
}

const listItem = (items, deviceName, handleClickOpenList, clickedId) => { 
    let tableRowItem = []
    for (let i = 1; i < 11; i++) {
        tableRowItem.push(
            <React.Fragment key={`bledy_${i}`}>
                <TableRow key={`bledy_${i}`}>
                    <TableCell style={{ textAlign: "center" }}>{i}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNN[`UWNN_${i}_AM_PM`] === 1 ? items.UWNM[`UWNM_${i}_GODZ`] + 12 : items.UWNN[`UWNN_${i}_AM_PM`] === 0 && items.UWNM[`UWNM_${i}_GODZ`] < 10 ? `0${items.UWNM[`UWNM_${i}_GODZ`]}` : items.UWNM[`UWNM_${i}_GODZ`]}:{items.UWNM[`UWNM_${i}_MIN`] < 10 ? `0${items.UWNM[`UWNM_${i}_MIN`]}` : items.UWNM[`UWNM_${i}_MIN`]}:{items.UWNM[`UWNM_${i}_SEK`] < 10 ? `0${items.UWNM[`UWNM_${i}_SEK`]}` : items.UWNM[`UWNM_${i}_SEK`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNN[`UWNN_${i}_DZ`]}.{items.UWNN[`UWNN_${i}_MSC`] >= 10 ? items.UWNN[`UWNN_${i}_MSC`] : `0${items.UWNN[`UWNN_${i}_MSC`]}`}.{items.UWNN[`UWNN_${i}_ROK`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNE[`UWNE_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNF[`UWNF_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNG[`UWNG_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNH[`UWNH_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNJ[`UWNJ_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNI[`UWNI_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNK[`UWNK_${i}`]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{items.UWNL[`UWNL_${i}`]}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell colSpan={1}></TableCell>
                <TableCell colSpan={3}>
                    BŁĘDY
                    <ul>
                        {functionMoreInfo(items.UWNA[`UWNA_${i}`], `rew${deviceName}`, `${items.VER_S1}.${items.VER_S2}`).map((el, index) => <li key={index}>{el}</li>)}
                    </ul>
                    <ul> 
                        {functionMoreInfo(items.UWND[`UWND_${i}`], `sxb2${deviceName}`, `${items.VER_S1}.${items.VER_S2}`, false, items.SIO_B03).map((el, index) => <li key={index}>{el}</li>)} 
                    </ul> 
                </TableCell>
                {clickedId[`${i}`] ? 
                    <TableCell colSpan={3}>
                        WEJŚCIA I WYJŚCIA BINARNE
                        <ul>
                            {functionMoreInfo(items.UWNB[`UWNB_${i}`], `sio${deviceName}`, `${items.VER_S1}.${items.VER_S2}`, true, items.SIO_B03).map((el, index) => <li key={index}>{el}</li>)}
                        </ul>
                    </TableCell>
                    : <TableCell colSpan={3}> 
                    </TableCell> 
                } 
                {clickedId[`${i}`] ? 
                    <TableCell colSpan={3}>
                        STAN PRACY
                        <ul>
                            {functionMoreInfo(items.UWNC[`UWNC_${i}`], `ssb${deviceName}`, `${items.VER_S1}.${items.VER_S2}`, true, items.SIO_B03).map((el, index) => <li key={index}>{el}</li>)}
                        </ul>
                        <ul>
                            {functionMoreInfo(items.UWND[`UWND_${i}`], `sxb${deviceName}`, `${items.VER_S1}.${items.VER_S2}`, true, items.SIO_B03).map((el, index) => <li key={index}>{el}</li>)}
                        </ul>
                    </TableCell>
                    : <TableCell colSpan={3}> 
                    </TableCell> 
                } 
                <TableCell colSpan={1}>
                    {clickedId[`${i}`] ? 
                        <ExpandLessIcon alt="zwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(i)} />  
                        : <ExpandMoreIcon alt="rozwiń" style={{ width: '100%', height: '40px', cursor: 'pointer' }} onClick={() => handleClickOpenList(i)} />
                    } 
                </TableCell>
            </TableRow>
        </React.Fragment>
        )
    }

    return tableRowItem
}

const Errors = ({ settings, setPublish, deviceName }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ deletion: false, legend: false })
    const [clickedId, setClickedId] = useState({})

    const handleClickOpen = (type) => {
        setOpen({ ...open, [type]: true })
    }

    const handleClickOpenList = (id) => { 
        if (!clickedId[id]) 
            setClickedId({...clickedId, [id]: id}) 
        else if (clickedId[id]) 
            setClickedId({...clickedId, [id]: null}) 
    } 

    const handleClose = () => {
        setOpen({ deletion: false, legend: false })
    }
    // const handleDeletionErrors = () => {
    //     deletionErrorsState(setPublish)
    //     handleClose()
    // }

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h2">Rejestr błędów</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button className={`${classes.buttonMargin} ${classes.floatRight}`} variant="contained" color="primary" onClick={() => handleClickOpen('legend')}>Legenda</Button>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.legend} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h2">Legenda błędów</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2}>
                                    <img alt="zegar" src={zegar} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Czas wystąpienia</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2}>
                                    <img alt="kalendarz" src={kalendarz} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Data wystąpienia</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2}>
                                    <img alt="temperatura zewnętrzna" src={tzew} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura zewnętrzna</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2}>
                                    <img alt="temperatura wewnętrzna" src={twew} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wewnętrzna</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={2}>
                                        <img alt="temperatura powietrza wejściowego" src={tempwejAtmo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                    : <Grid item xs={2}>
                                        <img alt="temperatura powietrza wejściowego" src={tempwejGeo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                }
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={10}>
                                        <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura powietrza wejściowego</Typography>
                                    </Grid>
                                    : <Grid item xs={10}>
                                        <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennika źródła - dół</Typography>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container>
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={2}>
                                        <img alt="temperatura powietrza wyjściowego" src={tempwyjAtmo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                    : <Grid item xs={2}>
                                        <img alt="temperatura powietrza wyjściowego" src={tempwyjGeo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                }
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={10}>
                                        <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura powietrza wyjściowego</Typography>
                                    </Grid>
                                    : <Grid item xs={10}>
                                        <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennika źródła - góra</Typography>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container>
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={2}>
                                        <img alt="temperatura wymiennika odbioru - góra" src={wymgoraAtmo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                    : <Grid item xs={2}>
                                        <img alt="temperatura wymiennika odbioru - góra" src={wymgoraGeo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                }
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennik odbioru - góra</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={2}>
                                        <img alt="temperatura wymiennika odbioru - dół " src={wymdolAtmo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                    : <Grid item xs={2}>
                                        <img alt="temperatura wymiennika odbioru - dół " src={wymdolGeo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                }
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura wymiennik odbioru - dół</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2}>
                                    <img alt="temperatura cwu" src={tcwu} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura w zasobniku cwu</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                    <Grid item xs={2}>
                                        <img alt="tłoczenie" src={tloczenieAtmo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                    : <Grid item xs={2}>
                                        <img alt="tłoczenie" src={tloczenieGeo} style={{ margin: "5px auto", display: "block", height: "30px" }} />
                                    </Grid>
                                }
                                <Grid item xs={10}>
                                    <Typography variant="body2" style={{ lineHeight: "40px" }}>Temperatura tłoczenia</Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                Lp
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="zegar" src={zegar} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="kalendarz" src={kalendarz} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="temperatura zewnętrzna" src={tzew} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="temperatura wewnętrzna" src={twew} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                                    <img alt="temperatura  powietrza wejściowego" src={tempwejAtmo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                    : <img alt="temperatura  powietrza wejściowego" src={tempwyjGeo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                }
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                                    <img alt="temperatura powietrza wyjściowego" src={tempwyjAtmo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                    : <img alt="temperatura powietrza wyjściowego" src={tempwejGeo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                }
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                                    <img alt="temperatura wymiennika odbioru - góra" src={wymgoraAtmo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                    : <img alt="temperatura wymiennika odbioru - góra" src={wymgoraGeo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                }
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                                    <img alt="temperatura wymiennika odbioru - dół " src={wymdolAtmo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                    : <img alt="temperatura wymiennika odbioru - dół " src={wymdolGeo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                }
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                <img alt="temperatura cwu" src={tcwu} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography component="div">
                                            <Box fontWeight="fontWeightBold">
                                                {deviceName === "ATMO_1" || deviceName === "ALLINONE_1" ? 
                                                    <img alt="tłoczenie" src={tloczenieAtmo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                    : <img alt="tłoczenie" src={tloczenieGeo} style={{ margin: "0 auto", display: "block", height: "50px" }} />
                                                }
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listItem(settings, deviceName, handleClickOpenList, clickedId)} 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*<Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleClickOpen('deletion')}>Kasuj błędy</Button>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.deletion} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>

                            <Typography variant="h2">Czy napewno chcesz wykasować historię błędów?</Typography>
                            <Typography variant="body2">Proces kasowania jest nieodwracalny.</Typography>
                            <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleDeletionErrors()}>Tak, kasuję permanentnie historię błędów z urządzenia</Button>
                        </DialogContent>
                    </Dialog>*/}
                </Grid>
            </Grid>
        </div>
    )
}

export default Errors