import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const ElectricalConnectionsEON = ({ required, setValues, powerLineProtection }) => {

    useEffect(() => {
        setValues({type: "childName", value: "ElectricalConnectionsEON"})
    }, []);

    return (
        <React.Fragment>
            <Typography variant='h2'>Wykonanie podłączeń elektrycznych</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Sprawdzenie połączeń elektrycznych całego urządzenia przed jego włączeniem<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Zaciski aparatury modułowej, kolejność faz</span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="connections"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="connections"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="connections" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Sprawdzenie wymaganego przekroju żył przewodu zasilającego<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Dla AIR 11/19 min. 5&#215;4 mm<sup>2</sup>, dla AIR 7 min. 5&#215;2,5 mm<sup>2</sup></span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Przekrój odpowiedni"
                                control={
                                    <Field
                                        name="wireCrossSection"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Zbyt mały przekrój"
                                control={
                                    <Field
                                        name="wireCrossSection"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="wireCrossSection" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Podłączenie zasilania
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="powerConnection"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="powerConnection"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="powerConnection" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Sprawdzenie zalecanego zabezpieczenia linii zasilającej pompę ciepła w rozdzielni głównej budynku<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Dla AIR 11/19 C25, dla AIR 7 C20</span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Odpowiedni"
                                control={
                                    <Field
                                        name="powerLineProtection"
                                        component={Radio}
                                        type="radio"
                                        value="Odpowiedni"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "powerLineProtection", value: false})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Zbyt mały prąd nominalny (niższy niż 25A/20A)"
                                control={
                                    <Field
                                        name="powerLineProtection"
                                        component={Radio}
                                        type="radio"
                                        value="Zbyt mały prąd"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "powerLineProtection", value: false})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nieodpowiednia charakterystyka (inna niż C)"
                                control={
                                    <Field
                                        name="powerLineProtection"
                                        component={Radio}
                                        type="radio"
                                        value="Inny"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "powerLineProtection", value: true})}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="powerLineProtection" />
                </Grid>
                {powerLineProtection ? (<Grid item xs={12}>
                    <Field name="powerLineProtectionValue"
                        component={TextField}
                        type="text"
                        label="Zmierzona charakterystyka"
                        fullWidth
                        validate={required}
                    />
                </Grid>) : null}
            </Grid>
        </React.Fragment>
    )
}

export default ElectricalConnectionsEON;