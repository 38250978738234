import React, { useState } from 'react'
import { AddServiceNote } from '../../../../components'
import {
    Button, TableCell, TableRow, Dialog,
    DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import serviceNoteDictionary from '../../../../dictionary/serviceNoteDictionary'
import { mainStyleClasses } from '../../../../theme'

const Note = ({ note, customerId, deviceId, noteIndex, changeServiceNotesList, perm, user, deviceSerial, keyM, partner_id, accessScada }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    return (
        <React.Fragment>
            <TableRow>
                <TableCell xs={1}>
                    <p>{noteIndex}</p>
                </TableCell>
                <TableCell xs={2}>
                    <p>{serviceNoteDictionary.serviceNoteStatus[note.note_status_id]}</p>
                </TableCell>
                <TableCell xs={2}>
                    <p>{serviceNoteDictionary.serviceNoteType[note.note_type_id]}</p>
                </TableCell>
                <TableCell xs={3}>
                    <p>{note.subject}</p>
                </TableCell>
                <TableCell xs={2}>
                    <p>{note.modification_date.replace("T", " ")}</p>
                </TableCell>
                <TableCell xs={1}>
                    <p>{note.id}</p>
                </TableCell>
                <TableCell xs={1}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen} className={`${classes.floatRight} ${classes.buttonMargin}`}>Szczegóły</Button>
                </TableCell>
            </TableRow>
            <Dialog fullWidth={true}
                maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight}>
                        <CloseIcon />
                    </IconButton>
                    <AddServiceNote accessScada={accessScada} partner_id={partner_id} user={user} deviceSerial={deviceSerial} keyM={keyM} perm={perm} handleClose={handleClose} action={"PUT"} note={note} customerId={customerId} deviceId={deviceId} changeServiceNotesList={changeServiceNotesList} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Note