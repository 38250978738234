export const descriptionDictionary = {
    main:{
        MODE: {
            AUTO: {
                name: "AUTO",
                register: "all_off",
            },
            SBF_OOF: {
                name: "OFF",
                register: "SBF_OOF",
            },
            SXF_ECO: {
                name: "ECO",
                register: "SXF_ECO"
            },
            SXF_TIME: {
                name: "TIME",
                register: "SXF_TIME",
            },
            SXF_AWA: {
                name: "AWAY",
                register: "SXF_AWA",
            },
        },
        BUILDING_TEMP: {
            descript: "Oczekiwana temperatura w&nbsp;domu",
            register: "SPHT_ZAD",
        },
        WATHER_TEMP: {
            descript: "Oczekiwana temperatura wody",
            register: "SPDHW_ZAD",
        },
        HEATING: {
            descript: "Dopuszczenie ogrzewania",
            register: "SBF_HTS",
        },
        AIR_CONDITIONING: {
            descript: "Dopuszczenie chłodzenia",
            register: "SBF_ACS",
        },
        CIRCULATION: {
            descript: "Cyrkulacja",
            register: "SXF_FLPCYR",
        },               
        ANTYLEGIONELLA: {
            descript: "Antylegionella",
            register: "SBF_PHT",
        },               
    },
    heating: {
        CUN_HCU: "Numer krzywej grzewczej",
        CUN_D_HCU: "Podbicie krzywej grzewczej",
        SX4_PH: "Temperatura graniczna grzania",
    },
    airConditioning: {
        SX2_P1: "Punkt P1 krzywej chłodzenia",
        SX2_P2: "Punkt P2 krzywej chłodzenia",
        SX4_PC: "Temperatura graniczna chłodzenia",
    },
    ecoSettings: {
        firstLabel: {
            morningTime: {
                descript: "Godzinny poranne",
                startRegister: "ECOT1R",
                stopRegister: "ECOT1S",
            },
            afternoonTimeWinter: {
                descript: "Godzinny popołudniowe zimą",
                startRegister: "ECOT2R_Z",
                stopRegister: "ECOT2S_Z",
            },
            afternoonTimeSummer: {
                descript: "Godzinny popołudniowe latem",
                startRegister: "ECOT2R_L",
                stopRegister: "ECOT2S_L",
            },
            eveningTime: {
                descript: "Godziny wieczorne",
                startRegister: "ECOT3R",
                stopRegister: "ECOT3S",
            },
        },
        secondLabel: {
            increaseMorningEvening: {
                descript: "Zwiekszenie w godzinach porannych i wieczornych taniej energii",
                register: "ECOY1",
            },
            increaseAfternoon: {
                descript: "Zwiekszenie w godzinach popołudniowych taniej energii",
                register: "ECOY2",
            },
            reduction: {
                descript: "Zmniejszenie w czasie drogiej energii",
                register: "ECOTIN",
            },
        },
        thirdLabel: {
            increaseMorningEvening: {
                descript: "Zwiekszenie w godzinach porannych i wieczornych taniej energii",
                register: "ECOX1",
            },
            increaseAfternoon: {
                descript: "Zwiekszenie w godzinach popołudniowych taniej energii",
                register: "ECOX2",
            },
            reduction: {
                descript: "Zmniejszenie w czasie drogiej energii",
                register: "ECODHW",
            },
        },
        fourthLabel: {
            descript: "Taryfa weekendowa",
            register: "SXF_WEK",
        },
        fifthLabel: {
            descript: "Sezonowa zmiana godzin popołudniowych",
            register: "SXF_AZP",
        },
    },
    away: {
        descript: "Liczba dni urlopu",
        register: "AWAY_CLOCK",
    },
    crot: {
        TOUT_KOR: "Temperatura zewnętrzna skorygowana - mechanizm CROT: ",
        TOUT_NUM: "Siła oddziaływania mechanizmu CROT",
    },
    block: {
        BLK_KD1: "Pierwsza część kodu odblokowującego",
        BLK_KD2: "Druga część kodu odblokowującego",
        BLK_LW: "Liczba dni do wyłączenia - bilans",
        BLK_LK: "Liczba poprawnych kodów",
    },
    temperature: {
        TSM9: "Temperatura zewnętrzna: ",
        TSM10: "Temperatura wewnętrzna:",
        TSM11: "Temperatura niższa na wymienniku źródła:",
        TSM12: "Temperatura wyższa na wymienniku źródła:",
        TSM13: "Temperatura wyższa na wymienniku odbioru:",
        TSM14: "Temperatura niższa na wymienniku odbioru:",
        TSM15: "Temperatura w zasobniku CWU:",
        TSM16: "Temperatura tłoczenia:",
        TSM17: "Temperatura z wejścia nie wykorzystanego:",
        T3fiz: "Temperatura wymiennika źródła – góra:",
        T4fiz: "Temperatura wymiennika źródła – dół:",
        T5fiz: "Temperatura wymiennika odbioru – góra:",
        T6fiz: "Temperatura wymiennika odbioru – dół:",
    },
    itc: {
        SPHT_HIST: "Histereza temperatury wewnętrznej",
        SPHT_ZAD: "Wartość zadana temperatury wewnętrznej",
        SCHT: "Wartość korekcji temperatury zadanej wewnętrznej wynikająca z trybów czasowych",
        SX6: "Finalna wartość temperatury zadanej wewnętrznej po korektach",
        CUN_HCU: "Numer krzywej grzewczej",
        CUN_D_HCU: "Podbicie krzywej grzewczej",
        SX2_P2: "Punkt P2 krzywej chłodzenia",
        SX2_P1: "Punkt P1 krzywej chłodzenia",
        SX3_HG: "Histereza sterowania temperatury odbioru ciepła według krzywej grzewczej",
        SX3_HC: "Histereza sterowania temperatury odbioru chłodu według krzywej chłodzenia",
        SPCU: "Wartość setpointu dla temperatury odbioru ciepła wyliczona z krzywej grzewczej",
        SCCU: "Wartość setpointu dla temperatury odbioru chłodu wyliczona z krzywej chłodzenia",
        COC: "Współczynnik sterujący mechanizmu REG+",
        SX1: "REG+ wyliczona wartość korekty setpointu dla temperatury odbioru ciepła i chłodu",
        TB1: "Setpoint finalny temperatury odbioru ciepła z krzywej grzewczej i korekt",
        TB2: "Setpoint finalny temperatury odbioru chłodu z krzywej chłodzenia i korekt",
        SX4_PH: "Próg załączenia ogrzewania względem TOUT_KOR",
        SX4_PC: "Próg załączenia chłodzenia względem TOUT_KOR",
        SPDHW_HIST: "Histereza temperatury w zasobniku CWU",
        SPDHW_ZAD: "Wartość zadana temperatury w zasobniku CWU",
    },
    errWarn: {
        REW_SUP: "Błąd softstartu / czujnika kalejności faz:",
        REW_TER: "Błąd z modułu termicznego sprężarki:",
        REW_LPC: "Błąd z presostatu niskiego ciśnienia dla chłodzenia:",
        REW_LPH: "Błąd z presostatu niskiego ciśnienia dla ogrzewania:",
        REW_HP: "Błąd z presostatu wysokiego ciśnienia:",
        REW_FLO: "Błąd z czujnika przepływu:",
        REW_DIS: "Przekroczenie temperatury tłoczenia:",
        REW_ERR: "Zbiorcza flaga stanu awaryjnego:",
        REW_SMI: "Przekroczenie temperatury minimalnej źródła ciepła (ogrzewanie):",
        REW_SMA: "Przekroczenie temperatury maksymalnej źródła chłodu (chłodzenie):",
        REW_RMI: "Przekroczenie temperatury minimalnej odbioru chłodu (chłodzenie):",
        REW_RMA: "Przekroczenie temperatury maksymalnej odbioru ciepła (ogrzewanie):",
        REW_SMD: "Przekroczenie temperatury krytycznej w wymienniku źródła:",
        REW_RMD: "Przekroczenie temperatury krytycznej w wymienniku odbioru:",
        REW_WRN: "Zbiorcza flaga stanu ostrzegawczego:",
        REW_FCS: "Awaria układu kontroli wejść binarnych:",
    },
    eco: {
        ECOT1R: "Godzina początku obowiązywania II taryfy rano (podana w godzinach od początku doby)",
        ECOT1S: "Godzina końca obowiązywania II taryfy rano (podana w godzinach od początku doby)",
        ECOT2R_Z: "Godzina początku obowiązywania II taryfy po południu - okres zimowy (podana w godzinach od początku doby)",
        ECOT2S_Z: "Godzina końca obowiązywania II taryfy po południu - okres zimowy (podana w godzinach od początku doby)",
        ECOT2R_L: "Godzina początku obowiązywania II taryfy po południu - okres letni (podana w godzinach od początku doby)",
        ECOT2S_L: "Godzina końca obowiązywania II taryfy po południu - okres letni (podana w godzinach od początku doby)",
        ECOT3R: "Godzina początku obowiązywania II taryfy wieczorem (podana w godzinach od początku doby)",
        ECOT3S: "Godzina końca obowiązywania II taryfy wieczorem (podana w godzinach od początku doby)",
        ECOX1: "Wartość podwyższenia temperatury zadanej CWU dla godzin rannych i wieczornych",
        ECOX2: "Wartość podwyższenia temperatury zadanej CWU dla godzin popołudniowych",
        ECODHW: "Wartość redukcji temperatury zadanej CWU poza godzinami taniej energii",
        ECOY1: "Wartość podwyższenia temperatury zadanej wewnętrznej dla godzin rannych i wieczornych",
        ECOY2: "Wartość podwyższenia temperatury zadanej wewnętrznej dla godzin popołudniowych",
        ECOTIN: "Wartość redukcji temperatury zadanej wewnętrznej poza godzinami taniej energii",
    },
    cfs: {
        SBF_PHT: "Manualne załączenie funkcji Antylegionella",
        SXF_TOE: "Zezwolenie na kontrolę temperaturą wewnętrzną (HEAT, AC, FC)",
        SBF_HTX: "Możliwość załączenia ogrzewania budynku",
        SBF_HTS: "Załączenie ogrzewania budynku",
        SBF_DHX: "Możliwość załączenia podgrzewania CWU",
        SBF_DHS: "Załączenie podgrzewania CWU",
        SBF_FCX: "Możliwość załączenia Free-Coolingu",
        SBF_FCS: "Załączenie Free-Coolingu",
        SBF_OOF: "Załączenie całego urządzenia",
        SBF_AHX: "Możliwość załączenia źródła szczytowego",
        SBF_AHS: "Możliwość załączenia źródła szczytowego tylko w tanim prądzie",
        SBF_ACX: "Możliwość załączenia Active-Coolingu",
        SBF_ACS: "Załączenie Active-Coolingu",
        SXF_TIM_WIN_SW: "Zezwolenie na automatyczną zmianę czasu (letni/zimowy)",
        SXF_TIM_WIN_FL: "1 = czas letni / 0 = czas zimowy",
        SXF_ACT: "Aktywna blokada czasowa",
        SXF_FLPCYR: "Wymuszenie czasowe pracy pompy cyrkulacyjnej",
        SXF_AZP: "Zezwolenie na sezonową zmianę godzin popołudniowych dla trybu ECO",
        SXF_TIMCYR: "Zezwolenie na sterowanie czasowe pompą cyrkulacyjną CWU",
        SXF_ENACYR: "Zezwolenie na załączenie pompy cyrkulacyjnej CWU",
        SXF_FORCYR: "Wymuszenie pracy pompy cyrkulacyjnej CWU",
        SXF_TIME: "Załączenie trybu TIME",
        SXF_ANL: "Zezwolenie na załączenie trybu Antylegionella",
        SXF_WEK: "Taryfa weekendowa rozliczania energii",
        SXF_AWA: "Załączenie trybu AWAY",
        SXF_ECO: "Załączenie trybu ECO",
        SXF_DHM: "0 = CWU przez zawór 2 drożny / 1 = CWU przez pompę obiegową",
        SXF_4EN: "Załączenie kontroli poprawności pracy zaworu 4 drożnego",
        ANL_ALL: "Tryb antylegionella w trakcie wygrzewania",
        ANL_1PH: "Tryb antylegionella w fazie nr 1",
        ANL_2PH: "Tryb antylegionella w fazie nr 2",
        ANL_3PH: "Tryb antylegionella w fazie nr 3",
    },
    ptc: {
        CYR_FILL: "Czas cyklicznej pracy pompy cyrkulacyjnej",
        CYRSW_S3: "Załączenie sterowania czasowego CWU dla strefy 3",
        CYRSW_S2: "Załączenie sterowania czasowego CWU dla strefy 2",
        CYRSW_S1: "Załączenie sterowania czasowego CWU dla strefy 1",

    }
}