import React, { Fragment, useEffect } from 'react'
import { Grid, Typography, Table, TableRow, TableCell, TableBody, TableContainer, Paper } from '@material-ui/core'

const ConfirmSendFormEON = ({ values, setValues }) => {

    useEffect(() => {
        setValues({type: "childName", value: "ConfirmSendFormEON"})
    }, []);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h2">Dane wprowadzone do protokołu:</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Protokół sporządził:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.drafted}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Data sporządzenia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.date}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Typ urządzenia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.modelType === "ATMO" ? "Zestaw Powietrznej Pompy Ciepła (ZPPC)" : "Zestaw Gruntowej Pompy Ciepła (ZGPC)"}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Model urządzenia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.model}</strong></Typography></TableCell>
                            </TableRow>
                            {values.modelType === 'ATMO' || values.modelType === 'ALLINONE' ? (
                                <Fragment>
                                    <TableRow>
                                        <TableCell size="small"><Typography variant="body1">Numer seryjny jednostki wewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.serialNumber? values.serialNumber : values.device_sn}</strong></Typography></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size="small"><Typography variant="body1">Numer seryjny jednostki zewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.serialNumberOutsideATMO}</strong></Typography></TableCell>
                                    </TableRow>
                                </Fragment>) : (

                                    <TableRow>
                                        <TableCell size="small"><Typography variant="body1">Numer seryjny:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.serialNumber? values.serialNumber : values.device_sn}</strong></Typography></TableCell>
                                    </TableRow>
                                )}
                            {/*<TableRow>
                                <TableCell size="small"><p>Inwestor:</p></TableCell><TableCell size="small"><p> <strong>{values.investorName}</strong></p></TableCell>
                            </TableRow>*/}
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Miejsce Montażu:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{`ul. ${values.street} ${values.streetNumber}, ${values.postNumber} ${values.city}, ${values.postOffice}`}</strong></Typography></TableCell>
                            </TableRow>
                            {/*<TableRow>
                                <TableCell size="small"><p>Rodzaj budynku:</p></TableCell><TableCell size="small"><p><strong>{values.typeBuilding}</strong></p></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><p>Poziom kondygnacji:</p></TableCell><TableCell size="small"><p><strong>{values.typeRoom}</strong></p></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><p>Instalator:</p></TableCell><TableCell size="small"><p><strong>{values.fitterName}</strong></p></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><p>Czy urządzenie ma uszkodzenia mechaniczne?:</p></TableCell><TableCell size="small"><p><strong>{values.broken}</strong></p></TableCell>
                            </TableRow>
                            {values.broken === 'Tak' ? (<TableRow>
                                <TableCell size="small"><p>Opisz rodzaj i miejsce uszkodzeń mechanicznych:</p></TableCell><TableCell size="small"><p><strong>{values.demageDescription}</strong></p></TableCell>
                            </TableRow>) : null}*/}
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Urządzenie wolne od uszkodzeń mechanicznych:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.inspection}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            {values.inspectionComments ? (<TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Uwagi:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.inspectionComments}</strong></Typography>
                                </TableCell>
                            </TableRow>) : null}
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Napełnienie instalacji:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.filling}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Wartość ciśnienia w układzie:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.pressure} bar</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ocena szczelności połączeń hydraulicznych:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.tightness}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ilość zużytego koncentratu:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.concentrateUsed} l</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ilość pozostawionego koncentratu u Klienta:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.concentrateLeft} l</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ilość roztworu wtłoczonego do instalacji:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.solutionUsed} l</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ilość roztworu pozostawionego u Klienta:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.solutionLeft} l</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Typ koncentratu glikolu:</Typography>
                                </TableCell>
                                {values.glycolType === "Inny" ?
                                    <TableCell size="small">
                                        <Typography variant="body1"><strong>{values.glycolTypeValue}</strong></Typography>
                                    </TableCell> :
                                    <TableCell size="small">
                                        <Typography variant="body1"><strong>{values.glycolType}</strong></Typography>
                                    </TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Aplikacja inhibitora korozji:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.corrosion}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Sprawdzenie połączeń elektrycznych całego urządzenia przed jego włączeniem:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.connections}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Sprawdzenie wymaganego przekroju żył przewodu zasilającego:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.wireCrossSection}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Podłączenie zasilania:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.powerConnection}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Sprawdzenie zalecanego zabezpieczenia linii zasilającej pompę ciepła w rozdzielni głównej budynku:</Typography>
                                </TableCell>
                                {values.powerLineProtection === "Inny" ?
                                    <TableCell size="small">
                                        <Typography variant="body1"><strong>{values.powerLineProtectionValue}</strong></Typography>
                                    </TableCell> :
                                    <TableCell size="small">
                                        <Typography variant="body1"><strong>{values.powerLineProtection}</strong></Typography>
                                    </TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Podłączenie czujników temperatury:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1">{values.sensorsConnected ? (<strong>{values.sensorsConnected.map(sensor => `${sensor}, `)}</strong>) : (<strong>czujniki nie zostały podłączone</strong>)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Umiejscowienie czujnika temperatury zewnętrznej:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.sensorOutsideTempPlace}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Zacienienie miejsca montażu czujnika:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.sensorOutsideTempShade}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Umiejscowienie czujnika temperatury wewnętrznej:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.sensorInsideTempPlace}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Narażenie miejsca montażu na nasłonecznienie lub umiejscowienie przy źródle ciepła:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.heatExposure}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            {values.heatExposure === "Tak" ?
                                <TableRow>
                                    <TableCell size="small">
                                        <Typography variant="body1">Opis:</Typography>
                                    </TableCell>
                                    <TableCell size="small">
                                        <Typography variant="body1"><strong>{values.heatExposureComments}</strong></Typography>
                                    </TableCell>
                                </TableRow> : null}
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Wymuszenie funkcji przygotowania cwu, odpowietrzenie obiegu przygotowania cwu, kontrola przepływu w obiegu przygotowania cwu:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.preparationCwu}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Wyłączenie funkcji przygotowania cwu i wymuszenie pracy na ogrzewanie, odpowietrzenie obiegu grzewczo - chłodzącego, kontrola przepływu w obiegu grzewczo - chłodzącym:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.forceHeating}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Demontaż dotychczasowego układu napełnia zładu:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.disassembly}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Pierwsze czyszczenie filtrów w obiegu grzewczym:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.cleanFilter}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Kontrola temperatury krzepnięcia w obiegu grzewczym:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.controlCoagulationTemp}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            {values.coagulationTemp? <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Temperatura krzepnięcia:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.coagulationTemp}&#x2103;</strong></Typography>
                                </TableCell>
                            </TableRow> : null}
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Załączenie bezpiecznika jednostki zewnętrznej:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.outdoorFuse}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Przywrócenie nastaw fabrycznych:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.reinstatementSettings}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Skasowanie historii alarmów, skasowanie statystyk:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.deleteHistory}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Kontrola znaku i wartości dT na wymienniku odbioru:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.dTValueControl}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            {values.dTValue? <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Wartości dT:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.dTValue}</strong></Typography>
                                </TableCell>
                            </TableRow> : null}
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Procedura uruchomienia w aplikacji serwisowej:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.launchAtmoMonitor}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ustawienie temperatury dla CWU:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1">
                                        {values.tempAdjustCWU === "Tak" ? (<strong>45&#x2103;</strong>) : (<strong>{values.tempCwuValue}&#x2103;</strong>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Ustawienie temperatury dla CO:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1">
                                        {values.tempAdjustCO === "Tak" ? (<strong>21&#x2103;</strong>) : (<strong>{values.tempCoValue}&#x2103;</strong>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Temperatura powietrza umożliwiająca start CO:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1">
                                        {values.startCoTemp === "Tak" ? (<strong>20&#x2103;</strong>) : (<strong>{values.startCoTempValue}&#x2103;</strong>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Test panelu operatorskiego:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.testOperatorPanel}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Komunikacja z Internetem:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.internetConnection}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Rejestracja pompy ciepła w systemie fabrycznym:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.deviceRegistration}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Test mobilnej aplikacji sterującej:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.MASTest}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Instalacja mobilnej aplikacji sterującej na urządzeniu Inwestora:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.MASInstall}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Przeszkolenie Inwestora z zasady działania urządzenia:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.operationPrinciples}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Przeszkolenie Inwestora z włączania i wyłączania pompy ciepła:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.switchOnOff}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Przeszkolenie Inwestora ze sterowania i wykonywania prostych konfiguracji:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.simpleConfigurations}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Przeszkolenie Inwestora z czyszczenia filtrów (skośnych/siatkowych, magnetycznych):</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.filterCleaning}</strong></Typography>
                                </TableCell>
                            </TableRow>
                            {values.comments ? (<TableRow>
                                <TableCell size="small">
                                    <Typography variant="body1">Pozostałe uwagi:</Typography>
                                </TableCell>
                                <TableCell size="small">
                                    <Typography variant="body1"><strong>{values.comments}</strong></Typography>
                                </TableCell>
                            </TableRow>) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            </Grid>
        </React.Fragment>
    )
}

export default ConfirmSendFormEON