import React, { useReducer, useState, useEffect } from 'react'
import { Grid, TextField, Button, Typography, IconButton, Snackbar, InputLabel, FormControl, Select, MenuItem, Fab, Menu } from '@material-ui/core'
import AddIcon from "@material-ui/icons/Add"
import Tooltip from "@material-ui/core/Tooltip"
import MuiAlert from '@material-ui/lab/Alert'
import {
    Redirect
} from 'react-router-dom'
import { mainStyleClasses } from '../../theme'
import { fetchAddDevice, fetchPartnersId, fetchDistribution, fetchAddPv } from '../../data'
import { ExcelRenderer } from 'react-excel-renderer'
import CloseIcon from '@material-ui/icons/Close'
import formatString from 'format-string-by-pattern'
import { validation } from '../../functions'


const required = value => (value ? undefined : "Pole obowiązkowe")
const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const textOnly = (anystring) => {
    const text = anystring.replace(/[0-9]/g, '')

    return text
}

const formatDeviceSn = (anyString) => {
    // const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999999-99', anyString)
}

const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('99-999', onlyNumbers)
}

const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const minLength = (min, value) => value === undefined || value.length < min ? true : false

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return true
    } else {
        return false
    }
}

const formatUpperCase = (anyString, modelType) => {
    const onlyUpperCase = anyString.toUpperCase()
    return formatString(modelType, onlyUpperCase)
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const newDeviceReducer = (state, action) => {
    return {
        ...state,
        [action.type]: action.payload
    }
}

const paternFAI = /^fai$/i
const paternEG = /^eg$/i
const paternAIO = /^aio$/i
const paternZOU = /^zou$/i

const deviceNumber = (value, deviceType) => {
    if (value !== undefined && value.length >= 3) {
        let testAIO = paternAIO.test(value.slice(0, 3))
        let testFAI = paternFAI.test(value.slice(0, 3))
        let testZOU = paternZOU.test(value.slice(0, 3))
        let testEG = paternEG.test(value.slice(0, 2))

        switch (deviceType) {
            case "ATMO":
                if (!testFAI) {
                    return "Nieprawidłowy numer seryjny urządzenia"
                } else {
                    return undefined
                }
            case "GEO":
                if (!testEG) {
                    return "Nieprawidłowy numer seryjny urządzenia"
                } else {
                    return undefined
                }
            case "ALLINONE":
                if (!testAIO) {
                    return "Nieprawidłowy numer seryjny urządzenia"
                } else {
                    return undefined
                }
            case "PV":
                if (!testZOU) {
                    return "Nieprawidłowy numer seryjny urządzenia"
                } else {
                    return undefined
                }

            default:
                return "Nieprawidłowy numer seryjny urządzenia"
        }
    }
}

const addNewDevice = (setBaseLogin, token, { deviceType, deviceSn, distribution, partnerId }, toastInfoStatus, setErrorDevice, errorDevice) => {
    let validateDeviceSn = deviceNumber(deviceSn, deviceType)

    if (token && deviceType && deviceSn && !validateDeviceSn) {
        console.log({ dev_type: deviceType, device_sn: deviceSn, partner_id: partnerId, distribution_id: distribution })
        fetchAddDevice(token, { dev_type: deviceType, device_sn: deviceSn, partner_id: partnerId, distribution_id: distribution })
            .then(response => {
                if (response.ok) {
                    toastInfoStatus("single")
                    setErrorDevice({
                        ...errorDevice,
                        pump: null
                    })
                    return response.json()
                } else {
                    throw new Error(`Wystąpił błąd połączenia: ${response.status} ${response.statusText}`)
                }
            })
            .catch(error => {
                setBaseLogin({ type: "token", payload: null })
                localStorage.removeItem("APIToken")
                //console.log(error)
            })
    } else if (validateDeviceSn) {
        setErrorDevice({
            ...errorDevice,
            pump: validateDeviceSn
        })
    }
}

const addNewPv = (payload, toastInfoStatus, setErrorDevice, errorDevice, setAddPv, hardwareVers, setHardwareVers) => {
    let validateDeviceSn = deviceNumber(payload.device_sn, "PV")
    payload = { ...payload, hardware_version: JSON.stringify(hardwareVers)}
    
    if (payload.device_sn && !validateDeviceSn) {
        fetchAddPv(payload)
            .then(response => {
                if (response.ok) {
                    toastInfoStatus("single")
                    setErrorDevice({
                        ...errorDevice,
                        pv: null
                    })
                    setAddPv({ dev_type: "PV", device_sn: "", email: "", phone: "", first_name: "", last_name: "", city: "", street: "", street_number: "", post_code: "" })
                    setHardwareVers([{ rowId: 0, name: "", serial: "" }])
                    return response.json()
                } else {
                    throw new Error(response.status)
                }
            })
            .catch(error => {
                setErrorDevice({
                    ...errorDevice,
                    pv: validation.validationAddDevice(parseInt(error.message))
                })
            })
    } else if (validateDeviceSn){
        setErrorDevice({
            ...errorDevice,
            pv: validateDeviceSn
        })
    }
}

const addNewDevices = (setBaseLogin, token, fileExcel, toastInfoStatus, setErrorDevice, errorDevice) => {

    for (let i = 0; i < fileExcel.length; i++) {
        fetchAddDevice(token, { dev_type: fileExcel[i][0].toUpperCase(), device_sn: fileExcel[i][1].toUpperCase(), partner_id: fileExcel[i][2], distribution_id: fileExcel[i][3] })
            .then(response => {
                if (response.ok) {
                    if (i === fileExcel.length - 1) {
                        toastInfoStatus("more")
                    }
                    setErrorDevice({
                        ...errorDevice,
                        pump: null
                    })
                    return response.json()
                } else {
                    throw new Error(response.status)
                }
            })
            .catch(error => {
                setErrorDevice({
                    ...errorDevice,
                    pump: validation.validationAddDevice(parseInt(error.message))
                })
            })

    }
}

const acceptFile = [".csv", ".xlsx", ".xls"]
const acceptDeviceType = ["atmo", "geo", "allinone", "ATMO", "GEO", "ALLINONE"]

const AddDevices = ({ token, setBaseLogin }) => {
    const classes = mainStyleClasses()
    const [newDevice, setNewDevice] = useReducer(newDeviceReducer, { deviceType: "", deviceSn: "", distribution: '', partnerId: '' })
    const [addPv, setAddPv] = useState({ dev_type: "PV", device_sn: "", email: "", phone: "", first_name: "", last_name: "", city: "", street: "", street_number: "", post_code: "", partner_id: 1 })
    const [fileExcel, setFile] = useState(null)
    const [newDeviceStatus, setDeviceStatus] = useState({ status: false, message: "" })
    const [errorFile, setErrorFile] = useState("")
    const [errorDevice, setErrorDevice] = useState({ pump: null, pv: null })
    const [partners, setPartners] = useState(null)
    const [distribution, setDistribution] = useState(null)
    const [hardwareVers, setHardwareVers] = useState([{ rowId: 0, name: "", serial: "" }])
    const [fieldError, setFieldError] = useState({})

    const handleHardwareVers = () => {
        let errorInfo = false
        for (let index = 0; index < hardwareVers.length; index++) {
            
            if(hardwareVers[index].serial === "" || hardwareVers[index].name === ""){
                errorInfo = true
                if(hardwareVers[index].serial === "")
                setFieldError({ ...fieldError, [`rowId${index}serial`]: { status: true, msg: "To pole jest wymagane"}})
                else if (hardwareVers[index].name === "")
                setFieldError({ ...fieldError, [`rowId${index}name`]: { status: true, msg: "To pole jest wymagane"}})
            }
        }

        if(!errorInfo)
        setHardwareVers([...hardwareVers, { rowId: hardwareVers.length, name: "", serial: ""}])
    }

    const addHardwareVers = (e, i) => {
        let arrHelp = hardwareVers.slice()
        arrHelp[i][e.target.name] = e.target.value
        setHardwareVers(arrHelp)
        setFieldError({ ...fieldError, [`rowId${i}${e.target.name}`]: { status: false, msg: ""}})
    }

    const fieldValidation = (e) => {
        switch (e.target.name) {
            case "email":
                if (formatEmail(e.target.value))
                    setFieldError({ ...fieldError, email: { status: true, msg: !e.target.value || e.target.value === "" ? "To pole jest wymagane" : "Nieprawidłowy adres e-mail." } })
                else
                    setFieldError({ ...fieldError, email: { status: false, msg: "" } })
                break;

            case "phone":
                if (minLength(11, e.target.value))
                    setFieldError({ ...fieldError, phone: { status: true, msg: !e.target.value || e.target.value === "" ? "To pole jest wymagane" : "Numer telefonu jest za krótki." } })
                else
                    setFieldError({ ...fieldError, phone: { status: false, msg: "" } })
                break;

            default:
                if (!e.target.value || e.target.value === "")
                    setFieldError({ ...fieldError, [e.target.name]: { status: true, msg: "To pole jest wymagane" } })
                else
                    setFieldError({ ...fieldError, [e.target.name]: { status: false, msg: "" } })
                break;
        }
    }

    const handleNewDevice = (e) => {
        if (e.target.name === "deviceType" || e.target.name === "deviceSn")
            setNewDevice({ type: e.target.name, payload: e.target.value.toUpperCase() })
        else {
            setNewDevice({ type: e.target.name, payload: parseInt(e.target.value) })
        }
    }
    const handleNewPv = (e) => {
        if (e.target.name === "dev_type" || e.target.name === "device_sn") {
            // if(e.target.name === "device_sn")
            const device_sn = formatDeviceSn(e.target.value)
            setAddPv({ ...addPv, [e.target.name]: device_sn.toUpperCase() })
            // setAddPv({ ...addPv, [e.target.name]: e.target.value.toUpperCase() })

        } else {
            switch (e.target.name) {
                case "post_code":
                    const post_code = formatOnlyNumbers(e.target.value)
                    setAddPv({ ...addPv, [e.target.name]: post_code })
                    break;
                case "phone":
                    const phone = formatOnlyNumbersTel(e.target.value)
                    setAddPv({ ...addPv, [e.target.name]: phone })
                    break;
                case "first_name":
                    const first_name = textOnly(e.target.value)
                    setAddPv({ ...addPv, [e.target.name]: first_name })
                    break;
                case "last_name":
                    const last_name = textOnly(e.target.value)
                    setAddPv({ ...addPv, [e.target.name]: last_name })
                    break;
                case "device_sn":
                    const device_sn = formatDeviceSn(e.target.value)
                    console.log("wejszło")
                    setAddPv({ ...addPv, [e.target.name]: device_sn })
                    break;
                default:
                    setAddPv({ ...addPv, [e.target.name]: e.target.value })
                    break;
            }

        }
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
            const dotIndex = fileObj.name.lastIndexOf(".")

            if (err || !acceptFile.includes(fileObj.name.slice(dotIndex))) {
                setErrorFile("Błąd załączonego pliku. Plik powinien być w formacie: .csv, .xls, lub .xlsx")
            }
            else {
                setErrorFile("")
                const addElement = []
                let errorsTable = []
                for (let i = 0; i < resp.rows.length; i++) {
                    if (resp.rows[i].length !== 0 && acceptDeviceType.includes(resp.rows[i][0]))
                        addElement.push(resp.rows[i])
                    if (!acceptDeviceType.includes(resp.rows[i][0])) {
                        errorsTable.push(i + 1)
                    }
                }

                if (errorsTable.length > 0) {
                    setErrorFile(`W pliku znajduje się nieznany typ urządzenia w ${errorsTable.length === 1 ? "wierszu numer" : "wierszach o numerach"}: ${errorsTable}. Na liście zapisano wyłącznie prawidłowe typy urządzeń. Możesz załączyć poprawiony plik, lub dodać wyświetloną listę urządzeń, a następnie uzupełnić o brakujące pozycje.`)
                }
                setFile({
                    rows: addElement
                });
            }
        });

    }

    const handleClose = deleteIndex => {
        let newRows = fileExcel.rows.filter((item, index) => index !== deleteIndex)

        setFile({
            rows: newRows
        })
    }

    const toastInfoStatus = (type) => {
        if (type === "single")
            setDeviceStatus({ status: true, message: `Dodano urządzenie o numerze seryjnym: ${newDevice.deviceSn? newDevice.deviceSn : addPv.device_sn}` })
        else
            setDeviceStatus({ status: true, message: `Dodano urządzenia z załączonego pliku` })
    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNewDevice({ type: "deviceType", payload: "" })
        setNewDevice({ type: "deviceSn", payload: "" })
        setDeviceStatus({ status: false, message: "" });
    }

    useEffect(() => {
        let mounted = true
        fetchPartnersId(token)
            .then(response => {
                if (response.status === "ok") {
                    setPartners(response.data)
                } else {
                    throw new Error(response.status)
                }
            }).catch(error => {
                console.log(error)
            })
        fetchDistribution(token)
            .then(response => {
                if (response.status === "ok") {
                    setDistribution(response.data)
                } else {
                    throw new Error(response.status)
                }
            }).catch(error => {
                console.log(error)
            })

        return () => {
            mounted = false
        }
    }, [])

    const [anchorEl, setAnchorEl] = useState(null);
    const [form, setForm] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = (type) => {
      setAnchorEl(null);
      setForm(type)
    };
console.log(form)
    return (
        <Grid container direction="row" >
            {!token ? <Redirect to="/" /> : null}
            <Grid container direction="row" >
                <Grid item xs={6}>
                    <Typography variant="h2">Dodawanie nowej instalacji</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={6}>
                    <Button className={`${classes.floatLeft} ${classes.buttonMargin}`} variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        Wybierz formularz
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        >
                        <MenuItem onClick={() => handleCloseMenu('pompa')}>Pompa ciepła</MenuItem>
                        <MenuItem onClick={() => handleCloseMenu('PV')}>Instalacja PV</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
            {form === 'pompa' ?
                <Grid item xs={12} md={6}>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <Typography variant="h3">Pompa ciepła</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel id="deviceType">Typ urządzenia</InputLabel>
                                <Select
                                    name="deviceType"
                                    labelId="deviceType"
                                    id="deviceType"
                                    value={newDevice.deviceType}
                                    onChange={(e) => handleNewDevice(e)}
                                >
                                    <MenuItem value={"ATMO"}>ATMO</MenuItem>
                                    <MenuItem value={"GEO"}>GEO</MenuItem>
                                    <MenuItem value={"ALLINONE"}>ALLINONE</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <TextField name="deviceSn" label="Numer seryjny urządzenia" type="text" value={newDevice.deviceSn} onChange={(e) => handleNewDevice(e)} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel id="partner">Partner</InputLabel>
                                <Select
                                    name="partnerId"
                                    labelId="partner"
                                    id="partnerId"
                                    value={newDevice.partnerId}
                                    onChange={(e) => handleNewDevice(e)}
                                >
                                    {partners ? Object.entries(partners).map(item => <MenuItem key={item[1].id} value={item[1].id}>{item[1].name}</MenuItem>) : <MenuItem>Bark listy partnerów</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel id="distribution_id">Dystrybucja</InputLabel>
                                <Select
                                    name="distribution"
                                    labelId="distribution_id"
                                    id="distribution"
                                    value={newDevice.distribution}
                                    onChange={(e) => handleNewDevice(e)}
                                >
                                    {distribution ? Object.entries(distribution).map(item => <MenuItem key={item[1].id} value={item[1].id}>{item[1].name}</MenuItem>) : <MenuItem>Bark listy dystrybucji</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}><br /><Button variant="contained" color="primary" type="submit" onClick={() => addNewDevice(setBaseLogin, token, newDevice, toastInfoStatus, setErrorDevice, errorDevice)}>Dodaj</Button>
                            {errorDevice && errorDevice.pump ? <Typography variant="body1" style={{ color: "red", marginTop: "15px" }}>{errorDevice.pump}</Typography> : null}</Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <Typography variant="h3">Lista instalacji z pliku (.csv, .xls, .xlsx)</Typography>
                            <Typography variant="body1">Struktura powinna zawierać dwie kolumny: pierwsza kolumna to typ urządzenia (atmo/geo/allinone), druga kolumna to numer seryjny urządzenia, trzecia kolumna id partnera, czwarta kolumna id dystrybucji</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <TextField type="file" id={`contained-button-file`}
                                className={`${classes.hidden}`} onChange={e => fileHandler(e)} style={{ padding: "10px", display: "none" }} />
                            {!fileExcel || fileExcel.rows.length === 0 ? (<label htmlFor={`contained-button-file`}>
                                <Button id={`contained-button-file-devices-list`} className={`${classes.floatLeft} ${classes.buttonMargin}`} variant="contained" color="primary" component="span">
                                    Dodaj plik z listą urządzeń
                                </Button>

                            </label>) : null}
                            {errorFile !== "" ? <Typography variant="body1" style={{ color: "red", marginTop: "15px" }}>{errorFile}</Typography> : null}
                            {fileExcel ? <ol>{fileExcel.rows ? fileExcel.rows.map((item, index) => (
                                <li key={item[1]}>
                                    Typ: {item[0]}, Nr seryjny: {item[1]}, Id partnera: {item[2]}, Id dystrybucji: {item[3]}
                                    <IconButton aria-label="close" onClick={() => handleClose(index)} style={{ display: "inlineBlock", marginLeft: "30px" }}>
                                        <CloseIcon />
                                    </IconButton>
                                </li>
                            )) : null}</ol> : null}
                        </Grid>
                    </Grid>
                    {fileExcel && fileExcel.rows.length !== 0 ? <Grid container direction="row" >
                        <Grid item xs={12}><br />
                            <Button variant="contained" color="primary" type="submit" onClick={() => addNewDevices(setBaseLogin, token, fileExcel.rows, toastInfoStatus, setErrorDevice, errorDevice)}>Dodaj wszystkie</Button>
                        </Grid>
                    </Grid> : null}
                </Grid>
                : form === 'PV' ?
                    <Grid item xs={12} md={6}>

                        <Grid container direction="row" >
                            <Grid item xs={12}>
                                <Typography variant="h3">Instalacja PV</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" >
                            <Grid item xs={12}>
                                <TextField name="device_sn" label="Numer seryjny urządzenia" type="text" value={addPv.device_sn} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.device_sn && fieldError.device_sn.status ? true : false} helperText={fieldError && fieldError.device_sn && fieldError.device_sn.status ? fieldError.device_sn.msg : ""} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" >
                            <Grid item xs={12}>
                                <Typography variant="h3" style={{display: 'inline'}}>Urządzenia dodatkowe</Typography>
                                <Tooltip title="Dodaj kolejne" placement="right-start">
                                <Fab
                                style={{ left: "1rem" }}
                                size="small"
                                color="primary"
                                onClick={() => handleHardwareVers()}
                                >
                                <AddIcon />
                                </Fab>
                            </Tooltip>
                            </Grid>
                        </Grid>
                        {hardwareVers && hardwareVers.length > 0 ?
                            <React.Fragment>{hardwareVers.map((item, i) => {
                                return <Grid container direction="row" key={`hardwareVers${i}`}>
                                    <Grid item xs={6}>
                                        <TextField name="serial" label="Numer seryjny" type="text" value={item.serial} onChange={(e) => addHardwareVers(e, i)} 
                                        error={fieldError && fieldError[`rowId${i}serial`] && fieldError[`rowId${i}serial`].status ? true : false} helperText={fieldError && fieldError[`rowId${i}serial`] && fieldError[`rowId${i}serial`].status ? fieldError[`rowId${i}serial`].msg : ""}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField name="name" label="Nazwa" type="text" value={item.name} onChange={(e) => addHardwareVers(e, i)} 
                                        error={fieldError && fieldError[`rowId${i}name`] && fieldError[`rowId${i}name`].status ? true : false} helperText={fieldError && fieldError[`rowId${i}name`] && fieldError[`rowId${i}name`].status ? fieldError[`rowId${i}name`].msg : ""}/>
                                    </Grid>
                                </Grid>
                            })}
                            
                            </React.Fragment>
                            : null
                        }
                        <Grid container direction="row" >
                            <Grid item xs={12}>
                                <Typography variant="h3">Dane klienta</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" >
                            <Grid container direction="row" >
                                <Grid item xs={6}>
                                    <TextField name="first_name" label="Imię" type="text" value={addPv.first_name} onChange={(e) => handleNewPv(e)}
                                        onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.first_name && fieldError.first_name.status ? true : false} helperText={fieldError && fieldError.first_name && fieldError.first_name.status ? fieldError.first_name.msg : ""} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField name="last_name" label="Nazwisko" type="text" value={addPv.last_name} onChange={(e) => handleNewPv(e)}
                                        onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.last_name && fieldError.last_name.status ? true : false} helperText={fieldError && fieldError.last_name && fieldError.last_name.status ? fieldError.last_name.msg : ""} />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="email" label="Adres e-mail" type="text" value={addPv.email} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.email && fieldError.email.status ? true : false} helperText={fieldError && fieldError.email && fieldError.email.status ? fieldError.email.msg : ""} />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="phone" label="Numer telefonu" type="text" value={addPv.phone} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.phone && fieldError.phone.status ? true : false} helperText={fieldError && fieldError.phone && fieldError.phone.status ? fieldError.phone.msg : ""} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" >
                            <Grid item xs={8}>
                                <TextField name="street" label="Ulica" type="text" value={addPv.street} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.street && fieldError.street.status ? true : false} helperText={fieldError && fieldError.street && fieldError.street.status ? fieldError.street.msg : ""} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="street_number" label="Numer ulicy" type="text" value={addPv.street_number} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.street_number && fieldError.street_number.status ? true : false} helperText={fieldError && fieldError.street_number && fieldError.street_number.status ? fieldError.street_number.msg : ""} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" >
                            <Grid item xs={8}>
                                <TextField name="city" label="Miasto" type="text" value={addPv.city} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.city && fieldError.city.status ? true : false} helperText={fieldError && fieldError.city && fieldError.city.status ? fieldError.city.msg : ""} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="post_code" label="Kod pocztowy" type="text" value={addPv.post_code} onChange={(e) => handleNewPv(e)}
                                    onBlur={(e) => fieldValidation(e)} error={fieldError && fieldError.post_code && fieldError.post_code.status ? true : false} helperText={fieldError && fieldError.post_code && fieldError.post_code.status ? fieldError.post_code.msg : ""} />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" >
                            <Grid item xs={12}><br /><Button variant="contained" color="primary" type="submit" onClick={() => addNewPv(addPv, toastInfoStatus, setErrorDevice, errorDevice, setAddPv, hardwareVers, setHardwareVers)}>Dodaj</Button>
                                {errorDevice && errorDevice.pv ? <Typography variant="body1" style={{ color: "red", marginTop: "15px" }}>{errorDevice.pv}</Typography> : null}</Grid>
                        </Grid>

                    </Grid>
                    : null
                }
            <Snackbar open={newDeviceStatus.status} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {newDeviceStatus.message}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default AddDevices