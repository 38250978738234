import React, { useState, useEffect } from 'react'
import { Typography, Button, Grid, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { NewAcceptanceReport, InstallationList, InstallerData } from './components'
import { mainStyleClasses } from '../../../../theme'
import { saveAs } from 'file-saver'
import { fetchInstallatorDetails, fetchCertInstallator } from '../../../../data'

const InstallatorDetails = ({ perm }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ newReport: false, legend: false })
    const [installator, setInstallator] = useState(null)
    let { id } = useParams();

    const handleClickOpen = (type) => {
        setOpen({ ...open, [type]: true })
    }

    const handleClose = () => {
        setOpen({ newReport: false, legend: false })
    }

    const refreshInstallator = () => {
        fetchInstallatorDetails(id).then(response => setInstallator(response))
    }

    useEffect(() => {
        let mounted = true

        refreshInstallator()

        return () => {
            mounted = false
        }
    }, [])

    const getCertificate = async(id, name) => {
        const blobCertificate = await fetchCertInstallator(id).then(res => res)
        const file = new Blob([blobCertificate], {type: 'application/pdf'})
        //let fileURL = URL.createObjectURL(file);
        saveAs(file, `certyfikat_${name}.pdf`)
    //   window.open(fileURL);
    //    return file
    }
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    {installator && installator.status === "ok" ? <InstallerData installator={installator.data} /> : null}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {installator && installator.status === "ok" ? <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => getCertificate(id, `${installator.data.first_name}_${installator.data.last_name}`)}> Pobierz Certyfikat</Button> : null}
                </Grid>
            </Grid>
                {installator && installator.status === "ok" ? <InstallationList refreshInstallator={refreshInstallator} perm={perm? perm : null} devices={installator.data.devices} /> : null }
        </React.Fragment>
    )
}

export default InstallatorDetails