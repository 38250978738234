// Kontrolery:
// 1 - przełącznik 0/1
// 2 - suwak
// 3 - karuzela
// 4 - znacznik zielon/czerowny (w znaczeniu występuje/nie występuje)

// Symbol -> jeśli występuje to razem ze spacją (o ile ma być spacja między liczbą a symbolem), pusty string jeśli nie ma symbolu

export const dataRange = () => {

	const deviceName = sessionStorage.getItem('deviceName')
	const version_1 = parseInt(sessionStorage.getItem('version_1'))
	const version_2 = parseInt(sessionStorage.getItem('version_2'))

	let dataRegistries = {
		//wspólne
		SPHT_HIST: {
			RW: 1,
			min: 0.4,
			max: 2.5,
			controller: 2,
			symbol: " °C",
			step: 0.4,
			parsed: 'float'
		},
		SPHT_ZAD: {
			RW: 1,
			min: 10,
			max: 30,
			controller: 2,
			symbol: " °C",
			parsed: 'int'
		},
		SCHT: {
			RW: 0,
			symbol: " °C",
			parsed: 'float'
		},
		SX6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		CUN_HCU: {
			RW: 1,
			min: 0,
			max: 9,
			controller: 2,
			symbol: "",
			parsed: 'int',
		},
		CUN_D_HCU: {
			RW: 1,
			min: 0,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SX2_P2: {
			RW: 1,
			min: "SX2_P1",
			max: 25,
			controller: 2,
			symbol: "",
			parsed: 'int',
		},
		SX2_P1: {
			RW: 1,
			min: 10,
			max: "SX2_P2",
			controller: 2,
			symbol: "",
			parsed: 'int',
		},
		SX3_HG: {
			RW: 1,
			min: 2,
			max: 16,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SX3_HC: {
			RW: 1,
			min: 2,
			max: 16,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPCU: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		SCCU: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		COC: {
			RW: 1,
			min: 1,
			max: 50,
			controller: 2,
			symbol: "",
			parsed: 'int',
		},
		SX1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TB1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TB2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		SX4_PH: {
			RW: 1,
			min: 5,
			max: "SX4_PC",
			change: "max",
			changeMax: -1,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SX4_PC: {
			RW: 1,
			min: "SX4_PH",
			max: 30,
			change: "min",
			changeMin: 1,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPDHW_HIST: {
			RW: 1,
			min: 1,
			max: 12,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPDHW_ZAD: {
			RW: 1,
			min: 35,
			max: 55,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		TSM9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM11: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM12: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM13: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM14: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM15: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM16: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TSM17: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		T3fiz: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		T4fiz: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		T5fiz: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		T6fiz: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		SCDHW: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		SX5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TOUT_KOR: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		TOUT_NUM: {
			RW: 1,
			min: 1,
			max: 24,
			controller: 2,
			symbol: "",
			parsed: 'int',
		},
		SPRMIN_ZAD: {
			RW: 1,
			min: 8,
			max: 15,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPRMIN_HIST: {
			RW: 1,
			min: 2,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPRMAX_ZAD: {
			RW: 1,
			min: 25,
			max: 75,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPRMAX_HIST: {
			RW: 1,
			min: 2,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SPSMIN_ZAD: {
			RW: 1,
			min: -6,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			step: 1
		},
		SPSMIN_HIST: {
			RW: 1,
			min: 2,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		REW_ERR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_LPH: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_HP: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_FLO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_DIS: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_RMD: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_WRN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_SMI: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_RMI: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_RMA: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_SMA: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		REW_SMD: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO_R06: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO_R08: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO_R09: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO_R10: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO_R02: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO_R05: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO_R11: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO_R01: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO_R03: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SXF_AWA: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_TIME: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_ECO: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_OOF: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_DHX: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_DHS: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_HTX: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_HTS: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_ACX: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SBF_ACS: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_TOE: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		ANL_ALL: {
			RW: 0,
			controller: 4,
			parsed: 'int',
			controllerColor0: 'red',
			controllerColor1: 'green',
		},
		ANL_1PH: {
			RW: 0,
			controller: 4,
			parsed: 'int',
			controllerColor0: 'red',
			controllerColor1: 'green',
		},
		ANL_2PH: {
			RW: 0,
			controller: 4,
			parsed: 'int',
			controllerColor0: 'red',
			controllerColor1: 'green',
		},
		ANL_3PH: {
			RW: 0,
			controller: 4,
			parsed: 'int',
			controllerColor0: 'red',
			controllerColor1: 'green',
		},
		SBF_PHT: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_ANL: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		ANL_DAY: {
			RW: 1,
			min: 0,
			max: 99,
			controller: 2,
			symbol: "",
			parsed: 'int',
			step: 1
		},
		ANL_CLOCK: {
			RW: 0,
			parsed: 'int',
		},
		ANL_SETP_ZAD: {
			RW: 1,
			min: 50,
			max: 70,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		ANL_SETP_HIST: {
			RW: 1,
			min: 2,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
		},
		SXF_ENACYR: {
			RW: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		AWAY_CLOCK: {
			RW: 1,
			min: 0,
			max: 99,
			step: 1,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		AWAY_COR: {
			RW: 0,
			symbol: " °C",
			parsed: 'int',
		},
		SXB_PV: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SBB_QHOUT: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SBB_QCOUT: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		ECOT1R: {
			RW: 1,
			symbol: "",
			min: 0,
			max: "ECOT1S",
			parsed: 'int',
		},
		ECOT1S: {
			RW: 1,
			symbol: "",
			min: 0,
			max: 23,
			parsed: 'int',
		},
		ECOT2R_Z: {
			RW: 1,
			symbol: "",
			min: 0,
			max: "ECOT2S_Z",
			parsed: 'int',
		},
		ECOT2S_Z: {
			RW: 1,
			symbol: "",
			min: "ECOT2R_Z",
			max: 23,
			parsed: 'int',
		},
		ECOT2R_L: {
			RW: 1,
			symbol: "",
			min: 0,
			max: "ECOT2S_L",
			parsed: 'int',
		},
		ECOT2S_L: {
			RW: 1,
			symbol: "",
			min: "ECOT2R_L",
			max: 23,
			parsed: 'int',
		},
		ECOT3R: {
			RW: 1,
			symbol: "",
			min: 0,
			max: 24,
			parsed: 'int',
		},
		ECOT3S: {
			RW: 1,
			symbol: "",
			min: "ECOT3R",
			max: 23,
			parsed: 'int',
		},
		ECOX1: {
			RW: 1,
			min: 0,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			sign: "+ "
		},
		ECOX2: {
			RW: 1,
			min: 0,
			max: 10,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			sign: "+ "
		},
		ECODHW: {
			RW: 1,
			min: 0,
			max: 3,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			sign: "- "
		},
		ECOY1: {
			RW: 1,
			min: 0,
			max: 6,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			sign: "+ "
		},
		ECOY2: {
			RW: 1,
			min: 0,
			max: 6,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			sign: "+ "
		},
		ECOTIN: {
			RW: 1,
			min: 0,
			max: 3,
			controller: 2,
			symbol: " °C",
			parsed: 'int',
			sign: "- "
		},
		NUMER_STER: {
			RW: 1,
			min: 1,
			max: 247,
			symbol: "",
			parsed: 'int',
		},
		VER_S1: {
			RW: 0,
			min: 0,
			max: 32767,
			symbol: "",
			parsed: 'int',
		},
		VER_S2: {
			RW: 0,
			min: 0,
			max: 32767,
			symbol: "",
			parsed: 'int',
		},
		VR_FLO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4
		},
		VR_FLI: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4
		},
		VR_ZCH: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
		},
		VR_ZKO: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
		},
		VR_ANO: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
		},
		VR_ZCW: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
		},
		VR_FCO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4
		},
		VR_FCI: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4
		},
		VR_PO: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
		},
		VR_PZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4
		},
		VR_REV: {
			RW: 0,
			symbol: "",
			controller: 4,
			parsed: 'int',
		},
		IDM_MAN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		IDM_GRO: {
			RW: 0,
			min: 0,
			max: 25,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		IDP_PWR: {
			RW: 0,
			min: 0,
			max: 255,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		IDP_SUB: {
			RW: 0,
			min: 0,
			max: 7,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		IDP_MOD: {
			RW: 0,
			min: 0,
			max: 3,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		IDP_EXP: {
			RW: 0,
			min: 0,
			max: 7,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PSN: {
			RW: 0,
			min: 0,
			max: 32768,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PDP_WEE: {
			RW: 0,
			mni: 0,
			max: 53,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PDP_YEA: {
			RW: 0,
			min: 0,
			max: 99,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		SIO_B05: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBF_AHX: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			controller: 1,
			parsed: 'int',
		},
		SBF_AHS: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			controller: 1,
			parsed: 'int',
		},
		TB5: {
			RW: 1,
			min: 1,
			max: 120,
			step: 1,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		CWP1P_PG: {
			RW: 1,
			min: 0,
			max: "CWP1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWP1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWP1P_KG: {
			RW: 1,
			min: "CWP1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWP1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWP1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWP2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWP2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWP2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWP2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWP2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWP3P_PG: {
			RW: 1,
			min: 0,
			max: "CWP3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWP3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWP3P_KG: {
			RW: 1,
			min: "CWP3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWP3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWP3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWW1P_PG: {
			RW: 1,
			min: 0,
			max: "CWW1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWW1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWW1P_KG: {
			RW: 1,
			min: "CWW1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWW1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWW1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWW2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWW2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWW2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWW2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWW2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWW3P_PG: {
			RW: 1,
			min: 0,
			max: "CWW3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWW3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWW3P_KG: {
			RW: 1,
			min: "CWW3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWW3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWW3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWS1P_PG: {
			RW: 1,
			min: 0,
			max: "CWS1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWS1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWS1P_KG: {
			RW: 1,
			min: "CWS1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWS1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWS1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWS2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWS2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWS2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWS2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWS2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWS3P_PG: {
			RW: 1,
			min: 0,
			max: "CWS3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWS3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWS3P_KG: {
			RW: 1,
			min: "CWS3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWS3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWS3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWC1P_PG: {
			RW: 1,
			min: 0,
			max: "CWC1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWC1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWC1P_KG: {
			RW: 1,
			min: "CWC1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWC1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWC1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWC2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWC2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWC2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWC2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWC2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWC3P_PG: {
			RW: 1,
			min: 0,
			max: "CWC3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWC3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWC3P_KG: {
			RW: 1,
			min: "CWC3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWC3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWC3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWPT1P_PG: {
			RW: 1,
			min: 0,
			max: "CWPT1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWPT1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWPT1P_KG: {
			RW: 1,
			min: "CWPT1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWPT1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWPT1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWPT2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWPT2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWPT2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWPT2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWPT2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWPT3P_PG: {
			RW: 1,
			min: 0,
			max: "CWPT3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWPT3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWPT3P_KG: {
			RW: 1,
			min: "CWPT3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWPT3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWPT3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWSO1P_PG: {
			RW: 1,
			min: 0,
			max: "CWSO1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWSO1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWSO1P_KG: {
			RW: 1,
			min: "CWSO1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWSO1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWSO1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWSO2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWSO2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWSO2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWSO2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWSO2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWSO3P_PG: {
			RW: 1,
			min: 0,
			max: "CWSO3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWSO3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWSO3P_KG: {
			RW: 1,
			min: "CWSO3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWSO3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWSO3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWN1P_PG: {
			RW: 1,
			min: 0,
			max: "CWN1P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWN1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWN1P_KG: {
			RW: 1,
			min: "CWN1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWN1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWN1T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWN2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWN2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWN2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWN2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWN2T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWN3P_PG: {
			RW: 1,
			min: 0,
			max: "CWN3P_KG",
			symbol: "",
			parsed: 'int',
		},
		CWN3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWN3P_KG: {
			RW: 1,
			min: "CWN3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		CWN3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CWN3T: {
			RW: 1,
			min: 35,
			max: 55,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		CWTSW_ND: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_SO: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_PT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_CZ: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_SR: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_WT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_PN: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_S3: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_S2: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CWTSW_S1: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWP1P_PG: {
			RW: 1,
			min: 0,
			max: "TWP1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWP1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWP1P_KG: {
			RW: 1,
			min: "TWP1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWP1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWP1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWP2P_PG: {
			RW: 1,
			min: 1,
			max: 'TWP2P_KG',
			symbol: "",
			parsed: 'int',
		},
		TWP2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWP2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWP2P_KM: {
			RW: 1,
			min: 0,
			max: 0,
			symbol: "",
			parsed: 'int',
		},
		TWP2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWP3P_PG: {
			RW: 1,
			min: 0,
			max: "TWP3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWP3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWP3P_KG: {
			RW: 1,
			min: "TWP3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWP3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWP3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWW1P_PG: {
			RW: 1,
			min: 0,
			max: "TWW1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWW1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWW1P_KG: {
			RW: 1,
			min: "TWW1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWW1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWW1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWW2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWW2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWW2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWW2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWW2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWW3P_PG: {
			RW: 1,
			min: 0,
			max: "TWW3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWW3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWW3P_KG: {
			RW: 1,
			min: "TWW3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWW3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWW3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWS1P_PG: {
			RW: 1,
			min: 0,
			max: "TWS1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWS1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWS1P_KG: {
			RW: 1,
			min: "TWS1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWS1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWS1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWS2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWS2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWS2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWS2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWS2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWS3P_PG: {
			RW: 1,
			min: 0,
			max: "TWS3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWS3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWS3P_KG: {
			RW: 1,
			min: "TWS3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWS3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWS3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWC1P_PG: {
			RW: 1,
			min: 0,
			max: "TWC1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWC1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWC1P_KG: {
			RW: 1,
			min: "TWC1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWC1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWC1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWC2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWC2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWC2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWC2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWC2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWC3P_PG: {
			RW: 1,
			min: 0,
			max: "TWC3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWC3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWC3P_KG: {
			RW: 1,
			min: "TWC3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWC3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWC3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWPT1P_PG: {
			RW: 1,
			min: 0,
			max: "TWPT1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWPT1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWPT1P_KG: {
			RW: 1,
			min: "TWPT1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWPT1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWPT1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWPT2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWPT2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWPT2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWPT2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWPT2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWPT3P_PG: {
			RW: 1,
			min: 0,
			max: "TWPT3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWPT3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWPT3P_KG: {
			RW: 1,
			min: "TWPT3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWPT3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWPT3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWSO1P_PG: {
			RW: 1,
			min: 0,
			max: "TWSO1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWSO1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWSO1P_KG: {
			RW: 1,
			min: "TWSO1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWSO1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWSO1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWSO2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWSO2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWSO2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWSO2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWSO2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWSO3P_PG: {
			RW: 1,
			min: 0,
			max: "TWSO3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWSO3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWSO3P_KG: {
			RW: 1,
			min: "TWSO3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWSO3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWSO3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWN1P_PG: {
			RW: 1,
			min: 0,
			max: "TWN1P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWN1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWN1P_KG: {
			RW: 1,
			min: "TWN1P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWN1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWN1T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWN2P_PG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWN2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWN2P_KG: {
			RW: 1,
			min: 1,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWN2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWN2T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWN3P_PG: {
			RW: 1,
			min: 0,
			max: "TWN3P_KG",
			symbol: "",
			parsed: 'int',
		},
		TWN3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWN3P_KG: {
			RW: 1,
			min: "TWN3P_PG",
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		TWN3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		TWN3T: {
			RW: 1,
			min: 10,
			max: 30,
			symbol: " °C",
			parsed: 'float',
			step: 1
		},
		TWTSW_ND: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_SO: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_PT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_CZ: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_SR: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_WT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_PN: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_S3: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_S2: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		TWTSW_S1: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		OOF_NUM: {
			RW: 1,
			min: 2,
			max: 12,
			symbol: "",
			parsed: 'int',
			controller: 2,
		},
		OOF_HT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		OOF_DHW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		OOF_CL: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		OOF_PWR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_3: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_4: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_5: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_6: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_7: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_8: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_9: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNA_10: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_3: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_4: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_5: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_6: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_7: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_8: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_9: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNB_10: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_3: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_4: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_5: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_6: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_7: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_8: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_9: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNC_10: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_3: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_4: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_5: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_6: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_7: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_8: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_9: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWND_10: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNE_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNE_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNF_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNG_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNH_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNI_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNJ_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNK_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_1: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_2: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_3: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_4: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_5: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_6: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_7: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_8: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_9: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNL_10: {
			RW: 0,
			symbol: " °C",
			parsed: 'float',
		},
		UWNM_1_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_1_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_1_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_2_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_2_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_2_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_3_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_3_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_3_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_4_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_4_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_4_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_5_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_5_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_5_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_6_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_6_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_6_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_7_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_7_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_7_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_8_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_8_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_8_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_9_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_9_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_9_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_10_GODZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_10_MIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNM_10_SEK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_1_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_1_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_1_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_1_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_2_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_2_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_2_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_2_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_3_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_3_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_3_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_3_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_4_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_4_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_4_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_4_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_5_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_5_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_5_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_5_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_6_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_6_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_6_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_6_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_7_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_7_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_7_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_7_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_8_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_8_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_8_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_8_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_9_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_9_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_9_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_9_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_10_AM_PM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_10_ROK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_10_MSC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		UWNN_10_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		//nie widze tego rejestru w spisie
		ANL_SETP_X: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_ND: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_SO: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_PT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_CZ: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_SR: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_WT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_PN: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_S3: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_S2: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRSW_S1: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		CYRP1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRP1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRP1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRP1P_KG: {
			RW: 1,
			min: "CYRP1P_PG",
			max: "CYRP2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRP1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRP2P_PG: {
			RW: 1,
			min: "CYRP1P_KG",
			max: "CYRP2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRP2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRP2P_KG: {
			RW: 1,
			min: "CYRP2P_PG",
			max: "CYRP3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRP2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRP3P_PG: {
			RW: 1,
			min: "CYRP2P_KG",
			max: "CYRP3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRP3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRP3P_KG: {
			RW: 1,
			min: "CYRP3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRP3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRW1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRW1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRW1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRW1P_KG: {
			RW: 1,
			min: "CYRW1P_PG",
			max: "CYRW2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRW1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRW2P_PG: {
			RW: 1,
			min: "CYRW1P_KG",
			max: "CYRW2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRW2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRW2P_KG: {
			RW: 1,
			min: "CYRW2P_PG",
			max: "CYRW3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRW2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRW3P_PG: {
			RW: 1,
			min: "CYRW2P_KG",
			max: "CYRW3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRW3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRW3P_KG: {
			RW: 1,
			min: "CYRW3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRW3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRS1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRS1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRS1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRS1P_KG: {
			RW: 1,
			min: "CYRS1P_PG",
			max: "CYRS2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRS1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRS2P_PG: {
			RW: 1,
			min: "CYRS1P_KG",
			max: "CYRS2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRS2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRS2P_KG: {
			RW: 1,
			min: "CYRS2P_PG",
			max: "CYRS3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRS2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRS3P_PG: {
			RW: 1,
			min: "CYRS2P_KG",
			max: "CYRS3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRS3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRS3P_KG: {
			RW: 1,
			min: "CYRS3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRS3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRC1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRC1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRC1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRC1P_KG: {
			RW: 1,
			min: "CYRC1P_PG",
			max: "CYRC2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRC1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRC2P_PG: {
			RW: 1,
			min: "CYRC1P_KG",
			max: "CYRC2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRC2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRC2P_KG: {
			RW: 1,
			min: "CYRC2P_PG",
			max: "CYRC3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRC2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRC3P_PG: {
			RW: 1,
			min: "CYRC2P_KG",
			max: "CYRC3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRC3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRC3P_KG: {
			RW: 1,
			min: "CYRC3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRC3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRPT1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRPT1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRPT1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRPT1P_KG: {
			RW: 1,
			min: "CYRPT1P_PG",
			max: "CYRPT2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRPT1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRPT2P_PG: {
			RW: 1,
			min: "CYRPT1P_KG",
			max: "CYRPT2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRPT2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRPT2P_KG: {
			RW: 1,
			min: "CYRPT2P_PG",
			max: "CYRPT3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRPT2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRPT3P_PG: {
			RW: 1,
			min: "CYRPT2P_KG",
			max: "CYRPT3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRPT3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRPT3P_KG: {
			RW: 1,
			min: "CYRPT3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRPT3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRSO1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRSO1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRSO1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRSO1P_KG: {
			RW: 1,
			min: "CYRSO1P_PG",
			max: "CYRSO2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRSO1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRSO2P_PG: {
			RW: 1,
			min: "CYRSO1P_KG",
			max: "CYRSO2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRSO2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRSO2P_KG: {
			RW: 1,
			min: "CYRSO2P_PG",
			max: "CYRSO3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRSO2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRSO3P_PG: {
			RW: 1,
			min: "CYRSO2P_KG",
			max: "CYRSO3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRSO3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRSO3P_KG: {
			RW: 1,
			min: "CYRSO3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRSO3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRN1P_PG: {
			RW: 1,
			min: 0,
			max: "CYRN1P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRN1P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRN1P_KG: {
			RW: 1,
			min: "CYRN1P_PG",
			max: "CYRN2P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRN1P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRN2P_PG: {
			RW: 1,
			min: "CYRN1P_KG",
			max: "CYRN2P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRN2P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRN2P_KG: {
			RW: 1,
			min: "CYRN2P_PG",
			max: "CYRN3P_PG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRN2P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRN3P_PG: {
			RW: 1,
			min: "CYRN2P_KG",
			max: "CYRN3P_KG",
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRN3P_PM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYRN3P_KG: {
			RW: 1,
			min: "CYRN3P_PG",
			max: 24,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 1
		},
		CYRN3P_KM: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		ST_SEKUNDY: {
			RW: 1,
			min: 0,
			max: 59,
			symbol: "",
			parsed: 'int',
		},
		ST_MINUTY: {
			RW: 1,
			min: 0,
			max: 59,
			symbol: "",
			parsed: 'int',
		},
		ST_GODZINY: {
			RW: 1,
			min: 0,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		ST_DZIEN: {
			RW: 1,
			min: 1,
			max: 31,
			symbol: "",
			parsed: 'int',
		},
		ST_MIESIAC: {
			RW: 1,
			min: 0,
			max: 11,
			symbol: "",
			parsed: 'int',
		},
		ST_ROK: {
			RW: 1,
			min: 0,
			max: 99,
			symbol: "",
			parsed: 'int',
		},
		ST_DZEN_TYG: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		SEKUNDY: {
			RW: 1,
			min: 0,
			max: 59,
			symbol: "",
			parsed: 'int',
		},
		MINUTY: {
			RW: 1,
			min: 0,
			max: 59,
			symbol: "",
			parsed: 'int',
		},
		GODZINY: {
			RW: 1,
			min: 0,
			max: 23,
			symbol: "",
			parsed: 'int',
		},
		DZIEN: {
			RW: 1,
			min: 1,
			max: 31,
			symbol: "",
			parsed: 'int',
		},
		MIESIAC: {
			RW: 1,
			min: 0,
			max: 11,
			symbol: "",
			parsed: 'int',
		},
		ROK: {
			RW: 1,
			min: 0,
			max: 99,
			symbol: "",
			parsed: 'int',
		},
		DZIEN_TYG: {
			RW: 1,
			min: 0,
			max: 6,
			symbol: "",
			parsed: 'int',
		},
		CYR_FILL: {
			RW: 1,
			min: 5,
			max: 60,
			symbol: "",
			parsed: 'int',
		},
		PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS1_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS1_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS1_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS1_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS1_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS1_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS2_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS2_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS2_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS2_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS2_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS2_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS3_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS3_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS3_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS3_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS3_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS3_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS4_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS4_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS4_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS4_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS4_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS4_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS5_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS5_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS5_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS5_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS5_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS5_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS6_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS6_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS6_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS6_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS6_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS6_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS7_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS7_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS7_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS7_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS7_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS7_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS8_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS8_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS8_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS8_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS8_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS8_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS9_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS9_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS9_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS9_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS9_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS9_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS10_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS10_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS10_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS10_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS10_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS10_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS11_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS11_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS11_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS11_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS11_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS11_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS12_PDHWT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS12_PDHWT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS12_PHTT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS12_PHTT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS12_PACT1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SS12_PACT2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		//wedle lsity wszystkie rejestry BLK nie są nigdzie wykorzystywane
		BLK_LW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		BLK_LK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		BLK_KD1: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		BLK_KD2: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		PANVER: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		RGRCH: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		TB5W: {
			RW: 1,
			min: 1,
			max: 120,
			step: 1,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		TB6: {
			RW: 1,
			min: -40,
			max: 20,
			symbol: " °C",
			controller: 2,
			parsed: 'int',
			step: 1
		},
		SWEXT_ZBC: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			controller: 1,
			parsed: 'int',
		},
		SXB_PWRO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'red',
		},
		SXB_CLO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'red',
		},
		SXB_DHWO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'red',
		},
		SXB_HTO: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'red',
		},
		SXB_WHT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SXB_WCL: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SXB_WDHW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SXB_QFC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SPSMIN_ZNAK: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		// brak rejestru
		SPSMAX_X: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		SPRMIN_ZNAK: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		// brak rejestru
		SPRMAX_X: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		// break rejestru
		SPRDIS_X: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		// brak rejestru
		SPDHW_X: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		// brak rejestru
		SPHT_X: {
			RW: 1,
			symbol: "",
			parsed: 'int',
		},
		TCH: {
			RW: 1,
			min: 60,
			max: 900,
			symbol: "",
			parsed: 'int',
			controller: 2,
			step: 10
		},
		TCR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		TRS: {
			RW: 1,
			min: 3,
			max: 60,
			symbol: "",
			parsed: 'int',
			controller: 2,
		},
		TRR: {
			RW: 1,
			min: 3,
			max: 60,
			symbol: "",
			parsed: 'int',
			controller: 2,
		},
		TB4: {
			RW: 0,
			min: 0,
			max: 5,
			symbol: "",
			parsed: 'int',
		},
		SXF_TIM_WIN_SW: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_TIM_WIN_FL: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_ACT: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_FLPCYR: {
			RW: 1,
			min: 0,
			max: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_AZP: {
			RW: 1,
			min: 0,
			max: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_TIMCYR: {
			RW: 1,
			min: 0,
			max: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_FORCYR: {
			RW: 1,
			min: 0,
			max: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_WEK: {
			RW: 1,
			min: 0,
			max: 1,
			controller: 1,
			symbol: "",
			parsed: 'int',
		},
		SXF_DHM: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		SBB_QHW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QDHW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QTIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QSMIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QSMAX: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QRMIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QRMAX: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QSMD: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QRMD: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QDIS: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QCS: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'red',
		},
		SBB_QHT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_QHL: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SBB_MAIN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SXB_FER: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'red',
		},
		SXB_ECACT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		R06: {
			RW: 0,
			parsed: 'int',
		},
		SWEXT_BUF: {
			RW: 1,
			min: 0,
			max: 1,
			symbol: "",
			parsed: 'int',
		},
		ASK_STEP2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		TSR: {
			RW: 1,
			min: 3,
			max: 60,
			symbol: "",
			parsed: 'int',
			controller: 2,
		},
//-------- rejestry dla extenderów
		AOR_FB: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'green',
			controllerColor1: 'red',
		},
		T_CWU_2: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_BUF_2: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_Z2_1: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_Z2_2: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_Z2_3: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_PVT: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_OB_CHL: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		T_ODW_IN: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		SIO2_R01_G1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO2_R02_G2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO2_R09_PV_READY: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO2_R08_DOL_ZR_BUF_Z2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R07_DOL_ZR_ODW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R07_DZ: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R03_REGEN_OTW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R04_REGEN_ZAMK: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R05_MIESZ_CHL_OTW: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R06_MIESZ_CHL_ZAM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R10_PVT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_R11_P_REGEN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		SIO2_B01: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_B02: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_B03: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_B04: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		SIO2_B05: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		PV_READY_AC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		PV_READY_THR1: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		PV_READY_THR2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		PV_READY_THR3: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		LICZNIK_PV_KOM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'green',
			controllerColor1: 'red',
		},
		LICZNIK_PC_KOM: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'green',
			controllerColor1: 'red',
		},
		LICZNIK_PV_PWR_ODB: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		LICZNIK_PV_PWR_NADPROD: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		T01_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T02_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T03_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T04_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T05_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T06_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T07_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T08_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T09_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T01_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T02_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T03_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T04_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T05_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T06_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T07_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T08_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T09_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T01_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T02_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T03_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T04_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T05_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T06_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T07_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T08_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		T09_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		PWM1_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		PWM2_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		PWM1_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		PWM2_IN: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		R01_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R02_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R03_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R04_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R05_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R06_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R07_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R08_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R09_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R10_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R11_AOR: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R01_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R02_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R03_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R04_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R05_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R06_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R07_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R08_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R09_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R10_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R11_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R01_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R02_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R03_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R04_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R05_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R06_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R07_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R08_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R09_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R10_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R11_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B01_AOR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		B02_AOR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		B03_AOR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		B04_AOR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		B05_AOR: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		B01_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B02_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B03_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B04_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B05_IN: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B01_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B02_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B03_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B04_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		B05_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'int',
			controller: 4,
			controllerColor0: 'grey',
			controllerColor1: 'green',
		},
		R01_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R02_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R03_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R04_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R05_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R06_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R07_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R08_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R09_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R10_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R11_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R01_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R02_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R03_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R04_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R05_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R06_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R07_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R08_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R09_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R10_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		R11_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B01_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B02_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B03_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B04_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B05_AOR_EN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B01_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B02_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B03_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B04_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		B05_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'int',
			controller: 1,
		},
		PW_G: {
			RW: 1,
			min: 0,
			max: 30000,
			step: 100,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PZ_G: {
			RW: 1,
			min: 0,
			max: 30000,
			step: 100,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PW_P: {
			RW: 1,
			min: 0,
			max: 30000,
			step: 100,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PZ_P: {
			RW: 1,
			min: 0,
			max: 30000,
			step: 100,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		PZ_P_G: {
			RW: 1,
			min: 0,
			max: 30000,
			step: 100,
			symbol: "",
			controller: 2,
			parsed: 'int',
		},
		SP_CO_G: {
			RW: 1,
			min: 15,
			max: 80,
			step: 1,
			symbol:  " °C",
			controller: 2,
			parsed: 'float',
		},
		SP_CWU_G: {
			RW: 1,
			min: 35,
			max: 90,
			step: 1,
			symbol:  " °C",
			controller: 2,
			parsed: 'float',
		},
		SP_T_REGEN: {
			RW: 1,
			min: 0,
			max: 60,
			step: 1,
			symbol:  " °C",
			controller: 2,
			parsed: 'float',
		},
		SP_CWU_REGENER: {
			RW: 1,
			min: 35,
			max: 90,
			step: 1,
			symbol:  " °C",
			controller: 2,
			parsed: 'float',
		},
		SP_CH_Z2: {
			RW: 1,
			min: -15,
			max: 30,
			step: 1,
			symbol:  " °C",
			controller: 2,
			parsed: 'float',
		},
		LICZNIK_PV_PWR_FLOAT1: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_PWR_FLOAT2: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_ENE_IMP_FLOAT1: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_ENE_IMP_FLOAT2: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_ENE_EXP_FLOAT1: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_ENE_EXP_FLOAT2: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PC_PWR_FLOAT1: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PC_PWR_FLOAT2: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PC_ENE_IMP_FLOAT1: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PC_ENE_IMP_FLOAT2: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PC_ENE_EXP_FLOAT1: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PC_ENE_EXP_FLOAT2: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		T01_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T02_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T03_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T04_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T05_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T06_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T07_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T08_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		T09_MAN: {
			RW: 1,
			min: -50,
			max: 150,
			step: 0.1,
			symbol: "",
			controller: 2,
			parsed: 'float',
		},
		PWM1_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'float',
			controller: 2,
		},
		PWM2_MAN: {
			RW: 1,
			symbol: "",
			parsed: 'float',
			controller: 2,
		},
		PWM1_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		PWM2_OUT: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_PWR_NADPROD_AR: {
			RW: 1,
			min: 0,
			max: 30000,
			step: 100,
			symbol: "",
			parsed: 'float',
		},
		LICZNIK_PV_PWR_ODB_AR: {
			RW: 0,
			symbol: "",
			parsed: 'float',
		},
		VER_DZIEN_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		VER_MIESIAC_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		VER_ROK_2: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
		T_ODW_OUT: {
			RW: 0,
			symbol:  " °C",
			parsed: 'float',
		},
		WYLI_PROG_ZAL_PC: {
			RW: 0,
			symbol: "",
			parsed: 'int',
		},
	}

	switch (true) {
		case deviceName === 'GEO_1':
			dataRegistries = {...dataRegistries,
				REW_SUP: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				REW_TER: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				REW_LPC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				REW_FCS: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SIO_B04: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SIO_R04: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SIO_R07: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SBF_FCX: {
					RW: 1,
					min: 0,
					max: 1,
					symbol: "",
					parsed: 'int',
					controller: 1,
				},
				SBF_FCS: {
					RW: 1,
					min: 0,
					max: 1,
					symbol: "",
					parsed: 'int',
					controller: 1,
				},
				PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS1_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS2_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS3_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS4_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS5_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS6_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS7_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS8_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS9_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS10_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS11_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SS12_PFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SXB_ACBL: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SXB_QSFC: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SPSMAX_HIST: {
					RW: 1,
					min: 2,
					max: 10,
					symbol: " °C",
					parsed: 'int',
					controller: 2,
				},
				SPSMAX_ZAD: {
					RW: 1,
					min: 15,
					max: 45,
					symbol: " °C",
					parsed: 'float',
					controller: 2,
				},
				TB3: {
					RW: 1,
					// min: 30,
					// max: 180,
					min: 0,
					max: 200,
					symbol: "",
					parsed: 'int',
					controller: 2,
				},
				SXF_4EN: {
					RW: 1, 
					min: 0,
					max: 1,
					symbol: "",
					parsed: 'int',
					controller: 1,
				},
				SIO_B01: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SIO_B02: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SIO_B03: {
					RW: 0,
					symbol: "",
					parsed: 'int',
				},
				SXB_4DR: {
					RW: 0,
					symbol: "",
					parsed: 'int',
					controller: 4,
					controllerColor0: 'grey',
					controllerColor1: 'red',
				},
				SXB_F4DP: {
					RW: 0,
					symbol: "",
					parsed: 'int',
					controller: 4,
					controllerColor0: 'grey',
					controllerColor1: 'green',
				},
				SXB_F4D: {
					RW: 0,
					symbol: "",
					parsed: 'int',
					controller: 4,
					controllerColor0: 'grey',
					controllerColor1: 'red',
				},
				TSS: {
					RW: 1,
					min: 3,
					max: 60,
					symbol: "",
					parsed: 'int',
					controller: 2,
				},
				SPRDIS_HIST: {
					RW: 1,
					min: 4,
					max: 15,
					symbol: " °C",
					parsed: 'int',
					controller: 2,
				},
				SPRDIS_ZAD: {
					RW: 1,
					min: 100,
					max: 135,
					symbol: " °C",
					parsed: 'float',
					controller: 2,
				},
			}
				switch (true) {
					case (version_1 > 870) || (version_1 === 870 && version_2 >= 9):
						dataRegistries = {...dataRegistries,
							TIME_FST: {
								RW: 0,
								symbol: "",
								parsed: 'int',
							},
							TIME_WEEK: {
								RW: 0,
								symbol: "",
								parsed: 'int',
							},
							OB1_TKMF: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
								controller: 2
							},
							OB1_TKMF_BAZ: {
								RW: 1,
								min: 15,
								max: 25,
								controller: 2,
								symbol: " °C",
								// powinien być float ale nie mieści się z symbolem
								parsed: 'int',
							},
							OB1_TWEW: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB1_KRZYWA: {
								RW: 1,
								min: 0,
								max: 9,
								symbol: "",
								parsed: 'int',
								controller: 2,
							},
							OB1_TCO: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB1_ZAD_TCO: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB2_TKMF: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
								controller: 2
							},
							OB2_TKMF_BAZ: {
								RW: 1,
								min: 15,
								max: 25,
								controller: 2,
								symbol: " °C",
								// powinien być float ale nie mieści się z symbolem
								parsed: 'int',
							},
							OB2_TWEW: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB2_KRZYWA: {
								RW: 1,
								min: 0,
								max: 9,
								symbol: "",
								parsed: 'int',
								controller: 2,
							},
							OB2_TCO: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB2_ZAD_TCO: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB3_TKMF: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
								controller: 2
							},
							OB3_TKMF_BAZ: {
								RW: 1,
								min: 15,
								max: 25,
								controller: 2,
								symbol: " °C",
								// powinien być float ale nie mieści się z symbolem
								parsed: 'int',
							},
							OB3_TWEW: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB3_KRZYWA: {
								RW: 1,
								min: 0,
								max: 9,
								symbol: "",
								parsed: 'int',
								controller: 2,
							},
							OB3_TCO: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							OB3_ZAD_TCO: {
								RW: 0,
								symbol: " °C",
								parsed: 'float',
							},
							MAG_C_CH: {
								RW: 0,
								symbol: "",
								parsed: 'int',
							},
						}
						break
					default:
						break
				}					
		break
	case deviceName === 'ATMO_1' || deviceName === 'ALLINONE_1': 
		dataRegistries = {...dataRegistries,
			MB_COMM2_XZM_TRANSM: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			MAX_PERF: {
				RW: 1,
				min: 5,
				max: 10,
				step: 1,
				controller: 2,
				parsed: 'int',
			},
			ZM_PRES_EVAP: {
				RW: 0,
				symbol: "",
				parsed: 'float',
			},
			ZM_PRES_COND: {
				RW: 0,
				symbol: "",
				parsed: 'float',
			},
			SWEXT_ZBCRO: {
				RW: 1,
				symbol: "",
				controller: 1,
				parsed: 'int',
			},
			SWEXT_B05: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			A1: {
				RW: 1,
				min: 1,
				max: 120,
				step: 1,
				symbol: "",
				controller: 2,
				parsed: 'int',
			},
			A2: {
				RW: 1,
				min: -40,
				max: 20,
				symbol: " °C",
				controller: 2,
				parsed: 'int',
				step: 1
			},
			CORSTA: {
				symbol: "",
				parsed: 'int',
			},
			CORSTA_SPSMIN: {
				RW: 1,
				min: 0,
				max: 10,
				symbol: "",
				parsed: 'int',
			},
			CORSTA_SPSMAX: {
				RW: 1,
				min: 0,
				max: 10,
				symbol: "",
				parsed: 'int',
			},
			CORSTA_SPRMIN: {
				RW: 1,
				min: 0,
				max: 10,
				symbol: "",
				parsed: 'int',
			},
			CORSTA_SPRMAX: {
				RW: 1,
				min: 0,
				max: 10,
				symbol: "",
				parsed: 'int',
			},
			SWEXT_ZBST1: {
				RW: 1,
				symbol: "",
				controller: 1,
				parsed: 'int',
			},
			HPOM: {
				RW: 1,
				min: 5,
				max: 100,
				controller: 2,
				symbol: "",
				parsed: 'int',
			},
			SWEXT_ZBST2: {
				RW: 1,
				symbol: "",
				controller: 1,
				parsed: 'int',
			},
			SWEXT_FIXREG: {
				RW: 1,
				symbol: "",
				controller: 1,
				parsed: 'int',
			},
			SWEXT_AHX2: {
				RW: 1,
				symbol: "",
				controller: 1,
				parsed: 'int',
			},
			SWEXT_AHS2: {
				RW: 1,
				symbol: "",
				controller: 1,
				parsed: 'int',
			},
			DETE: {
				RW: 0,
				symbol: "",
				parsed: 'float',
			},
			FSET_CO: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			FSET_CWU: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			FSET_AC: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZMB_SET_COMP_SPEED: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZMB_SET_EEV_OPEN: {
				RW: 0,
				symbol: "",
				parsed: 'float',
			},
			ZMB_SET_INW_TEMP: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			ZM_CUR_OP_MOD: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_AC_IN_VOL: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_AC_IN_CUR: {
				RW: 0,
				symbol: "",
				parsed: 'float',
			},
			ZM_CUR_MAX_FREQ: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_CUR_COMP_FREQ: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_FAN_MAX_SPE: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_FAN1_SPE: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_FAN2_SPE: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_EEV_OPEN: {
				RW: 0,
				symbol: "",
				parsed: 'float',
			},
			ZM_OUT_TEMP: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			ZM_COIL_TEMP: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			ZM_DIS_TEMP: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			ZM_SUCT_TEMP: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			ZM_DEFR_SIGN: {
				RW: 0,
				symbol: "",
				controller: 4,
				parsed: 'int',
				controllerColor0: 'grey',
				controllerColor1: 'green',
			},
			ASK_STEP: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ASK_STEP_JZ: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			XZM_TRANSM: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_ERR1: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR1_MCUR: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_PCUR: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_IPM: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_OIL: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_PSW: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR1_PDF: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_PHPR: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR1_OGTS: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_COIL_PROT: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_VOLT: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR1_FREQ_LT: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ZM_ERR2: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_OTEMP_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
			},
			ERR2_CTEMP_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_DTEMP_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_STEMP_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_EPTRA_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR2_CPTRA_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR2_HP_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR2_LP_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
				controller: 4,
				controllerColor0: 'grey',
				controllerColor1: 'red',
			},
			ERR2_DCA_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_DCB_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_EVPRES_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ERR2_COPRES_F: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			ZM_ERR3: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			IHW: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			TIME_FST: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			TIME_WEEK: {
				RW: 0,
				symbol: "",
				parsed: 'int',
			},
			OB1_TKMF: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
				controller: 2
			},
			OB1_TKMF_BAZ: {
				RW: 1,
				min: 15,
				max: 25,
				controller: 2,
				symbol: " °C",
				// powinien być float ale nie mieści się z symbolem
				parsed: 'int',
			},
			OB1_TWEW: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB1_KRZYWA: {
				RW: 1,
				min: 0,
				max: 9,
				symbol: "",
				parsed: 'int',
				controller: 2,
			},
			OB1_TCO: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB1_ZAD_TCO: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB2_TKMF: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
				controller: 2
			},
			OB2_TKMF_BAZ: {
				RW: 1,
				min: 15,
				max: 25,
				controller: 2,
				symbol: " °C",
				// powinien być float ale nie mieści się z symbolem
				parsed: 'int',
			},
			OB2_TWEW: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB2_KRZYWA: {
				RW: 1,
				min: 0,
				max: 9,
				symbol: "",
				parsed: 'int',
				controller: 2,
			},
			OB2_TCO: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB2_ZAD_TCO: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB3_TKMF: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
				controller: 2
			},
			OB3_TKMF_BAZ: {
				RW: 1,
				min: 15,
				max: 25,
				controller: 2,
				symbol: " °C",
				// powinien być float ale nie mieści się z symbolem
				parsed: 'int',
			},
			OB3_TWEW: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB3_KRZYWA: {
				RW: 1,
				min: 0,
				max: 9,
				symbol: "",
				parsed: 'int',
				controller: 2,
			},
			OB3_TCO: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			OB3_ZAD_TCO: {
				RW: 0,
				symbol: " °C",
				parsed: 'float',
			},
			MAX_RET_TEMP: {
				RW: 0,
				symbol: " °C",
				parsed: 'int'
			},
			MAX_RET_TEMP_HIST: {
				RW: 0,
				parsed: 'int',
				symbol: " °C",
			} 
		}
			switch (true) {
				case (version_1 > 4) || (version_1 === 4 && version_2 >= 50):
					dataRegistries = {...dataRegistries,
						XSCHT: {
							RW: 1,
							symbol : '',
							parsed: 'int',
							controller: 1,
						},
					}
				break
			default:
				break
			}
			switch (true) {
				case (version_1 > 4) || (version_1 === 4 && version_2 >= 87):
					dataRegistries = {...dataRegistries,
						TNKOR: {
							RW: 1,
							min: 1,
							max: 'MAX_PERF',
							controller: 2,
							parsed: 'int',
						},
					}
				break
			default:
				break
			}
			switch (true) {
				case (version_1 > 4) || (version_1 === 4 && version_2 >= 92):
					dataRegistries = {...dataRegistries,
						SIO_B01: {
							RW: 0,
							symbol: "",
							parsed: 'int',
						},
						SIO_B02: {
							RW: 0,
							symbol: "",
							parsed: 'int',
						},
						SIO_B03: {
							RW: 0,
							symbol: "",
							parsed: 'int',
						},
						SXB_4DR: {
							RW: 0,
							symbol: "",
							parsed: 'int',
							controller: 4,
							controllerColor0: 'grey',
							controllerColor1: 'red',
						},
						SXB_F4DP: {
							RW: 0,
							symbol: "",
							parsed: 'int',
							controller: 4,
							controllerColor0: 'grey',
							controllerColor1: 'green',
						},
						SXB_F4D: {
							RW: 0,
							symbol: "",
							parsed: 'int',
							controller: 4,
							controllerColor0: 'grey',
							controllerColor1: 'red',
						},
					}
				break
			default:
				break
		}
		break
	default:
		break
	}
	return dataRegistries

}