import { makeStyles } from '@material-ui/core/styles'

const mainStyleClasses = makeStyles((theme) => ({
    hidden: {
      display: 'none',
    },
    floatRight: {
        float: 'right',
    },
    floatLeft: {
        float: 'left',
    },
    buttonMargin: {
        margin: '20px auto !important',
    },
    error: {
        color: 'red',
        fontSize: '12px',
        '@media (max-width:600px)': {
            fontSize: '10px',
          },
    },
    logo: {
        margin: '15px',
        width: '180px',
        '@media (max-width:600px)': {
            margin: '10px',
            width: '120px',
          },
    },
    backgroundRed: {
        background: 'none #FFC0C0',
    },
    backgroundGreen: {
        background: 'none #C0FFC0',
    },
    backgroundYellow: {
        background: 'none #FFFFC0',
    },
    color25: {
        background: '#ff1e04',
    },
    color24: {
        background: '#fd8c13',
    },
    color23: {
        background: '#f9c623',
    },
    color22: {
        background: '#bfd82a',
    },
    color21: {
        background: '#37b753',
    },
    color20: {
        background: '#009191',
    },
    color19: {
        background: '#00aaff',
    },
    color18: {
        background: '#003eff',
    },
    color55: {
        background: '#ff0000',
    },
    color54: {
        background: '#ff2806',
    },
    color53: {
        background: '#fe460b',
    },
    color52: {
        background: '#fc6511',
    },
    color51: {
        background: '#fb8116',
    },
    color50: {
        background: '#fa9e1c',
    },
    color49: {
        background: '#f9b921',
    },
    color48: {
        background: '#dec42a',
    },
    color47: {
        background: '#afc035',
    },
    color46: {
        background: '#84bd40',
    },
    color45: {
        background: '#59ba4b',
    },
    color44: {
        background: '#34bb5c',
    },
    color43: {
        background: '#2ac87c',
    },
    color42: {
        background: '#20d59c',
    },
    color41: {
        background: '#16e2bb',
    },
    color40: {
        background: '#0bf0dc',
    },
    color39: {
        background: '#02fcf9',
    },
    color38: {
        background: '#00d7ff',
    },
    color37: {
        background: '#00abff',
    },
    color36: {
        background: '#007eff',
    },
    color35: {
        background: '#0058ff',
    },
    list: {
        width: 250,
      },
    fullList: {
        width: 'auto',
      },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: (props) => `calc(108% - ${props.drawerWidth}px)`,
        marginLeft: (props) => `calc(${props.drawerWidth}px - 8%)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    icon: {
        fontSize: 15,
        opacity: 0.5,
        '&:hover': {
            opacity: 1,
        },
    },
    activeIcon: {
        fontSize: 15,
        opacity: 1,
    },
    root: {
        display: 'flex',
    },
    drawer: {
        width: (props) => props.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: (props) => props.drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 76,
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    radio: {
        paddingTop: 0,
        paddingBottom: 0,
    }
  }));

  export default mainStyleClasses