import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import { descriptionDictionary } from '../../dataFilter/descriptionDictionary'
import { TitleRow } from '../../components'

import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

const ErrorsWarnings = ({ errWarn }) => {
    const tableErrWarn = Object.entries(errWarn).map(entry => (
        <tr key={entry[0]}>
            <td className="text-left">{descriptionDictionary.errWarn[entry[0]]} </td>
            <td>{errWarn[entry[0]] === 1 ?
                <FontAwesomeIcon style={{ color: "red" }} icon={faCircle} />
                : <FontAwesomeIcon icon={faCircle} style={{ color: "green" }} />}</td>
        </tr>
    ));

    return (
        <>
            <Container>
                <TitleRow title="Błędy i ostrzeżenia" />
                <Row>
                    <Col>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th className="text-left">Opis</th>
                                    <th>Wskazania</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableErrWarn}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ErrorsWarnings;