import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Typography, Table, TableRow, TableCell, TableBody, TableContainer, Paper, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { mainStyleClasses } from '../../../../../../theme'
import { RefillAcceptanceReport } from '../../../../../../components'

const ConfirmSendForm = ({ report, reportId, refreshInstallator }) => {
    const classes = mainStyleClasses()
    const [editReport, setEditReport] = useState(false)
    const [values, setNewValues] = useState(null)

    const handleOnClickEdit = () => {
        setEditReport(!editReport)
    }

    useEffect(() => {
        let mounted = true
        setNewValues(report)
        return () => {
            mounted = false
        }
    }, [setNewValues, report])

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h2">Dane wprowadzone do protokołu:</Typography>
            </Grid>
            {!editReport && values? <React.Fragment><Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Protokół sporządził:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.drafted}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Data sporządzenia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.date}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Typ urządzenia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.modelType === "ATMO" ? "Zestaw Powietrznej Pompy Ciepła (ZPPC)" : "Zestaw Gruntowej Pompy Ciepła (ZGPC)"}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Model urządzenia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.model}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                    <TableCell size="small"><Typography variant="body1">Klucz:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.key}</strong></Typography></TableCell>
                                </TableRow>
                            {values.modelType === 'ATMO' ? (
                                <Fragment>
                                    <TableRow>
                                        <TableCell size="small"><Typography variant="body1">Numer seryjny jednostki wewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.serialNumber}</strong></Typography></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size="small"><Typography variant="body1">Numer seryjny jednostki zewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.serialNumberOutsideATMO}</strong></Typography></TableCell>
                                    </TableRow>
                                </Fragment>) : (

                                <TableRow>
                                    <TableCell size="small"><Typography variant="body1">Numer seryjny:</Typography></TableCell><TableCell size="small"><Typography variant="body1"> <strong>{values.serialNumber}</strong></Typography></TableCell>
                                </TableRow>
                            )}
                            {/*<TableRow>
                                <TableCell size="small"><p>Inwestor:</p></TableCell><TableCell size="small"><p> <strong>{values.investorName}</strong></p></TableCell>
                            </TableRow>*/}
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Miejsce Montażu:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{`ul. ${values.street} ${values.streetNumber}, ${values.postNumber} ${values.city}, ${values.postOffice}`}</strong></Typography></TableCell>
                            </TableRow>
                            {/*<TableRow>
                                <TableCell size="small"><p>Rodzaj budynku:</p></TableCell><TableCell size="small"><p><strong>{values.typeBuilding}</strong></p></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><p>Poziom kondygnacji:</p></TableCell><TableCell size="small"><p><strong>{values.typeRoom}</strong></p></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><p>Instalator:</p></TableCell><TableCell size="small"><p><strong>{values.fitterName}</strong></p></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><p>Czy urządzenie ma uszkodzenia mechaniczne?:</p></TableCell><TableCell size="small"><p><strong>{values.broken}</strong></p></TableCell>
                            </TableRow>
                            {values.broken === 'Tak' ? (<TableRow>
                                <TableCell size="small"><p>Opisz rodzaj i miejsce uszkodzeń mechanicznych:</p></TableCell><TableCell size="small"><p><strong>{values.demageDescription}</strong></p></TableCell>
                            </TableRow>) : null}*/}
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Napełnienie instalacji:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.filling}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Ocena szczelności połączeń hydraulicznych:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.tightness}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Sprawdzenie wymaganego przekroju żył przewodu zasilającego:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.wireCrossSection}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Podłączenie zasilania:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.powerConnection}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Sprawdzenie zalecanego zabezpieczenia linii zasilającej pompę ciepła w rozdzielni głównej budynku:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.powerLineProtection}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Podłączenie czujników temperatury:</Typography></TableCell><TableCell size="small"><Typography variant="body1">{values.sensorsConnected ? (<strong>{values.sensorsConnected.map(sensor => `${sensor}, `)}</strong>) : (<strong>czujniki nie zostały podłączone</strong>)}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Umiejscowienie czujnika temperatury zewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.sensorOutsideTempPlace}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Umiejscowienie czujnika temperatury wewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.sensorInsideTempPlace}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Wymuszenie funkcji przygotowania cwu, odpowietrzenie obiegu przygotowania cwu, kontrola przepływu w obiegu przygotowania cwu:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.preparationCwu}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Wyłączenie funkcji przygotowania cwu i wymuszenie pracy na ogrzewanie, odpowietrzenie obiegu grzewczo - chłodzącego, kontrola przepływu w obiegu grzewczo - chłodzącym:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.forceHeating}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Pierwsze czyszczenie filtrów w obiegu grzewczym:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.cleanFilter}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Kontrola temperatury krzepnięcia w obiegu grzewczym:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.controlCoagulationTemp}</strong></Typography></TableCell>
                            </TableRow>
                            {values.coagulationTemp ? <TableRow>
                                <TableCell size="small"><Typography variant="body1">Temperatura krzepnięcia:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.coagulationTemp} &#x2103;</strong></Typography></TableCell>
                            </TableRow> : null}
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Załączenie bezpiecznika jednostki zewnętrznej:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.outdoorFuse}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Przywrócenie nastaw fabrycznych:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.reinstatementSettings}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Skasowanie historii alarmów, skasowanie statystyk:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.deleteHistory}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Kontrola znaku i wartości dT na wymienniku odbioru:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.dTValueControl}</strong></Typography></TableCell>
                            </TableRow>
                            {values.dTValue ? <TableRow>
                                <TableCell size="small"><Typography variant="body1">Wartości dT:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.dTValue}</strong></Typography></TableCell>
                            </TableRow> : null}
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Procedura uruchomienia w aplikacji ATMO Monitor:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.launchAtmoMonitor}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Test panelu operatorskiego:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.testOperatorPanel}</strong></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small"><Typography variant="body1">Przeszkolenie użytkownika:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.userInstruct}</strong></Typography></TableCell>
                            </TableRow>
                            {values.comments ? (<TableRow>
                                <TableCell size="small"><Typography variant="body1">Uwagi:</Typography></TableCell><TableCell size="small"><Typography variant="body1"><strong>{values.comments}</strong></Typography></TableCell>
                            </TableRow>) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() => handleOnClickEdit()} variant="contained" color="primary" className={`${classes.floatRight} ${classes.buttonMargin}`}>Edycja&nbsp;&nbsp;&nbsp;<EditIcon /></Button>
            </Grid></React.Fragment>: <RefillAcceptanceReport setNewValues={setNewValues} refreshInstallator={refreshInstallator} report={values} id={reportId} handleOnClickEdit={handleOnClickEdit} />}
        </React.Fragment>
    )
}

export default ConfirmSendForm