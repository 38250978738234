import { Guid } from 'js-guid'

const clientGuid = Guid.newGuid()



// ----------------------------------- DEVELOPER
 
export const mqttConfig = () => {
  let accessScada = JSON.parse(sessionStorage.getItem('ScadaAccess'))
 
  return {  
  clientId: clientGuid, 
  port: 9001,
  host: 'dev.eurosenergy.com',
  hostname: "dev.eurosenergy.com",
  rejectUnauthorized: false,
  username: `${accessScada.username}`,
  password: `${accessScada.password}`,
  secureProtocol: 'TLSv1_2_method',
  protocolId: 'MQTT',
  protocolVersion: 4,
  protocol: 'mqtts',
  retain: false
}
}

// ------------------------------------ PRODUCTION

// export const mqttConfig = () => {
//   let accessScada = JSON.parse(sessionStorage.getItem('ScadaAccess'))
 
//   return {  
//   clientId: clientGuid, 
//   port: 9003,
//   host: 'cloud.eurosenergy.com',
//   hostname: "cloud.eurosenergy.com",
//   rejectUnauthorized: false,
//   username: `${accessScada.username}`,
//   password: `${accessScada.password}`,
//   secureProtocol: 'TLSv1_2_method',
//   protocolId: 'MQTT',
//   protocolVersion: 4,
//   protocol: 'mqtts',
//   retain: false
// }
// }