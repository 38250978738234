import React from 'react'
//import { registerDictionary } from '../../helpValue'
import { registerDictionary } from '../../../../data/dataDictionary'
import { Grid, Typography } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import { FiberManualRecord } from '@material-ui/icons'
import { dataRange } from '../../../../data/dataRange'

const TileBox = ({ item, colorBox, name, communicationColor, dialogOpen, data }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary(data? data : null)[name]
    const color = communicationColor ? communicationColor : 0
    const filteredItems = Object.entries(item ? item.map : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))

    return (

        <div className={`${colorBox} ${classes.boxRadius}`} style={{ paddingTop: '7px', cursor: 'pointer' }} onClick={() => dialogOpen(name, color)}>
            <Typography variant="h3">{dictionary.title}</Typography>
            {communicationColor === 1 && filteredItems.map(entry => entry[1] === 1).includes(true) ?
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant="body1" style={{ color: 'red' }}>Występują błędy lub ostrzeżenia</Typography>
                    </Grid>
                </Grid>
                : communicationColor === 1 && filteredItems.map(entry => entry[1] === 1).includes(false) ?
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="body1" style={{ color: 'grey' }}>Brak błędów lub ostrzeżeń</Typography>
                        </Grid>
                    </Grid>
                    : null
            }
            {filteredItems.map(entry => {
                // if (communicationColor === 1 && entry[1] !== undefined) {
                //     return (
                //         <Grid container key={entry[0]} className='vertical-centering'>
                //                 {entry[1] === 1 ?
                //                     <Grid container>
                //                         <Grid item xs={11}>
                //                             <Typography variant="body1" style={{ color: 'red' }}>Występują błędy lub ostrzeżenia</Typography>
                //                         </Grid>
                //                     </Grid>
                //                     : <Grid container>
                //                         <Grid item xs={11}>
                //                             <Typography variant="body1" style={{ color: 'grey' }}>Brak błędów lub ostrzeżeń</Typography>
                //                         </Grid>
                //                     </Grid>
                //                 } 
                //         </Grid>
                //     )
                // } 
                if (communicationColor === 2 && entry[1] !== undefined) {
                    return (
                        <Grid container key={entry[0]} className='vertical-centering'>
                            <Grid item xs={1}><FiberManualRecord style={{ color: entry[1] === 0 ? "grey" : "green", fontSize: '16px', display: 'flex' }} /></Grid> 
                            <Grid item xs={11}><Typography variant="body1" style={{ color: entry[1] === 0 ? "grey" : "green" }}>{dictionary.data[entry[0]]}</Typography></Grid>
                        </Grid>
                    )
                } else if(!communicationColor && entry[1] !== undefined){
                    return (
                        <Grid container key={entry[0]}>
                            <Grid item xs={10}><Typography variant="body1">{dictionary.data[entry[0]]}</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}><Typography variant="body1">{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]}</Typography></Grid>
                        </Grid>
                    )
                } else {
                    return null
                }
            }
            )}
        </div>

    )
}

export default TileBox