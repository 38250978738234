import React, { useState } from 'react'
import {
    Grid, Button, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    IconButton, MenuItem
} from '@material-ui/core'
import formatString from 'format-string-by-pattern'
import { fetchInstallatorsList, fetchCertInstallator, fetchPartnersList, fetchRefreshToken, fetchCheckNipPartnerToken,
    fetchRegonApi, fetchInstallerAddEditToken, fetchPartnerAddEditToken } from '../../../../data'
import { errorMessage } from '../../validation'
import { ExcelRenderer } from 'react-excel-renderer'
import { mainStyleClasses } from '../../../../theme'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const textOnly = value => (value && textOnlyPattern.test(value) ? "" : "Pole może zawierać tylko litery")

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return ""
    }
}

const validNipNumber = (anyString) => {
    let anyString2 = anyString.toString()
    const nipString = anyString2.replaceAll(/[\s-]/gi, '')
    const nipWieght = [6, 5, 7, 2, 3, 4, 5, 6, 7]

    let nipSum = 0

    if (nipString.length < 10) {
        return "Podany numer jest za krótki"
    } else if (nipString.length > 10) {
        return "Podany numer jest za długi"
    } else {

        for (let i = 0; i < nipWieght.length; i++) {
            nipSum += (parseInt(nipString.slice(i, i + 1)) * nipWieght[i])
        }

        if (nipSum % 11 === parseInt(nipString.slice(9, 10))) {
            return ""
        } else {
            return "Nieprawidłowy NIP"
        }
    }
}

const acceptFile = [".csv", ".xlsx", ".xls"]

const InstallatorsFromFile = ({ partnersList, refreshInstallatorsList }) => {
    const classes = mainStyleClasses()
    const [open2, setOpen2] = useState({ status: false, edit: [], editNIP: [] })
    const [fileExcel, setFile] = useState(null)
    const [errorFile, setErrorFile] = useState("")
    const [verificationFile, setVerificationFile] = useState({ name: [], email: [], NIP: [] })
    const [editValues, setEditValues] = useState({ name: [], surname: [], email: [], phone: [], NIP: [] })
    const [editNIPValues, setEditNIPValues] = useState({ NIPname: [], street: [], street_number: [], post_code: [], city: [] })
    const [NIPValues, setNIPValues] = useState({ NIP: [], NIPname: [], street: [], street_number: [], post_code: [], city: [], addStatus: [] })
    const [disableAdd, setDisableAdd] = useState(false)
    const [nipStatus, setNipStatus] = useState({ status: [], company: [] })
    const [addStatus, setAddStatus] = useState({ status: [], errMessage: [] })
    const [partnerSelect, setPartnerSelect] = useState('')
    const [certSave, setCertSave] = useState(0)

    const handlePartnerSelect = (e) => {
        setPartnerSelect(parseInt(e.target.value))
        
        let cond_edit = open2.edit.every(x => x === false)
        let cond_edit2 = open2.editNIP.every(x => x === false)
        let cond_err = verificationFile.name.every(x => x === "") & verificationFile.email.every(x => x === "") & verificationFile.NIP.every(x => x === "") & nipStatus.status.every(x => x === true)
        setDisableAdd(!(cond_edit & cond_edit2 & cond_err & !(e.target.value === '')))
    }

    const handleCloseItem = deleteIndex => {
        let newRows = fileExcel.rows.filter((item, index) => index !== deleteIndex)

        let err_name = verificationFile.name.filter((item, index) => index !== deleteIndex)
        let err_email = verificationFile.email.filter((item, index) => index !== deleteIndex)
        let err_nip = verificationFile.NIP.filter((item, index) => index !== deleteIndex)

        setVerificationFile({ name: err_name, email: err_email, NIP: err_nip })

        let edit_indices = open2.edit.filter((item, index) => index !== deleteIndex)

        setOpen2({ ...open2, edit: edit_indices })

        let newEditValues_name = editValues.name.filter((item, index) => index !== deleteIndex)
        let newEditValues_surname = editValues.surname.filter((item, index) => index !== deleteIndex)
        let newEditValues_email = editValues.email.filter((item, index) => index !== deleteIndex)
        let newEditValues_phone = editValues.phone.filter((item, index) => index !== deleteIndex)
        let newEditValues_NIP = editValues.NIP.filter((item, index) => index !== deleteIndex)

        setEditValues({ name: newEditValues_name, surname: newEditValues_surname, email: newEditValues_email,
            phone: newEditValues_phone, NIP: newEditValues_NIP })

        let newNIPStatus_status = nipStatus.status.filter((item, index) => index !== deleteIndex)
        let newNIPStatus_company = nipStatus.company.filter((item, index) => index !== deleteIndex)

        setNipStatus({ status: newNIPStatus_status, company: newNIPStatus_company })

        let newEditNIPValues_NIPname = editNIPValues.NIPname.filter((item, index) => index !== deleteIndex)
        let newEditNIPValues_street = editNIPValues.street.filter((item, index) => index !== deleteIndex)
        let newEditNIPValues_street_number = editNIPValues.street_number.filter((item, index) => index !== deleteIndex)
        let newEditNIPValues_post_code = editNIPValues.post_code.filter((item, index) => index !== deleteIndex)
        let newEditNIPValues_city = editNIPValues.city.filter((item, index) => index !== deleteIndex)

        setEditNIPValues({ NIPname: newEditNIPValues_NIPname, street: newEditNIPValues_street,
            street_number: newEditNIPValues_street_number, post_code: newEditNIPValues_post_code, city: newEditNIPValues_city })

        let newAddStatus = addStatus.status.filter((item, index) => index !== deleteIndex)
        let newAddStatusMessage = addStatus.errMessage.filter((item, index) => index !== deleteIndex)

        setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })

        setDisableAdd(!(err_name.every(x => x === "") & err_email.every(x => x === "") & err_nip.every(x => x === "") &
            newNIPStatus_status.every(x => x === true) & !(partnerSelect === '')))

        setFile({
            rows: newRows
        })
    }

    const handleEditItem = editIndex => {
        let edited = open2.edit
        edited[editIndex] = true
        setOpen2({ ...open2, edit: edited })

        let row = fileExcel.rows[editIndex]
        let curState = editValues
        
        curState.name[editIndex] = row[0]
        curState.surname[editIndex] = row[1]
        curState.email[editIndex] = row[2]
        curState.phone[editIndex] = row[3]
        curState.NIP[editIndex] = row[4]
        setEditValues({ name: curState.name, surname: curState.surname, email: curState.email,
            phone: curState.phone, NIP: curState.NIP })
        setDisableAdd(true)
    }

    const handleEditNIPData = editIndex => {
        let edited = open2.editNIP
        edited[editIndex] = true
        setOpen2({ ...open2, editNIP: edited })

        let row = nipStatus.company[editIndex]
        let curState = editNIPValues
        
        curState.NIPname[editIndex] = row.name
        curState.street[editIndex] = row.street
        curState.street_number[editIndex] = row.street_number
        curState.post_code[editIndex] = row.post_code ? row.post_code : row.postal_code
        curState.city[editIndex] = row.city
        setEditNIPValues({ NIPname: curState.NIPname, street: curState.street, street_number: curState.street_number,
            post_code: curState.post_code, city: curState.city })
        setDisableAdd(true)
    }

    const handleEditChange = (e, index) => {
        let key = e.target.name
        let edited = editValues[key]
        edited[index] = e.target.value
        setEditValues({ ...editValues, [e.target.name]: edited })
    }

    const handleEditNIPChange = (e, index) => {
        let key = e.target.name
        let edited = editNIPValues[key]
        edited[index] = e.target.value
        setEditNIPValues({ ...editNIPValues, [e.target.name]: edited })
    }

    const handleAcceptChanges = index => {
        let newRows = fileExcel.rows
        newRows[index] = [editValues.name[index], editValues.surname[index], editValues.email[index],
            editValues.phone[index], editValues.NIP[index]]

        setFile({ rows: newRows })
        
        let edited = open2.edit
        edited[index] = false
        setOpen2({ ...open2, edit: edited })

        let edited2 = open2.editNIP

        let errorsTableName = verificationFile.name
        let errorsTableEmail = verificationFile.email
        let errorsTableNIP = verificationFile.NIP

        errorsTableName[index] = textOnly(`${editValues.name[index]} ${editValues.surname[index]}`)
        errorsTableEmail[index] = formatEmail(editValues.email[index])
        errorsTableNIP[index] = validNipNumber(editValues.NIP[index])
        setVerificationFile({ name: errorsTableName, email: errorsTableEmail, NIP: errorsTableNIP })

        let cond_edit = edited.every(x => x === false)
        let cond_edit2 = edited2.every(x => x === false)
        let cond_err = errorsTableName.every(x => x === "") & errorsTableEmail.every(x => x === "") &
            errorsTableNIP.every(x => x === "")
        setDisableAdd(!(cond_edit & cond_edit2 & cond_err & !(partnerSelect === '')))

        if (NIPValues.NIP.includes(parseInt(editValues.NIP[index]))) {
            let ind = NIPValues.NIP.indexOf(parseInt(editValues.NIP[index]))
            let status = nipStatus.status
            let company = nipStatus.company
            status[index] = true
            company[index] = { name: NIPValues.NIPname[ind], street_address: (NIPValues.street[ind] + " " + NIPValues.street_number[ind]), street: NIPValues.street[ind],
                street_number: NIPValues.street_number[ind], post_code: NIPValues.post_code[ind], postal_code: NIPValues.post_code[ind], city: NIPValues.city[ind] }
            setNipStatus({ status: status, company: company })
            let newAddStatus = addStatus.status
            newAddStatus[index] = NIPValues.addStatus[ind]
            let newAddStatusMessage = addStatus.errMessage
            newAddStatusMessage[index] = ""
            setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
        } else {
            fetchRefreshToken().then(newToken => {
                if (errorsTableNIP[index] === "") {
                    fetchCheckNipPartnerToken(editValues.NIP[index], newToken).then(response => {
                        if (response.status === "ok") {
                            let status = nipStatus.status
                            let company = nipStatus.company
                            status[index] = true
                            company[index] = response.data
                            setNipStatus({ status: status, company: company })
                            let newAddStatus = addStatus.status
                            newAddStatus[index] = "ready"
                            let newAddStatusMessage = addStatus.errMessage
                            newAddStatusMessage[index] = ""
                            setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                        } else {
                            // jak nie ma w naszej bazie jeszcze zapisanej firmy o tym nipie
                            fetchRegonApi(editValues.NIP[index]).then(async res => {
                                if (res.status === "ok") {
                                    let status = nipStatus.status
                                    let company = nipStatus.company
                                    status[index] = true
                                    company[index] = res.data
                                    setNipStatus({ status: status, company: company })
                                    let newAddStatus = addStatus.status
                                    newAddStatus[index] = "new"
                                    let newAddStatusMessage = addStatus.errMessage
                                    newAddStatusMessage[index] = ""
                                    setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                } else {
                                    let status = nipStatus.status
                                    let company = nipStatus.company
                                    status[index] = false
                                    company[index] = "Podany NIP nie istnieje"
                                    setNipStatus({ status: status, company: company })
                                    setDisableAdd(true)
                                    let newAddStatus = addStatus.status
                                    newAddStatus[index] = "error"
                                    let newAddStatusMessage = addStatus.errMessage
                                    newAddStatusMessage[index] = ""
                                    setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                }
                            })
                        }
                    })
                } else {
                    let status = nipStatus.status
                    let company = nipStatus.company
                    status[index] = false
                    company[index] = "Niepoprawny NIP"
                    setNipStatus({ status: status, company: company })
                    setDisableAdd(true)
                    let newAddStatus = addStatus.status
                    newAddStatus[index] = "error"
                    let newAddStatusMessage = addStatus.errMessage
                    newAddStatusMessage[index] = ""
                    setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                }
            })
        }
    }

    const handleAcceptNIPChanges = index => {
        let newRows = nipStatus.company

        for (let i = 0; i < newRows.length; i++) {
            if (fileExcel.rows[i][4] === fileExcel.rows[index][4]) {
                newRows[i].name = editNIPValues.NIPname[index]
                newRows[i].street = editNIPValues.street[index]
                newRows[i].street_number = editNIPValues.street_number[index]
                newRows[i].street_address = `${editNIPValues.street[index]} ${editNIPValues.street_number[index]}`
                newRows[i].post_code = editNIPValues.post_code[index]
                newRows[i].postal_code = editNIPValues.post_code[index]
                newRows[i].city = editNIPValues.city[index]
            }
        }

        setNipStatus({ ...nipStatus, company: newRows })

        let values_NIP = NIPValues.NIP
        let values_NIPname = NIPValues.NIPname
        let values_street = NIPValues.street
        let values_street_number = NIPValues.street_number
        let values_post_code = NIPValues.post_code
        let values_city = NIPValues.city
        let values_addStatus = NIPValues.addStatus
        let ind = NIPValues.NIP.indexOf(fileExcel.rows[index][4])

        values_NIPname[ind] = editNIPValues.NIPname[index]
        values_street[ind] = editNIPValues.street[index]
        values_street_number[ind] = editNIPValues.street_number[index]
        values_post_code[ind] = editNIPValues.post_code[index]
        values_city[ind] = editNIPValues.city[index]

        setNIPValues({ NIP: values_NIP, NIPname: values_NIPname, street: values_street, street_number: values_street_number, post_code: values_post_code,
            city: values_city, addStatus: values_addStatus })

        let edited = open2.edit
        
        let edited2 = open2.editNIP
        edited2[index] = false
        setOpen2({ ...open2, editNIP: edited2 })

        let errorsTableName = verificationFile.name
        let errorsTableEmail = verificationFile.email
        let errorsTableNIP = verificationFile.NIP

        let cond_edit = edited.every(x => x === false)
        let cond_edit2 = edited2.every(x => x === false)
        let cond_err = errorsTableName.every(x => x === "") & errorsTableEmail.every(x => x === "") &
            errorsTableNIP.every(x => x === "")
        setDisableAdd(!(cond_edit & cond_edit2 & cond_err & !(partnerSelect === '')))
    }

    const handleRejectChanges = index => {
        let edited = open2.edit
        edited[index] = false
        setOpen2({ ...open2, edit: edited })

        let edited2 = open2.editNIP

        let cond_edit = edited.every(x => x === false)
        let cond_edit2 = edited2.every(x => x === false)
        let cond_err = verificationFile.name.every(x => x === "") & verificationFile.email.every(x => x === "") &
            verificationFile.NIP.every(x => x === "") & nipStatus.status.every(x => x === true)
        setDisableAdd(!(cond_edit & cond_edit2 & cond_err & !(partnerSelect === '')))
    }

    const handleRejectNIPChanges = index => {
        let edited = open2.edit

        let edited2 = open2.editNIP
        edited2[index] = false
        setOpen2({ ...open2, editNIP: edited2 })

        let cond_edit = edited.every(x => x === false)
        let cond_edit2 = edited2.every(x => x === false)
        let cond_err = verificationFile.name.every(x => x === "") & verificationFile.email.every(x => x === "") &
            verificationFile.NIP.every(x => x === "") & nipStatus.status.every(x => x === true)
        setDisableAdd(!(cond_edit & cond_edit2 & cond_err & !(partnerSelect === '')))
    }

    const fileHandler = (event) => {
        setNIPValues({ NIP: [], NIPname: [], street: [], street_number: [], post_code: [], city: [], addStatus: [] })
        let fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
            const dotIndex = fileObj.name.lastIndexOf(".")

            if (err || !acceptFile.includes(fileObj.name.slice(dotIndex))) {
                setErrorFile("Błąd załączonego pliku. Plik powinien być w formacie: .csv, .xls, lub .xlsx")
            }
            else {
                setErrorFile("")
                const addElement = []
                let errorsTableName = []
                let errorsTableEmail = []
                let errorsTableNIP = []
                let edit_indices = []
                let edit_NIP_indices = []
                let edit_values = { name: [], surname: [], email: [], phone: [], NIP: [] }
                let edit_NIP_values = { NIPname: [], street: [], street_number: [], post_code: [], city: [] }
                let NIP_values = { NIP: [], NIPname: [], street: [], street_number: [], post_code: [], city: [], addStatus: [] }

                setNipStatus({ status: [], company: [] })

                fetchRefreshToken().then(newToken => {
                    for (let i = 0; i < resp.rows.length; i++) {
                        if (resp.rows[i].length !== 0) {
                            let nipValidation = validNipNumber(resp.rows[i][4])

                            errorsTableName.push(textOnly(`${resp.rows[i][0]} ${resp.rows[i][1]}`))
                            errorsTableEmail.push(formatEmail(resp.rows[i][2]))
                            errorsTableNIP.push(nipValidation)

                            edit_values.name[i] = resp.rows[i][0]
                            edit_values.surname[i] = resp.rows[i][1]
                            edit_values.email[i] = resp.rows[i][2]
                            edit_values.phone[i] = resp.rows[i][3]
                            edit_values.NIP[i] = resp.rows[i][4]

                            if (nipValidation === "") {
                                fetchCheckNipPartnerToken(resp.rows[i][4], newToken).then(response => {
                                    if (response.status === "ok") {
                                        let status = nipStatus.status
                                        let company = nipStatus.company
                                        status[i] = true
                                        company[i] = response.data
                                        setNipStatus({ status: status, company: company })

                                        edit_NIP_values.NIPname[i] = response.data.name
                                        edit_NIP_values.street[i] = response.data.street
                                        edit_NIP_values.street_number[i] = response.data.street_number
                                        edit_NIP_values.post_code[i] = response.data.post_code
                                        edit_NIP_values.city[i] = response.data.city

                                        let newAddStatus = addStatus.status
                                        newAddStatus[i] = "ready"
                                        let newAddStatusMessage = addStatus.errMessage
                                        newAddStatusMessage[i] = ""
                                        setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })

                                        if(!NIPValues.NIP.includes(resp.rows[i][4])) {
                                            NIP_values.NIP.push(resp.rows[i][4])
                                            NIP_values.NIPname.push(response.data.name)
                                            NIP_values.street.push(response.data.street)
                                            NIP_values.street_number.push(response.data.street_number)
                                            NIP_values.post_code.push(response.data.post_code)
                                            NIP_values.city.push(response.data.city)
                                            NIP_values.addStatus.push("ready")
                                            setNIPValues({ NIP: NIP_values.NIP, NIPname: NIP_values.NIPname, street: NIP_values.street,
                                                street_number: NIP_values.street_number, post_code: NIP_values.post_code,
                                                city: NIP_values.city, addStatus: NIP_values.addStatus })
                                        }
                                    } else {
                                        // jak nie ma w naszej bazie jeszcze zapisanej firmy o tym nipie
                                        fetchRegonApi(resp.rows[i][4]).then(async res => {
                                            if (res.status === "ok") {
                                                const reg = /^(.*[^0-9]+) (([1-9][0-9]*)[/])?([1-9][0-9]*[a-cA-C]?)$/g
                                                let status = nipStatus.status
                                                let company = nipStatus.company
                                                status[i] = true
                                                company[i] = res.data
                                                setNipStatus({ status: status, company: company })

                                                edit_NIP_values.NIPname[i] = res.data.name
                                                edit_NIP_values.street[i] = res.data.street
                                                edit_NIP_values.street_number[i] = res.data.street_address.replace(reg.exec(res.data.street_address)[1] + " ", "")
                                                edit_NIP_values.post_code[i] = res.data.postal_code
                                                edit_NIP_values.city[i] = res.data.city
                                                
                                                let newAddStatus = addStatus.status
                                                newAddStatus[i] = "new"
                                                let newAddStatusMessage = addStatus.errMessage
                                                newAddStatusMessage[i] = ""
                                                setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })

                                                if(!NIP_values.NIP.includes(resp.rows[i][4])) {
                                                    const reg = /^(.*[^0-9]+) (([1-9][0-9]*)[/])?([1-9][0-9]*[a-cA-C]?)$/g
                                                    
                                                    NIP_values.NIP.push(resp.rows[i][4])
                                                    NIP_values.NIPname.push(res.data.name)
                                                    NIP_values.street.push(res.data.street)
                                                    NIP_values.street_number.push(res.data.street_address.replace(reg.exec(res.data.street_address)[1] + " ", ""))
                                                    NIP_values.post_code.push(res.data.postal_code)
                                                    NIP_values.city.push(res.data.city)
                                                    NIP_values.addStatus.push("new")
                                                    setNIPValues({ NIP: NIP_values.NIP, NIPname: NIP_values.NIPname, street: NIP_values.street,
                                                        street_number: NIP_values.street_number, post_code: NIP_values.post_code,
                                                        city: NIP_values.city, addStatus: NIP_values.addStatus })
                                                }
                                            } else {
                                                let status = nipStatus.status
                                                let company = nipStatus.company
                                                status[i] = false
                                                company[i] = "Podany NIP nie istnieje"
                                                setNipStatus({ status: status, company: company })
                                                let newAddStatus = addStatus.status
                                                newAddStatus[i] = "error"
                                                let newAddStatusMessage = addStatus.errMessage
                                                newAddStatusMessage[i] = ""
                                                setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                            }
                                        })
                                    }
                                })
                            } else {
                                let status = nipStatus.status
                                let company = nipStatus.company
                                status[i] = false
                                company[i] = "Niepoprawny NIP"
                                setNipStatus({ status: status, company: company })
                                let newAddStatus = addStatus.status
                                newAddStatus[i] = "error"
                                let newAddStatusMessage = addStatus.errMessage
                                newAddStatusMessage[i] = ""
                                setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                            }

                            addElement.push(resp.rows[i])
                        }

                        edit_indices[i] = false
                        edit_NIP_indices[i] = false
                    }
                }).then(() => {
                    setVerificationFile({ name: errorsTableName, email: errorsTableEmail, NIP: errorsTableNIP })

                    setOpen2({ ...open2, edit: edit_indices, editNIP: edit_NIP_indices })

                    setEditValues({ name: edit_values.name, surname: edit_values.surname, email: edit_values.email,
                        phone: edit_values.phone, NIP: edit_values.NIP })

                    setEditNIPValues({ NIPname: edit_NIP_values.NIPname, street: edit_NIP_values.street,
                        street_number: edit_NIP_values.street_number, post_code: edit_NIP_values.post_code,
                        city: edit_NIP_values.city })

                    setDisableAdd(!(errorsTableName.every(x => x === "") & errorsTableEmail.every(x => x === "") &
                        errorsTableNIP.every(x => x === "") & nipStatus.status.every(x => x === true) & !(partnerSelect === '')))

                    setFile({
                        rows: addElement
                    });
                })
            }
        });
    }

    const dataPack = (val, data, type) => {
        const reg = /^(.*[^0-9]+) (([1-9][0-9]*[a-zA-Z]?)[/])?([a-zA-Z]?[1-9][0-9]*[a-zA-Z]?)$/g
    
        const street = data ? (data.street_address ? reg.exec(data.street_address)[1]
            : (data.street ? data.street : "")) : ""
        let pack
        if (type === "company") {
            pack = {
                name: data.name ? data.name.replaceAll("\"", "") : "nazwa firmy",
                email: val.email,
                phone: val.phone,
                nip: val.nip,
                street: street ? street : "ulica",
                street_number: data.street_address ? data.street_address.replace(street + " ", "")
                    : (data.street_number ? data.street_number : "numer ulicy"),
                city: data.city ? data.city : "miasto",
                post_code: data.postal_code ? data.postal_code : (data.post_code ? data.post_code : "kod pocztowy"),
                parent_partner_id: val.parent_partner_id,
                memo: ""
            }
        } else {
            pack = {
                partner_id: val.partner_id,
                installators: [
                    {
                        email: val.email,
                        phone: val.phone,
                        first_name: val.first_name,
                        last_name: val.last_name
                    }
                ]
            }
        }
    
        return pack
    }

    const handleAddInstallers = async values => {
        fetchRefreshToken().then(newToken => {
            for (let i = 0; i < values.length; i++) {
                let row = values[i]
                let val = { first_name: row[0], last_name: row[1], email: row[2], phone: row[3],
                    nip: row[4], partner_id: nipStatus.company[i].id }
                val.nip = val.nip.toString().replaceAll(/[\s-]/gi, '')
                val.phone = val.phone.toString()

                switch(addStatus.status[i]) {
                    case "ready":
                        fetchInstallerAddEditToken(dataPack(val), "POST", newToken).then(response => {
                            if (response.status === 200 || response.status === "ok") {
                                let newAddStatus = addStatus.status
                                newAddStatus[i] = "done"
                                let newAddStatusMessage = addStatus.errMessage
                                newAddStatusMessage[i] = ""
                                setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                //handleCloseItem(i)
                                return response.json()
                            } else {
                                console.log("ready error")
                                let newAddStatus = addStatus.status
                                newAddStatus[i] = "ready error"
                                let newAddStatusMessage = addStatus.errMessage
                                newAddStatusMessage[i] = errorMessage(response.status)
                                setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                            }
                        }).then(response => {
                            setCertSave({values, new: response})
                        });
                        break;
                    case "new":
                        val = { ...val, parent_partner_id: partnerSelect }
                        
                        fetchPartnerAddEditToken(dataPack(val, nipStatus.company[i], "company"), "POST", 0, newToken).then(response => {
                            if (response.status === 200 || response.status === "ok") {
                                return response.json()
                            } else {
                                console.log("new partner error")
                                let newAddStatus = addStatus.status
                                newAddStatus[i] = "new partner error"
                                let newAddStatusMessage = addStatus.errMessage
                                newAddStatusMessage[i] = errorMessage(response.status, "partner")
                                setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                return "error"
                            }
                        }).then(response => {
                            if (response.status === "ok") {
                                val = { ...val, partner_id: response.data.id }
                                
                                fetchInstallerAddEditToken(dataPack(val), "POST", newToken).then(response => {
                                    
                                    if (response.status === 200 || response.status === "ok") {
                                        let newAddStatus = addStatus.status
                                        newAddStatus[i] = "done"
                                        let newAddStatusMessage = addStatus.errMessage
                                        newAddStatusMessage[i] = ""
                                        setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                        //handleCloseItem(i)
                                        return response.json()
                                    } else {
                                        console.log("new installer error")
                                        let newAddStatus = addStatus.status
                                        newAddStatus[i] = "new installer error"
                                        let newAddStatusMessage = addStatus.errMessage
                                        newAddStatusMessage[i] = errorMessage(response.status)
                                        setAddStatus({ status: newAddStatus, errMessage: newAddStatusMessage })
                                    }
                                }).then(response => {
                                    setCertSave({values, new: response})
                                })
                            }
        
                        });
                        break;
                    default:
                        break;
                }
            }
        }).then(() => refreshInstallatorsList())
    }

    const disableAddVar = disableAdd;

    return (
        <React.Fragment>
            <Grid container direction="row" >
                <Grid item xs={12} md={12}>
                    <Typography variant="h2">Lista nowych instalatorów z pliku</Typography>
                    <Typography component={'span'} variant="body1">
                        Plik powinien być w jednym z formatów: .csv, .xls lub .xlsx. Struktura powinna zawierać pięć kolumn:
                        <ol>
                            <li>imię instalatora</li>
                            <li>nazwisko instalatora</li>
                            <li>adres email</li>
                            <li>numer telefonu</li>
                            <li>NIP</li>
                        </ol>
                        Upewnij się, że przy wprowadzaniu danych instalatora z firmy, której jeszcze nie mamy w bazie,
                        wszystkie dane firmy pobrane na podstawie NIPu są kompletne i poprawne.
                    </Typography>
                    <Grid container direction="row" >
                        <Grid item xs={9} md={9}>
                            {partnersList ?
                                <TextField
                                    name="parent_partner_id"
                                    label="Wybierz partnera"
                                    select
                                    value={partnerSelect}
                                    onChange={(e) => handlePartnerSelect(e)}
                                    variant="standard"
                                    size="small"
                                >
                                    {partnersList.map(partner => <MenuItem key={partner.id} value={partner.id}>{partner.name}</MenuItem>)}
                                </TextField>
                            : null}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={4} md={4}>
                            <TextField
                                type="file"
                                id={`contained-button-file`}
                                className={`${classes.hidden}`}
                                onChange={e => fileHandler(e)}
                                style={{ padding: "10px", display: "none" }} />
                            <label htmlFor={`contained-button-file`}>
                                <Button id={`contained-button-file-devices-list`} className={`${classes.floatLeft} ${classes.buttonMargin}`}
                                    variant="contained" color="primary" component="span">
                                    Dodaj plik z listą instalatorów
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            {fileExcel && fileExcel.rows.length !== 0 ?
                                <Button variant="contained" color="primary" className={`${classes.floatLeft} ${classes.buttonMargin}`}
                                    onClick={(e) => handleAddInstallers(fileExcel.rows, e)} disabled={disableAddVar}>
                                    Dodaj wszystkich
                                </Button>
                                : null}
                        </Grid>
                    </Grid>
                    {errorFile !== "" ? <Typography variant="body1" style={{ color: "red", marginTop: "15px" }}>{errorFile}</Typography> : null}
                </Grid>
                {fileExcel ? 
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Imię i nazwisko
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Email
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Telefon
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            NIP
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Dane firmy
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="fontWeightBold">
                                            Uwagi
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileExcel.rows ? fileExcel.rows.map((item, index) => (
                                    open2.edit[index] ?
                                        <TableRow>
                                            <TableCell>
                                                <TextField
                                                    label="Imię"
                                                    name="name"
                                                    type="text"
                                                    onChange={(e) => handleEditChange(e, index)}
                                                    value={editValues.name[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Nazwisko"
                                                    name="surname"
                                                    type="text"
                                                    onChange={(e) => handleEditChange(e, index)}
                                                    value={editValues.surname[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Email"
                                                    name="email"
                                                    type="text"
                                                    onChange={(e) => handleEditChange(e, index)}
                                                    value={editValues.email[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Telefon"
                                                    name="phone"
                                                    type="text"
                                                    onChange={(e) => handleEditChange(e, index)}
                                                    value={editValues.phone[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="NIP"
                                                    name="NIP"
                                                    type="text"
                                                    onChange={(e) => handleEditChange(e, index)}
                                                    value={editValues.NIP[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton aria-label="close" onClick={() => handleAcceptChanges(index)} style={{ display: "inlineBlock", marginLeft: "30px", color: "green" }}>
                                                    <DoneIcon />
                                                </IconButton>
                                                <IconButton aria-label="close" onClick={() => handleRejectChanges(index)} style={{ display: "inlineBlock", marginLeft: "30px", color: "red" }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow> : addStatus.status[index] === "done" ? 
                                        <TableRow key={index}>
                                            <TableCell align="center" colSpan={6}><p style={{ color: "green" }}>Instalator poprawnie dodany do bazy</p></TableCell>
                                        </TableRow> : open2.editNIP[index] ?
                                        <TableRow>
                                            <TableCell>
                                                <TextField
                                                    label="Nazwa firmy"
                                                    name="NIPname"
                                                    type="text"
                                                    onChange={(e) => handleEditNIPChange(e, index)}
                                                    value={editNIPValues.NIPname[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Ulica"
                                                    name="street"
                                                    type="text"
                                                    onChange={(e) => handleEditNIPChange(e, index)}
                                                    value={editNIPValues.street[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Numer domu"
                                                    name="street_number"
                                                    type="text"
                                                    onChange={(e) => handleEditNIPChange(e, index)}
                                                    value={editNIPValues.street_number[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Kod pocztowy"
                                                    name="post_code"
                                                    type="text"
                                                    onChange={(e) => handleEditNIPChange(e, index)}
                                                    value={editNIPValues.post_code[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    label="Miejscowość"
                                                    name="city"
                                                    type="text"
                                                    onChange={(e) => handleEditNIPChange(e, index)}
                                                    value={editNIPValues.city[index]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton aria-label="close" onClick={() => handleAcceptNIPChanges(index)} style={{ display: "inlineBlock", marginLeft: "30px", color: "green" }}>
                                                    <DoneIcon />
                                                </IconButton>
                                                <IconButton aria-label="close" onClick={() => handleRejectNIPChanges(index)} style={{ display: "inlineBlock", marginLeft: "30px", color: "red" }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow> :
                                        <TableRow key={index}>
                                            <TableCell>{item[0]} {item[1]} {verificationFile.name[index] ? (<p style={{ color: "red" }}><br />{verificationFile.name[index]}</p>) : null}</TableCell>
                                            <TableCell>{item[2]} {verificationFile.email[index] ? (<p style={{ color: "red" }}><br />{verificationFile.email[index]}</p>) : null}</TableCell>
                                            <TableCell>{item[3]}</TableCell>
                                            <TableCell>{item[4]} {verificationFile.NIP[index] ? (<p style={{ color: "red" }}><br />{verificationFile.NIP[index]}</p>) : null}</TableCell>
                                            <TableCell>
                                                {nipStatus.company.map((it, ind) => (
                                                    ind === index ?
                                                        ((it.name || it.street_address || it.street || it.street_number || it.postal_code || it.post_code || it.city) ?
                                                            (it.street_address ?
                                                                (it.postal_code ?
                                                                    <div>
                                                                        {it.name} {it.street_address} {it.postal_code} {it.city}
                                                                        {addStatus.status[ind] === "new" || addStatus.status[ind] === "new partner error" || addStatus.status[ind] === "new installer error" ?
                                                                        <div>
                                                                            <br />
                                                                            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEditNIPData(index)}>
                                                                                Edytuj dane firmy
                                                                            </Button>
                                                                        </div> : null}
                                                                    </div>
                                                                :   <div>
                                                                        {it.name} {it.street_address} {it.post_code} {it.city}
                                                                        {addStatus.status[ind] === "new" || addStatus.status[ind] === "new partner error" || addStatus.status[ind] === "new installer error" ?
                                                                        <div>
                                                                            <br />
                                                                            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEditNIPData(index)}>
                                                                                Edytuj dane firmy
                                                                            </Button>
                                                                        </div> : null}
                                                                    </div>)
                                                            : (it.postal_code ?
                                                                    <div>
                                                                        {it.name} {it.street} {it.street_number} {it.postal_code} {it.city}
                                                                        {addStatus.status[ind] === "new" || addStatus.status[ind] === "new partner error" || addStatus.status[ind] === "new installer error" ?
                                                                        <div>
                                                                            <br />
                                                                            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEditNIPData(index)}>
                                                                                Edytuj dane firmy
                                                                            </Button>
                                                                        </div> : null}
                                                                    </div>
                                                                :   <div>
                                                                        {it.name} {it.street} {it.street_number} {it.post_code} {it.city}
                                                                        {addStatus.status[ind] === "new" || addStatus.status[ind] === "new partner error" || addStatus.status[ind] === "new installer error" ?
                                                                        <div>
                                                                            <br />
                                                                            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEditNIPData(index)}>
                                                                                Edytuj dane firmy
                                                                            </Button>
                                                                        </div> : null}
                                                                    </div>))
                                                        : (<p style={{ color: "red" }} key={ind}>{it}</p>))
                                                    : ""
                                                ))}
                                            </TableCell>
                                            <TableCell>{addStatus.errMessage[index] ? (<p style={{ color: "red" }}>{addStatus.errMessage[index]}</p>) : null}</TableCell>
                                            <TableCell>
                                                <IconButton aria-label="close" onClick={() => handleEditItem(index)} style={{ display: "inlineBlock", marginLeft: "30px" }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton aria-label="close" onClick={() => handleCloseItem(index)} style={{ display: "inlineBlock", marginLeft: "30px" }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                )) : null}
                            </TableBody>

                        </Table>
                    </TableContainer> : null}
            </Grid>
        </React.Fragment>
    )
}

export default InstallatorsFromFile