import { convertToBinary } from 'decimal-to-binary'

const functionMoreInfo = (data, name, version, workState, SIO_B03) => {

    const version_1 = parseInt(sessionStorage.getItem('version_1')) 
	const version_2 = parseInt(sessionStorage.getItem('version_2')) 

const messages = {
    rewGEO_1: [
        "Awaria układu kontrolnego wejść binarnych",
        "Zbiorcza flaga stanu ostrzegawczego",
        "Przekroczenie temperatury krytycznej w wymienniku odbioru",
        "Przekroczenie temperatury krytycznej w wymienniku źródła",
        "Przekroczona temp. max. odbioru ciepła (ogrzewanie)",
        "Przekroczona temp. min. odbioru chłodu (chłodzenie)",
        "Przekroczona temp. max. źródła chłodu (chłodzenie)",
        "Przekroczona temp. min. źródła ciepła (ogrzewanie)",
        "Zbiorcza flaga stanu awaryjnego",
        "Przekroczenie temperatury tłoczenia",
        "Sygnał z czujnika przepływu",
        "Błąd z presostatu wysokiego ciśnienia",
        "Błąd z presostatu niskiego ciśnienia dla ogrzewania",
        "Błąd z presostatu niskiego ciśnienia dla chłodzenia",
        "Błąd z modułu termicznego sprężarki",
        "Błąd softstartera/czujnika kolejności faz",
    ],
    rewATMO_1: [
        null,
        "Zbiorcza flaga stanu ostrzegawczego",
        "Przekroczenie temperatury krytycznej w wymienniku odbioru",
        "Praca źródła szczytowego bez jednostki zewnętrznej",
        "Przekroczona temp. max. odbioru ciepła (ogrzewanie)",
        "Przekroczona temp. min. odbioru chłodu (chłodzenie)",
        "Przekr. temp. max. powrotu jedn.zewn",
        "Przekr. temp. min. wtr. czynnika (chłodzenie)",
        "Zbiorcza flaga stanu awaryjnego",
        "Przekroczenie temperatury tłoczenia",
        "Sygnał z czujnika przepływu",
        "Błąd z presostatu wysokiego ciśnienia",
        "Błąd z presostatu niskiego ciśnienia",
        null,
        null,
        null,
    ],
    rewALLINONE_1: [
        null,
        "Zbiorcza flaga stanu ostrzegawczego",
        "Przekroczenie temperatury krytycznej w wymienniku odbioru",
        "Praca źródła szczytowego bez jednostki zewnętrznej",
        "Przekroczona temp. max. odbioru ciepła (ogrzewanie)",
        "Przekroczona temp. min. odbioru chłodu (chłodzenie)",
        "Przekr. temp. max. powrotu jedn.zewn",
        "Przekr. temp. min. wtr. czynnika (chłodzenie)",
        "Zbiorcza flaga stanu awaryjnego",
        "Przekroczenie temperatury tłoczenia",
        "Sygnał z czujnika przepływu",
        "Błąd z presostatu wysokiego ciśnienia",
        "Błąd z presostatu niskiego ciśnienia",
        null,
        null,
        null,
    ],
    sxb2GEO_1: [ 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        "Niepoprawna praca zaworu 4-drogowego lub wyjście poza kopertę pracy", 
        "Wystąpiła blokada awaryjna funkcji ogrzewania", 
        "Wystąpiła blokada awaryjna funkcji podgrzewania cwu", 
        "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego", 
        "Wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem", 
        null, 
        "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji", 
        null 
    ], 
    sxb2ATMO_1: [ 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Awaria akumulatora" : null,
        "Wystąpiła blokada awaryjna funkcji ogrzewania", 
        "Wystąpiła blokada awaryjna funkcji podgrzewania cwu", 
        "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego", 
        "Wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem", 
        null, 
        "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji", 
        null 
    ], 
    sxb2ALLINONE_1: [ 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Awaria akumulatora" : null,
        "Wystąpiła blokada awaryjna funkcji ogrzewania", 
        "Wystąpiła blokada awaryjna funkcji podgrzewania cwu", 
        "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego", 
        "Wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem", 
        null, 
        "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji", 
        null 
    ], 
    // sio: [
    //     "Załączony przekaźnik pompy cyrkulacyjnej cwu",
    //     "Załączony przekaźnik zaw. 4-dr/zaw. odwracających obiegi",
    //     "Załączony przekaźnik zaworu cwu/pompy obiegowej cwu",
    //     "Załączony przekaźnik pompy obiegowej odbioru",
    //     "Załączony przekaźnik pompy obiegowej źródła",
    //     "Załączony przekaźnik sprężarki",
    //     "Załączony przekaźnik R05",
    //     "Załączony przekaźnik R04",
    //     "Zał. przek. źródła szczytowego",
    //     "Załączony przekaźnik R02",
    //     "Załączony przekaźnik R01",
    //     "Brak sygnału kontr. z modułu term.spr./czujnika przepływu",
    //     "Brak sygnału kontrolnego z sofstartera/czujnika zasilania",
    //     "Brak sygnału z presostatu wysokiego ciśniena",
    //     "Brak sygnału z presostatu niskiego ciśniena dla ogrzewania",
    //     "Brak sygnału z presostatu niskiego ciśniena dla chłodzenia",
    // ],
    sioGEO_1: [
        ["Wyłączony przekaźnik źródła biwalentnego", "Załączony przekaźnik żródła biwalentnego"],
        ["Wyłączony przekaźnik grzałki CWU","Załączony przekaźnik grzałki cwu"],
        ["Wyłączony przekaźnik zaworu cwu/pompy ob. cwu","Załączony przekaźnik zaworu cwu/pompy obiegowej cwu"],
        ["Wyłączony przekaźnik pompy obiegowej odbioru","Załączony przekaźnik pompy obiegowej odbioru"],
        ["Wyłączony przekaźnik pompy obiegowej źródła","Załączony przekaźnik pompy obiegowej źródła"],
        ["Brak sygnału startu dla sprężarki","Sygnał startu dla sprężarki"],
        ["Wyłączony przekaźnik pompy chłodzenia","Załączony przekaźnik pompy chłodzenia"],
        ["Wyłączony przekaźnik free-cooling","Załączony przekaźnik free-cooling"],
        ["Wyłączony przekaźnik pompy cyrkulacyjnej CWU","Załączony przekaźnik pompy cyrkulacyjnej CWU"],
        ["Wyłączony przekaźnik pompy grzania", "Załączony przekaźnik pompy grzania"],
        ["Wyłączony przekaźnik zaworu 4-drogowego / zaworu odwracajacego obiegi","Załączony przekaźnik zaworu 4-drogowego / zaworu odwracajacego obiegi"],
        ["Brak sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu","Obecność sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu "],
        ["Brak sygnału kontrolnego z softstartera/czujnika zasilania"," Obecność sygnału kontrolnego z softstartera/czujnika zasilania"],
        ["Brak sygnału z presostatu wysokiego ciśnienia","Obecność sygnału z presostatu wysokiego ciśnienia"],
        ["Brak sygnału z presostatu niskiego ciśnienia dla ogrzewania","Obecność sygnału z presostatu niskiego ciśnienia dla ogrzewania"],
        ["Brak sygnału z presostatu niskiego ciśnienia dla chłodzenia","Obecność sygnału z presostatu niskiego ciśnienia dla chłodzenia"],
    ],
    sioATMO_1: [
        ["Wyłączony pierwszy stopień grzałek", "Załączony pierwszy stopień grzałek"],
        ["Wyłączony przekaźnik grzałki CWU", "Załączony przekaźnik grzałki cwu"],
        ["Wyłączony przekaźnik zaworu cwu/pompy ob. cwu", "Załączony przekaźnik zaworu cwu/pompy obiegowej cwu"],
        ["Wyłączony przekaźnik pompy obiegowej odbioru", "Załączony przekaźnik pompy obiegowej odbioru"],
        [(version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Wyłączony przekaźnik startu przewornicy" : null, (version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Załączony przekaźnik startu przewornicy" : null],
        ["Brak sygnału startu dla jedn. zewnętrznej", "Sygnał startu dla jedn. zewnętrznej"],
        ["Wyłączony przekaźnik pompy chłodzenia", "Załączony przekaźnik pompy chłodzenia"],
        [null, null],
        ["Wyłączony przekaźnik pompy cyrkulacyjnej CWU", "Załączony przekaźnik pompy cyrkulacyjnej CWU"],
        ["Wyłączony przekaźnik pompy grzania", "Załączony przekaźnik pompy grzania"],
        ["Wyłączony przekaźnik źródła szczytowego (i drugiego stopnia grzałek)", "Załączony przekaźnik źródła szczytowego (i drugiego stopnia grzałek)"],
        ["Brak sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu", "Obecność sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu"],
        [null, null],
        [(version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Moduł UPS nie podłączony" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) ? "Moduł UPS podłączony" : null], 
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Awaria akumulatora" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Akumulator OK" : null], 
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Zasilanie sieciowe" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Brak zasilania sieciowego (zasilanie awaryjne)" : null], 
    ],
    sioALLINONE_1: [
        ["Wyłączony pierwszy stopień grzałek", "Załączony pierwszy stopień grzałek"],
        ["Wyłączony przekaźnik grzałki CWU", "Załączony przekaźnik grzałki cwu"],
        ["Wyłączony przekaźnik zaworu cwu/pompy ob. cwu", "Załączony przekaźnik zaworu cwu/pompy obiegowej cwu"],
        ["Wyłączony przekaźnik pompy obiegowej odbioru", "Załączony przekaźnik pompy obiegowej odbioru"],
        [(version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Wyłączony przekaźnik startu przewornicy" : null, (version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Załączony przekaźnik startu przewornicy" : null], 
        ["Brak sygnału startu dla jedn. zewnętrznej", "Sygnał startu dla jedn. zewnętrznej"],
        ["Wyłączony przekaźnik pompy chłodzenia", "Załączony przekaźnik pompy chłodzenia"],
        [null, null],
        ["Wyłączony przekaźnik pompy cyrkulacyjnej CWU", "Załączony przekaźnik pompy cyrkulacyjnej CWU"],
        ["Wyłączony przekaźnik pompy grzania", "Załączony przekaźnik pompy grzania"],
        ["Wyłączony przekaźnik źródła szczytowego (i drugiego stopnia grzałek)", "Załączony przekaźnik źródła szczytowego (i drugiego stopnia grzałek)"],
        ["Brak sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu", "Obecność sygnału kontrolnego z modułu term.sprężarki/czujnika przepływu"],
        [null, null],
        [(version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Moduł UPS nie podłączony" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) ? "Moduł UPS podłączony" : null], 
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Awaria akumulatora" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Akumulator OK" : null], 
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Zasilanie sieciowe" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Brak zasilania sieciowego (zasilanie awaryjne)" : null], 
    ],
    // ssb: [
    //     "Rozkaz uruchomienia spr. kierowany do modułu wykonawczego",
    //     "Warunki temp. chłodzenia spełnione i chłodzenie załączone",
    //     "Warunki temp. ogrzewania spełnione i ogrzewanie załączone",
    //     "Wymuszony postój sprężarki",
    //     "Temperatura tłoczenia przekroczona",
    //     "Temp.na wyjściu z wym. odbioru wyższa niż setpoint z k. chłodzenia",
    //     "Temp.na wyjśćiu z wym. odbioru niższa niż setpoint z k. grzewczej",
    //     "Temperatura odbioru ciepła za wysoka",
    //     "Temperatura odbioru chłodu za niska",
    //     "Temperatura źródła chłodu za wysoka",
    //     "Tempeatura źródła ciepła za niska",
    //     "Temp.zewn. niższa od progu ogrzewania (zezw. na ogrzewanie)",
    //     "Temp.zewn. wyższa od progu chłodzenia (zezw. na chłodzenie)",
    //     "Temp.wewn.niższa od zad./0 = temp.wewn.wyższa od zadanej",
    //     "Woda w zas. cwu za zimna/0 = woda w zas. cwu podgrzana",
    //     "Warunki temp. podgrz. cwu spełnione i podgrz.cwu załączone"
    // ],
    ssbGEO_1: [
        ["Brak rozkazu uruchomienia sprężarki kierowany do modułu wykonawczego", "Rozkaz uruchomienia sprężarki kierowany do modułu wykonawczego"],
        ["Warunki temperaturowe chłodzenia niespełnione lub chłodzenie wyłączone", "Warunki temperaturowe chłodzenia spełnione i chłodzenie załączone"],
        ["Warunki temperaturowe ogrzewania niespełnione lub ogrzewanie wyłączone", "Warunki temperaturowe ogrzewania spełnione i ogrzewanie załączone"],
        ["Brak wymuszonego postoju sprężarki", "Wymuszony postój sprężarki"],
        ["Temperatura tłoczenia w normie", "Temperatura tłoczenia przekroczona"],
        ["Temperatura na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej chłodzenia (brak zapotrzebowania na chłodzenie)", "Temperatura na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej chłodzenia (zapotrzebowanie na chłodzenie)"],
        ["Temperatura na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej grzewczej (brak zapotrzebowania na grzanie)", "Temperatura na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej grzewczej (zapotrzebowanie na grzanie)"],
        ["Temperatura odbioru ciepła w normie", "Temperatura odbioru ciepła za wysoka"],
        ["Temperatura odbioru chłodu w normie", "Temperatura odbioru chłodu za niska"],
        ["Temperatura źródła chłodu w normie", "Temperatura źródła chłodu za wysoka"],
        ["Temperatura źródła ciepła w nomie", "Temperatura źródła ciepła za niska"],
        ["Temperatura zewnętrzna wyższa od progu ogrzewania (brak zezwolenia na ogrzewanie)", "Temperatura zewnętrzna niższa od progu ogrzewania (zezwolenie na ogrzewanie)"],
        ["Temperatura zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)", "Temperatura zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)"],
        ["Temperatura wewnętrzna wyższa od zadanej", "Temperatura wewnętrzna niższa od zadanej"],
        ["Woda w zasobniku cwu podgrzana", "Woda w zasobniku cwu za zimna"],
        ["Warunki temperaturowe podgrzewania cwu niespełnione lub podgrzewanie cwu wyłączone", "Warunki temperaturowe podgrzewania cwu spełnione i podgrzewanie cwu załączone"]
    ],
    ssbATMO_1: [
        ["Brak rozkazu uruchomienia sprężarki kierowany do modułu wykonawczego", "Rozkaz uruchomienia sprężarki kierowany do modułu wykonawczego"],
        ["Warunki temperaturowe chłodzenia niespełnione lub chłodzenie wyłączone", "Warunki temperaturowe chłodzenia spełnione i chłodzenie załączone"],
        ["Warunki temperaturowe ogrzewania niespełnione lub ogrzewanie wyłączone", "Warunki temperaturowe ogrzewania spełnione i ogrzewanie załączone"],
        ["Brak wymuszonego postoju sprężarki", "Wymuszony postój sprężarki"],
        ["Temperatura tłoczenia w normie", "Temperatura tłoczenia przekroczona"],
        ["Temperatura na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej chłodzenia (brak zapotrzebowania na chłodzenie)", "Temperatura na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej chłodzenia (zapotrzebowanie na chłodzenie)"],
        ["Temperatura na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej grzewczej (brak zapotrzebowania na grzanie)", "Temperatura na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej grzewczej (zapotrzebowanie na grzanie)"],
        ["Temperatura odbioru ciepła w normie", "Temperatura odbioru ciepła za wysoka"],
        ["Temperatura odbioru chłodu w normie", "Temperatura odbioru chłodu za niska"],
        ["Temperatura powrotu jednostki zewnetrznej w normie", " Temperatura powrotu jednostki zewnetrznej za wysoka"],
        ["Temperatura wtrysku czynnika w normie", "Temperatura wtrysku czynnika za niska "],
        ["Temperatura zewnętrzna wyższa od progu ogrzewania (brak zezwolenia na ogrzewanie)", "Temperatura zewnętrzna niższa od progu ogrzewania (zezwolenie na ogrzewanie)"],
        ["Temperatura zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)", "Temperatura zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)"],
        ["Temperatura wewnętrzna wyższa od zadanej", "Temperatura wewnętrzna niższa od zadanej"],
        ["Woda w zasobniku cwu podgrzana", "Woda w zasobniku cwu za zimna"],
        ["Warunki temperaturowe podgrzewania cwu niespełnione lub podgrzewanie cwu wyłączone", "Warunki temperaturowe podgrzewania cwu spełnione i podgrzewanie cwu załączone"]
    ],
    ssbALLINONE_1: [
        ["Brak rozkazu uruchomienia sprężarki kierowany do modułu wykonawczego", "Rozkaz uruchomienia sprężarki kierowany do modułu wykonawczego"],
        ["Warunki temperaturowe chłodzenia niespełnione lub chłodzenie wyłączone", "Warunki temperaturowe chłodzenia spełnione i chłodzenie załączone"],
        ["Warunki temperaturowe ogrzewania niespełnione lub ogrzewanie wyłączone", "Warunki temperaturowe ogrzewania spełnione i ogrzewanie załączone"],
        ["Brak wymuszonego postoju sprężarki", "Wymuszony postój sprężarki"],
        ["Temperatura tłoczenia w normie", "Temperatura tłoczenia przekroczona"],
        ["Temperatura na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej chłodzenia (brak zapotrzebowania na chłodzenie)", "Temperatura na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej chłodzenia (zapotrzebowanie na chłodzenie)"],
        ["Temperatura na wyjściu z wymiennika odbioru wyższa niż setpoint z krzywej grzewczej (brak zapotrzebowania na grzanie)", "Temperatura na wyjściu z wymiennika odbioru niższa niż setpoint z krzywej grzewczej (zapotrzebowanie na grzanie)"],
        ["Temperatura odbioru ciepła w normie", "Temperatura odbioru ciepła za wysoka"],
        ["Temperatura odbioru chłodu w normie", "Temperatura odbioru chłodu za niska"],
        ["Temperatura powrotu jednostki zewnetrznej w normie", " Temperatura powrotu jednostki zewnetrznej za wysoka"],
        ["Temperatura wtrysku czynnika w normie", "Temperatura wtrysku czynnika za niska "],
        ["Temperatura zewnętrzna wyższa od progu ogrzewania (brak zezwolenia na ogrzewanie)", "Temperatura zewnętrzna niższa od progu ogrzewania (zezwolenie na ogrzewanie)"],
        ["Temperatura zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)", "Temperatura zewnętrzna wyższa od progu chłodzenia (zezwolenie na chłodzenie)"],
        ["Temperatura wewnętrzna wyższa od zadanej", "Temperatura wewnętrzna niższa od zadanej"],
        ["Woda w zasobniku cwu podgrzana", "Woda w zasobniku cwu za zimna"],
        ["Warunki temperaturowe podgrzewania cwu niespełnione lub podgrzewanie cwu wyłączone", "Warunki temperaturowe podgrzewania cwu spełnione i podgrzewanie cwu załączone"]
    ],
    // sxb: [
    //     "Wymuszenie pracy z instalacji PV",
    //     "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji",
    //     "Obowiązująca taryfa energii elektrycznej (1=tani prąd)",
    //     "Wystąpiła blok.aw. całego urządzenia z powodu problemów z zas.",
    //     "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego",
    //     "Wystąpiła blokada awaryjna funkcji podgrzewania cwu",
    //     "Wystąpiła blokada awaryjna funkcji ogrzewania",
    //     "Niepoprawna praca zaw. 4-dr.lub praca skrajnie poza kopertą pracy",
    //     "Zbyt niska temp. źródła chłodu – blokada AC",
    //     "Wym. czas. zał. pomp ob. źródła i odbioru po zmianie stanu zaw. 4-DR z grzania na chłodzenie",
    //     "Wym. czas. zał. pomp ob. źródła i odbioru po zmianie stanu zaw. 4-DR z chłodzenia na grzanie",
    //     "Praca sprężarki w funkcji ogrzewania",
    //     "Praca sprężarki w funkcji active-coolingu (AC)",
    //     "Praca sprężarki w funkcji przygotowania cwu",
    //     "Warunki temp.załączenia FC spełnione i FC załączony",
    //     "Warunek temperaturowy załączenia free-coolongu (FC) spełniony"
    // ]
    sxbGEO_1: [
        ["Warunek temperaturowy załączenia FC niespełniony", "Warunek temperaturowy załączenia FC spełniony"],
        ["Warunki temperaturowe załączenia FC niespełnione lub FC wyłączone ", "Warunki temperaturowe załączenia FC spełnione i FC załączone"],
        ["Sprężarka nie pracuje w funkcji przygotowania cwu", "Sprężarka pracuje w funkcji przygotowania cwu"],
        ["Sprężarka nie pracuje w funkcji active-coolingu (AC)", "Sprężarka pracuje w funkcji active-coolingu (AC)"],
        ["Sprężarka nie pracuje w funkcji ogrzewania", "Sprężarka pracuje w funkcji ogrzewania"],
        ["Brak wymuszenia czasowego załączenia pomp obiegowych źródła i odbioru po zmianie stanu zaworu 4-drogowego (chłodzenie -> grzanie)", "Wymuszenie czasowe załączenia pomp obiegowych źródła i odbioru po zmianie stanu zaworu 4-drogowego (chłodzenie -> grzanie)"],
        ["Brak wymuszenia czasowego załączenia pomp obiegowych źródła i odbioru po zmianie stanu zaw. zaworu 4-drogowego (grzanie -> chłodzenie)", "Wymuszenie czasowego załączenia pomp obiegowych źródła i odbioru po zmianie stanu zaw. zaworu 4-drogowego (grzanie -> chłodzenie)"],
        ["Temperatura minimalna źródła chłodu przy AC zbyt niska", "Temperatura minimalna źródła chłodu przy AC w normie"],
        ["Brak sygnału o niepoprawnej pracy zaworu 4-drogowego lub wyjściu poza kopertę pracy", "Niepoprawna praca zaworu 4-drogowego lub wyjście poza kopertę pracy"],
        ["Nie wystąpuje blokada awaryjna funkcji ogrzewania", "Wystąpiła blokada awaryjna funkcji ogrzewania"],
        ["Nie wystąpuje blokada awaryjna funkcji podgrzewania cwu", "Wystąpiła blokada awaryjna funkcji podgrzewania cwu"],
        ["Nie wystąpuje blokada awaryjna funkcji chłodzenia aktywnego", "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego"],
        ["Nie występuje blokada awaryjna całego urządzenia z powodu problemów z zasilaniem", "Wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem"],
        ["Obowiązujaca taryfa energii elektrycznej: drogi prąd", "Obowiązujaca taryfa energii elektrycznej: tani prąd"],
        ["Nie wystąpiuje blokada awaryjna całego urządzenia lub którejś funkcji", "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji"],
        ["Brak wymuszenia z instalacji PV", "Wymuszenie z instalacji PV"]
    ],
    sxbATMO_1: [
        ["Brak sygnału defrostu", "Sygnał defrostu"],
        [null, null],
        ["Sprężarka nie pracuje w funkcji przygotowania cwu", "Sprężarka pracuje w funkcji przygotowania cwu"],
        ["Sprężarka nie pracuje w funkcji active-coolingu (AC)", "Sprężarka pracuje w funkcji active-coolingu (AC)"],
        ["Sprężarka nie pracuje w funkcji ogrzewania", "Sprężarka pracuje w funkcji ogrzewania"],
        [(version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Obecność zasilania sieciowego" : null, (version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Brak zasilania sieciowego" : null], 
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Moduł UPS podłączony" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Moduł UPS podłączony" : null], 
        [null, null],
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Akumulator OK" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Awaria akumulatora" : null], 
        ["Nie wystąpuje blokada awaryjna funkcji ogrzewania", "Wystąpiła blokada awaryjna funkcji ogrzewania"],
        ["Nie wystąpuje blokada awaryjna funkcji podgrzewania cwu", "Wystąpiła blokada awaryjna funkcji podgrzewania cwu"],
        ["Nie wystąpuje blokada awaryjna funkcji chłodzenia aktywnego", "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego"],
        ["Nie występuje blokada awaryjna całego urządzenia z powodu problemów z zasilaniem", "Wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem"],
        ["Obowiązujaca taryfa energii elektrycznej: drogi prąd", "Obowiązujaca taryfa energii elektrycznej: tani prąd"],
        ["Nie wystąpiuje blokada awaryjna całego urządzenia lub którejś funkcji", "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji"],
        ["Brak wymuszenia z instalacji PV", "Wymuszenie z instalacji PV"]
    ],
    sxbALLINONE_1: [
        ["Brak sygnału defrostu", "Sygnał defrostu"],
        [null, null],
        ["Sprężarka nie pracuje w funkcji przygotowania cwu", "Sprężarka pracuje w funkcji przygotowania cwu"],
        ["Sprężarka nie pracuje w funkcji active-coolingu (AC)", "Sprężarka pracuje w funkcji active-coolingu (AC)"],
        ["Sprężarka nie pracuje w funkcji ogrzewania", "Sprężarka pracuje w funkcji ogrzewania"],
        [(version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Obecność zasilania sieciowego" : null, (version_1 > 4) || (version_1 === 4 && version_2 >= 92) ? "Brak zasilania sieciowego" : null], 
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Moduł UPS podłączony" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Moduł UPS podłączony" : null], 
        [null, null],
        [((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Akumulator OK" : null, ((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && SIO_B03 === 1 ? "Awaria akumulatora" : null],
        ["Nie wystąpuje blokada awaryjna funkcji ogrzewania", "Wystąpiła blokada awaryjna funkcji ogrzewania"],
        ["Nie wystąpuje blokada awaryjna funkcji podgrzewania cwu", "Wystąpiła blokada awaryjna funkcji podgrzewania cwu"],
        ["Nie wystąpuje blokada awaryjna funkcji chłodzenia aktywnego", "Wystąpiła blokada awaryjna funkcji chłodzenia aktywnego"],
        ["Nie występuje blokada awaryjna całego urządzenia z powodu problemów z zasilaniem", "Wystąpiła blokada awaryjna całego urządzenia z powodu problemów z zasilaniem"],
        ["Obowiązujaca taryfa energii elektrycznej: drogi prąd", "Obowiązujaca taryfa energii elektrycznej: tani prąd"],
        ["Nie wystąpiuje blokada awaryjna całego urządzenia lub którejś funkcji", "Wystąpiła blokada awaryjna całego urządzenia lub którejś funkcji"],
        ["Brak wymuszenia z instalacji PV", "Wymuszenie z instalacji PV"]
    ]
}

    const msgErr = []
    let binnaryData = data.toString(2).padStart(16, "0")
    for (let i = 15; i >= 0; i--) {
        if(workState) {
            // if (parseInt(binnaryData[15 - i]) === 0) {
            //     if (messages[name][i][0] !== null)
            //     msgErr.push(messages[name][i][0])
            // }
            // else {
            //     if (parseInt(binnaryData[15 - i]) === 1) {
            //         if (messages[name][i][1] !== null)
            //         msgErr.push(messages[name][i][1])
            //     }
            // }
            if (messages[name][i][parseInt(binnaryData[15 - i])] !== null)
                msgErr.push(messages[name][i][parseInt(binnaryData[15 - i])])
        }
        else {
            if (parseInt(binnaryData[15 - i]) === 1) {
                if (messages[name][i] !== null)
                msgErr.push(messages[name][i])
            }
        }
    }

    return msgErr

}

export default functionMoreInfo