import React, { useEffect, useState } from 'react'
import { Typography, Grid, IconButton, Dialog, DialogContent  } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { FiberManualRecord } from '@material-ui/icons'
import { registerDictionary } from '../../../../data/dataDictionary'
import { CarouselInputMulti } from '../../../../components'
import { dataRange } from '../../../../data/dataRange'
import { ItemMapping } from '../../../../components'
import { HeatingCircuitsAdditional } from './HeatingCircuitsAdditional' 

const Heatingstate = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const dictionary = registerDictionary().periphery.heatingCircuits
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: "" })

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Obiegi grzewcze</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h2">Obieg główny[0]</Typography>
                    <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{itemValue.main.SBF_HTS === 1 && itemValue.main.SBF_HTX === 1 && itemValue.main.SXF_TOE === 1 && itemValue.main.SBF_OOF === 1 ? "Załączony" : "Wyłączony"}</Typography> 
                        </Grid>
                        <Grid item xs={3} className={classes.textRight}>
                            <FiberManualRecord style={{ color: itemValue.main.SBF_HTS === 1 && itemValue.main.SBF_HTX === 1 && itemValue.main.SXF_TOE === 1 && itemValue.main.SBF_OOF === 1 ? 'green' : 'gray' }} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.main.map, handleSwitchValue, handleClickOpen, dictionary.main, classes, filteredItems(itemValue.main.map), 'map')}
                    </Grid>
                    {/*<Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.TSM10}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                            <Typography variant="body2">{itemValue.main.map.TSM10.toFixed(1)}{dataRange()['TSM10'].symbol}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.SPHT_ZAD}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }} onClick={() => handleClickOpen(itemValue.main.map.SPHT_ZAD, 'SPHT_ZAD', "Ustaw", "", dataRange()['SPHT_ZAD'].min, dataRange()['SPHT_ZAD'].max, itemValue.main.map.SPHT_ZAD, 'main')}>
                            <Typography variant="body2" className={"carousel-input-click"}>{itemValue.main.map.SPHT_ZAD}{dataRange()['SPHT_ZAD'].symbol}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.SX6}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                            <Typography variant="body2">{itemValue.main.map.SX6.toFixed(1)}{dataRange().SX6.symbol}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.CUN_HCU}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }} onClick={() => handleClickOpen(itemValue.main.map.CUN_HCU, 'CUN_HCU', "Ustaw", "", dataRange()['CUN_HCU'].min, dataRange()['CUN_HCU'].max, itemValue.main.map.CUN_HCU, 'main')}>
                            <Typography variant="body2" className={"carousel-input-click"}>{itemValue.main.map.CUN_HCU}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.SX3_HG}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }} onClick={() => handleClickOpen(itemValue.main.map.SX3_HG, 'SX3_HG', "Ustaw", "", dataRange()['SX3_HG'].min, dataRange()['SX3_HG'].max, itemValue.main.map.SX3_HG, 'main')}>
                            <Typography variant="body2" className={"carousel-input-click"}>{itemValue.main.map.SX3_HG}{dataRange().SX3_HG.symbol}</Typography>
                        </Grid>
                    </Grid>
                    
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.TB1}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                            <Typography variant="body2">{itemValue.main.map.TB1.toFixed(1)}{dataRange().TB1.symbol}</Typography>
                        </Grid>
                    </Grid>*/}
                    <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{dictionary.main.SWEXT_BUF}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight}>
                            <Typography variant="body2">{itemValue.main.SWEXT_BUF === 1 && itemValue.main.SXB_PV === 0 ? itemValue.main.TSM17.toFixed(1) : itemValue.main.TSM13}{dataRange()['TSM17'].symbol}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                        <Grid item xs={9}>
                            <Typography variant="body2">{`${dictionary.main.SIO_R02}`}{itemValue.main.SIO_R02 === 1 ? ": Praca" : ": Stop"}</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textRight}>
                            <FiberManualRecord style={{ color: itemValue.main.SIO_R02 === 1 ?  'green' : 'gray' }} />
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item xs={6}>
                    <Typography variant="h2">Obieg dodatkowy[1] (z mieszaczem)</Typography>
                    <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                        <Grid item xs={10}>
                            <Typography variant="body2">{itemValue.additional.OB1_XZM_TRANSM === 0 ? "Aktywny [MB=5]" : "Brak"}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.textRight}>
                            <FiberManualRecord style={{ color: itemValue.additional.OB1_XZM_TRANSM === 0 ? 'green' : 'gray' }} />
                        </Grid>
                    </Grid>
                    {itemValue.additional.OB1_XZM_TRANSM === 0 ? 
                        <Grid item>
                            <Grid item>
                                {ItemMapping(itemValue.additional.map, handleSwitchValue, handleClickOpen, dictionary.additional, classes, filteredItems(itemValue.additional.map), 'map')}
                            </Grid>
                            <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                                <Grid item xs={10}>
                                    <Typography variant="body2">{`${dictionary.additional.OB1_PCO}`}{itemValue.main.OB1_PCO === 100 ? ": Praca" : ": Stop"}</Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.textRight}>
                                    <FiberManualRecord style={{ color: itemValue.main.OB1_PCO === 100 ? 'green' : 'gray' }} /> 
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }
                </Grid>*/} 
                {Object.values(itemValue.additional.map).every((v) => v === 1) ?
                    <Typography variant="body2" className='vertical-centering'>Brak obiegów dodatkowych.</Typography>
                    : settings.additional.map ? Object.entries(itemValue.additional.map).map((entry, index) => {
                        if (entry[1] === 0) {
                            return (
                                <HeatingCircuitsAdditional key={entry[0]} settings={settings} num={parseInt(entry[0].slice(2, 4)) > 9 ? parseInt(entry[0].slice(2, 4)) : parseInt(entry[0].slice(2, 3))} handleSwitchValue={handleSwitchValue} handleClickOpen={handleClickOpen}/>
                            )
                        } else {
                            return null
                            }
                    }) : <Typography variant="body2">Brak obiegów dodatkowych.</Typography>
                }
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Heatingstate