const validationAddDevice = (status) => {

    switch (status) {
        case 420:
            return "Urządzenie z takim numerem seryjnym już istnieje."

        case 470:
            return "Nieprawidłowy adres e-mail."

        case 471:
            return "Nieprawidłowy numer telefonu."

        case 472:
            return "Nieznany typ urządzenia."
            
        case 500:
            return "Błąd serwera, skontaktuj się z administratorem."

        case 503:
            return "Uruchomiony updater. Proszę spróbować ponownie później."

        default:
            return "Nieznany błąd, skontaktuj się z administratorem."
    }
}

export const validation = {
    validationAddDevice
}