import React, { useEffect, useState, useReducer } from 'react'
import { Typography, Grid, Switch, Dialog, DialogContent, IconButton, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { registerDictionary } from '../../../../../../data/dataDictionary'
import { dataRange } from '../../../../../../data/dataRange'
import CloseIcon from '@material-ui/icons/Close'
import { CarouselInputMulti } from '../../../../../../components'
import { ItemMapping } from '../../../../../../components'
import { mainStyleClasses } from '../../../../../../theme'

const StepSecond = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const dictionary = registerDictionary().periphery.peakSource.step_2
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: "" })

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

    // const switchControl = (entry, item) => {
    //     switch (dataRange()[entry[0]].controller) {
    //         case 1:
    //             return (
    //                 <React.Fragment>
    //                     <Grid item xs={2} className={classes.textRight}>
    //                         <Switch
    //                             checked={parseInt(entry[1]) === 0 ? false : true}
    //                             onChange={handleSwitchValue(entry[0])}
    //                             color="primary"
    //                             name={entry[0]}
    //                             inputProps={{ 'aria-label': 'primary checkbox' }}
    //                         />
    //                     </Grid>
    //                 </React.Fragment>
    //             )
    //         case 2:
    //             let minValue = typeof (dataRange()[entry[0]].min) === "string" ? parseFloat(item[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
    //             let maxValue = typeof (dataRange()[entry[0]].max) === "string" ? parseFloat(item[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)
    //             return (
    //                 <Grid item xs={2} className={classes.textRight} onClick={() => handleClickOpen(entry[1], entry[0], dict[entry[0]], minValue, maxValue, dataRange()[entry[0]].step, entry[0], entry[1])}>
    //                     <Typography variant="body2" className={"carousel-input-click"}>{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]} {dataRange()[entry[0]].symbol}</Typography>
    //                 </Grid>
    //             )
    //         default:
    //             break;
    //     }
    // }
    
    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item>
                    <Typography variant="h2">{dictionary.title}</Typography>
                </Grid>
                <Grid item>
                    {ItemMapping(itemValue.map, handleSwitchValue, handleClickOpen, dictionary.data, classes, filteredItems(itemValue.map), 'map')}
                </Grid>
            </Grid>
            {/*{step_1 ? filteredItems.map(entry => {
                if (entry[1] !== undefined) {
                    return (
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px', margin: dict[entry[0]].length > 54 ? '5px 0 5px 0' : null }} key={entry[0]}>
                            <Grid item xs={10}><Typography variant="body2">{dict[entry[0]]}</Typography></Grid>

                            {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                switchControl(entry, step_1) : <Grid item xs={2} className={classes.textRight}>
                                {dataRange()[entry[0]].controller === 4 ? entry[1] === 1 ? 
                                    <FiberManualRecord style={{ color: "green" }} /> : <FiberManualRecord style={{ color: "red" }} />: 
                                    <Typography variant="body2">{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1] < 0 ? 0 : entry[1]}  {dataRange()[entry[0]].symbol}</Typography>}
                                </Grid>}
                        </Grid>
                    )
                } else {
                    return null
                }
            }) : null
            }*/}
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default StepSecond