import React, { useState, useEffect } from 'react'
import { Grid, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'

import { TileBox, DetailsBox } from './components'
// import { dataRange } from '../../data/dataRange'

const Home = ({ home, setPublish, data }) => {
    const classes = mainStyleClasses()
    
    const [open, setOpen] = useState({status: false, element: "", communicationColor: 0})
    const deviceName = sessionStorage.getItem('deviceName')

  const handleClickOpen = (element, color) => {
    setOpen({status: true, element: element, communicationColor: color})
  }

  const handleClose = () => {
    // setOpen({status: false})
    setOpen({...open, status: false, communicationColor: 0})
  }

//   useEffect(() => {
//     window.history.pushState(null, document.title, window.location.href);
//     window.addEventListener('popstate', function (event){
//         window.history.pushState(null, document.title,  window.location.href);
//     });
//   }, []);

    return (
        <Grid container>
            <Grid item md={4} sm={6} xs={12}>
                <TileBox item={home.internalTempControl} colorBox={classes.backgroundRed} name={"internalTempControl"} dialogOpen={handleClickOpen} />
                <TileBox item={home.heatingCoolingControlCrot} colorBox={classes.backgroundWhite} name={"heatingCoolingControlCrot"} dialogOpen={handleClickOpen} />
                <TileBox item={home.heatingControlCWU} colorBox={classes.backgroundGreen} name={"heatingControlCWU"} dialogOpen={handleClickOpen} />
                <TileBox item={home.devicesPeriphery} data={data} colorBox={classes.backgroundWhite} name={"devicesPeriphery"} communicationColor={2} dialogOpen={handleClickOpen} />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <TileBox item={home.heatingCurveControl} colorBox={classes.backgroundWhite} name={"heatingCurveControl"} dialogOpen={handleClickOpen} />
                <TileBox item={home.coolingCurveControl} colorBox={classes.backgroundWhite} name={"coolingCurveControl"} dialogOpen={handleClickOpen} />
                <TileBox item={home.permits} colorBox={classes.backgroundWhite} name={"permits"} communicationColor={2} dialogOpen={handleClickOpen} />
                <TileBox item={home.errorWarnings} colorBox={classes.backgroundWhite} name={"errorWarnings"} communicationColor={1} dialogOpen={handleClickOpen} />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <TileBox item={home.heatReceptionControlMax} colorBox={classes.backgroundWhite} name={"heatReceptionControlMax"} dialogOpen={handleClickOpen} />
                {deviceName === 'GEO_1' ?
                <TileBox item={home.acfcControll} colorBox={classes.backgroundWhite} name={"acfcControll"} dialogOpen={handleClickOpen} />
                : <TileBox item={home.outdoorTempControl} colorBox={classes.backgroundWhite} name={"outdoorTempControl"} dialogOpen={handleClickOpen} />
                }
                <TileBox item={home.coldReceptionControl} colorBox={classes.backgroundWhite} name={"coldReceptionControl"} dialogOpen={handleClickOpen} />
                
                <TileBox item={home.sensorEjaculationControlMax} colorBox={classes.backgroundWhite} name={"sensorEjaculationControlMax"} dialogOpen={handleClickOpen} />
                <TileBox item={home.othersTemp} colorBox={classes.backgroundWhite} name={"othersTemp"} dialogOpen={handleClickOpen} />
                {deviceName === 'GEO_1' ?
                    <TileBox item={home.coolignSource} colorBox={classes.backgroundWhite} name={"coolignSource"} dialogOpen={handleClickOpen} />
                    : <TileBox item={home.refrigerationSystem} colorBox={classes.backgroundWhite} name={"refrigerationSystem"} dialogOpen={handleClickOpen} />
                }
                <TileBox item={home.modes} colorBox={classes.backgroundWhite} name={"modes"} communicationColor={2} dialogOpen={handleClickOpen} modes={true} />
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{padding: "10px 25px 25px"}}>
                <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{zIndex: "99", position: "absolute", top: "10px", right: "15px"}}>
                    <CloseIcon />
                </IconButton>
                    <DetailsBox item={open.element === "" ? null: home[open.element]} data={data} name={open.element} communicationColor={open.communicationColor} setPublish={setPublish} />
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default Home