import fetchRefreshToken from './fetchRefreshToken'

const fetchCustomer = async (token, installation_id) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installations/${installation_id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${newToken}`
        }
      }).then(response => response.json())
        .then(res => fetchCustomerInfo(newToken, res.customer_id))
      return res
    })
    
  
  return response
}


const fetchCustomerInfo = async (token, customer_id) => {
  const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers/${customer_id}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
  .then(response => response.json())

  return response
}

export default fetchCustomer