import React, { useReducer, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TitleRow } from '../../components'
import { ControlFunctionItem, ControlFunctionItemRead } from './components'
import { dataRangeDictionary } from '../../dataFilter/dataRangeDictionary'

const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        if (state[action.type] === 0) {
            return { ...state, [action.type]: 1 };
        } else if (state[action.type] === 1) {
            return { ...state, [action.type]: 0 };
        }
    }
};

function cfsTableRender(controller, cfs, setPublish) {
    return (
        Object.entries(controller).map(entry => {
            if(dataRangeDictionary.cfs[entry[0]].RW === 0)
            return <ControlFunctionItemRead key={entry[0]} cfs={cfs} setPublish={setPublish} stamp={entry[0]}/>
            else
            return <ControlFunctionItem key={entry[0]} cfs={cfs} setPublish={setPublish} stamp={entry[0]}/>
        }
        )
    )
}

const ControlFunctionState = ({ cfs, setPublish }) => {

    const [controller, setFunctionState] = useReducer(switchOption, cfs);

    useEffect(() => {
        setFunctionState({ payload: cfs, case: "UPDATE_FROM_BROKER" })
    }, [cfs])


    const cfsTable = cfsTableRender(controller, cfs, setPublish);


    return (
        <Container>
            <TitleRow title="Stany załączenia funkcji sterujących" />
            <Row className="functionState">
                <Col>
                    {cfsTable}
                </Col>
            </Row>
        </Container>
    );
}

export default ControlFunctionState;