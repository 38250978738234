import fetchRefreshToken from './fetchRefreshToken'

const fetchAccess = async () => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/basic_telemetry_creds`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${newToken}`
        }
      }).then(response => response.json())
      return res
    })
    
  
  return response
}

export default fetchAccess