import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'
import formatString from "format-string-by-pattern"

/*const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
  
    return formatString('9', onlyNumbers)
}*/

const maxValue = max => value =>
    isNaN(value) || value <= max ? undefined : `Wartość powinna wynosić nie wiecej niż ${max}`
    
const mustBeNumber = value => (isNaN(value) ? 'Wartość powinna być liczbą' : undefined)

const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^(\d, \\.)]/g, '')

    return onlyNumbers
}


const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const FirstFillingEON = ({ required, setValues, glycolType }) => {

    useEffect(() => {
        setValues({type: "childName", value: "FirstFillingEON"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Pierwsze napełnienie instalacji</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Napełnienie instalacji</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel
                                    label="Wykonano"
                                    control={
                                        <Field
                                            name="filling"
                                            component={Radio}
                                            type="radio"
                                            value="Tak"
                                            color="primary"
                                            validate={required}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Nie wykonano"
                                    control={
                                        <Field
                                            name="filling"
                                            component={Radio}
                                            type="radio"
                                            value="Nie"
                                            color="primary"
                                            validate={required}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl><br />
                        <Error name="filling" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Wartość ciśnienia w układzie</Typography>
                        <Field name="pressure"
                            component={TextField}
                            type="text"
                            label="Wskazanie manometru w barach"
                            fullWidth
                            validate={composeValidators(required, mustBeNumber)}
                            parse={e => formatOnlyNumbers(e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Ocena szczelności połączeń hydraulicznych</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel
                                    label="Szczelność potwierdzona"
                                    control={
                                        <Field
                                            name="tightness"
                                            component={Radio}
                                            type="radio"
                                            value="Tak"
                                            color="primary"
                                            validate={required}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Brak szczelności"
                                    control={
                                        <Field
                                            name="tightness"
                                            component={Radio}
                                            type="radio"
                                            value="Nie"
                                            color="primary"
                                            validate={required}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl><br />
                        <Error name="tightness" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Ilość zużytego koncentratu</Typography>
                        <Field name="concentrateUsed"
                            component={TextField}
                            type="text"
                            label="Wartość w litrach"
                            fullWidth
                            validate={composeValidators(required, mustBeNumber)}
                            parse={e => formatOnlyNumbers(e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Ilość pozostawionego koncentratu u Klienta</Typography>
                        <Field name="concentrateLeft"
                            component={TextField}
                            type="text"
                            label="Wartość w litrach"
                            fullWidth
                            validate={composeValidators(required, mustBeNumber)}
                            parse={e => formatOnlyNumbers(e)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Ilość roztworu wtłoczonego do instalacji</Typography>
                        <Field name="solutionUsed"
                            component={TextField}
                            type="text"
                            label="Wartość w litrach"
                            fullWidth
                            validate={composeValidators(required, mustBeNumber)}
                            parse={e => formatOnlyNumbers(e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Ilość roztworu pozostawionego u Klienta</Typography>
                        <Field name="solutionLeft"
                            component={TextField}
                            type="text"
                            label="Wartość w litrach"
                            fullWidth
                            validate={composeValidators(required, mustBeNumber)}
                            parse={e => formatOnlyNumbers(e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>
                            Typ koncentratu glikolu<br />
                            <span style={{color: "Grey", fontSize: "80%"}}>Jeżeli inny należy wpisać typ</span>
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel
                                    label="Propylenowy"
                                    control={
                                        <Field
                                            name="glycolType"
                                            component={Radio}
                                            type="radio"
                                            value="Propylenowy"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => setValues({type: "glycolType", value: false})}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Etylenowy"
                                    control={
                                        <Field
                                            name="glycolType"
                                            component={Radio}
                                            type="radio"
                                            value="Etylenowy"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => setValues({type: "glycolType", value: false})}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Inny"
                                    control={
                                        <Field
                                            name="glycolType"
                                            component={Radio}
                                            type="radio"
                                            value="Inny"
                                            color="primary"
                                            validate={required}
                                            onClick={ () => setValues({type: "glycolType", value: true})}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl><br />
                        <Error name="inspection" />
                    </Grid>
                    {glycolType ? (<Grid item xs={12}>
                        <Field name="glycolTypeValue"
                            component={TextField}
                            type="text"
                            label="Typ koncentratu glikolu"
                            fullWidth
                            validate={required}
                        />
                    </Grid>) : null}
                    <Grid item xs={12}>
                        <Typography variant='h3'>Aplikacja inhibitora korozji</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row>
                                <FormControlLabel
                                    label="Wykonano"
                                    control={
                                        <Field
                                            name="corrosion"
                                            component={Radio}
                                            type="radio"
                                            value="Tak"
                                            color="primary"
                                            validate={required}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Nie wykonano"
                                    control={
                                        <Field
                                            name="corrosion"
                                            component={Radio}
                                            type="radio"
                                            value="Nie"
                                            color="primary"
                                            validate={required}
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl><br />
                        <Error name="corrosion" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FirstFillingEON;