import fetchRefreshToken from './fetchRefreshToken'

const fetchServiceNotesParms = async (payload) => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/service_notes/search`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      return res
    })
    
    return response
  }


  export default fetchServiceNotesParms