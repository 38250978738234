import fetchRefreshToken from './fetchRefreshToken'

const fetchCustomerUpdate = async (payload) => {
    const response = await fetchRefreshToken().then(async newToken => {
      
      console.log(JSON.stringify(payload))
        const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers`, {
          method: "PUT",
          headers: {
            "Authorization": `Bearer ${newToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }).then(response => response.json())
        
        return res
      })
    return response
  }
  
  export default fetchCustomerUpdate