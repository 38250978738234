import React, { useEffect, useState } from 'react'
import { Typography, Grid, IconButton, Dialog, DialogContent  } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import { FiberManualRecord } from '@material-ui/icons'
import { registerDictionary } from '../../data/dataDictionary'
import { CarouselInputMulti, ItemMapping } from '../../components'
import { dataRange } from '../../data/dataRange'

const EmergencyStop = ({ settings, setPublish, deviceName }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const dictionary = registerDictionary().emergencyStop
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0 })
    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val})
    }

    const handleClose = () => {
        setItemValue({...itemValue, 
                        settings: {
                            ...itemValue.settings, 
                            [open.name]: open.val
                        }
                    })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (name, val) => {
        setOpen({
            ...open,
            val: val
        })
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);
    
    return (
        <React.Fragment>
            <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
                <Grid container style={{ padding: '0 24px' }}>
                    <Grid item xs={12}>
                        <Typography variant="h2">Ustawienia i stan algorytmu wyłączeń awaryjnych</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item>
                            <Typography variant="h2">{dictionary.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.status, undefined, handleClickOpen, dictionary.data, classes, filteredItems(itemValue.status))}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item>
                            <Typography variant="h2">{dictionary.title2}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.settings, undefined, handleClickOpen, dictionary.data2, classes, filteredItems(itemValue.settings))}
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent style={{ padding: "9px 25px 25px" }}>
                        <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "9px", right: "15px" }}>
                            <CloseIcon />
                        </IconButton>
                        <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                    </DialogContent>
                </Dialog>
            </div>
        </React.Fragment>
    )
}

export default EmergencyStop