import React, { useState, useEffect } from 'react'
import { mainStyleClasses } from '../../theme'
import { AppBar } from '@material-ui/core'
import { Extender } from './Extender'
import { TabPanel, Tab, Tabs } from '../../components'

const Extenders = ({ settings, setPublish, setPublishDevice }) => {
    const classes = mainStyleClasses()
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <AppBar position="static" style={{ boxShadow: "none" }}>
                <Tabs value={value} onChange={handleChange}>
                    {settings ? Object.entries(settings).map((entry, index) => {
                        if (entry[1] !== 0) {
                            return (
                                <Tab key={entry[0]} style={{ maxWidth: '12.5%', minWidth: '10%', lineHeight: '16px' }} label={`Extender ${entry[0].slice(-1)}`} />
                            )
                        } else {
                            return null
                            }
                        }) : null
                    }
                </Tabs> 
            </AppBar>
            {settings ? Object.entries(settings).map((entry, index) => {
                if (entry[1] !== 0) {
                    return (
                        <TabPanel key={entry[0]} value={value} index={index}>
                            <Extender settings={settings[entry[0]]} setPublish={setPublish} setPublishDevice={setPublishDevice} />
                        </TabPanel>
                    )
                } else {
                    return null
                    }
                }) : null
            }
        </div>
    )
}

export default Extenders
