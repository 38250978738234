import React, { useEffect, useState, useReducer } from 'react'
import { Typography, Grid, Table, Paper, TableBody, TableContainer, TextField, Fab, Button } from '@material-ui/core'
import AddIcon from "@material-ui/icons/Add"
import Tooltip from "@material-ui/core/Tooltip"
import CircularProgress from '@material-ui/core/CircularProgress'
import SoftwareList from './SoftwareList'
import HardwareList from './HardwareList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import fetchDeviceUpdate from '../../../../data/fetchDeviceUpdate'

const DeviceDetails = ({ details, perm, colorTelemetryUpdate, role, partner, setBaseLogin, token }) => {
    const [deviceDetails, setDeviceDetails] = useState({})
    const [changeData, setChangeData] = useState({ change: null, data: { agent_pass: "", bro_pass: "", device_sn: "", device_sn2: "" } })
    const [hardwareVers, setHardwareVers] = useState([{ rowId: 0, name: "", serial: "" }])
    const [fieldError, setFieldError] = useState({})
    const [errorDevice, setErrorDevice] = useState({ pump: null, pv: null })
    const [newDevice, setNewDevice] = useState({ deviceType: '', deviceSn: '', distribution: '', partnerId: '' })

    // if (details) {
    //     setNewDevice({deviceType: details.device_type, deviceSn: details.device_sn, distribution: details.distribution, partnerId: details.partner_id})
    // }
    // const handleNewDevice = (e) => {
    //     if (e.target.name === "deviceType" || e.target.name === "deviceSn")
    //         setNewDevice({ type: e.target.name, payload: e.target.value.toUpperCase() })
    //     else {
    //         setNewDevice({ type: e.target.name, payload: parseInt(e.target.value) })
    //     }
    // }

    const addHardwareVers = (e, i) => {
        let arrHelp = hardwareVers
        arrHelp[i][e.target.name] = e.target.value
        setHardwareVers(arrHelp)
        setFieldError({ ...fieldError, [`rowId${i}${e.target.name}`]: { status: false, msg: ""}})
    }

    const editHardwareVers = (e, i) => {
        let arrHelp = JSON.parse(deviceDetails.hardware_version)
        arrHelp[i][e.target.name] = e.target.value
        arrHelp = JSON.stringify(arrHelp)
        setDeviceDetails({...deviceDetails, hardware_version: arrHelp})
        setFieldError({ ...fieldError, [`rowId${i}${e.target.name}`]: { status: false, msg: ""}})
    }

    const deleteHardwareVers = (i) => {
        let newDeviceDetails = Object.fromEntries(Object.entries(deviceDetails).filter(([key,value])=>['key', 'id', 'agent_pass', 'bro_pass', 'device_sn', 'device_outside.sn', 'device_outside'].includes(key)))
        let arrHelp = JSON.parse(deviceDetails.hardware_version)
        arrHelp = arrHelp.filter((obj) => obj.rowId !== i)
        arrHelp.map((item, i) => item.rowId = i)
        arrHelp = JSON.stringify(arrHelp)
        newDeviceDetails = {...newDeviceDetails, hardware_version: arrHelp}
        setDeviceDetails({...deviceDetails, hardware_version: arrHelp})
        fetchDeviceUpdate(newDeviceDetails).then(response => console.log(response))
    }

    const software = () => {
        if (details && details.installed_sw_packages) {
            let softwareObject = JSON.parse(details.installed_sw_packages)
            return softwareObject.map(item => <SoftwareList key={item.name} software={item} />)
        }
    }

    const hardware = () => {
        if (deviceDetails) {
            let hardwareObject = JSON.parse(deviceDetails.hardware_version)
            return hardwareObject.map((item, i) => <HardwareList key={item.name} hardware={item} changeData={changeData} deviceDetails={deviceDetails} handleChangeDeviceData={handleChangeDeviceData} sendDeviceData={sendDeviceData} handleChange={handleChange} addHardwareVers={addHardwareVers} i={i} fieldError={fieldError} hardwareVers={hardwareVers} deleteHardwareVersion={deleteHardwareVers} editHardwareVers={editHardwareVers}/>)
        }
    }
    console.log(deviceDetails ? deviceDetails.hardware_version : null)
    const handleHardwareVers = () => {
        let errorInfo = false
        for (let index = 0; index < hardwareVers.length; index++) {
            
            if(hardwareVers[index].serial === "" || hardwareVers[index].name === ""){
                errorInfo = true
                if(hardwareVers[index].serial === "")
                setFieldError({ ...fieldError, [`rowId${index}serial`]: { status: true, msg: "To pole jest wymagane"}})
                else if (hardwareVers[index].name === "")
                setFieldError({ ...fieldError, [`rowId${index}name`]: { status: true, msg: "To pole jest wymagane"}})
            }
        }

        if(!errorInfo)
        setHardwareVers([...hardwareVers, { rowId: hardwareVers.length, name: "", serial: ""}])
    }
    
    useEffect(() => {
        if (details) {
            setChangeData({
                change: null, data: {
                    agent_pass: details.agent_pass ? details.agent_pass : "",
                    bro_pass: details.bro_pass ? details.bro_pass : "",
                    device_sn: details.device_sn ? details.device_sn : "",
                    device_sn2: details.device_sn2 ? details.device_sn2 : "",
                }
            })
            setNewDevice({
                deviceType: details.device_type,
                deviceSn: details.device_sn,
                distribution: details.distribution_id,
                partnerId: details.partner_id
            })
        }
        setDeviceDetails(details)

    }, [details])

    const handleChange = (value) => {
        if (changeData.change === value) {
            setChangeData({
                ...changeData,
                change: null
            })
            setDeviceDetails(details)
        } else {
            setChangeData({
                ...changeData,
                change: value
            })
        }
    }

    const handleChangeDeviceData = (e) => {
        setChangeData({
            ...changeData,
            data: {
                ...changeData.data,
                [e.target.name]: e.target.value
            }
        })
    }
    
    const sendDeviceData = (val) => {
        let newDeviceDetails = Object.fromEntries(Object.entries(deviceDetails).filter(([key,value])=>['key', 'id', 'agent_pass', 'bro_pass', 'device_sn', 'device_outside.sn', 'device_outside'].includes(key)))

        if (val === 'hardware_edit') {
            newDeviceDetails = { ...newDeviceDetails, 'hardware_version': deviceDetails.hardware_version }
            setDeviceDetails(newDeviceDetails)
            setChangeData({ ...changeData, change: null })
        } else if (val === 'hardware_version') {
            const payload = JSON.parse(deviceDetails.hardware_version).concat(hardwareVers)
            payload.map((item, i) => item.rowId = i)
            newDeviceDetails = { ...newDeviceDetails, [val]: JSON.stringify(payload) }
            setDeviceDetails(newDeviceDetails)
            setHardwareVers([{ rowId: 0, name: "", serial: "" }])
            setChangeData({ ...changeData, change: null })
        } else {
            newDeviceDetails = { ...newDeviceDetails, [val]: changeData.data[val] }
            setDeviceDetails(newDeviceDetails)
            setChangeData({
                ...changeData,
                change: null
            })
        }
        fetchDeviceUpdate(newDeviceDetails).then(response => console.log(response))
    }

    return (
        <React.Fragment>
            {details ? (<React.Fragment>
                <Grid container direction="row" style={{ padding: "0 0 15px", margin: "0 0 15px" }} className={colorTelemetryUpdate}>
                    <Grid item xs={8}><Typography variant="h2">Szczegóły urządzenia o id: {details.id}</Typography></Grid>
                    <Grid item xs={4}><Typography variant="h3">{partner && partner.name? partner.name : "Brak informacji o partnerze"}</Typography></Grid>
                    <Grid item xs={4}><Typography variant="h6">Ostatnio online:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="h6">{details.last_telemetry_update === null || parseInt(details.last_telemetry_update.slice(0, 4)) < 2019 ? "NIGDY" : `${details.last_telemetry_update.slice(0, 19).replace("T", " ")}`}</Typography></Grid>
                </Grid>

                {role && role === "admin" ? <React.Fragment>

                    {changeData && changeData.change === "agent_pass" ? (
                        <React.Fragment>
                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <TextField name="agent_pass" label="Hasło agenta" type="text" value={changeData ? changeData.data.agent_pass : ""}
                                        onChange={(e) => handleChangeDeviceData(e)} />
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={4}>
                                    <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendDeviceData("agent_pass") }} /><br />
                                </Grid>
                                <Grid item xs={4}>
                                    <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("agent_pass")} />
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <p style={{ color: "#d32f2f", lineHeight: "1" }}>Uwaga! Zmiana hasła agenta spowoduje,
                                    że agent nie będzie mógł się połączyć dopóki hasło nie zostanie zmienione również w jego pliku konfiguracyjnym.</p>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <Grid container direction="row">
                                    <Grid item xs={4}><Typography variant="body1">Hasło agenta:</Typography></Grid>
                                    <Grid item xs={6}><Typography variant="body1">{deviceDetails ? deviceDetails.agent_pass : ""}</Typography></Grid>
                                    <Grid item xs={2}><FontAwesomeIcon onClick={() => handleChange("agent_pass")} style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" /></Grid>
                                </Grid>
                            </React.Fragment>
                        )}




                    {changeData && changeData.change === "bro_pass" ? (
                        <React.Fragment>
                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <TextField name="bro_pass" label="Hasło brokera" type="text" value={changeData ? changeData.data.bro_pass : ""}
                                        onChange={(e) => handleChangeDeviceData(e)} />
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={4}>
                                    <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendDeviceData("bro_pass") }} /><br />
                                </Grid>
                                <Grid item xs={4}>
                                    <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("bro_pass")} />
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <p style={{ color: "#d32f2f", lineHeight: "1" }}>Uwaga! Zmiana hasła brokera spowoduje, że trzeba będzie ponownie zalogować się do aplikacji mobilnej.</p>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <Grid container direction="row">
                                    <Grid item xs={4}><Typography variant="body1">Hasło brokera:</Typography></Grid>
                                    <Grid item xs={6}><Typography variant="body1">{deviceDetails ? deviceDetails.bro_pass : ""}</Typography></Grid>
                                    <Grid item xs={2}><FontAwesomeIcon onClick={() => handleChange("bro_pass")} style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" /></Grid>
                                </Grid>
                            </React.Fragment>
                        )}

                </React.Fragment> : null}
                <Grid container direction="row">
                    <Grid item xs={4}><Typography variant="body1">Certyfikat:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="body1">{details.certificate}</Typography></Grid>
                </Grid>

                {role && role === "admin"?
                    changeData && changeData.change === "device_sn" ? (
                    <React.Fragment>
                        <Grid container direction="row">
                            <Grid item xs={8}>

                                <TextField name="device_sn" label="Nr seryjny" type="text" value={changeData ? changeData.data.device_sn : ""}
                                    onChange={(e) => handleChangeDeviceData(e)} />

                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendDeviceData("device_sn") }} /><br />
                            </Grid>
                            <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("device_sn")} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <Grid container direction="row">
                                <Grid item xs={4}><Typography variant="body1">Nr seryjny:</Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1">{deviceDetails ? deviceDetails.device_sn : ""}</Typography></Grid>
                                <Grid item xs={2}><FontAwesomeIcon onClick={() => handleChange("device_sn")} style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" /></Grid>
                            </Grid>
                        </React.Fragment>
                    ) :
                    <Grid container direction="row">
                    <Grid item xs={4}><Typography variant="body1">Nr seryjny:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="body1">{deviceDetails ? deviceDetails.device_sn : ""}</Typography></Grid>
                </Grid>}


                {/*changeData && changeData.change === "device_sn2" ? (
                    <React.Fragment>
                        <Grid container direction="row" >
                            <Grid item xs={8}>

                                <TextField name="device_sn2" label="Nr seryjny jednostki zewnętrznej" type="text" value={changeData ? changeData.data.device_outside.sn : ""}
                                    onChange={(e) => handleChangeDeviceData(e)} />

                            </Grid>
                        </Grid>
                        <Grid container direction="row" >
                            <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#388e3c", float: "right" }} title="Zapisz" icon={faCheckCircle} onClick={() => { sendDeviceData("device_sn2") }} /><br />
                            </Grid>
                            <Grid item xs={4}>
                                <FontAwesomeIcon style={{ cursor: "pointer", color: "#d32f2f", float: "left" }} icon={faTimesCircle} title="Anuluj" onClick={() => handleChange("device_sn2")} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : (
                <React.Fragment>*/}
                            <Grid container direction="row" >
                                <Grid item xs={4}><Typography variant="body1">Nr jed. zew.:</Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1">{deviceDetails && deviceDetails.device_outside !== null ? deviceDetails.device_outside.sn : "Nie powiązano jednostki zewnętrznej z instalacją"}</Typography></Grid>
                                {/*<Grid item xs={2}><FontAwesomeIcon onClick={() => handleChange("device_sn2")} style={{ cursor: "pointer" }} icon={faEdit} title="Edytuj" /></Grid>*/}
                            </Grid>
                        {/*</React.Fragment>
                        )}*/}
                {details.device_status ? 
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Status urządzenia:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.device_status}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.device_type ? 
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Typ urządzenia:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.device_type}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.environment ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Środowisko:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.environment}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.installation_id ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Id instalacji:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.installation_id}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.key ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Klucz maliny:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.key}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.last_sw_update_date ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Aktualizacja paczek:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.last_sw_update_date}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.status_update_date ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Aktualizacja bazy:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.status_update_date}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.wifi_pass ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Hasło wifi maliny:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.wifi_pass}</Typography></Grid>
                    </Grid>
                    : null
                }
                {details.wifi_ussid ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1">Wifi maliny:</Typography></Grid>
                        <Grid item xs={6}><Typography variant="body1">{details.wifi_ussid}</Typography></Grid>
                    </Grid>
                    : null
                }
                <Typography variant="h3" style={{ marginTop: 30}}>Urządzenia dodatkowe</Typography>
                {details.hardware_version ?
                    <Grid container direction="row">
                        {/*<Grid item xs={4}><Typography variant="body1"><br />Hardware version:</Typography></Grid>*/}
                        <Grid item xs={12}>{details && details.hardware_version !== "pending" ?
                            (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {hardware()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                            : null}</Grid>
                    </Grid>
                    : null
                }
                <Grid container direction="row" style={{marginTop: 20}} >
                    <Grid item xs={12}>
                        <Typography variant="h3" style={{display: 'inline'}}>Dodaj kolejne</Typography>
                        <Tooltip title="Dodaj kolejne" placement="right-start">
                        <Fab
                        style={{ left: "1rem" }}
                        size="small"
                        color="primary"
                        onClick={() => handleHardwareVers()}
                        >
                        <AddIcon />
                        </Fab>
                    </Tooltip>
                    </Grid>
                </Grid>
                {hardwareVers && hardwareVers.length > 0 ?
                    <React.Fragment>{hardwareVers.map((item, i) => {
                        return <Grid container direction="row" key={`hardwareVers${i}`}>
                            <Grid item xs={6}>
                                <TextField name="name" label="Nazwa" type="text" value={item.name} onChange={(e) => addHardwareVers(e, i)} 
                                error={fieldError && fieldError[`rowId${i}name`] && fieldError[`rowId${i}name`].status ? true : false} helperText={fieldError && fieldError[`rowId${i}name`] && fieldError[`rowId${i}name`].status ? fieldError[`rowId${i}name`].msg : ""}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="serial" label="Numer seryjny" type="text" value={item.serial} onChange={(e) => addHardwareVers(e, i)} 
                                error={fieldError && fieldError[`rowId${i}serial`] && fieldError[`rowId${i}serial`].status ? true : false} helperText={fieldError && fieldError[`rowId${i}serial`] && fieldError[`rowId${i}serial`].status ? fieldError[`rowId${i}serial`].msg : ""}/>
                            </Grid>
                        </Grid>
                    })}
                    
                    </React.Fragment>
                    : null
                }
                <Grid container direction="row" >
                    <Grid item xs={12}><br /><Button variant="contained" color="primary" type="submit" onClick={() => sendDeviceData("hardware_version")}>Dodaj</Button>
                        {errorDevice && errorDevice.pump ? <Typography variant="body1" style={{ color: "red", marginTop: "15px" }}>{errorDevice.pump}</Typography> : null}</Grid>
                </Grid>
                {details.installed_sw_packages ?
                    <Grid container direction="row">
                        <Grid item xs={4}><Typography variant="body1"><br />Oprogramowanie:</Typography></Grid>
                        <Grid item xs={6}>{details && details.installed_sw_packages !== "pending" ?
                            (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {software()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                            : null}</Grid>
                    </Grid>
                    : null
                }
            </React.Fragment>) : <CircularProgress />
            }
        </React.Fragment>
    )
}

export default DeviceDetails