const fetchCheckNipPartnerToken = async (nip, token) => {
      
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/partners/nip/${nip}`, {
    method: "GET",
    headers:{
        "Authorization": `Bearer ${token}`
    }
    })
    .then(response => response.json())

    return response
}

export default fetchCheckNipPartnerToken