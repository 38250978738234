import React, { useEffect } from 'react';
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid,
} from '@material-ui/core'

const VentingCircuitsEON = ({ required, setValues }) => {

    useEffect(() => {
        setValues({type: "childName", value: "VentingCircuitsEON"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Odpowietrzenie obiegów</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>Wymuszenie funkcji przygotowania cwu, odpowietrzenie obiegu przygotowania cwu, kontrola
                    przepływu w obiegu przygotowania cwu</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="preparationCwu"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="preparationCwu"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="preparationCwu" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Wyłączenie funkcji przygotowania cwu i wymuszenie pracy na ogrzewanie, odpowietrzenie
                    obiegu grzewczo-chłodzącego, kontrola przepływu w obiegu grzewczo-chłodzącym</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="forceHeating"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="forceHeating"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="forceHeating" />
                </Grid>
            </Grid>
        </>
    )
}

export default VentingCircuitsEON;