import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Typography,
    Grid
} from '@material-ui/core'
import formatString from "format-string-by-pattern"

const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Wartość powinna wynosić nie mniej niż ${min}`;

const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Wartość powinna wynosić nie wiecej niż ${max}`;

  const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
  
    return formatString('9', onlyNumbers)
  }


const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const FirstLaunchEON = ({ required, setValues, dtValue }) => {

    useEffect(() => {
        setValues({type: "childName", value: "FirstLaunchEON"})
    }, []);

    return (
        <>
            <Typography variant='h2'>Pierwsze uruchomienie</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        Przywrócenie nastaw fabrycznych<br />
                        <span style={{color: "Grey", fontSize: "80%"}}>Należy przywrócić nastawy fabryczne, wywołując odpowiednią funkcję sterownika</span>
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="reinstatementSettings"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="reinstatementSettings"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="reinstatementSettings" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Skasowanie historii alarmów, skasowanie statystyk</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="deleteHistory"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="deleteHistory"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="deleteHistory" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>Kontrola znaku i wartości dT na wymienniku odbioru</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="dTValueControl"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "dtValue", value: true})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="dTValueControl"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "dtValue", value: false})}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="dTValueControl" />
                </Grid>
                {dtValue ? (<Grid item xs={12} sm={6}>
                    <Field name="dTValue"
                        component={TextField}
                        type="text"
                        label="Wartość"
                        fullWidth
                        validate={composeValidators(required, minValue(1), maxValue(7))}
                        parse={e => formatOnlyNumbers(e)}
                    />
                </Grid>) : null}
            </Grid>
        </>
    )
}

export default FirstLaunchEON;