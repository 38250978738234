export let initData = (receivedData) => {
    let data;
    if (receivedData !== undefined) {
        const [tabData] = receivedData;
        data = tabData.Data;

        return {
            device:{
                Name: tabData.Name,
                Type: tabData.Type,
                Version: tabData.Version
            },
            main: {
                MODE: {
                    AUTO: "wyłączenie pozostałych trybów",
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SXF_ECO: parseInt(data.SXF_ECO),
                    SXF_TIME: parseInt(data.SXF_TIME),
                    SXF_AWA: parseInt(data.SXF_AWA),
                },
                BUILDING_TEMP: parseInt(data.SPHT_ZAD),
                WATHER_TEMP: parseInt(data.SPDHW_ZAD),
                HEATING: parseInt(data.SBF_HTS),
                AIR_CONDITIONING: parseInt(data.SBF_ACS),
                CIRCULATION: parseInt(data.SXF_FLPCYR),
                ANTYLEGIONELLA: parseInt(data.SBF_PHT),
                REW_WRN: parseInt(data.REW_WRN),
                SBB_QHT: parseInt(data.SBB_QHT),
                SBB_QHL: parseInt(data.SBB_QHL),
                SBB_QHW: parseInt(data.SBB_QHW),
                TSM10: parseInt(data.TSM10),
                TSM15: parseInt(data.TSM15),
                SXF_TOE: parseInt(data.SXF_TOE),
                SBF_DHS: parseInt(data.SBF_DHS)
            },
            heating: {
                CUN_HCU: parseInt(data.CUN_HCU),
                CUN_D_HCU: parseInt(data.CUN_D_HCU),
                SX4_PH: parseInt(data.SX4_PH),
                SX4_PC: parseInt(data.SX4_PC),
            },
            airConditioning: {
                SX2_P1: parseInt(data.SX2_P1),
                SX2_P2: parseInt(data.SX2_P2),
                SX4_PH: parseInt(data.SX4_PH),
                SX4_PC: parseInt(data.SX4_PC),
            },
            ecoSettings: {
                firstLabel: {
                    morningTime: {
                        ECOT1R: parseInt(data.ECOT1R),
                        ECOT1S: parseInt(data.ECOT1S),
                    },
                    afternoonTimeWinter: {
                        ECOT2R_Z: parseInt(data.ECOT2R_Z),
                        ECOT2S_Z: parseInt(data.ECOT2S_Z),
                    },
                    afternoonTimeSummer: {
                        ECOT2R_L: parseInt(data.ECOT2R_L),
                        ECOT2S_L: parseInt(data.ECOT2S_L),
                    },
                    eveningTime: {
                        ECOT3R: parseInt(data.ECOT3R),
                        ECOT3S: parseInt(data.ECOT3S),
                    },
                },
                secondLabel: {
                    increaseMorningEvening: {
                        ECOY1: parseInt(data.ECOY1),
                    },
                    increaseAfternoon: {
                        ECOY2: parseInt(data.ECOY2),
                    },
                    reduction: {
                        ECOTIN: parseInt(data.ECOTIN),
                    },
                },
                thirdLabel: {
                    increaseMorningEvening: {
                        ECOX1: parseInt(data.ECOX1),
                    },
                    increaseAfternoon: {
                        ECOX2: parseInt(data.ECOX2),
                    },
                    reduction: {
                        ECODHW: parseInt(data.ECODHW),
                    },
                },
                fourthLabel: {
                    register: {
                        SXF_WEK: parseInt(data.SXF_WEK),
                    },
                },
                fifthLabel: {
                    register: {
                        SXF_AZP: parseInt(data.SXF_AZP),
                    },
                },
            },
            away: {
                AWAY_CLOCK: parseInt(data.AWAY_CLOCK),
            },
            time: {
                T: {
                    PN: {
                        TW1: {
                            PG: parseInt(data.TWP1P_PG),
                            PM: parseInt(data.TWP1P_PM),
                            KG: parseInt(data.TWP1P_KG),
                            KM: parseInt(data.TWP1P_KM),
                            Temp: parseInt(data.TWP1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWP2P_PG),
                            PM: parseInt(data.TWP2P_PM),
                            KG: parseInt(data.TWP2P_KG),
                            KM: parseInt(data.TWP2P_KM),
                            Temp: parseInt(data.TWP2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWP3P_PG),
                            PM: parseInt(data.TWP3P_PM),
                            KG: parseInt(data.TWP3P_KG),
                            KM: parseInt(data.TWP3P_KM),
                            Temp: parseInt(data.TWP3T),
                        },
                        TWTSW_PN: parseInt(data.TWTSW_PN),
                    },
                    WT: {
                        TW1: {
                            PG: parseInt(data.TWW1P_PG),
                            PM: parseInt(data.TWW1P_PM),
                            KG: parseInt(data.TWW1P_KG),
                            KM: parseInt(data.TWW1P_KM),
                            Temp: parseInt(data.TWW1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWW2P_PG),
                            PM: parseInt(data.TWW2P_PM),
                            KG: parseInt(data.TWW2P_KG),
                            KM: parseInt(data.TWW2P_KM),
                            Temp: parseInt(data.TWW2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWW3P_PG),
                            PM: parseInt(data.TWW3P_PM),
                            KG: parseInt(data.TWW3P_KG),
                            KM: parseInt(data.TWW3P_KM),
                            Temp: parseInt(data.TWW3T),
                        },
                        TWTSW_WT: parseInt(data.TWTSW_WT),
                    },
                    SR: {
                        TW1: {
                            PG: parseInt(data.TWS1P_PG),
                            PM: parseInt(data.TWS1P_PM),
                            KG: parseInt(data.TWS1P_KG),
                            KM: parseInt(data.TWS1P_KM),
                            Temp: parseInt(data.TWS1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWS2P_PG),
                            PM: parseInt(data.TWS2P_PM),
                            KG: parseInt(data.TWS2P_KG),
                            KM: parseInt(data.TWS2P_KM),
                            Temp: parseInt(data.TWS2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWS3P_PG),
                            PM: parseInt(data.TWS3P_PM),
                            KG: parseInt(data.TWS3P_KG),
                            KM: parseInt(data.TWS3P_KM),
                            Temp: parseInt(data.TWS3T),
                        },
                        TWTSW_SR: parseInt(data.TWTSW_SR),
                    },
                    CZ: {
                        TW1: {
                            PG: parseInt(data.TWC1P_PG),
                            PM: parseInt(data.TWC1P_PM),
                            KG: parseInt(data.TWC1P_KG),
                            KM: parseInt(data.TWC1P_KM),
                            Temp: parseInt(data.TWC1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWC2P_PG),
                            PM: parseInt(data.TWC2P_PM),
                            KG: parseInt(data.TWC2P_KG),
                            KM: parseInt(data.TWC2P_KM),
                            Temp: parseInt(data.TWC2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWC3P_PG),
                            PM: parseInt(data.TWC3P_PM),
                            KG: parseInt(data.TWC3P_KG),
                            KM: parseInt(data.TWC3P_KM),
                            Temp: parseInt(data.TWC3T),
                        },
                        TWTSW_CZ: parseInt(data.TWTSW_CZ),
                    },
                    PT: {
                        TW1: {
                            PG: parseInt(data.TWPT1P_PG),
                            PM: parseInt(data.TWPT1P_PM),
                            KG: parseInt(data.TWPT1P_KG),
                            KM: parseInt(data.TWPT1P_KM),
                            Temp: parseInt(data.TWPT1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWPT2P_PG),
                            PM: parseInt(data.TWPT2P_PM),
                            KG: parseInt(data.TWPT2P_KG),
                            KM: parseInt(data.TWPT2P_KM),
                            Temp: parseInt(data.TWPT2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWPT3P_PG),
                            PM: parseInt(data.TWPT3P_PM),
                            KG: parseInt(data.TWPT3P_KG),
                            KM: parseInt(data.TWPT3P_KM),
                            Temp: parseInt(data.TWPT3T),
                        },
                        TWTSW_PT: parseInt(data.TWTSW_PT),
                    },
                    SO: {
                        TW1: {
                            PG: parseInt(data.TWSO1P_PG),
                            PM: parseInt(data.TWSO1P_PM),
                            KG: parseInt(data.TWSO1P_KG),
                            KM: parseInt(data.TWSO1P_KM),
                            Temp: parseInt(data.TWSO1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWSO2P_PG),
                            PM: parseInt(data.TWSO2P_PM),
                            KG: parseInt(data.TWSO2P_KG),
                            KM: parseInt(data.TWSO2P_KM),
                            Temp: parseInt(data.TWSO2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWSO3P_PG),
                            PM: parseInt(data.TWSO3P_PM),
                            KG: parseInt(data.TWSO3P_KG),
                            KM: parseInt(data.TWSO3P_KM),
                            Temp: parseInt(data.TWSO3T),
                        },
                        TWTSW_SO: parseInt(data.TWTSW_SO),
                    },
                    ND: {
                        TW1: {
                            PG: parseInt(data.TWN1P_PG),
                            PM: parseInt(data.TWN1P_PM),
                            KG: parseInt(data.TWN1P_KG),
                            KM: parseInt(data.TWN1P_KM),
                            Temp: parseInt(data.TWN1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWN2P_PG),
                            PM: parseInt(data.TWN2P_PM),
                            KG: parseInt(data.TWN2P_KG),
                            KM: parseInt(data.TWN2P_KM),
                            Temp: parseInt(data.TWN2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWN3P_PG),
                            PM: parseInt(data.TWN3P_PM),
                            KG: parseInt(data.TWN3P_KG),
                            KM: parseInt(data.TWN3P_KM),
                            Temp: parseInt(data.TWN3T),
                        },
                        TWTSW_ND: parseInt(data.TWTSW_ND),
                    },
                },
                C: {
                    PN: {
                        CW1: {
                            PG: parseInt(data.CWP1P_PG),
                            PM: parseInt(data.CWP1P_PM),
                            KG: parseInt(data.CWP1P_KG),
                            KM: parseInt(data.CWP1P_KM),
                            Temp: parseInt(data.CWP1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWP2P_PG),
                            PM: parseInt(data.CWP2P_PM),
                            KG: parseInt(data.CWP2P_KG),
                            KM: parseInt(data.CWP2P_KM),
                            Temp: parseInt(data.CWP2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWP3P_PG),
                            PM: parseInt(data.CWP3P_PM),
                            KG: parseInt(data.CWP3P_KG),
                            KM: parseInt(data.CWP3P_KM),
                            Temp: parseInt(data.CWP3T),
                        },
                        CWTSW_PN: parseInt(data.CWTSW_PN),
                    },
                    WT: {
                        CW1: {
                            PG: parseInt(data.CWW1P_PG),
                            PM: parseInt(data.CWW1P_PM),
                            KG: parseInt(data.CWW1P_KG),
                            KM: parseInt(data.CWW1P_KM),
                            Temp: parseInt(data.CWW1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWW2P_PG),
                            PM: parseInt(data.CWW2P_PM),
                            KG: parseInt(data.CWW2P_KG),
                            KM: parseInt(data.CWW2P_KM),
                            Temp: parseInt(data.CWW2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWW3P_PG),
                            PM: parseInt(data.CWW3P_PM),
                            KG: parseInt(data.CWW3P_KG),
                            KM: parseInt(data.CWW3P_KM),
                            Temp: parseInt(data.CWW3T),
                        },
                        CWTSW_WT: parseInt(data.CWTSW_WT),
                    },
                    SR: {
                        CW1: {
                            PG: parseInt(data.CWS1P_PG),
                            PM: parseInt(data.CWS1P_PM),
                            KG: parseInt(data.CWS1P_KG),
                            KM: parseInt(data.CWS1P_KM),
                            Temp: parseInt(data.CWS1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWS2P_PG),
                            PM: parseInt(data.CWS2P_PM),
                            KG: parseInt(data.CWS2P_KG),
                            KM: parseInt(data.CWS2P_KM),
                            Temp: parseInt(data.CWS2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWS3P_PG),
                            PM: parseInt(data.CWS3P_PM),
                            KG: parseInt(data.CWS3P_KG),
                            KM: parseInt(data.CWS3P_KM),
                            Temp: parseInt(data.CWS3T),
                        },
                        CWTSW_SR: parseInt(data.CWTSW_SR),
                    },
                    CZ: {
                        CW1: {
                            PG: parseInt(data.CWC1P_PG),
                            PM: parseInt(data.CWC1P_PM),
                            KG: parseInt(data.CWC1P_KG),
                            KM: parseInt(data.CWC1P_KM),
                            Temp: parseInt(data.CWC1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWC2P_PG),
                            PM: parseInt(data.CWC2P_PM),
                            KG: parseInt(data.CWC2P_KG),
                            KM: parseInt(data.CWC2P_KM),
                            Temp: parseInt(data.CWC2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWC3P_PG),
                            PM: parseInt(data.CWC3P_PM),
                            KG: parseInt(data.CWC3P_KG),
                            KM: parseInt(data.CWC3P_KM),
                            Temp: parseInt(data.CWC3T),
                        },
                        CWTSW_CZ: parseInt(data.CWTSW_CZ),
                    },
                    PT: {
                        CW1: {
                            PG: parseInt(data.CWPT1P_PG),
                            PM: parseInt(data.CWPT1P_PM),
                            KG: parseInt(data.CWPT1P_KG),
                            KM: parseInt(data.CWPT1P_KM),
                            Temp: parseInt(data.CWPT1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWPT2P_PG),
                            PM: parseInt(data.CWPT2P_PM),
                            KG: parseInt(data.CWPT2P_KG),
                            KM: parseInt(data.CWPT2P_KM),
                            Temp: parseInt(data.CWPT2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWPT3P_PG),
                            PM: parseInt(data.CWPT3P_PM),
                            KG: parseInt(data.CWPT3P_KG),
                            KM: parseInt(data.CWPT3P_KM),
                            Temp: parseInt(data.CWPT3T),
                        },
                        CWTSW_PT: parseInt(data.CWTSW_PT),
                    },
                    SO: {
                        CW1: {
                            PG: parseInt(data.CWSO1P_PG),
                            PM: parseInt(data.CWSO1P_PM),
                            KG: parseInt(data.CWSO1P_KG),
                            KM: parseInt(data.CWSO1P_KM),
                            Temp: parseInt(data.CWSO1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWSO2P_PG),
                            PM: parseInt(data.CWSO2P_PM),
                            KG: parseInt(data.CWSO2P_KG),
                            KM: parseInt(data.CWSO2P_KM),
                            Temp: parseInt(data.CWSO2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWSO3P_PG),
                            PM: parseInt(data.CWSO3P_PM),
                            KG: parseInt(data.CWSO3P_KG),
                            KM: parseInt(data.CWSO3P_KM),
                            Temp: parseInt(data.CWSO3T),
                        },
                        CWTSW_SO: parseInt(data.CWTSW_SO),
                    },
                    ND: {
                        CW1: {
                            PG: parseInt(data.CWN1P_PG),
                            PM: parseInt(data.CWN1P_PM),
                            KG: parseInt(data.CWN1P_KG),
                            KM: parseInt(data.CWN1P_KM),
                            Temp: parseInt(data.CWN1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWN2P_PG),
                            PM: parseInt(data.CWN2P_PM),
                            KG: parseInt(data.CWN2P_KG),
                            KM: parseInt(data.CWN2P_KM),
                            Temp: parseInt(data.CWN2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWN3P_PG),
                            PM: parseInt(data.CWN3P_PM),
                            KG: parseInt(data.CWN3P_KG),
                            KM: parseInt(data.CWN3P_KM),
                            Temp: parseInt(data.CWN3T),
                        },
                        CWTSW_ND: parseInt(data.CWTSW_ND),
                    },
                },
            },
            expirationData: {
                ExpirationTime: data.ExpirationTime,
                TimeStamp: data.ExpirationTime,
            },
            controllertime: {
                ROK: data.ROK,
                MIESIAC: data.MIESIAC,
                DZIEN: data.DZIEN,
                GODZINY: data.GODZINY,
                MINUTY: data.MINUTY,
                SEKUNDY: data.SEKUNDY,
            },
            crot: {
                TOUT_NUM: data.TOUT_NUM,
                TOUT_KOR: data.TOUT_KOR
            },
            block: {
                BLK_KD1: data.BLK_KD1,
                BLK_KD2: data.BLK_KD2,
                BLK_LW: data.BLK_LW,
                BLK_LK: data.BLK_LK
            },
            temperature: {
                TSM9: data.TSM9,
                TSM10: data.TSM10,
                TSM11: data.TSM11,
                TSM12: data.TSM12,
                TSM13: data.TSM13,
                TSM14: data.TSM14,
                TSM15: data.TSM15,
                TSM16: data.TSM16,
                TSM17: data.TSM17,
                T3fiz: data.T3fiz,
                T4fiz: data.T4fiz,
                T5fiz: data.T5fiz,
                T6fiz: data.T6fiz
            },
            itc: {
                SPHT_HIST: parseFloat(data.SPHT_HIST),
                SPHT_ZAD: parseFloat(data.SPHT_ZAD),
                CUN_HCU: parseFloat(data.CUN_HCU),
                CUN_D_HCU: parseFloat(data.CUN_D_HCU),
                SX2_P2: parseFloat(data.SX2_P2),
                SX2_P1: parseFloat(data.SX2_P1),
                SX3_HG: parseFloat(data.SX3_HG),
                SX3_HC: parseFloat(data.SX3_HC),
                COC: parseFloat(data.COC),
                SX4_PH: parseFloat(data.SX4_PH),
                SX4_PC: parseFloat(data.SX4_PC),
                SCHT: parseFloat(data.SCHT),
                SX6: parseFloat(data.SX6),
                SPCU: parseFloat(data.SPCU),
                SCCU: parseFloat(data.SCCU),
                SX1: parseFloat(data.SX1),
                TB1: parseFloat(data.TB1),
                TB2: parseFloat(data.TB2),
                SPDHW_HIST: parseFloat(data.SPDHW_HIST),
                SPDHW_ZAD: parseFloat(data.SPDHW_ZAD),
            },
            errWarn: {
                REW_SUP: parseInt(data.REW_SUP),
                REW_TER: parseInt(data.REW_TER),
                REW_LPC: parseInt(data.REW_LPC),
                REW_LPH: parseInt(data.REW_LPH),
                REW_HP: parseInt(data.REW_HP),
                REW_FLO: parseInt(data.REW_FLO),
                REW_DIS: parseInt(data.REW_DIS),
                REW_ERR: parseInt(data.REW_ERR),
                REW_SMI: parseInt(data.REW_SMI),
                REW_SMA: parseInt(data.REW_SMA),
                REW_RMI: parseInt(data.REW_RMI),
                REW_RMA: parseInt(data.REW_RMA),
                REW_SMD: parseInt(data.REW_SMD),
                REW_RMD: parseInt(data.REW_RMD),
                REW_WRN: parseInt(data.REW_WRN),
                REW_FCS: parseInt(data.REW_FCS)
            },
            eco: {
                ECOT1R: parseInt(data.ECOT1R),
                ECOT1S: parseInt(data.ECOT1S),
                ECOT2R_Z: parseInt(data.ECOT2R_Z),
                ECOT2S_Z: parseInt(data.ECOT2S_Z),
                ECOT2R_L: parseInt(data.ECOT2R_L),
                ECOT2S_L: parseInt(data.ECOT2S_L),
                ECOT3R: parseInt(data.ECOT3R),
                ECOT3S: parseInt(data.ECOT3S),
                ECOX1: parseInt(data.ECOX1),
                ECOX2: parseInt(data.ECOX2),
                ECODHW: parseInt(data.ECODHW),
                ECOY1: parseInt(data.ECOY1),
                ECOY2: parseInt(data.ECOY2),
                ECOTIN: parseInt(data.ECOTIN),
            },
            cfs: {
                SBF_PHT: parseInt(data.SBF_PHT),
                SBF_HTX: parseInt(data.SBF_HTX),
                SBF_HTS: parseInt(data.SBF_HTS),
                SBF_DHX: parseInt(data.SBF_DHX),
                SBF_DHS: parseInt(data.SBF_DHS),
                SBF_FCX: parseInt(data.SBF_FCX),
                SBF_FCS: parseInt(data.SBF_FCS),
                SBF_OOF: parseInt(data.SBF_OOF),
                SBF_AHX: parseInt(data.SBF_AHX),
                SBF_AHS: parseInt(data.SBF_AHS),
                SBF_ACX: parseInt(data.SBF_ACX),
                SBF_ACS: parseInt(data.SBF_ACS),
                SXF_TIM_WIN_SW: parseInt(data.SXF_TIM_WIN_SW),
                SXF_TIM_WIN_FL: parseInt(data.SXF_TIM_WIN_FL),
                SXF_ACT: parseInt(data.SXF_ACT),
                SXF_FLPCYR: parseInt(data.SXF_FLPCYR),
                SXF_AZP: parseInt(data.SXF_AZP),
                SXF_TIMCYR: parseInt(data.SXF_TIMCYR),
                SXF_ENACYR: parseInt(data.SXF_ENACYR),
                SXF_TOE: parseInt(data.SXF_TOE),
                SXF_FORCYR: parseInt(data.SXF_FORCYR),
                SXF_TIME: parseInt(data.SXF_TIME),
                SXF_ANL: parseInt(data.SXF_ANL),
                SXF_WEK: parseInt(data.SXF_WEK),
                SXF_AWA: parseInt(data.SXF_AWA),
                SXF_ECO: parseInt(data.SXF_ECO),
                SXF_DHM: parseInt(data.SXF_DHM),
                SXF_4EN: parseInt(data.SXF_4EN),
                ANL_ALL: parseInt(data.ANL_ALL),
                ANL_1PH: parseInt(data.ANL_1PH),
                ANL_2PH: parseInt(data.ANL_2PH),
                ANL_3PH: parseInt(data.ANL_3PH),
            },
            ptc: {
                CYR_FILL: parseInt(data.CYR_FILL),
                CYRSW_S3: parseInt(data.CYRSW_S3),
                CYRSW_S2: parseInt(data.CYRSW_S2),
                CYRSW_S1: parseInt(data.CYRSW_S1),
                PN: {
                    SWITCH: parseInt(data.CYRSW_PN),
                    CYR1: {
                        PG: parseInt(data.CYRP1P_PG),
                        PM: parseInt(data.CYRP1P_PM),
                        KG: parseInt(data.CYRP1P_KG),
                        KM: parseInt(data.CYRP1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRP2P_PG),
                        PM: parseInt(data.CYRP2P_PM),
                        KG: parseInt(data.CYRP2P_KG),
                        KM: parseInt(data.CYRP2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRP3P_PG),
                        PM: parseInt(data.CYRP3P_PM),
                        KG: parseInt(data.CYRP3P_KG),
                        KM: parseInt(data.CYRP3P_KM),
                    },
                },
                WT: {
                    SWITCH: parseInt(data.CYRSW_WT),
                    CYR1: {
                        PG: parseInt(data.CYRW1P_PG),
                        PM: parseInt(data.CYRW1P_PM),
                        KG: parseInt(data.CYRW1P_KG),
                        KM: parseInt(data.CYRW1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRW2P_PG),
                        PM: parseInt(data.CYRW2P_PM),
                        KG: parseInt(data.CYRW2P_KG),
                        KM: parseInt(data.CYRW2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRW3P_PG),
                        PM: parseInt(data.CYRW3P_PM),
                        KG: parseInt(data.CYRW3P_KG),
                        KM: parseInt(data.CYRW3P_KM),
                    },
                },
                SR: {
                    SWITCH: parseInt(data.CYRSW_SR),
                    CYR1: {
                        PG: parseInt(data.CYRS1P_PG),
                        PM: parseInt(data.CYRS1P_PM),
                        KG: parseInt(data.CYRS1P_KG),
                        KM: parseInt(data.CYRS1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRS2P_PG),
                        PM: parseInt(data.CYRS2P_PM),
                        KG: parseInt(data.CYRS2P_KG),
                        KM: parseInt(data.CYRS2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRS3P_PG),
                        PM: parseInt(data.CYRS3P_PM),
                        KG: parseInt(data.CYRS3P_KG),
                        KM: parseInt(data.CYRS3P_KM),
                    },
                },
                CZ: {
                    SWITCH: parseInt(data.CYRSW_CZ),
                    CYR1: {
                        PG: parseInt(data.CYRC1P_PG),
                        PM: parseInt(data.CYRC1P_PM),
                        KG: parseInt(data.CYRC1P_KG),
                        KM: parseInt(data.CYRC1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRC2P_PG),
                        PM: parseInt(data.CYRC2P_PM),
                        KG: parseInt(data.CYRC2P_KG),
                        KM: parseInt(data.CYRC2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRC3P_PG),
                        PM: parseInt(data.CYRC3P_PM),
                        KG: parseInt(data.CYRC3P_KG),
                        KM: parseInt(data.CYRC3P_KM),
                    },
                },
                PT: {
                    SWITCH: parseInt(data.CYRSW_PT),
                    CYR1: {
                        PG: parseInt(data.CYRPT1P_PG),
                        PM: parseInt(data.CYRPT1P_PM),
                        KG: parseInt(data.CYRPT1P_KG),
                        KM: parseInt(data.CYRPT1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRPT2P_PG),
                        PM: parseInt(data.CYRPT2P_PM),
                        KG: parseInt(data.CYRPT2P_KG),
                        KM: parseInt(data.CYRPT2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRPT3P_PG),
                        PM: parseInt(data.CYRPT3P_PM),
                        KG: parseInt(data.CYRPT3P_KG),
                        KM: parseInt(data.CYRPT3P_KM),
                    },
                },
                SO: {
                    SWITCH: parseInt(data.CYRSW_SO),
                    CYR1: {
                        PG: parseInt(data.CYRSO1P_PG),
                        PM: parseInt(data.CYRSO1P_PM),
                        KG: parseInt(data.CYRSO1P_KG),
                        KM: parseInt(data.CYRSO1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRSO2P_PG),
                        PM: parseInt(data.CYRSO2P_PM),
                        KG: parseInt(data.CYRSO2P_KG),
                        KM: parseInt(data.CYRSO2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRSO3P_PG),
                        PM: parseInt(data.CYRSO3P_PM),
                        KG: parseInt(data.CYRSO3P_KG),
                        KM: parseInt(data.CYRSO3P_KM),
                    },
                },
                ND: {
                    SWITCH: parseInt(data.CYRSW_ND),
                    CYR1: {
                        PG: parseInt(data.CYRN1P_PG),
                        PM: parseInt(data.CYRN1P_PM),
                        KG: parseInt(data.CYRN1P_KG),
                        KM: parseInt(data.CYRN1P_KM),
                    },
                    CYR2: {
                        PG: parseInt(data.CYRN2P_PG),
                        PM: parseInt(data.CYRN2P_PM),
                        KG: parseInt(data.CYRN2P_KG),
                        KM: parseInt(data.CYRN2P_KM),
                    },
                    CYR3: {
                        PG: parseInt(data.CYRN3P_PG),
                        PM: parseInt(data.CYRN3P_PM),
                        KG: parseInt(data.CYRN3P_KG),
                        KM: parseInt(data.CYRN3P_KM),
                    },
                }
            }
        }
    } else {
        return null;
    }
}