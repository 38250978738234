import React, { useEffect, useState } from 'react'
import { Typography, Grid, Switch, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { registerDictionary } from '../../../../data/dataDictionary'
import { mainStyleClasses } from '../../../../theme'
import { StepFirst, StepSecond, StepGeo } from './components'
import { dataRange } from '../../../../data/dataRange'
import { ItemMapping } from '../../../../components'

const PeakSource = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const dictionary = registerDictionary().periphery.peakSource
    const deviceName = sessionStorage.getItem('deviceName')

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const handleRadioSwitchValue = (name, section) => (e, newValue) => {
        if (!newValue || parseInt(newValue) === 0) {
            setItemValue({
                ...itemValue,
                [section]: {
                    ...itemValue[section],
                    [name]: 0
                }
            })
            setPublish({ [name]: 0 })
        } else {
            setItemValue({
                ...itemValue,
                [section]: {
                    ...itemValue[section],
                    [name]: 1
                }
            })
            setPublish({ [name]: 1 })
        }
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.settings.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.settings, handleSwitchValue, undefined, dictionary.settings.data, classes, filteredItems(itemValue.settings), 'settings')}
                    </Grid>
                </Grid>
                {deviceName === 'GEO_1' ? 
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.step_geo.status.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.step_geo.status, handleSwitchValue, undefined, dictionary.step_geo.status.data, classes, filteredItems(itemValue.step_geo.status), 'status')}
                        </Grid>
                        <Grid container className={classes.containerVerticalCenter}>
                            <Grid item xs={9}>
                                <Typography variant="body2">Obowiązująca taryfa energii elektrycznej</Typography> 
                            </Grid>
                            {itemValue.SXB_ECACT === 0 ? 
                                <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                                    <Typography variant="body2">drogi prąd</Typography>  
                                </Grid>
                                : <Grid item xs={3} className={classes.textRight} style={{ paddingRight: '24px' }}>
                                    <Typography variant="body2">tani prąd</Typography> 
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    : <Grid item xs={6}>
                        <Typography variant="h2">Konfiguracja</Typography><br />
                        <FormControl component="fieldset">
                            <RadioGroup name="SWEXT_ZBCRO" value={itemValue.configuration.SWEXT_ZBCRO} onChange={handleRadioSwitchValue("SWEXT_ZBCRO", "configuration")}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.containerVerticalCenter}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={0} control={<Radio color="primary" />} label="Grzałki wewnętrzne / dodatkowy kocioł elektryczny" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} className={classes.containerVerticalCenter}>
                                        <label style={{ display: "block", height: "100%" }}>
                                            <FormControlLabel value={1} control={<Radio color="primary" />} label="Dodatkowy kocioł spalinowy" />
                                        </label>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                }
            </Grid>
            
            <Grid container>
                {deviceName === 'GEO_1' ?
                    <Grid item xs={6}>
                        <StepGeo settings={itemValue.step_geo} setPublish={setPublish} />
                    </Grid>
                    : <Grid item xs={6}>
                        <StepFirst settings={itemValue.step_1} setPublish={setPublish} />
                    </Grid>
                }
                {deviceName === 'GEO_1' ? 
                    null
                    : <Grid item xs={6}>
                        <StepSecond settings={itemValue.step_2} setPublish={setPublish} />
                    </Grid>
                }
                </Grid>
            {deviceName === 'GEO_1' ? 
                null
                : <Grid container>
                    <Grid item xs={12}>
                        <hr style={{ color: "#005492", opacity: 0.5, border: "0.8px solid", margin: "8px 0", fontSize: "16px", fontWeight: "normal", lineHeight: "normal" }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">{dictionary.heater.SIO_R11}</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}>
                                {itemValue.heater.SIO_R11 === 1 ? 
                                    <FiberManualRecord style={{ color: "green" }} /> 
                                    : <FiberManualRecord style={{ color: "gray" }} />
                                } 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container className={classes.containerVerticalCenter} style={{ paddingRight: '24px' }}>
                            <Grid item xs={10}><Typography variant="body2">{dictionary.heater.SIO_R01}</Typography></Grid>
                            <Grid item xs={2} className={classes.textRight}>
                                {itemValue.heater.SIO_R01 === 1 ? 
                                    <FiberManualRecord style={{ color: "green" }} /> 
                                    : <FiberManualRecord style={{ color: "gray" }} />
                                } 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    )
}

export default PeakSource