import React, { useState, useEffect } from 'react'
import { Grid, Typography, LinearProgress, Box } from '@material-ui/core'
import logo from '../../img/logo.svg'

import { Navigation } from './components'
import hourglass from '../../img/klepsydra.svg'
import unlink from '../../img/unlink.svg'
import link from '../../img/link.svg'
import defrost from '../../img/defrost.svg'
import chlodzenie from '../../img/chlodzenie.svg'
import grzanie from '../../img/grzanie.svg'
import pompka_on from '../../img/pompka_on.svg'
import pompka_off from '../../img/pompka_off.svg'
import kropla from '../../img/kropla.svg'
import legionella_1 from '../../img/legionella_1.svg'
import legionella_2 from '../../img/legionella_2.svg'
import legionella_3 from '../../img/legionella_3.svg'
import legionella_off from '../../img/legionella_off.svg'
import PV from '../../img/PV.svg'
import PV_OFF from '../../img/PV_OFF.svg'
import delta from '../../img/delta.svg'
import fix from '../../img/fix.svg'
import reg from '../../img/reg.svg'
import timer from '../../img/timer.svg'
import bateria_uszkodzona from '../../img/bateria_uszkodzona.svg'
import bateria_sprawna from '../../img/bateria_sprawna.svg'
import background from '../../img/background.png'

const AsideMenu = ({ iconInfo, deviceType, url, locState, extenders }) => {
    // const [progress, setProgress] = useState((iconInfo.TCR / iconInfo.TCH) * 100)
    const deviceName = sessionStorage.getItem('deviceName')
	const version_1 = parseInt(sessionStorage.getItem('version_1'))
	const version_2 = parseInt(sessionStorage.getItem('version_2'))

    const comparisonState = () => {
        if ((iconInfo.SXB_WCL === 0 && iconInfo.SXB_WHT === 0 && iconInfo.SXB_WDHW === 0 && iconInfo.ZM_CUR_OP_MOD === 0)
            || (iconInfo.SXB_WCL === 1 && iconInfo.SXB_WHT === 0 && iconInfo.SXB_WDHW === 0 && iconInfo.ZM_CUR_OP_MOD === 1)
            || (iconInfo.SXB_WCL === 0 && iconInfo.SXB_WHT === 1 && iconInfo.SXB_WDHW === 0 && iconInfo.ZM_CUR_OP_MOD === 2)
            || (iconInfo.SXB_WCL === 0 && iconInfo.SXB_WHT === 0 && iconInfo.SXB_WDHW === 1 && iconInfo.ZM_CUR_OP_MOD === 3)) {
            return true
        } else {
            return false
        }
    }

    const efficiency = () => {
        if (iconInfo.SIO_R08 === 0) {
            return 0
        } else {
            return iconInfo.PWM_1
        }
    }

    useEffect(() => {
        let mounted = true

        // setProgress((iconInfo.TCR / iconInfo.TCH) * 100)
        return () => {
            mounted = false
        }
    }, [iconInfo])
    
    return (
        <React.Fragment>
            <Grid container direction='column' className='aside-panel'>
            {/*}
                <img alt="Logo Euros Energy" src={logo} style={{maxWidth: "100%"}} />
            {*/}
                <Grid item xs={1} className='aside-panel-navigation'>
                    <Navigation url={url} locState={locState} extenders={extenders} />
                </Grid>
                {iconInfo ? <Grid container direction='column'>
                    <Grid item className='aside-panel-box'>
                        {iconInfo.SXB_PV === 1 ?
                            <Grid item>
                                <img src={background} alt="" style={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />
                                <img className='PV-icon' src={PV} alt="" title="" />
                                <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '4%' }}>PV ON</Typography>
                            </Grid>
                            : <Grid item>
                                <img className='PV-icon' src={PV_OFF} alt="" title="" />
                                <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '4%' }}>PV OFF</Typography>
                            </Grid>
                        }            
                    </Grid>
                        <Grid item className='aside-panel-box'>
                            {iconInfo.SXB_WCL === 0 && iconInfo.SXB_WHT === 0 && iconInfo.SXB_WDHW === 0 ?
                                <img src={hourglass} alt="" title="" className='aside-panel-mode-icons hourglass' />
                                : iconInfo.SXB_WCL === 1 ?
                                    <img src={chlodzenie} alt="" title="" className='aside-panel-mode-icons chlodzenie' />
                                    : iconInfo.SXB_WHT === 1 ?
                                        <img src={grzanie} alt="" title="" className='aside-panel-mode-icons grzanie' />
                                        : iconInfo.SXB_WDHW === 1 ?
                                            <img src={kropla} alt="" title="" className='aside-panel-mode-icons kropla' />
                                            : null
                            }
                            {iconInfo && iconInfo.XZM_TRANSM === 1 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                <img  src={unlink} alt="" title="" className='aside-panel-link' />
                                : (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                    <img  src={link} alt="" title="" className={`aside-panel-link ${comparisonState() ? null : "pulsowanie"}`} />
                                    : null
                            }
                            {iconInfo && iconInfo.ZM_CUR_OP_MOD === 0 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                <img  src={hourglass} alt="" title="" className='aside-panel-mode-icons hourglass' />
                                : iconInfo.ZM_CUR_OP_MOD === 1 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                    <img src={chlodzenie} alt="" title="" className='aside-panel-mode-icons chlodzenie' />
                                    : iconInfo.ZM_CUR_OP_MOD === 2 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                        <img src={grzanie} alt="" title="" className='aside-panel-mode-icons grzanie' />
                                        : iconInfo.ZM_CUR_OP_MOD === 3 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                            <img src={kropla} alt="" title="" className='aside-panel-mode-icons kropla' />
                                            : iconInfo.ZM_CUR_OP_MOD === 4 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                                <img src={defrost} alt="" title="" className='aside-panel-mode-icons defrost' />
                                                : null
                            }
                            {iconInfo && iconInfo.XZM_TRANSM === 1 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '6%' }}>UNLINK</Typography>
                                : (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                    <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '6%' }}>LINK</Typography>
                                    : null
                            }
                        </Grid>
                        <Grid item className='aside-panel-box'>
                            {iconInfo.SWEXT_FIXREG === 1 ?
                                iconInfo.DETE !== 0 ? 
                                <Typography variant="body1" className='aside-panel-text'>REG</Typography>
                                    : <Typography variant="body1" className='aside-panel-text'>FIX</Typography>
                                : <Typography variant="body1" className='aside-panel-text'>FIX</Typography>
                            }
                            <Typography variant='h4' style={{ color: 'white' }}>
                                {isNaN(iconInfo.ZM_CUR_COMP_FREQ) ? 
                                    "--"
                                : iconInfo.ZM_CUR_COMP_FREQ}
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Hz</span>
                            </Typography>
                            <Typography variant="body1" className='aside-panel-text'>MODULACJA</Typography>
                        </Grid>
                        {/*{iconInfo.SIO_R08 === 0 ?
                            null
                            : <Grid item className='aside-panel-box'>
                                        <Grid>
                                            <img src={pompka} alt="" title="" className='pompka' /> 
                                            {iconInfo.VER_S1 > 1 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ? 
                                                <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '6%' }}>PWM {efficiency()}%</Typography>
                                                : null
                                            }
                                        </Grid>
                            </Grid>
                        }*/}
                        {iconInfo.SIO_R08 === 1 ?
                            iconInfo.VER_S1 > 1 && (deviceType === "ATMO_1" || deviceType === "ALLINONE_1") ?
                                <Grid item className='aside-panel-box'>
                                    <img src={pompka_on} alt="" title="" className='pompka' /> 
                                    <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '6%' }}>PWM {efficiency()}%</Typography>
                                </Grid>
                                : <Grid item className='aside-panel-box'>
                                    <img src={pompka_on} alt="" title="" className='pompka' /> 
                                    <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '6%' }}>ON</Typography>
                                </Grid>
                                    : <Grid item className='aside-panel-box'>
                                        <img src={pompka_off} alt="" title="" className='pompka' /> 
                                        <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '6%' }}>OFF</Typography>
                                    </Grid>
                        }       
                        <Grid item className='aside-panel-box'>
                        {/*<Typography variant="body2" style={{ color: "white", display: "inline-block" }}>{(iconInfo.T3fiz - iconInfo.T4fiz).toFixed(2)}</Typography>*/}
                            <Typography variant="body1" className='aside-panel-text' style={{ marginRight: '20px' }}>{iconInfo.TCR} s</Typography>
                            <img src={timer} alt="" title="" className='timer' />
                            <img src={delta} alt="" title="" className='delta' />
                            <Typography variant="body1" className='aside-panel-text' style={{ marginLeft: '5%', marginTop: '-3px' }}>{`${(iconInfo.T3fiz - iconInfo.T4fiz).toFixed(1)} / ${(iconInfo.T6fiz - iconInfo.T5fiz).toFixed(1)}`}</Typography>
                        </Grid>
                        <Grid item className='aside-panel-box'>
                        {((version_1 > 4) || (version_1 === 4 && version_2 > 92)) && (deviceName === 'ATMO_1' || deviceName === 'ALLINONE_1') && iconInfo.SXB_F4DP === 1 ? 
                            iconInfo.SXB_4DR === 1 ?
                                <img src={bateria_uszkodzona} alt="" title="" className='bateria-uszkodzona' /> 
                                : <img src={bateria_sprawna} alt="" title="" className='bateria-sprawna' />
                            : null
                        }
                        {iconInfo.ANL_3PH === 1 ? <img src={legionella_3} alt="" title="" className={iconInfo.VER_S1 >= 4 && iconInfo.VER_S2 >= 92 && iconInfo.SXB_F4DP === 1 ? 'legionella-bateria' : 'legionella'} />
                            : iconInfo.ANL_2PH === 1 ? <img src={legionella_2} alt="" title="" className={iconInfo.VER_S1 >= 4 && iconInfo.VER_S2 >= 92 && iconInfo.SXB_F4DP === 1 ? 'legionella-bateria' : 'legionella'} />
                                : iconInfo.ANL_1PH === 1 ? <img src={legionella_1} alt="" title="" className={iconInfo.VER_S1 >= 4 && iconInfo.VER_S2 >= 92 && iconInfo.SXB_F4DP === 1 ? 'legionella-bateria' : 'legionella'} /> 
                                    : <img src={legionella_off} alt="" title="" className={iconInfo.VER_S1 >= 4 && iconInfo.VER_S2 >= 92 && iconInfo.SXB_F4DP === 1 ? 'legionella-bateria' : 'legionella'} /> }
                            <Typography variant="body1" className='aside-panel-text' style={{ marginTop: '3%' }}>ANTYLGNL</Typography>
                        </Grid>
                </Grid> : null}
            </Grid>
        </React.Fragment>
    )
}

export default AsideMenu