import React, { useReducer, useEffect, useState } from 'react'
import { Grid, Typography, Slider, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { registerDictionary } from '../../../../data/dataDictionary'
import { dataRange } from '../../../../data/dataRange'
import { CarouselInputMulti } from '../../../../components'
import { ItemMapping } from '../../../../components'

const EcoMode = ({ eco, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(eco)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", type: "", min: 0, max: 0, val: 0 })

    const dictionary = registerDictionary()
    
    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }
    
    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }
    
    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    useEffect(() => {
        let mounted = true
        setItemValue(eco)
        return () => {
            mounted = false
        }
    }, [eco])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">Tryb ECO</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Ustawienia i stan zmiennych związanych z trybem ECO</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body2">Godziny obowiązywania taniej energii elektrycznej (II taryfa)</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3} >
                    <Typography variant="body2">Rano</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body2" className={"carousel-input-click-text2"} style={{ color: 'grey', borderColor: 'grey' }}>Start</Typography>
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ color: 'grey', borderColor: 'grey', margin: '0px auto', boxSizing: 'content-box' }}>00</Typography>
                        </Grid>
                        <Grid item xs={6} onClick={() => handleClickOpen(itemValue.modeTime.ECOT1S, "ECOT1S", "Ustaw godzinę końca", dataRange().ECOT1S.min, itemValue.MIESIAC > 3 && itemValue.MIESIAC < 10 ? itemValue.modeTime.ECOT2R_L - 1 : itemValue.modeTime.ECOT2R_Z - 1, dataRange().ECOT1S.step ? dataRange().ECOT1S.step : 1, "ECOT1S", itemValue.modeTime.ECOT1S, 'modeTime')}>
                            <Typography variant="body2" className={"carousel-input-click-text2"}>Stop</Typography>
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{itemValue.modeTime.ECOT1S < 10 ? `0${itemValue.modeTime.ECOT1S}` : `${itemValue.modeTime.ECOT1S}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="body2">Po południu {itemValue && itemValue.modyficators.SXF_AZP === 0 ? null : "(zima)"}</Typography>
                     <Grid container>
                         <Grid item xs={6} onClick={() => handleClickOpen(itemValue.modeTime.ECOT2R_Z, "ECOT2R_Z", "Ustaw godzinę rozpoczęcia", itemValue.modeTime.ECOT1S + 1, itemValue.modeTime.ECOT2S_Z - 1, dataRange().ECOT2S_Z.step ? dataRange().ECOT2S_Z.step : 1, "ECOT2S_Z", itemValue.modeTime.ECOT2S_Z, 'modeTime')}>
                             <Typography variant="body2" className={"carousel-input-click-text2"}>Start</Typography>
                             <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{itemValue.modeTime.ECOT2R_Z < 10 ? `0${itemValue.modeTime.ECOT2R_Z}` : `${itemValue.modeTime.ECOT2R_Z}`}</Typography>
                         </Grid>
                         <Grid item xs={6} onClick={() => handleClickOpen(itemValue.modeTime.ECOT2S_Z, "ECOT2S_Z", "Ustaw godzinę końca", itemValue.modeTime.ECOT2R_Z + 1, itemValue.modeTime.ECOT3R - 1, dataRange().ECOT3R.step ? dataRange().ECOT3R.step : 1, "ECOT2S_Z", itemValue.modeTime.ECOT2S_Z, 'modeTime')}>
                             <Typography variant="body2" className={"carousel-input-click-text2"}>Stop</Typography>
                             <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{itemValue.modeTime.ECOT2S_Z < 10 ? `0${itemValue.modeTime.ECOT2S_Z}` : `${itemValue.modeTime.ECOT2S_Z}`}</Typography>
                         </Grid>
                     </Grid>
                 </Grid>
                <Grid item xs={3}>
                {itemValue && itemValue.modyficators.SXF_AZP === 1 ? <React.Fragment>
                    <Typography variant="body2">Po południu (lato)</Typography>
                    <Grid container>
                        <Grid item xs={6} onClick={() => handleClickOpen(itemValue.modeTime.ECOT2R_L, "ECOT2R_L", "Ustaw godzinę rozpoczęcia", itemValue.modeTime.ECOT1S + 1, itemValue.modeTime.ECOT2S_L - 1, dataRange().ECOT2R_L.step ? dataRange().ECOT2R_L.step : 1, "ECOT2R_L", itemValue.modeTime.ECOT2R_L, 'modeTime')}>
                            <Typography variant="body2" className={"carousel-input-click-text2"}>Start</Typography>
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{itemValue.modeTime.ECOT2R_L < 10 ? `0${itemValue.modeTime.ECOT2R_L}` : `${itemValue.modeTime.ECOT2R_L}`}</Typography>
                        </Grid>
                        <Grid item xs={6} onClick={() => handleClickOpen(itemValue.modeTime.ECOT2S_L, "ECOT2S_L", "Ustaw godzinę końca", itemValue.modeTime.ECOT2R_L + 1, itemValue.modeTime.ECOT3R - 1, dataRange().ECOT2S_L.step ? dataRange().ECOT2S_L.step : 1, "ECOT2S_L", itemValue.modeTime.ECOT2S_L, 'modeTime')}>
                            <Typography variant="body2" className={"carousel-input-click-text2"}>Stop</Typography>
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{itemValue.modeTime.ECOT2S_L < 10 ? `0${itemValue.modeTime.ECOT2S_L}` : `${itemValue.modeTime.ECOT2S_L}`}</Typography>
                        </Grid>
                    </Grid> </React.Fragment> : null }
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2">Wieczór</Typography>
                    <Grid container>
                        <Grid item xs={6} onClick={() => handleClickOpen(itemValue.modeTime.ECOT3R, "ECOT3R", "Ustaw datę rozpoczęcia", itemValue.MIESIAC > 3 && itemValue.MIESIAC < 10 ? itemValue.modeTime.ECOT2S_L + 1 : itemValue.modeTime.ECOT2S_Z + 1, dataRange().ECOT3R.max, dataRange().ECOT3R.step ? dataRange().EECOT3R.step : 1, "ECOT3R", itemValue.modeTime.ECOT3R,  'modeTime')}>
                            <Typography variant="body2" className={"carousel-input-click-text2"}>Start</Typography>
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ margin: '0px auto', boxSizing: 'content-box' }}>{itemValue.modeTime.ECOT3R < 10 ? `0${itemValue.modeTime.ECOT3R}` : `${itemValue.modeTime.ECOT3R}`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" className={"carousel-input-click-text2"} style={{ color: 'grey', borderColor: 'grey' }}>Stop</Typography>
                            <Typography variant="body2" className={"carousel-input-click2"} style={{ color: 'grey', borderColor: 'grey', margin: '0px auto', boxSizing: 'content-box' }}>24</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.eco.tempcwu.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.tempcwu, handleSwitchValue, handleClickOpen, dictionary.eco.tempcwu.data, classes, filteredItems(itemValue.tempcwu), 'tempcwu')}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.eco.temp.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.temp, handleSwitchValue, handleClickOpen, dictionary.eco.temp.data, classes, filteredItems(itemValue.temp), 'temp')}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography variant="h2">{dictionary.eco.modyficators.title}</Typography>
                    </Grid>
                    <Grid item>
                        {ItemMapping(itemValue.modyficators, handleSwitchValue, handleClickOpen, dictionary.eco.modyficators.data, classes, filteredItems(itemValue.modyficators), 'modyficators')}
                    </Grid>
                    <Grid container className={classes.containerVerticalCenter}   style={{ paddingRight: '24px' }}>
                        <Grid item xs={10}>
                            <Typography variant="body2">{dictionary.eco.SXB_ECACT}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.textRight}>{itemValue.SXB_ECACT === 0 ? "drogi prąd" : "tani prąd"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default EcoMode