export const registerValue = (receivedData) => {
    let data
    
    if (receivedData !== undefined) { 
        const [tabData] = receivedData; 
        data = tabData.Data;

        let registries = { 
            device: {
                Name: tabData.Name,
                Type: tabData.Type,
                Version: tabData.Version,
            },
            errors: {
                Errors: parseInt(tabData.Errors),
            },
            iconInfo: {
                SXB_WCL: parseInt(data.SXB_WCL),
                SXB_WHT: parseInt(data.SXB_WHT),
                SXB_WDHW: parseInt(data.SXB_WDHW),
                ZM_CUR_OP_MOD: parseInt(data.ZM_CUR_OP_MOD),
                XZM_TRANSM: parseInt(data.XZM_TRANSM),
                VER_S1: parseInt(data.VER_S1),
                VER_S2: parseInt(data.VER_S2.slice(0, 2)),
                PWM_1: parseFloat(data.PWM_1),
                SIO_R08: parseInt(data.SIO_R08),
                SWEXT_FIXREG: parseInt(data.SWEXT_FIXREG),
                DETE: parseInt(data.DETE),
                ANL_1PH: parseInt(data.ANL_1PH),
                ANL_2PH: parseInt(data.ANL_2PH),
                ANL_3PH: parseInt(data.ANL_3PH),
                SXB_PV: parseInt(data.SXB_PV),
                T3fiz: parseFloat(data.T3fiz),
                T4fiz: parseFloat(data.T4fiz),
                T5fiz: parseFloat(data.T5fiz),
                T6fiz: parseFloat(data.T6fiz),
                TCH: parseInt(data.TCH),
                TCR: parseInt(data.TCR),
                SWEXT_ZBCRO: parseInt(data.SWEXT_ZBCRO),
                SIO_R01: parseInt(data.SIO_R01),
                SIO_R11: parseInt(data.SIO_R11),
                ZM_CUR_COMP_FREQ: parseInt(data.ZM_CUR_COMP_FREQ),
                SXB_F4DP: parseInt(data.SXB_F4DP),
                SXB_4DR: parseInt(data.SXB_4DR),
            },
            home: {
                heatingControlCWU: {
                    map: {
                        TSM15: parseFloat(data.TSM15),
                        SPDHW_ZAD: parseFloat(data.SPDHW_ZAD),
                        SPDHW_HIST: parseInt(data.SPDHW_HIST),
                        SCDHW: parseFloat(data.SCDHW),
                        SX5: parseFloat(data.SX5),
                    },
                },
                internalTempControl: {
                    map: {
                        TSM10: parseFloat(data.TSM10),
                        SPHT_ZAD: parseFloat(data.SPHT_ZAD),
                        SPHT_HIST: parseFloat(data.SPHT_HIST),
                        SCHT: parseFloat(data.SCHT),
                        SX6: parseFloat(data.SX6),
                        COC: parseInt(data.COC),
                    },
                },
                heatingCoolingControlCrot: {
                    map: {
                        TSM9: parseFloat(data.TSM9),
                        TOUT_KOR: parseFloat(data.TOUT_KOR),
                        TOUT_NUM: parseInt(data.TOUT_NUM),
                        SX4_PH: parseInt(data.SX4_PH),
                        SX4_PC: parseInt(data.SX4_PC),
                    },
                },
                coldReceptionControl: {
                    map: {
                        TSM14: parseFloat(data.TSM14),
                        SPRMIN_ZAD: parseFloat(data.SPRMIN_ZAD),
                        SPRMIN_HIST: parseInt(data.SPRMIN_HIST),
                    },
                },
                othersTemp: {
                    map: {
                        TSM16: parseFloat(data.TSM16),
                        T3fiz: parseFloat(data.T3fiz),
                        T4fiz: parseFloat(data.T4fiz),
                        SPRDIS_ZAD: parseFloat(data.SPRDIS_ZAD),
                        SPRDIS_HIST: parseFloat(data.SPRDIS_HIST),
                    },
                },
                heatReceptionControlMax: {
                    map: {
                        TSM13: parseFloat(data.TSM13),
                        SPRMAX_ZAD: parseFloat(data.SPRMAX_ZAD),
                        SPRMAX_HIST: parseInt(data.SPRMAX_HIST),
                    },
                },
                sensorEjaculationControlMax: {
                    map: {
                        TSM12: parseFloat(data.TSM12),
                        SPSMIN_ZAD: parseFloat(data.SPSMIN_ZAD),
                        SPSMIN_HIST: parseInt(data.SPSMIN_HIST),
                    },
                    SPSMIN_ZNAK: parseInt(data.SPSMIN_ZNAK),
                },
                refrigerationSystem: {
                    map: {
                        ZM_PRES_EVAP: parseFloat(data.ZM_PRES_EVAP),
                        ZM_PRES_COND: parseFloat(data.ZM_PRES_COND),
                    },
                },
                heatingCurveControl: {
                    map: {
                        TSM17: parseFloat(data.TSM17),
                        TSM13: parseFloat(data.TSM13),
                        CUN_HCU: parseInt(data.CUN_HCU),
                        CUN_D_HCU: parseFloat(data.CUN_D_HCU),
                        SPCU: parseFloat(data.SPCU),
                        SX1: parseFloat(data.SX1),
                        TB1: parseFloat(data.TB1),
                        SX3_HG: parseInt(data.SX3_HG),
                    },
                },
                coolingCurveControl: {
                    map: {
                        TSM17: parseFloat(data.TSM17),
                        TSM14: parseFloat(data.TSM14),
                        SX2_P1: parseInt(data.SX2_P1),
                        SX2_P2: parseInt(data.SX2_P2),
                        SCCU: parseFloat(data.SCCU),
                        SX1: parseFloat(data.SX1),
                        TB2: parseFloat(data.TB2),
                        SX3_HC: parseInt(data.SX3_HC),
                    },
                },
                errorWarnings: {
                    map: {
                        REW_ERR: parseInt(data.REW_ERR),
                        REW_LPH: parseInt(data.REW_LPH),
                        REW_HP: parseInt(data.REW_HP),
                        REW_FLO: parseInt(data.REW_FLO),
                        REW_DIS: parseInt(data.REW_DIS),
                        REW_RMD: parseInt(data.REW_RMD),
                        REW_WRN: parseInt(data.REW_WRN),
                        REW_SMI: parseInt(data.REW_SMI),
                        REW_RMI: parseInt(data.REW_RMI),
                        REW_RMA: parseInt(data.REW_RMA),
                        REW_SMA: parseInt(data.REW_SMA),
                        REW_LPC: parseInt(data.REW_LPC),
                        REW_SUP: parseInt(data.REW_SUP),
                        REW_TER: parseInt(data.REW_TER),
                        MB_COMM2_XZM_TRANSM: parseInt(data.MB_COMM2_XZM_TRANSM),
                        REW_SMD: parseInt(data.REW_SMD),
                        REW_FCS: parseInt(data.REW_FCS),  
                    },
                },
                devicesPeriphery: {
                    map: {
                        SIO_R06: parseInt(data.SIO_R06),
                        SIO_R08: parseInt(data.SIO_R08),
                        SIO_R09: parseInt(data.SIO_R09),
                        SIO_R10: parseInt(data.SIO_R10),
                        SIO_R02: parseInt(data.SIO_R02),
                        SIO_R05: parseInt(data.SIO_R05),
                        SIO_R03: parseInt(data.SIO_R03),
                        SIO_R11: parseInt(data.SIO_R11),
                        SIO_R01: parseInt(data.SIO_R01),
                        SIO_R07: parseInt(data.SIO_R07),
                        SIO_R04: parseInt(data.SIO_R04),
                    },
                },
                permits: {
                    map: {
                        SBF_OOF: parseInt(data.SBF_OOF),
                        SBF_DHX: parseInt(data.SBF_DHX),
                        SBF_DHS: parseInt(data.SBF_DHS),
                        SBF_HTX: parseInt(data.SBF_HTX),
                        SBF_HTS: parseInt(data.SBF_HTS),
                        SBF_ACX: parseInt(data.SBF_ACX),
                        SBF_ACS: parseInt(data.SBF_ACS),
                        SXF_TOE: parseInt(data.SXF_TOE),
                        SBF_FCX: parseInt(data.SBF_FCX),
                        SBF_FCS: parseInt(data.SBF_FCS),
                    },
                },
                modes: {
                    map: {
                        SXF_ECO: parseInt(data.SXF_ECO),
                        SXF_TIME: parseInt(data.SXF_TIME),
                        SXF_AWA: parseInt(data.SXF_AWA),
                    },
                },
                coolignSource: {
                    map: {
                        TSM12: parseFloat(data.TSM12),
                        SPSMAX_ZAD: parseFloat(data.SPSMAX_ZAD),
                        SPSMAX_HIST: parseInt(data.SPSMAX_HIST),
                    }
                },
                acfcControll: {
                    map: {
                        TSM11: parseFloat(data.TSM11),
                        SCCU: parseFloat(data.SCCU),
                    }
                },
                outdoorTempControl: {
                    map: {
                        TSM14: parseFloat(data.TSM14),
                        MAX_RET_TEMP: 54,
                        MAX_RET_TEMP_HIST: 2,
                    }
                }
            },
            wizzard: {
                factorySettings: {
                    DEFA_ST1: parseInt(data.DEFA_ST1),
                    DEFA_ST2: parseInt(data.DEFA_ST2),
                },
                dateTimeSettings: {
                    map: {
                       SBB_MAIN: parseInt(data.SBB_MAIN), 
                       SBB_QCS: parseInt(data.SBB_QCS),
                       SXB_F4D: parseInt(data.SXB_F4D),
                       SXB_F4DP: parseInt(data.SXB_F4DP),
                       TCR: parseInt(data.TCR),
                       TCH: parseInt(data.TCH),
                       TRR: parseInt(data.TRR),
                       TSR: parseInt(data.TSR),
                       TRS: parseInt(data.TRS),
                       TSS: parseInt(data.TSS),
                       TB3: parseInt(data.TB3),
                    },
                    ST_GODZINY: parseInt(data.ST_GODZINY),
                    ST_MINUTY: parseInt(data.ST_MINUTY),
                    ST_SEKUNDY: parseInt(data.ST_SEKUNDY),
                    SXF_TIM_WIN_SW: parseInt(data.SXF_TIM_WIN_SW),
                    SXF_TIM_WIN_FL: parseInt(data.SXF_TIM_WIN_FL),
                    ST_DZIEN: parseInt(data.ST_DZIEN),
                    ST_MIESIAC: parseInt(data.ST_MIESIAC),
                    ST_ROK: parseInt(data.ST_ROK),
                    ST_DZEN_TYG: parseInt(data.ST_DZEN_TYG),
                    GODZINY: parseInt(data.GODZINY),
                    MINUTY: parseInt(data.MINUTY),
                    SEKUNDY: parseInt(data.SEKUNDY),
                    DZIEN: parseInt(data.DZIEN),
                    MIESIAC: parseInt(data.MIESIAC),
                    ROK: parseInt(data.ROK),
                    DZIEN_TYG: parseInt(data.DZIEN_TYG),
                    TIME_WEEK: parseInt(data.TIME_WEEK),
                    TIME_FST: parseInt(data.TIME_FST),
                },
                deletionErrors: {
                    UWNA: {
                        UWNA_1: parseInt(data.UWNA_1),
                        UWNA_2: parseInt(data.UWNA_2),
                        UWNA_3: parseInt(data.UWNA_3),
                        UWNA_4: parseInt(data.UWNA_4),
                        UWNA_5: parseInt(data.UWNA_5),
                        UWNA_6: parseInt(data.UWNA_6),
                        UWNA_7: parseInt(data.UWNA_7),
                        UWNA_8: parseInt(data.UWNA_8),
                        UWNA_9: parseInt(data.UWNA_9),
                        UWNA_10: parseInt(data.UWNA_10),
                    },
                    UWNB: {
                        UWNB_1: parseInt(data.UWNB_1),
                        UWNB_2: parseInt(data.UWNB_2),
                        UWNB_3: parseInt(data.UWNB_3),
                        UWNB_4: parseInt(data.UWNB_4),
                        UWNB_5: parseInt(data.UWNB_5),
                        UWNB_6: parseInt(data.UWNB_6),
                        UWNB_7: parseInt(data.UWNB_7),
                        UWNB_8: parseInt(data.UWNB_8),
                        UWNB_9: parseInt(data.UWNB_9),
                        UWNB_10: parseInt(data.UWNB_10),
                    },
                    UWNC: {
                        UWNC_1: parseInt(data.UWNC_1),
                        UWNC_2: parseInt(data.UWNC_2),
                        UWNC_3: parseInt(data.UWNC_3),
                        UWNC_4: parseInt(data.UWNC_4),
                        UWNC_5: parseInt(data.UWNC_5),
                        UWNC_6: parseInt(data.UWNC_6),
                        UWNC_7: parseInt(data.UWNC_7),
                        UWNC_8: parseInt(data.UWNC_8),
                        UWNC_9: parseInt(data.UWNC_9),
                        UWNC_10: parseInt(data.UWNC_10),
                    },
                    UWND: {
                        UWND_1: parseInt(data.UWND_1),
                        UWND_2: parseInt(data.UWND_2),
                        UWND_3: parseInt(data.UWND_3),
                        UWND_4: parseInt(data.UWND_4),
                        UWND_5: parseInt(data.UWND_5),
                        UWND_6: parseInt(data.UWND_6),
                        UWND_7: parseInt(data.UWND_7),
                        UWND_8: parseInt(data.UWND_8),
                        UWND_9: parseInt(data.UWND_9),
                        UWND_10: parseInt(data.UWND_10),
                    },
                    UWNE: {
                        UWNE_1: parseFloat(data.UWNE_1),
                        UWNE_2: parseFloat(data.UWNE_2),
                        UWNE_3: parseFloat(data.UWNE_3),
                        UWNE_4: parseFloat(data.UWNE_4),
                        UWNE_5: parseFloat(data.UWNE_5),
                        UWNE_6: parseFloat(data.UWNE_6),
                        UWNE_7: parseFloat(data.UWNE_7),
                        UWNE_8: parseFloat(data.UWNE_8),
                        UWNE_9: parseFloat(data.UWNE_9),
                        UWNE_10: parseFloat(data.UWNE_10),
                    },
                    UWNF: {
                        UWNF_1: parseFloat(data.UWNF_1),
                        UWNF_2: parseFloat(data.UWNF_2),
                        UWNF_3: parseFloat(data.UWNF_3),
                        UWNF_4: parseFloat(data.UWNF_4),
                        UWNF_5: parseFloat(data.UWNF_5),
                        UWNF_6: parseFloat(data.UWNF_6),
                        UWNF_7: parseFloat(data.UWNF_7),
                        UWNF_8: parseFloat(data.UWNF_8),
                        UWNF_9: parseFloat(data.UWNF_9),
                        UWNF_10: parseFloat(data.UWNF_10),
                    },
                    UWNG: {
                        UWNG_1: parseFloat(data.UWNG_1),
                        UWNG_2: parseFloat(data.UWNG_2),
                        UWNG_3: parseFloat(data.UWNG_3),
                        UWNG_4: parseFloat(data.UWNG_4),
                        UWNG_5: parseFloat(data.UWNG_5),
                        UWNG_6: parseFloat(data.UWNG_6),
                        UWNG_7: parseFloat(data.UWNG_7),
                        UWNG_8: parseFloat(data.UWNG_8),
                        UWNG_9: parseFloat(data.UWNG_9),
                        UWNG_10: parseFloat(data.UWNG_10),
                    },
                    UWNH: {
                        UWNH_1: parseFloat(data.UWNH_1),
                        UWNH_2: parseFloat(data.UWNH_2),
                        UWNH_3: parseFloat(data.UWNH_3),
                        UWNH_4: parseFloat(data.UWNH_4),
                        UWNH_5: parseFloat(data.UWNH_5),
                        UWNH_6: parseFloat(data.UWNH_6),
                        UWNH_7: parseFloat(data.UWNH_7),
                        UWNH_8: parseFloat(data.UWNH_8),
                        UWNH_9: parseFloat(data.UWNH_9),
                        UWNH_10: parseFloat(data.UWNH_10),
                    },
                    UWNI: {
                        UWNI_1: parseFloat(data.UWNI_1),
                        UWNI_2: parseFloat(data.UWNI_2),
                        UWNI_3: parseFloat(data.UWNI_3),
                        UWNI_4: parseFloat(data.UWNI_4),
                        UWNI_5: parseFloat(data.UWNI_5),
                        UWNI_6: parseFloat(data.UWNI_6),
                        UWNI_7: parseFloat(data.UWNI_7),
                        UWNI_8: parseFloat(data.UWNI_8),
                        UWNI_9: parseFloat(data.UWNI_9),
                        UWNI_10: parseFloat(data.UWNI_10),
                    },
                    UWNJ: {
                        UWNJ_1: parseFloat(data.UWNJ_1),
                        UWNJ_2: parseFloat(data.UWNJ_2),
                        UWNJ_3: parseFloat(data.UWNJ_3),
                        UWNJ_4: parseFloat(data.UWNJ_4),
                        UWNJ_5: parseFloat(data.UWNJ_5),
                        UWNJ_6: parseFloat(data.UWNJ_6),
                        UWNJ_7: parseFloat(data.UWNJ_7),
                        UWNJ_8: parseFloat(data.UWNJ_8),
                        UWNJ_9: parseFloat(data.UWNJ_9),
                        UWNJ_10: parseFloat(data.UWNJ_10),
                    },
                    UWNK: {
                        UWNK_1: parseFloat(data.UWNK_1),
                        UWNK_2: parseFloat(data.UWNK_2),
                        UWNK_3: parseFloat(data.UWNK_3),
                        UWNK_4: parseFloat(data.UWNK_4),
                        UWNK_5: parseFloat(data.UWNK_5),
                        UWNK_6: parseFloat(data.UWNK_6),
                        UWNK_7: parseFloat(data.UWNK_7),
                        UWNK_8: parseFloat(data.UWNK_8),
                        UWNK_9: parseFloat(data.UWNK_9),
                        UWNK_10: parseFloat(data.UWNK_10),
                    },
                    UWNL: {
                        UWNL_1: parseFloat(data.UWNL_1),
                        UWNL_2: parseFloat(data.UWNL_2),
                        UWNL_3: parseFloat(data.UWNL_3),
                        UWNL_4: parseFloat(data.UWNL_4),
                        UWNL_5: parseFloat(data.UWNL_5),
                        UWNL_6: parseFloat(data.UWNL_6),
                        UWNL_7: parseFloat(data.UWNL_7),
                        UWNL_8: parseFloat(data.UWNL_8),
                        UWNL_9: parseFloat(data.UWNL_9),
                        UWNL_10: parseFloat(data.UWNL_10),
                    },
                    UWNM: {
                        UWNM_1_GODZ: parseInt(data.UWNM_1_GODZ),
                        UWNM_1_MIN: parseInt(data.UWNM_1_MIN),
                        UWNM_1_SEK: parseInt(data.UWNM_1_SEK),
                        UWNM_2_GODZ: parseInt(data.UWNM_2_GODZ),
                        UWNM_2_MIN: parseInt(data.UWNM_2_MIN),
                        UWNM_2_SEK: parseInt(data.UWNM_2_SEK),
                        UWNM_3_GODZ: parseInt(data.UWNM_3_GODZ),
                        UWNM_3_MIN: parseInt(data.UWNM_3_MIN),
                        UWNM_3_SEK: parseInt(data.UWNM_3_SEK),
                        UWNM_4_GODZ: parseInt(data.UWNM_4_GODZ),
                        UWNM_4_MIN: parseInt(data.UWNM_4_MIN),
                        UWNM_4_SEK: parseInt(data.UWNM_4_SEK),
                        UWNM_5_GODZ: parseInt(data.UWNM_5_GODZ),
                        UWNM_5_MIN: parseInt(data.UWNM_5_MIN),
                        UWNM_5_SEK: parseInt(data.UWNM_5_SEK),
                        UWNM_6_GODZ: parseInt(data.UWNM_6_GODZ),
                        UWNM_6_MIN: parseInt(data.UWNM_6_MIN),
                        UWNM_6_SEK: parseInt(data.UWNM_6_SEK),
                        UWNM_7_GODZ: parseInt(data.UWNM_7_GODZ),
                        UWNM_7_MIN: parseInt(data.UWNM_7_MIN),
                        UWNM_7_SEK: parseInt(data.UWNM_7_SEK),
                        UWNM_8_GODZ: parseInt(data.UWNM_8_GODZ),
                        UWNM_8_MIN: parseInt(data.UWNM_8_MIN),
                        UWNM_8_SEK: parseInt(data.UWNM_8_SEK),
                        UWNM_9_GODZ: parseInt(data.UWNM_9_GODZ),
                        UWNM_9_MIN: parseInt(data.UWNM_9_MIN),
                        UWNM_9_SEK: parseInt(data.UWNM_9_SEK),
                        UWNM_10_GODZ: parseInt(data.UWNM_10_GODZ),
                        UWNM_10_MIN: parseInt(data.UWNM_10_MIN),
                        UWNM_10_SEK: parseInt(data.UWNM_10_SEK),
                    },
                    UWNN: {
                        UWNN_1_DZ: parseInt(data.UWNN_1_DZ),
                        UWNN_1_MSC: parseInt(data.UWNN_1_MSC),
                        UWNN_1_ROK: parseInt(data.UWNN_1_ROK),
                        UWNN_1_AM_PM: parseInt(data.UWNN_1_AM_PM),
                        UWNN_2_DZ: parseInt(data.UWNN_2_DZ),
                        UWNN_2_MSC: parseInt(data.UWNN_2_MSC),
                        UWNN_2_ROK: parseInt(data.UWNN_2_ROK),
                        UWNN_2_AM_PM: parseInt(data.UWNN_2_AM_PM),
                        UWNN_3_DZ: parseInt(data.UWNN_3_DZ),
                        UWNN_3_MSC: parseInt(data.UWNN_3_MSC),
                        UWNN_3_ROK: parseInt(data.UWNN_3_ROK),
                        UWNN_3_AM_PM: parseInt(data.UWNN_3_AM_PM),
                        UWNN_4_DZ: parseInt(data.UWNN_4_DZ),
                        UWNN_4_MSC: parseInt(data.UWNN_4_MSC),
                        UWNN_4_ROK: parseInt(data.UWNN_4_ROK),
                        UWNN_4_AM_PM: parseInt(data.UWNN_4_AM_PM),
                        UWNN_5_DZ: parseInt(data.UWNN_5_DZ),
                        UWNN_5_MSC: parseInt(data.UWNN_5_MSC),
                        UWNN_5_ROK: parseInt(data.UWNN_5_ROK),
                        UWNN_5_AM_PM: parseInt(data.UWNN_5_AM_PM),
                        UWNN_6_DZ: parseInt(data.UWNN_6_DZ),
                        UWNN_6_MSC: parseInt(data.UWNN_6_MSC),
                        UWNN_6_ROK: parseInt(data.UWNN_6_ROK),
                        UWNN_6_AM_PM: parseInt(data.UWNN_6_AM_PM),
                        UWNN_7_DZ: parseInt(data.UWNN_7_DZ),
                        UWNN_7_MSC: parseInt(data.UWNN_7_MSC),
                        UWNN_7_ROK: parseInt(data.UWNN_7_ROK),
                        UWNN_7_AM_PM: parseInt(data.UWNN_7_AM_PM),
                        UWNN_8_DZ: parseInt(data.UWNN_8_DZ),
                        UWNN_8_MSC: parseInt(data.UWNN_8_MSC),
                        UWNN_8_ROK: parseInt(data.UWNN_8_ROK),
                        UWNN_8_AM_PM: parseInt(data.UWNN_8_AM_PM),
                        UWNN_9_DZ: parseInt(data.UWNN_9_DZ),
                        UWNN_9_MSC: parseInt(data.UWNN_9_MSC),
                        UWNN_9_ROK: parseInt(data.UWNN_9_ROK),
                        UWNN_9_AM_PM: parseInt(data.UWNN_9_AM_PM),
                        UWNN_10_DZ: parseInt(data.UWNN_10_DZ),
                        UWNN_10_MSC: parseInt(data.UWNN_10_MSC),
                        UWNN_10_ROK: parseInt(data.UWNN_10_ROK),
                        UWNN_10_AM_PM: parseInt(data.UWNN_10_AM_PM),
                    },
                    VER_S1: parseInt(data.VER_S1),
                    VER_S2: parseInt(data.VER_S2),
                    SIO_B03: parseInt(data.SIO_B03),
                },
                deletionStatistics: {
                    SS0: {
                        PDHWT1: parseInt(data.PDHWT1),
                        PDHWT2: parseInt(data.PDHWT2),
                        PHTT1: parseInt(data.PHTT1),
                        PHTT2: parseInt(data.PHTT2),
                        PACT1: parseInt(data.PACT1),
                        PACT2: parseInt(data.PACT2),
                        PFC: parseInt(data.PFC),
                    },
                    SS1: {
                        SS1_PDHWT1: parseInt(data.SS1_PDHWT1),
                        SS1_PDHWT2: parseInt(data.SS1_PDHWT2),
                        SS1_PHTT1: parseInt(data.SS1_PHTT1),
                        SS1_PHTT2: parseInt(data.SS1_PHTT2),
                        SS1_PACT1: parseInt(data.SS1_PACT1),
                        SS1_PACT2: parseInt(data.SS1_PACT2),
                        SS1_PFC: parseInt(data.SS1_PFC),
                    },
                    SS2: {
                        SS2_PDHWT1: parseInt(data.SS2_PDHWT1),
                        SS2_PDHWT2: parseInt(data.SS2_PDHWT2),
                        SS2_PHTT1: parseInt(data.SS2_PHTT1),
                        SS2_PHTT2: parseInt(data.SS2_PHTT2),
                        SS2_PACT1: parseInt(data.SS2_PACT1),
                        SS2_PACT2: parseInt(data.SS2_PACT2),
                        SS2_PFC: parseInt(data.SS2_PFC),
                    },
                    SS3: {
                        SS3_PDHWT1: parseInt(data.SS3_PDHWT1),
                        SS3_PDHWT2: parseInt(data.SS3_PDHWT2),
                        SS3_PHTT1: parseInt(data.SS3_PHTT1),
                        SS3_PHTT2: parseInt(data.SS3_PHTT2),
                        SS3_PACT1: parseInt(data.SS3_PACT1),
                        SS3_PACT2: parseInt(data.SS3_PACT2),
                        SS3_PFC: parseInt(data.SS3_PFC),
                    },
                    SS4: {
                        SS4_PDHWT1: parseInt(data.SS4_PDHWT1),
                        SS4_PDHWT2: parseInt(data.SS4_PDHWT2),
                        SS4_PHTT1: parseInt(data.SS4_PHTT1),
                        SS4_PHTT2: parseInt(data.SS4_PHTT2),
                        SS4_PACT1: parseInt(data.SS4_PACT1),
                        SS4_PACT2: parseInt(data.SS4_PACT2),
                        SS4_PFC: parseInt(data.SS4_PFC),
                    },
                    SS5: {
                        SS5_PDHWT1: parseInt(data.SS5_PDHWT1),
                        SS5_PDHWT2: parseInt(data.SS5_PDHWT2),
                        SS5_PHTT1: parseInt(data.SS5_PHTT1),
                        SS5_PHTT2: parseInt(data.SS5_PHTT2),
                        SS5_PACT1: parseInt(data.SS5_PACT1),
                        SS5_PACT2: parseInt(data.SS5_PACT2),
                        SS5_PFC: parseInt(data.SS5_PFC),
                    },
                    SS6: {
                        SS6_PDHWT1: parseInt(data.SS6_PDHWT1),
                        SS6_PDHWT2: parseInt(data.SS6_PDHWT2),
                        SS6_PHTT1: parseInt(data.SS6_PHTT1),
                        SS6_PHTT2: parseInt(data.SS6_PHTT2),
                        SS6_PACT1: parseInt(data.SS6_PACT1),
                        SS6_PACT2: parseInt(data.SS6_PACT2),
                        SS6_PFC: parseInt(data.SS6_PFC),
                    },
                    SS7: {
                        SS7_PDHWT1: parseInt(data.SS7_PDHWT1),
                        SS7_PDHWT2: parseInt(data.SS7_PDHWT2),
                        SS7_PHTT1: parseInt(data.SS7_PHTT1),
                        SS7_PHTT2: parseInt(data.SS7_PHTT2),
                        SS7_PACT1: parseInt(data.SS7_PACT1),
                        SS7_PACT2: parseInt(data.SS7_PACT2),
                        SS7_PFC: parseInt(data.SS7_PFC),
                    },
                    SS8: {
                        SS8_PDHWT1: parseInt(data.SS8_PDHWT1),
                        SS8_PDHWT2: parseInt(data.SS8_PDHWT2),
                        SS8_PHTT1: parseInt(data.SS8_PHTT1),
                        SS8_PHTT2: parseInt(data.SS8_PHTT2),
                        SS8_PACT1: parseInt(data.SS8_PACT1),
                        SS8_PACT2: parseInt(data.SS8_PACT2),
                        SS8_PFC: parseInt(data.SS8_PFC),
                    },
                    SS9: {
                        SS9_PDHWT1: parseInt(data.SS9_PDHWT1),
                        SS9_PDHWT2: parseInt(data.SS9_PDHWT2),
                        SS9_PHTT1: parseInt(data.SS9_PHTT1),
                        SS9_PHTT2: parseInt(data.SS9_PHTT2),
                        SS9_PACT1: parseInt(data.SS9_PACT1),
                        SS9_PACT2: parseInt(data.SS9_PACT2),
                        SS9_PFC: parseInt(data.SS9_PFC),
                    },
                    SS10: {
                        SS10_PDHWT1: parseInt(data.SS10_PDHWT1),
                        SS10_PDHWT2: parseInt(data.SS10_PDHWT2),
                        SS10_PHTT1: parseInt(data.SS10_PHTT1),
                        SS10_PHTT2: parseInt(data.SS10_PHTT2),
                        SS10_PACT1: parseInt(data.SS10_PACT1),
                        SS10_PACT2: parseInt(data.SS10_PACT2),
                        SS10_PFC: parseInt(data.SS10_PFC),
                    },
                    SS11: {
                        SS11_PDHWT1: parseInt(data.SS11_PDHWT1),
                        SS11_PDHWT2: parseInt(data.SS11_PDHWT2),
                        SS11_PHTT1: parseInt(data.SS11_PHTT1),
                        SS11_PHTT2: parseInt(data.SS11_PHTT2),
                        SS11_PACT1: parseInt(data.SS11_PACT1),
                        SS11_PACT2: parseInt(data.SS11_PACT2),
                        SS11_PFC: parseInt(data.SS11_PFC),
                    },
                    SS12: {
                        SS12_PDHWT1: parseInt(data.SS12_PDHWT1),
                        SS12_PDHWT2: parseInt(data.SS12_PDHWT2),
                        SS12_PHTT1: parseInt(data.SS12_PHTT1),
                        SS12_PHTT2: parseInt(data.SS12_PHTT2),
                        SS12_PACT1: parseInt(data.SS12_PACT1),
                        SS12_PACT2: parseInt(data.SS12_PACT2),
                        SS12_PFC: parseInt(data.SS12_PFC),
                    },
                    compressorStartCount: {
                        CORSTA_SPRMAX: parseInt(data.CORSTA_SPRMAX),
                        CORSTA_SPRMIN: parseInt(data.CORSTA_SPRMIN),
                        CORSTA_SPSMAX: parseInt(data.CORSTA_SPSMAX),
                    },
                    peakSourceTime: {
                        TIME_FST: parseInt(data.TIME_FST),
                        TIME_WEEK: parseInt(data.TIME_WEEK),
                        CORSTA: parseInt(data.CORSTA),
                        CORSTA_SPSMIN: parseInt(data.CORSTA_SPSMIN)
                    },
                    MIESIAC: parseInt(data.MIESIAC),
                    ROK: parseInt(data.ROK),
                },
                distributionCwuCo: {
                    SXF_DHM: parseInt(data.SXF_DHM),
                },
                coopInstallation: {
                    SWEXT_BUF: parseInt(data.SWEXT_BUF),
                },
                parameterOptimization: {
                    TOUT_NUM: parseInt(data.TOUT_NUM),
                    COC: parseInt(data.COC),
                    CUN_HCU: parseInt(data.CUN_HCU),
                },
                glycolInSystem: {
                    SPSMIN_HIST: parseInt(data.SPSMIN_HIST),
                    SPSMIN_ZNAK: parseInt(data.SPSMIN_ZNAK),
                    SPSMIN_ZAD: parseInt(data.SPSMIN_ZAD),
                },
                controlHysteresisTest: {
                    toMemory: {
                        SX4_PC: parseInt(data.SX4_PC),
                        SX4_PH: parseInt(data.SX4_PH),
                        SPHT_HIST: parseFloat(data.SPHT_HIST),
                        SPHT_ZAD: parseFloat(data.SPHT_ZAD),
                        CUN_HCU: parseInt(data.CUN_HCU),
                        CUN_D_HCU: parseFloat(data.CUN_D_HCU),
                        SXF_TIM_WIN_SW: parseInt(data.SXF_TIM_WIN_SW),
                        SXF_TIM_WIN_FL: parseInt(data.SXF_TIM_WIN_FL),
                        SXF_ACT: parseInt(data.SXF_ACT),
                        SXF_FLPCYR: parseInt(data.SXF_FLPCYR),
                        SXF_AZP: parseInt(data.SXF_AZP),
                        SXF_TIMCYR: parseInt(data.SXF_TIMCYR),
                        SXF_ENACYR: parseInt(data.SXF_ENACYR),
                        SXF_TOE: parseInt(data.SXF_TOE),
                        SXF_FORCYR: parseInt(data.SXF_FORCYR),
                        SXF_TIME: parseInt(data.SXF_TIME),
                        SXF_ANL: parseInt(data.SXF_ANL),
                        SXF_WEK: parseInt(data.SXF_WEK),
                        SXF_ECO: parseInt(data.SXF_ECO),
                        SXF_DHM: parseInt(data.SXF_DHM),
                        SXF_4EN: parseInt(data.SXF_4EN),
                        SBF_PHT: parseInt(data.SBF_PHT),
                        SBF_HTX: parseInt(data.SBF_HTX),
                        SBF_HTS: parseInt(data.SBF_HTS),
                        SBF_DHX: parseInt(data.SBF_DHX),
                        SBF_DHS: parseInt(data.SBF_DHS),
                        SBF_FCX: parseInt(data.SBF_FCX),
                        SBF_FCS: parseInt(data.SBF_FCS),
                        SBF_OOF: parseInt(data.SBF_OOF),
                        SBF_AHX: parseInt(data.SBF_AHX),
                        SBF_AHS: parseInt(data.SBF_AHS),
                        SBF_ACX: parseInt(data.SBF_ACX),
                        SBF_ACS: parseInt(data.SBF_ACS),
                        ANL_ALL: parseInt(data.ANL_ALL),
                        ANL_1PH: parseInt(data.ANL_1PH),
                        ANL_2PH: parseInt(data.ANL_2PH),
                        ANL_3PH: parseInt(data.ANL_3PH),
                    },
                    TSM9: parseFloat(data.TSM9),
                    TSM10: parseFloat(data.TSM10),
                    errors: {
                        REW_SUP: parseInt(data.REW_SUP),
                        REW_TER: parseInt(data.REW_TER),
                        REW_LPC: parseInt(data.REW_LPC),
                        REW_LPH: parseInt(data.REW_LPH),
                        REW_HP: parseInt(data.REW_HP),
                        REW_FLO: parseInt(data.REW_FLO),
                        REW_DIS: parseInt(data.REW_DIS),
                        REW_ERR: parseInt(data.REW_ERR),
                        REW_SMI: parseInt(data.REW_SMI),
                        REW_SMA: parseInt(data.REW_SMA),
                        REW_RMI: parseInt(data.REW_RMI),
                        REW_RMA: parseInt(data.REW_RMA),
                        REW_SMD: parseInt(data.REW_SMD),
                        REW_RMD: parseInt(data.REW_RMD),
                        REW_WRN: parseInt(data.REW_WRN),
                        REW_FCS: parseInt(data.REW_FCS),
                    },
                    SIO_R06: parseInt(data.SIO_R06),
                    TSM13: parseFloat(data.TSM13),
                    TSM14: parseFloat(data.TSM14),
                },
                selectionFunction: {
                    SBF_DHX: parseInt(data.SBF_DHX),
                    SBF_DHS: parseInt(data.SBF_DHS),
                    SBF_HTX: parseInt(data.SBF_HTX),
                    SBF_HTS: parseInt(data.SBF_HTS),
                    SBF_ACX: parseInt(data.SBF_ACX),
                    SBF_ACS: parseInt(data.SBF_ACS),
                    SBF_FCX: parseInt(data.SBF_FCX),
                    SBF_FCS: parseInt(data.SBF_FCS),
                    SBF_OOF: parseInt(data.SBF_OOF),
                },
                sensorInside: {
                    SWEXT_TWP: parseInt(data.SWEXT_TWP)
                },
                controlType: {
                    IDM_GRO: parseInt(data.IDM_GRO)
                }
            },
            emergencyStop: {
                status: {
                    OOF_HT: parseInt(data.OOF_HT),
                    OOF_DHW: parseInt(data.OOF_DHW),
                    OOF_CL: parseInt(data.OOF_CL),
                    OOF_PWR: parseInt(data.OOF_PWR),
                    SXB_FER: parseInt(data.SXB_FER),
                    SXB_HTO: parseInt(data.SXB_HTO),
                    SXB_DHWO: parseInt(data.SXB_DHWO),
                    SXB_CLO: parseInt(data.SXB_CLO),
                    SXB_PWRO: parseInt(data.SXB_PWRO),
                    SXB_4DR: parseInt(data.SXB_4DR),
                },
                settings :{
                    OOF_NUM: parseInt(data.OOF_NUM),
                }
            },
            antylegionella: {
                ANL_ALL: parseInt(data.ANL_ALL),
                ANL_1PH: parseInt(data.ANL_1PH),
                ANL_2PH: parseInt(data.ANL_2PH),
                ANL_3PH: parseInt(data.ANL_3PH),
                SBF_PHT: parseInt(data.SBF_PHT),
                SXF_ANL: parseInt(data.SXF_ANL),
                ANL_DAY: parseInt(data.ANL_DAY),
                // ANL_CLOCK: parseInt(data.ANL_CLOCK),
                TSM15: parseFloat(data.TSM15),
                ANL_SETP_ZAD: parseFloat(data.ANL_SETP_ZAD),
                ANL_SETP_HIST: parseInt(data.ANL_SETP_HIST),
                SIO_R01: parseInt(data.SIO_R01),
                SXF_ENACYR: parseInt(data.SXF_ENACYR),
                SIO_R11: parseInt(data.SIO_R11),
            },
            antyException: {
                ANL_CLOCK: parseInt(data.ANL_CLOCK),
            },
            heatingCurve: {
                parameters: {
                    TSM9: parseFloat(data.TSM9),
                    CUN_HCU: parseInt(data.CUN_HCU),
                    CUN_D_HCU: parseFloat(data.CUN_D_HCU),
                    SPCU: parseFloat(data.SPCU),
                    SX1: parseFloat(data.SX1),
                    TB1: parseFloat(data.TB1),
                    SX3_HG: parseInt(data.SX3_HG),
                },
                temp: {
                    TSM13: parseFloat(data.TSM13),
                },
                reg: {
                    TSM10: parseFloat(data.TSM10),
                    SX6: parseFloat(data.SX6),
                    COC: parseInt(data.COC),
                },
            },
            coolingCurve: {
                parameters: {
                    TSM9: parseFloat(data.TSM9),
                    SX2_P1: parseInt(data.SX2_P1),
                    SX2_P2: parseInt(data.SX2_P2),
                    SCCU: parseFloat(data.SCCU),
                    SX1: parseFloat(data.SX1),
                    TB2: parseFloat(data.TB2),
                    SX3_HC: parseInt(data.SX3_HC),
                },
                temp: {
                    TSM14: parseFloat(data.TSM14),
                },
                reg: {
                    TSM10: parseFloat(data.TSM10),
                    SX6: parseFloat(data.SX6),
                    COC: parseInt(data.COC),
                },
            },
            away: {
                controllCryteria: {
                    TSM9: parseFloat(data.TSM9),
                    SPHT_ZAD: parseFloat(data.SPHT_ZAD),
                    AWAY_COR: parseFloat(data.AWAY_COR),
                    SCHT: parseFloat(data.SCHT),
                    SX6: parseFloat(data.SX6),
                    SXB_PV: parseInt(data.SXB_PV),
                    SBB_QHOUT: parseFloat(data.SBB_QHOUT),
                    SBB_QCOUT: parseFloat(data.SBB_QCOUT),
                },
                dawayControll: {
                    SXF_AWA: parseInt(data.SXF_AWA),
                    AWAY_CLOCK: parseInt(data.AWAY_CLOCK),
                }, 
            },
            eco: {
                modeTime: {
                    ECOT1R: parseInt(data.ECOT1R),
                    ECOT1S: parseInt(data.ECOT1S),
                    ECOT2R_Z: parseInt(data.ECOT2R_Z),
                    ECOT2S_Z: parseInt(data.ECOT2S_Z),
                    ECOT2R_L: parseInt(data.ECOT2R_L),
                    ECOT2S_L: parseInt(data.ECOT2S_L),
                    ECOT3R: parseInt(data.ECOT3R),
                    ECOT3S: parseInt(data.ECOT3S),
                },
                tempcwu: {
                    ECOX1: parseFloat(data.ECOX1),
                    ECOX2: parseFloat(data.ECOX2),
                    ECODHW: parseFloat(data.ECODHW),
                },
                temp: {
                    ECOY1: parseFloat(data.ECOY1),
                    ECOY2: parseFloat(data.ECOY2),
                    ECOTIN: parseFloat(data.ECOTIN),
                },
                modyficators: {
                    SXF_ECO: parseInt(data.SXF_ECO),
                    SXF_WEK: parseInt(data.SXF_WEK),
                    SXF_AZP: parseInt(data.SXF_AZP),
                    SCDHW: parseFloat(data.SCDHW),
                    SCHT: parseFloat(data.SCHT),
                },
                SXB_ECACT: parseInt(data.SXB_ECACT),
                MIESIAC: parseInt(data.MIESIAC),
            },
            time: {
                T: {
                    PN: {
                        TW1: {
                            PG: parseInt(data.TWP1P_PG),
                            PM: parseInt(data.TWP1P_PM),
                            KG: parseInt(data.TWP1P_KG),
                            KM: parseInt(data.TWP1P_KM),
                            Temp: parseFloat(data.TWP1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWP2P_PG),
                            PM: parseInt(data.TWP2P_PM),
                            KG: parseInt(data.TWP2P_KG),
                            KM: parseInt(data.TWP2P_KM),
                            Temp: parseFloat(data.TWP2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWP3P_PG),
                            PM: parseInt(data.TWP3P_PM),
                            KG: parseInt(data.TWP3P_KG),
                            KM: parseInt(data.TWP3P_KM),
                            Temp: parseFloat(data.TWP3T),
                        },
                        TWTSW_PN: parseInt(data.TWTSW_PN),
                    },
                    WT: {
                        TW1: {
                            PG: parseInt(data.TWW1P_PG),
                            PM: parseInt(data.TWW1P_PM),
                            KG: parseInt(data.TWW1P_KG),
                            KM: parseInt(data.TWW1P_KM),
                            Temp: parseFloat(data.TWW1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWW2P_PG),
                            PM: parseInt(data.TWW2P_PM),
                            KG: parseInt(data.TWW2P_KG),
                            KM: parseInt(data.TWW2P_KM),
                            Temp: parseFloat(data.TWW2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWW3P_PG),
                            PM: parseInt(data.TWW3P_PM),
                            KG: parseInt(data.TWW3P_KG),
                            KM: parseInt(data.TWW3P_KM),
                            Temp: parseFloat(data.TWW3T),
                        },
                        TWTSW_WT: parseInt(data.TWTSW_WT),
                    },
                    SR: {
                        TW1: {
                            PG: parseInt(data.TWS1P_PG),
                            PM: parseInt(data.TWS1P_PM),
                            KG: parseInt(data.TWS1P_KG),
                            KM: parseInt(data.TWS1P_KM),
                            Temp: parseFloat(data.TWS1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWS2P_PG),
                            PM: parseInt(data.TWS2P_PM),
                            KG: parseInt(data.TWS2P_KG),
                            KM: parseInt(data.TWS2P_KM),
                            Temp: parseFloat(data.TWS2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWS3P_PG),
                            PM: parseInt(data.TWS3P_PM),
                            KG: parseInt(data.TWS3P_KG),
                            KM: parseInt(data.TWS3P_KM),
                            Temp: parseFloat(data.TWS3T),
                        },
                        TWTSW_SR: parseInt(data.TWTSW_SR),
                    },
                    CZ: {
                        TW1: {
                            PG: parseInt(data.TWC1P_PG),
                            PM: parseInt(data.TWC1P_PM),
                            KG: parseInt(data.TWC1P_KG),
                            KM: parseInt(data.TWC1P_KM),
                            Temp: parseFloat(data.TWC1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWC2P_PG),
                            PM: parseInt(data.TWC2P_PM),
                            KG: parseInt(data.TWC2P_KG),
                            KM: parseInt(data.TWC2P_KM),
                            Temp: parseFloat(data.TWC2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWC3P_PG),
                            PM: parseInt(data.TWC3P_PM),
                            KG: parseInt(data.TWC3P_KG),
                            KM: parseInt(data.TWC3P_KM),
                            Temp: parseFloat(data.TWC3T),
                        },
                        TWTSW_CZ: parseInt(data.TWTSW_CZ),
                    },
                    PT: {
                        TW1: {
                            PG: parseInt(data.TWPT1P_PG),
                            PM: parseInt(data.TWPT1P_PM),
                            KG: parseInt(data.TWPT1P_KG),
                            KM: parseInt(data.TWPT1P_KM),
                            Temp: parseFloat(data.TWPT1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWPT2P_PG),
                            PM: parseInt(data.TWPT2P_PM),
                            KG: parseInt(data.TWPT2P_KG),
                            KM: parseInt(data.TWPT2P_KM),
                            Temp: parseFloat(data.TWPT2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWPT3P_PG),
                            PM: parseInt(data.TWPT3P_PM),
                            KG: parseInt(data.TWPT3P_KG),
                            KM: parseInt(data.TWPT3P_KM),
                            Temp: parseFloat(data.TWPT3T),
                        },
                        TWTSW_PT: parseInt(data.TWTSW_PT),
                    },
                    SO: {
                        TW1: {
                            PG: parseInt(data.TWSO1P_PG),
                            PM: parseInt(data.TWSO1P_PM),
                            KG: parseInt(data.TWSO1P_KG),
                            KM: parseInt(data.TWSO1P_KM),
                            Temp: parseFloat(data.TWSO1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWSO2P_PG),
                            PM: parseInt(data.TWSO2P_PM),
                            KG: parseInt(data.TWSO2P_KG),
                            KM: parseInt(data.TWSO2P_KM),
                            Temp: parseFloat(data.TWSO2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWSO3P_PG),
                            PM: parseInt(data.TWSO3P_PM),
                            KG: parseInt(data.TWSO3P_KG),
                            KM: parseInt(data.TWSO3P_KM),
                            Temp: parseFloat(data.TWSO3T),
                        },
                        TWTSW_SO: parseInt(data.TWTSW_SO),
                    },
                    ND: {
                        TW1: {
                            PG: parseInt(data.TWN1P_PG),
                            PM: parseInt(data.TWN1P_PM),
                            KG: parseInt(data.TWN1P_KG),
                            KM: parseInt(data.TWN1P_KM),
                            Temp: parseFloat(data.TWN1T),
                        },
                        TW2: {
                            PG: parseInt(data.TWN2P_PG),
                            PM: parseInt(data.TWN2P_PM),
                            KG: parseInt(data.TWN2P_KG),
                            KM: parseInt(data.TWN2P_KM),
                            Temp: parseFloat(data.TWN2T),
                        },
                        TW3: {
                            PG: parseInt(data.TWN3P_PG),
                            PM: parseInt(data.TWN3P_PM),
                            KG: parseInt(data.TWN3P_KG),
                            KM: parseInt(data.TWN3P_KM),
                            Temp: parseFloat(data.TWN3T),
                        },
                        TWTSW_ND: parseInt(data.TWTSW_ND),
                    },
                },
                C: {
                    PN: {
                        CW1: {
                            PG: parseInt(data.CWP1P_PG),
                            PM: parseInt(data.CWP1P_PM),
                            KG: parseInt(data.CWP1P_KG),
                            KM: parseInt(data.CWP1P_KM),
                            Temp: parseFloat(data.CWP1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWP2P_PG),
                            PM: parseInt(data.CWP2P_PM),
                            KG: parseInt(data.CWP2P_KG),
                            KM: parseInt(data.CWP2P_KM),
                            Temp: parseFloat(data.CWP2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWP3P_PG),
                            PM: parseInt(data.CWP3P_PM),
                            KG: parseInt(data.CWP3P_KG),
                            KM: parseInt(data.CWP3P_KM),
                            Temp: parseFloat(data.CWP3T),
                        },
                        CWTSW_PN: parseInt(data.CWTSW_PN),
                    },
                    WT: {
                        CW1: {
                            PG: parseInt(data.CWW1P_PG),
                            PM: parseInt(data.CWW1P_PM),
                            KG: parseInt(data.CWW1P_KG),
                            KM: parseInt(data.CWW1P_KM),
                            Temp: parseFloat(data.CWW1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWW2P_PG),
                            PM: parseInt(data.CWW2P_PM),
                            KG: parseInt(data.CWW2P_KG),
                            KM: parseInt(data.CWW2P_KM),
                            Temp: parseFloat(data.CWW2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWW3P_PG),
                            PM: parseInt(data.CWW3P_PM),
                            KG: parseInt(data.CWW3P_KG),
                            KM: parseInt(data.CWW3P_KM),
                            Temp: parseFloat(data.CWW3T),
                        },
                        CWTSW_WT: parseInt(data.CWTSW_WT),
                    },
                    SR: {
                        CW1: {
                            PG: parseInt(data.CWS1P_PG),
                            PM: parseInt(data.CWS1P_PM),
                            KG: parseInt(data.CWS1P_KG),
                            KM: parseInt(data.CWS1P_KM),
                            Temp: parseFloat(data.CWS1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWS2P_PG),
                            PM: parseInt(data.CWS2P_PM),
                            KG: parseInt(data.CWS2P_KG),
                            KM: parseInt(data.CWS2P_KM),
                            Temp: parseFloat(data.CWS2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWS3P_PG),
                            PM: parseInt(data.CWS3P_PM),
                            KG: parseInt(data.CWS3P_KG),
                            KM: parseInt(data.CWS3P_KM),
                            Temp: parseFloat(data.CWS3T),
                        },
                        CWTSW_SR: parseInt(data.CWTSW_SR),
                    },
                    CZ: {
                        CW1: {
                            PG: parseInt(data.CWC1P_PG),
                            PM: parseInt(data.CWC1P_PM),
                            KG: parseInt(data.CWC1P_KG),
                            KM: parseInt(data.CWC1P_KM),
                            Temp: parseFloat(data.CWC1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWC2P_PG),
                            PM: parseInt(data.CWC2P_PM),
                            KG: parseInt(data.CWC2P_KG),
                            KM: parseInt(data.CWC2P_KM),
                            Temp: parseFloat(data.CWC2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWC3P_PG),
                            PM: parseInt(data.CWC3P_PM),
                            KG: parseInt(data.CWC3P_KG),
                            KM: parseInt(data.CWC3P_KM),
                            Temp: parseFloat(data.CWC3T),
                        },
                        CWTSW_CZ: parseInt(data.CWTSW_CZ),
                    },
                    PT: {
                        CW1: {
                            PG: parseInt(data.CWPT1P_PG),
                            PM: parseInt(data.CWPT1P_PM),
                            KG: parseInt(data.CWPT1P_KG),
                            KM: parseInt(data.CWPT1P_KM),
                            Temp: parseFloat(data.CWPT1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWPT2P_PG),
                            PM: parseInt(data.CWPT2P_PM),
                            KG: parseInt(data.CWPT2P_KG),
                            KM: parseInt(data.CWPT2P_KM),
                            Temp: parseFloat(data.CWPT2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWPT3P_PG),
                            PM: parseInt(data.CWPT3P_PM),
                            KG: parseInt(data.CWPT3P_KG),
                            KM: parseInt(data.CWPT3P_KM),
                            Temp: parseFloat(data.CWPT3T),
                        },
                        CWTSW_PT: parseInt(data.CWTSW_PT),
                    },
                    SO: {
                        CW1: {
                            PG: parseInt(data.CWSO1P_PG),
                            PM: parseInt(data.CWSO1P_PM),
                            KG: parseInt(data.CWSO1P_KG),
                            KM: parseInt(data.CWSO1P_KM),
                            Temp: parseFloat(data.CWSO1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWSO2P_PG),
                            PM: parseInt(data.CWSO2P_PM),
                            KG: parseInt(data.CWSO2P_KG),
                            KM: parseInt(data.CWSO2P_KM),
                            Temp: parseFloat(data.CWSO2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWSO3P_PG),
                            PM: parseInt(data.CWSO3P_PM),
                            KG: parseInt(data.CWSO3P_KG),
                            KM: parseInt(data.CWSO3P_KM),
                            Temp: parseFloat(data.CWSO3T),
                        },
                        CWTSW_SO: parseInt(data.CWTSW_SO),
                    },
                    ND: {
                        CW1: {
                            PG: parseInt(data.CWN1P_PG),
                            PM: parseInt(data.CWN1P_PM),
                            KG: parseInt(data.CWN1P_KG),
                            KM: parseInt(data.CWN1P_KM),
                            Temp: parseFloat(data.CWN1T),
                        },
                        CW2: {
                            PG: parseInt(data.CWN2P_PG),
                            PM: parseInt(data.CWN2P_PM),
                            KG: parseInt(data.CWN2P_KG),
                            KM: parseInt(data.CWN2P_KM),
                            Temp: parseFloat(data.CWN2T),
                        },
                        CW3: {
                            PG: parseInt(data.CWN3P_PG),
                            PM: parseInt(data.CWN3P_PM),
                            KG: parseInt(data.CWN3P_KG),
                            KM: parseInt(data.CWN3P_KM),
                            Temp: parseFloat(data.CWN3T),
                        },
                        CWTSW_ND: parseInt(data.CWTSW_ND),
                    },
                },
            },
            blockade: {
                SXF_ACT: parseInt(data.SXF_ACT),
                BLK_LW: parseInt(data.BLK_LW),
                BLK_LK: parseInt(data.BLK_LK),
                BLK_ENA: parseInt(data.BLK_ENA),
                BLK_KD1: data.BLK_KD1,
                BLK_KD2: data.BLK_KD2,
            },
            analysis: {
                cwu: {
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SBF_DHX: parseInt(data.SBF_DHX),
                    SBF_DHS: parseInt(data.SBF_DHS),
                    SBB_QDHW: parseInt(data.SBB_QDHW),
                    REW_ERR: parseInt(data.REW_ERR),
                    SBB_QDIS: parseInt(data.SBB_QDIS),
                    SBB_QRMAX: parseInt(data.SBB_QRMAX),
                    SBB_QSMAX: parseInt(data.SBB_QSMAX),
                    SBB_QCS: parseInt(data.SBB_QCS),
                    REW_RMD: parseInt(data.REW_RMD),
                    SXB_4DR: parseInt(data.SXB_4DR),
                    REW_SMD: parseInt(data.REW_SMD),
                },
                heating: {
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SBF_HTX: parseInt(data.SBF_HTX),
                    SBF_HTS: parseInt(data.SBF_HTS),
                    SXF_TOE: parseInt(data.SXF_TOE),
                    SBB_QHW: parseInt(data.SBB_QHW),
                    SWEXT_TWP: parseInt(data.SWEXT_TWP),
                    SBB_QTIN: parseInt(data.SBB_QTIN),
                    SBB_QHOUT: parseInt(data.SBB_QHOUT),
                    SBB_QSMD: parseInt(data.SBB_QSMD),
                    REW_ERR: parseInt(data.REW_ERR),
                    SBB_QDIS: parseInt(data.SBB_QDIS),
                    SBB_QRMAX: parseInt(data.SBB_QRMAX),
                    SBB_QSMAX: parseInt(data.SBB_QSMAX),
                    SBB_QCS: parseInt(data.SBB_QCS),
                    REW_RMD: parseInt(data.REW_RMD),
                    OB1_XZM_TRANSM: parseInt(data.OB1_XZM_TRANSM),
                    OB1_TWEW: parseFloat(data.OB1_TWEW),
                    OB1_TKMF: parseFloat(data.OB1_TKMF),
                    SXB_4DR: parseInt(data.SXB_4DR),
                    REW_SMD: parseInt(data.REW_SMD),
                },
                coolingFC: {
                    SBF_ACX: parseInt(data.SBF_ACX),
                    SBF_ACS: parseInt(data.SBF_ACS),
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SBF_FCX: parseInt(data.SBF_FCX),
                    SBF_FCS: parseInt(data.SBF_FCS),
                    SXF_TOE: parseInt(data.SXF_TOE),
                    SBB_QHW: parseInt(data.SBB_QHW),
                    SBB_QTIN: parseInt(data.SBB_QTIN),
                    SBB_QCOUT: parseInt(data.SBB_QCOUT),
                    SXB_QFC: parseInt(data.SXB_QFC),
                    SBB_RMD: parseInt(data.SBB_RMD),
                    REW_SMD: parseInt(data.REW_SMD),
                    REW_RMD: parseInt(data.REW_RMD),
                    REW_ERR: parseInt(data.REW_ERR),
                },
                coolingAC: {
                    SBF_ACX: parseInt(data.SBF_ACX),
                    SBF_ACS: parseInt(data.SBF_ACS),
                    SBF_OOF: parseInt(data.SBF_OOF),
                    SXF_TOE: parseInt(data.SXF_TOE),
                    SBB_QHW: parseInt(data.SBB_QHW),
                    SBB_QTIN: parseInt(data.SBB_QTIN),
                    SBB_QCOUT: parseInt(data.SBB_QCOUT),
                    SBB_RMD: parseInt(data.SBB_RMD),
                    SBB_QDIS: parseInt(data.SBB_QDIS),
                    SBB_QRMIN: parseInt(data.SBB_QRMIN),
                    SBB_QCS: parseInt(data.SBB_QCS),
                    SXB_QFC: parseInt(data.SXB_QFC),
                    REW_RMD: parseInt(data.REW_RMD),
                    SBB_QSMIN: parseInt(data.SBB_QSMIN),
                    SWEXT_TWP: parseInt(data.SWEXT_TWP),
                    REW_ERR: parseInt(data.REW_ERR),
                    SBB_QSMAX: parseInt(data.SBB_QSMAX),
                    SXB_4DR: parseInt(data.SXB_4DR),
                    SBF_FCS: parseInt(data.SBF_FCS),
                    SXB_ACBL: parseInt(data.SXB_ACBL),
                    REW_SMD: parseInt(data.REW_SMD),
                },
            },
            periphery: {
                circulation: {
                    TIM_CYR: parseInt(data.TIM_CYR),
                    ANL_3PH: parseInt(data.ANL_3PH),
                    CYR_FILL: parseInt(data.CYR_FILL),
                    CYR_BS: parseInt(data.CYR_BS),
                    CYRSW_S1: parseInt(data.CYRSW_S1),
                    CYRSW_S2: parseInt(data.CYRSW_S2),
                    CYRSW_S3: parseInt(data.CYRSW_S3),
                    CYRSW_PN: parseInt(data.CYRSW_PN),
                    CYRSW_WT: parseInt(data.CYRSW_WT),
                    CYRSW_SR: parseInt(data.CYRSW_SR),
                    CYRSW_CZ: parseInt(data.CYRSW_CZ),
                    CYRSW_PT: parseInt(data.CYRSW_PT),
                    CYRSW_SO: parseInt(data.CYRSW_SO),
                    CYRSW_ND: parseInt(data.CYRSW_ND),
                    map: { 
                        SBF_OOF: parseInt(data.SBF_OOF), 
                        SBF_DHX: parseInt(data.SBF_DHX), 
                        SBF_DHS: parseInt(data.SBF_DHS), 
                        SXF_ENACYR: parseInt(data.SXF_ENACYR), 
                        SXF_FORCYR: parseInt(data.SXF_FORCYR), 
                        SXF_TIMCYR: parseInt(data.SXF_TIMCYR), 
                        SXF_FLPCYR: parseInt(data.SXF_FLPCYR), 
                    },
                    PN: {
                        SWITCH: parseInt(data.CYRSW_PN),
                        KM: parseInt(data.CYRP3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRP1P_PG),
                            // PM: parseInt(data.CYRP1P_PM),
                            KG: parseInt(data.CYRP1P_KG),
                            // KM: parseInt(data.CYRP1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRP2P_PG),
                            // PM: parseInt(data.CYRP2P_PM),
                            KG: parseInt(data.CYRP2P_KG),
                            // KM: parseInt(data.CYRP2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRP3P_PG),
                            // PM: parseInt(data.CYRP3P_PM),
                            KG: parseInt(data.CYRP3P_KG),
                        },
                    },
                    WT: {
                        SWITCH: parseInt(data.CYRSW_WT),
                        KM: parseInt(data.CYRW3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRW1P_PG),
                            // PM: parseInt(data.CYRW1P_PM),
                            KG: parseInt(data.CYRW1P_KG),
                            // KM: parseInt(data.CYRW1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRW2P_PG),
                            // PM: parseInt(data.CYRW2P_PM),
                            KG: parseInt(data.CYRW2P_KG),
                            // KM: parseInt(data.CYRW2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRW3P_PG),
                            // PM: parseInt(data.CYRW3P_PM),
                            KG: parseInt(data.CYRW3P_KG),
                        },
                    },
                    SR: {
                        SWITCH: parseInt(data.CYRSW_SR),
                        KM: parseInt(data.CYRS3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRS1P_PG),
                            // PM: parseInt(data.CYRS1P_PM),
                            KG: parseInt(data.CYRS1P_KG),
                            // KM: parseInt(data.CYRS1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRS2P_PG),
                            // PM: parseInt(data.CYRS2P_PM),
                            KG: parseInt(data.CYRS2P_KG),
                            // KM: parseInt(data.CYRS2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRS3P_PG),
                            // PM: parseInt(data.CYRS3P_PM),
                            KG: parseInt(data.CYRS3P_KG),
                        },
                    },
                    CZ: {
                        SWITCH: parseInt(data.CYRSW_CZ),
                        KM: parseInt(data.CYRC3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRC1P_PG),
                            // PM: parseInt(data.CYRC1P_PM),
                            KG: parseInt(data.CYRC1P_KG),
                            // KM: parseInt(data.CYRC1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRC2P_PG),
                            // PM: parseInt(data.CYRC2P_PM),
                            KG: parseInt(data.CYRC2P_KG),
                            // KM: parseInt(data.CYRC2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRC3P_PG),
                            // PM: parseInt(data.CYRC3P_PM),
                            KG: parseInt(data.CYRC3P_KG),
                        },
                    },
                    PT: {
                        SWITCH: parseInt(data.CYRSW_PT),
                        KM: parseInt(data.CYRPT3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRPT1P_PG),
                            // PM: parseInt(data.CYRPT1P_PM),
                            KG: parseInt(data.CYRPT1P_KG),
                            // KM: parseInt(data.CYRPT1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRPT2P_PG),
                            // PM: parseInt(data.CYRPT2P_PM),
                            KG: parseInt(data.CYRPT2P_KG),
                            // KM: parseInt(data.CYRPT2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRPT3P_PG),
                            // PM: parseInt(data.CYRPT3P_PM),
                            KG: parseInt(data.CYRPT3P_KG),
                        },
                    },
                    SO: {
                        SWITCH: parseInt(data.CYRSW_SO),
                        KM: parseInt(data.CYRSO3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRSO1P_PG),
                            // PM: parseInt(data.CYRSO1P_PM),
                            KG: parseInt(data.CYRSO1P_KG),
                            // KM: parseInt(data.CYRSO1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRSO2P_PG),
                            // PM: parseInt(data.CYRSO2P_PM),
                            KG: parseInt(data.CYRSO2P_KG),
                            // KM: parseInt(data.CYRSO2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRSO3P_PG),
                            // PM: parseInt(data.CYRSO3P_PM),
                            KG: parseInt(data.CYRSO3P_KG),
                        },
                    },
                    ND: {
                        SWITCH: parseInt(data.CYRSW_ND),
                        KM: parseInt(data.CYRN3P_KM),
                        CYR1: {
                            PG: parseInt(data.CYRN1P_PG),
                            // PM: parseInt(data.CYRN1P_PM),
                            KG: parseInt(data.CYRN1P_KG),
                            // KM: parseInt(data.CYRN1P_KM),
                        },
                        CYR2: {
                            PG: parseInt(data.CYRN2P_PG),
                            // PM: parseInt(data.CYRN2P_PM),
                            KG: parseInt(data.CYRN2P_KG),
                            // KM: parseInt(data.CYRN2P_KM),
                        },
                        CYR3: {
                            PG: parseInt(data.CYRN3P_PG),
                            // PM: parseInt(data.CYRN3P_PM),
                            KG: parseInt(data.CYRN3P_KG),
                        },
                    },
                },
                peakSource: {
                    settings: {
                        SBF_OOF: parseInt(data.SBF_OOF),
                        SBF_HTX: parseInt(data.SBF_HTX),
                        SBF_HTS: parseInt(data.SBF_HTS),
                        SXF_TOE: parseInt(data.SXF_TOE),
                    },
                    configuration: {
                        SWEXT_ZBCRO: parseInt(data.SWEXT_ZBCRO),
                    },
                    step_1: {
                        map: {
                            SBF_AHX: parseInt(data.SBF_AHX),
                            SBF_AHS: parseInt(data.SBF_AHS),
                            TB5: parseInt(data.TB5),
                            TSM9: parseFloat(data.TSM9),
                            TB6: parseFloat(data.TB6),
                            TB5W: parseInt(data.TB5W),
                            SWEXT_ZBC: parseInt(data.SWEXT_ZBC),
                            TB4: parseInt(data.TB4),
                            SXB_ECACT: parseInt(data.SXB_ECACT),
                            SWEXT_ZBST1: parseInt(data.SWEXT_ZBST1),
                            HPOM: parseInt(data.HPOM),
                        }  
                    },
                    step_2: {
                        map: {
                            SWEXT_AHX2: parseInt(data.SWEXT_AHX2),
                            SWEXT_AHS2: parseInt(data.SWEXT_AHS2),
                            A1: parseInt(data.A1),
                            TSM9: parseFloat(data.TSM9),
                            A2: parseFloat(data.A2),
                            SIO_R06: parseInt(data.SIO_R06),
                            SWEXT_ZBST2: parseInt(data.SWEXT_ZBST2),
                        }
                    },
                    heater: {
                        SIO_R11: parseInt(data.SIO_R11),
                        SIO_R01: parseInt(data.SIO_R01),
                    },
                    step_geo: {
                        map: {
                            SBF_AHX: parseInt(data.SBF_AHX),
                            SBF_AHS: parseInt(data.SBF_AHS),
                            TB5: parseInt(data.TB5),
                            TSM9: parseFloat(data.TSM9),
                            TB6: parseFloat(data.TB6),
                            TB5W: parseInt(data.TB5W),
                            SWEXT_ZBC: parseInt(data.SWEXT_ZBC)
                        },
                        status: {
                            TB4: parseInt(data.TB4),
                            SIO_R06: parseInt(data.SIO_R06),
                            SIO_R11: parseInt(data.SIO_R11),
                        },
                    },
                    SXB_ECACT: parseInt(data.SXB_ECACT),
                },
                coopInstallation: {
                    SWEXT_BUF: parseInt(data.SWEXT_BUF),
                },
                sensorInside: {
                    SWEXT_TWP: parseInt(data.SWEXT_TWP)
                },
                circuits: {
                    status: {
                        SBF_OOF: parseInt(data.SBF_OOF),
                        SBF_HTX: parseInt(data.SBF_HTX),
                        SBF_HTS: parseInt(data.SBF_HTS),
                        SXF_TOE: parseInt(data.SXF_TOE),
                        SBF_ACX: parseInt(data.SBF_ACX),
                        SBF_ACS: parseInt(data.SBF_ACS),
                        SBB_QHOUT: parseInt(data.SBB_QHOUT),
                        SBB_QCOUT: parseInt(data.SBB_QCOUT),
                        SBB_QTIN: parseInt(data.SBB_QTIN),
                        SIO_R02: parseInt(data.SIO_R02),
                        SIO_R05: parseInt(data.SIO_R05),
                    },
                    settings: {
                        RGRCH: parseInt(data.RGRCH),
                        SWEXT_DCP: parseInt(data.SWEXT_DCP)
                    }
                },
                heatingCircuits: {
                    main: {
                        map: {
                            TSM10: parseFloat(data.TSM10),
                            SPHT_ZAD: parseFloat(data.SPHT_ZAD),
                            SX6: parseFloat(data.SX6),
                            CUN_HCU: parseInt(data.CUN_HCU),
                            SX3_HG: parseInt(data.SX3_HG),
                            TB1: parseFloat(data.TB1),  
                        },
                        SWEXT_BUF: parseInt(data.SWEXT_BUF),
                        TSM17: parseFloat(data.TSM17),
                        TSM13: parseFloat(data.TSM13),
                        SIO_R02: parseInt(data.SIO_R02),
                        SXB_PV: parseInt(data.SXB_PV),
                        SBF_HTS: parseInt(data.SBF_HTS),
                        SBF_HTX: parseInt(data.SBF_HTX),
                        SXF_TOE: parseInt(data.SXF_TOE),
                        SBF_OOF: parseInt(data.SBF_OOF),
                    },
                    additional: {} 
                },
                dr4Valve: {
                    map: {
                        SIO_R10: parseInt(data.SIO_R10),
                        SXF_4EN: parseInt(data.SXF_4EN),
                        SXB_4DR: parseInt(data.SXB_4DR),
                        TB3: parseInt(data.TB3),
                    },  
                    SXF_4EN: parseInt(data.SXF_4EN), 
                    SXB_WDHW: parseInt(data.SXB_WDHW), 
                    SXB_WHT: parseInt(data.SXB_WHT), 
                    SXB_4DR: parseInt(data.SXB_4DR), 
                    SXB_WCL: parseInt(data.SXB_WCL), 
                    TSM11: parseFloat(data.TSM11), 
                    TSM12: parseFloat(data.TSM12), 
                    TSM13: parseFloat(data.TSM13), 
                    TSM14: parseFloat(data.TSM14),

                },
                cwuco: {
                    SXF_DHM: parseInt(data.SXF_DHM),
                },
            },
            outdoor: {
                info: {
                    MB_COMM2_XZM_TRANSM: parseInt(data.MB_COMM2_XZM_TRANSM),
                },
                parameters: {
                    ZM_AC_IN_VOL: parseInt(data.ZM_AC_IN_VOL),
                    ZM_AC_IN_CUR: parseFloat(data.ZM_AC_IN_CUR),
                    ZM_CUR_MAX_FREQ: parseInt(data.ZM_CUR_MAX_FREQ),
                    ZM_CUR_COMP_FREQ: parseInt(data.ZM_CUR_COMP_FREQ),
                    ZM_FAN_MAX_SPE: parseInt(data.ZM_FAN_MAX_SPE),
                    ZM_FAN1_SPE: parseInt(data.ZM_FAN1_SPE),
                    ZM_FAN2_SPE: parseInt(data.ZM_FAN2_SPE),
                    ZM_EEV_OPEN: parseFloat(data.ZM_EEV_OPEN),
                },
                ref_system: {
                    ZM_PRES_EVAP: parseFloat(data.ZM_PRES_EVAP),
                    ZM_PRES_COND: parseFloat(data.ZM_PRES_COND),
                    ZM_EEV_OPEN: parseFloat(data.ZM_EEV_OPEN),
                    ZM_SUCT_TEMP: parseFloat(data.ZM_SUCT_TEMP),
                    TSM12: parseFloat(data.TSM12),
                },
                temp: {
                    ZM_OUT_TEMP: parseFloat(data.ZM_OUT_TEMP),
                    ZM_COIL_TEMP: parseFloat(data.ZM_COIL_TEMP),
                    ZM_DIS_TEMP: parseFloat(data.ZM_DIS_TEMP),
                    ZM_SUCT_TEMP: parseFloat(data.ZM_SUCT_TEMP),
                },
                control_registers: {
                    ZMB_SET_COMP_SPEED: parseFloat(data.ZMB_SET_COMP_SPEED),
                    ZMB_SET_EEV_OPEN: parseFloat(data.ZMB_SET_EEV_OPEN),
                    ZMB_SET_INW_TEMP: parseFloat(data.ZMB_SET_INW_TEMP),
                    ZM_CUR_OP_MOD: parseInt(data.ZM_CUR_OP_MOD),
                },
                errors_registers: {
                    ZM_ERR1: parseInt(data.ZM_ERR1),
                    ZM_ERR2: parseInt(data.ZM_ERR2),
                    ZM_ERR3: parseInt(data.ZM_ERR3),
                },
                errors_outdoor: {
                    ERR1_MCUR: parseInt(data.ERR1_MCUR),
                    ERR1_PCUR: parseInt(data.ERR1_PCUR),
                    ERR1_IPM: parseInt(data.ERR1_IPM),
                    ERR1_OIL: parseInt(data.ERR1_OIL),
                    ERR1_PDF: parseInt(data.ERR1_PDF),
                    ERR1_OGTS: parseInt(data.ERR1_OGTS),
                    ERR1_COIL_PROT: parseInt(data.ERR1_COIL_PROT),
                    ERR1_VOLT: parseInt(data.ERR1_VOLT),
                    ERR1_FREQ_LT: parseInt(data.ERR1_FREQ_LT),
                    ERR2_EPTRA_F: parseInt(data.ERR2_EPTRA_F),
                    ERR2_CPTRA_F: parseInt(data.ERR2_CPTRA_F),
                    ERR2_HP_F: parseInt(data.ERR2_HP_F),
                    ERR2_LP_F: parseInt(data.ERR2_LP_F),
                },
                efficiency_regulation: {
                    SWEXT_FIXREG: parseInt(data.SWEXT_FIXREG),
                    TNKOR: parseInt(data.TNKOR),
                    MAX_PERF: parseInt(data.MAX_PERF),
                    DETE: parseFloat(data.DETE),
                    FSET_CO: parseInt(data.FSET_CO),
                    FSET_CWU: parseInt(data.FSET_CWU),
                    FSET_AC: parseInt(data.FSET_AC),
                    IHW: parseInt(data.IHW),
                    ZM_DEFR_SIGN: parseInt(data.ZM_DEFR_SIGN),
                },
                defrost : {
                    XSCHT: parseInt(data.XSCHT),
                }  
            },
            identyfication: {
                controller: {
                    map: {
                        NUMER_STER: parseInt(data.NUMER_STER),
                        VER_S1: parseInt(data.VER_S1),
                        IDM_MAN: parseInt(data.IDM_MAN),
                        IDM_GRO: parseInt(data.IDM_GRO),
                        PSN: parseInt(data.PSN),
                        PDP_WEE: parseInt(data.PDP_WEE),
                        PDP_YEA: parseInt(data.PDP_YEA),
                    },
                    VER_S2: parseInt(data.VER_S2),
                },
                configuration: {
                    map: {
                        VR_FLO: parseInt(data.VR_FLO),
                        VR_FLI: parseInt(data.VR_FLI),
                        VR_ZCH: parseInt(data.VR_ZCH),
                        VR_ZKO: parseInt(data.VR_ZKO),
                        VR_ANO: parseInt(data.VR_ANO),
                        VR_ZCW: parseInt(data.VR_ZCW),
                        VR_FCO: parseInt(data.VR_FCO),
                        VR_FCI: parseInt(data.VR_FCI),
                        VR_PO: parseInt(data.VR_PO),
                        VR_PZ: parseInt(data.VR_PZ),
                        VR_REV: parseInt(data.VR_REV),
                    },
                },
                characteristics: {
                    map: {
                        IDP_PWR: parseInt(data.IDP_PWR),
                        IDP_SUB: parseInt(data.IDP_SUB),
                        IDP_MOD: parseInt(data.IDP_MOD),
                        IDP_EXP: parseInt(data.IDP_EXP),
                        PANVER: parseInt(data.PANVER),
                    },
                },
            },
            defaultdata: {
                CYRP1P_PG: parseInt(data.CYRP1P_PG),
                CYRP1P_KG: parseInt(data.CYRP1P_KG),
                CYRP2P_PG: parseInt(data.CYRP2P_PG),
                CYRP2P_KG: parseInt(data.CYRP2P_KG),
                CYRP3P_PG: parseInt(data.CYRP3P_PG),
                CYRP3P_KG: parseInt(data.CYRP3P_KG),
                CYRP3P_KM: parseInt(data.CYRP3P_KM),

                CYRW1P_PG: parseInt(data.CYRW1P_PG),
                CYRW1P_KG: parseInt(data.CYRW1P_KG),
                CYRW2P_PG: parseInt(data.CYRW2P_PG),
                CYRW2P_KG: parseInt(data.CYRW2P_KG),
                CYRW3P_PG: parseInt(data.CYRW3P_PG),
                CYRW3P_KG: parseInt(data.CYRW3P_KG),
                CYRW3P_KM: parseInt(data.CYRW3P_KM),

                CYRS1P_PG: parseInt(data.CYRS1P_PG),
                CYRS1P_KG: parseInt(data.CYRS1P_KG),
                CYRS2P_PG: parseInt(data.CYRS2P_PG),
                CYRS2P_KG: parseInt(data.CYRS2P_KG),
                CYRS3P_PG: parseInt(data.CYRS3P_PG),
                CYRS3P_KG: parseInt(data.CYRS3P_KG),
                CYRS3P_KM: parseInt(data.CYRS3P_KM),

                CYRC1P_PG: parseInt(data.CYRC1P_PG),
                CYRC1P_KG: parseInt(data.CYRC1P_KG),
                CYRC2P_PG: parseInt(data.CYRC2P_PG),
                CYRC2P_KG: parseInt(data.CYRC2P_KG),
                CYRC3P_PG: parseInt(data.CYRC3P_PG),
                CYRC3P_KG: parseInt(data.CYRC3P_KG),
                CYRC3P_KM: parseInt(data.CYRC3P_KM),

                CYRPT1P_PG: parseInt(data.CYRPT1P_PG),
                CYRPT1P_KG: parseInt(data.CYRPT1P_KG),
                CYRPT2P_PG: parseInt(data.CYRPT2P_PG),
                CYRPT2P_KG: parseInt(data.CYRPT2P_KG),
                CYRPT3P_PG: parseInt(data.CYRPT3P_PG),
                CYRPT3P_KG: parseInt(data.CYRPT3P_KG),
                CYRPT3P_KM: parseInt(data.CYRPT3P_KM),

                CYRSO1P_PG: parseInt(data.CYRSO1P_PG),
                CYRSO1P_KG: parseInt(data.CYRSO1P_KG),
                CYRSO2P_PG: parseInt(data.CYRSO2P_PG),
                CYRSO2P_KG: parseInt(data.CYRSO2P_KG),
                CYRSO3P_PG: parseInt(data.CYRSO3P_PG),
                CYRSO3P_KG: parseInt(data.CYRSO3P_KG),
                CYRSO3P_KM: parseInt(data.CYRSO3P_KM),

                CYRN1P_PG: parseInt(data.CYRN1P_PG),
                CYRN1P_KG: parseInt(data.CYRN1P_KG),
                CYRN2P_PG: parseInt(data.CYRN2P_PG),
                CYRN2P_KG: parseInt(data.CYRN2P_KG),
                CYRN3P_PG: parseInt(data.CYRN3P_PG),
                CYRN3P_KG: parseInt(data.CYRN3P_KG),
                CYRN3P_KM: parseInt(data.CYRN3P_KM),

                CWP1P_PG: parseInt(data.CWP1P_PG),
                CWP1P_PM: parseInt(data.CWP1P_PM),
                CWP1P_KG: parseInt(data.CWP1P_KG),
                CWP1P_KM: parseInt(data.CWP1P_KM),
                CWP2P_PG: parseInt(data.CWP2P_PG),
                CWP2P_PM: parseInt(data.CWP2P_PM),
                CWP2P_KG: parseInt(data.CWP2P_KG),
                CWP2P_KM: parseInt(data.CWP2P_KM),
                CWP3P_PG: parseInt(data.CWP3P_PG),
                CWP3P_PM: parseInt(data.CWP3P_PM),
                CWP3P_KG: parseInt(data.CWP3P_KG),
                CWP3P_KM: parseInt(data.CWP3P_KM),
                CWW1P_PG: parseInt(data.CWW1P_PG),
                CWW1P_PM: parseInt(data.CWW1P_PM),
                CWW1P_KG: parseInt(data.CWW1P_KG),
                CWW1P_KM: parseInt(data.CWW1P_KM),
                CWW2P_PG: parseInt(data.CWW2P_PG),
                CWW2P_PM: parseInt(data.CWW2P_PM),
                CWW2P_KG: parseInt(data.CWW2P_KG),
                CWW2P_KM: parseInt(data.CWW2P_KM),
                CWW3P_PG: parseInt(data.CWW3P_PG),
                CWW3P_PM: parseInt(data.CWW3P_PM),
                CWW3P_KG: parseInt(data.CWW3P_KG),
                CWW3P_KM: parseInt(data.CWW3P_KM),
                CWS1P_PG: parseInt(data.CWS1P_PG),
                CWS1P_PM: parseInt(data.CWS1P_PM),
                CWS1P_KG: parseInt(data.CWS1P_KG),
                CWS1P_KM: parseInt(data.CWS1P_KM),
                CWS2P_PG: parseInt(data.CWS2P_PG),
                CWS2P_PM: parseInt(data.CWS2P_PM),
                CWS2P_KG: parseInt(data.CWS2P_KG),
                CWS2P_KM: parseInt(data.CWS2P_KM),
                CWS3P_PG: parseInt(data.CWS3P_PG),
                CWS3P_PM: parseInt(data.CWS3P_PM),
                CWS3P_KG: parseInt(data.CWS3P_KG),
                CWS3P_KM: parseInt(data.CWS3P_KM),
                CWC1P_PG: parseInt(data.CWC1P_PG),
                CWC1P_PM: parseInt(data.CWC1P_PM),
                CWC1P_KG: parseInt(data.CWC1P_KG),
                CWC1P_KM: parseInt(data.CWC1P_KM),
                CWC2P_PG: parseInt(data.CWC2P_PG),
                CWC2P_PM: parseInt(data.CWC2P_PM),
                CWC2P_KG: parseInt(data.CWC2P_KG),
                CWC2P_KM: parseInt(data.CWC2P_KM),
                CWC3P_PG: parseInt(data.CWC3P_PG),
                CWC3P_PM: parseInt(data.CWC3P_PM),
                CWC3P_KG: parseInt(data.CWC3P_KG),
                CWC3P_KM: parseInt(data.CWC3P_KM),
                CWPT1P_PG: parseInt(data.CWPT1P_PG),
                CWPT1P_PM: parseInt(data.CWPT1P_PM),
                CWPT1P_KG: parseInt(data.CWPT1P_KG),
                CWPT1P_KM: parseInt(data.CWPT1P_KM),
                CWPT2P_PG: parseInt(data.CWPT2P_PG),
                CWPT2P_PM: parseInt(data.CWPT2P_PM),
                CWPT2P_KG: parseInt(data.CWPT2P_KG),
                CWPT2P_KM: parseInt(data.CWPT2P_KM),
                CWPT3P_PG: parseInt(data.CWPT3P_PG),
                CWPT3P_PM: parseInt(data.CWPT3P_PM),
                CWPT3P_KG: parseInt(data.CWPT3P_KG),
                CWPT3P_KM: parseInt(data.CWPT3P_KM),
                CWSO1P_PG: parseInt(data.CWSO1P_PG),
                CWSO1P_PM: parseInt(data.CWSO1P_PM),
                CWSO1P_KG: parseInt(data.CWSO1P_KG),
                CWSO1P_KM: parseInt(data.CWSO1P_KM),
                CWSO2P_PG: parseInt(data.CWSO2P_PG),
                CWSO2P_PM: parseInt(data.CWSO2P_PM),
                CWSO2P_KG: parseInt(data.CWSO2P_KG),
                CWSO2P_KM: parseInt(data.CWSO2P_KM),
                CWSO3P_PG: parseInt(data.CWSO3P_PG),
                CWSO3P_PM: parseInt(data.CWSO3P_PM),
                CWSO3P_KG: parseInt(data.CWSO3P_KG),
                CWSO3P_KM: parseInt(data.CWSO3P_KM),
                CWN1P_PG: parseInt(data.CWN1P_PG),
                CWN1P_PM: parseInt(data.CWN1P_PM),
                CWN1P_KG: parseInt(data.CWN1P_KG),
                CWN1P_KM: parseInt(data.CWN1P_KM),
                CWN2P_PG: parseInt(data.CWN2P_PG),
                CWN2P_PM: parseInt(data.CWN2P_PM),
                CWN2P_KG: parseInt(data.CWN2P_KG),
                CWN2P_KM: parseInt(data.CWN2P_KM),
                CWN3P_PG: parseInt(data.CWN3P_PG),
                CWN3P_PM: parseInt(data.CWN3P_PM),
                CWN3P_KG: parseInt(data.CWN3P_KG),
                CWN3P_KM: parseInt(data.CWN3P_KM),

                CWP1T: parseInt(data.CWP1T),
                CWP2T: parseInt(data.CWP2T),
                CWP3T: parseInt(data.CWP3T),

                CWW1T: parseInt(data.CWW1T),
                CWW2T: parseInt(data.CWW2T),
                CWW3T: parseInt(data.CWW3T),

                CWS1T: parseInt(data.CWS1T),
                CWS2T: parseInt(data.CWS2T),
                CWS3T: parseInt(data.CWS3T),

                CWC1T: parseInt(data.CWC1T),
                CWC2T: parseInt(data.CWC2T),
                CWC3T: parseInt(data.CWC3T),

                CWPT1T: parseInt(data.CWPT1T),
                CWPT2T: parseInt(data.CWPT2T),
                CWPT3T: parseInt(data.CWPT3T),

                CWSO1T: parseInt(data.CWSO1T),
                CWSO2T: parseInt(data.CWSO2T),
                CWSO3T: parseInt(data.CWSO3T),

                CWN1T: parseInt(data.CWN1T),
                CWN2T: parseInt(data.CWN2T),
                CWN3T: parseInt(data.CWN3T),

                TWP1P_PG: parseInt(data.TWP1P_PG),
                TWP1P_PM: parseInt(data.TWP1P_PM),
                TWP1P_KG: parseInt(data.TWP1P_KG),
                TWP1P_KM: parseInt(data.TWP1P_KM),
                TWP2P_PG: parseInt(data.TWP2P_PG),
                TWP2P_PM: parseInt(data.TWP2P_PM),
                TWP2P_KG: parseInt(data.TWP2P_KG),
                TWP2P_KM: parseInt(data.TWP2P_KM),
                TWP3P_PG: parseInt(data.TWP3P_PG),
                TWP3P_PM: parseInt(data.TWP3P_PM),
                TWP3P_KG: parseInt(data.TWP3P_KG),
                TWP3P_KM: parseInt(data.TWP3P_KM),
                TWW1P_PG: parseInt(data.TWW1P_PG),
                TWW1P_PM: parseInt(data.TWW1P_PM),
                TWW1P_KG: parseInt(data.TWW1P_KG),
                TWW1P_KM: parseInt(data.TWW1P_KM),
                TWW2P_PG: parseInt(data.TWW2P_PG),
                TWW2P_PM: parseInt(data.TWW2P_PM), 
                TWW2P_KG: parseInt(data.TWW2P_KG), 
                TWW2P_KM: parseInt(data.TWW2P_KM), 
                TWW3P_PG: parseInt(data.TWW3P_PG), 
                TWW3P_PM: parseInt(data.TWW3P_PM), 
                TWW3P_KG: parseInt(data.TWW3P_KG), 
                TWW3P_KM: parseInt(data.TWW3P_KM), 
                TWS1P_PG: parseInt(data.TWS1P_PG), 
                TWS1P_PM: parseInt(data.TWS1P_PM), 
                TWS1P_KG: parseInt(data.TWS1P_KG), 
                TWS1P_KM: parseInt(data.TWS1P_KM), 
                TWS2P_PG: parseInt(data.TWS2P_PG), 
                TWS2P_PM: parseInt(data.TWS2P_PM), 
                TWS2P_KG: parseInt(data.TWS2P_KG), 
                TWS2P_KM: parseInt(data.TWS2P_KM), 
                TWS3P_PG: parseInt(data.TWS3P_PG), 
                TWS3P_PM: parseInt(data.TWS3P_KM), 
                TWS3P_KG: parseInt(data.TWS3P_KG), 
                TWS3P_KM: parseInt(data.TWS3P_KM), 
                TWC1P_PG: parseInt(data.TWC1P_PG), 
                TWC1P_PM: parseInt(data.TWC1P_PM), 
                TWC1P_KG: parseInt(data.TWC1P_KG), 
                TWC1P_KM: parseInt(data.TWC1P_KM), 
                TWC2P_PG: parseInt(data.TWC2P_PG), 
                TWC2P_PM: parseInt(data.TWC2P_PM), 
                TWC2P_KG: parseInt(data.TWC2P_KG), 
                TWC2P_KM: parseInt(data.TWC2P_KM), 
                TWC3P_PG: parseInt(data.TWC3P_PG), 
                TWC3P_PM: parseInt(data.TWC3P_PM), 
                TWC3P_KG: parseInt(data.TWC3P_KG), 
                TWC3P_KM: parseInt(data.TWC3P_KM), 
                TWPT1P_PG: parseInt(data.TWPT1P_PG), 
                TWPT1P_PM: parseInt(data.TWPT1P_PM), 
                TWPT1P_KG: parseInt(data.TWPT1P_KG), 
                TWPT1P_KM: parseInt(data.TWPT1P_KM), 
                TWPT2P_PG: parseInt(data.TWPT2P_PG), 
                TWPT2P_PM: parseInt(data.TWPT2P_PM), 
                TWPT2P_KG: parseInt(data.TWPT2P_KG), 
                TWPT2P_KM: parseInt(data.TWPT2P_KM), 
                TWPT3P_PG: parseInt(data.TWPT3P_PG), 
                TWPT3P_PM: parseInt(data.TWPT3P_PM), 
                TWPT3P_KG: parseInt(data.TWPT3P_KG), 
                TWPT3P_KM: parseInt(data.TWPT3P_KM), 
                TWSO1P_PG: parseInt(data.TWSO1P_PG), 
                TWSO1P_PM: parseInt(data.TWSO1P_PM), 
                TWSO1P_KG: parseInt(data.TWSO1P_KG), 
                TWSO1P_KM: parseInt(data.TWSO1P_KM), 
                TWSO2P_PG: parseInt(data.TWSO2P_PG), 
                TWSO2P_PM: parseInt(data.TWSO2P_PM), 
                TWSO2P_KG: parseInt(data.TWSO2P_KG), 
                TWSO2P_KM: parseInt(data.TWSO2P_KM), 
                TWSO3P_PG: parseInt(data.TWSO3P_PG), 
                TWSO3P_PM: parseInt(data.TWSO3P_PM), 
                TWSO3P_KG: parseInt(data.TWSO3P_KG), 
                TWSO3P_KM: parseInt(data.TWSO3P_KM), 
                TWN1P_PG: parseInt(data.TWN1P_PG), 
                TWN1P_PM: parseInt(data.TWN1P_PM), 
                TWN1P_KG: parseInt(data.TWN1P_KG), 
                TWN1P_KM: parseInt(data.TWN1P_KM), 
                TWN2P_PG: parseInt(data.TWN2P_PG), 
                TWN2P_PM: parseInt(data.TWN2P_PM), 
                TWN2P_KG: parseInt(data.TWN2P_KG), 
                TWN2P_KM: parseInt(data.TWN2P_KM), 
                TWN3P_PG: parseInt(data.TWN3P_PG), 
                TWN3P_PM: parseInt(data.TWN3P_PM), 
                TWN3P_KG: parseInt(data.TWN3P_KG), 
                TWN3P_KM: parseInt(data.TWN3P_KM),

                TWP1T: parseInt(data.TWP1T),
                TWP2T: parseInt(data.TWP2T),
                TWP3T: parseInt(data.TWP3T),

                TWW1T: parseInt(data.TWW1T),
                TWW2T: parseInt(data.TWW2T),
                TWW3T: parseInt(data.TWW3T),

                TWS1T: parseInt(data.TWS1T),
                TWS2T: parseInt(data.TWS2T),
                TWS3T: parseInt(data.TWS3T),

                TWC1T: parseInt(data.TWC1T),
                TWC2T: parseInt(data.TWC2T),
                TWC3T: parseInt(data.TWC3T),

                TWPT1T: parseInt(data.TWPT1T),
                TWPT2T: parseInt(data.TWPT2T),
                TWPT3T: parseInt(data.TWPT3T),

                TWSO1T: parseInt(data.TWSO1T),
                TWSO2T: parseInt(data.TWSO2T),
                TWSO3T: parseInt(data.TWSO3T),

                TWN1T: parseInt(data.TWN1T),
                TWN2T: parseInt(data.TWN2T),
                TWN3T: parseInt(data.TWN3T),
                 
                CYR_FILL: parseInt(data.CYR_FILL), 
                CYR_BS: parseInt(data.CYR_BS), 
            },
            MAG_C_CH: parseInt(data.MAG_C_CH),
        }

        const getAdditional = (data) => { 
            const allRegistryKeys = Object.keys(data); 
            let addArr = [] 
 
            return allRegistryKeys  
                .map((add) => add.match(/^OB\d+/)?.[0]) 
                .reduce((all, current) => { 
                    if (current && !all.includes(current)) { 
                        addArr.push(current) 
                    } 
                    return addArr 
                }) 
        } 
 
        for (let i = 0; i < getAdditional(data).length; i++) { 
            registries.periphery.heatingCircuits = {...registries.periphery.heatingCircuits, 
                additional: {...registries.periphery.heatingCircuits.additional, 
                    map: { 
                        ...registries.periphery.heatingCircuits.additional.map, 
                        [`OB${i+1}_XZM_TRANSM`]: parseInt(data[`OB${i+1}_XZM_TRANSM`]), 
                    }, 
                    [`map${i+1}`]: { 
                        ...registries.periphery.heatingCircuits.additional[`map${i+1}`], 
                        [`OB${i+1}_TCO`]: parseInt(data[`OB${i+1}_TCO`]), 
                        [`OB${i+1}_TWEW`]: parseFloat(data[`OB${i+1}_TWEW`]), 
                        [`OB${i+1}_ZAD_TCO`]: parseInt(data[`OB${i+1}_ZAD_TCO`]), 
                        [`OB${i+1}_TKMF`]: parseInt(data[`OB${i+1}_TKMF`]), 
                        [`OB${i+1}_KRZYWA`]: parseInt(data[`OB${i+1}_KRZYWA`]), 
                        [`OB${i+1}_TKMF_BAZ`]: parseInt(data[`OB${i+1}_TKMF_BAZ`]), 
                        [`OB${i+1}_XZM_TRANSM`]: parseInt(data[`OB${i+1}_XZM_TRANSM`]), 
                    }, 
                    [`OB${i+1}_PCO`]: parseInt(data[`OB${i+1}_PCO`]), 
                }, 
            } 
        } 

        let extDataArray = []
        let extenders = {}

        for (let i = 0; i < receivedData.length; i++) {
            if (receivedData[i].Type === 'FriskoEXT') {
                const extTabData = receivedData[i];
                extDataArray[i] = extTabData.Data;
                extenders[`extender_${receivedData[i].Name.slice(-1)}`] = {
                        extDevice: {
                            Name: extTabData.Name,
                            Type: extTabData.Type,
                            Version: extTabData.Version,
                        },
                        settingsPV: {
                            LICZNIK_PV_PWR_NADPROD: parseInt(extDataArray[i].LICZNIK_PV_PWR_NADPROD),
                            PW_G: parseInt(extDataArray[i].PW_G),
                            PZ_G: parseInt(extDataArray[i].PZ_G),
                            PW_P: parseInt(extDataArray[i].PW_P),
                            PZ_P: parseInt(extDataArray[i].PZ_P),
                            WYLI_PROG_ZAL_PC: parseInt(extDataArray[i].WYLI_PROG_ZAL_PC), 
                            PZ_P_G: parseInt(extDataArray[i].PZ_P_G),
                        },
                        tempSettings: {
                            SP_CO_G: parseFloat(extDataArray[i].SP_CO_G),
                            TSM17: parseFloat(data.TSM17),
                            SP_CWU_G: parseFloat(extDataArray[i].SP_CWU_G),
                            TSM15: parseFloat(data.TSM15),
                            SP_T_REGEN: parseFloat(extDataArray[i].SP_T_REGEN),
                            T_ODW_IN: parseFloat(extDataArray[i].T_ODW_IN),
                            SP_CWU_REGENER: parseFloat(extDataArray[i].SP_CWU_REGENER),
                            SP_CH_Z2: parseFloat(extDataArray[i].SP_CH_Z2),
                            T_Z2_1: parseFloat(extDataArray[i].T_Z2_1),
                        },
                        status: { 
                            SIO2_R01_G1: parseInt(extDataArray[i].SIO2_R01_G1), 
                            SIO2_R02_G2: parseInt(extDataArray[i].SIO2_R02_G2),
                            SIO2_R11_P_REGEN: parseInt(extDataArray[i].SIO2_R11_P_REGEN), 
                            SIO2_R07_DZ: parseInt(extDataArray[i].SIO2_R07_DZ), 
                            LICZNIK_PV_KOM: parseInt(extDataArray[i].LICZNIK_PV_KOM), 
                            LICZNIK_PC_KOM: parseInt(extDataArray[i].LICZNIK_PC_KOM), 
                            AOR_FB: parseInt(extDataArray[i].AOR_FB), 
                        }, 
                        forcePV: { 
                            SIO2_R09_PV_READY: parseInt(extDataArray[i].SIO2_R09_PV_READY), 
                            PV_READY_AC: parseInt(extDataArray[i].PV_READY_AC), 
                            PV_READY_THR1: parseInt(extDataArray[i].PV_READY_THR1), 
                            PV_READY_THR2: parseInt(extDataArray[i].PV_READY_THR2), 
                            PV_READY_THR3: parseInt(extDataArray[i].PV_READY_THR3), 
                        }, 
                        tempDisplay : { 
                            T_CWU_2: parseFloat(extDataArray[i].T_CWU_2), 
                            T_BUF_2: parseFloat(extDataArray[i].T_BUF_2), 
                            T_Z2_1: parseFloat(extDataArray[i].T_Z2_1), 
                            T_Z2_2: parseFloat(extDataArray[i].T_Z2_2), 
                            T_Z2_3: parseFloat(extDataArray[i].T_Z2_3), 
                            T_PVT: parseFloat(extDataArray[i].T_PVT), 
                            T_OB_CHL: parseFloat(extDataArray[i].T_OB_CHL), 
                            T_ODW_IN: parseFloat(extDataArray[i].T_ODW_IN), 
                            T_ODW_OUT: parseFloat(extDataArray[i].T_ODW_OUT), 
                        }, 
                        mainDriver: { 
                            TB1: parseFloat(data.TB1), 
                            SX3_HG: parseFloat(data.SX3_HG), 
                            TB2: parseFloat(data.TB2), 
                            SX3_HC: parseFloat(data.SX3_HC), 
                            SPDHW_ZAD: parseFloat(data.SPDHW_ZAD), 
                            SX5: parseFloat(data.SX5), 
                            SPDHW_HIST: parseFloat(data.SPDHW_HIST), 
                        }, 
                        tempMode: {
                            map: { 
                                T01_IN: parseFloat(extDataArray[i].T01_IN), 
                                T02_IN: parseFloat(extDataArray[i].T02_IN), 
                                T03_IN: parseFloat(extDataArray[i].T03_IN), 
                                T04_IN: parseFloat(extDataArray[i].T04_IN), 
                                T05_IN: parseFloat(extDataArray[i].T05_IN), 
                                T06_IN: parseFloat(extDataArray[i].T06_IN), 
                                T07_IN: parseFloat(extDataArray[i].T07_IN), 
                                T08_IN: parseFloat(extDataArray[i].T08_IN), 
                                T09_IN: parseFloat(extDataArray[i].T09_IN), 
                            }, 
                            T01_AOR_EN: parseInt(extDataArray[i].T01_AOR_EN),
                            T02_AOR_EN: parseInt(extDataArray[i].T02_AOR_EN),
                            T03_AOR_EN: parseInt(extDataArray[i].T03_AOR_EN),
                            T04_AOR_EN: parseInt(extDataArray[i].T04_AOR_EN),
                            T05_AOR_EN: parseInt(extDataArray[i].T05_AOR_EN),
                            T06_AOR_EN: parseInt(extDataArray[i].T06_AOR_EN),
                            T07_AOR_EN: parseInt(extDataArray[i].T07_AOR_EN),
                            T08_AOR_EN: parseInt(extDataArray[i].T08_AOR_EN),
                            T09_AOR_EN: parseInt(extDataArray[i].T09_AOR_EN),
                            T01_MAN: parseFloat(extDataArray[i].T01_MAN),
                            T02_MAN: parseFloat(extDataArray[i].T02_MAN),
                            T03_MAN: parseFloat(extDataArray[i].T03_MAN),
                            T04_MAN: parseFloat(extDataArray[i].T04_MAN),
                            T05_MAN: parseFloat(extDataArray[i].T05_MAN),
                            T06_MAN: parseFloat(extDataArray[i].T06_MAN),
                            T07_MAN: parseFloat(extDataArray[i].T07_MAN),
                            T08_MAN: parseFloat(extDataArray[i].T08_MAN),
                            T09_MAN: parseFloat(extDataArray[i].T09_MAN),
                            T01_OUT: parseFloat(extDataArray[i].T01_OUT), 
                            T02_OUT: parseFloat(extDataArray[i].T02_OUT), 
                            T03_OUT: parseFloat(extDataArray[i].T03_OUT), 
                            T04_OUT: parseFloat(extDataArray[i].T04_OUT), 
                            T05_OUT: parseFloat(extDataArray[i].T05_OUT), 
                            T06_OUT: parseFloat(extDataArray[i].T06_OUT), 
                            T07_OUT: parseFloat(extDataArray[i].T07_OUT), 
                            T08_OUT: parseFloat(extDataArray[i].T08_OUT), 
                            T09_OUT: parseFloat(extDataArray[i].T09_OUT),
                            AOR_OFF_TRIGG: parseInt(extDataArray[i].AOR_OFF_TRIGG),
                        },
                        digitalModeIn: { 
                            map:{ 
                                B01_IN: parseFloat(extDataArray[i].B01_IN), 
                                B02_IN: parseFloat(extDataArray[i].B02_IN), 
                                B03_IN: parseFloat(extDataArray[i].B03_IN), 
                                B04_IN: parseFloat(extDataArray[i].B04_IN), 
                                B05_IN: parseFloat(extDataArray[i].B05_IN), 
                            }, 
                            B01_AOR_EN: parseInt(extDataArray[i].B01_AOR_EN), 
                            B02_AOR_EN: parseInt(extDataArray[i].B02_AOR_EN), 
                            B03_AOR_EN: parseInt(extDataArray[i].B03_AOR_EN), 
                            B04_AOR_EN: parseInt(extDataArray[i].B04_AOR_EN), 
                            B05_AOR_EN: parseInt(extDataArray[i].B05_AOR_EN), 
                            B01_MAN: parseFloat(extDataArray[i].B01_MAN), 
                            B02_MAN: parseFloat(extDataArray[i].B02_MAN), 
                            B03_MAN: parseFloat(extDataArray[i].B03_MAN), 
                            B04_MAN: parseFloat(extDataArray[i].B04_MAN), 
                            B05_MAN: parseFloat(extDataArray[i].B05_MAN), 
                            B01_OUT: parseFloat(extDataArray[i].B01_OUT), 
                            B02_OUT: parseFloat(extDataArray[i].B02_OUT), 
                            B03_OUT: parseFloat(extDataArray[i].B03_OUT), 
                            B04_OUT: parseFloat(extDataArray[i].B04_OUT), 
                            B05_OUT: parseFloat(extDataArray[i].B05_OUT), 
                        }, 
                        digitalModeOut: { 
                            map: { 
                                R01_IN: parseFloat(extDataArray[i].R01_IN), 
                                R02_IN: parseFloat(extDataArray[i].R02_IN), 
                                R03_IN: parseFloat(extDataArray[i].R03_IN), 
                                R04_IN: parseFloat(extDataArray[i].R04_IN), 
                                R05_IN: parseFloat(extDataArray[i].R05_IN), 
                                R06_IN: parseFloat(extDataArray[i].R06_IN), 
                                R07_IN: parseFloat(extDataArray[i].R07_IN), 
                                R08_IN: parseFloat(extDataArray[i].R08_IN), 
                                R09_IN: parseFloat(extDataArray[i].R09_IN), 
                                R10_IN: parseFloat(extDataArray[i].R10_IN), 
                                R11_IN: parseFloat(extDataArray[i].R11_IN), 
                            }, 
                            R01_AOR_EN: parseInt(extDataArray[i].R01_AOR_EN),
                            R02_AOR_EN: parseInt(extDataArray[i].R02_AOR_EN),
                            R03_AOR_EN: parseInt(extDataArray[i].R03_AOR_EN),
                            R04_AOR_EN: parseInt(extDataArray[i].R04_AOR_EN),
                            R05_AOR_EN: parseInt(extDataArray[i].R05_AOR_EN),
                            R06_AOR_EN: parseInt(extDataArray[i].R06_AOR_EN),
                            R07_AOR_EN: parseInt(extDataArray[i].R07_AOR_EN),
                            R08_AOR_EN: parseInt(extDataArray[i].R08_AOR_EN),
                            R09_AOR_EN: parseInt(extDataArray[i].R09_AOR_EN),
                            R10_AOR_EN: parseInt(extDataArray[i].R10_AOR_EN), 
                            R11_AOR_EN: parseInt(extDataArray[i].R11_AOR_EN), 
                            R01_MAN: parseFloat(extDataArray[i].R01_MAN), 
                            R02_MAN: parseFloat(extDataArray[i].R02_MAN), 
                            R03_MAN: parseFloat(extDataArray[i].R03_MAN), 
                            R04_MAN: parseFloat(extDataArray[i].R04_MAN), 
                            R05_MAN: parseFloat(extDataArray[i].R05_MAN), 
                            R06_MAN: parseFloat(extDataArray[i].R06_MAN), 
                            R07_MAN: parseFloat(extDataArray[i].R07_MAN), 
                            R08_MAN: parseFloat(extDataArray[i].R08_MAN), 
                            R09_MAN: parseFloat(extDataArray[i].R09_MAN), 
                            R10_MAN: parseFloat(extDataArray[i].R10_MAN), 
                            R11_MAN: parseFloat(extDataArray[i].R11_MAN), 
                            R01_OUT: parseFloat(extDataArray[i].R01_OUT), 
                            R02_OUT: parseFloat(extDataArray[i].R02_OUT), 
                            R03_OUT: parseFloat(extDataArray[i].R03_OUT), 
                            R04_OUT: parseFloat(extDataArray[i].R04_OUT), 
                            R05_OUT: parseFloat(extDataArray[i].R05_OUT), 
                            R06_OUT: parseFloat(extDataArray[i].R06_OUT), 
                            R07_OUT: parseFloat(extDataArray[i].R07_OUT), 
                            R08_OUT: parseFloat(extDataArray[i].R08_OUT), 
                            R09_OUT: parseFloat(extDataArray[i].R09_OUT), 
                            R10_OUT: parseFloat(extDataArray[i].R10_OUT), 
                            R11_OUT: parseFloat(extDataArray[i].R11_OUT),
                        },
                        PWMOut: { 
                            map:{ 
                                PWM1_IN: parseFloat(extDataArray[i].B01_IN), 
                                PWM2_IN: parseFloat(extDataArray[i].B02_IN), 
                            }, 
                            PWM1_AOR_EN: parseInt(extDataArray[i].PWM1_AOR_EN), 
                            PWM2_AOR_EN: parseInt(extDataArray[i].PWM2_AOR_EN), 
                            PWM1_MAN: parseFloat(extDataArray[i].PWM1_MAN), 
                            PWM2_MAN: parseFloat(extDataArray[i].PWM2_MAN), 
                            PWM1_OUT: parseFloat(extDataArray[i].PWM1_OUT), 
                            PWM2_OUT: parseFloat(extDataArray[i].PWM2_OUT), 
                        }, 
                        AOR_FB: parseInt(extDataArray[i].AOR_FB),
                        SIO2_R08_DOL_ZR_BUF_Z2: parseInt(extDataArray[i].SIO2_R08_DOL_ZR_BUF_Z2),
                        SIO2_R07_DOL_ZR_ODW: parseInt(extDataArray[i].SIO2_R07_DOL_ZR_ODW),
                        SIO2_R03_REGEN_OTW: parseInt(extDataArray[i].SIO2_R03_REGEN_OTW),
                        SIO2_R04_REGEN_ZAMK: parseInt(extDataArray[i].SIO2_R04_REGEN_ZAMK),
                        SIO2_R05_MIESZ_CHL_OTW: parseInt(extDataArray[i].SIO2_R05_MIESZ_CHL_OTW),
                        SIO2_R06_MIESZ_CHL_ZAM: parseInt(extDataArray[i].SIO2_R06_MIESZ_CHL_ZAM),
                        SIO2_R10_PVT: parseInt(extDataArray[i].SIO2_R10_PVT),
                        SIO2_B01: parseInt(extDataArray[i].SIO2_B01),
                        SIO2_B02: parseInt(extDataArray[i].SIO2_B02),
                        SIO2_B03: parseInt(extDataArray[i].SIO2_B03),
                        SIO2_B04: parseInt(extDataArray[i].SIO2_B04),
                        SIO2_B05: parseInt(extDataArray[i].SIO2_B05),
                        LICZNIK_PV_PWR_ODB: parseInt(extDataArray[i].LICZNIK_PV_PWR_ODB),
                        LICZNIK_PV_PWR_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PV_PWR_FLOAT1),
                        LICZNIK_PV_PWR_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PV_PWR_FLOAT2),
                        LICZNIK_PV_ENE_IMP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PV_ENE_IMP_FLOAT1),
                        LICZNIK_PV_ENE_IMP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PV_ENE_IMP_FLOAT2),
                        LICZNIK_PV_ENE_EXP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PV_ENE_EXP_FLOAT1),
                        LICZNIK_PV_ENE_EXP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PV_ENE_EXP_FLOAT2),
                        LICZNIK_PC_PWR_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PC_PWR_FLOAT1),
                        LICZNIK_PC_PWR_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PC_PWR_FLOAT2),
                        LICZNIK_PC_ENE_IMP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PC_ENE_IMP_FLOAT1),
                        LICZNIK_PC_ENE_IMP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PC_ENE_IMP_FLOAT2),
                        LICZNIK_PC_ENE_EXP_FLOAT1: parseFloat(extDataArray[i].LICZNIK_PC_ENE_EXP_FLOAT1),
                        LICZNIK_PC_ENE_EXP_FLOAT2: parseFloat(extDataArray[i].LICZNIK_PC_ENE_EXP_FLOAT2),
                        LICZNIK_PV_PWR_NADPROD_AR: parseFloat(extDataArray[i].LICZNIK_PV_PWR_NADPROD_AR),
                        LICZNIK_PV_PWR_ODB_AR: parseFloat(extDataArray[i].LICZNIK_PV_PWR_ODB_AR),
                        VER_DZIEN_2: parseInt(extDataArray[i].VER_DZIEN_2),
                        VER_MIESIAC_2: parseInt(extDataArray[i].VER_MIESIAC_2),
                        VER_ROK_2: parseInt(extDataArray[i].VER_ROK_2), 
                    }
                }
                registries = {
                    ...registries,
                    extenders
                }
        }  
        return registries    
    } else {
        return null
    }
    
}