import React, { useRef, useReducer, useEffect, useState } from 'react'
import {
    Grid, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress'
import { mainStyleClasses } from '../../theme'
import { mqttConfig } from '../../config/mqttConfig'
import { mqttClient } from '../../config/mqttClient'
import { functionPublish } from '../../config/functionPublish'
import { dataDescriptions } from './data/dataDescriptions'
import { dataRange } from './data/dataRange'
import { FileUpload } from '../../components';

const registerListReducer = (state, action) => {
    let data = action.payload
    let registers = []
    console.log(data)
    let index = 0

    data.map((device, ind) => (
        Object.keys(device.data).sort().map((register, i) => {
            let reg = {}
            reg.id = index
            reg.registerName = register
            reg.value = dataRange()[register] ? `${device.data[register]}${dataRange()[register].symbol}` : device.data[register]
            reg.device = device.name
            reg.device_type = device.type
            reg.device_version = device.version

            index = index + 1

            return registers.push(reg)
        })
    ))

    return registers
}

const RegisterList = () => {
    let { installation, pass } = useParams();
    const [connectMess, setConnectMess] = useState(0)
    const clientRef = useRef(null)
    const classes = mainStyleClasses()
    const [messages, setMessages] = useState(null)
    const [connectionStatus, setConnectionStatus] = useState(false)
    const [registers, setRegisters] = useReducer(registerListReducer, [])
    const [editRegs, setEditRegs] = useState({})
    const [editDevices, setEditDevices] = useState({})
    const [editNames, setEditNames] = useState([])
    const [open, setOpen] = useState(false)
    const [errorOfExpiration, seterrorOfExpiration] = useState(null);
    const [pageSize, setPageSize] = React.useState(50);
    const [openFileUpload, setOpenFileUpload] = useState(false)

    sessionStorage.setItem('deviceName', messages ? messages[0].Name : null)
    sessionStorage.setItem('version', messages ? `${messages[0].VER_S1}.${messages[0].VER_S2}` : null)

    const handleClickOpen = (dialog) => {
        if (dialog === 'saveChanges') {
            setOpen(true) 
        } else if (dialog === 'upload') {
            setOpenFileUpload(true)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setOpenFileUpload(false)
    }

    const handleClickEdit = (names, devices) => {
        let uniqueDevices = [...new Set(Object.values(devices).map(item => item.name))]
        
        let dataAll = []
        let deviceAll = []

        uniqueDevices.map((Device, i) => {
            let deviceNames = names.filter(name => devices[name].name === Device)
            let data = {}
            deviceNames.forEach(name => {
                data[name] = editRegs[name]
            })

            let device = {
                Name: Device,
                Type: devices[deviceNames[0]].type,
                Version: devices[deviceNames[0]].version,
            }

            dataAll.push(data)
            deviceAll.push(device)
        })

        if (messages && messages[0]) {
            console.log(dataAll)
            functionPublish(clientRef.current, dataAll, installation, deviceAll)
        }
      
        setEditNames([])
        setEditDevices({})
        setOpen(false)
    }
    
    const handleDelete = (name) => {
        let result = editNames.filter(item => item !== name)
        setEditNames(result)

        let result2 = editDevices
        delete result2[name]
        setEditDevices(result2)
    }

    // const [columns, setColumns] = useState([
    const columns = [
        { field: 'registerName', headerName: 'Nazwa rejestru', width: 200, headerClassName: 'user-table-header' },
        { field: 'device', headerName: 'Sterownik', width: 150, headerClassName: 'user-table-header' },
        // { field: 'device_type', headerName: 'Typ sterownika', width: 150, headerClassName: 'user-table-header', hide: true },
        // { field: 'device_version', headerName: 'Wersja sterownika', width: 150, headerClassName: 'user-table-header', hide: true },
        {
            field: "description",
            headerName: "Opis",
            headerClassName: 'user-table-header',
            cellClassName: 'desc-styling',
            flex: 1,
            filterable: false,
            // hide: false,
            renderCell: params => <Typography variant='body1'>{!dataDescriptions() ? "" : dataDescriptions()[params.row.registerName] ? (dataDescriptions()[params.row.registerName] === "NA" ? "" : dataDescriptions()[params.row.registerName]) : ""}</Typography>
        },
        { field: 'value', headerName: 'Aktualna wartość', width: 250, headerClassName: 'user-table-header', filterable: false },
        {
            field: "range",
            headerName: "Zakres",
            headerClassName: 'user-table-header',
            cellClassName: 'desc-styling',
            width: 130,
            filterable: false,
            renderCell: (params) => (
                dataRange()[params.row.registerName] && (dataRange()[params.row.registerName].min || dataRange()[params.row.registerName].max) ? (
                    <div>
                        <span className='data-grid-min'>{`Min: ${dataRange()[params.row.registerName].min ? dataRange()[params.row.registerName].min : (dataRange()[params.row.registerName].min === 0 ? "0" : "-")}`}</span>
                        <span>{`Max: ${dataRange()[params.row.registerName].max ? dataRange()[params.row.registerName].max : (dataRange()[params.row.registerName].max === 0 ? "0" : "-")}`}</span>
                    </div>
                ) : ""
            )
        },
        {
            field: "edit",
            headerName: "Edytuj wartość",
            sortable: false,
            headerClassName: 'user-table-header',
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return <TextField name={params.row.registerName} type="number" step={"any"}
                    onChange={(e) => onChangeInput(e, params.row.device, params.row.device_type, params.row.device_version)}
                    value={editRegs[params.row.registerName] ? editRegs[params.row.registerName] : ""} />;
            }
        },
    ]
    // ])
    
    const onChangeInput = (e, device, device_type, device_version) => {
        let names = editNames
        
        if (!names.includes(e.target.name)) {
            names.push(e.target.name)
            setEditNames(names)
        }
        
        setEditRegs({ ...editRegs, [e.target.name]: e.target.value })
        setEditDevices({ ...editDevices, [e.target.name]: { name: device, type: device_type, version: device_version } })
    }

    useEffect(() => {
        clientRef.current = mqttClient.getClient(mqttConfig())
        clientRef.current.subscribe(`${installation}/Dev`)
        clientRef.current.on('connect', () => setConnectionStatus(true))
        clientRef.current.on('message', function (topic, message, packet) {

            if (topic === `${installation}/Dev` && JSON.parse(message)) {
                let DateTime = new Date()
                let StampTime = new Date()
                DateTime = Date.now()

                StampTime = Date.parse(JSON.parse(message).TimeStamp)
                if (DateTime - StampTime > JSON.parse(message).ExpirationTime + 3000) {
                    seterrorOfExpiration(1)
                } else {
                    seterrorOfExpiration(null)
                }
                let saveToState = JSON.parse(message).Devices
                saveToState.expirationData = {
                    ExpirationTime: JSON.parse(message).ExpirationTime,
                    TimeStamp: JSON.parse(message).TimeStamp
                }

                setMessages(saveToState)
                console.log(JSON.parse(message))
                let extdata = JSON.parse(message).Devices.map((device, i) => {
                    return { name: device.Name, data: device.Data, type: device.Type, version: device.Version }
                })
                
                // setRegisters({ payload: JSON.parse(message).Devices[0].Data })
                setRegisters({ payload: extdata })
            }
        })

        return () => {
            if (clientRef.current) {
                clientRef.current.unsubscribe(`${installation}/Dev`)
                clientRef.current.end(clientRef.current)
            }
        }

    }, [installation])

    const startedAppData = (statusStart) => {
        if (statusStart === 0) {
            setConnectMess(1)
            let extdata = messages.map((device, i) => {
                return device.Data
            })
            let flatten = {}
            for(let i = 0; i < extdata.length; i++ ) {
                Object.assign(flatten, extdata[i]);
            }
            
            setEditRegs(flatten)
        }
    }

    const handleSaveRegistries = () => {
        const data = JSON.stringify(messages, null, "\t");
        const myBlob = new Blob(
            [data],
            {type: "text/plain"}
        );
    
        const url = URL.createObjectURL(myBlob);
        const link = document.createElement('a');
        link.download = `${installation}_${new Date().getFullYear()}.${new Date().getMonth() + 1 < 10 ? 0 + (new Date().getMonth() + 1).toString() : new Date().getMonth() + 1}.${new Date().getDate() < 10 ? new Date().getDate().toString() : new Date().getDate()}.json`;
        link.href = url;
        link.click();
    }

    useEffect(() => {
        let mounted = true
        if (messages)
            startedAppData(connectMess)

        return () => {
            mounted = false
        }
    }, [connectMess, messages])

    return (
        <React.Fragment>
            <Grid container style={{ position: "sticky", zIndex: 99, backgroundColor: 'white', top: 0 }}>
                <Grid item xs={2} style={{ padding: 0 }}>
                    <Typography variant="h2">Lista rejestrów</Typography>
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                    <Button variant="contained" color="primary"
                        onClick={handleSaveRegistries} >
                        Zapisz konfigurację
                    </Button>
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                    <Button variant="contained" color="primary"
                        onClick={() => handleClickOpen('upload')} >
                        Załaduj konfigurację
                    </Button>
                </Grid>
            </Grid>
            {registers ? (<Grid container>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                    <DataGrid
                        columns={columns}
                        rows={registers}
                        autoHeight
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 30, 50, 100]}
                        pagination
                    />
                </Grid>
            </Grid>) : <CircularProgress />}
            <Button variant="contained" color="primary" style={{ position: "fixed", right: "5%", bottom: "5%", zIndex: 99 }}
                onClick={() => handleClickOpen('saveChanges')} >
                Zatwierdź zmiany
            </Button>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="edit-registers">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <Typography variant="h2">Lista zmian</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography component="div" height="100%">
                                                    <Box fontWeight="fontWeightBold" height="100%">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item xs={12} style={{ padding: '0' }}>
                                                                Nazwa rejestru
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div" height="100%">
                                                    <Box fontWeight="fontWeightBold" height="100%">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item xs={12} style={{ padding: '0' }}>
                                                                Sterownik
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div" height="100%">
                                                    <Box fontWeight="fontWeightBold" height="100%">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item xs={12} style={{ padding: '0' }}>
                                                                Obecna wartość
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item xs={12} style={{ padding: '0' }}>
                                                                Wartość wprowadzona
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item xs={12} style={{ padding: '0' }}>
                                                                Usuń zmianę
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {editNames && editNames.length > 0 ? (
                                            editNames.map((name, id) => (
                                                <TableRow key={id}>
                                                    <TableCell>{name}</TableCell>
                                                    <TableCell>{registers.find(x => x.registerName === name).device}</TableCell>
                                                    <TableCell>{registers.find(x => x.registerName === name).value}</TableCell>
                                                    <TableCell>{editRegs[name]}</TableCell>
                                                    <TableCell>
                                                        <IconButton aria-label="deleteItem" onClick={() => handleDelete(name)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow><TableCell align="center" colSpan={12}>Nie wprowadzono zmian rejestrów</TableCell></TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" className={`${classes.floatRight}`}
                                style={{ marginTop: "20px" }} onClick={() => handleClickEdit(editNames, editDevices)}>Zatwierdź</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={openFileUpload} onClose={handleClose} aria-labelledby="file-upload">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container direction="row" >
                        <FileUpload client={clientRef.current} installation={installation} handleClose={handleClose} /> 
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default RegisterList