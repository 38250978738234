import React from 'react'

export const TimeOption = ({ start, stop, nameKey, multiplier }) => {
    const items = [];
    for (let i = start; i < stop; i++) {
        if (multiplier) {
            items.push(<option key={nameKey + i} value={i * 10}> {i === 0 ? `0${i}` : i * 10} </option>);
        } else {
            items.push(<option key={nameKey + i} value={i}> {i < 10 ? `0${i}` : i} </option>);
        }
    }

    return items;
}
