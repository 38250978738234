import { createTheme  } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'

const theme = createTheme ({
    overrides: {
        // Style sheet name ⚛️
        MuiContainer: {
            root: {
                marginTop: '0 !important',
            },
        },
        MuiFormControl: {
            root: {
                margin: '0 auto 0px',
                '& .PrivateSwitchBase-': {
                    padding: 0,
                },
                width: '100%'
            },
        },
        MuiFormLabel: {
            root: {
                marginBottom: '10px',
                '@media (max-width:600px)': {
                    fontSize: '12px',
                  },
            }
        },
        MuiButton: {
            fontFamily: [
                'Geogrotesque-Regular',
                'Geogrotesque-SemiBold',
                'Geogrotesque-Medium',
                'sans-serif',
            ].join(','),
            root: {
                fontFamily: 'Geogrotesque-Regular',
            },
        },
        MuiInput: {
            underline: {
                '&:hover': {
                    '&:not(.Mui-disabled)': {
                        '&:before': {
                            borderBottom: "2px solid #005492",
                        },
                    },
                },
            },
            root: {
                marginBottom: "15px",
                padding: "0 5px",
            '@media (max-width:600px)': {
                fontSize: '14px',
              },
            },
        },
        MuiInputLabel: {
            root: {
                '@media (max-width:600px)': {
                    fontSize: '14px',
                  },
            },
        },
        MuiSwitch: {
            thumb: {
                width: '29px',
                height: '29px',
            },
            track: {
                borderRadius: '13px',
                opacity: 1,
                backgroundColor: '#868383',
            },
            switchBase: {
                left: '0',
                top: '-7px',
                '& .Mui-checked': {
                    opacity: 1
                },
                color: '#b3b3b3',
            },
            colorPrimary: {
                opacity: 1,
                '& .Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#b3b3b3 !important',
                }
            },
            root: {
                width: '65px',
                height: '40px',
                padding: '4px 8px 11px 8px',
            },
        },
        MuiTabs: {
            indicator: {
                display: 'none',
            },
            root: {
                marginTop: '20px',
            },
        },
        MuiTab: {
            root: {
                border: '2px solid #ffffff !important',
                maxWidth: '14% !important',
                width: '12.5% !important',
                minWidth: 'auto !important',
                margin: '0 6px',
                fontSize: '42px',
                lineHeight: 1,
                borderRadius: '4px',
                textColorPrimary: {
                    color: '#fffffff',
                },
            }
        },
        MuiSlider: {
            markLabel:{
                color: '#ffffff !important',
                top: 0,
            }
        },
        /* MuiDialog: {
            paper: {
                background: "rgba(0, 0, 0, 0.85)",
                border: "2px solid rgba(255, 255, 255, 0.6)",
                color: "#ffffff",
                borderRadius: "12px",
            }
        }, */
        /* MuiIconButton: {
            root: {
                color: "rgba(255, 255, 255, 0.6)"
            },
        }, */
        /* MuiSvgIcon: {
            root: {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "36px",
                border: "2px solid rgba(255, 255, 255, 0.8)",
                borderRadius: "60px"
            },
        }, */
        /* MuiTable: {
            root: {
               marginTop: "35px"
            }
        }, */
        /* MuiTableContainer: {
            root: {
                overflowX: 'clip'
            }
        }, */
        MuiTableHead: {
            root: {
                backgroundColor: '#f5f5f5',
                color: "#ffffff",
                textTransform: "uppercase",
            }
        },
        MuiTableCell: {
            root: {
                fontWeight: "normal",
                fontSize: '14px',
                verticalAlign: 'top',
            },
            body: {
                color: "rgba(0, 0, 0, 1)",
            }
        },
        MuiInputBase: {
            input: {
                textAlign: 'center'
            },
            inputMultiline: {
                textAlign: 'left'
            }
        },
        MuiGrid: {
            item: {
                padding: '0 15px',
                position: 'relative'
            }
        },
        MuiMenu: {
            list: {
                textAlign: 'center'
            }
        },
        MuiAlert: {
            root: {
                fontSize: "28px",
            }
        },
       
    },
    typography: {
        fontFamily: [
            'Geogrotesque-Regular',
            'Geogrotesque-SemiBold',
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: 'Geogrotesque-SemiBold',
            fontSize: '36px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '30px',
              },
            '@media (max-width:600px)': {
                fontSize: '26px',
              },
        },
        h2: {
            fontFamily: 'Geogrotesque-SemiBold',
            fontSize: '26px',
            margin: '20px auto',
            '@media (max-width:960px)': {
                fontSize: '22px',
              },
            '@media (max-width:600px)': {
                fontSize: '18px',
                margin: '20px auto 10px',
              },
        },
        h3: {
            fontFamily: 'Geogrotesque-SemiBold',
            fontSize: '18px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '16px',
              },
            '@media (max-width:600px)': {
                fontSize: '14px',
              },
        },
        body2: {
            // fontSize: '12px',
            margin: '0 auto',
            fontWeight: 'lighter',            
            '@media (max-width:600px)': {
                fontSize: '10px',
              },
        },
        body1: {
            fontSize: '14px',
        },
    },
    palette: {
        primary: {
            main: '#005492',
        },
    },
    margin: {
        marginLarge: '50px auto',
        marginMedium: '30px auto',
        marginSmall: '15px auto',
    },
    input: {
        marginBottom: '20px',
    },
}, plPL)



export default theme