import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dopuszczenie_C from '../../../../img/dopuszczenie-C.svg'
import dopuszczenie_H from '../../../../img/dopuszczenie-H.svg'
import dopuszczenie_HC from '../../../../img/dopuszczenie-HC.svg'
import dopuszczenie_HC_off from '../../../../img/dopuszczenie-HC-off.svg'
import ALEGIONELLA_on from '../../../../img/ALEGIONELLA-on.svg'
import ALEGIONELLA_off from '../../../../img/ALEGIONELLA-off.svg'
import CYRKULACJA_on from '../../../../img/CYRKULACJA-on.svg'
import CYRKULACJA_off from '../../../../img/CYRKULACJA-off.svg'
import ALARM_on from '../../../../img/ALARM-on.svg'
import ALARM_off from '../../../../img/ALARM-off.svg'
import ustawienia from '../../../../img/ustawienia.svg'
import turbo_on from '../../../../img/turbo_cwu_on.svg'
import turbo_off from '../../../../img/turbo_cwu_off.svg'
import disabled from '../../../../img/disabled.svg'
import X from '../../../../img/x.svg'

import { Grid, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import HeatCoolState from './components/HeatCoolState/HeatCoolState'
import { useRouteMatch } from "react-router-dom"


const RightSide = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [heatCoolState, setHeatCoolState] = useState(0)
    const [heaterSet, setHeaterSet] = useState(null)
    const [more, setMore] = useState({ status: false, name: "", value: {} })

    const handleOnclick = (e) => {
        if (settings[e] === 0) {
            setPublish({ [e]: 1 })
            setHeaterSet({
                ...heaterSet,
                [e]: 1
            })
        } else {
            setPublish({ [e]: 0 })
            setHeaterSet({
                ...heaterSet,
                [e]: 0
            })
        }
    }

    const handleOnCirculation = () => {
        setPublish({
            CYRSW_S1: 1,
            CYRSW_S2: 1,
            CYRSW_S3: 1,
            CYRSW_PN: 1,
            CYRSW_WT: 1,
            CYRSW_SR: 1,
            CYRSW_CZ: 1,
            CYRSW_PT: 1,
            CYRSW_SO: 1,
            CYRSW_ND: 1,
        })
    }

    // const handleHeatCoolState = () => {
    //     if (settings.SBF_ACX === 0 && settings.SBF_FCX === 0) {
    //         setPublish({ SBF_HTS: 1, SBF_ACS: 0, SBF_FCS: 0 })
    //         setHeatCoolState(2)

    //     } else if (settings.SBF_HTX === 0 && settings.SBF_FCX === 0) {
    //         setPublish({ SBF_HTS: 0, SBF_ACS: 1, SBF_FCS: 0 })
    //         setHeatCoolState(3)

    //     } else if (settings.SBF_HTX === 0 && settings.SBF_ACX === 0) {
    //         setPublish({ SBF_HTS: 0, SBF_ACS: 0, SBF_FCS: 1 })
    //         setHeatCoolState(3)

    //     } else if (settings.SBF_FCX === 0) {
    //         switch (heatCoolState) {
    //             case 0:
    //                 setPublish({ SBF_HTS: 1, SBF_ACS: 1, SBF_FCS: 0 })
    //                 setHeatCoolState(1)
    //                 break;

    //             case 1:
    //                 setPublish({ SBF_HTS: 1, SBF_ACS: 0, SBF_FCS: 0 })
    //                 setHeatCoolState(2)
    //                 break;

    //             case 2:
    //                 setPublish({ SBF_HTS: 0, SBF_ACS: 1, SBF_FCS: 0 })
    //                 setHeatCoolState(3)
    //                 break;

    //             case 3:
    //                 setPublish({ SBF_HTS: 1, SBF_ACS: 1, SBF_FCS: 0 })
    //                 setHeatCoolState(1)
    //                 break;

    //             default:
    //                 break;
    //         }
    //     } else if (settings.SBF_ACX === 0) {
    //         switch (heatCoolState) {
    //             case 0:
    //                 setPublish({ SBF_HTS: 1, SBF_ACS: 0, SBF_FCS: 1 })
    //                 setHeatCoolState(1)
    //                 break;

    //             case 1:
    //                 setPublish({ SBF_HTS: 1, SBF_ACS: 0, SBF_FCS: 0 })
    //                 setHeatCoolState(2)
    //                 break;

    //             case 2:
    //                 setPublish({ SBF_HTS: 0, SBF_ACS: 0, SBF_FCS: 1 })
    //                 setHeatCoolState(3)
    //                 break;

    //             case 3:
    //                 setPublish({ SBF_HTS: 1, SBF_ACS: 0, SBF_FCS: 1 })
    //                 setHeatCoolState(1)
    //                 break;

    //             default:
    //                 break;
    //         }
    //     } else if (settings.SBF_HTX === 0) {
    //         switch (heatCoolState) {
    //             case 0:
    //                 setPublish({ SBF_HTS: 0, SBF_ACS: 1, SBF_FCS: 0 })
    //                 setHeatCoolState(3)
    //                 break;

    //             case 3:
    //                 setPublish({ SBF_HTS: 0, SBF_ACS: 0, SBF_FCS: 1 })
    //                 setHeatCoolState(4)
    //                 break;

    //             case 4:
    //                 setPublish({ SBF_HTS: 0, SBF_ACS: 1, SBF_FCS: 0 })
    //                 setHeatCoolState(3)
    //                 break;   

    //             default:
    //                 break;
    //         }
    //     }
    // }

    const handleClickOpenMore = (name) => {
            setMore({ ...more, status: true, name: name })
    }

    const handleCloseMore = () => {
            setMore({ ...more, status: false })
    }

    useEffect(() => {
        let mounted = true
        setHeaterSet(settings)
        return () => {
            mounted = false
        }
    }, [settings])

// console.log({heaterSet})
useEffect(() => {
    let mounted = true
    if ((settings.SBF_HTX === 0 || settings.SBF_HTS === 0) && (settings.SBF_ACX === 0 || settings.SBF_ACS === 0) && (settings.SBF_FCX === 0 || settings.SBF_FCS === 0)) {
        setHeatCoolState(0)
    } else if (settings.SBF_HTS === 1 && settings.SBF_HTX === 1 && ((settings.SBF_ACS === 1 &&  settings.SBF_ACX === 1) || (settings.SBF_FCS === 1 &&  settings.SBF_FCX === 1))) {
        setHeatCoolState(1)
    } else if (settings.SBF_HTS === 1 && settings.SBF_HTX === 1) {
        setHeatCoolState(2)
    } else if ((settings.SBF_ACS === 1 && settings.SBF_ACX === 1) || (settings.SBF_FCS === 1 && settings.SBF_FCX === 1)) {
        setHeatCoolState(3)
    }
    return () => {
        mounted = false
    }
}, [settings])

let { url } = useRouteMatch()

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    {settings.REW_ERR === 1 || settings.MB_COMM2_XZM_TRANSM === 1 || settings.REW_FCS === 1 || (settings.REW_FLO === 1 && settings.REW_ERR === 1) || settings.REW_TER === 1 || settings.REW_SMD === 1 || settings.SXB_FER === 1 ? 
                        <Link to={`${url}/warnings`} ><img alt="alarmy" className="mode-icon" src={ALARM_on} /></Link>
                        : <Link to={`${url}/warnings`} ><img alt="alarmy" className="mode-icon" src={ALARM_off} /></Link>}
                </Grid>
            </Grid>
            <Grid container onClick={() => handleClickOpenMore("heatCoolState")}>
                <Grid item xs={12}>
                    {heatCoolState === 0 || settings.SBF_OOF === 0 ?
                        <img alt="grzanie i chłodzenie" className="mode-icon" src={dopuszczenie_HC_off} />
                        : heatCoolState === 1 ?
                            <img alt="dopuszczenie grzania i chłodzenia" className="mode-icon" src={dopuszczenie_HC} />
                            : heatCoolState === 2 ?
                                <img alt="dopuszczenie grzania" className="mode-icon" src={dopuszczenie_H} />
                                : <img alt="dopuszczenie chłodzenia" className="mode-icon" src={dopuszczenie_C} />
                    }
                </Grid>
            </Grid>
            {heaterSet && heaterSet.SBF_DHS === 1 && heaterSet.SBF_DHX === 1 && heaterSet.SBF_OOF === 1 ?
                heaterSet.SWEXT_T_CWU === 1 || heaterSet.SWEXT_T_CWU === 0 ?
                    heaterSet.SXF_ANL === 1 && heaterSet.SBF_PHT === 1 ?
                        <img alt="antylegionella" className="mode-icon" src={ALEGIONELLA_on} />
                        : <Grid container onClick={() => handleOnclick("SWEXT_T_CWU")}>
                            <Grid item xs={12}>
                                {
                                    heaterSet.SWEXT_T_CWU === 1 ?
                                        <img alt="turbo cwu" className="mode-icon" src={turbo_on} />
                                        : <img alt="turbo cwu" className="mode-icon" src={turbo_off} />
                                }
                            </Grid>
                        </Grid>
                    : heaterSet.SXF_ANL === 1 ?
                        // <Grid container onClick={() => handleOnclick("SBF_PHT")}>
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    heaterSet.SBF_PHT === 1 ?
                                        <img alt="antylegionella" className="mode-icon" src={ALEGIONELLA_on} />
                                        : <img alt="antylegionella" className="mode-icon" src={ALEGIONELLA_off} />
                                }
                            </Grid>
                        </Grid>
                        : <Grid container>
                            <Grid item xs={12}>
                                <img style={{ cursor: "default"}} alt="brak funkcji" className="mode-icon" src={disabled} />
                            </Grid>
                        </Grid>
                : <Grid container>
                    <Grid item xs={12}>
                        <img style={{ cursor: "default"}} alt="brak funkcji" className="mode-icon" src={disabled} />
                    </Grid>
                </Grid>
            }
            <Grid container>
                <Grid item xs={12} onClick={handleOnCirculation}>
                    {settings.SXF_FLPCYR === 1 ? <Link to={`${url}/circulation`} ><img alt="cyrkulacja" className="mode-icon" src={CYRKULACJA_on} /></Link>
                        : <Link to={`${url}/circulation`} ><img alt="cyrkulacja" className="mode-icon" src={CYRKULACJA_off} /></Link>}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Link to={`${url}/settings`} ><img alt="ustawienia" className="mode-icon" src={ustawienia} /></Link>
                </Grid>
            </Grid>

            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"md"} open={more.status} onClose={handleCloseMore} aria-labelledby="form-dialog-title" className='euros-box-dialog'>
                <DialogContent style={{ padding: "10px 25px" }}>
                    <IconButton aria-label="close" onClick={handleCloseMore} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}>
                            <HeatCoolState settings={settings} setPublish={setPublish}/> 
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default RightSide