import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'

// const deletionStatisticsState = (setPublish) => {
//     setPublish({
//         TIME_FST: 0,
//         TIME_WEEK: 0,
//         CORSTA: 0,
//         CORSTA_SPSMIN: 0
//     })
// }

const PeakSourceTime = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState(false)
    const [itemValue, setItemValue] = useState(settings)

    const deviceName = sessionStorage.getItem('deviceName')
	const version_1 = parseFloat(sessionStorage.getItem('version_1'))
	const version_2 = parseFloat(sessionStorage.getItem('version_2'))

    // const handleClickOpen = () => {
    //     setOpen(true)
    // }

    // const handleClose = () => {
    //     setOpen(false)
    // }

    // const handleDeletionStatistics = () => {
    //     deletionStatisticsState(setPublish)
    //     setState({
    //         ...state,
    //         TIME_FST: 0,
    //         TIME_WEEK: 0,
    //         CORSTA: 0,
    //         CORSTA_SPSMIN: 0
    //     })
    //     handleClose()
    // }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])
    
    return (
        <Grid container className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid item xs={6}>
                {(deviceName === 'ATMO_1' || deviceName === 'ALLINONE_1') || (deviceName === 'GEO_1' &&  ((version_1 > 870) || (version_1 === 870 && version_2 >= 9))) ? 
                    <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={10}>
                            <Typography variant="body2">Czas pracy ST1:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.textRight}>{Math.round(itemValue.TIME_WEEK * 7 * 24 + itemValue.TIME_FST / 60)} h</Typography>
                        </Grid>
                    </Grid>
                    : null
                }
                {deviceName === 'GEO_1' ? 
                    null
                    : <Grid container className={classes.containerVerticalCenter}>
                        <Grid item xs={10}>
                            <Typography variant="body2">Czas pracy ST2:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.textRight}>{Math.round(itemValue.CORSTA_SPSMIN * 7 * 24 + itemValue.CORSTA / 60)} h</Typography>
                        </Grid>
                    </Grid>
                }
                {/*<Grid container>   
                    <Grid item xs={9}>
                        <Typography variant="body2">ST1 - minuty:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{state.TIME_FST}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">Czas pracy ST1:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{Math.round(itemValue.TIME_WEEK * 7 * 24 + itemValue.TIME_FST / 60)} h</Typography>
                    </Grid>
                </Grid>
                {/*<Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">ST1 - tygodnie:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{state.TIME_WEEK}</Typography>
                    </Grid>
                </Grid>
                {/*<Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">ST2 - minuty:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{state.CORSTA}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">Czas pracy ST2:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{Math.round(itemValue.CORSTA_SPSMIN * 7 * 24 + itemValue.CORSTA / 60)} h</Typography>
                    </Grid>
                </Grid>
                {/*<Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">ST2 - tygodnie:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2">{state.CORSTA_SPSMIN}</Typography>
                    </Grid>
                </Grid>*/}
            </Grid>
            {/*<Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleClickOpen()}>Kasuj historię</Button>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h2">Czy napewno chcesz wykasować historię czasu pracy źródła szczytowego?</Typography>
                    <Typography variant="body2">Proces kasowania jest nieodwracalny.</Typography>
                    <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleDeletionStatistics()}>Tak, kasuję permanentnie historię czasu pracy źródła szczytowego</Button>
                </DialogContent>
            </Dialog>*/}
        </Grid>
    )
}

export default PeakSourceTime