import React, { useState, useEffect } from 'react'
import { Grid, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { Button, ButtonGroup, Slider } from '@mui/material'
import { Link } from 'react-router-dom'
import Eco from '../../img/ECO2.svg'
import Back from '../../img/powrot.svg'
import TempCWU from '../../img/Temp-CWU.svg'
import Tempdom from '../../img/Temp-dom.svg'
import X from '../../img/x.svg'
import { CarouselInputMulti } from '../../components'
import { mainStyleClasses } from '../../theme'
import { dataRange } from '../../data/dataRange'

const marks = () => {
    const marksTable = []

    for (let i = 0; i <= 24; i++) {
        marksTable.push({ value: i, label: `${i}` })
    }

    return marksTable
}

function valuetext(value) {
    return `${value}`;
}

const minDistance = 1

const EcoMode = ({ settings, setPublish, setTouchStatus, url }) => {
    const classes = mainStyleClasses()
    const [sezon, setSezon] = useState({ adm: settings.SXF_AZP, sezon: false, wek: settings.SXF_WEK })
    const [value1, setValue] = useState([settings.ECOT1S, sezon.sezon === false ? settings.ECOT2R_Z : settings.ECOT2R_L, sezon.sezon === false ? settings.ECOT2S_Z : settings.ECOT2S_L, settings.ECOT3R])
    const [trackColors, setTrackColors] = useState({
        ECOT1S: (settings.ECOT1S / 24) * 100,
        ECOT2R: sezon.sezon === false ? (settings.ECOT2R_Z / 24) * 100 : (settings.ECOT2R_L / 24) * 100,
        ECOT2S: sezon.sezon === false ? (settings.ECOT2S_Z / 24) * 100 : (settings.ECOT2S_L / 24) * 100,
        ECOT3R: (settings.ECOT3R / 24) * 100,
    })
    const [tempChange, setTempChange] = useState(settings.temp)
    const [open, setOpen] = useState({ status: false, name: '', num: '', init: '', min: 0, max: 0, title: "", val: 0 })

    const handleClickOpen = (name, num, init, min, max, title, val) => {
        setOpen({ status: true, name: name, num: num, init: init, min: min, max: max, title: title, val: val })
    }

    const handleClose = () => {
        setTempChange({...tempChange,
            [open.name]: open.val
        })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({ 
            ...open, 
            val: val 
        })
    }

    const handleClickSezon = (val) => {
        setSezon({ ...sezon, sezon: val })
    }

    const handleWekAdm = () => {
        if (sezon.wek === 1) {
            setPublish({ SXF_WEK: 0 })
            setSezon({ ...sezon, wek: 0 })
        } else {
            setPublish({ SXF_WEK: 1 })
            setSezon({ ...sezon, wek: 1 })
        }
    }

    const handleSezonAdm = () => {
        if (sezon.adm === 1) {
            setPublish({ SXF_AZP: 0 })
            setSezon({ ...sezon, adm: 0, sezon: false })
        } else {
            setPublish({ SXF_AZP: 1 })
            setSezon({ ...sezon, adm: 1, sezon: false })
        }
    }

    const handleChange1 = (event, newValue, activeThumb) => {
        setTouchStatus(true)
        if (!Array.isArray(newValue)) {
            return
        }

        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value1[1] - minDistance), value1[1], value1[2], value1[3]])
            setTrackColors({ ...trackColors, ECOT1S: (Math.min(newValue[0], value1[1] - minDistance) / 24) * 100 })
        }

        if (activeThumb === 1) {
            if (newValue[1] > value1[1]) {
                // console.log({case1_min: Math.min(newValue[0], value1[1] - minDistance)})
                setValue([value1[0], Math.min(newValue[1], value1[2] - minDistance), value1[2], value1[3]])
                setTrackColors({ ...trackColors, ECOT2R: (Math.min(newValue[1], value1[2] - minDistance) / 24) * 100 })
            } else {
                // console.log({case1_max: Math.max(newValue[1], value1[0] + minDistance)})
                setValue([value1[0], Math.max(newValue[1], value1[0] + minDistance), value1[2], value1[3]])
                setTrackColors({ ...trackColors, ECOT2R: (Math.max(newValue[1], value1[0] + minDistance) / 24) * 100 })
            }
        }

        if (activeThumb === 2) {
            if (newValue[2] > value1[2]) {
                // console.log({case1_min: Math.min(newValue[0], value1[1] - minDistance)})
                setValue([value1[0], value1[1], Math.min(newValue[2], value1[3] - minDistance), value1[3]])
                setTrackColors({ ...trackColors, ECOT2S: (Math.min(newValue[2], value1[3] - minDistance) / 24) * 100 })
            } else {
                // console.log({case1_max: Math.max(newValue[1], value1[0] + minDistance)})
                setValue([value1[0], value1[1], Math.max(newValue[2], value1[1] + minDistance), value1[3]])
                setTrackColors({ ...trackColors, ECOT2S: (Math.max(newValue[2], value1[1] + minDistance) / 24) * 100 })
            }
        }

        if (activeThumb === 3) {
            setValue([value1[0], value1[1], value1[2], Math.max(newValue[3], value1[2] + minDistance)])
            setTrackColors({ ...trackColors, ECOT3R: (Math.max(newValue[3], value1[2] + minDistance) / 24) * 100 })
        }

    }

    const handleTouchMouseEnd = (event, newValue) => {
        if (sezon.adm === 0) {
            setPublish({
                [`ECOT1R`]: 0,
                [`ECOT1S`]: newValue[0],
                [`ECOT2R_Z`]: newValue[1],
                [`ECOT2S_Z`]: newValue[2],
                [`ECOT3R`]: newValue[3],
                [`ECOT3S`]: 24,
            })
        } else {
            if (sezon.sezon === false) {
                setPublish({
                    [`ECOT1R`]: 0,
                    [`ECOT1S`]: newValue[0],
                    [`ECOT2R_Z`]: newValue[1],
                    [`ECOT2S_Z`]: newValue[2],
                    [`ECOT3R`]: newValue[3],
                    [`ECOT3S`]: 24,
                })
            } else {
                setPublish({
                    [`ECOT1R`]: 0,
                    [`ECOT1S`]: newValue[0],
                    [`ECOT2R_L`]: newValue[1],
                    [`ECOT2S_L`]: newValue[2],
                    [`ECOT3R`]: newValue[3],
                    [`ECOT3S`]: 24,
                })
            }
        }
        setTouchStatus(false)
    }

    useEffect(() => {
        let mounted = true
        setSezon({ ...sezon, adm: settings.SXF_AZP, wek: settings.SXF_WEK })
        setValue([settings.ECOT1S, sezon.sezon === false ? settings.ECOT2R_Z : settings.ECOT2R_L, sezon.sezon === false ? settings.ECOT2S_Z : settings.ECOT2S_L, settings.ECOT3R])
        setTrackColors({
            ECOT1S: (settings.ECOT1S / 24) * 100,
            ECOT2R: sezon.sezon === false ? (settings.ECOT2R_Z / 24) * 100 : (settings.ECOT2R_L / 24) * 100,
            ECOT2S: sezon.sezon === false ? (settings.ECOT2S_Z / 24) * 100 : (settings.ECOT2S_L / 24) * 100,
            ECOT3R: (settings.ECOT3R / 24) * 100,
        })
        setTempChange(settings.temp)
        return () => {
            mounted = false
        }
    }, [settings])

    useEffect(() => {
        let mounted = true
        setValue([settings.ECOT1S, sezon.sezon === false ? settings.ECOT2R_Z : settings.ECOT2R_L, sezon.sezon === false ? settings.ECOT2S_Z : settings.ECOT2S_L, settings.ECOT3R])
        setTrackColors({
            ECOT1S: (settings.ECOT1S / 24) * 100,
            ECOT2R: sezon.sezon === false ? (settings.ECOT2R_Z / 24) * 100 : (settings.ECOT2R_L / 24) * 100,
            ECOT2S: sezon.sezon === false ? (settings.ECOT2S_Z / 24) * 100 : (settings.ECOT2S_L / 24) * 100,
            ECOT3R: (settings.ECOT3R / 24) * 100,
        })
        return () => {
            mounted = false
        }
    }, [sezon.sezon])


    return (
        <React.Fragment>
            <Grid container className='ecomode'>
                <Grid item xs={2} style={{ textAlign: 'center' }} className='modes'>
                    <img style={{ margin: '15px 20px' }} alt="Tryb ECO" className="small-icon" src={Eco} />
                </Grid>
                <Grid item xs={10}>
                    <Grid container style={{ marginTop: '20px', position: 'relative' }}>
                        <ButtonGroup
                            orientation="horizontal"
                            aria-label="horizontal"
                        >
                            <Button onClick={handleSezonAdm} className={sezon.adm === 1 ? 'active' : null}>Sezonowa</Button>
                            <Button onClick={() => handleClickSezon(false)} className={sezon.adm === 1 ? sezon.sezon === false ? 'active' : null : 'hide'}>Zima</Button>
                            <Button onClick={() => handleClickSezon(true)} className={sezon.adm === 1 ? sezon.sezon === true ? 'active' : null : 'hide'}>Lato</Button>
                            <Button onClick={handleWekAdm} style={{ position: 'absolute', right: '30px', marginRight: '0', display: 'block' }} className={sezon.wek === 1 ? 'active' : null}>Weekend</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid container style={{ marginTop: '30px' }}>
                        <Grid item xs={10}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>Tryb ECO - godziny taniego prądu Pn - Pt <br /> Sb - Nd obniżenia i podwyższenia nie działają</Typography>

                            <div className='back-btn'><Link to={`${url}`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: '70px 50px 0' }}>
                        <Slider
                            className='bottomLabelInvert'
                            getAriaLabel={() => 'Minimum distance'}
                            value={value1}
                            onChange={handleChange1}
                            onChangeCommitted={handleTouchMouseEnd}
                            getAriaValueText={valuetext}
                            max={24}
                            min={0}
                            marks={marks()}
                            disableSwap
                            valueLabelDisplay="on"
                            sx={{
                                '& .MuiSlider-track': {
                                    background: `none transparent`,
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 1,
                                    background: `linear-gradient(90deg,
                                #006837 0%, 
                                #006837 ${trackColors.ECOT1S}%, 
                               #960000 ${trackColors.ECOT1S}%, 
                               #960000 ${trackColors.ECOT2R}%, 
                                #8cc63f ${trackColors.ECOT2R}%, 
                                #8cc63f ${trackColors.ECOT2S}%,
                               #960000 ${trackColors.ECOT2S}%,
                               #960000 ${trackColors.ECOT3R}%,
                                #006837 ${trackColors.ECOT3R}%,
                                #006837 100%)`,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container className='ecomode'>
                <Grid item xs={2} style={{ textAlign: 'center', paddingTop: '108px' }} className='modes'>
                    <img style={{ margin: '10px 20px' }} alt="Tryb ECO" className="small-icon" src={Tempdom} />
                    <img style={{ margin: '10px 20px' }} alt="Tryb ECO" className="small-icon" src={TempCWU} />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '20px', position: 'relative' }}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>tania energia rano,<br /> wieczorem + <b>PV ready</b></Typography>
                            <div onClick={() => handleClickOpen('ECOY1', 'ECOY1', tempChange.ECOY1, dataRange().ECOY1.min, dataRange().ECOY1.max, "Podniesienie temp. w taniej energii", tempChange.ECOY1)} className='temp-button darkgreen'>+/- {tempChange.ECOY1}°C</div>
                            <div onClick={() => handleClickOpen('ECOX1', 'ECOX1', tempChange.ECOX1, dataRange().ECOX1.min, dataRange().ECOX1.max, "Podniesienie temp. w taniej energii", tempChange.ECOX1)} className='temp-button darkgreen'>+ {tempChange.ECOX1}°C</div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>tania energia <br />po południu</Typography>
                            <div onClick={() => handleClickOpen('ECOY2', 'ECOY2', tempChange.ECOY2, dataRange().ECOY2.min, dataRange().ECOY2.max, "Podniesienie temp. w taniej energii", tempChange.ECOY2)} className='temp-button lightgreen'>+/- {tempChange.ECOY2}°C</div>
                            <div onClick={() => handleClickOpen('ECOX2', 'ECOX2', tempChange.ECOX2, dataRange().ECOX2.min, dataRange().ECOX2.max, "Podniesienie temp. w taniej energii", tempChange.ECOX2)} className='temp-button lightgreen'>+ {tempChange.ECOX2}°C</div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='h5' style={{ display: 'inline-block' }}>droga energia<br /><br /></Typography>
                            <div onClick={() => handleClickOpen('ECOTIN', 'ECOTIN', tempChange.ECOTIN, dataRange().ECOTIN.min, dataRange().ECOTIN.max, "Obniżenie temp. w drogiej energii", tempChange.ECOTIN)} className='temp-button red'>+/- {tempChange.ECOTIN}°C</div>
                            <div onClick={() => handleClickOpen('ECODHW', 'ECODHW', tempChange.ECODHW, dataRange().ECODHW.min, dataRange().ECODHW.max, "Obniżenie temp. w drogiej energii", tempChange.ECODHW)} className='temp-button red'>- {tempChange.ECODHW}°C</div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h5">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default EcoMode