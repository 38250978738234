import React, { useState, useEffect } from 'react'
import {
    Grid, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, TableFooter, Paper, Button, Select, FormControl, MenuItem, InputLabel
} from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TablePaginationActions } from '../../components'
import { ListItem, ExportServisList } from './components'
import { fetchServiceNotesParms, fetchGetServicers } from '../../data'
import { default as paramsService } from '../../dictionary/serviceNoteDictionary'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from "date-fns/locale/pl"


const ServicePanel = ({ token, role, user, accessScada }) => {
    const [paramsFilter, setParamsFilter] = useState({ paramsStatus: 1, paramsCategory: 0, paramsExecutive: 0, paramsMethod: 0, paramsDateFrom: "", paramsDateTo: "" })
    const [notes, setNotes] = useState(null)
    const [servicers, setServicers] = useState(null)
    const [page, setPage] = useState(0)
    const [info, setInfo] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [dateFrom, setDateFrom] = useState(new Date())
    const [dateTo, setDateTo] = useState(new Date())
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const dataPack = () => {
        let data = {}
        if (paramsFilter && paramsFilter.paramsStatus !== 0)
            data = { ...data, note_status_id: parseInt(paramsFilter.paramsStatus) }

        if (paramsFilter && paramsFilter.paramsCategory !== 0)
            data = { ...data, note_type_id: parseInt(paramsFilter.paramsCategory) }

        if (paramsFilter && paramsFilter.paramsExecutive !== 0)
            data = { ...data, executive_id: parseInt(paramsFilter.paramsExecutive) }

        if (paramsFilter && paramsFilter.paramsMethod !== 0)
            data = { ...data, method_of_notification: parseInt(paramsFilter.paramsMethod) }

        if (paramsFilter && dateFrom !== "0000-00-00" && dateTo !== "0000-00-00")
            data = { ...data, date_from: `${dateFrom.toISOString().replace(/T.*/,'')} 00:00:00`, date_to: `${dateTo.toISOString().replace(/T.*/,'')} 23:59:59` }

        return data
    }

    const submitChangeParamsSend = (payload) => {
        fetchServiceNotesParms(payload)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status)
                }
            }).then(response => {
                if (response.data)
                    setNotes(response.data)
                else {
                    setNotes(null)
                    setInfo("Nie ma zgłoszeń o zadanych parametrach")
                }
            })
            .catch(error => {
                // if (error.message === "403") {
                //     sessionStorage.removeItem("APIToken")
                // }
            })
    }

    const getServicers = () => {
        fetchGetServicers()
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status)
                }
            }).then(response => {
                setServicers(response.data)
            })
            .catch(error => {
                // if (error.message === "403") {
                //     sessionStorage.removeItem("APIToken")
                // }
            })
    }

    const handleChangeParamsFilter = (event) => {
        // console.log(event)
        switch (event.target.name) {
            case "serviceCategory":
                setParamsFilter({ ...paramsFilter, paramsCategory: parseInt(event.target.value) })
                break;

            case "serviceStatus":
                setParamsFilter({ ...paramsFilter, paramsStatus: parseInt(event.target.value) })
                break;

            case "serviceExecutive":
                setParamsFilter({ ...paramsFilter, paramsExecutive: parseInt(event.target.value) })
                break;

            case "serviceMethod":
                setParamsFilter({ ...paramsFilter, paramsMethod: parseInt(event.target.value) })
                break;

            case "serviceDateFrom":
                if (event.target.value.length === 11) {
                    return
                } else if (event.target.value.length < paramsFilter.paramsDateFrom.length) {
                    setParamsFilter({ ...paramsFilter, paramsDateFrom: event.target.value })
                } else if (event.target.value.length === 4) {
                    setParamsFilter({ ...paramsFilter, paramsDateFrom: event.target.value + "-"})
                } else if (event.target.value.length === 7 || (event.target.value.length === 6 && event.target.value.slice(-1) !== "1" && event.target.value.slice(-1) !== "0")) {
                    setParamsFilter({ ...paramsFilter, paramsDateFrom: event.target.value + "-"})
                } else
                setParamsFilter({ ...paramsFilter, paramsDateFrom: event.target.value })
                break;

            case "serviceDateTo":
                if (event.target.value.length === 11) {
                    return
                } else if (event.target.value.length < paramsFilter.paramsDateTo.length) {
                    setParamsFilter({ ...paramsFilter, paramsDateTo: event.target.value })
                } else if (event.target.value.length === 4) {
                    setParamsFilter({ ...paramsFilter, paramsDateTo: event.target.value + "-"})
                } else if (event.target.value.length === 7 || (event.target.value.length === 6 && event.target.value.slice(-1) !== "1" && event.target.value.slice(-1) !== "0")) {
                    setParamsFilter({ ...paramsFilter, paramsDateTo: event.target.value + "-"})
                } else
                setParamsFilter({ ...paramsFilter, paramsDateTo: event.target.value })
                break;;

            default:
                break;
        }
    }

    const handleChangeParamsSend = () => {
        submitChangeParamsSend(dataPack())
    }

    const changeServiceNotesList = (action, value) => {
        if (action === "PUT" && value) {
            console.log({ value })
            let serviceNoteList = [...notes]
            let newServiceNoteList = serviceNoteList.map(item => {
                if (item.id === value.id) {
                    return {
                        ...item,
                        content: value.content,
                        modification_date: value.modification_date,
                        note_status_id: value.note_status_id,
                        note_type_id: value.note_type_id,
                        executive_id: value.executive_id,
                        parts: value.parts
                    }
                } else {
                    return item
                }
            })

            setNotes(newServiceNoteList)

        } else if (action === "POST" && value) {
            let newServiceNoteList = [...notes]
            newServiceNoteList.push(value)
            setNotes(newServiceNoteList)
            // console.log("POST notes")
        }
    }

    useEffect(() => {
        let mounted = true
        submitChangeParamsSend({ note_status_id: 1 }) //executive_id dodać jako użytkownika, na adminie wszystko, na Piotrka (Piotrek jest adminem) wszystko
        getServicers()

        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6} style={{ padding: 0 }}>
                    <Typography variant="h2">Panel serwisowy wersja BETA</Typography>
                </Grid>
                <Grid item xs={6} style={{ padding: 0 }}>
                    <ExportServisList dataToExport={notes} />
                </Grid>
            </Grid>
            <Grid container>

                <Grid item xs={2}>
                    <FormControl style={{ margin: "20px -15px" }}>
                        <InputLabel id="serviceStatus">Status</InputLabel>
                        <Select
                            labelId="serviceStatus"
                            name="serviceStatus"
                            id="serviceStatusParams"
                            value={paramsFilter && paramsFilter.paramsStatus ? paramsFilter.paramsStatus : 0}
                            onChange={handleChangeParamsFilter}
                        >
                            <MenuItem value={0}>Status</MenuItem>
                            {Object.entries(paramsService.serviceNoteStatus).map(item => {
                                return <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>
                            })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl style={{ margin: "20px -15px" }}>
                        <InputLabel id="serviceCategory">Kategoria</InputLabel>
                        <Select
                            labelId="serviceCategory"
                            name="serviceCategory"
                            id="serviceCategoryParams"
                            value={paramsFilter && paramsFilter.paramsCategory ? paramsFilter.paramsCategory : 0}
                            onChange={handleChangeParamsFilter}
                        >
                            <MenuItem value={0}>Kategoria</MenuItem>
                            {Object.entries(paramsService.serviceNoteType).map(item => {
                                return <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>
                            })
                            }
                        </Select>
                    </FormControl>
                </Grid>

                {accessScada && servicers && (role !== "partner" || accessScada.partner_id === 1) ? <Grid item xs={2}>
                    <FormControl style={{ margin: "20px -15px" }}>
                        <InputLabel id="serviceExecutive">Wykonawca</InputLabel>
                        <Select
                            labelId="serviceExecutive"
                            name="serviceExecutive"
                            id="serviceExecutiveParams"
                            value={paramsFilter && paramsFilter.paramsExecutive ? paramsFilter.paramsExecutive : 0}
                            onChange={handleChangeParamsFilter}
                        >
                            <MenuItem value={0}>Wykonawca</MenuItem>
                            {Object.entries(servicers).map(item => {
                                return <MenuItem key={item[1].id} value={item[1].id}> {item[1].first_name} {item[1].last_name}</MenuItem>
                            })
                            }
                        </Select>
                    </FormControl>
                </Grid> : null}
                <Grid item xs={2}>
                    <FormControl style={{ margin: "20px -15px" }}>
                        <InputLabel id="serviceMethod">Sposób zgłoszenia</InputLabel>
                        <Select
                            labelId="serviceMethod"
                            name="serviceMethod"
                            id="serviceMethodParams"
                            value={paramsFilter && paramsFilter.paramsMethod ? paramsFilter.paramsMethod : 0}
                            onChange={handleChangeParamsFilter}
                        >
                            <MenuItem value={0}>Sposób zgłoszenia</MenuItem>
                            {Object.entries(paramsService.serviceNoteMethod).map(item => {
                                return <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>
                            })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <FormControl style={{ margin: "20px -15px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                            <KeyboardDatePicker
                                variant="inline"
                                ampm={false}
                                label="Od dnia"
                                placeholder="0000-00-00"
                                value={dateFrom}
                                onChange={setDateFrom}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <FormControl style={{ margin: "20px -15px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                            <KeyboardDatePicker
                                variant="inline"
                                ampm={false}
                                label="Do dnia"
                                placeholder="0000-00-00"
                                value={dateTo}
                                onChange={setDateTo}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                        </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={() => handleChangeParamsSend()} style={{ marginTop: '30px', float: "right" }}>Zobacz</Button>
                </Grid>
            </Grid>
            {!token ? <Redirect to="/" /> : null}
            {notes ? (
                <React.Fragment>
                    <Grid container direction="row" >
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography component="div" height="100%">
                                                <Box fontWeight="fontWeightBold" height="100%">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Lp
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div" height="100%">
                                                <Box fontWeight="fontWeightBold" height="100%">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Id
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Status
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Kategoria
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Data
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Tytuł
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Nr seryjny i klucz
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Klient
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="div">
                                                <Box fontWeight="fontWeightBold">
                                                    <Grid container spacing={0} direction="row" alignItems="center">
                                                        <Grid item xs={11}>
                                                            Sposób zgłoszenia
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <p></p>
                                        </TableCell>
                                        <TableCell>
                                            <p></p>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : notes
                                    ).map((row, index) => (
                                        <React.Fragment key={index}><ListItem accessScada={accessScada} user={user} role={role} changeServiceNotesList={changeServiceNotesList} item={row} index={index} /></React.Fragment>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {<TablePagination
                                            rowsPerPageOptions={[15, 30, 50, { label: 'All', value: -1 }]}
                                            colSpan={8}
                                            count={notes.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'Ilość wyników' },
                                                native: true,
                                            }}
                                            label={`Ilość wyników`}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </React.Fragment>)
                : info !== "" ? <Typography variant="h3" style={{ textAlign: "center" }}>{info}</Typography> : <CircularProgress />}

        </React.Fragment>
    )
}

export default ServicePanel