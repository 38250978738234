import React, { useEffect, useState } from 'react'
import { Typography, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const DeviceDetails = ({ details, perm, colorTelemetryUpdate }) => {
    const [deviceDetails, setDeviceDetails] = useState(null)

 

    useEffect(() => {
       
        setDeviceDetails(details)

    }, [details])




    return (
        <React.Fragment>
            {details ? (<React.Fragment>
                <Grid container direction="row" style={{ padding: "0 0 15px", margin: "0 0 15px" }} className={colorTelemetryUpdate}>
                    <Grid item xs={8}><Typography variant="h2">Szczegóły urządzenia o id: {details.id}</Typography></Grid>
                    <Grid item xs={4}><Typography variant="h2">{deviceDetails? deviceDetails.partner.name : "Brak informacji o partnerze"}</Typography></Grid>
                    <Grid item xs={4}><Typography variant="h6">Ostatnio online:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="h6">{details.last_telemetry_update === (null || undefined) || parseInt(details.last_telemetry_update.slice(0, 4)) < 2019 ? "NIGDY" : `${details.last_telemetry_update.slice(0, 19).replace("T", " ")}`}</Typography></Grid>
                </Grid>

                            <Grid container direction="row">
                                <Grid item xs={4}><Typography variant="body1">Nr seryjny:</Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1">{deviceDetails ? deviceDetails.device_sn : ""}</Typography></Grid>
                                
                            </Grid>

                            <Grid container direction="row" >
                                <Grid item xs={4}><Typography variant="body1">Nr jed. zew.:</Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1">{deviceDetails && deviceDetails.device_outside !== null && deviceDetails.device_outside !== undefined ? deviceDetails.device_outside.sn 
                                    : "Nie powiązano jednostki zewnętrznej z instalacją"}</Typography></Grid>
                              </Grid>
                        
               
                <Grid container direction="row">
                    <Grid item xs={4}><Typography variant="body1">Typ urządzenia:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="body1">{details.device_type}</Typography></Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={4}><Typography variant="body1">Klucz maliny:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="body1">{details.key}</Typography></Grid>
                </Grid>
                
            </React.Fragment>) : <CircularProgress />
            }
        </React.Fragment>
    )
}

export default DeviceDetails