import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { registerDictionary } from '../../../../data/dataDictionary'
import { FiberManualRecord } from '@material-ui/icons'

const LogicCWU = ({ settings }) => {
    const dictionary = registerDictionary().analysis.cwu
    const deviceName = sessionStorage.getItem('deviceName')

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Analiza logiki sterowania CWU</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_OOF}</Typography>
                </Grid>             
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_OOF === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_OOF === 1 ? "Urządzenie załączone" : "Urządzenie wyłączone"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_DHX}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_DHX === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_DHX === 1 ? "Funkcja cwu dostępna" : "Funkcja cwu niedostępna"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBF_DHS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBF_DHS === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBF_DHS === 1 ? "Funkcja cwu załączona" : "Funkcja cwu wyłączona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QDHW}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QDHW === 1 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QDHW === 1 ? "Woda w zasobniku cwu za zimna" : "Woda w zasobniku cwu podgrzana"}</Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_ERR}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_ERR === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_ERR === 0 ? "Brak błędu" : "Aktywny błąd"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QDIS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QDIS === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QDIS === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QRMAX}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QRMAX === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QRMAX === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {deviceName === 'GEO_1' ? 
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QSMIN}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QSMIN === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMIN === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QSMAX}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SBB_QSMAX === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QSMAX === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SBB_QCS}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.SBB_QCS === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SBB_QCS === 0 ? "Zezwolenie" : "Wymuszony postój"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {deviceName === 'GEO_1' ? 
                <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.SXB_4DR}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.SXB_4DR === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.SXB_4DR === 0 ? "Praca prawidłowa" : "Praca nieprawidłowa"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            {deviceName === 'GEO_1' ? 
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_SMD}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={2}>
                                <FiberManualRecord style={{ color: settings.REW_SMD === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_SMD === 0 ? "W normie" : "Przekroczona"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null
            }
            <Grid container style={{ backgroundColor: '#F8F8F8' }}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ lineHeight: '27px' }}>{dictionary.REW_RMD}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                            <FiberManualRecord style={{ color: settings.REW_RMD === 0 ? 'green' : 'red', marginLeft: '45px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" style={{ lineHeight: '27px' }}>{settings.REW_RMD === 0 ? "W normie" : "Przekroczona"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}

export default LogicCWU