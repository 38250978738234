//Json z danymi sterowników od brokera
class JsonDeviceHeader {

    Name
    Type
    Version
}

class JsonDevice extends JsonDeviceHeader {

    Data
}

class JsonObject {

    Token
    TimeStamp
    ExpirationTime
    Devices = []
}

function GenerateToken() {

    return Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
}

// module.exports = {

//     GetJson: function (Values, Name, Type, Version) {

//         try {

//             let JData = JSON.parse(Values)
//             let JObject = new JsonObject()
//             let JDev = new JsonDevice()
//             JDev.Data = {}

//             for (let [key, value] of Object.entries(JData.Data)) {

//                 JDev.Data[key] = value
//             }

//             JDev.Data["TOKEN"] = GenerateToken().toString();

//             JDev.Name = Name
//             JDev.Type = Type
//             JDev.Version = Version
//             JObject.Token = JData.Token.toString()
//             JObject.TimeStamp = new Date().toISOString()
//             JObject.ExpirationTime = 0
//             JObject.Devices = [JDev]

//             return JSON.stringify(JObject)
//         }
//         catch{

//             return null
//         }
//     }
// }

export const PublishSetupModule = (Values, Name, Type, Version) =>{
    try {
        let JObject = new JsonObject()
        if(typeof(Values) === "string") {
            let JData = JSON.parse(Values)
            let JDev = new JsonDevice()
            JDev.Data = {}
    
            for (let [key, value] of Object.entries(JData.Data)) {
    
                JDev.Data[key] = value
            }
    
            JDev.Data["TOKEN"] = GenerateToken().toString();
    
            JDev.Name = Name
            JDev.Type = Type
            JDev.Version = Version
            JObject.Token = JData.Token.toString()
            JObject.TimeStamp = new Date().toISOString()
            JObject.ExpirationTime = 0
            JObject.Devices = [JDev]
        } else {
            JObject.Devices = []
            Values.map((value, i) => {
                let JData = JSON.parse(value)
                let JDev = new JsonDevice()
                JDev.Data = {}
                
                for (let [key, value] of Object.entries(JData.Data)) {
        
                    JDev.Data[key] = value
                }
        
                JDev.Data["TOKEN"] = GenerateToken().toString();
        
                JDev.Name = Name[i]
                JDev.Type = Type[i]
                JDev.Version = Version[i]
                JObject.Devices.push(JDev)
            })
            JObject.Token = JSON.parse(Values[0]).Token.toString()
            JObject.TimeStamp = new Date().toISOString()
            JObject.ExpirationTime = 0
        }

        return JSON.stringify(JObject)
    }
    catch{

        return null
    }
}