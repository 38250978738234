import React, { useState, useEffect } from 'react'
import { Grid, Typography, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import Back from '../../img/powrot.svg'
import X from '../../img/x.svg'
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../theme'
import { DataTime } from './components'
import { CarouselInputMulti } from '../../components'
import { HeatingCurve, CoolingCurve, HeatingCurve2, Antylegionella } from './components'
import { dataRange } from '../../data/dataRange'

const dayWeek = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela']

const SettingsPage = ({ settings, device, setPublish, url, locState }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings);
    const [open, setOpen] = useState({ status: false, name: "", init: 0, min: 0, max: 0, num: "", title: "", val: 0 })
    const [more, setMore] = useState({ status: false, name: "", value: {} })
    // const config = window.configValue

    const hostOffline = window.location.hostname

    const handleClickOpen = (name, init, min, max, num, title, val) => {
        setOpen({ status: true, name: name, init: init, min: min, max: max, num: num, title: title, val: val })
    }

    const handleClickOpenMore = (name) => {
        if (name === "time" || name === "date" || name === "dayweek")
            setMore({ status: true, name: name })
        else
            setMore({ ...more, status: true, name: name })
    }

    const handleClose = () => {
        setState({ ...state, [open.name]: open.val })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const handleCloseMore = (blok) => {
        if (more.name === "time" || more.name === "date" || more.name === "dayweek") {
            // setState({ ...state, 
            //             [blok]: {
            //                 ...state[blok],
            //                 ...Object.fromEntries(Object.entries(more.value).map(entry => [entry[0].slice(3), entry[1]]))
            //             }
            // })
            setPublish(more.value)
            setMore({ ...more, status: false })
        } else
            setMore({ ...more, status: false })
    }

    const handleChange = (e) => {
        if (state[e.name] === 1) {
            setState({ ...state, [e.name]: 0 })
            setPublish({ [e.name]: 0 })
        } else {
            setState({ ...state, [e.name]: 1 })
            setPublish({ [e.name]: 1 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({ 
            ...open, 
            val: val 
        })
        // setPublish({ [num]: val })
    }
    const setNewValue2 = (num, val) => {
        setMore({
            ...more,
            value: {
                ...more.value,
                [num]: val
            }
        })
        // setPublish({ [num]: val })
    }

    const handleRefresh = () => {
        setTimeout(() => {
            window.location.reload()
        })
    }

    useEffect(() => {
        let mounted = true

        setState(settings)

        return () => {
            mounted = false
        }
    }, [settings])

    /* useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
      }, []);
      console.log(hostOffline) */
    console.log(dataRange().SX4_PH.max)
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline-block' }}>Ustawienia</Typography>
                    <div className='back-btn' style={{ marginTop: "20px" }}>
                        <Link to={`${url}`}>
                            <img style={{ width: '100%' }} alt="Powrót" src={Back} />
                        </Link>
                    </div>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px", marginTop: "30px" }}>
                <Grid item xs={10} className="setting-item" style={{ borderTop: "0" }}>
                    <Typography variant="body1">
                        Zezwolenie na kontrolę temperatury wewnętrznej (HEAT, AC{device && device.Name.slice(0, 3) === "GEO" ? ', FC' : null})
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item" style={{ borderTop: "0" }}>
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SXF_TOE === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SXF_TOE"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Próg załączenia ogrzewania
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item" onClick={() => handleClickOpen("SX4_PH", state.SX4_PH, dataRange().SX4_PH.min, state.SX4_PC - 1, "SX4_PH", "Próg załączenia ogrzewania", state.SX4_PH)}>
                    <Typography variant="body1" className="edit-box">{state.SX4_PH}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Krzywa grzewcza
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpenMore("heatingcurve")} className="edit-box" style={{ padding: "0 25px" }}>
                        Edytuj
                    </Typography>
                </Grid>
            </Grid>
            {settings.OB1_XZM_TRANSM === 0 ? <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Krzywa grzewcza drugiego obiegu
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpenMore("heatingcurve2")} className="edit-box" style={{ padding: "0 25px" }}>
                        Edytuj
                    </Typography>
                </Grid>
            </Grid> : null}
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Próg załączenia chłodzenia
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item" onClick={() => handleClickOpen("SX4_PC", state.SX4_PC, state.SX4_PH + 1, dataRange().SX4_PC.max, "SX4_PC", "Próg załączenia chłodzenia", state.SX4_PC)}>
                    <Typography variant="body1" className="edit-box">{state.SX4_PC}</Typography>
                </Grid>
            </Grid>

            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Krzywa chłodzenia
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpenMore("coolingcurve")} className="edit-box" style={{ padding: "0 25px" }}>
                        Edytuj
                    </Typography>
                </Grid>
            </Grid>
            {/* {state.SBF_ACX === 1 ? <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Załączenie Active-Coolingu
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_ACS === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_ACS"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid> : null} */}
            {/* {device && device.Name.slice(0, 3) === "GEO" && state.SBF_FCX === 1 ? <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Załączenie Free-Coolingu
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_FCS === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_FCS"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid> : null} */}
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Zezwolenie na załączenie pompy cyrkulacyjnej CWU
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SXF_ENACYR === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SXF_ENACYR"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Zezwolenie na załączenie trybu Antylegionella
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SXF_ANL === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SXF_ANL"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Antylegionella
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    {/*<Typography variant="body1" onClick={() => handleClickOpenMore("antylegionella")} className="edit-box" style={{ padding: "0 25px" }}>
                        Edytuj
                    </Typography>*/}
                    {state.SBF_DHX === 1 ?
                        <Link to={`${url}/antylegionella`} style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" className="edit-box" style={{ padding: "0 25px", color: '#FFFFFF' }}>Edytuj</Typography>
                        </Link>
                        : <Typography variant="body1" className="edit-box" style={{ padding: "0 25px", color: '#4d4d4d', cursor: 'default' }}>Edytuj</Typography>
                    }
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Możliwość załączenia źródła szczytowego
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_AHX === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_AHX"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Możliwość załączenia źródła szczytowego tylko w tanim prądzie
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_AHS === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_AHS"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={4} className="setting-item">
                    <Typography variant="body1">
                        Ustawienie daty i zegara
                    </Typography>
                </Grid>
                <Grid item xs={8} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpenMore('date')} className="data-box">
                        {state.datatime.DZIEN < 10 ? `0${state.datatime.DZIEN}` : state.datatime.DZIEN}.{state.datatime.MIESIAC < 10 ? `0${state.datatime.MIESIAC}` : state.datatime.MIESIAC}.{state.datatime.ROK}
                    </Typography>
                    <Typography variant="body1" onClick={() => handleClickOpenMore('dayweek')} className="data-box">
                        {dayWeek[state.datatime.DZIEN_TYG]}
                    </Typography>
                    <Typography variant="body1" onClick={() => handleClickOpenMore('time')} className="data-box">
                        {state.datatime.GODZINY < 10 ? `0${state.datatime.GODZINY}` : state.datatime.GODZINY}:{state.datatime.MINUTY < 10 ? `0${state.datatime.MINUTY}` : state.datatime.MINUTY}:{state.datatime.SEKUNDY < 10 ? `0${state.datatime.SEKUNDY}` : state.datatime.SEKUNDY}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Zezwolenie na automatyczną zmianę czasu (letni/zimowy)
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SXF_TIM_WIN_SW === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SXF_TIM_WIN_SW"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        0 = czas letni / 1 = czas zimowy
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SXF_TIM_WIN_FL === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SXF_TIM_WIN_FL"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">
                        Wersja sterownika
                    </Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" style={{ display: "block", color: "#ffffff", padding: "0 25px 0 60px", textDecoration: "none" }}>
                            {state.VER_S1}.{state.VER_S2}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item">
                    <Typography variant="body1">Wersja aplikacji</Typography>
                </Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" style={{ display: "block", color: "#ffffff", padding: "0 25px 0 60px", textDecoration: "none" }}>
                        1.77.0
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item" style={{ borderBottom: "0" }}><Typography variant="body1">Ustawienia i dane serwisowe</Typography></Grid>
                <Grid item xs={2} className="setting-item" style={{ borderBottom: "0" }}>
                    <Typography variant="body1" className="data-box" style={{ padding: 0 }}>
                        <Link onClick={handleRefresh} style={{ display: "block", color: "#ffffff", padding: "0 25px", textDecoration: "none" }}
                            to={{ pathname: `${url.replace("box", "monitor")}`,
                                state: { device_sn: locState.device_sn, installation_id: locState.installation_id, last_telemetry_update: locState.last_telemetry_update } }}>
                            Przejdź
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            {/*} <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Możliwość załączenia ogrzewania budynku</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_HTX === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_HTX"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
    </Grid> 
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Możliwość załączenia podgrzewania CWU</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_DHX === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_DHX"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
           {/* {device && device.Name.slice(0, 3) === "GEO" ? <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Możliwość załączenia Free-Coolingu </Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_FCX === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_FCX"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid> : null}*/}



            {/*<Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Możliwość załączenia Active-Coolingu</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SBF_ACX === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SBF_ACX"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            

            

            
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">0 = CWU przez zawór 2 drożny / 1 = CWU przez pompę obiegową</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Switch
                        style={{ display: "block" }}
                        checked={state.SXF_DHM === 1 ? true : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="SXF_DHM"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Siła oddziaływania mechanizmu CROT</Typography></Grid>
                <Grid item xs={2} className="setting-item" onClick={() => handleClickOpen("TOUT_NUM", state.TOUT_NUM, 0, 24, "TOUT_NUM", "Siła mechanizmu CROT")}>
                    <Typography variant="body1" className="edit-box">{state.TOUT_NUM}</Typography>
                </Grid>
    </Grid>*/}


            {/*<Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Współczynnik sterujący mechanizmu REG+</Typography></Grid>
                <Grid item xs={2} className="setting-item" onClick={() => handleClickOpen("COC", state.COC, 0, 50, "COC", "Współczynnik sterujący REG+")}>
                    <Typography variant="body1" className="edit-box">{state.COC}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Histereza grzania</Typography></Grid>
                <Grid item xs={2} className="setting-item" onClick={() => handleClickOpen("SX3_HG", state.SX3_HG, 2, 16, "SX3_HG", "Histereza grzania")}>
                    <Typography variant="body1" className="edit-box">{state.SX3_HG}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Histereza chłodzenia</Typography></Grid>
                <Grid item xs={2} className="setting-item" onClick={() => handleClickOpen("SX3_HC", state.SX3_HC, 2, 16, "SX3_HC", "Histereza chłodzenia")}>
                    <Typography variant="body1" className="edit-box">{state.SX3_HC}</Typography>
                </Grid>
</Grid>*/}


            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"md"} open={more.status} onClose={() => handleCloseMore('datatime')} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={() => handleCloseMore('datatime')} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    {more.name === "time" || more.name === "date" || more.name === "dayweek" ?
                        <Grid container>
                            <Grid item xs={12} className="carousel">
                                <DataTime settings={state.datatime} setNewValue2={setNewValue2} element={more.name} />
                            </Grid>
                        </Grid> :
                        <Grid container>
                            <Grid item xs={12} className="carousel">
                                {more.name === "heatingcurve" ? <HeatingCurve settings={settings.heatingCurve} setPublish={setPublish} />
                                    : more.name === "coolingcurve" ? <CoolingCurve settings={settings.coolingCurve} setPublish={setPublish} />
                                        : more.name === "heatingcurve2" ? <HeatingCurve2 settings={settings.heatingCurve2} setPublish={setPublish} />
                                            : null}
                            </Grid>
                        </Grid>}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default SettingsPage