import React, { useState, useEffect } from 'react'
import { Typography, Grid, Dialog, DialogContent, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTheme, VictoryScatter, VictoryLabel } from 'victory'
import { CarouselInputMulti } from '../../../../components'
import { mainStyleClasses } from '../../../../theme'

import { dataRange } from '../../../../data/dataRange'
import { registerDictionary } from '../../../../data/dataDictionary'
import { ItemMapping } from '../../../../components'

const CoolingCurve = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: "" })

    const dictionary = registerDictionary()

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={5}  style={{ zIndex: '99' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.coolingCurveParameters.parameters.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.parameters, undefined, handleClickOpen, dictionary.coolingCurveParameters.parameters.data, classes, filteredItems(itemValue.parameters), 'parameters')}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.coolingCurveParameters.temp.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.temp, undefined, handleClickOpen, dictionary.coolingCurveParameters.temp.data, classes, filteredItems(itemValue.temp), 'temp')}
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant="h2">{dictionary.coolingCurveParameters.reg.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ItemMapping(itemValue.reg, undefined, handleClickOpen, dictionary.coolingCurveParameters.reg.data, classes, filteredItems(itemValue.reg), 'reg')}
                            <Grid container className={classes.containerVerticalCenter}   style={{ paddingRight: '24px' }}>
                                <Grid item xs={10}>
                                    <Typography variant="body2">Różnica temp. wewn. akt. i zad. (TSM10 - SX6)</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.textRight}>{(itemValue.reg.TSM10 - itemValue.reg.SX6).toFixed(1)}{dataRange().SX6.symbol}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={() => handleClose(open.blok)} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={() => handleClose(open.blok)} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Grid container>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6}>
                                    <CarouselInputMulti init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} num={open.num} step={open.step} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item xs={7}>
                    <VictoryChart theme={VictoryTheme.material} style={{ parent: { left: "250px", height: '500px', position: 'absolute' } }}>
                        <VictoryAxis
                            width={400}
                            height={400}
                            domain={[10, 40]}
                            theme={VictoryTheme.material}
                            offsetY={50}
                            orientation="bottom"
                            standalone={false}
                            tickValues={[10, 15, 20, 25, 30, 35, 40]}
                            tickLabelComponent={<VictoryLabel dy={0} style={{ fontSize: 10 }} />}
                            label="TSM9 [4072] - temperatura zewnętrzna"
                            style={{
                                axisLabel: { padding: 35, fontSize: 10 }
                            }}
                        />
                        <VictoryAxis dependentAxis
                            width={400}
                            height={400}
                            domain={[0, 30]}
                            theme={VictoryTheme.material}
                            offsetX={50}
                            orientation="left"
                            standalone={false}
                            tickValues={[0, 5, 10, 15, 20, 25, 30]}
                            tickLabelComponent={<VictoryLabel dx={0} style={{ fontSize: 10 }} />}
                            label="SCCU [4091] - wartość wyliczona"
                            style={{
                                axisLabel: { padding: 35, fontSize: 10 }
                            }}
                        />

                        <VictoryLine
                            scale={{ x: "linear", y: "linear" }}
                            labels={["", `P2(${itemValue.parameters.SX2_P2})`, `P1(${itemValue.parameters.SX2_P1})`]}
                            data={[
                                { x: 10, y: itemValue.parameters.SX2_P2 },
                                { x: 20, y: itemValue.parameters.SX2_P2 },
                                { x: 35, y: itemValue.parameters.SX2_P1 },
                                { x: 40, y: itemValue.parameters.SX2_P1 }
                            ]}
                            style={{ data: { stroke: "tomato", strokeWidth: 3 }, labels: { fontSize: 8 } }}

                        />

                        <VictoryScatter
                            data={[
                                { x: 20, y: itemValue.parameters.SX2_P2 },
                                { x: 35, y: itemValue.parameters.SX2_P1 }
                            ]}

                        />
                    </VictoryChart>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default CoolingCurve