import React from 'react';
import logo from '../../img/LogoEuros.svg'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { mainStyleClasses } from '../../theme'
import { Navigation } from '../'

const Header = ({setBaseLogin, perm, accessScada, role}) => {
    const classes = mainStyleClasses()
    return (
        <AppBar position="static">
            <Grid container>
                <Grid item xs={6}>
                    <img className={classes.logo} src={logo} alt="Euros Energy"
                        title="Euros Energy"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Navigation accessScada={accessScada} role={role} perm={perm} setBaseLogin={setBaseLogin} />
                </Grid>
            </Grid>
        </AppBar>
    )
}

export default Header;