import React, { useState, useReducer, useEffect } from 'react'
import { NumberInput, OptionList } from '../../../../components'
import { descriptionDictionary } from '../../../../dataFilter/descriptionDictionary'
import airConditioningIcon from '../../../../../../img/Chlodzenie2.svg'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Navbar from 'react-bootstrap/Navbar'


const switchOption = (state, action) => {
    if (action.case === "UPDATE_FROM_BROKER") {
        return (action.payload)
    } else {
        return { ...state, [action.type]: parseInt(action.payload) }
    }
}

const AirConditioning = ({ airConditioning, setPublish }) => {

    const [controller, setAirConditioningState] = useReducer(switchOption, airConditioning)
    const [state, setState] = useState(null)

    useEffect(() => {
        setAirConditioningState({ payload: airConditioning, case: "UPDATE_FROM_BROKER" })
    }, [airConditioning])

    
    const handleChangeAirConditioning = e => {
        let sendKey;
        let sendData;

        if (e.id) {
            sendKey = e.id;
            sendData = e.value;
            setAirConditioningState({ type: e.id, payload: e.value })
        } else {
            sendKey = e.target.name;
            sendData = e.target.value;
            setAirConditioningState({ type: e.target.name, payload: e.target.value })
        }

        setPublish({ [sendKey]: sendData })
    }
    return (
        <React.Fragment>
            <Row>
                <Navbar bg="primary" variant="dark" style={{ width: "100%" }}>
                    <Navbar.Brand className="mr-auto"><img alt="chłodzenie" className="mode-icon" src={airConditioningIcon} /></Navbar.Brand>
                    <Navbar.Brand>CHŁODZENIE</Navbar.Brand>
                </Navbar>
            </Row>
            <Row>
                <Col lg={6} xs={12}>
                    <Row className="bottom-margin">
                        <Col xs={8} className="text-left">
                            <p>{descriptionDictionary.airConditioning.SX2_P1}</p>
                        </Col>
                        <Col xs={4}>
                            <Form.Control as="select" custom value={controller.SX2_P1} name="SX2_P1" onChange={e => handleChangeAirConditioning(e)}>
                                <OptionList nameKey={`SX2_P1`} start={10} stop={controller.SX2_P2} />
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="bottom-margin">
                        <Col xs={8} className="text-left">
                            <p>{descriptionDictionary.airConditioning.SX2_P2}</p>
                        </Col>
                        <Col xs={4}>
                            <Form.Control as="select" custom value={controller.SX2_P2} name="SX2_P2" onChange={e => handleChangeAirConditioning(e)}>
                                <OptionList nameKey={`SX2_P2`} start={controller.SX2_P1} stop={25} />
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-left"><p>{descriptionDictionary.airConditioning.SX4_PC}</p></Col>
                        <Col xs={6}>
                            <Row>
                                <Col xs={4}> &nbsp;</Col>
                                <NumberInput min={controller.SX4_PH + 1} max={30} setState={setState} descript={descriptionDictionary.airConditioning.SX4_PC} value={controller["SX4_PC"]}
                                    stamp={"SX4_PC"} stateStamp={"SX4_PC"} setTemperatureState={setAirConditioningState} handleSendTemperatureState={handleChangeAirConditioning} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/*state ? (<ToastInfo response={state} close={() => setState(null)} />) : null*/}
        </React.Fragment>
    )
}

export default AirConditioning