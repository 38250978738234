import React, { useRef, useState, useEffect, useReducer } from "react";
import { Grid, Typography } from '@material-ui/core'
import {
  Main,
  TimeMode,
  EcoMode,
  SettingsPage,
  AwayMode,
  Circulation,
  Warnings,
  ErrorPage,
} from "./pages";
import { Antylegionella } from "./pages/SettingsPage/components"
import { DefaultValueSet } from "./components";
import { ThemeProvider } from "@material-ui/core/styles";
import { mainStyleTheme } from "./theme";
import { mqttConfig } from "../../config/mqttConfig";
import { mqttClient } from "./config/mqttClient";
import { functionPublish } from "./config/functionPublish";
import { registerValue } from "./data/dataFilter";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router-dom";
import fetchCustomer from '../../data/fetchCustomer'
import fetchDevice from '../../data/fetchDevice'

// const started = async () => {
//   await fetch("started.txt")
//     .then((response) => response)
//     .then(() => console.log("started data"));
// };
// const params = new URLSearchParams(window.location.search)
// const mqttHost = params.get("mqtt")

// const config = window.configValue

const switchOption = (state, action) => {
  return { ...state, [action.type]: action.payload };
};

function EurosBox({ token }) {
  let { installation, pass } = useParams();
  // let location = useLocation();
  // const { sn, token, installation_id, telemetry } = location.state ? location.state : JSON.parse(localStorage.getItem('deviceInfo'))
  const [deviceDetails, setDeviceDetails] = useState({ device_sn: "", installation_id: "", last_telemetry_update: "" })
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [tokenStatus, setTokenStatus] = useState([]);
  const [touchStatus, setTouchStatus] = useState(false);
  const [messagesHelp, setMessagesHelp] = useState(null);
  const [messages, setMessages] = useState(null);
  const [publishMsg, setPublish] = useState(null);
  const clientRef = useRef(null);
  const [defaultValue, setDefault] = useState(false);
  // const [startedtxt, setStarted] = useState(0);
  const [errorOfExpiration, seterrorOfExpiration] = useState(null);
  const [customer, setCustomer] = useState({ first_name: "", last_name: "", phone: "", street: "", street_number: "", post_code: "", city: "" })
  const [transmState, settransmState] = useState(0)
  
  sessionStorage.setItem('deviceName', messages ? messages.device.Name : null)
  sessionStorage.setItem('version_1', messages ? messages.settings.VER_S1 : null)
  sessionStorage.setItem('version_2', messages ? messages.settings.VER_S2 > 99 ? messages.settings.VER_S1.toString().slice(0, 2) : messages.settings.VER_S2 : null)
  sessionStorage.setItem('isExt', messages ? messages.MAG_C_CH : null)

  // localStorage.setItem('deviceInfo', location.state ? JSON.stringify(location.state) : JSON.stringify({ sn: sn, token: token, installation_id: installation_id, telemetry: telemetry }))

  const changeTokenStatus = (token) => {
    let newTokenStatus = tokenStatus.slice()
    let time = Date.now()

    newTokenStatus.push({
      token: token,
      dateTime: time,
    })

    setTokenStatus(newTokenStatus)
  }

  const saveMessageFunc = (message, tokenStat, touch) => {
    let saveToState
    let DateTime = new Date()
    let StampTime = new Date()
    DateTime = Date.now()

    StampTime = Date.parse(JSON.parse(message).TimeStamp)
    if (DateTime - StampTime > JSON.parse(message).ExpirationTime + 3000) {
      seterrorOfExpiration(1)
    } else {
      seterrorOfExpiration(null)
      saveToState = registerValue(JSON.parse(message).Devices)
      saveToState.expirationData = {
        ExpirationTime: JSON.parse(message).ExpirationTime,
        TimeStamp: JSON.parse(message).TimeStamp,
      }

      if (!touch) {
        let newTokenStatus = []

        if (tokenStat && tokenStat.length > 0) {
          for (let index = 0; index < tokenStat.length; index++) {
            if ((DateTime - tokenStat[index].dateTime < JSON.parse(message).ExpirationTime + 3000) && (`${tokenStat[index].token}` !== JSON.parse(message).Token)) {
              newTokenStatus.push(tokenStat[index])
            }

          }
          setTokenStatus(newTokenStatus)
        }
        setMessages(saveToState)
        console.log({dane: JSON.parse(message)})
      }

    }
  }


  useEffect(() => {
    let mounted = true
    if (messagesHelp)
      saveMessageFunc(messagesHelp, tokenStatus, touchStatus)

    return () => {
      mounted = false
    }
  }, [messagesHelp])


  useEffect(() => {
    let mounted = true;
    if (publishMsg && messages.device) {
      functionPublish(
        clientRef.current,
        publishMsg,
        installation,
        messages.device,
        changeTokenStatus
      );
      setPublish(null);
    }
    return function cleanup() {
      mounted = false;
    };
  }, [publishMsg, installation]);

  useEffect(() => {
    let saveToState;
    clientRef.current = mqttClient.getClient(mqttConfig());

    clientRef.current.subscribe(`${installation}/Dev`)
    clientRef.current.subscribe(`${installation}/wifi`)
    clientRef.current.subscribe(`${installation}/Diagnostics/Agent`)
    clientRef.current.on("connect", () => setConnectionStatus(true));
    clientRef.current.on(
      "message",
      function (topic, message) {
        if (topic === `${installation}/Dev` && JSON.parse(message).Devices) {
          setMessagesHelp(message)
        }
        // if (topic === `${config.topicKey}/Diagnostics/Agent`) {

        //   console.log({alarmy: JSON.parse(message)})
        // }
        // console.log(JSON.parse(message))
        // if (startedtxt === 0) {
        //   setStarted(1);
        //   setTimeout(() => {
        //     clientRef.current.publish(`${config.topicKey}/start`, JSON.stringify({version: '1.0'}), {retain: false})
        //     started();
        //   }, parseInt(config.startTime));
        // }
      },
      [tokenStatus]
    )



    return () => {
      if (clientRef.current) {
        clientRef.current.unsubscribe(`${installation}/Diagnostics/Agent`);
        clientRef.current.unsubscribe(`${installation}/wifi`);
        clientRef.current.unsubscribe(`${installation}/Dev`);
        clientRef.current.end(clientRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let mounted = true
    if (installation) {
        fetchDevice(token, installation)
            .then(response => {
                if (mounted)
                    setDeviceDetails(response)
            })
    }
    return function cleanup() {
        mounted = false
    }
  }, [token, installation])

  useEffect(() => {
    if (deviceDetails.installation_id) {
        fetchCustomer(token, deviceDetails.installation_id)
            .then(response => {
                setCustomer(response)
            })
    }
  }, [deviceDetails, deviceDetails.installation_id, token])

  useEffect(() => {
    if (messages && messages.main.scada.MB_COMM2_XZM_TRANSM === 1) {
        settransmState(transmState + 1)
    }
    else {
        settransmState(0)
    }
  }, [messagesHelp])

  let { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Grid container className='infobox' justifyContent="center" style={{ maxWidth: "1024px", margin: "auto", marginBottom: "30px" }}>
          <Grid item xs={4}>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{deviceDetails.device_sn}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.first_name} {customer.last_name}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.phone}</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{installation}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>ul. {customer.street} {customer.street_number}</Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{customer.post_code}, {customer.city}</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>Ostatnio online: </Typography>
              <Typography variant="h3" className='text-scada' style={{ margin: "5px 0" }}>{deviceDetails && deviceDetails.last_telemetry_update ? deviceDetails.last_telemetry_update.slice(0, 19).replace("T", " ") : ""}</Typography>
          </Grid>
      </Grid>
      <div className="euros-box">
      {messages && !defaultValue ? (
        <DefaultValueSet
          setDefault={setDefault}
          defaultValue={defaultValue}
          settings={messages ? messages.defaultdata : null}
          setPublish={setPublish}
        />
        ) : null}
        <Router>
        
          <ThemeProvider theme={mainStyleTheme}>
            {(messages && messages.errors && messages.errors.Errors >= 3) ||
              errorOfExpiration === 1 ||
              !messages ? (
              <ErrorPage />
            ) : (
              <Switch>
                <Route exact path={`${url}/`}>
                  {messages ? (
                    <Main
                      settings={messages ? messages.main : null}
                      extSettings={messages ? messages.extenders : null}
                      setPublish={setPublish}
                      url={url}
                      transmState={transmState}
                    />
                  ) : null}
                </Route>
                <Route path={`${url}/timemode`}>
                  {messages ? (
                    <TimeMode
                      setTouchStatus={setTouchStatus}
                      settings={messages ? messages.timemode : null}
                      setPublish={setPublish}
                      url={url}
                    />
                  ) : null}
                </Route>
                <Route exact path={`${url}/ecomode`}>
                  {messages ? (
                    <EcoMode
                      setTouchStatus={setTouchStatus}
                      settings={messages ? messages.ecomode : null}
                      setPublish={setPublish}
                      url={url}
                    />
                  ) : null}
                </Route>
                <Route exact path={`${url}/awaymode`}>
                  {messages ? (
                    <AwayMode
                      settings={messages ? messages.main : null}
                      setPublish={setPublish}
                    />
                  ) : null}
                </Route>
                <Route exact path={`${url}/warnings`}>
                  {messages ? (
                    <Warnings
                      typeDevice={messages ? messages.device.Name : null}
                      settings={messages ? messages.warnings : null}
                      setPublish={setPublish}
                      url={url}
                    />
                  ) : null}
                </Route>
                <Route exact path={`${url}/circulation`}>
                  {messages ? (
                    <Circulation
                      setTouchStatus={setTouchStatus}
                      settings={messages ? messages.circulation : null}
                      setPublish={setPublish}
                      url={url}
                    />
                  ) : null}
                </Route>
                <Route exact path={`${url}/settings`}>
                  {messages ? (
                    <SettingsPage
                      settings={messages ? messages.settings : null}
                      device={messages ? messages.device : null}
                      setPublish={setPublish}
                      url={url}
                      locState={deviceDetails}
                    />
                  ) : null}
                </Route>
                <Route exact path={`${url}/antylegionella`}>
                    <Antylegionella
                      settings={messages ? messages.settings.antylegionella : null}
                      device={messages ? messages.device : null}
                      setPublish={setPublish}
                      url={url}
                    />
                </Route>
              </Switch>
            )}
          </ThemeProvider>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default EurosBox;