import React, { useReducer, useState, useEffect } from 'react'
import { mainStyleClasses } from '../../theme'
import { Typography, Grid, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { registerDictionary } from '../../data/dataDictionary'
import { deviceType } from '../../data/deviceType' 
import { CarouselInput } from '../../components'
import { dataRange } from '../../data/dataRange'

const IDP_SUBTable = ["glikol/woda", "powietrze/woda", "woda/woda", "powietrze/powietrze", "powietrze/woda cwu", "nieznany", "nieznany", "nieznany"]
const IDP_MODTable = ["grzanie", "chłodzenie", "rewersyjna", "inna"]
const IDP_EXPTable = ["termostatyczny", "elektroniczny", "kapilara", "korektor", "2 x termostatyczny", "2 x elektroniczny", "nieznany", "nieznany"]

const Identyfication = ({ settings, setPublish }) => {
    const {controller, configuration, characteristics} = settings
    const classes = mainStyleClasses()
    const [controlState, setControlState] = useState(controller)
    const [configState, setConfigState] = useState(configuration)
    const [charState, setCharState] = useState(characteristics)
    const dict = registerDictionary().identyfication

    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, item: "", setter: "", val: 0 })
    
    const handleSwitchValue = (e, item, setter) => {
        if (item[e.name] === 1) {
            setter({ ...item, [e.name]: 0})
            setPublish({ [e.name]: 0 })
        } else {
            setter({ ...item, [e.name]: 1 })
            setPublish({ [e.name]: 1 })
        }
    }
    
    const handleClickOpen = (element, name, title, min, max, step, item, setter, val) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, item: item, setter: setter, val: val})
    }
    
    const handleClose = () => {
        open.setter({ ...open.item, [open.name]: open.val })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const setNewValue = (name, val) => {
        setOpen({
            ...open,
            name: name,
            val: val
        })
    }

    useEffect(() => {
        let mounted = true
        setControlState(controller)
        setConfigState(configuration)
        setCharState(characteristics)
        return () => {
            mounted = false
        }
    }, [controller, configuration, characteristics])
    
    const switchControl = (entry, item, setter, title) => {
        switch (dataRange()[entry[0]].controller) {
            case 1:
                return (
                        <Grid item xs={3}>
                            <Switch
                                checked={item[entry[0]] === 0 ? false : true}
                                onChange={(e) => handleSwitchValue(e.target, item, setter)}
                                color="primary"
                                name={entry[0]}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                )
            case 2:
                let minValue = typeof (dataRange()[entry[0]].min) === "string" ? parseFloat(item[dataRange()[entry[0]].min]) : parseFloat(dataRange()[entry[0]].min)
                let maxValue = typeof (dataRange()[entry[0]].max) === "string" ? parseFloat(item[dataRange()[entry[0]].max]) : parseFloat(dataRange()[entry[0]].max)
                return (
                    <Grid item xs={3} onClick={() => handleClickOpen(entry[1], entry[0], title, minValue, maxValue, dataRange()[entry[0]].step, item, setter, entry[1])}>
                        <Typography variant="body2" className={"carousel-input-click"}>{entry[1]} {dataRange()[entry[0]].symbol}</Typography>
                    </Grid>
                )
            default:
                break;
        }
    }

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   }, []);

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok.map : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }
    
    return (
        <div className={`${classes.backgroundWhite} ${classes.boxRadius}`} style={{ height: "100%" }}>
            <Grid container style={{ padding: '0 24px' }}>
                <Grid item xs={12}>
                    <Typography variant="h2">Identyfikacja</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h2">{dict.identyfication_controller.title}</Typography>
                        {controlState ? filteredItems(controlState).map(entry => {
                            if (entry[1] !== undefined) {
                                return (
                                    <Grid container key={entry[0]}>
                                    <Grid item xs={7}><Typography variant="body2">{dict.identyfication_controller.data[entry[0]]}</Typography></Grid>

                                        {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                            switchControl(entry, controlState, setControlState, dict.identyfication_controller.data[entry[0]]) 
                                            :   <Grid item xs={5} className={classes.textRight}>
                                                    {dataRange()[entry[0]].controller === 4 ? 
                                                        entry[1] === 1 ? 
                                                            <FiberManualRecord style={{ color: "green" }} /> 
                                                            : <FiberManualRecord style={{ color: "grey" }} /> 
                                                        : <Typography variant="body2" style={{ marginLeft: '25px'}}>
                                                            {entry[0] === 'IDM_GRO' ? 
                                                            `${entry[1]} - ${deviceType()[entry[1]] ? deviceType()[entry[1]] : deviceType()[0]}`
                                                                : entry[0] === 'IDM_MAN' ?
                                                                    entry[1] === 102 ? "EurosEnergy" : "nieznany"
                                                                    : entry[0] === 'VER_S1' ?
                                                                        `${entry[1]}.${controlState.VER_S2}`
                                                                        : dataRange()[entry[0]].parsed === 'float' ? 
                                                                            entry[1].toFixed(1) 
                                                                            : entry[1]}{dataRange()[entry[0]].symbol}</Typography>}
                                                </Grid>
                                        }
                                    </Grid>
                                )
                            } else {
                                return null
                            }
                        }) : null
                    }
                    <Grid container>
                        <Grid item xs={7}>
                            <Typography variant="body2">Wersja aplikacji</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.textRight}>
                            <Typography variant="body2" style={{ marginLeft: '25px'}}>1.37.0</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h2">{dict.characteristics.title}</Typography>
                    {charState ? filteredItems(charState).map(entry => {
                        if (entry[1] !== undefined) {
                            return (
                                <Grid container key={entry[0]}>
                                    <Grid item xs={7}><Typography variant="body2">{dict.characteristics.data[entry[0]]}</Typography></Grid>

                                    {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                        switchControl(entry, charState, setCharState, dict.characteristics.data[entry[0]]) 
                                        :   <Grid item xs={5} className={classes.textRight}>
                                                {dataRange()[entry[0]].controller === 4 ? 
                                                    entry[1] === 1 ? 
                                                        <FiberManualRecord style={{ color: "green" }} /> 
                                                        : <FiberManualRecord style={{ color: "grey" }} /> 
                                                    : <Typography variant="body2" style={{ marginLeft: '11px'}}>
                                                        {entry[0] === 'IDP_SUB' ? 
                                                            `${entry[1]} - ${IDP_SUBTable[entry[1]]}` 
                                                            : entry[0] === 'IDP_MOD' ? 
                                                                `${entry[1]} - ${IDP_MODTable[entry[1]]}`  
                                                                : entry[0] === 'IDP_EXP' ? 
                                                                    `${entry[1]} - ${IDP_EXPTable[entry[1]]}` 
                                                                    : dataRange()[entry[0]].parsed === 'float' ? 
                                                                        entry[1].toFixed(1) 
                                                                        : entry[1]}{dataRange()[entry[0]].symbol}</Typography>}
                                            </Grid>}
                                </Grid>
                            )
                        } else {
                            return null
                        }
                    }) : null
                    }
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                    <Typography variant="h2">{dict.controller_configuration.title}</Typography>
                    {configState ? filteredItems(configState).map((entry, index) => {
                        if (entry[1] !== undefined) {
                            return (
                                <Grid container key={entry[0]} style={{ backgroundColor: index % 2 === 1 ? '#F8F8F8' : null }}>
                                    <Grid item xs={10}><Typography variant="body2">{dict.controller_configuration.data[entry[0]]}</Typography></Grid>

                                    {dataRange()[entry[0]].controller && dataRange()[entry[0]].RW === 1 ?
                                        switchControl(entry, configState, setConfigState, dict.controller_configuration.data[entry[0]]) 
                                        :   <Grid item xs={2} className={classes.textRight}>
                                                {dataRange()[entry[0]].controller === 4 ? 
                                                    entry[1] === 1 ? 
                                                        <FiberManualRecord style={{ color: "green" }} /> 
                                                        : <FiberManualRecord style={{ color: "grey" }} /> 
                                                    : <Typography variant="body2">{dataRange()[entry[0]].parsed === 'float' ? entry[1].toFixed(1) : entry[1]}  {dataRange()[entry[0]].symbol}</Typography>}
                                            </Grid>}
                                </Grid>
                            )
                        } else {
                            return null
                        }
                    }) : null
                    }
                </Grid>
                {/*<Grid item xs={3} onClick={() => handleClickOpen(settings.characteristics.IDP_MOD, "IDP_MOD", dict.characteristics.title, dataRange().IDP_MOD.min, dataRange().IDP_MOD.max, dataRange().IDP_MOD.step, "IDP_MOD")}>
                <Typography variant="body2" className={"carousel-input-click"}>{IDP_MODTable[settings.characteristics.IDP_MOD]} {dataRange().IDP_MOD.symbol}</Typography>
                </Grid>*/}
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h3">{open.title}</Typography>
                    <CarouselInput init={open.element} name={open.name} min={open.min} max={open.max} setVal={setNewValue} step={open.step} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Identyfication