import React from 'react';

import { descriptionDictionary } from '../../dataFilter/descriptionDictionary'
import { TitleRow } from '../../components'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'


const TemperatureRead = ({ temperature }) => {
    
    const tableTemperature = Object.entries(temperature).map(entry => (
        <tr key={entry[0]}>
            <td className="text-left">{descriptionDictionary.temperature[entry[0]]} </td>
            <td className="text-right">{temperature[entry[0]]} &#x2103;</td>
        </tr>
    ));

    return (
        <>
            <Container>
                <TitleRow title="Odczyty temperatury" />
                <Row>
                    <Col>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th className="text-left">Opis</th>
                                    <th className="text-right">Wskazania</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableTemperature}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TemperatureRead;