import mqtt from 'mqtt'
import publishSetup from './tokenBuild'

const getClient = (options) => {
    const mqttClient = mqtt.connect(options)
    mqttClient.on('connect', function () {
        console.info('Mqtt client - connected')
    })

    mqttClient.on('error', function (Error) {

        console.error("Mqtt client error " + Error)
    })

    return mqttClient
}

const onPublish = (client, Data) => {
    console.log("weszło")
//     if (client.readyState === client.OPEN) {
//         client.send(JSON.stringify({
//             Data,
//             "Token": Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
//         }))
//     } else {
//             console.log('Connection Error');
//             let error = 'Nie można wysłać danych. Proszę sprawdzić połączenie i odświeżyć aplikację.';
//             return error;
//     }
//    console.log(JSON.stringify({
//     Data}))
//client.publish('010101002B6F543FBD73/Dev', PublishSetupModule.GetJson(value, DevConfig.Name, DevConfig.Type, DevConfig.Version))
}

const closeConnection = (client) => {
    client.end = function () {
        console.log('echo-protocol Client Closed');
    };
}

export const mqttClient = {
    getClient,
    onPublish,
    closeConnection,
}