import React, { useEffect, useState } from 'react'
import { Typography, Grid, IconButton, Dialog, DialogContent  } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../theme'
import { registerDictionary } from '../../../../data/dataDictionary'
import { CarouselInput } from '../../../../components'
import { dataRange } from '../../../../data/dataRange'
import { ItemMapping } from '../../../../components'

const Dr4Controll = ({ settings, setPublish }) => {
    const classes = mainStyleClasses()
    const [itemValue, setItemValue] = useState(settings)
    const dictionary = registerDictionary().periphery
    const [open, setOpen] = useState({ status: false, element: 0, name: "", title: "", min: 0, max: 0, step: 1, num: "", val: 0, blok: "" })

    const filteredItems = (blok) => {
        return Object.entries(blok ? blok : []).filter(entry => Object.entries(dataRange()).find(element => element[0] === entry[0]))
    }

    const handleClickOpen = (element, name, title, min, max, step, num, val, blok) => {
        setOpen({ status: true, element: element, name: name, title: title, min: min, max: max, step: step ? step : 0, num: num, val: val, blok: blok })
    }

    const handleClose = (blok) => {
        setItemValue({ ...itemValue, 
                        [blok]: {
                            ...itemValue[blok],
                            [open.name]: open.val
                        },
                    })
        setPublish({ [open.name]: open.val})
        setOpen({ ...open, status: false })
    }

    const handleSwitchValue = (e, item, blok) => {
        if (item[e.name] === 1) {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 0
                            }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setItemValue({ ...itemValue, 
                            [blok]: {
                                ...itemValue[blok],
                                [e.name]: 1
                            }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({...open, val: val })
    }

    useEffect(() => {
        let mounted = true
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Kontrola poprawności pracy zaworu 4-drogowego</Typography>
                </Grid>
                <Grid item xs={6}>
                    {ItemMapping(itemValue.map, handleSwitchValue, handleClickOpen, dictionary.dr4Controll, classes, filteredItems(itemValue.map), 'map')}
                </Grid>
                <Grid item xs={6}>
                    <svg version="1.1" id="Layer_2_00000016759233253529952860000009062259280238349205_" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 470.3 408"> 
                        <g className={itemValue.SXF_4EN === 0 ? 'cls-1' : 'cls-2'}> 
                            <path d="M110,338.8v2.3c0,6.6-5.4,12-12,12H13.5c-6.6,0-12-5.4-12-12V13.5c0-6.6,5.4-12,12-12H98c6.6,0,12,5.4,12,12v0.7"/> 
                            <line x1="110" y1="30.4" x2="110" y2="322.3"/> 
                            <path d="M272.1,224.8v60.4h-75.2V142.1c0-11.6,9.4-20.9,20.9-20.9h8.1"/> 
                            <path d="M242.9,121.2h8.3c11.5,0,20.9,9.4,20.9,20.9V210"/> 
                            <path d="M360.3,15.7v-2.2c0-6.6,5.4-12,12-12h84.5c6.6,0,12,5.4,12,12v327.6c0,6.6-5.4,12-12,12h-84.5c-6.6,0-12-5.4-12-12v-3.4"/> 
                            <line x1="360.3" y1="322" x2="360.3" y2="31"/> 
                            <polyline points="274.3,280.7 286.9,280.7 286.9,291.2 300.9,291.2 300.9,296.9 168,296.9 168,291.2 183.9,291.2 183.9,280.7 194.8,280.7 "/> 
                            <circle cx="234.5" cy="330.5" r="26"/> 
                            <line x1="216.4" y1="312.4" x2="252.5" y2="348.5"/> 
                            <line x1="252.5" y1="312.4" x2="216.4" y2="348.5"/> 
                            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="7" patternTransform="rotate(-45 2 2)"> 
                                <path d="M -1,2 l 6,0" style={{ stroke: 'red', strokeWidth: 3 }} /> 
                            </pattern> 
                            <path style={{ fill: itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0 ? 'navy'  
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 ? 'orange' 
                                    : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                        : null 
                                }}  
                                d="M209.1,338.4H80.6c-4.6,0-8.3-3.7-8.3-8.3l0,0c0-4.6,3.7-8.3,8.3-8.3h128.8" 
                            /> 
                            <path style={{ fill: itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0 ? 'orange'  
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 ? 'navy' 
                                    : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                        : null 
                                }}  
                                d="M259.6,321.8h130.1c4.6,0,8.3,3.7,8.3,8.3l0,0c0,4.6-3.7,8.3-8.3,8.3h-130" 
                            /> 
                            <path style={{ fill: itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0 ? 'red'  
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 ? 'aqua' 
                                    : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                        : null 
                                }}  
                                d="M204.6,74.7V40c0-5-4.1-9.1-9.1-9.1H80c-4.4,0-8-3.6-8-8s3.6-8,8-8h115.5c13.9,0,25.1,11.3,25.1,25.1v34.8H204.6z" 
                            /> 
                            <path style={{ fill: (itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0) || (itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0) ? 'aqua' 
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                    : null 
                            }} 
                                d="M310.7,66.2v128.3c0,17.1-13.9,31.1-31,31.1h-15.9c-4.4,0-8-3.6-8-8s3.6-8,8-8h15.9c8.3,0,15-6.8,15-15.1V66" 
                            /> 
                            <path style={{ fill: (itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0) || (itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0) ? 'red' 
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                    : null 
                            }}  
                                d="M242.5,89.5v41.7c0,4.4-3.6,8-8,8s-8-3.6-8-8V89.6L242.5,89.5z" 
                            /> 
                            <path style={{ fill: (itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0) || (itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0) ? 'aqua' 
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                    : null 
                            }}  
                                d="M294.7,48.9v-10c0-4.4-3.6-8-8-8h-36.1c-4.4,0-8.1,3.6-8.1,8l0,35.8l-16,0l0-35.8c0-13.3,10.8-24,24.1-24h36.1c13.3,0,24,10.8,24,24V49" 
                            /> 
                            <path style={{ fill: itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                : null  
                            }} 
                                d="M202.3,74.2h65.9c4.3,0,7.8,3.5,7.8,7.8v0c0,4.3-3.5,7.8-7.8,7.8h-65.9c-4.3,0-7.8-3.5-7.8-7.8v0C194.4,77.7,197.9,74.2,202.3,74.2z" 
                            /> 
                            <path style={{ fill: itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 1 && itemValue.SXB.WHT === 1 && itemValue.SXB_4DR === 0 ? 'red'  
                                : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 ? 'aqua' 
                                    : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? 'url(#diagonalHatch)' 
                                        : null 
                            }}  
                                d="M252.4,74.2v-2.5c0-12.2,10-22.2,22.2-22.2h46.2c3.4,0,6.2-2.8,6.2-6.2v-5.9c0-12.2,10-22.2,22.2-22.2h42.5c4.4,0,8,3.6,8,8s-3.6,8-8,8h-42.5c-3.4,0-6.2,2.8-6.2,6.2v5.9c0,12.2-10,22.2-22.2,22.2h-46.2c-3.4,0-6.2,2.8-6.2,6.2v2.5H252.4z" 
                            /> 
                        </g>
                        {itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 0 ?  
                            itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1 ?  
                                <text x="15" y="385" style={{ fontSize: '30px' }}>{parseFloat(itemValue.TSM11).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                                : itemValue.SXB_WCL === 1 ?  
                                    <text x="15" y="385" style={{ fontSize: '30px' }}>{parseFloat(itemValue.TSM12).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                                    : null 
                            : null 
                        } 
                        {itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 0 && ((itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1) || itemValue.SXB_WCL === 1) ? 
                                itemValue.TSM11 < itemValue.TSM13 || itemValue.TSM12 < itemValue.TSM14 ? 
                                    <text x="206" y="385" style={{ fontSize: '30px' }}>{'<<<'}</text> 
                                    : itemValue.TSM11 > itemValue.TSM13 || itemValue.TSM12 > itemValue.TSM14 ? 
                                        <text x="206" y="385" style={{ fontSize: '30px' }}>{'>>>'}</text>                            
                                        : <text x="206" y="385" style={{ fontSize: '30px' }}>{'==='}</text> 
                                : null 
                        } 
                        {itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 0 ?  
                            itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1 ?  
                                <text x="15" y="385" style={{ fontSize: '30px' }}>{parseFloat(itemValue.TSM13).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                                : itemValue.SXB_WCL === 1 ?  
                                    <text x="15" y="385" style={{ fontSize: '30px' }}>{parseFloat(itemValue.TSM14).toFixed(1)}<tspan baselineShift="sup">&deg;</tspan>C</text> 
                                    : null 
                            : null 
                        } 
                        {itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ?  
                            <g> 
                                <text x="73" y="385" style={{ fill: 'red', fontSize: '20px' }}>NIEPOPRAWNA PRACA ZAWORU LUB</text> 
                                <text x="60" y="405" style={{ fill: 'red', fontSize: '20px' }}>PRACA SKRAJNIE POZA KOPERTĄ PRACY</text> 
                            </g> 
                            : null 
                        }
                    </svg>
                    {/*<Grid item style={{ display: 'flex', justifyContent: 'center' }}> 
                        {itemValue.SXF_4EN === 0 || (itemValue.SXF_4EN === 1 && itemValue.SXB_WDHW === 0 && itemValue.SXB.WHT === 0 && itemValue.SXB_WCL === 0 &&  itemValue.SXB_4DR === 0) ? null 
                            : itemValue.SXF_4EN === 1 && itemValue.SXB_4DR === 1 ? <Typography variant="body2" style={{ color: 'red', textAlign: 'center'}}>NIEPOPRAWNA PRACA ZAWORU LUB <br/>PRACA SKRAJNIE POZA KOPERTĄ PRACY</Typography> 
                                : <Grid item xs={11} style={{ display: 'flex', justifyContent: 'space-between' }}> 
                                    <Typography variant="h5"> 
                                        {itemValue.SXF_4EN === 1 && (itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1) &&  itemValue.SXB_4DR === 0 && ((itemValue.TSM11 < itemValue.TSM13) || (itemValue.TSM11 === itemValue.TSM13) || (itemValue.TSM11 > itemValue.TSM13)) ?  
                                            itemValue.TSM11 
                                                : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 && ((itemValue.TSM12 < itemValue.TSM14) || (itemValue.TSM12 === itemValue.TSM14) || (itemValue.TSM12 > itemValue.TSM14)) ? 
                                                    itemValue.TSM12 
                                                        : null 
                                        } 
                                    </Typography> 
                                    <Typography variant="h5"> 
                                        {itemValue.SXF_4EN === 1 && (itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1) &&  itemValue.SXB_4DR === 0 && itemValue.TSM11 < itemValue.TSM13 ? `<<<` 
                                            : itemValue.SXF_4EN === 1 && (itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1) &&  itemValue.SXB_4DR === 0 && itemValue.TSM11 === itemValue.TSM13 ? `===` 
                                                : itemValue.SXF_4EN === 1 && (itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1) &&  itemValue.SXB_4DR === 0 && itemValue.TSM11 > itemValue.TSM13 ? `>>>` 
                                                    : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 && itemValue.TSM12 < itemValue.TSM14 ? `<<<` 
                                                        : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 && itemValue.TSM12 === itemValue.TSM14 ? `===` 
                                                            : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 && itemValue.TSM12 > itemValue.TSM14 ? `>>>` 
                                                                : null 
                                        } 
                                    </Typography> 
                                    <Typography variant="h5"> 
                                        {itemValue.SXF_4EN === 1 && (itemValue.SXB_WDHW === 1 || itemValue.SXB_WHT === 1) &&  itemValue.SXB_4DR === 0 && ((itemValue.TSM11 < itemValue.TSM13) || (itemValue.TSM11 === itemValue.TSM13) || (itemValue.TSM11 > itemValue.TSM13)) ?  
                                            itemValue.TSM13 
                                                : itemValue.SXF_4EN === 1 && itemValue.SXB_WCL === 1 && itemValue.SXB_4DR === 0 && ((itemValue.TSM12 < itemValue.TSM14) || (itemValue.TSM12 === itemValue.TSM14) || (itemValue.TSM12 > itemValue.TSM14)) ? 
                                                    itemValue.TSM14 
                                                        : null 
                                        } 
                                    </Typography> 
                                </Grid>     
                        } 
                    </Grid>*/} 
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"xs"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent style={{ padding: "10px 25px 25px" }}>
                <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                    <CloseIcon />
                </IconButton>
                <CarouselInput style={{ float: "left" }} init={open.element} name={open.name} min={open.min} max={open.max} title={open.title} setPublish={setPublish} type={open.type} setVal={setNewValue}/>
            </DialogContent>
        </Dialog>
        </React.Fragment>
    )
}

export default Dr4Controll