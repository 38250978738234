import React, { useReducer, useState } from 'react'
import './App.css'
import { Container, Grid } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { mainStyleTheme } from './theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Header } from './components'
import { Login, DevicesList, AddDevices, Device, OnlineManagement, NotFound, Partners, Installators, EurosMonitor, DeviceService,
  ServicePanel, EurosBox, UserPanel, RegisterList } from './pages'
import { InstallatorDetails } from './pages/Installators/components'
import { decodeToken } from "react-jwt"


const baseLoginReducer = (state, action) => {
  if (action.type === "login") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "password") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "token") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "device") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "logout") {
    return { ...state, token: false, perm: null }
  } else if (action.type === "perm") {
    return { ...state, perm: action.payload }
  }
}

const deviceFilterReducer = (state, action) => {
  switch (action.type) {
      case "FILTERS":
          return {
              ...state,
              filters: action.payload
          }

      case "SORT":
          return {
              ...state,
              sort: action.payload
          }

      default:
          return state
  }

}

function App() {

  let isLogin, perm, subLogin, role, user, scadaAccess

  if (sessionStorage.getItem("APIToken") && sessionStorage.getItem("APIToken") !== "undefined" && sessionStorage.getItem("APIToken") !== null) {
    isLogin = sessionStorage.getItem("APIToken")
    perm = decodeToken(sessionStorage.getItem("APIToken"))
    user = decodeToken(sessionStorage.getItem("APIToken"))
    role = perm.role
    console.log(perm)
    subLogin = perm.sub
    perm = perm.perms
  } else {
    isLogin = null
    perm = null
  }

  if (sessionStorage.getItem("ScadaAccess") && sessionStorage.getItem("ScadaAccess") !== "undefined" && sessionStorage.getItem("ScadaAccess") !== null) {
    scadaAccess = JSON.parse(sessionStorage.getItem('ScadaAccess'))
  } else {
    scadaAccess = null
  }

  const [baseLogin, setBaseLogin] = useReducer(baseLoginReducer, { token: isLogin, perm: perm })
  const [filterDevice, setFilterDevice] = useReducer(deviceFilterReducer, { sort: { sortColumn: "none", direction: "desc" },
  filters: { typeFilter: false, typeValue: "", SNFilter: false, SNValue: "", keyFilter: false, keyValue: "",
              onlineFilter: false, onlineValue: "", onlineFrom: "", onlineTo: "", startFilter: false, startValue: "",
              startFrom: "", startTo: "", customerFilter: false, customerValue: "", location: false, locationValue: "",
              registrationFilter: false, registrationValue: "", partnerFilter: false, partnerValue: "" } })

  const [accessScada, setAccessScada] = useState(scadaAccess)


  return (
    <Router className="App">
      <ThemeProvider theme={mainStyleTheme}>
        <CssBaseline />
        <Header accessScada={accessScada} role={role} setBaseLogin={setBaseLogin} perm={baseLogin && baseLogin.perm ? baseLogin.perm : null} />
        <Grid container style={{ marginTop: "30px" }} maxWidth="lg">
        <Grid item xs={1}></Grid>
          <Grid item xs={10}>
          <Switch>
            <Route exact path="/"><Login baseLogin={baseLogin} setBaseLogin={setBaseLogin} setAccessScada={setAccessScada} /></Route>
            <Route exact path="/urzadzenia" ><DevicesList accessScada={accessScada} role={role} perm={baseLogin.perm} subLogin={subLogin} setBaseLogin={setBaseLogin} 
            token={baseLogin && baseLogin.token ? baseLogin.token : null} filterDevice={filterDevice} setFilterDevice={setFilterDevice} /> 
            </Route>
            {role && (role === "admin" || role === "standard_viewer" || (role === "partner" && accessScada && accessScada.partner_id === 1)) ?<Route exact path="/serwis">
            <ServicePanel user={user} role={role} baseLogin={baseLogin} setBaseLogin={setBaseLogin} accessScada={accessScada}
  token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route> : null}

            {baseLogin && baseLogin.perm && role === "partner" && accessScada && accessScada.partner_id !== 1 ?
            <Route exact path="/deviceservice/:keyM/:deviceSN" ><DeviceService accessScada={accessScada} user={user} role={role} perm={baseLogin && baseLogin.perm ? baseLogin.perm : null} 
            setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /> </Route> 
            : <Route exact path="/szczegoly/:keyM/:deviceSN" ><Device accessScada={accessScada} user={user} role={role} perm={baseLogin && baseLogin.perm ? baseLogin.perm : null} 
            setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /> </Route> }

            {baseLogin && baseLogin.perm && (baseLogin.perm.includes("device_c") || baseLogin.perm.includes("admin"))
              ? <Route exact path="/dodaj"><AddDevices setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route>
              : null}
            {baseLogin && baseLogin.perm && (baseLogin.perm.includes("device_c") || baseLogin.perm.includes("admin"))
              ? <Route exact path="/partnerzy"><Partners setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route>
              : null}
            {baseLogin && baseLogin.perm && (baseLogin.perm.includes("device_c") || baseLogin.perm.includes("admin"))
              ? <Route exact path="/instalatorzy"><Installators setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route>
              : null}
            {baseLogin && baseLogin.perm && (baseLogin.perm.includes("device_c") || baseLogin.perm.includes("admin"))
              ? <Route exact path="/instalatorzy/szczegoly/:id"><InstallatorDetails perm={baseLogin && baseLogin.perm ? baseLogin.perm : null} setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route>
              : null}
            {baseLogin && baseLogin.perm && (baseLogin.perm.includes("device_c") || baseLogin.perm.includes("admin"))
              ? <Route exact path="/instalatorzy/szczegoly/:id"><InstallatorDetails perm={baseLogin && baseLogin.perm ? baseLogin.perm : null} setBaseLogin={setBaseLogin} token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route>
              : null}
            {role && (role === "admin" || role === "super_viewer" || role === "standard_viewer" || (role === "partner" && accessScada && accessScada.partner_id === 1)) ?
              <Route path="/online-management/:installation/:pass"><OnlineManagement setBaseLogin={setBaseLogin} 
              token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route> : null}
            
            {role && (role === "admin" || role === "super_viewer" || role === "standard_viewer") ? <Route path="/euros-monitor/:installation/:pass"><EurosMonitor setBaseLogin={setBaseLogin} 
            token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route> :
            null}

            {role && (role === "admin" || role === "super_viewer" || role === "standard_viewer") ? <Route path="/euros-box/:installation/:pass"><EurosBox setBaseLogin={setBaseLogin} 
            token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route> :
            null}

            {role && (role === "admin") ? <Route path="/uzytkownicy"><UserPanel setBaseLogin={setBaseLogin} 
            token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route> :
            null}

            {role && (role === "admin") ? <Route path="/register-list/:installation/:pass"><RegisterList setBaseLogin={setBaseLogin} 
            token={baseLogin && baseLogin.token ? baseLogin.token : null} /></Route> :
            null}
            
              <Route component={NotFound} />
          </Switch>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Router>
  );
}

export default App;
