import fetchRefreshToken from './fetchRefreshToken'

const fetchPartnersList = async () => {

    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/partners`, {
        method: "GET",
        headers:{
          "Authorization": `Bearer ${newToken}`
        }
      })
      .then(response => response.json())
      return res
    })

    return response
}

  export default fetchPartnersList